import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/api-calls/auth.service';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { DoctorSetupComponent } from '../doctor-setup/doctor-setup.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alerts-create',
  templateUrl: './alerts-create.component.html',
  styleUrls: ['./alerts-create.component.scss']
})
export class AlertsCreateComponent implements OnInit {

  coalldepart: any = [];
  name;
  desc;
  code;
  selectdate: any;
  selectdate1: any;
  departmentId;
  checkpointId;
  avgProcessingTime;
  doctoredit: boolean;
  loader;
  url;
  id;
  doctorObj: any = [];
  languageCode: string;
  items: any = [];
  dataSource: any;
  msgContent: string;
  avgTime;
  pocName: any;
  pocPhNo: any;
  address: any;
  vendorrObj: any;
  activeInd: boolean;
  vendor: any;
  phoneNo: any;
  emailId: any;
  cafeteriasObj: any;
  alldepart: any;
  unitPrice: any;
  itemCode: any;
  description: any;
  venue: any;
  eventObj: any;
  sdt: string;
  edt: string;

  constructor(public data: DataService, private translate: TranslateService, public app: AppService, public auth: AuthService) { }

  ngOnInit() {
    this.loader = true;
    this.languageCode = localStorage.getItem('lang');
    this.url = this.app.urlData();
    setTimeout(() => {
      this.loader = false;
    }, 500);
    if (this.url != 'alerts-create') {
      this.doctoredit = true;
      this.id = this.app.urlData();
      this.data.getalertsById(this.id).subscribe((res: any) => {
        this.eventObj = res;
        this.id = res.id;
        this.description = res.description;
        this.code = res.code;
        this.name = res.name;
        this.loader = false;
      }, err => {
        this.loader = false;
      })
    } else {
      console.log('jj')
    }

  }

  departmentGet() {
    this.data.departmentGetAll().subscribe((res: any) => {
      this.alldepart = res;
    })
  }

  alertsCreate() {
    this.loader = true;
    var post = {
      "name": this.name.toUpperCase(),
      "description": this.description,
      "code": this.code,
      orgId: localStorage.getItem('organizationId'),
    }
    this.data.alertsCreate(post).subscribe((res: any) => {
      this.loader = false;
      this.app.openPage('alerts-setup');
      // this.app.openSnackBar('Doctor Created Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Alerts Created Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })

    }, err => {
      this.loader = false;
    })
  }
  cancel() {
    this.app.openPage('alerts-setup')
  }
  alertsUpdate() {
    this.loader = true;
    this.eventObj.name = this.name.toUpperCase();
    this.eventObj.id = this.id;
    this.eventObj.code = this.code;
    this.eventObj.description = this.description;
    this.data.alertPutById(this.id, this.eventObj).subscribe(res => {
      this.loader = false;
      this.app.openPage('alerts-setup');
      // this.app.openSnackBar('Doctor Updated Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Alerts Updated Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })
    }, err => {
      this.loader = false;
    })

  }

}
