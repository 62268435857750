import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { AuthService } from 'src/api-calls/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DataService } from 'src/api-calls/data.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  languageCode: string;

  constructor(public app: AppService,
    public fb: FormBuilder,
    public translate: TranslateService,
    public service: DataService,
    private auth: AuthService) { }
  // localLang = localStorage.getItem('lang');
  // languages = [{ value: 'en-US', lang: 'English' },
  // { value: 'ta', lang: 'தமிழ்' },
  // { value: 'te', lang: 'తెలుగు' },
  // ]

  loginForm: FormGroup;
  public selectLangCode: string = 'en-US';
  // public passhide = true;
  passhide: boolean = true;
  orgcode;
  orglogo: any;

  async ngOnInit() {

    this.languageCode = localStorage.getItem('lang');
    this.loginForm = this.fb.group({
      emailId: ['', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(50)
      ]],
      password: ['', Validators.required],
      orgCode: ['', Validators.required]
    });
    if (sessionStorage.getItem('orgCode')) {
      let a = sessionStorage.getItem('orgCode')
      this.loginForm.controls['orgCode'].setValue(a)
      // this.orglogo=await this.auth.getOrgLogo(a).toPromise()
    }
  }

  async setLang(data) {
    console.log(data);
    localStorage.setItem('lang', data)
    this.app.setLang();
  }

  hide() {
    this.passhide = !this.passhide
  }

  login() {
    if (!this.loginForm.valid) {
      // this.app.openSnackBar('Please enter login Credentials!', 'error-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please enter login Credentials").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    }
    else {
      let convert = window.btoa(this.loginForm.get('password').value.trim());
      let userData = this.loginForm.get('emailId').value.trim();
      if (userData.match(/^\d{10}$/)) {
        userData = '+91' + userData;
      }
      let post = {
        "emailId": userData,
        "password": convert,
        "orgCode": this.loginForm.get('orgCode').value,
        "appId": "2"
      }

      this.service.loader = true;

      this.auth.login(post).subscribe(
        (res: any) => {
          this.service.loader = false;
          // this.app.openSnackBar('Welcome back user!', 'success-snackbar');
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get("Welcome back user").subscribe((translation: string) => {
              this.app.openSnackBar(translation, "success-snackbar")
            })
          })
          if (res.accountEnabledInd) {
            localStorage.setItem('hashKey', res.hashKey);
            localStorage.setItem('currentUserId', JSON.stringify(res.userId));
            localStorage.setItem('organizationId', res.organizationId);
            // localStorage.setItem('prefLang', res.prefLang);
            // this.setLang(res.prefLang);
            this.switchLanguage(res.prefLang);
            sessionStorage.setItem('userName', res.firstName);
            this.auth.getConfig();
            this.auth.getCapability();
            this.app.openPage('dashboard');
          }
          else {
            // sessionStorage.setItem('validateUser', JSON.stringify(res.body));
            // this.app.openSnackBar('Supervisor approval is pending,Please Contact the Supervisor', 'error-snackbar')
            this.translate.use(this.languageCode).subscribe(() => {
              this.translate.get("Supervisor approval is pending,Please Contact the Supervisor").subscribe((translation: string) => {
                this.app.openSnackBar(translation, "error-snackbar")
              })
            })
          }
        },
        (err: any) => {
          localStorage.clear()
          this.loginForm.controls['emailId'].setValue('')
          this.loginForm.controls['password'].setValue('')
          this.loginForm.controls['orgCode'].setValue('')
          localStorage.setItem('lang', 'en-US')
          this.service.loader = false;
        })
    }
  }

  register() {
    this.app.openPage('register')
  }
  switchLanguage(language: string) {
    this.translate.setDefaultLang(language);
    localStorage.setItem('lang', language);
    this.setLang(language);

    console.log(language);
    // this.localLang = localStorage.getItem('lang');
    // this.fontStyle=localStorage.getItem('lang');
  }

}
