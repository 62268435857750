import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { SearchPipe } from 'src/api-calls/custom.pipe';
import { Pipe, PipeTransform } from '@angular/core';
import { MatTableDataSource ,MatSort} from '@angular/material';
import { ExcelService } from 'src/api-calls/excel.service';

@Component({
  selector: 'app-checkpoint-search',
  templateUrl: './checkpoint-search.component.html',
  styleUrls: ['./checkpoint-search.component.scss'],
  providers: [
    Pipe
  ]
})

export class CheckpointSearchComponent implements OnInit {
  displayedColumns = ['name', 'description', 'actions'];
  dataSource: any;
  sort;
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort){
       this.dataSource.sort = this.sort;
  
    }
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  } 

  items: any = [];
  name;
  description;
  appcheckpoint;
  Cview: boolean = true;
  ad;
  appname;
  loader: boolean = true;
  msgContent: string = "";
  public addchkpt: boolean = false;
  changeView: boolean = false;

  constructor(private data: DataService, public app: AppService, public pipe: SearchPipe,private excelService: ExcelService) { }

  ngOnInit() {
    this.data.getCheckpoints().subscribe(res => {
      this.loader = false;
      if (res != null) {
        this.items = res;
        this.items.sort(function (a, b) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
          if (nameA < nameB) { return -1; }
          if (nameA > nameB) { return 1; }
          return 0;
        })
        this.dataSource = new MatTableDataSource(this.items)
      }
      else {
        this.msgContent = "No checkpoints";
      }

    })

    let x = JSON.parse(localStorage.getItem('config'));
    let a = x.find(res => res.shortCdConst == "CHKNM");
    this.appname = a.userValue;
  }

  viewChange(id) {
    if (id == 1) { this.Cview = true; }
    else {this.Cview = false; }
  }
  ngDoCheck(): void {
    if (localStorage.getItem('capability')) {
      let data: any = JSON.parse(localStorage.getItem('capability'));
      if (data.length > 0) {
        data.map(res => {
          if (res.includes('ADDCHKPT')) {
            this.addchkpt = true;
          }
        })
      }
    }
  }

  search(data) {
    if (data) {
      this.data.getCheckpoints().subscribe(res => {
        this.items = res;
        this.loader = false;
        this.items = this.pipe.transform(this.items, 'name', data);
        if(this.items.length < 1){
          this.msgContent="No data";
        }
        else{
          this.msgContent="";
        }
        this.dataSource=this.items
      })

    }
    else {
      this.data.getCheckpoints().subscribe(res => {
        this.items = res;
        this.dataSource=res
        this.loader = false;
      })
    }
  }
  edit(id) {
    this.app.openPage('checkpointAdd/' + id)
  }
  addnew() {
    this.name = "";
    this.description = "";
    this.app.popSub = []
    this.app.popData = [];
    this.app.shiftData = [];
    this.app.openPage('checkpointAdd')
  }
  exportAsXLSX(): void {
    let data:any=[];
     this.items.map(res=>data.push({CheckpointName:res.name,CheckpointDescription:res.description})
     ) 
    this.excelService.exportAsExcelFile(data, 'Checkpoint Details');
}

}


