import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { interval } from 'rxjs';
import { MatDialog } from '@angular/material';
import { AuthService } from 'src/api-calls/auth.service';
import { BlockPopComponent } from '../block-pop/block-pop.component';
import { AssistancepopComponent } from '../assistancepop/assistancepop.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
// import { Pipe, PipeTransform } from '@angular/core';

@Component({
  selector: 'app-clearworkq',
  templateUrl: './clearworkq.component.html',
  styleUrls: ['./clearworkq.component.scss'],
  // providers: [
  //   Pipe
  // ]
})
export class ClearworkqComponent implements OnInit, OnDestroy {
  checkObject: any = [];
  subcheckpoints2: any = [];
  checkPointId: any = 0;
  subCheckPointId;
  newSub;
  patientData: any;
  selectType: boolean;
  checkObjecttById: any;
  notePop: boolean = false;
  patData: any;
  subCheckPoints: any = [];
  subCheckPoints1: any = [];
  workQId;
  selectWorkq: any = [];
  workFlowId;
  currentWorkQ: any = [];
  pattagchecks: any;
  patcheckpoints: any;
  remainingWorkQ: any = [];
  MsgContent: string = "Select checkpoint to display content";
  msgContent1: string = "";
  public getmisent: boolean = false;
  public markmis: boolean = false;
  public nextpat: boolean = false;
  missedData: any = [];
  skippedData: any = [];
  loader: boolean = false;
  loader1: boolean = true;
  mis: boolean = true;
  indexValue: any;
  cloneIndex;
  idle = 0;
  url;
  patientId;
  data;
  selectedItem;
  customWorkId;
  name: any;
  patientRefId: any;
  metadata: string;
  token;
  getWorkFlow: any = [];
  selectWorkq5: any = [];
  firstMsg: string;
  wheeled: any = [];
  callRequired: any = [];
  internationalPatient: any = [];
  allcheckpoints: any = [];
  aged: any = [];
  inPatient: any = [];
  mpatient: any = []
  filterId;
  filterEnable: boolean = false;
  nriPatient: any = [];
  showToken: string;
  show: string = 'false';
  nextpatientB: boolean = true;
  checkAvail: boolean;
  location;
  checkArray: any = [];
  checkpointCheck: any = [];
  colorId;
  expandObj: any = [];
  expandId;
  expandBoolean: boolean = false;
  loader2: boolean = false;
  missedId;
  refreshBoolean: any = false;
  fasting: boolean = false;
  phone;
  refId;
  initialCount;
  emrgVipPatList: any = [];
  emrgList: any = [];
  vipList: any = [];
  emrgVipPatListDisplay: any = [];
  displaySubCheckPoint: boolean = false;
  chooseCheckpoint: Boolean = false;
  clinica: boolean;
  fromQue = "NA";
  patId;
  remainingWorkQcount;
  currentWorkQcount;
  missedDatacount;
  skippedDatacount;
  patientReferId: any;
  languageCode: string;
  // search;

  constructor(public dservice: DataService, private translate: TranslateService, public router: Router, public app: AppService, public dialog: MatDialog, public auth: AuthService) {
    interval(15000).subscribe(() => {
      if (this.url == "newWorkQ") {
        if (this.checkPointId != undefined && this.checkPointId != 0 && !this.loader) {
          this.check5()
        }
      }
      var url1 = this.app.sendUrl()
      if (url1.includes('workque')) {
        if (this.checkPointId != undefined && this.checkPointId != 0 && !this.loader) {
          this.check5()
        }
      }
    })
  }

  async ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.app.showFull = false;
    this.url = window.location.href.split('/').pop();
    this.dservice.getCheckpointsPatientAdd(1).subscribe(res => {
      this.allcheckpoints = res;
      this.allcheckpoints.map(dat => {
        Object.assign(dat, { select: false })
      })
    })
    await this.dservice.getRolesById(localStorage.getItem('currentUserId')).toPromise().then((res: any) => {
      this.loader1 = false;
      this.checkObject = res.userCheckpoints;
      if (this.checkObject) {
        this.checkObject.map((obj: any) => {
          Object.assign(obj, { select: false });
        });
      }
      if (this.checkObject && this.checkObject.length > 1) {
        this.checkObject.sort(function (a, b) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
          if (nameA < nameB) { return -1; }
          if (nameA > nameB) { return 1; }
          return 0;
        })
      }

      if (this.checkObject) {
        this.checkObject = this.checkObject.filter(res => {
          return res.activeInd == true;
        });
      }
    })
    let x = JSON.parse(localStorage.getItem('config'));
    let y = x.find(res => res.shortCdConst == "SCAPL");
    this.show = y.userValue;
    let a = x.find(res => res.shortCdConst == "TKNAUTO");
    this.showToken = a.userValue;
    let mobC = JSON.parse(localStorage.getItem('capability'));
    this.app.mobCab = mobC.includes('MOBNOVISIB');
    let b = x.find(res => res.shortCdConst == "SSCHK");
    this.newSub = b.userValue;
    let c = x.find(res => res.shortCdConst == "RSRVDSLTS");
    if (c)
      this.initialCount = c.userValue;
    else
      this.initialCount = 0;
    let f = x.find(res => res.shortCdConst == "CLNQENBL");
    if (f)
      this.clinica = JSON.parse(f.userValue);
    for (let i = 1; i <= this.initialCount; i++) {
      this.emrgVipPatListDisplay.push({ index: i })
    }
    // if (this.newSub == 'true') {
    //   this.selectType = false;
    // }
    var url = this.app.sendUrl()
    if (url.includes('workque')) {
      var id = this.url.split('/').pop()
      this.checkPointId = id
      if (this.checkPointId != undefined && this.checkPointId != 0) {
        this.check()
      }
    }
    if (sessionStorage.getItem('checkId') !== '0' && sessionStorage.getItem('checkId') !== null) {
      this.checkPointId = sessionStorage.getItem('checkId');
      this.check();
    }
  }

  async check() {
    sessionStorage.setItem("checkId", this.checkPointId);
    this.loader = true;
    this.refreshBoolean = true;
    this.app.workQData = '';
    this.app.moveBackToQ = false;
    this.app.checkPointName = '';
    this.subCheckPoints = [];
    this.subCheckPoints1 = [];
    this.emrgVipPatListDisplay = [];
    this.emrgVipPatList = [];
    this.missedData = [];
    this.skippedData = [];
    this.selectWorkq = [];
    this.currentWorkQ = [];
    this.remainingWorkQ = [];
    this.wheeled = [];
    this.aged = [];
    this.callRequired = [];
    this.mpatient = [];
    this.nriPatient = [];
    this.inPatient = [];
    this.nextpatientB = true;
    this.subCheckPointId = 0;
    this.checkArray = [];
    this.checkpointCheck = [];
    this.expandId = "";
    this.selectWorkq = [];

    for (let i = 1; i <= this.initialCount; i++) {
      this.emrgVipPatListDisplay.push({ index: i })
    }

    this.checkObjecttById = await this.dservice.getCheckpointById(this.checkPointId).toPromise();
    this.subCheckPoints1 = this.checkObjecttById.subCheckpoints;
    this.app.forceStartRea = await this.dservice.staticForFStart().toPromise();
    this.app.forceRemoveRea = await this.dservice.staticForFRemove().toPromise();
    this.checkpointCheck = this.checkObjecttById.checkpointChecks;
    this.checkpointCheck.map(res => {
      Object.assign(res, { check: false })
    })
    if (this.checkObjecttById.layout == 1) {
      this.selectType = false;
    } else {
      this.selectType = true;
    }
    if (this.checkPointId != undefined) {
      if (this.selectType) {
        sessionStorage.setItem("checkId", this.checkPointId);
        this.subCheckPointId = 0;
        sessionStorage.setItem("noDrop", 'false');
      }
      else {
        sessionStorage.setItem("checkId", this.checkPointId);
        sessionStorage.setItem("noDrop", 'true');
      }
    }
    this.checkAvail = this.checkObjecttById.available;
    this.location = this.checkObjecttById.location
    this.app.checkPointName = this.checkObjecttById.name;
    this.workQId = this.checkObjecttById.worq.id;
    this.app.workQData = this.workQId;
    if (this.checkObjecttById.subCheckpoints) {
      this.subCheckPoints = this.checkObjecttById.subCheckpoints;
      this.subCheckPoints1 = this.subCheckPoints;
      // this.subcheckpoints2 = this.subCheckPoints;
      if (!this.selectType) {
        this.subCheckPoints1 = this.subCheckPoints;
        if (this.subCheckPoints1.length < 1) {
          this.nextpatientB = false;
        }
      }

    }
    this.selectWorkq = await this.dservice.getWorkqById(this.workQId).toPromise();
    await this.dservice.getMissed(this.workQId).toPromise().then(res => {
      if (res) {
        this.missedData = res;
        this.missedDatacount = this.missedData.length
      } else {
        this.missedData = [];
        this.missedDatacount = 0;
      }
    })
    await this.dservice.getSkipped(this.workQId).toPromise().then(res => {
      if (res) {
        this.skippedData = res;
        this.skippedDatacount = this.skippedData.length
      } else {
        this.skippedDatacount = 0;
        this.skippedData = [];
      }
    })
    this.workFlowId = this.selectWorkq.id;
    if (this.selectWorkq.entities == null) {
      this.currentWorkQcount = 0;
      this.remainingWorkQcount = 0;
    }
    if (this.selectWorkq.entities != null) {
      this.selectWorkq = this.selectWorkq.entities.sort((a, b) => { return a.positionInQ - b.positionInQ });
      let a: any = this.selectWorkq.filter(res => { return res.positionInQ > 0 });
      this.wheeled = a.filter(res => { return res.wheeled == true })
      this.aged = a.filter(res => { return res.aged == true })
      this.callRequired = a.filter(res => { return res.callRequired == true })
      this.nriPatient = a.filter(res => { return res.internationalPatient == true })
      this.inPatient = a.filter(res => { return res.entityType == 2 })
      this.mpatient = a.filter(res => { return res.entityType == 3 })
      this.currentWorkQ = this.selectWorkq.filter(res => { return res.positionInQ == 0 });
      this.currentWorkQcount = this.currentWorkQ.length
      this.emrgVipPatList = this.selectWorkq.filter(res => { return res.positionInQ != 0 && res.positionInQ <= this.initialCount });
      this.remainingWorkQ = this.selectWorkq.filter(res => { return res.positionInQ > this.initialCount });
      this.remainingWorkQcount = this.remainingWorkQ.length
      this.emrgVipPatListDisplay.map((res, i) => {
        // if(res.patientRefId) {
        //   (this.patientReferId = res.patientRefId)
        // }
        // Object.assign(res, { searchFirstName: this.patientReferId })

        this.emrgVipPatList.map((res1, j) => {
          if (i == j) {
            this.emrgVipPatListDisplay[i] = this.emrgVipPatList[j];
          }
        })
      })
      // if(this.initialCount >= this.emrgList.length){
      //   this.emrgList.map(res=>{
      //     this.emrgVipPatList.push(res)
      //   })
      //  }
      // else{
      //   this.emrgList.map((res,i)=>{
      //     if(this.initialCount>=i+1){
      //       this.emrgVipPatList.push(res)
      //     }
      //     else{
      //       this.remainingWorkQ.push(res);
      //       this.remainingWorkQ = this.remainingWorkQ.sort((a, b) => { return a.positionInQ - b.positionInQ });
      //     }
      //   })
      // }
      // console.log(this.emrgVipPatList)
      // if(this.initialCount >= this.emrgVipPatList.length){
      //   if(this.initialCount >= this.emrgVipPatList.length + this.vipList.length){
      //     this.vipList.map(res=>{
      //       this.emrgVipPatList.push(res)
      //     })
      //   }
      //   else{
      //     this.vipList.map((res,i)=>{
      //       if(this.initialCount>=i+1+this.emrgVipPatList.length){
      //         this.emrgVipPatList.push(res)
      //       }
      //       else{
      //         this.remainingWorkQ.push(res);
      //         this.remainingWorkQ = this.remainingWorkQ.sort((a, b) => { return a.positionInQ - b.positionInQ });
      //       }
      //     })
      //   }
      // }
      // else{
      //   this.vipList.map(res=>{
      //     this.remainingWorkQ.push(res)
      //   })
      // this.remainingWorkQ = this.remainingWorkQ.sort((a, b) => { return a.positionInQ - b.positionInQ });
      // }
      // console.log(this.emrgVipPatList)
      // if(this.emrgVipPatListDisplay.length>= this.emrgVipPatList.length){
      //   this.emrgVipPatListDisplay.map((res,i)=>{
      //     this.emrgVipPatList.map((res1,j)=>{
      //       if(i==j){
      //         this.emrgVipPatListDisplay[i]=this.emrgVipPatList[j];
      //       }
      //   })
      //  })
      // }
      // else{

      // }

      if (this.subCheckPoints.length > 0) {
        this.subCheckPoints.sort(function (a, b) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
          if (nameA < nameB) { return -1; }
          if (nameA > nameB) { return 1; }
          return 0;
        })
        if (this.currentWorkQ.length > 0) {
          this.subCheckPoints.map(res => {
            this.currentWorkQ.map(res1 => {
              if (res1.subCheckpoint) {
                if (res.id == res1.subCheckpoint.id) {
                  Object.assign(res, { currentPatient: res1 })
                }
              }
            })
          })
        }
        // this.subcheckpoints2=this.subCheckPoints;
        this.loader = false;
      }

      this.loader = false;

    }
    if (this.currentWorkQ.length > 0) {
      this.firstMsg = "";
      this.MsgContent = "";
    }
    else {
      this.firstMsg = "No current patient";
    }
    if (this.remainingWorkQ.length > 0) {
      this.MsgContent = "";
      this.msgContent1 = "";
    }
    else {
      this.msgContent1 = "No Patients in Queue";
    }
    this.loader = false;
  }
  async checkDub() {
    this.loader = true;
    this.loader1 = false;
    this.refreshBoolean = true;
    this.app.workQData = '';
    this.app.moveBackToQ = false;
    this.app.checkPointName = '';
    this.subCheckPoints = [];
    // this.subCheckPoints1 = [];
    this.missedData = [];
    this.skippedData = [];
    this.selectWorkq = [];
    this.currentWorkQ = [];
    this.remainingWorkQ = [];
    this.wheeled = [];
    this.aged = [];
    this.callRequired = [];
    this.mpatient = [];
    this.nriPatient = [];
    this.inPatient = [];
    this.nextpatientB = true;
    // this.subCheckPointId = 0;
    this.checkArray = [];
    this.checkpointCheck = [];
    this.expandId = "";
    this.emrgVipPatListDisplay = [];
    this.emrgVipPatList = [];
    for (let i = 1; i <= this.initialCount; i++) {
      this.emrgVipPatListDisplay.push({ index: i })
    }

    if (this.checkPointId != undefined) {
      if (this.selectType) {
        sessionStorage.setItem("checkId", this.checkPointId);
        this.subCheckPointId = 0;
        sessionStorage.setItem("noDrop", 'false');
      }
      else {
        sessionStorage.setItem("checkId", this.checkPointId);
        sessionStorage.setItem("noDrop", 'true');
      }
    }
    this.checkObjecttById = await this.dservice.getCheckpointById(this.checkPointId).toPromise();
    this.checkpointCheck = this.checkObjecttById.checkpointChecks;
    this.checkpointCheck.map(res => {
      Object.assign(res, { check: false })
    })
    this.checkAvail = this.checkObjecttById.available;
    this.location = this.checkObjecttById.location
    this.app.checkPointName = this.checkObjecttById.name;
    this.workQId = this.checkObjecttById.worq.id;
    this.app.workQData = this.workQId;

    if (this.checkObjecttById.subCheckpoints) {
      // this.subcheckpoints2 = this.checkObjecttById.subCheckpoints;
      this.subCheckPoints1 = this.subCheckPoints;
      this.subCheckPoints = this.checkObjecttById.subCheckpoints;
      if (!this.selectType) {
        this.subCheckPoints = this.checkObjecttById.subCheckpoints;
        this.subCheckPoints1 = this.subCheckPoints;
        if (!this.selectType) {
          this.subCheckPoints1 = this.subCheckPoints.filter(res => { return res.available });
        }
        if (this.subCheckPoints1.length < 1) {
          this.nextpatientB = false;
        }
      }


    }
    this.selectWorkq = await this.dservice.getWorkqById(this.workQId).toPromise();
    await this.dservice.getMissed(this.workQId).toPromise().then(res => {

      if (res) {
        this.missedData = res;
        this.missedDatacount = this.missedData.length;
      } else {
        this.missedDatacount = 0;
        this.missedData = [];
      }
    })
    await this.dservice.getSkipped(this.workQId).toPromise().then(res => {

      if (res) {
        this.skippedData = res;
        this.skippedDatacount = this.skippedData.length;
      } else {
        this.skippedDatacount = 0;
        this.skippedData = [];
      }
    })
    if (this.selectWorkq.entities == null) {
      this.currentWorkQcount = 0;
      this.remainingWorkQcount = 0;
    }
    if (this.selectWorkq.entities != null) {

      this.selectWorkq = this.selectWorkq.entities.sort((a, b) => { return a.positionInQ - b.positionInQ });
      let a: any = this.selectWorkq.filter(res => { return res.positionInQ > 0 });
      this.wheeled = a.filter(res => { return res.wheeled == true })
      this.aged = a.filter(res => { return res.aged == true })
      this.callRequired = a.filter(res => { return res.callRequired == true })
      this.nriPatient = a.filter(res => { return res.internationalPatient == true })
      this.inPatient = a.filter(res => { return res.entityType == 2 })
      this.mpatient = a.filter(res => { return res.entityType == 3 })
      this.currentWorkQ = this.selectWorkq.filter(res => { return res.positionInQ == 0 });
      this.currentWorkQcount = this.currentWorkQ.length
      this.emrgVipPatList = this.selectWorkq.filter(res => { return res.positionInQ != 0 && res.positionInQ <= this.initialCount });
      this.remainingWorkQ = this.selectWorkq.filter(res => { return res.positionInQ > this.initialCount });
      this.remainingWorkQcount = this.remainingWorkQ.length
      this.emrgVipPatListDisplay.map((res, i) => {
        this.emrgVipPatList.map((res1, j) => {
          if (i == j) {
            this.emrgVipPatListDisplay[i] = this.emrgVipPatList[j];
          }
        })
      })
      // this.remainingWorkQ = this.selectWorkq.filter(res => { return res.positionInQ > 0 && !res.emrgPat && !res.vipPat });
      // this.emrgList = this.selectWorkq.filter(res => { return res.positionInQ > 0 && res.emrgPat});
      // this.vipList = this.selectWorkq.filter(res => { return res.positionInQ > 0 && res.vipPat });
      // if(this.initialCount >= this.emrgList.length){
      //   this.emrgList.map(res=>{
      //     this.emrgVipPatList.push(res)
      //   })
      //  }
      // else{
      //   this.emrgList.map((res,i)=>{
      //     if(this.initialCount>=i+1){
      //       this.emrgVipPatList.push(res)
      //     }
      //     else{
      //       this.remainingWorkQ.push(res);
      //       this.remainingWorkQ = this.remainingWorkQ.sort((a, b) => { return a.positionInQ - b.positionInQ });
      //     }
      //   })
      // }
      // if(this.initialCount >= this.emrgVipPatList.length){
      //   if(this.initialCount >= this.emrgVipPatList.length + this.vipList.length){
      //     this.vipList.map(res=>{
      //       this.emrgVipPatList.push(res)
      //     })
      //   }
      //   else{
      //     this.vipList.map((res,i)=>{
      //       if(this.initialCount>=i+1+this.emrgVipPatList.length){
      //         this.emrgVipPatList.push(res)
      //       }
      //       else{
      //         this.remainingWorkQ.push(res);
      //         this.remainingWorkQ = this.remainingWorkQ.sort((a, b) => { return a.positionInQ - b.positionInQ });
      //       }
      //     })
      //   }
      // }
      // else{
      //   this.vipList.map(res=>{
      //     this.remainingWorkQ.push(res)
      //   })
      // this.remainingWorkQ = this.remainingWorkQ.sort((a, b) => { return a.positionInQ - b.positionInQ });
      // }
      // if(this.emrgVipPatListDisplay.length>= this.emrgVipPatList.length){
      //   this.emrgVipPatListDisplay.map((res,i)=>{
      //     this.emrgVipPatList.map((res1,j)=>{
      //       if(i==j){
      //         this.emrgVipPatListDisplay[i]=this.emrgVipPatList[j];
      //       }
      //   })
      //  })
      // }
      // else{

      // }

      if (this.subCheckPoints.length > 0) {
        this.subCheckPoints.sort(function (a, b) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
          if (nameA < nameB) { return -1; }
          if (nameA > nameB) { return 1; }
          return 0;
        })
        if (this.currentWorkQ.length > 0) {
          this.subCheckPoints.map(res => {
            this.currentWorkQ.map(res1 => {
              if (res1.subCheckpoint) {
                if (res.id == res1.subCheckpoint.id) {
                  Object.assign(res, { currentPatient: res1 })
                }
              }
            })
          })
          // this.subcheckpoints2=this.subCheckPoints
        }

        this.loader = false;
      }

      this.loader = false;

    }
    if (this.currentWorkQ.length > 0) {
      this.firstMsg = "";
      this.MsgContent = "";
    }
    else {
      this.firstMsg = "No current patient";
    }
    if (this.remainingWorkQ.length > 0) {
      this.MsgContent = "";
      this.msgContent1 = "";
    }
    else {
      this.msgContent1 = "No Patients in Queue";
    }
    this.loader = false;
  }
  checkEdit(checkAvail: boolean) {
    this.loader = true;
    this.checkObjecttById.available = checkAvail;
    this.dservice.updateCheckpointsById(this.checkPointId, this.checkObjecttById).toPromise()
      .then((res) => {
        this.loader = false;
      })
      .catch(
        err => {
          this.loader = false;
          // this.app.openSnackBar(err.error.msg, 'error-snackbar');
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get(err.error.msg).subscribe((translation: string) => {
              this.app.openSnackBar(translation, "error-snackbar")
            })
          })
        }
      )

  }
  ngOnDestroy(): void {
    this.url = "";
    this.checkPointId = undefined;
    this.selectType = false;
  }

  ngDoCheck(): void {
    if (localStorage.getItem('capability')) {
      let data: any = JSON.parse(localStorage.getItem('capability'));
      if (data.length > 0) {
        data.map(res => {
          if (res.includes('GETMISSEDENT')) {
            this.getmisent = true;
          }
          if (res.includes('MRKASMISSED')) {
            this.markmis = true;
          }
          if (res.includes('NXTENTITY')) {
            this.nextpat = true;
          }
        })
      }
    }
  }
  check2() {
    this.check();
  }
  quickCheckIn(id) {
    if (this.checkPointId != undefined && this.checkPointId != 0) {
      sessionStorage.setItem('checkPointId', this.checkPointId)
      const dialogRef = this.dialog.open(AssistancepopComponent, {
        data: id
      });
      dialogRef.afterClosed().subscribe(result => {
        this.checkDub();
      })
    }
    else {
      // this.app.openSnackBar('Please Select a Checkpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a Checkpoint").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    }

  }
  async forceStart(data, missed: boolean) {
    if (this.selectType) {
      this.subCheckPointId = this.app.subCheckpointIdP;
    }
    if (this.subCheckPoints.length > 0 && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    }
    else {
      await this.dservice.forceStart(this.workFlowId, data.id, this.subCheckPointId, missed, this.app.comments, this.app.moveBackToQ).toPromise()
        .then((res: any) => {
          this.checkDub();
        });
    }
  }
  async forceStart1(result, data, missed: boolean) {
    if (this.selectType) {
      this.subCheckPointId = this.app.subCheckpointIdP;
    }
    if ((this.checkObjecttById.mandateEditWorkflow && !this.app.moveBackToQ) && result == 1) {
      this.fromQue = "U";
      this.patId = data.id
      this.fetchPat();
    }
    else {
      this.forceStartBy(data.id, missed)
    }

  }

  async forceStartBy(id, missed) {
    await this.dservice.forceStart(this.workFlowId, id, this.subCheckPointId, missed, this.app.comments, this.app.moveBackToQ).toPromise()
      .then((res: any) => {
        this.fromQue = "NA";
        this.checkDub();
      });
  }
  async forceDelete(data) {
    if (this.selectType) {
      this.subCheckPointId = this.app.subCheckpointIdP;
    }
    if (this.subCheckPoints.length > 0 && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    }
    else {
      await this.dservice.forceRemove(this.workFlowId, data.id, this.subCheckPointId, this.app.comments).toPromise()
        .then((res: any) => {
          this.checkDub();
        });
    }
  }
  async currentForceDelete(data, id) {
    if (this.selectType) {
      this.subCheckPointId = id;
    }
    if (this.subCheckPoints.length > 0 && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    }
    else {
      await this.dservice.forceRemove(this.workFlowId, data.id, this.subCheckPointId, this.app.comments).toPromise()
        .then((res: any) => {
          this.checkDub();
        });
    }
  }
  async forceDeleteMissed(data) {
    if (this.selectType) {
      this.subCheckPointId = this.app.subCheckpointIdP;
    }
    if (this.subCheckPoints.length > 0 && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    }
    else {
      await this.dservice.forceRemoveMissed(this.workFlowId, data.id, this.subCheckPointId, true).toPromise()
        .then((res: any) => {
          this.checkDub();
        });
    }
  }

  missedMovetoqueue(data){
    const result = 3;
    this.forceStart1(result, data, true);
  }

  skippedForstart(data){
    const result = 99;
    this.forceStart1(result, data, true);
  }

  currentPatForceRemove(data, id1){
    this.currentForceDelete(data, id1);
  }

  Popup(id, data): void {
    if (this.subCheckPoints.length > 0 && this.subCheckPointId == 0 && !this.selectType) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    }
    else {
      if (this.currentWorkQ.length > 0 || id == 6 || id == 7 || id == 11) {
        const dialogRef = this.dialog.open(BlockPopComponent, {
          data: id
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result != "cancel") {
          }
          if (result == 1) {
            this.forceStart1(result, data, false);
          }
          if (result == 99) {
            this.forceStart1(result, data, false);
          }
          if (result == 2) {
            this.forceDelete(data);
          }
          if (result == 3) {
            this.forceStart1(result, data, true);
          }
        });
      }
      if (this.currentWorkQ.length == 0 && id == 3) {
        const dialogRef = this.dialog.open(BlockPopComponent, {
          data: 15
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result != "cancel") {
          }
          if (result == 15) {
            this.forceStart(data, false);
          }
        });
      }
    }
  }
  PopupRemove(id, data, id1): void {
    if (this.subCheckPoints.length > 0 && this.subCheckPointId == 0 && !this.selectType) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    }
    else {

      const dialogRef = this.dialog.open(BlockPopComponent, {
        data: id
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != "cancel") {
        }
        if (result == 11) {
          this.currentForceDelete(data, id1);
        }
      });


    }
  }
  async missed(data) {
    if (this.selectType) {
      if (data.subCheckpoint) {
        this.subCheckPointId = data.subCheckpoint.id;
      }
      else {
        this.subCheckPointId = 0;
      }
    }
    if (this.subCheckPoints.length > 0 && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    }
    else {
      this.loader1 = true;
      await this.dservice.missed(this.workFlowId, data.id, this.subCheckPointId).toPromise()
        .then((res: any) => {
          this.loader1 = false;
          this.checkDub();
        })
        .catch(err => {
          this.loader1 = false;
        })

    }
  }

  select(id) {
    this.allcheckpoints.map(res => {
      if (res.id == id) {
        res.select = !res.select;
      }
    })

  }

  getPatient(id) {
    this.dservice.getPatientById(id).subscribe(res => {
      if (res) {
        sessionStorage.setItem("currentEntity", JSON.stringify(res))
        this.patientData = res;
        if (this.patientData.fasting) {
          this.fasting = this.patientData.fasting
        }
        this.chooseCheckpoint = true;
        this.allcheckpoints.map(res => {
          res.select = false;
        })
        this.getPatientWorkFlow(id);
      }
      else {
        this.loader1 = false;
      }
    }, err => {
      // this.app.openSnackBar(err.error.msg, 'error-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get(err.error.msg).subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
      this.loader1 = false;
    })
  }
  getPatientWorkFlow(id) {
    this.dservice.getWorkflowbyPatient(id).subscribe(res => {
      if (res) {
        this.patcheckpoints = res[0];
        Object.assign(this.patcheckpoints, { entityId: id })
        this.allcheckpoints.map(dat => {
          this.patcheckpoints.checkpoints.map(dat1 => {
            if (dat.id == dat1.id) {
              if (dat.id == dat1.id)
                dat.select = true;
              else
                dat.select = false;
            }

          })
        })
        this.loader1 = false;
      }
      else {
        this.loader1 = false;
      }
    },
      err => {
        // this.app.openSnackBar(err.error.msg, 'error-snackbar');
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err.error.msg).subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
        this.loader1 = false;
      })
  }
  putPatient() {
    this.pattagchecks = this.allcheckpoints.filter(res => { return res.select == true });
    this.chooseCheckpoint = false;
    this.loader1 = true;
    this.pattagchecks.map((res, i) => {
      this.pattagchecks[i].stepNbr = i + 1;
    });
    this.patcheckpoints.checkpoints = this.pattagchecks;
    this.dservice.updateWorkFlow(this.patcheckpoints.id, this.patcheckpoints).subscribe(res => {
      if (this.fromQue == "T")
        this.subcheckStart(this.patcheckpoints.entityId, this.subCheckPointId);
      else if (this.fromQue == "U")
        this.forceStartBy(this.patId, false);
      else
        this.nextQue()
    }, err => {
      // this.app.openSnackBar(err.error.msg, 'error-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get(err.error.msg).subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
      this.loader1 = false;
    })
  }
  next(id) {
    if (this.selectType) {
      if (this.subCheckPoints.length > 0 && this.subCheckPointId == 0) {
        this.subCheckPointId = id;
      }
      if (id == undefined) {
        this.subCheckPointId = 0;
      }
    }
    this.loader1 = true;
    if (this.subCheckPoints.length > 0 && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
      this.loader1 = false;
    }
    else {
      if (this.subCheckPointId != 1) {
        if (this.checkObjecttById.mandateEditWorkflow) {
          this.fetchPat();
        }
        else {
          this.nextQue();
        }
      }
      else {
        // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
        this.loader1 = false;
      }
    }
  }

  async nextQue() {
    this.dservice.nextQ(this.workFlowId, this.subCheckPointId, this.checkArray)
      .subscribe(res => {
        this.fromQue = "NA";
        this.loader1 = false;
        this.checkDub();
      },
        err => {
          this.loader1 = false;
        }
      )
  }
  async fetchPat() {
    if (this.currentWorkQ.length > 0) {
      let pat;
      if (this.currentWorkQ.length == 1) {
        if (this.currentWorkQ[0].subCheckpoint) {
          pat = this.currentWorkQ.filter(res => res.subCheckpoint.id == this.subCheckPointId);
          if (pat.length > 0) {
            this.getPatient(pat[0].id);
          }
          else {
            if (this.fromQue == "U")
              this.forceStartBy(this.patId, false);
            else
              this.nextQue();
          }
        }
        else {
          this.getPatient(this.currentWorkQ[0].id);
        }
      }
      else {
        pat = this.currentWorkQ.filter(res => res.subCheckpoint.id == this.subCheckPointId);
        if (pat.length > 0) {
          this.getPatient(pat[0].id);
        }
        else {
          if (this.fromQue == "U")
            this.forceStartBy(this.patId, false);
          else
            this.nextQue();
        }
      }
    }
    else {
      this.nextQue();
    }
  }

  async subCheckDisable(id, id1) {
    if (id1 == undefined) {
      let subCheckObject: any = {}
      subCheckObject = await this.dservice.getCheckpointById(id).toPromise();
      subCheckObject.available = !subCheckObject.available;
      this.dservice.updateCheckpointsById(id, subCheckObject).toPromise()
        .then((res) => {
          this.checkDub();
        })
        .catch(
          err => {

          }
        )
    }
    if (id1 != undefined) {
      const dialogRef = this.dialog.open(BlockPopComponent, {
        data: 17
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == 17) {
          this.subCheckPointId = id;
          if (this.checkObjecttById.mandateEditWorkflow && !this.app.moveBackToQ) {
            this.fromQue = "T";
            this.fetchPat();
          }
          else
            this.subcheckStart(id1.id, id);
        }
      })

    }
  }

  async subcheckStart(id1, id) {
    this.dservice.subCheckCurrentStart(this.workFlowId, id1, id, false, this.app.comments, this.app.moveBackToQ).toPromise()
      .then((res: any) => {
        this.fromQue = "NA";
        this.remove(id);
      }, err => {
        this.fromQue = "NA";
        this.loader1 = false;
      });
  }
  async remove(id) {
    let subCheckObject: any = {}
    subCheckObject = await this.dservice.getCheckpointById(id).toPromise();
    subCheckObject.available = !subCheckObject.available;
    this.dservice.updateCheckpointsById(id, subCheckObject).toPromise()
      .then((res) => {
        this.checkDub();
      })
      .catch(
        err => {

        }
      )
  }
  change(id) {
    id == 1 ? this.mis = true : this.mis = false;
  }
  fullScreen() {
    if (this.checkPointId != undefined && this.checkPointId != 0) {
      this.app.showFull = true;
      setTimeout(() => {
        var show = document.getElementById('fuller')
        show.requestFullscreen();
      }, 10);
    }
    else {
      // this.app.openSnackBar('Please Select a Checkpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a Checkpoint").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    }
  }
  @HostListener('click', ['$event'])
  myMethod(e) {
    if (e.target.id.includes("more-")) {
      this.indexValue = e.target.id.split("-").pop();
      this.cloneIndex = this.indexValue;
      document.getElementById('drop-' + this.indexValue).setAttribute('style', 'display:grid');
    }
    else if (this.cloneIndex != undefined && this.cloneIndex != null) {
      document.getElementById('drop-' + this.cloneIndex).setAttribute('style', 'display:none');
      this.cloneIndex = null
    }
  }
  @HostListener('fullscreenchange', ['$event']) screenChange(e) {
    if (this.idle == 0) {
      this.app.showFull = true;
      this.idle = 1;
    }
    else {
      this.app.showFull = false;
      this.idle = 0;
    }
  }
  // ngOnDestroy(): void {
  //   this.url = "";
  //   this.checkPointId = 0;
  // }
  async check5() {
    let subCheckPoints: any = [];
    this.emrgVipPatList = [];
    if (this.checkPointId != undefined) {
      if (this.selectType) {
        sessionStorage.setItem("checkId", this.checkPointId);
        // this.subCheckPointId = 0;
        sessionStorage.setItem("noDrop", 'false');
      }
      else {
        sessionStorage.setItem("noDrop", 'true');
      }
    }
    this.checkObjecttById = await this.dservice.getCheckpointById(this.checkPointId).toPromise();
    this.app.checkPointName = this.checkObjecttById.name;
    this.workQId = this.checkObjecttById.worq.id;
    this.app.workQData = this.workQId;
    if (this.checkObjecttById.subCheckpoints) {
      subCheckPoints = this.checkObjecttById.subCheckpoints;
      this.subCheckPoints1 = subCheckPoints;
      if (!this.selectType) {
        this.subCheckPoints1 = this.subCheckPoints.filter(res => { return res.available });
        if (this.subCheckPoints1.length < 1) {
          this.nextpatientB = false;
        }
      }
    }

    // this.selectWorkq = await this.dservice.getWorkqById(this.workQId).toPromise();
    await this.dservice.getMissed(this.workQId).toPromise().then(res => {

      if (res) {
        this.missedData = res;
        this.missedDatacount = this.missedData.length;
      } else {
        this.missedDatacount = 0;
        this.missedData = [];
      }
    })
    await this.dservice.getSkipped(this.workQId).toPromise().then(res => {

      if (res) {
        this.skippedData = res;
        this.skippedDatacount = this.skippedData.length;
      } else {
        this.skippedDatacount = 0;
        this.skippedData = [];
      }
    })
    this.selectWorkq5 = await this.dservice.getWorkqById(this.workQId).toPromise();
    this.workFlowId = this.selectWorkq5.id;
    if (this.selectWorkq5.entities == null) {
      this.currentWorkQcount = 0;
      this.remainingWorkQcount = 0;
    }
    if (this.selectWorkq5.entities != null) {
      this.selectWorkq5 = this.selectWorkq5.entities.sort((a, b) => { return a.positionInQ - b.positionInQ });
      let a: any = this.selectWorkq5.filter(res => { return res.positionInQ > 0 });
      this.wheeled = a.filter(res => { return res.wheeled == true })
      this.aged = a.filter(res => { return res.aged == true })
      this.callRequired = a.filter(res => { return res.callRequired == true })
      this.nriPatient = a.filter(res => { return res.internationalPatient == true })
      this.inPatient = a.filter(res => { return res.entityType == 2 })
      this.mpatient = a.filter(res => { return res.entityType == 3 })
      if (this.selectWorkq5 != this.selectWorkq) {
        this.selectWorkq = this.selectWorkq5;
        // this.selectWorkq5 = this.selectWorkq5.entities.sort((a, b) => { return a.positionInQ - b.positionInQ });
        this.currentWorkQ = this.selectWorkq5.filter(res => { return res.positionInQ == 0 });
        this.currentWorkQcount = this.currentWorkQ.length
        this.emrgVipPatList = this.selectWorkq5.filter(res => { return res.positionInQ != 0 && res.positionInQ <= this.initialCount });
        this.remainingWorkQ = this.selectWorkq5.filter(res => { return res.positionInQ > this.initialCount });
        this.remainingWorkQcount = this.remainingWorkQ.length
        this.emrgVipPatListDisplay.map((res, i) => {
          this.emrgVipPatList.map((res1, j) => {
            if (i == j) {
              this.emrgVipPatListDisplay[i] = this.emrgVipPatList[j];
            }
          })
        })
        // this.remainingWorkQ = this.selectWorkq5.filter(res => { return res.positionInQ > 0 && !res.emrgPat && !res.vipPat });
        // this.emrgList = this.selectWorkq5.filter(res => { return res.positionInQ > 0 && res.emrgPat});
        // this.vipList = this.selectWorkq5.filter(res => { return res.positionInQ > 0 && res.vipPat });
        // if(this.initialCount >= this.emrgList.length){
        //   this.emrgList.map(res=>{
        //     this.emrgVipPatList.push(res)
        //   })
        //  }
        // else{
        //   this.emrgList.map((res,i)=>{
        //     if(this.initialCount>=i+1){
        //       this.emrgVipPatList.push(res)
        //     }
        //     else{
        //       this.remainingWorkQ.push(res);
        //       this.remainingWorkQ = this.remainingWorkQ.sort((a, b) => { return a.positionInQ - b.positionInQ });
        //     }
        //   })
        // }
        // if(this.initialCount >= this.emrgVipPatList.length){
        //   if(this.initialCount >= this.emrgVipPatList.length + this.vipList.length){
        //     this.vipList.map(res=>{
        //       this.emrgVipPatList.push(res)
        //     })
        //   }
        //   else{
        //     this.vipList.map((res,i)=>{
        //       if(this.initialCount>=i+1+this.emrgVipPatList.length){
        //         this.emrgVipPatList.push(res)
        //       }
        //       else{
        //         this.remainingWorkQ.push(res);
        //         this.remainingWorkQ = this.remainingWorkQ.sort((a, b) => { return a.positionInQ - b.positionInQ });
        //       }
        //     })
        //   }
        // }
        // else{
        //   this.vipList.map(res=>{
        //     this.remainingWorkQ.push(res)
        //   })
        // this.remainingWorkQ = this.remainingWorkQ.sort((a, b) => { return a.positionInQ - b.positionInQ });
        // }
        // if(this.emrgVipPatListDisplay.length>= this.emrgVipPatList.length){
        //   this.emrgVipPatListDisplay.map((res,i)=>{
        //     this.emrgVipPatList.map((res1,j)=>{
        //       if(i==j){
        //         this.emrgVipPatListDisplay[i]=this.emrgVipPatList[j];
        //       }
        //   })
        //  })
        // }
        // else{

        // }

        if (subCheckPoints.length > 0) {
          subCheckPoints.sort(function (a, b) {
            var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
            if (nameA < nameB) { return -1; }
            if (nameA > nameB) { return 1; }
            return 0;
          })
          if (this.currentWorkQ.length > 0) {
            subCheckPoints.map(res => {
              this.currentWorkQ.map(res1 => {
                if (res1.subCheckpoint) {
                  if (res.id == res1.subCheckpoint.id) {
                    Object.assign(res, { currentPatient: res1 })
                  }
                }
              })
            })
          }
          // this.subcheckpoints2 = subCheckPoints;
          // if(!this.selectType){
          //   if(this.subCheckPointId>1)
          // this.subCheckPoints=subCheckPoints.filter(res=>res.id==this.subCheckPointId);
          // }
          // else
          this.subCheckPoints = subCheckPoints;
        }
        this.filter(this.filterId);
      }
    }
    if (this.currentWorkQ.length > 0) {
      this.firstMsg = "";
      this.MsgContent = "";
    }
    else {
      this.firstMsg = "No current patient";
    }
    if (this.remainingWorkQ.length > 0) {
      this.msgContent1 = "";
      this.MsgContent = "";
    }
    else {
      this.msgContent1 = "No Patients in Queue";
    }
  }
  filter(id) {
    this.filterId = id;
    if (id == 1) {
      this.filterEnable = true;
      this.remainingWorkQ = this.callRequired;
    }
    if (id == 2) {
      this.filterEnable = true;
      this.remainingWorkQ = this.wheeled;
    }
    if (id == 3) {
      this.filterEnable = true;
      this.remainingWorkQ = this.aged;
    }
    if (id == 4) {
      this.filterEnable = true;
      this.remainingWorkQ = this.inPatient;
    }
    if (id == 5) {
      this.filterEnable = false;
      this.remainingWorkQ = this.selectWorkq.filter(res => { return res.positionInQ > 0 })
    }
    if (id == 6) {
      this.filterEnable = true;
      this.remainingWorkQ = this.mpatient
    }
    if (id == 7) {
      this.filterEnable = true;
      this.remainingWorkQ = this.nriPatient
    }

  }
  edit(data) {
    sessionStorage.setItem('Edit', "true")
    this.app.openPage('patientEdit/' + data.id);
  }
  sendMsg(id) {
    if (this.checkPointId != undefined && this.checkPointId != 0) {
      sessionStorage.setItem('workqId', this.workQId);
      sessionStorage.setItem('checkPointSend', this.checkPointId);
      const dialogRef = this.dialog.open(AssistancepopComponent, {
        data: id
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == 21) {
          this.checkDub();

        }
      })
    }
    else {
      // this.app.openSnackBar('Please Select a Checkpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a Checkpoint").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    }

  }
  checkpointChecks(id, id1) {
    this.colorId = id1;
    this.checkpointCheck.map(res => {
      if (res.id == id) {
        res.check = !res.check;
      }
    })

    if (this.checkArray.length < 1) {
      this.checkArray.push(id);
    }
    else {
      // for(var i = this.checkArray.length - 1; i >= 0; i--) {
      if (this.checkArray.includes(id)) {
        this.checkArray.splice(this.checkArray.indexOf(id), 1)
      }
      else {
        this.checkArray.push(id);
      }
    }
  }
  async expandFunc(id) {
    if (this.expandId == id) {
      // this.loader2 = true;
      this.expandId = "";
      this.expandObj = [];
      this.expandBoolean = false;
    }
    else {
      // this.loader2 = true;
      this.expandId = id;
      this.expandBoolean = true;
      this.expandObj = [];
      this.expandObj = await this.dservice.getWorkflowbyPatient(id).toPromise();
      this.expandObj = this.expandObj[0].checkpoints;
      this.expandObj = this.expandObj.sort((a, b) => { let dateA: any = new Date(a.completedTime), dateB: any = new Date(b.completedTime); return dateB.getTime() - dateA.getTime() })
      this.expandObj.map(res => {
        Object.assign(res, { clas: '' });
        if (res.status == 1) {
          res.clas = 'checkIn';
        }
        if (res.status == 2) {
          res.clas = 'queue';
        }
        if (res.status == 3) {
          res.clas = 'current';
        }
        if (res.status == 4) {
          res.clas = 'complete';
        }
        if (res.status == 5) {
          res.clas = 'miss';
        }
      })
    }

    // this.loader2 = false;
  }

  openCard(id) {
    if (this.missedId != id) {
      this.missedId = id;
    }
    else {
      this.missedId = "";
    }

  }


  diagnosis(data) {
    console.log(data)
    if (data.id) {
      localStorage.setItem('workQsId', this.workFlowId)
      localStorage.setItem('diagnosisCheckPoint', this.checkPointId)
      if (data.subCheckpoint && data.subCheckpoint.id) {
        let subcheckinPoint = data.subCheckpoint.id;
        if (subcheckinPoint) {
          localStorage.setItem('subcheckPointId', subcheckinPoint);
        }
      } else {
        localStorage.removeItem('subcheckPointId');
      }
      this.router.navigate(['/diagnosises', data.id]);
    } else {
      // this.app.openSnackBar('diagnosis is not created', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("diagnosis is not created").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    }
  }
  addNotes(data) {
    this.patData = data;
    if (!this.patData.notes)
      Object.assign(this.patData, { notes: " " })
    this.notePop = true;
  }
  putNotes() {
    this.notePop = false;
    this.expandBoolean = false;
    let post = {
      notes: this.patData.notes
    }
    this.dservice.addPatientNotes(this.patData.workQId, this.patData.id, post).subscribe(res => {

    })
  }
  updateEntity(fasting) {
    console.log(fasting);
    var entity = JSON.parse(sessionStorage.getItem('currentEntity'));
    Object.assign(entity, { fasting: fasting })

    this.dservice.editPatient(entity.id, entity).toPromise()
      .then((res: any) => {
      });
  }

}
