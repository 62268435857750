import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-manageroles-view',
  templateUrl: './manageroles-view.component.html',
  styleUrls: ['./manageroles-view.component.scss']
})
export class ManagerolesViewComponent implements OnInit {
  getId;
  s1;
  s2;
  Capabilities:any =[];
  userObject: any = [];
  selectedItem: any = [];
  role:any=[];
  loader:boolean = true;
  languageCode: string;
  

  constructor(public data: DataService, private translate: TranslateService, public app: AppService) { }

 async  ngOnInit() {
  this.languageCode = localStorage.getItem('lang');
   await this.data.getCapabilities().toPromise().then(res=>{
  this.Capabilities = res;
  this.loader = false;
    });
    this.getId = this.app.urlData();
    this.data.getcapabilitiesById(this.getId).toPromise().then((res:any)=> {
      this.userObject = res;
        this.userObject.map(res=>{
          this.getCheck(res.id)
        })
    
    })
    this.data.getRoles().toPromise().then((res:any)=> {
      this.role = res;
      this.role = this.role.filter(res=>{
        return res.roleId == this.getId
      })
      this.role=this.role[0]
    })
  }
  async getCheck(id) {
    let mapData: any = [];
    mapData = this.Capabilities.filter(res => { return res.id == id });
    this.selectedItem.push(mapData[0]);
    this.Capabilities = this.Capabilities.filter(res => !this.selectedItem.find(val => res.id === val.id));
  }
  async getback(id) {
    let mapData: any = [];
    mapData = this.selectedItem.filter(res => { return res.id == id });
    this.Capabilities.push(mapData[0]);
    this.selectedItem = this.selectedItem.filter(res => !this.Capabilities.find(val => res.id === val.id));
  }

  save(){
    let post1:any = [];
    this.selectedItem.map(res=>{
    let post:any = {id:res.id};
    post1.push(post)
    })
    this.data.capabilityApprove(this.getId,post1).subscribe(res=>{
    //  this.app.openSnackBar('Capabilities updated Successfully !!', 'success-snackbar');
     this.translate.use(this.languageCode).subscribe(() => {
      this.translate.get("Capabilities updated Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
      })
  })
     this.app.openPage('manage-roles');
    })
  }
  cancel(){
    this.app.openPage('manage-roles');
  }
}
