import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { HandlerService } from 'src/api-calls/handler.service';
import { DataService } from 'src/api-calls/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/api-calls/auth.service';
import { ToastService } from 'src/api-calls/toast.service';

@Component({
  selector: 'app-agreement-create',
  templateUrl: './agreement-create.component.html',
  styleUrls: ['./agreement-create.component.scss']
})
export class AgreementCreateComponent implements OnInit {

  name;
  qtn;
  description;
  contents;
  loader: boolean = false;
  part1: boolean = true;
  active: boolean = true;
  id;
  item: any = {};
  items: any = [];
  edit: boolean = false;
  types = [
    { name: "Customer Agreement", type: '1' },
    { name: "Staff Agreement", type: '2' },
    { name: "Service Contractor Agreement", type: '3' },
    { name: "Customer Cancel Agreement", type: '5' },
    { name: "Third Party Ad Provider", type: '4' }
  ];
  typeName = "Customer Agreement";
  type = "0";
  url;
  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '28rem',
    minHeight: '3rem',
    placeholder: 'Enter Your Description Here...',
    translate: 'no',
    "toolbar": [
      ["bold", "italic", "underline"],
    ]
  };

  htmlContent = '';
  orgId = localStorage.getItem('organizationId');
  newOrgObject: any = {};
  orgName;
  agreement;

  constructor(
    public app: AppComponent,
    public handler: HandlerService,
    private router: Router,
    private route: ActivatedRoute,
    private service: DataService,
    private auth: AuthService,
    private toast: ToastService,
  ) { }

  ngOnInit() {
    // if (localStorage.getItem('dashBoardOrgId')) {
    //   this.orgId = localStorage.getItem('dashBoardOrgId')
    // } else {
    //   this.orgId = localStorage.getItem('organizationId')
    // }
    // this.service.getOrgLogo().subscribe(res => {
    //   this.newOrgObject = res;
    //   this.openBottomSheetOrg(this.newOrgObject)
    // })


    // sessionStorage.setItem('orgId', this.orgId);
    // localStorage.setItem('dashBoardOrgId', this.orgId);
    // this.newOrgObject = JSON.parse(localStorage.getItem('capabilityOrg'));
    // let obj = [];
    // this.newOrgObject.map(res => {
    //   let x = this.auth.getOrgCapabilities(res.id, 'SP');
    //   if (x == true) {
    //     obj.push(res)
    //   }
    // })
    // this.newOrgObject = obj;
    // this.newOrgObject.map(res => {
    //   if (res.id == this.orgId) {
    //     this.orgName = res.name;
    //   }
    // })
    this.url = window.location.href;
    this.id = this.url.split('/').pop();
    if (this.url.includes('edit-agreement')) {
      this.edit = true;
      this.loader = true;
      this.service.getAgreementById(this.id).subscribe((res: any) => {
        this.item = res;
        this.name = res.name;
        this.description = res.description;
        this.contents = res.contents;
        this.type = res.type;
        this.types.map(data => {
          if (data.type == res.type + '')
            this.typeName = data.name
        })
        this.active = res.activeInd;
        this.loader = false;
      }, err => {
        this.loader = false;
        this.handler.err(err)
      })
    }
    this.getAgreementType();
  }
  part() {
    this.part1 = false;
  }

  prev() {
    this.part1 = true;
  }

  createAssess() {
    this.loader = true;
    if (this.name) {
      this.name = this.name.toUpperCase();
    }
    if (this.description) {
      this.description = this.description.toUpperCase();
    }
    var post = {
      name: this.name,
      description: this.description,
      contents: this.contents,
      type: this.type,
      activeInd: this.active
    }
    this.service.agreeCreate(post).subscribe(res => {
      this.loader = false;
      this.toast.open('Agreement Created successfully', 'res');
      this.router.navigate(['/agreement-setup'])
    },
      err => {
        this.loader = false;
        this.handler.err(err.error);
      })
  }

  save() {
    if (this.url.includes('edit-agreement')) {
      this.editAssess();
    }
    else {
      this.createAssess();
    }
  }


  editAssess() {
    this.loader = true;
    if (this.name) {
      this.name = this.name.toUpperCase();
    }
    if (this.description) {
      this.description = this.description.toUpperCase();
    }
    this.item.type = this.type;
    this.item.contents = this.contents;
    this.item.name = this.name;
    this.item.description = this.description;
    this.item.activeInd = this.active;
    this.item.orgId = sessionStorage.getItem('orgId');
    this.service.editAgreement(this.id, this.item).subscribe(res => {
      this.loader = false;
      this.toast.open('Agreement updated successfully', 'res');
      this.router.navigate(['/agreement-setup']);
    }, err => {
      this.handler.err(err);
      this.loader = false;
    })


  }
  autoFill() {
    this.description = this.name;
  }

  // openBottomSheetOrg(res) {
  //   if (res) {
  //     this.orgName = res.name;
  //     this.orgId = res.id;
  //     // localStorage.setItem('dashBoardOrgId', res.id)
  //     this.loader = true;
  //     this.service.getAllAgreementsOrg(this.orgId).subscribe(res => {
  //       if (res) {
  //         this.items = res;
  //       }
  //       else {
  //         this.items = [];
  //         this.handler.res("No Agreements For you")
  //       }
  //       this.loader = false;
  //     }, err => {
  //       this.loader = false;
  //       this.handler.err(err)
  //     })
  //     this.auth.getConfig();
  //   }
  // }

  openBottomSheet(res) {
    this.typeName = res.name;
    this.type = res.type;
  }

  ionViewWillLeave() {
    sessionStorage.clear();
  }

  getAgreementType() {
    this.service.getAgreementType().subscribe(res => {
      this.agreement = res;
    })
  }
}
