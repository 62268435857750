import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/api-calls/data.service';

@Component({
  selector: 'app-get-medicine',
  templateUrl: './get-medicine.component.html',
  styleUrls: ['./get-medicine.component.scss']
})
export class GetMedicineComponent implements OnInit {

  medicines:any;
  loader : boolean = false;
  activeToggle: any;
 
  constructor(private service:DataService,private router:Router) { }

  ngOnInit() {
    this.loader=true;
    this.service.getMedicine().subscribe(res=>{
     this.medicines=res;
     this.loader=false;
    })
  }

  edit(id){
    this.router.navigate(['/edit-medicine', id]);
  }
 
}
