import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/api-calls/auth.service';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { DoctorSetupComponent } from '../doctor-setup/doctor-setup.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-doctor-add',
  templateUrl: './doctor-add.component.html',
  styleUrls: ['./doctor-add.component.scss']
})
export class DoctorAddComponent implements OnInit {
  alldepart: any = [];
  name;
  desc;
  code;
  departmentId;
  checkpointId;
  avgProcessingTime;
  doctoredit: boolean;
  loader;
  url;
  id;
  doctorObj: any = [];
  languageCode: string;
  items: any = [];
  dataSource: any;
  msgContent: string;
  avgTime;

  constructor(public data: DataService, private translate: TranslateService, public app: AppService, public auth: AuthService) { }

  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
  
    this.departmentGet();
    this.checkpoints();
    this.url = this.app.urlData();
    if (this.url != 'doctoradd') {
      this.doctoredit = true;
      this.id = this.app.urlData();
      this.data.getDoctorById(this.id).subscribe((res: any) => {
        this.doctorObj = res;
        this.id = res.id;
        this.code = res.shortCode;
        this.avgTime = res.avgProcessingTime;
        this.departmentId = res.departmentId;
        this.checkpointId = res.checkpoint.id;
        this.desc = res.description;
        this.name = res.name;
      }, err => {
        this.loader = false;
      })
    } else {
      console.log('jj')
    }

  }
  checkpoints() {
    this.data.getCheckpoints().subscribe(res => {
      this.loader = false;
      if (res != null) {
        this.items = res;
        // this.items.sort(function (a, b) {
        //   var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        //   if (nameA < nameB) { return -1; }
        //   if (nameA > nameB) { return 1; }
        //   return 0;
        // })
        // this.dataSource = new MatTableDataSource(this.items)
      }
      else {
        this.msgContent = "No checkpoints";
      }

    })
  }
  departmentGet() {
    this.data.departmentGetAll().subscribe((res: any) => {
      this.alldepart = res;
    })
  }

  doctorCreate() {
    if (this.checkpointId != undefined && this.checkpointId != '') {
      if (this.departmentId != undefined && this.departmentId != '') {
    this.loader = true;
    
    var post = {
      "name": this.name.toUpperCase(),
      "shortCode": this.code,
      "avgProcessingTime": this.avgTime,
      "description": this.desc,
      "departmentId": Number(this.departmentId),
      // "checkpoint":Number(this.departmentId),
      "checkpoint": {
        "id": this.checkpointId
      }
    }
    this.data.doctorCreate(post).subscribe((res: any) => {
      this.loader = false;
      this.app.openPage('doctorsetup');
      // this.app.openSnackBar('Doctor Created Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Doctor Created Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })

    }, err => {
      this.loader = false;
    })
  }else{
    return this.translate.use(this.languageCode).subscribe(() => {
      this.translate.get("Please enter the required fields",).subscribe((translation: string) => {
        this.app.openSnackBar(translation, "error-snackbar");
      });
    })
  }
    }else{
      return this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please enter the required fields",).subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar");
        });
      })
    }
  }
  cancel() {
    this.app.openPage('doctorsetup')
  }
  doctorUpdate() {
    this.loader = true;

    this.doctorObj.name = this.name.toUpperCase();
    this.doctorObj.id = this.id;
    this.doctorObj.shortCode = this.code;
    this.doctorObj.avgProcessingTime = this.avgTime;
    this.doctorObj.checkpoint = {
      "id": this.checkpointId
    };
    this.doctorObj.description = this.desc;
    this.doctorObj.departmentId = this.departmentId;
    this.data.doctorPutById(this.id, this.doctorObj).subscribe(res => {
      this.loader = false;
      this.app.openPage('doctorsetup');
      // this.app.openSnackBar('Doctor Updated Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Doctor Updated Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })
    }, err => {
      this.loader = false;
    })

  }


}
