import { Injectable } from '@angular/core';
import { ToastService } from './toast.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class HandlerService {
    constructor(public toast: ToastService,
        public router: Router) { }

      
    user;
    i=0;
    j=0;
    check:boolean=false;
    serviceRequests:any=[];
    cloneServiceRequests:any=[];
    quoteId;
    qouteDate;
    quoteOrg;
    deny = 0 ;
    quoteInd:boolean=false;
    quoteObj:any={
        orgId:" ",
        name:" ",
        emailId:" ",
        orgInd:false,
        contactNbrMob:null
    };
    rescheduleObj:any;
    contractor;
    menu:any;
    docData:any;
    filterDate: any;
    filterDate1: any;
    loader:boolean;
    err(err: any) {
        if (err.error) {
            let error: any = err.error;
            if(error.errorMessage!=null){
                this.toast.open(error.errorMessage, 'err');
            }else{
                this.toast.open(error.msg, 'err');
            }
           
        }
        if (err.status == 401)
            this.fourNotOne();
        if (err.status == 500)
            this.toast.open("Internal Server Error", 'err');
         if (err.status == 0)
            this.toast.open("Unable to reach server", 'err');
    }
    error(err: any) {
        this.toast.open(err, 'err');
    }

    loginRes(res) {
        localStorage.setItem('hashKey', res.hashKey);
        localStorage.setItem('currentUserId', res.userId);
        localStorage.setItem('organizationId', res.organizationId);
    }

    res(msg) {
        this.toast.open(msg, 'res');
    }

    fourNotOne() {
        localStorage.clear();
        sessionStorage.clear();
        this.user = undefined;
        this.router.navigate(['/start']);
    }
    staticError(err: any){
        this.toast.openPay(err, 'err')
      }

}