import { Component, OnInit, HostListener,AfterViewInit} from "@angular/core";
import { DataService } from "src/api-calls/data.service";
import { AppService } from "../app.service";
import { DragulaService } from "ng2-dragula";
import { MatDialog } from "@angular/material";
import { MatTableDataSource, MatSort } from '@angular/material';
import { AssistancepopComponent } from "../assistancepop/assistancepop.component";
import { AuthService } from "src/api-calls/auth.service";
import { TranslateService } from '@ngx-translate/core';

declare var getBankCheck: any;
declare var getHealthCheckLabels: any;

@Component({
  selector: "app-patients-check-in",
  templateUrl: "./patients-check-in.component.html",
  styleUrls: ["./patients-check-in.component.scss"],
  providers: [DragulaService],
})


export class PatientsCheckInComponent implements OnInit, AfterViewInit {
  sort: any;
  ngAfterViewInit() {
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }
  items: any = [];
  displayedColumns = ['radio', 'patientRefId', 'name'];
  selectedPatientId: string = '';
  name;
  dataSource: any;
  description;
  token;
  s1;
  s2;
  mobile;
  patientId;
  workFlowId;
  allergic = [
    { name: "Yes", id: true },
    { name: "No", id: false },
  ];
  add;
  loader: boolean = false;
  loader1: boolean = false;
  getWorkFlow: any = [];
  selectedItem: any = [];
  checkpoints: any = [];
  pushcheckpoints: any = [];
  customWorkId;
  metadata: string;
  address: any;
  enable: boolean = true;
  checkObject;
  countrynum = "+91";
  phone;
  refId;
  refIdCheck;
  reportingTime = "9.00";
  showtime: boolean = false;
  showcalendar: boolean = false;
  // showname:boolean = false;
  showToken: string = "";
  sa: any;
  appConfig: any;
  maxName;
  maxToken;
  maxMetadata;
  maxPatientRef;
  language = [
    { name: "English", value: "101" },
    { name: "Hindi", value: "105" },
    { name: "Tamil", value: "103" },
    { name: "Bengali", value: "107" },
  ];
  preflang = "101";
  patients: any[] = [
    { name: "In-Patient", id: "2", disable: true },
    { name: "Out-Patient", id: "1", disable: false },
    { name: "MHC", id: "3", disable: true },
    { name: "BedSide", id: "4", disable: true },
  ];
  patientType = this.patients[1].id;
  aged: boolean;
  wheeled: boolean;
  callRequired: boolean;
  selectdate = new Date();
  todayDate = new Date();
  // ActivityId;
  cloneCheck: any;
  receiptNbr;
  wardNbr;
  bedSide: any;
  doctorName;
  age;
  gender = "Male";
  height;
  weight;
  public setApptTime: boolean = false;
  showAppt: boolean = false;
  patientObject: any = [];
  workqId;
  workqTime;
  category: boolean = false;
  newPatient: boolean = false;
  patientsdetails: boolean = false;
  fasting: boolean = false;
  documentNbr;
  secMobile;
  secCntryNum = "+91";
  health;
  labels: any;
  clinica: boolean;
  hwind: boolean;
  entype: any = ([] = [
    { name: "Appointment", id: "1" },
    { name: "Walk-In", id: "2" },
  ]);
  // visitype: any = ([] = [
  //   { name: "Consultation", id: "1" },
  //   { name: "Treatment", id: "2" },
  // ]);
  mulCard: any;
  alldepart: any = [];
  deptId;
  entryType = "Walk-In";
  department;
  docName: any = [];
  available: boolean = false;
  tagId: any;
  languageCode: string;
  doctorId: any;
  entity: any;
  mobileCheck: any;
  patientDetails: any;
  patientUhId: any;
  showmobile: boolean = false;
  showUh: boolean = false;
  showname: boolean = false;
  showsno: boolean = false;
  showprelan: boolean = false;
  showto: boolean = false;
  showreceipt: boolean = false;
  showdoc: boolean = false;
  showmeta: boolean = false;
  showage: boolean = false;
  showsex: boolean = false;
  showheight: boolean = false;
  showwei: boolean = false;
  showenttp: boolean = false;
  showdept: boolean = false;
  showdoct: boolean = false;
  showadd: boolean;
  tamilfontsize: string;
  visitType: any;
  visitype: any = [];
  checkPOint: any = [];
  doctId: any;
  docCheckPoint: any;
  sData: any;
  status: any;
  isInputHighlighted: boolean;
  issue: any;
  issueName: any;
  statusName: any;
  smoke: any;
  alcohol: any;
  betelAndAricaNut: any;
  pregnancy: any;
  theMother: any;
  monthlyDeduction: any;
  ulcer: any;
  allergy: any;
  proceedelement:any;
  dizzy: any;
  palpitation: any;
  sugar: any;
  chestPain: any;
  thyroid: any;
  oralMedications: any;
  fever: any;
  cough: any;
  throatPain: any;
  wheezing: any;
  bleeding: any;
  userhealthissue: any;
  userhealthconditions: any;
  userHabit: any;

  constructor(
    public data: DataService,
    public app: AppService,
    public dialog: MatDialog,
    public dragulaService: DragulaService,
    public auth: AuthService,
    private translate: TranslateService,
  ) {
    dragulaService.createGroup("DRAGULA_FACTS", {
      moves: function (el: any, container: any, handle: any): any {
        if (el.classList.contains("king")) {
          return false;
        }
        return true;
      },
    });
  }

  async ngOnInit() {
    this.app.loader1 = true;
    if (localStorage.getItem("lang") == "ta") {
      console.log(localStorage.getItem("lang") == "ta")
      this.tamilfontsize = 'tamilspace'
    } else {
      this.tamilfontsize = 'space'
    }

    this.sData = await this.data.staticData().toPromise();
    this.sData = this.sData.filter(data => data.typeId == 22);
    this.sData = this.sData[0].values;
    this.languageCode = localStorage.getItem('lang');
    this.reportingTime = "9.00";
    this.health = sessionStorage.getItem("health");
    if (this.health == "true") {
      this.labels = new getHealthCheckLabels();
    }
    if (this.health == "false") {
      this.patients = [
        { name: "Withdrawal", id: "2" },
        { name: "Deposit", id: "1" },
        { name: "Paperwork", id: "3" },
        { name: "Requisition", id: "4" },
      ];
      this.labels = new getBankCheck();
    }
    this.app.aged = false;
    this.app.callRequired = false;
    this.app.wheeled = false;
    this.app.nri = false;
    this.app.emrgPat = false;
    this.app.vipPat = false;
    // this.loader = true;
    this.data.getCheckpointsPatientAdd(1).subscribe((res) => {
      this.checkObject = res;
      this.checkObject = this.checkObject.filter(res => {
        return res.activeInd == true;
      })
      this.cloneCheck = JSON.parse(JSON.stringify(res));
      this.loader = false;
      this.app.loader1 = false;
    });
    this.departmentGet();
    this.visitTypeStatic();
    let x = JSON.parse(localStorage.getItem("config"));
    let a = x.find((res) => res.shortCdConst == "TKNAUTO");
    this.showToken = a.userValue;
    let b = x.find((res) => res.shortCdConst == "PATNM");
    this.maxName = b.userValue;
    let c = x.find((res) => res.shortCdConst == "PATTKNNO");
    this.maxToken = c.userValue;
    let d = x.find((res) => res.shortCdConst == "PATMTDA");
    this.maxMetadata = d.userValue;
    let e = x.find((res) => res.shortCdConst == "PATRFID");
    this.maxPatientRef = e.userValue;
    let f = x.find((res) => res.shortCdConst == "MCPTCD");
    if (f.userValue == "true" || f.userValue == true) {
      this.mulCard = true;
    } else {
      this.mulCard = false;
    }
    let h = x.find((res) => res.shortCdConst == "CLNQENBL");
    if (h) this.clinica = JSON.parse(h.userValue);
    let w = x.find((res) => res.shortCdConst == "HWIND");
    if (w) this.hwind = JSON.parse(w.userValue);




    let i = x.find((res) => res.shortCdConst == "MBNO");
    if (i && i.userValue == "true" || i.userValue == true) {
      this.showmobile = true;
    } else {
      this.showmobile = false;
    }
    let k = x.find((res) => res.shortCdConst == "UHTE");
    if (k && k.userValue == "true" || k.userValue == true) {
      this.showUh = true;
    } else {
      this.showUh = false;
    }
    let l = x.find((res) => res.shortCdConst == "NA");
    if (l && l.userValue == "true" || l.userValue == true) {
      this.showname = true;
    } else {
      this.showname = false;
    }
    let m = x.find((res) => res.shortCdConst == "SECNO");
    if (m && m.userValue == "true" || m.userValue == true) {
      this.showsno = true;
    } else {
      this.showsno = false;
    }
    let n = x.find((res) => res.shortCdConst == "PRLANG");
    if (n && n.userValue == "true" || n.userValue == true) {
      this.showprelan = true;
    } else {
      this.showprelan = false;
    }
    let o = x.find((res) => res.shortCdConst == "TE");
    if (o && o.userValue == "true" || o.userValue == true) {
      this.showto = true;
    } else {
      this.showto = false;
    }
    let p = x.find((res) => res.shortCdConst == "RT");
    if (p && p.userValue == "true" || p.userValue == true) {
      this.showreceipt = true;
    } else {
      this.showreceipt = false;
    }
    let q = x.find((res) => res.shortCdConst == "DC");
    if (q && q.userValue == "true" || q.userValue == true) {
      this.showdoc = true;
    } else {
      this.showdoc = false;
    }
    let r = x.find((res) => res.shortCdConst == "MADA");
    if (r && r.userValue == "true" || r.userValue == true) {
      this.showmeta = true;
    } else {
      this.showmeta = false;
    }
    let s = x.find((res) => res.shortCdConst == "AG");
    if (s && s.userValue == "true" || s.userValue == true) {
      this.showage = true;
    } else {
      this.showage = false;
    }
    let t = x.find((res) => res.shortCdConst == "SE");
    if (t && t.userValue == "true" || t.userValue == true) {
      this.showsex = true;
    } else {
      this.showsex = false;
    }
    let u = x.find((res) => res.shortCdConst == "HE");
    if (u && u.userValue == "true" || u.userValue == true) {
      this.showheight = true;
    } else {
      this.showheight = false;
    }
    let va = x.find((res) => res.shortCdConst == "WE");
    if (va && va.userValue == "true" || va.userValue == true) {
      this.showwei = true;
    } else {
      this.showwei = false;
    }
    let vb = x.find((res) => res.shortCdConst == "EYTP");
    if (vb && vb.userValue == "true" || vb.userValue == true) {
      this.showenttp = true;
    } else {
      this.showenttp = false;
    }
    let vc = x.find((res) => res.shortCdConst == "DP");
    if (vc && vc.userValue == "true" || vc.userValue == true) {
      this.showdept = true;
    } else {
      this.showdept = false;
    }
    let vd = x.find((res) => res.shortCdConst == "DOCNA");
    if (vd && vd.userValue == "true" || vd.userValue == true) {
      this.showdoct = true;
    } else {
      this.showdoct = false;
    }
    let ve = x.find((res) => res.shortCdConst == "ADD");
    if (ve && ve.userValue == "true" || vd.userValue == true) {
      this.showadd = true;
    } else {
      this.showadd = false;
    }

  }

  switchLanguage(value) {
    this.preflang = value;
  }

  async save() {
    var metadata;
    if (this.metadata == undefined || this.metadata == "") {
      if (this.getWorkFlow.shortCd != undefined) {
        metadata = this.getWorkFlow.shortCd;
      }
    } else {
      if (this.getWorkFlow.shortCd != undefined) {
        metadata = this.metadata + "|" + this.getWorkFlow.shortCd;
      } else {
        metadata = this.metadata;
      }
    }

    this.selectedItem.map((res) => {
      if (res.cpType == 2) {
        this.category = true;
      }
    });
    if (this.name == undefined || this.name == "") {
      // return this.app.openSnackBar(
      //   "Please enter the required fields",
      //   "error-snackbar"
      // );
      return this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please enter the required fields",).subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar");
        });
      })
    } if (this.department == undefined || this.department == "") {
      return this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please enter the required fields",).subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar");
        });
      })

    } if (this.doctorName == undefined || this.doctorName == "") {
      return this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please enter the required fields",).subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar");
        });
      })

    } else {
      if (this.showcalendar == true || this.category == true) {
        this.reportingTime = "9.00";
        if (this.reportingTime == undefined || this.reportingTime == "") {
          // return this.app.openSnackBar(
          //   "Please enter the required fields",
          //   "error-snackbar"
          // );
          return this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get("Please enter the required fields",).subscribe((translation: string) => {
              this.app.openSnackBar(translation, "error-snackbar");
            });
          })
        } else {
          // this.reportingTime = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2) + ' ' + this.reportingTime;
          this.app.loader1 = true;
          let secondNumb;
          if (
            this.mobile != null ||
            this.mobile != undefined ||
            this.mobile != ""
          ) {
            this.phone = this.countrynum + this.mobile;
          }
          if (this.mobile == null) {
            this.phone = this.countrynum + undefined;
          }
          if (this.mobile == "") {
            this.phone = this.countrynum + undefined;
          }
          if (
            this.secMobile != null ||
            this.secMobile != undefined ||
            this.secMobile != ""
          ) {
            secondNumb = this.secCntryNum + this.secMobile;
          }
          if (this.secMobile == null) {
            secondNumb = this.secCntryNum + undefined;
          }
          if (this.secMobile == "") {
            secondNumb = this.secCntryNum + undefined;
          }

          var post = {
            name: this.name.toUpperCase(),
            contactNbr: this.phone,
            tokenNbr: this.token,
            metadata: metadata,
            address: this.address,
            prefLang: this.preflang,
            callRequired: this.app.callRequired,
            aged: this.app.aged,
            wheeled: this.app.wheeled,
            internationalPatient: this.app.nri,
            emrgPat: this.app.emrgPat,
            vipPat: this.app.vipPat,
            age: this.age,
            // tagId:this.tagId,
            category: 5,
            height: this.height,
            weight: this.weight,
            doctorName: this.doctorName,
            doctorId: this.doctorId,
            receiptNbr: this.receiptNbr,
            wardNbr: this.wardNbr,
            documentNbr: this.documentNbr,
            newPatient: this.newPatient,
            fasting: this.fasting,
            department: this.department,
            secContactNbr: secondNumb,
          };
          if (this.refId != null && this.refId != undefined && this.refId != "") {
            Object.assign(post, { patientRefId: this.refId });
          }
          if (this.entryType) {
            this.entype.map((res) => {
              if (res.name == this.entryType) {
                Object.assign(post, { entryType: res.id });
              }
            });
          }
          if (this.visitType) {
            this.visitype.map((res) => {
              if (res.name == this.visitType) {
                Object.assign(post, { visitType: res.id });
              }
            });
          }
          if (this.gender)
            Object.assign(post, { gender: this.gender.toUpperCase() });
          if (this.patientType != 4) {
            Object.assign(post, { entityType: this.patientType });
          }
          if (this.patientType == 4) {
            Object.assign(post, { entityType: "2", bedSide: "true" });
          }
          if (this.showcalendar) {
            Object.assign(post, {
              expctdReportingTimeMins: this.reportingTime,
            });
          }
          this.selectedItem.map((res, i) => {
            this.selectedItem[i].stepNbr = i + 1;
          });

          if (this.name) {
            this.saveCheck(post);
          }
        }
      } else {
        let secondNumb;
        this.app.loader1 = true;
        if (
          this.mobile != null ||
          this.mobile != undefined ||
          this.mobile != ""
        ) {
          this.phone = this.countrynum + this.mobile;
        }
        if (this.mobile == null) {
          this.phone = this.countrynum + undefined;
        }
        if (this.mobile == "") {
          this.phone = this.countrynum + undefined;
        }
        if (
          this.secMobile != null ||
          this.secMobile != undefined ||
          this.secMobile != ""
        ) {
          secondNumb = this.secCntryNum + this.secMobile;
        }
        if (this.secMobile == null) {
          secondNumb = this.secCntryNum + undefined;
        }
        if (this.secMobile == "") {
          secondNumb = this.secCntryNum + undefined;
        }

        const userHabits = {
          "smoke": this.smoke,
          "alcohol": this.alcohol,
          "betelAndAricaNut": this.betelAndAricaNut,
          "habitsNotes": this.userHabit,
        }
        const userHealthConditions = {
          "pregnancy": this.pregnancy,
          "theMother": this.theMother,
          "monthlyDeduction": this.monthlyDeduction,
          "healthConditionsNotes": this.userhealthconditions,
        }
        const userHealthIssues = {
          "ulcer": this.ulcer,
          "allergy": this.allergy,
          "dizzy": this.dizzy,
          "palpitation": this.palpitation,
          "sugar": this.sugar,
          "chestPain": this.chestPain,
          "thyroid": this.thyroid,
          "oralMedications": this.oralMedications,
          "fever": this.fever,
          "cough": this.cough,
          "throatPain": this.throatPain,
          "wheezing": this.wheezing,
          "bleeding": this.bleeding,
          "healthIssuesNotes": this.userhealthissue,
        };
        let post: any = {
          name: this.name.toUpperCase(),
          contactNbr: this.phone,
          tokenNbr: this.token,
          metadata: metadata,
          address: this.address,
          age: this.age,
          issue: this.issue,
          height: this.height,
          weight: this.weight,
          prefLang: this.preflang,
          callRequired: this.app.callRequired,
          aged: this.app.aged,
          wheeled: this.app.wheeled,
          internationalPatient: this.app.nri,
          emrgPat: this.app.emrgPat,
          vipPat: this.app.vipPat,
          category: 5,
          doctorName: this.doctorName,
          doctorId: this.doctorId,
          receiptNbr: this.receiptNbr,
          wardNbr: this.wardNbr,
          documentNbr: this.documentNbr,
          newPatient: this.newPatient,
          fasting: this.fasting,
          department: this.department,
          secContactNbr: secondNumb,
        };
        console.log(post)
        if (this.clinica) {
          Object.assign(post, {
            userHabits: userHabits,
            userHealthConditions: userHealthConditions,
            userHealthIssues: userHealthIssues,
          });
        }
        if (this.refId != null && this.refId != undefined && this.refId != "") {
          Object.assign(post, { patientRefId: this.refId });
        }
        if (this.entryType) {
          this.entype.map((res) => {
            if (res.name == this.entryType) {
              Object.assign(post, { entryType: res.id });
            }
          });
        }
        if (this.visitType) {
          this.visitype.map((res) => {
            if (res.name == this.visitType) {
              Object.assign(post, { visitType: res.id });
            }
          });
        }
        if (this.gender)
          Object.assign(post, { gender: this.gender.toUpperCase() });
        if (this.patientType != 4) {
          Object.assign(post, { entityType: this.patientType });
        }
        if (this.patientType == 4) {
          Object.assign(post, { entityType: "2", bedSide: "true" });
        }
        this.selectedItem.map((res, i) => {
          this.selectedItem[i].stepNbr = i + 1;
        });

        if (this.name) {
          this.saveCheck(post);
        }
      }
    }
  }

  saveCheck(post) {
    this.data.patientCheckin(post).toPromise().then((res: any) => {
      this.patientDetails = res;
      this.patientId = this.patientDetails.id;
      this.patientUhId = this.patientDetails.patientRefId
      if (this.patientId != undefined) {
        if (this.getWorkFlow.name != undefined) {
          this.name = this.getWorkFlow.name;
        }
        let post1 = {
          name: this.name,
          description: this.name,
          checkpoints: [this.docCheckPoint],
          predefined: false,
          entityId: this.patientId,
        };
        this.data.createWorkFlow(post1).toPromise().then((res: any) => {
          this.customWorkId = res.id;
          if (this.patientId != undefined) {
            this.data.patientAssignWorkFlow(this.customWorkId, this.patientId, post).subscribe((res) => {
              this.app.loader1 = false;
              // this.app.openSnackBar(
              //   " Patient Checked-In Successfully !!",
              //   "success-snackbar"
              // );
              this.translate.use(this.languageCode).subscribe(() => {
                this.translate.get('patient CheckedIn Successfully').subscribe((translation: string) => {
                  const message = `${translation} | Patient Ref ID: ${this.patientUhId} !!!`;
                  this.app.openSnackBar(message, 'success-snackbar');
                });
              });

              this.name = "";
              this.description = "";
              this.mobile = "";
              this.secMobile = "";
              this.token = "";
              this.metadata = "";
              this.address = "";
              this.age = "";
              this.tagId = "";
              this.gender = "";
              this.height = "";
              this.weight = "";
              this.workFlowId = "";
              this.enable = true;
              this.selectedItem = [];

              this.checkObject = JSON.parse(
                JSON.stringify(this.cloneCheck)
              );

              this.refId = "";
              this.reportingTime = "";
              this.patientType = this.patients[1].id;
              this.app.aged = false;
              this.app.wheeled = false;
              this.app.callRequired = false;
              this.app.nri = false;
              this.app.vipPat = false;
              this.app.emrgPat = false;
              this.showtime = false;
              this.showcalendar = false;
              this.category = false;
              this.newPatient = false;
              this.fasting = false;
              this.department = "";
              this.entryType = "";
              this.visitType = "";
              this.wardNbr = "";
              this.receiptNbr = "";
              this.doctorName = "";
              this.doctorId = "";
              this.getWorkFlow = [];
              this.documentNbr = "";
            },
              (err) => {
                // this.app.openSnackBar(err, "error-snackbar");
                this.translate.use(this.languageCode).subscribe(() => {
                  this.translate.get(err).subscribe((translation: string) => {
                    this.app.openSnackBar(translation, "error-snackbar");
                  });
                });
                this.app.loader1 = false;
              }
            );
          }
        });
      }
    })
      .catch((res) => {
        // this.app.openSnackBar(res.error.msg, "error-snackbar")
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(res.error.msg).subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar");
          });
        }),
          (this.app.loader1 = false);
      });
  }

  async getWorkId(id) {
    this.loader = true;
    this.workFlowId = id;
    this.checkpoints = await this.data.getCheckpoints().toPromise();
    this.getWorkFlow = await this.data
      .getWorkFlowsById(this.workFlowId)
      .toPromise();
    this.loader = false;
    if (this.getWorkFlow) {
      this.selectedItem = this.getWorkFlow.checkpoints;
      this.checkpoints = this.checkpoints.filter(
        (res) => !this.selectedItem.find((val) => res.id === val.id)
      );
    }
  }

  async getCheck(id) {
    let mapData: any = [];
    this.workqId = id;
    mapData = this.checkObject.filter((res) => {
      return res.id == id;
    });
    this.selectedItem.push(mapData[0]);
    this.getReportingTIme();
    this.selectedItem.map((res) => {
      if (res.checkPointPositionId == 1 || res.checkPointPositionId == 2) {
        Object.assign(res, { cla: "king" });
      } else {
        Object.assign(res, { cla: "john" });
      }
    });
    let order = [1, 3, 2];
    this.selectedItem.sort(function (a, b) {
      return (
        order.indexOf(a.checkPointPositionId) -
        order.indexOf(b.checkPointPositionId)
      );
    });
    this.checkpoints = this.checkpoints.filter(
      (res) => !this.selectedItem.find((val) => res.id === val.id)
    );
    this.checkObject = this.checkObject.filter(
      (res) => !this.selectedItem.find((val) => res.id === val.id)
    );
  }

  async getback(id) {
    let mapData: any = [];
    mapData = this.selectedItem.filter((res) => {
      return res.id == id;
    });
    this.checkpoints.push(mapData[0]);
    this.checkObject.push(mapData[0]);
    this.selectedItem = this.selectedItem.filter(
      (res) => !this.checkpoints.find((val) => res.id === val.id)
    );
  }

  async custom() {
    this.loader = true;
    this.add = ""
    this.selectedItem = [];
    this.data.getWorkFlows().subscribe((res) => {
      this.items = res;
      this.loader = false;
    });
    this.enable = false;
  }

  goCheckPoint() {
    this.loader = true;
    this.items = [];
    this.selectedItem = [];
    this.checkpoints = [];
    this.workFlowId = "";
    this.data.getCheckpointsPatientAdd(this.patientType).subscribe((res) => {
      this.checkObject = res;
      this.loader = false;
    });
    this.enable = true;
  }

  cancel() {
    this.name = "";
    this.description = "";
    this.mobile = "";
    this.secMobile = "";
    this.token = "";
    this.metadata = "";
    this.address = "";
    this.age = "";
    this.tagId = "";
    this.gender = "";
    this.height = "";
    this.weight = "";
    this.workFlowId = "";
    this.enable = true;
    this.refId = "";
    this.reportingTime = "";
    this.app.aged = false;
    this.app.wheeled = false;
    this.app.callRequired = false;
    this.app.emrgPat = false;
    this.app.vipPat = false;
    this.app.nri = false;
    this.category = false;
    this.newPatient = false;
    this.fasting = false;
    this.department = "";
    this.entryType = "";
    this.visitType = "";
    this.app.nri = false;
    this.wardNbr = "";
    this.receiptNbr = "";
    this.doctorName = "";
    this.doctorId = "";
    this.patientType = this.patients[1].id;
    this.showcalendar = false;
    this.showtime = false;
    this.smoke = false;
    this.alcohol = false;
    this.betelAndAricaNut = false;
    this.pregnancy = false;
    this.monthlyDeduction = false;
    this.theMother = false;
    this.bleeding = false;
    this.ulcer = false;
    this.allergy = false;
    this.dizzy = false;
    this.palpitation = false;
    this.sugar = false;
    this.chestPain = false;
    this.thyroid = false;
    this.oralMedications = false;
    this.fever = false;
    this.cough = false;
    this.wheezing = false;
    this.throatPain = false;
    this.getWorkFlow = [];
    this.documentNbr = "";
    this.checkObject = JSON.parse(JSON.stringify(this.cloneCheck));
  }

  ngDoCheck(): void {
    // if (this.patientType == 2) {
    //   this.showtime = true;
    //   this.showcalendar = true;
    // }
    // if (this.patientType == 4) {
    //   this.showcalendar = false;
    //   this.showtime = true;
    // }
    // if (this.patientType == 1 || this.patientType == 3) {
    //   this.showcalendar = false;
    //   this.showtime = false;
    // }

    let apptcap = JSON.parse(localStorage.getItem("capability"));
    if (apptcap.length > 0) {
      apptcap.map((res) => {
        if (res.includes("APTTMORD")) {
          this.setApptTime = true;
        }
      });
    }
  }

  openDialog(id): void {
    const dialogRef = this.dialog.open(AssistancepopComponent, {
      data: id,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "1") {
        this.reportingTime = "";
        this.patientType = this.patients[1].id;
        this.app.aged = false;
        this.app.wheeled = false;
        this.app.callRequired = false;
        this.app.nri = false;
        this.app.emrgPat = false;
        this.app.vipPat = false;
        this.showtime = false;
        this.showcalendar = false;
        this.wardNbr = "";
        this.receiptNbr = "";
        this.doctorName = "";
        this.doctorId = "";
        this.name = "";
        this.description = "";
        this.mobile = "";
        this.secMobile = "";
        this.token = "";
        this.metadata = "";
        this.address = "";
        this.age = "";
        this.tagId = "";
        this.gender = "";
        this.height = "";
        this.weight = "";
        this.enable = true;
        this.documentNbr = "";
        this.category = false;
      }
    });
  }

  focusOutFunction(data) {
    if (this.refId || this.mobile) {
      var serviceId;
      if (this.patientType == 1 || this.patientType == 3) {
        serviceId = 1;
      }
      if (this.patientType == 2 || this.patientType == 4) {
        serviceId = 2;
      }
      this.data.getPatientDetails(this.refId, serviceId, this.mobile).subscribe((res) => {
        if (res) {
          this.patientObject = res;
          this.name = this.patientObject.name;
          this.documentNbr = this.patientObject.documentNbr;
          if (this.patientObject.entityType != 2) {
            var len = this.patientObject.contactNbr.length;
            if (this.patientObject.secContactNbr) {
              var len1 = this.patientObject.secContactNbr.length;
              len1 = len1 - 10;
            }
            len = len - 10;
            if (len1) {
              this.secCntryNum = this.patientObject.secContactNbr.slice(
                0,
                len1
              );
              this.secMobile = this.patientObject.secContactNbr.slice(-10);
            }
            this.countrynum = this.patientObject.contactNbr.slice(0, len);
            this.mobile = this.patientObject.contactNbr.slice(-10);
          }
          this.refId = this.patientObject.patientRefId;
          this.age = this.patientObject.age;
          this.gender = this.patientObject.gender;
          this.height = this.patientObject.height;
          // this.newPatient = this.patientObject.newPatient;
          this.fasting = this.patientObject.fasting;
          this.department = this.patientObject.department;
          if (this.patientObject.entryType) {
            this.entype.map((res) => {
              if (res.id == this.patientObject.entryType) {
                this.entryType = res.name;
              }
            });
          }
          if (this.patientObject.visitType) {
            this.visitype.map((res) => {
              if (res.id == this.patientObject.visitType) {
                this.visitType = res.name;
              }
            });
          }
          this.weight = this.patientObject.weight;
          this.metadata = this.patientObject.metadata;
          this.address = this.patientObject.address;
          if (this.patientObject.expctdReportingTime) {
            let r = this.patientObject.expctdReportingTime;
            this.reportingTime = r.slice(11, 16);
            this.selectdate = new Date(r);
          }
          this.wardNbr = this.patientObject.wardNbr;
          this.receiptNbr = this.patientObject.receiptNbr;
          this.doctorName = this.patientObject.doctorName;
          this.doctorId = this.patientObject.doctorId;
          this.app.aged = this.patientObject.aged;
          this.app.wheeled = this.patientObject.wheeled;
          this.app.nri = this.patientObject.internationalPatient;
          this.app.callRequired = this.patientObject.callRequired;
        }
      });
    } 
  }

  focusOutFunction1(data) {
    if (this.refId || this.mobile) {
      var serviceId;
      if (this.patientType == 1 || this.patientType == 3) {
        serviceId = 1;
      }
      if (this.patientType == 2 || this.patientType == 4) {
        serviceId = 2;
      }
      this.data.getPatientDetail(serviceId, this.mobile).subscribe((res:any) => {
        if (res) {
          this.patientsdetails = true;
          this.patientObject = res;
          this.dataSource = new MatTableDataSource(this.patientObject)
        }
      });
    } 
  }
  setvalue(a){
    this.proceedelement =a;
  }
  view(){
    if (this.proceedelement == undefined || this.proceedelement == ""|| this.proceedelement == null) {
      return this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select Patient ",).subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar");
        });
      })
    }else{
   const element = this.proceedelement ;
    this.patientsdetails =false;
    console.log(element);
    this.name = element.name;
    this.documentNbr = element.documentNbr;
    if (element.entityType != 2) {
      var len = element.contactNbr.length;
      if (element.secContactNbr) {
        var len1 = element.secContactNbr.length;
        len1 = len1 - 10;
      }
      len = len - 10;
      if (len1) {
        this.secCntryNum =element.secContactNbr.slice(
          0,
          len1
        );
        this.secMobile = element.secContactNbr.slice(-10);
      }
      this.countrynum = element.contactNbr.slice(0, len);
      this.mobile = element.contactNbr.slice(-10);
    }
    this.refId =element.patientRefId;
    this.age = element.age;
    this.gender = element.gender;
    this.height = element.height;
    // this.newPatient = this.patientObject.newPatient;
    this.fasting = element.fasting;
    this.department =element.department;
    if (element.entryType) {
      this.entype.map((res) => {
        if (res.id == element.entryType) {
          this.entryType = res.name;
        }
      });
    }
    if (element.visitType) {
      this.visitype.map((res) => {
        if (res.id == element.visitType) {
          this.visitType = res.name;
        }
      });
    }
    this.weight = element.weight;
    this.metadata = element.metadata;
    this.address = element.address;
    if (element.expctdReportingTime) {
      let r = element.expctdReportingTime;
      this.reportingTime = r.slice(11, 16);
      this.selectdate = new Date(r);
    }
    this.wardNbr = element.wardNbr;
    this.receiptNbr = element.receiptNbr;
    this.doctorName =element.doctorName;
    this.doctorId = element.doctorId;
    this.app.aged = element.aged;
    this.app.wheeled = element.wheeled;
    this.app.nri = element.internationalPatient;
    this.app.callRequired =element.callRequired;
    this.proceedelement = null;
  }
}
  close(){
    this.patientsdetails =false;
  }
  newAdd(){
    if(!this.proceedelement){
      this.patientsdetails =false;
    }

  }
  checkPrevEntity() {
    if (this.refId) {
      var post = {};
      if (this.refId) {
        Object.assign(post, { patientRefId: this.refId })
      }
      if (this.mobile) {
        Object.assign(post, { contactNbr: this.mobile })
      }


      //     var serviceId;
      //     if (this.patientType == 1 || this.patientType == 3) {
      //       serviceId = 1;
      //     }
      //     if (this.patientType == 2 || this.patientType == 4) {
      //       serviceId = 2;
      //     }
      //     // this.data.getPatientDetails(this.refId, serviceId).subscribe(res => {
      //       if (res) {
      //         this.patientObject = res
      //         this.name = this.patientObject.name;
      //         this.age = this.patientObject.age;
      //         this.gender = this.patientObject.gender;
      //         this.weight = this.patientObject.weight;
      //         this.height = this.patientObject.height;
      //         this.documentNbr = this.patientObject.documentNbr;
      //         if (this.patientObject.entityType != 2) {
      //           var len = this.patientObject.contactNbr.length;
      //           len = len - 10;
      //           this.countrynum = this.patientObject.contactNbr.slice(0, len);
      //           this.mobile = this.patientObject.contactNbr.slice(-10);
      //         }
      //         this.refId = this.patientObject.patientRefId;
      //         this.age = this.patientObject.age;
      //         this.gender = this.patientObject.gender;
      //         this.height = this.patientObject.height;
      //         this.weight = this.patientObject.weight;
      //         this.metadata = this.patientObject.metadata;
      //         if (this.patientObject.expctdReportingTime) {
      //           let r = this.patientObject.expctdReportingTime
      //           this.reportingTime = r.slice(11, 16);
      //           this.selectdate = new Date(r)
      //         }
      //         this.wardNbr = this.patientObject.wardNbr;
      //         this.receiptNbr = this.patientObject.receiptNbr;
      //         this.doctorName = this.patientObject.doctorName;
      //         this.app.loader1 = false;
      //       }
      //       else {
      //         this.app.loader1 = false;
      //       }
      //       err => {
      //         this.app.loader1 = false;
      //       }
      //     })
      this.data.searchPatient1(post).toPromise().then((res) => {
        this.mobileCheck = res;
        if (this.mobileCheck != null) {
          this.app.refIdObj = res;
          this.app.loader1 = false;
          this.openDialog(2);
        } else {
          this.focusOutFunction(res);
        }
        (err) => {
          this.app.loader1 = false;
        };
      });
    }
  }

  getAppt(data) {
    this.loader = true;
    this.data.getCheckpointsPatientAdd(data).subscribe((res) => {
      this.checkObject = res;
      this.loader = false;
    });
  }

  async datePost(id, date1: Date) {
    let checkGetById: any = await this.data.getCheckpointById(id).toPromise();
    let temp;

    if (this.patientType == 2 || checkGetById.cpType == 2) {
      let date =
        date1.getFullYear() +
        "/" +
        ("0" + (date1.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + date1.getDate()).slice(-2);

      this.data.getAppointment(checkGetById.worq.id, date).subscribe(
        (res) => {
          //  if(res){
          //   this.reportingTime = res;
          //   temp = res;
          //   this.selectedItem.map(res => {
          //     if (res.id == id) {
          //       Object.assign(res, { apptTimeStr: date + ' ' + this.reportingTime, time: temp, date: date1 })
          //     }
          //   })
          // }
          // else{
          this.reportingTime = "9.00";
          // }
        },
        (err) => {
          this.reportingTime = "9.00";
          // if (err.status == 409) {
          //   this.selectedItem.map(res => {
          //     if (res.id == id) {
          //       Object.assign(res, { time: "", date: date1 })
          //     }
          //   })
          //   this.app.openSnackBar('Appointments Not Available for Selected Date', 'error-snackbar')
          // }
        }
      );
    }
  }

  timeChange(id, time, date1: Date) {
    let date =
      date1.getFullYear() +
      "/" +
      ("0" + (date1.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + date1.getDate()).slice(-2);
    this.reportingTime = time;
    let temp = time;
    this.selectedItem.map((res) => {
      if (res.id == id) {
        Object.assign(res, {
          apptTimeStr: date + " " + this.reportingTime,
          time: temp,
        });
      }
    });
  }

  async getReportingTIme() {
    let checkGetById: any = await this.data
      .getCheckpointById(this.workqId)
      .toPromise();
    if (this.patientType == 2 || checkGetById.cpType == 2) {
      if (checkGetById) {
        let date =
          this.selectdate.getFullYear() +
          "/" +
          ("0" + (this.selectdate.getMonth() + 1)).slice(-2) +
          "/" +
          ("0" + this.selectdate.getDate()).slice(-2);

        await this.data
          .getAppointment(checkGetById.worq.id, date)
          .toPromise()
          .then(
            (res) => {
              this.reportingTime = "9.00";
              // this.workqTime = res
              // if (this.reportingTime) {
              //   let date = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2);
              //   this.selectedItem.map(res => {
              //     if (res.id == this.workqId) {
              //       Object.assign(res, { apptTimeStr: date + ' ' + this.reportingTime, time: this.workqTime, date: this.selectdate })
              //     }
              //   })

              // }
            },
            (err) => {
              this.reportingTime = "9.00";
              // if (err.status == 409) {
              //   this.app.openSnackBar('Appointments Not Available for Selected Date', 'error-snackbar')
              // }
            }
          );
      }
    }
  }

  clearArray() {
    this.selectedItem = [];
    this.workFlowId = "";
  }

  departmentGet() {
    this.data.departmentGetAll().subscribe((res: any) => {
      this.alldepart = res;
    });
  }

  getDoctorsList() {
    this.data.getDoctorsList().subscribe((res: any) => {
      this.docName = res;
    });
  }

  // getId(data) {
  //   this.alldepart.map((res) => {
  //     if (data) {
  //       if (res.name == data) {
  //         this.deptId = res.id;
  //         this.data.getDoctorByDepartmentId(this.deptId).subscribe((res) => {
  //           this.docName = res;
  //           this.available = true;
  //           this.doctorName = res[0].name;
  //           this.doctorId = res[0].id;
  //         });
  //       }
  //     }
  //   });
  // }
  getId(data) {
    if (data == "All") {
      this.getDoctorsList()
    } else {
      this.alldepart.map((res) => {
        if (data) {
          if (res.name == data) {
            this.deptId = res.id;
            this.getDoctors(this.deptId)
          }
        }
      });
    }
  }
  // getDocId(data) {
  // console.log(data)
  //   if (data == "All") {
  //     this.getDoctorsList()
  //   } else {
  //     this.docName.map((res) => {
  //       if (data) {
  //         if (res.name == data) {
  //           this.doctId = res.id;
  //           this.getCheckpoint(this.doctId)
  //         }
  //       }
  //     });
  //   }
  // }
  getDocId(data: any) {
    console.log(data)
    this.docName.map((res) => {
      // console.log(res);
      if (data) {
        if (res.name == data) {
          this.doctId = res.id;
          console.log(this.doctId);
          this.getCheckpoint(this.doctId)
        }
      }
    });
  }
  getCheckpoint(doctId) {
    this.loader = true;
    this.data.getDoctorById(doctId).subscribe((res: any) => {
      if (res) {
        this.loader = false
        this.checkPOint = res;
        const stepNbr = 1;
        Object.assign(this.checkPOint.checkpoint, { "stepNbr": stepNbr }); // Corrected syntax here
        this.docCheckPoint = res.checkpoint;
      } else {
        this.loader = false;
      }
    },
      (err) => {
        this.loader = false;
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err).subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
      }
    );
  }

  getDoctors(deptId) {
    this.loader = true;
    this.data.getDoctorByDepartmentIdAvl(deptId).subscribe((res: any) => {
      if (res) {
        this.loader = false
        this.docName = res;
        this.available = true;
        this.doctorName = res[0].name;
        if (this.doctorName) {
          this.getDocId(this.doctorName);
        }
        this.doctorId = res[0].id;
      } else {
        this.loader = false;
      }
    },
      (err) => {
        this.loader = false;
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err).subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
      }
    );
  }

  clearUhid() {
    if (this.newPatient) {
      this.refId = null;
      this.refId = '';
      // this.cancel();
    }
    if (!this.newPatient) {
      // this.checknewEntity();
    }
  }

  checknewEntity() {
    this.data.getAllnewnextentity().toPromise().then((res) => {
      this.refId = res;
      this.entity = this.refId.patientRefId,
        this.refId = this.entity
    });
  }
  visitTypeStatic() {
    this.data.visitStaticData().subscribe(res => {
      this.visitype = res;
      this.app.loader1 = false;
    });
  }
  suggestClick(itemId: string) {
    this.sData.map(res => {
      if (res.id == itemId) {
        this.issue = res.name;
        // this.issueId =res.id;
        this.isInputHighlighted = true;
      }
    })
  }
  findIssue(data) {
    this.issueName = this.sData.filter(res => res.name.toLowerCase().includes(data));

  }
  findstatus(data) {
    this.statusName = this.status[0].values.filter(res => res.name.toLowerCase().includes(data));
  }
 
}
