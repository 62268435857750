import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { MatTableDataSource, MatSort } from '@angular/material';
import { ExcelService } from 'src/api-calls/excel.service';
@Component({
  selector: 'app-manageusers',
  templateUrl: './manageusers.component.html',
  styleUrls: ['./manageusers.component.scss']
})
export class ManageusersComponent implements OnInit {
  displayedColumns = ['firstName', 'contactNbrMob', 'emailId', 'actions'];
  dataSource: any;
  sort;
  userRole: Object;
  searcapability: Object;

  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;

    }
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  Cview: boolean = true;
  firstName;
  lastName;
  search1;
  items: any;
  roles: any = [];
  role = 0;
  searchparam: any = {};
  loader: boolean = true;
  pending: boolean = false;
  manageuser;
  adminroleSearch;
  appname;
  public manageusers: boolean = false;
  changeView: boolean = false;
  orgId = localStorage.getItem('organizationId');


  constructor(public data: DataService, public app: AppService, private excelService: ExcelService) { }

  ngOnInit() {
    var post = {
      "userType": 1
    }
    this.data.searchUser(post).subscribe(res => {
      this.items = res;
      this.dataSource = new MatTableDataSource(this.items)
      this.loader = false;
      this.items = this.items.sort(function (a, b) {
        if (a.firstName < b.firstName) { return -1; }
        if (a.firstName > b.firstName) { return 1; }
        return 0;
      })

    })

    let x = JSON.parse(localStorage.getItem('config'));
    let a = x.find(res => res.shortCdConst == "CHKNM");
    this.appname = a.userValue;
    // this.userSearchRole(this.orgId);

    this.data.getRoles().toPromise().then(res => {
      this.searcapability = res;
      console.log(this.searcapability);

      this.loader = false;
    });
  }


  viewChange(id) {
    if (id == 1) { this.Cview = true; }
    else { this.Cview = false; }
  }
  search() {
    if (this.firstName) {
      this.firstName = this.firstName.trim();
    }
    this.loader = true;
    this.searchparam = {
      "firstName": this.firstName,
      "lastName": this.lastName,
    };
    this.data.searchUser(this.searchparam).subscribe(res => {
      this.items = res;
      this.dataSource = res;
      this.loader = false;
    })
  }

  clear() {
    this.firstName = "";
    this.lastName = "";
    this.role = 0;
    var post = {}
    this.data.searchUser(post).subscribe(res => {
      this.items = res;
      this.dataSource = res;
    })
  }

  view(data) {
    this.app.openPage('manageuser-view/' + data.userId);
  }
  viewP(data) {
    this.app.openPage('pendinguser-view/' + data.userId);
  }
  pendingApproval() {
    this.pending = true;
    this.loader = true;
    var po = {};
    this.data.pendingApprovalUser(po).subscribe(res => {
      this.items = res;
      this.dataSource = res;
      this.loader = false;
    })

  }

  ngDoCheck(): void {
    if (localStorage.getItem('capability')) {
      let data: any = JSON.parse(localStorage.getItem('capability'));
      if (data.length > 0) {
        data.map(res => {
          if (res.includes('MNGUSR')) {
            this.manageusers = true;
          }
          if (res.includes('MNURSH')) {
            this.adminroleSearch = true;
          }
        })
      }
    }
  }
  exportAsXLSX(): void {
    let data: any = [];
    this.items.map(res =>
      data.push({ FirstName: res.firstName, LastName: res.lastName, Mobile: res.contactNbrMob, EmailId: res.emailId })
    )

    this.excelService.exportAsExcelFile(data, 'Users Details');
  }

  userSearchRole(id) {
    this.loader = true;
    this.data.getUserSearchRole(id, this.orgId).subscribe(res => {
      this.items = res;
      this.loader = false;
      this.dataSource = new MatTableDataSource(this.items)
    })

  }
}
