import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-popper5',
  templateUrl: './popper5.component.html',
  styleUrls: ['./popper5.component.scss']
})
export class Popper5Component implements OnInit {

  img:any

  constructor(public dialogRef: MatDialogRef<Popper5Component>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog) { }

    ngOnInit() {
      this.img = this.data;
    }

}
