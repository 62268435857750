import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import {MatTableDataSource, MatSort} from '@angular/material';
import { DataService } from 'src/api-calls/data.service';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {
  selectdate = new Date();
  selectdate1 = new Date();
  date1;
  date2;
  activityLog:any=[];
  constructor(public data:DataService) { }

  ngOnInit() {
    this.activityLogs()
  }
  displayedColumns = ['activityId', 'comments', 'eventTs'];
  dataSource: any;
 
  activityLogs(){
    var today = new Date();
    this.date1 = today.getFullYear() + '/' + ("0" + (today.getMonth() + 1)).slice(-2) + '/' + ("0" + today.getDate()).slice(-2) + ' ' + '00:00:00'
    this.date2 = today.getFullYear() + '/' + ("0" + (today.getMonth() + 1)).slice(-2) + '/' + ("0" + today.getDate()).slice(-2) + ' ' + '23:59:59'
    this.getActivityLogs()
  }
  async getActivityLogs() {
    // this.loader = true;
    this.activityLog = await this.data.activityLogs(this.date1, this.date2).toPromise();
    this.dataSource = new MatTableDataSource(this.activityLog)
  //   if(this.databox1 !=""){
  //   this.databox1.sort(function (a, b) {
  //     var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
  //     if (nameA < nameB) { return -1; }
  //     if (nameA > nameB) { return 1; }
  //     return 0;
  //   })
  // }
  //   this.loader = false;
  }
  async dateSearch() {
    this.date1 = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2) + ' ' + '00:00:00'
    this.date2 = this.selectdate1.getFullYear() + '/' + ("0" + (this.selectdate1.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate1.getDate()).slice(-2) + ' ' + '23:59:59'
    this.getActivityLogs()
  }
 

  @ViewChild(MatSort) sort: MatSort;
  ngAfterViewInit() {
    setTimeout(() => {
    this.dataSource.sort = this.sort;
  })
  }


}






