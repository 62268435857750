import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { DataService } from 'src/api-calls/data.service';
import { MatDialog } from '@angular/material';
import { AssistancepopComponent } from '../assistancepop/assistancepop.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-patedit-mob',
  templateUrl: './patedit-mob.component.html',
  styleUrls: ['./patedit-mob.component.scss']
})
export class PateditMobComponent implements OnInit {
  items: any = [];
  name;
  s1;
  s2;
  description;
  patientRefId;
  mobile;
  patientId;
  workFlowId;
  add
  loader: boolean = true;
  getWorkFlow: any = [];
  selectedItem: any = [];
  checkpoints: any = [];
  customWorkId;
  patientObject: any = [];
  workFlowName;
  wDescription;
  patientUserid;
  entityId;
  metadata;
  language = [{ name: 'English', value: '101' },
  { name: 'Hindi', value: '105' },
  { name: 'Tamil', value: '103' }];
  preflang = '101';
  showToken: string;
  maxName: string;
  maxToken: string;
  maxMetadata: string;
  maxPatientRef: string;
  showtime: boolean = false;
  patients = [{ name: 'In-Patient', id: '2' },
  { name: 'Out-Patient', id: '1' },
  { name: 'MHC', id: '3' },
  { name: 'BedSide', id: '4' }];
  entityType;
  countrynum;
  reportingTime;
  receiptNbr;
  wardNbr;
  bedSide: any;
  doctorName; age; gender;height;weight;
  showcalendar: boolean = false;
  selectdate:Date = new Date();
  setApptTime: boolean = false;
  mulCard: boolean;
  token;
  workqTime;
  workqId;
  reportingDate;
  enable:boolean=true;
  loader1:boolean=false;
  documentNbr;
  languageCode: string;
  constructor(public app:AppService, private translate: TranslateService, public data:DataService,public dialog: MatDialog) { }

  async ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    let x = JSON.parse(localStorage.getItem('config'));
    let a = x.find(res => res.shortCdConst == "TKNAUTO");
    this.showToken = a.userValue;
    let b = x.find(res => res.shortCdConst == "PATNM");
    this.maxName = b.userValue;
    let c = x.find(res => res.shortCdConst == "PATTKNNO");
    this.maxToken = c.userValue;
    let d = x.find(res => res.shortCdConst == "PATMTDA");
    this.maxMetadata = d.userValue;
    let e = x.find(res => res.shortCdConst == "PATRFID");
    this.maxPatientRef = e.userValue;
    let f = x.find(res => res.shortCdConst == "MCPTCD");
    if (f.userValue == "true" || f.userValue == true) {
      this.mulCard = true;
    }
    else {
      this.mulCard = false;
    }
    this.patientId = this.app.urlData()
    this.data.getPatientById(this.patientId).toPromise().then(res => {
      this.patientObject = res;
      this.name = this.patientObject.name;
      if (this.patientObject.entityType != 2) {
        var len = this.patientObject.contactNbr.length;
        len = len - 10;
        this.countrynum = this.patientObject.contactNbr.slice(0, len);
        this.mobile = this.patientObject.contactNbr.slice(-10);
      }
      this.patientRefId = this.patientObject.patientRefId;
      this.token = this.patientObject.tokenNbr;
      this.entityType = this.patientObject.entityType;
      this.preflang= this.patientObject.prefLang,
      this.documentNbr= this.patientObject.documentNbr,

      this.metadata = this.patientObject.metadata;
      this.age = this.patientObject.age;
      this.gender = this.patientObject.gender;
      this.height = this.patientObject.height;
      this.weight = this.patientObject.weight;
      if (this.patientObject.apptTimeStr) {
        let r = this.patientObject.expctdReportingTime
        this.reportingTime = r.slice(11, 16);
        this.selectdate = new Date(r)
      }
      this.app.callRequired = this.patientObject.callRequired;
      this.app.wheeled = this.patientObject.wheeled;
      this.app.aged = this.patientObject.aged;
      this.app.nri = this.patientObject.internationalPatient;
      this.wardNbr = this.patientObject.wardNbr;
      this.receiptNbr = this.patientObject.receiptNbr;
      this.bedSide = this.patientObject.bedSide;
      this.doctorName = this.patientObject.doctorName;
      
      this.data.getWorkflowbyPatient(this.patientId).toPromise().then(res =>  {
        this.getWorkFlow = res[0];
        if (this.getWorkFlow) {
          this.workFlowId = this.getWorkFlow.id;
          this.workFlowName = this.getWorkFlow.name;
        }
        if (this.getWorkFlow) {
          this.selectedItem = this.getWorkFlow.checkpoints;
          this.selectedItem.map(res => {
            if (res.apptTimeStr) {
              let r = res.apptTimeStr
              let time = r.slice(11, 16);
              let date: any = new Date(r)
              this.reportingTime=r.slice(11,16)
              Object.assign(res, { time: time, date: date })
          
            }
          })
     
          this.data.getCheckpointsPatientAdd(this.patientObject.entityType).toPromise().then(res => {
            this.checkpoints = res;
    
            this.loader = false;
            if(this.checkpoints.length>0){
                this.checkpoints.map(res1=>{
                  this.selectedItem.map(res2=>{
                    if(res1.id==res2.id){
                      if(this.entityType!=2 || res1.cpType!=2){
                      Object.assign(res1,{selected:true,time:res2.time,date:res2.date,apptTimeStr:res2.apptTimeStr})
                    }
         
                  if(this.entityType==2 ||res1.cpType==2){
                    Object.assign(res1,{select:true,selected:true,time:res2.time,date:res2.date,apptTimeStr:res2.apptTimeStr})
                  }
                }
                  })
                })
              }
          })
          this.selectedItem = this.selectedItem.sort((a, b) => { return a.stepNbr - b.stepNbr });
          let order = [1, 3, 2];

          this.selectedItem.sort(function (a, b) {
            return order.indexOf(a.checkPointPositionId) - order.indexOf(b.checkPointPositionId)

          });
        }
      })
      setTimeout(() => {
        this.loader = false;
      }, 2000);
    });
  }
  async getWorkId(item) {
    if(this.entityType==2 ||item.cpType==2){
      this.datePost(item.id,this.selectdate)
      this.checkpoints.map(res=>{
        if(res.id==item.id){
          if(res.selected){
          delete res.select
          this.selectedItem=this.selectedItem.filter(res=>{return res.id != item.id})
          }
          else{
           Object.assign(res,{select:true})
           this.selectedItem.push(res)
         }
        }
      
      })
  }
   this.checkpoints.map(res=>{
     if(res.id==item.id){
       if(res.selected){
       delete res.selected
       this.selectedItem=this.selectedItem.filter(res=>{return res.id != item.id})
       }
       else{
        Object.assign(res,{selected:true})
        this.selectedItem.push(res)
      }
     }
   
   })
  }
  async save() {
    this.selectedItem=this.checkpoints.filter(res=>{return res.selected==true})
    this.loader=true;
    if (this.name == undefined || this.selectedItem.length == 0 || this.name == "" || this.patientRefId == undefined || this.patientRefId == "") {
      // this.app.openSnackBar('Please enter the required fields', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Deleted Successfully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
        })
    })
      this.loader = false;
    }
    else {
      if (this.showcalendar == true) {
        if (this.reportingTime == undefined || this.reportingTime == "") {
          // this.app.openSnackBar('Please enter the time  required fields', 'error-snackbar')
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get("Please enter the time  required fields").subscribe((translation: string) => {
                this.app.openSnackBar(translation, "error-snackbar")
            })
        })
          this.loader=false
        }
        else {
        // this.reportingTime = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2) + ' ' + this.reportingTime + ':00';
        this.patientObject.name = this.name.toUpperCase();
        if (this.mobile != null || this.mobile != undefined || this.mobile != "") {
          this.patientObject.contactNbr = this.countrynum + this.mobile;
        }
        //  if(this.mobile!=null || this.mobile!=undefined || this.mobile!=""  ){
        //   this.patientObject.contactNbr = this.countrynum + this.mobile;
        //  }
        if (this.mobile == null) {
          this.patientObject.contactNbr = '+91' + undefined
        }
        if (this.mobile == "") {
          this.patientObject.contactNbr = '+91' + undefined
        }
        if (this.mobile == undefined) {
          this.patientObject.contactNbr = '+91' + undefined
        }
        this.patientObject.metadata = this.metadata;
        this.patientObject.age = this.age;
        this.patientObject.gender = this.gender;
        this.patientObject.height = this.height;
        this.patientObject.weight = this.weight;
         this.patientObject.prefLang=this.preflang,
         this.patientObject.documentNbr=this.documentNbr,
        this.patientObject.patientRefId = this.patientRefId;
        if (this.entityType != 4) {
          this.patientObject.entityType = this.entityType;
        }
        if (this.entityType == 4) {
          this.patientObject.entityType = '2';
          this.patientObject.bedSide = 'true';
        }
        this.patientObject.aged = this.app.aged;
        this.patientObject.wheeled = this.app.wheeled;
        this.patientObject.internationalPatient = this.app.nri;
        this.patientObject.callRequired = this.app.callRequired;
        this.patientObject.wardNbr = this.wardNbr;
        this.patientObject.receiptNbr = this.receiptNbr;
        this.patientObject.doctorName = this.doctorName;


        await this.data.editPatient(this.patientId, this.patientObject).toPromise()
          .then((res: any) => {
          });

        this.selectedItem.map((res, i) => {
          this.selectedItem[i].stepNbr = i + 1;
        })
        this.getWorkFlow.checkpoints = this.selectedItem;
        this.getWorkFlow.predefined = false;
        this.getWorkFlow.entityId = this.patientId;
        if (this.name) {
          this.data.updateWorkFlow(this.workFlowId, this.getWorkFlow).toPromise()
            .then((res: any) => {
              // this.customWorkId=res.id ;
              this.loader = false;
              this.app.openPage('patcheckin-mob');
              // this.app.openSnackBar('Patient Details Updated Successfully !!', 'success-snackbar')
              this.translate.use(this.languageCode).subscribe(() => {
                this.translate.get("Deleted Successfully").subscribe((translation: string) => {
                    this.app.openSnackBar(translation, "success-snackbar")
                })
            })
              //   this.data.patientAssignWorkFlow(this.customWorkId,this.patientId,null).subscribe(
              //     res=>this.app.openSnackBar(' Patient checkedin updated Successfully !!'),
              //   err=>
              //  );
              this.app.aged = false;
              this.app.wheeled = false;
              this.app.callRequired = false;
              this.app.nri = false;
            })
            .catch(
              err => {
                this.loader = false
              }
            )
        }
      }
    }
      else {
        this.patientObject.name = this.name.toUpperCase();
        if (this.mobile != null || this.mobile != undefined || this.mobile != "") {
          this.patientObject.contactNbr = this.countrynum + this.mobile;
        }
        if (this.mobile == null) {
          this.patientObject.contactNbr = '+91' + undefined
        }
        if (this.mobile == "") {
          this.patientObject.contactNbr = '+91' + undefined
        }
        if (this.mobile == undefined) {
          this.patientObject.contactNbr = '+91' + undefined
        }
        // else{
        //   this.patientObject.contactNbr = this.countrynum + undefined
        // }
        this.patientObject.metadata = this.metadata;
        this.patientObject.age = this.age;
        this.patientObject.gender = this.gender;
        this.patientObject.height = this.height;
        this.patientObject.weight = this.weight;
        this.patientObject.prefLang=this.preflang;
        this.patientObject.documentNbr=this.documentNbr;
        


        if (this.entityType != 4) {
          this.patientObject.entityType = this.entityType;
        }
        if (this.entityType == 4) {
          this.patientObject.entityType = '2';
          this.patientObject.bedSide = 'true';
        }
        // if (this.showcalendar) {
        //   this.patientObject.expctdReportingTime = this.reportingTime;
        // }
          this.patientObject.wardNbr = this.wardNbr;
          this.patientObject.receiptNbr = this.receiptNbr;
          this.patientObject.doctorName = this.doctorName;
        // this.patientObject.expctdReportingTime = this.reportingTime;
        this.patientObject.aged = this.app.aged;
        this.patientObject.wheeled = this.app.wheeled;
        this.patientObject.internationalPatient = this.app.nri;
        this.patientObject.callRequired = this.app.callRequired;

        await this.data.editPatient(this.patientId, this.patientObject).toPromise()
          .then((res: any) => {
          });


        this.selectedItem.map((res, i) => {
          this.selectedItem[i].stepNbr = i + 1;
        })

        // let post1 = { name: this.name, description: this.name, checkpoints: this.selectedItem,predefined:false }
        // this.getWorkFlow.name=this.workFlowName;
        // this.getWorkFlow.description=this.workFlowName;
        this.getWorkFlow.checkpoints = this.selectedItem;
        this.getWorkFlow.predefined = false;
        if (this.name) {
          this.data.updateWorkFlow(this.workFlowId, this.getWorkFlow).toPromise()
            .then((res: any) => {
              // this.customWorkId=res.id ;
              this.loader = false;
              this.app.openPage('patcheckin-mob');
              // this.app.openSnackBar('Patient Details Updated Successfully !!', 'success-snackbar')
              this.translate.use(this.languageCode).subscribe(() => {
                this.translate.get("Deleted Successfully").subscribe((translation: string) => {
                    this.app.openSnackBar(translation, "success-snackbar")
                })
            })
              //   this.data.patientAssignWorkFlow(this.customWorkId,this.patientId,null).subscribe(
              //     res=>this.app.openSnackBar(' Patient checkedin updated Successfully !!'),
              //   err=>
              //  );
              this.app.aged = false;
              this.app.wheeled = false;
              this.app.nri = false;
              this.app.callRequired = false;
            })
            .catch(
              err => {
                this.loader = false
              }
            )


        }

      }
    }


  }
  ngDoCheck(): void {
    if (this.entityType == 2) {
      this.showtime = true;
      this.showcalendar = true
    }
    if (this.entityType == 4) {
      this.showcalendar = false;
      this.showtime = true;
    }
    if (this.entityType == 1 || this.entityType == 3) {
      this.showcalendar = false;
      this.showtime = false;
    }
    let apptcap = JSON.parse(localStorage.getItem('capability'))
    if (apptcap.length > 0) {
      apptcap.map(res => {
        if (res.includes('APTTMORD')) {
          this.setApptTime = true;
        }

      })
    }
  }
  
  openDialog(id): void {
    const dialogRef = this.dialog.open(AssistancepopComponent, {
      width: '250px',
      data: id
    });
  }
  getAppt(data) {
    this.loader1=true
    this.selectedItem=[]
    this.data.getCheckpointsPatientAdd(data).subscribe(res => {
      this.checkpoints = res;
      this.loader1=false;
      // this.checkpoints = this.checkpoints.filter(res => !this.selectedItem.find(val => res.id === val.id));
    })
  }
  async datePost(id, date: Date) {
    let checkGetById: any = await this.data.getCheckpointById(id).toPromise();
    let temp;
    this.selectdate = date;
  
      let date1 = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2)

      this.data.getAppointment(checkGetById.worq.id, date1).subscribe(res => {

        this.reportingTime = res;
        temp = res;
        this.checkpoints.map(res2=>{
          if(res2.id==id)
            
          Object.assign(res2,{time:res,date:this.selectdate,apptTimeStr: date1 + ' ' + this.reportingTime})
        })
        

        this.selectedItem.map(res1 => {
         
          if (res1.id == id) {
            Object.assign(res, { apptTimeStr: date1 + ' ' + this.reportingTime, time: temp })
          }
        })
      },
        err => {
          if (err.status == 409) {
            // this.app.openSnackBar('Appointments Not Available for Selected Date', 'error-snackbar')
            this.translate.use(this.languageCode).subscribe(() => {
              this.translate.get("Deleted Successfully").subscribe((translation: string) => {
                  this.app.openSnackBar(translation, "success-snackbar")
              })
          })
            this.checkpoints.map(res2=>{
              if(res2.id==id)
                
              Object.assign(res2,{date:this.selectdate})
            })
          }
        })
    
  }
  timeChange(id, time) {
    let date = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2);
    this.reportingTime = time;
    let temp = time;
    this.checkpoints.map(res2=>{
      if(res2.id==id)
        
      Object.assign(res2,{time:time,date:this.selectdate,apptTimeStr: date + ' ' + this.reportingTime})
    })
    this.selectedItem.map(res => {
      if (res.id == id) {
        Object.assign(res, { apptTimeStr: date + ' ' + this.reportingTime, time: temp })
      }
    })
  }

  async getReportingTIme(date1:Date) {
    this.selectdate=date1;
    if (this.entityType == 2) {
      let checkGetById: any = await this.data.getCheckpointById(this.workqId).toPromise()
      if (checkGetById) {
        let date = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2)

        await this.data.getAppointment(checkGetById.worq.id, date).toPromise().then(res => {
          this.reportingTime = res
          this.workqTime = res
          if (this.reportingTime) {
            let date = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2);
            this.selectedItem.map(res => {
              if (res.id == this.workqId) {
                Object.assign(res, { apptTimeStr: date + ' ' + this.reportingTime, time: this.workqTime,date:date1 })
              }
            })

          }
        },
          err => {
            if (err.status == 409) {
              // this.app.openSnackBar('Appointments Not Available for Selected Date', 'error-snackbar')
              this.translate.use(this.languageCode).subscribe(() => {
                this.translate.get("Deleted Successfully").subscribe((translation: string) => {
                    this.app.openSnackBar(translation, "success-snackbar")
                })
            })
            }
          })
      }
    }
  }
}
