import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class DataService {
    urlCall: string;
    serviceId: null;
    mobileNbr: null;
    entityId: any;
    doctorsList() {
        throw new Error('Method not implemented.');
    }
    private baseUrl = 'https://example.com';

    private url = window.atob(window["apiUrl"]);

    // clinica prod
    private url1 = "/userManagement/"
    private url2 = '/kairos-svcs/'

    // local prod
    // private url1 = "/userManagement-svcs-sdh/"
    // private url2 = '/kairos-svcs-sdh/'

    // Test
    // private url1 = "/userManagement-svcs/"
    // private url2 = '/kairos-svcs/'

    public loader: boolean = false;

    constructor(private http: HttpClient) { }

    userData(id) {
        return this.http.get(this.url + this.url1 + 'users/' + id + '/basicDetails');
    }

    updateUserData(id, data) {
        return this.http.put(this.url + this.url1 + 'users/' + id + '/userUpdate', data);
    }

    getWorkFlows() {
        return this.http.get(this.url + '/clinique/workflows?predefined=true');
    }
    getWorkFlowsById(id) {
        return this.http.get(this.url + '/clinique/workflows/' + id);
    }
    createWorkFlow(data) {
        return this.http.post(this.url + '/clinique/workflows', data);
    }
    updateWorkFlow(id, data) {
        return this.http.put(this.url + '/clinique/workflows/' + id, data);
    }
    getCheckpoints() {
        return this.http.get(this.url + '/clinique/checkpoints');
    }
    getCheckpointById(id) {
        return this.http.get(this.url + '/clinique/checkpoints/' + id + '?allDetails=true');
    }
    getCheckpointsPatientAdd(id) {
        return this.http.get(this.url + '/clinique/checkpoints?entityType=' + id);
    }
    createCheckpoints(data) {
        return this.http.post(this.url + '/clinique/checkpoints', data);
    }
    updateCheckpointsById(id, data) {
        return this.http.put(this.url + '/clinique/checkpoints/' + id, data);
    }
    searchCheckpoints(data) {
        return this.http.post(this.url + '/clinique/checkpoints/search', data);
    }
    searchWorkFlow(data) {
        return this.http.post(this.url + '/clinique/workflows/search', data);
    }
    getworkQueue(id) {
        return this.http.get(this.url + '/clinique/workq/' + id + '/entities/');
    }
    patientCheckin(data) {
        return this.http.post(this.url + '/clinique/entities', data);
    }
    patientCheck(data, data1, data2) {
        return this.http.post(this.url + '/clinique/entities?fromDate=' + data1 + '&&toDate=' + data2, data);
    }
    getAppointment(id, date) {
        return this.http.get(this.url + '/clinique/workqs/' + id + '/nextAppt?requestDate=' + date, { responseType: 'text' });
    }
    patientAssignWorkFlow(id1, id, data) {
        return this.http.post(this.url + '/clinique/workflows/' + id1 + '/entities/' + id, data);
    }
    addPatientNotes(id1, id2, data) {
        return this.http.put(this.url + '/clinique/workqs/' + id1 + '/entities/' + id2 + '/notes?notes=' + data, null);
    }
    getAllWorkq() {
        return this.http.get(this.url + '/clinique/workqs');
    }
    getWorkqById(id) {
        return this.http.get(this.url + '/clinique/workqs/' + id);
    }
    workqCompleted(id, id1, id2) {
        if (id2 && id2 != 0 && id2 != null) {
            return this.http.put(this.url + '/clinique/workqs/' + id + '/entities/' + id1 + '/complete?subCheckpointId=' + id2, null);
        }
        else {
            return this.http.put(this.url + '/clinique/workqs/' + id + '/entities/' + id1 + '/complete', null);
        }
    }
    // workqCompleted1(id, id1) {
    //     return this.http.put(this.url + '/clinique/workqs/' + id + '/entities/' + id1 + '/complete', null);
    // }
    getWorkflowbyPatient(id) {
        return this.http.get(this.url + '/clinique/workflows?entityId=' + id);
    }
    // getAllPatient() {
    //     this.loader = true;
    //     return this.http.get(this.url + '/clinique/entities')
    //         .pipe(tap(res => this.loader = false));
    // }
    getAllPatient(data1, data2) {
        this.loader = true;
        return this.http.post(this.url + '/clinique/entities/search?fromDate=' + data1 + '&&toDate=' + data2, null)
            .pipe(tap(res => this.loader = false));
    }
    getAllPatient1(data1, data2) {
        this.loader = true;
        return this.http.post(this.url + '/clinique/entities/search?fromDate=' + data1 + '&&toDate=' + data2 + '&&currentCheckpoint=true', null)
            .pipe(tap(res => this.loader = false));
    }
    getPatientDetails(UHID, serviceId, mobileNbr) {
        // this.loader = true;
        this.urlCall = '/clinique/entities/external?serviceId=' + serviceId;
        if (UHID != null && UHID != undefined && UHID != '') {
            this.urlCall = this.urlCall + '&&UHID=' + UHID;
        }
        if (mobileNbr != null && mobileNbr != undefined && mobileNbr != '') {
            this.urlCall = this.urlCall + '&&mobileNbr=' + mobileNbr;
        }
        // .pipe(tap(res => this.loader = false));
        return this.http.get(this.url + this.urlCall,);
    }
    searchPatient(data, data1, data2) {
        return this.http.post(this.url + '/clinique/entities/search?fromDate=' + data1 + '&&toDate=' + data2, data);
    }
    searchPatientAdmin(data, data1, data2) {
        return this.http.post(this.url + '/clinique/entities/workFlowStats/search?fromDate=' + data1 + '&&toDate=' + data2, data);
    }
    searchAdminWorkFlow(data, data1, data2) {
        return this.http.post(this.url + '/clinique/entities/search?fromDate=' + data1 + '&&toDate=' + data2 + '&&currentCheckpoint=true', data);
    }
    searchPatient1(data) {
        return this.http.post(this.url + '/clinique/entities/search', data);
    }
    getPatientById(id) {
        return this.http.get(this.url + '/clinique/entities/' + id);
    }
    editPatient(id, data) {
        return this.http.put(this.url + '/clinique/entities/' + id, data);
    }
    forceRemove(id1, id2, id3, data) {
        if (id3 && id3 != 0 && id3 != null) {
            return this.http.put(this.url + '/clinique/workqs/' + id1 + '/entities/' + id2 + '/forceRemove?subCheckpointId=' + id3, data)
        }
        else {
            return this.http.put(this.url + '/clinique/workqs/' + id1 + '/entities/' + id2 + '/forceRemove', data)
        }
    }
    // forceRemove1(id1, id2,data) {
    //     return this.http.put(this.url + '/clinique/workqs/' + id1 + '/entities/' + id2 + '/forceRemove', data)
    // }
    // forceRemove1Missed(id1, id2,data) {
    //     return this.http.put(this.url + '/clinique/workqs/' + id1 + '/entities/' + id2 + '/forceRemove?missed=' + data, null)
    // }
    forceRemoveMissed(id1, id2, id3, data) {
        if (id3 && id3 != 0 && id3 != null) {
            return this.http.put(this.url + '/clinique/workqs/' + id1 + '/entities/' + id2 + '/forceRemove?subCheckpointId=' + id3 + '&&missed=' + data, null)
        }
        else {
            return this.http.put(this.url + '/clinique/workqs/' + id1 + '/entities/' + id2 + '/forceRemove?missed=' + data, null)
        }
    }
    forceStart(id1, id2, id3, data, data1, data2) {
        if (id3 && id3 != 0 && id3 != null) {
            return this.http.put(this.url + '/clinique/workqs/' + id1 + '/entities/' + id2 + '/forceStart?subCheckpointId=' + id3 + '&&missed=' + data + '&&moveBackToQ=' + data2, data1)
        }
        else {
            return this.http.put(this.url + '/clinique/workqs/' + id1 + '/entities/' + id2 + '/forceStart?missed=' + data + '&&moveBackToQ=' + data2, data1)
        }
    }
    subCheckCurrentStart(id1, id2, id3, data, data1, data2) {
        if (id3 && id3 != 0 && id3 != null) {
            return this.http.put(this.url + '/clinique/workqs/' + id1 + '/entities/' + id2 + '/forceStart?subCheckpointId=' + id3 + '&&missed=' + data + '&&moveBackToQ=' + data2 + '&&available=false', data1)
        }
        else {
            return this.http.put(this.url + '/clinique/workqs/' + id1 + '/entities/' + id2 + '/forceStart?missed=' + data + '&&moveBackToQ=' + data2 + '&&available=false', data1)
        }
    }
    // forceStart1(id1, id2, data, data2, data3) {
    //     return this.http.put(this.url + '/clinique/workqs/' + id1 + '/entities/' + id2 + '/forceStart?missed=' + data + '&&moveBackToQ=' + data2, data3)
    // }
    forceWait(id1, id2, id3) {
        if (id3 && id3 != 0 && id3 != null) {
            return this.http.put(this.url + '/clinique/workqs/' + id1 + '/entities/' + id2 + '/forceWait?subCheckpointId=' + id3, null)
        }
        else {
            return this.http.put(this.url + '/clinique/workqs/' + id1 + '/entities/' + id2 + '/forceWait', null)
        }
    }
    // forceWait1(id1, id2) {
    //     return this.http.put(this.url + '/clinique/workqs/' + id1 + '/entities/' + id2 + '/forceWait', null)

    // }
    missed(id1, id2, id3) {
        if (id3 && id3 != 0 && id3 != null) {
            return this.http.put(this.url + '/clinique/workqs/' + id1 + '/entities/' + id2 + '/missed?subCheckpointId=' + id3, null)
        }
        else {
            return this.http.put(this.url + '/clinique/workqs/' + id1 + '/entities/' + id2 + '/missed', null)
        }
    }
    // missed1(id1, id2) {
    //     return this.http.put(this.url + '/clinique/workqs/' + id1 + '/entities/' + id2 + '/missed', null)

    // }
    getMissed(id1) {
        return this.http.get(this.url + '/clinique/workqs/' + id1 + '/entities?status=2');

    }
    getMissed1(id1, data1, data2) {
        return this.http.get(this.url + '/clinique/workqs/' + id1 + '/entities?status=2&&fromDate=' + data1 + '&&toDate=' + data2);
    }
    getSkipped(id1) {
        return this.http.get(this.url + '/clinique/workqs/' + id1 + '/entities?status=5');
    }
    getSkipped1(id1, data1, data2) {
        return this.http.get(this.url + '/clinique/workqs/' + id1 + '/entities?status=5&&fromDate=' + data1 + '&&toDate=' + data2);
    }
    getCompleted(id1, data1, data2) {
        return this.http.get(this.url + '/clinique/workqs/' + id1 + '/entities?status=4&&fromDate=' + data1 + '&&toDate=' + data2);
    }
    getCompleted1(id1) {
        return this.http.get(this.url + '/clinique/workqs/' + id1 + '/entities?status=4');
    }
    getCheckedIn(id1) {

        return this.http.get(this.url + '/clinique/workqs/' + id1 + '/entities?status=6');
    }
    getCheckedIn1(id1, data1, data2) {
        return this.http.get(this.url + '/clinique/workqs/' + id1 + '/entities?status=6&&fromDate=' + data1 + '&&toDate=' + data2);
    }
    startQ(qid, id, id1) {
        if (id1 && id1 != 0 && id1 != null) {
            return this.http.put(this.url + '/clinique/workqs/' + qid + '/entities/' + id + '/start?subCheckpointId=' + id1, null)
        }
        else {
            return this.http.put(this.url + '/clinique/workqs/' + qid + '/entities/' + id + '/start', null)
        }
    }
    // startQ1(qid, id) {
    //     return this.http.put(this.url + '/clinique/workqs/' + qid + '/entities/' + id + '/start', null)
    // }

    nextQ(qid, id1, data) {
        if (id1 && id1 != 0 && id1 != null) {
            return this.http.put(this.url + '/clinique/workqs/' + qid + '/next?subCheckpointId=' + id1, data)
        }
        else {
            return this.http.put(this.url + '/clinique/workqs/' + qid + '/next', data)
        }
    }

    getPosition() {
        return this.http.get(this.url + '/clinique/checkpoints/positions');
    }
    getUsers() {
        return this.http.get(this.url + this.url1 + 'users')
    }
    getSummary(data1, data2) {
        return this.http.get(this.url + '/clinique/workqs/summary?fromDate=' + data1 + '&&toDate=' + data2);
    }
    activityLogs(data1, data2) {
        return this.http.post(this.url + '/clinique/activityLogs/search?fromDate=' + data1 + '&&toDate=' + data2, null);
    }
    configs() {
        return this.http.get(this.url + '/clinique/appConfigs');
    }
    postConfig(data) {
        return this.http.post(this.url + '/clinique/appConfigs', data)
    }
    getReport() {
        return this.http.post(this.url + '/clinique/databox/search', {})
    }
    getDatabox(data) {
        return this.http.post(this.url + '/clinique/databox/search', data)
    }
    getDatabox1(data, frmdate, todate) {
        return this.http.post(this.url + '/clinique/databox/search?fromDate=' + frmdate + '&&toDate=' + todate, data)
    }
    getDashboard() {
        return this.http.get(this.url + '/clinique/databox')
    }
    postChartType(data) {
        return this.http.post(this.url + '/clinique/databox', data)
    }
    searchUser(data) {
        return this.http.post(this.url + '/clinique/users/search?preview=true', data)
    }
    pendingApprovalUser(data) {
        return this.http.post(this.url + '/clinique/users/search?preview=true&&onlyPendingApprovals=true', data)
    }
    getRoles() {
        return this.http.get(this.url + '/clinique/roles/')
    }
    getRolesById(id) {
        return this.http.get(this.url + '/clinique/users/' + id);
    }
    deleteChart(id) {
        return this.http.delete(this.url + '/clinique/databox/' + id)
    }
    rolesApprove(id, data) {
        return this.http.post(this.url + '/clinique/users/' + id + '/roles', data);
    }
    checkpointSave(id, data) {
        return this.http.put(this.url + '/clinique/users/' + id, data);
    }
    approve(id, data) {
        return this.http.post(this.url + '/clinique/users/' + id + '/roles?onlyPendingApprovals=true', data);
    }
    getCapabilities() {
        return this.http.get(this.url + '/clinique/capabilities')
    }
    capabilityApprove(id, data) {
        return this.http.post(this.url + '/clinique/roles/' + id + '/capabilities', data);
    }

    getEntitiesByUserId() {
        return this.http.get(this.url + '/clinique/entities?parentUserId=' + localStorage.getItem('currentUserId'));
    }
    getEntitiesByMobile(num) {
        return this.http.get(this.url + '/clinique/entities?contactNbr=' + num);
    }
    getcapabilitiesById(id) {
        return this.http.get(this.url + '/clinique/roles/' + id + '/capabilities');
    }
    // searchCapabilitiesById(id) {
    //     return this.http.get(this.url + '/clinique/capabilities/' + id + '/roles');
    // }
    searchCapabilitiesById(id1, id2) {
        return this.http.get(this.url + '/clinique/roles/' + id1 + '/capabilities/' + id2);
    }
    roleGetById(id) {
        return this.http.get(this.url + '/clinique/roles/' + id);
    }
    staticForFStart() {
        return this.http.get(this.url + '/clinique/staticdata/FRCE_STRT_RESNS');
    }
    staticForFRemove() {
        return this.http.get(this.url + '/clinique/staticdata/FRCE_RMV_RESNS');
    }
    staticForPatientCategory() {
        return this.http.get(this.url + '/clinique/staticdata/ENTITY_CATEGORIES');
    }
    getOrgLogo() {
        return this.http.get(this.url + '/clinique/org/' + localStorage.getItem("organizationId"));
    }
    sendMsg(data) {
        return this.http.post(this.url + '/clinique/notifications/send', data);
    }
    moveEntity(worqId, chkId, data) {
        return this.http.put(this.url + '/clinique/workqs/' + worqId + '/moveCheckpoint/' + chkId, data);
    }
    addReview(id, id1) {
        return this.http.put(this.url + '/clinique/workqs/' + id + '/entities/' + id1 + '?action=Review', null);
    }
    workFlowStats(data) {
        return this.http.post(this.url + '/clinique/entities/workFlowStats', data);
    }
    getChecks() {
        return this.http.get(this.url + '/clinique/staticdata/CHECKPOINT_CHECKS');
    }
    adminMileStoneComplete(id, id2) {
        return this.http.put(this.url + '/clinique/workqs/' + id + '/entities/' + id2 + '/complete', null);
    }
    adminMileStoneStart(id, id2) {
        return this.http.put(this.url + '/clinique/workqs/' + id + '/entities/' + id2 + '/start', null);
    }
    adminExpand(id) {
        return this.http.get(this.url + '/clinique/workflows/fullDetails?entityId=' + id);
    }

    adminExpandAll(date1, date2) {
        return this.http.get(this.url + '/clinique/workflows/fullDetails?fromDate=' + date1 + '&&toDate=' + date2);
    }

    sendReportNotifictaion(id, id2, value) {
        return this.http.put(this.url + '/clinique/workqs/' + id + '/entities/' + id2 + '/reportready?reportReady=' + value, null);
    }
    sendReportCollectedNotifictaion(id, id2, value) {
        return this.http.put(this.url + '/clinique/workqs/' + id + '/entities/' + id2 + '/reportsCollected?reportsCollected=' + value, null);
    }


    // CLINICA APIs

    searchMedicine(data) {
        return this.http.post(this.url + '/clinique/medicines/search?preview=true', data);
    }

    searchCenter(data) {
        return this.http.post(this.url + '/clinique/healthCareCenters/search', data);
    }

    preferMedicine(id) {
        return this.http.get(this.url + '/clinique/healthCareCenters/' + id + '/preferredMedicines');
    }

    staticData() {
        return this.http.get(this.url + '/clinique/staticData')
    }

    getLabCenter() {
        return this.http.get(this.url + '/clinique/healthCareCenters/centerType/4');
    }

    diagnosis(id) {
        return this.http.get(this.url + '/clinique/diagnosis/' + id);
    }
    diagnosisCheckPointId(id, id1) {
        return this.http.get(this.url + '/clinique/diagnosis/' + id + '?checkPointId=' + id1);
    }
    getPharma() {
        return this.http.get(this.url + '/clinique/healthCareCenters/centerType/3');
    }

    getMed(id) {
        return this.http.get(this.url + '/clinique/medicines/' + id);
    }

    diagnosisUpdate(id, data) {
        return this.http.put(this.url + '/clinique/diagnosis/' + id, data);
    }
    getPrevMed() {
        return this.http.get(this.url + '/clinique/medicines/search?preview=true');
    }

    clinicaPathistory(id) {
        return this.http.get(this.url + '/clinique/diagnosis?patientId=' + id);
    }

    previousMdicine(id) {
        return this.http.get(this.url + '/clinique/diagnosis?patientId=' + id + '&&lastDiagnosisOnly=true');
    }

    getMedicine() {
        return this.http.get(this.url + '/clinique/medicines')
    }

    putMed(id, data) {
        return this.http.put(this.url + '/clinique/medicines/' + id, data);
    }

    createMed(data) {
        return this.http.post(this.url + '/clinique/medicines', data);
    }

    labTestGetById(id1, id2) {
        return this.http.get(this.url + '/clinique/diagnosis/' + id1 + '/labtest/' + id2);
    }
    postDocument(id, data, val) {
        return this.http.post(this.url + '/clinique/diagnosis/' + id + '/documents/?docName=' + val + '&&primaryDoc=false', data);
    }
    getDocument(id1, id2) {
        return this.http.get(this.url + '/clinique/diagnosis/' + id1 + '/documents/' + id2);
    }
    deleteDocument(id1, id2) {
        return this.http.delete(this.url + '/clinique/diagnosis/' + id1 + '/documents/' + id2);
    }
    putPrimaryDoc(id, id1, data) {
        return this.http.put(this.url + '/clinique/diagnosis/' + id + '/document/' + id1 + '/primaryDoc?primaryDoc=' + data, null);
    }
    getUserPrefer(id) {
        return this.http.get(this.url + '/clinique/appConfigs?shrtCode=PAGESIZE&&userId=' + id);
    }
    saveUserPrefer(data, id) {
        return this.http.post(this.url + '/clinique/appConfigs?shrtCode=PAGESIZE&&userId=' + id, data);
    }
    userSearch(id) {
        return this.http.get(this.url + '/clinique/roles/users?roleCd=P&&orgId=' + id);
    }
    userSearchByPage(id, nbr) {
        return this.http.get(this.url + '/clinique/roles/users?roleCd=P&&orgId=' + id + '&&pageNbr=' + nbr + '&&pageSize=50');
    }
    patientSearch(data) {
        return this.http.post(this.url + '/clinique/users/search?preview=true', data);
    }
    deleteCheckPoint(id) {
        return this.http.delete(this.url + '/clinique/checkpoints/' + id)
    }
    departmentCreate(data) {
        return this.http.post(this.url + '/clinique/departments', data);
    }
    departmentGetAll() {
        return this.http.get(this.url + '/clinique/departments');
    }
    departmentGetById(id) {
        return this.http.get(this.url + '/clinique/departments/' + id);
    }
    departmentPutById(id, data) {
        return this.http.put(this.url + '/clinique/departments/' + id, data);
    }
    departmentDeleteById(id) {
        return this.http.delete(this.url + '/clinique/departments/' + id);
    }
    getDoctorsList() {
        return this.http.get(this.url + '/clinique/doctorsList');
    }
    doctorCreate(data) {
        return this.http.post(this.url + '/clinique/doctorsList', data);
    }
    doctorPutById(id, data) {
        return this.http.put(this.url + '/clinique/doctorsList/' + id, data);
    }
    doctorDeleteById(id) {
        return this.http.delete(this.url + '/clinique/doctorsList/' + id);
    }
    getDoctorById(id) {
        return this.http.get(this.url + '/clinique/doctorsList/' + id);
    }
    getDoctorByDepartmentId(id) {
        return this.http.get(this.url + '/clinique/departments/doctors?departmentId=' + id);
    }
    getDoctorByDepartmentIdAvl(id) {
        return this.http.get(this.url + '/clinique/departments/doctors?departmentId=' + id + '&&availableInd=true');
    }
    getStaticIssues() {
        return this.http.get(this.url + '/clinique/staticdata/issues');
    }
    getStaticSuggestions() {
        return this.http.get(this.url + '/clinique/staticdata/suggestions');
    }
    getToothList() {
        return this.http.get(this.url + '/clinique/staticdata/tooth');
    }
    getFrequentlyUsed() {
        return this.http.get(this.url + '/clinique/medicines/frequentlyUsed');
    }
    getUserSearchRole(id, id1) {
        return this.http.get(this.url + '/clinique/roles/users?roleCd=' + id + '&&orgId=' + id1);
    }
    fullreportDetails(data) {
        return this.http.post(this.url + '/clinique/workflows/fullDetailsReport', data);
    }
    medgroupDetails(data) {
        return this.http.post(this.url + '/clinique/medGroup', data);
    }
    getgroupMedicine(id) {
        return this.http.get(this.url + '/clinique/medGroup/' + id);
    }
    putgroupMedicine(id, data) {
        return this.http.put(this.url + '/clinique/medGroup/' + id, data);
    }
    searchmedgroupDetails(data) {
        return this.http.post(this.url + '/clinique/medGroup/search', data);
    }

    getdoctorsById(id) {
        return this.http.get(this.url + '/clinique/doctorsList/' + id);
    }

    getTimeSlots(date, post) {
        return this.http.post(
            window['url'] + this.url2 + 'schedule/date/' +
            date +
            '/availableTimeSlots',
            post
        );
    }

    getAppointmentsTime(date, post) {
        return this.http.post(
            window['url'] + this.url2 + 'schedule/date/' +
            date +
            '/availableTimeSlots',
            post
        );
    }

    getAppointmentsbyWindow(date, post) {
        return this.http.post(
            window['url'] + this.url2 + 'schedule/date/' +
            date +
            '/availableTimeSlotsWindow',
            post
        );
    }


    getBlocks(id, date) {
        return this.http.get(
            window['url'] + this.url2 + 'appointments?typeId=2&targetSource=' +
            id +
            '&date=' +
            date
        );
    }
    unBlockSlot(id) {
        return this.http.put(
            window['url'] + this.url2 + 'appointments/' + id + '/unblockAppt',
            null
        );
    }

    getCalendar(date, data) {
        return this.http.post(
            window['url'] + this.url2 + 'schedule/date/' + date + '/getTimeSlots',
            data
        );
    }

    getAppointReq2(data) {
        return this.http.post(
            window['url'] + '/clinique/appointments/search',
            data
        );
    }

    postAppointments(post) {
        return this.http.post(window['url'] + this.url2 + 'appointments', post);
    }

    getAppointReqById(id) {
        return this.http.get(window['url'] + '/clinique/requests/' + id);
    }

    requestReject(id, data) {
        return this.http.put(
            window['url'] + '/clinique/requests/' + id + '/reject',
            data
        );
    }

    getRejectReason() {
        return this.http.get(window['url'] + '/clinique/staticdata/rejectReason');
    }


    manageCalGet(data) {
        return this.http.post(
            window['url'] + this.url2 + 'schdlConfigs/capacityDef/search',
            data
        );
    }

    manageCalPost(data) {
        return this.http.post(
            window['url'] + this.url2 + 'schdlConfigs/capacityDef',
            data
        );
    }

    deleteSlots(id) {
        return this.http.delete(
            window['url'] + this.url2 + 'schdlConfigs/capacityDef/' + id
        );
    }

    appointmentgetByIdbooking(id) {
        return this.http.get(window['url'] + '/clinique/appointments' + id);
    }

    appointmentbooking(post) {
        return this.http.post(
            window['url'] +
            '/clinique/appointments',
            post
        );
    }


    appointmentconfirm(id, data) {
        return this.http.put(
            window['url'] +
            '/clinique/appointments/' + id + '/confirm', data);
    }


    appointmentcancel(id, data) {
        return this.http.put(
            window['url'] +
            '/clinique/appointments/' + id + '/cancel', data);
    }

    appointmentcomplete(id, data) {
        return this.http.put(
            window['url'] +
            '/clinique/appointments/' + id + '/complete', data);
    }

    appointmentreschedule(id, data) {
        return this.http.put(
            window['url'] +
            '/clinique/appointments/' + id + '/reschedule', data);
    }

    getAllnewnextentity() {
        return this.http.get(this.url + '/clinique/entities/nextUHId');
    }

    appointmentPut(id, data) {
        return this.http.put(
            window['url'] +
            '/clinique/entities/' + id, data);
    }
    appointment(id, data) {
        return this.http.put(window['url'] + '/clinique/appointments/' + id + '/arrived', data);
    }

    treatmentCreate(data) {
        return this.http.post(this.url + '/clinique/treatments', data);
    }
    treatmentPutById(id, data) {
        return this.http.put(this.url + '/clinique/treatments/' + id, data);
    }
    getTreatmentById(id) {
        return this.http.get(this.url + '/clinique/treatments/' + id + '?includeAllDtls=true');
    }
    getTreatmentList() {
        return this.http.get(this.url + '/clinique/treatments');
    }
    treatmentDeleteById(id) {
        return this.http.delete(this.url + '/clinique/treatments/' + id);
    }
    visitStaticData() {
        return this.http.get(this.url + '/clinique/staticdata/visitType');
    }
    getTreatmentIssusList(id) {
        return this.http.get(this.url + '/clinique/treatments/' + id + '/issues');
    }
    billingCreate(data) {
        return this.http.post(this.url + '/clinique/billing', data);
    }
    getBilling() {
        return this.http.get(this.url + '/clinique/billing');
    }
    getBillingById(id) {
        return this.http.get(this.url + '/clinique/billing/' + id);
    }
    billingDeleteById(id) {
        return this.http.delete(this.url + '/clinique/billing/' + id);
    }
    billingPutById(id, data) {
        return this.http.put(this.url + '/clinique/billing/' + id, data);
    }
    getbillingDate(data1, data2) {
        return this.http.get(this.url + '/clinique/billing/statement?fromDate=' + data1 + '&&toDate=' + data2);
    }
    servicesCreate(data) {
        return this.http.post(this.url + '/clinique/services', data);
    }
    getServices() {
        return this.http.get(this.url + '/clinique/services');
    }
    getServicesById(id) {
        return this.http.get(this.url + '/clinique/services/' + id);
    }
    servicesDeleteById(id) {
        return this.http.delete(this.url + '/clinique/services/' + id);
    }
    servicesPutById(id, data) {
        return this.http.put(this.url + '/clinique/services/' + id, data);
    }
    getBilliingDetails(mobileNbr) {
        this.urlCall = '/clinique/entities/external?mobileNbr=' + mobileNbr;
        // if (mobileNbr != null && mobileNbr != undefined && mobileNbr != '') {
        //     this.urlCall = this.urlCall + '&&mobileNbr=' + mobileNbr;
        // }
        return this.http.get(this.url + this.urlCall,);
    }
    vendorCreate(data) {
        return this.http.post(this.url + '/clinique/vendors', data);
    }
    getvendorList() {
        return this.http.get(this.url + '/clinique/vendors??activeOnly=false');
    }
    vendorPutById(id, data) {
        return this.http.put(this.url + '/clinique/vendors/' + id, data);
    }
    getvendorById(id) {
        return this.http.get(this.url + '/clinique/vendors/' + id);
    }
    vendorDeleteById(id) {
        return this.http.delete(this.url + '/clinique/vendors/' + id);
    }
    ordersCreate(data) {
        return this.http.post(this.url + '/clinique/orders', data);
    }
    getOrdersById(id) {
        return this.http.get(this.url + '/clinique/orders/' + id);
    }
    getOrdersList() {
        return this.http.get(this.url + '/clinique/orders');
    }
    userSearchBy(orgId, data1) {
        return this.http.get(this.url + '/clinique/roles/users?roleCd=P' + '&&firstName=' + data1 + '&&orgId=' + orgId);
    }
    ordersDeleteById(id) {
        return this.http.delete(this.url + '/clinique/orders/' + id);
    }
    ordersPutById(id, data) {
        return this.http.put(this.url + '/clinique/orders/' + id, data);
    }
    recordPaymentPutById(id, data) {
        return this.http.put(this.url + '/clinique/billing/' + id + '/recordPayment', data);
    }
    // getOrders(id,id1,id2,id3) {
    //     return this.http.get(this.url + '/clinique/orders?patientId=' + id + '&contactNbrMob='+id1+ '&fromDateStr='+id2+ '&toDateStr='+id3);
    // }
    // getOrders(patientId: number, contactNbrMob: string, fromDateStr: string, toDateStr: string): Observable<any> {
    //     const url = `${this.baseUrl}/clinique/orders`;

    //     const params = new HttpParams()
    //     .set('patientId', patientId !== undefined ? patientId.toString() : '')
    //     .set('contactNbrMob', contactNbrMob || '')
    //     .set('fromDateStr', fromDateStr || '')
    //     .set('toDateStr', toDateStr || '');

    //     return this.http.get(url, { params });
    //   }
    getOrders(id, id1, id2, id3) {
        // Check if id1 and id are defined before calling toString()
        const patientIdParam = id !== undefined ? '&patientId=' + id.toString() : '';
        const contactNbrMobParam = id1 !== undefined ? '&contactNbrMob=' + id1 : '';

        const url = `${this.url}/clinique/orders?fromDateStr=${id2}&toDateStr=${id3}${patientIdParam}${contactNbrMobParam}`;

        return this.http.get(url);
    }
    getPatientDetail(serviceId, mobileNbr) {
        // this.loader = true;
        this.urlCall = '/clinique/entities/external?serviceId=' + serviceId;
        if (mobileNbr != null && mobileNbr != undefined && mobileNbr != '') {
            this.urlCall = this.urlCall + '&&mobileNbr=' + mobileNbr;

        }
        this.urlCall = this.urlCall + '&&returnMultiple=true';
        // .pipe(tap(res => this.loader = false));
        return this.http.get(this.url + this.urlCall,);
    }
    getPatientDetail1(mobileNbr) {
        // this.loader = true;
        this.urlCall = '/clinique/entities/external?mobileNbr=' + mobileNbr;
        // if (mobileNbr != null && mobileNbr != undefined && mobileNbr != '') {
        //     this.urlCall = this.urlCall + '&&mobileNbr=' + mobileNbr;

        // }
        this.urlCall = this.urlCall + '&&returnMultiple=true';
        // .pipe(tap(res => this.loader = false));
        return this.http.get(this.url + this.urlCall,);
    }

    treatmentTemCre(data) {
        return this.http.post(this.url + '/clinique/treatmentSummaryTemplate', data);
    }

    gettemplateTreatmentById(id, data) {
        return this.http.get(this.url + '/clinique/treatmentSummaryTemplate?treatmentId=' + id + '&&summaryName=' + data);
    }

    treatmenttemplateDeleteById(id) {
        return this.http.delete(this.url + '/clinique/treatmentSummaryTemplate/' + id);
    }

    getTreatmentTemplateList() {
        return this.http.get(this.url + '/clinique/treatmentSummaryTemplate/all');
    }

    treatmentDianosispost(id1, id2, data) {
        return this.http.post(this.url + '/clinique/diagnosis/' + id1 + '/treatments/' + id2, data);
    }

    treatmentDianosisdel(id1, id2) {
        return this.http.delete(this.url + '/clinique/diagnosis/' + id1 + '/treatments/' + id2);
    }

    getalldiagtretListBypatient(id) {
        return this.http.get(this.url + '/clinique/diagnosis/treatments?patientId=' + id);
    }

    getallAgreement() {
        return this.http.get(this.url + '/clinique/agreements');
    }
    getAgreementById(Id) {
        return this.http.get(this.url + '/clinique/agreements/' + Id);
    }
    editAgreement(id, data) {
        return this.http.put(this.url + '/clinique/agreements/' + id, data);
    }
    agreeCreate(data) {
        return this.http.post(this.url + '/clinique/agreements', data);
    }
    getAllAgreementsOrg(Id) {
        return this.http.get(this.url + '/clinique/agreements?orgId=' + Id);
    }
    getAgreementType() {
        return this.http.get(this.url + '/clinique/staticdata/agreementType');
    }
    getAgreementsType() {
        return this.http.get(this.url + '/clinique/agreements?type=5&&activeOnly=true');
    }
    gettemplateTreatmentBysittingId(id, data) {
        return this.http.get(this.url + '/clinique/treatmentSummaryTemplate?treatmentId=' + id + '&&sittingNbr=' + data);
    }
    treatmentSummary(data) {
        return this.http.post(this.url + '/clinique/diagnosis/treatmentSummary', data);
    }
    getsummarybyDiagnosis(Id) {
        return this.http.get(this.url + '/clinique/diagnosis/treatmentSummary?patientId=' + Id);
    }
    gettemplateTreatmentBytemplateId(id) {
        return this.http.get(this.url + '/clinique/treatmentSummaryTemplate/' + id);
    }
    puttemplateTreatmentBytemplateId(id, data) {
        return this.http.put(this.url + '/clinique/treatmentSummaryTemplate/' + id, data);
    }
    getmedicalcampList() {
        return this.http.get(this.url + '/clinique/medicalCamp');
    }
    medicalcampDeleteById(id) {
        return this.http.delete(this.url + '/clinique/medicalCamp/' + id);
    }
    getmedicalcampById(id) {
        return this.http.get(this.url + '/clinique/medicalCamp/' + id);
    }
    medicalcampCreate(data) {
        return this.http.post(this.url + '/clinique/medicalCamp', data);
    }
    medicalcampPutById(id, data) {
        return this.http.put(this.url + '/clinique/medicalCamp/' + id, data);
    }
    getnavigationchartList() {
        return this.http.get(this.url + '/clinique/navigationChart');
    }
    navigationchartDeleteById(id) {
        return this.http.delete(this.url + '/clinique/navigationChart/' + id);
    }
    getnavigationcampById(id) {
        return this.http.get(this.url + '/clinique/navigationChart/' + id);
    }
    navigationchartCreate(data) {
        return this.http.post(this.url + '/clinique/navigationChart', data);
    }
    navigationchartPutById(id, data) {
        return this.http.put(this.url + '/clinique/navigationChart/' + id, data);
    }
    deleteitemDocument1(id) {
        return this.http.get(this.url + '/clinique/navigationChart/' + id);
    }
    getcafeteriaList(data) {
        return this.http.post(this.url + '/clinique/cafeterias/search', data);
    }
    cafeteriaDeleteById(id) {
        return this.http.delete(this.url + '/clinique/cafeterias/' + id);
    }
    getcafeteriasById(id) {
        return this.http.get(this.url + '/clinique/cafeterias/' + id);
    }
    cafeteriasCreate(data) {
        return this.http.post(this.url + '/clinique/cafeterias', data);
    }
    cafeteriasPutById(id, data) {
        return this.http.put(this.url + '/clinique/cafeterias/' + id, data);
    }
    getcafeteriamenusList(data) {
        return this.http.post(this.url + '/clinique/cafeteriaMenus/search', data);
    }
    cafeteriaMenusDeleteById(id) {
        return this.http.delete(this.url + '/clinique/cafeteriaMenus/' + id);
    }
    getcafeteriaMenusById(id) {
        return this.http.get(this.url + '/clinique/cafeteriaMenus/' + id);
    }
    cafeteriaMenusCreate(data) {
        return this.http.post(this.url + '/clinique/cafeteriaMenus', data);
    }
    cafeteriaMenusPutById(id, data) {
        return this.http.put(this.url + '/clinique/cafeteriaMenus/' + id, data);
    }
    eventCreate(data) {
        return this.http.post(this.url + '/clinique/event', data);

    }
    eventPutById(id, data) {
        return this.http.put(this.url + '/clinique/event/' + id, data);

    }
    geteventById(id) {
        return this.http.get(this.url + '/clinique/event/' + id);
    }
    eventDeleteById(id) {
        return this.http.delete(this.url + '/clinique/event/' + id);
    }
    geteventList() {
        return this.http.get(this.url + '/clinique/event');
    }
    // getEvent(id, id1) {
    //     const url = `${this.url}/clinique/event?name=${id}&venue=${id1}`;
    //     return this.http.get(url);
    // }
    getEvent(id, id1) {
        this.urlCall = '/clinique/event?';
        if (id != null && id != undefined && id != '') {
            this.urlCall = this.urlCall + '&&name=' + id;
        }
        if (id1 != null && id1 != undefined && id1 != '') {
            this.urlCall = this.urlCall + '&&venue=' + id1;
        }

        return this.http.get(this.url + this.urlCall,);
    }
    getalertsList(data) {
        return this.http.post(this.url + '/clinique/alerts/search', data);
    }
    alertsDeleteById(id) {
        return this.http.delete(this.url + '/clinique/alerts/' + id);
    }
    getalertsById(id) {
        return this.http.get(this.url + '/clinique/alerts/' + id);
    }
    alertsCreate(data) {
        return this.http.post(this.url + '/clinique/alerts', data);
    }
    alertPutById(id, data) {
        return this.http.put(this.url + '/clinique/alerts/' + id, data);

    }
    getDocumentbychartId(id) {
        return this.http.get(this.url + "/clinique/documents/" + id, { responseType: 'blob' });
    }
    deleteitemDocument(id) {
        return this.http.delete(this.url + "/clinique/documents/" + id + "/documents");
    }
    uploadChartFile1(name, files) {
        const docstore = "cliniqueDOCS";
        const id1 = localStorage.getItem('organizationId');
        const id2 = localStorage.getItem('currentUserId');
        const formData: FormData = new FormData();
        formData.append('file', files, name);
        return this.http.post(this.url + "/clinique/documents?docStore=" + docstore + "&displayName=" + name + "&description=" + name + "&orgId=" + id1 + "&imageDocInd=true", formData)
    }
    uploadChartFile(name, files): Observable<any> {
        const docstore = "cliniqueDOCS";
        const id1 = localStorage.getItem('organizationId');
        const id2 = localStorage.getItem('currentUserId');
        const formData: FormData = new FormData();
        formData.append('file', files, name);
        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: {
                // Add any required headers here, such as authorization or content-type
                // Example content-type for file upload
                // 'Content-Type': 'multipart/form-data',
            },
        };
        const url =
            this.url + '/clinique/documents?docStore=' + docstore + '&displayName=' + name + '&description=' + name + '&orgId=' + id1 + '&imageDocInd=true';
        return new Observable((observer) => {
            fetch(url, requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((responseData) => {
                    // Emit the response data to subscribers
                    observer.next(responseData);
                    observer.complete();
                })
                .catch((error) => {
                    // Emit an error to subscribers if an error occurs
                    observer.error(error);
                });
        });
    }

}


