import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/api-calls/auth.service';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { DoctorSetupComponent } from '../doctor-setup/doctor-setup.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-services-create',
  templateUrl: './services-create.component.html',
  styleUrls: ['./services-create.component.scss']
})
export class ServicesCreateComponent implements OnInit {

  alldepart: any = [];
  name;
  desc;
  code;
  doctoredit: boolean;
  loader;
  url;
  id;
  treatmentObj: any = [];
  languageCode: string;
  items: any = [];
  dataSource: any;
  msgContent: string;
  avgTime;
  langCd: any;
  sittingCount: any;
  activeInd: any;
  issues: any = [];
  checklocation1: any = [];
  handler: any;
  checkloc: any =[];
  assetTypeVendorTagList: any;
  mobile: any;
  patientType: number;
  patientObject: any;
  documentNbr: any;
  secCntryNum: any;
  secMobile: any;
  countrynum: any;
  age: any;
  entype: any;
  entryType: any;
  visitype: any;
  visitType: any;
  wardNbr: any;
  receiptNbr: any;
  doctorName: any;
  doctorId: any;
  refId: any;
  customerName: any;
  customerGstNo: any;
  customerPhNo: any;
  billTotal: any;
  cgst: any;
  sgst: any;
  payTotal: any;
  discountAmt: any;
  billingObj: any = [];
  additionalInfo: any;
  unitPrice: any;
  cost: any;
  totalSessions: any;
  serviceName: any;
  description: any;
  avlQty: any;
  servicesInd: boolean=true;
  constructor(public data: DataService, private translate: TranslateService, public app: AppService, public auth: AuthService) { }

  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.url = this.app.urlData();
    if(this.servicesInd  ==true ){
      this.avlQty =1
    }else{
      this.avlQty =""
    }
    if (this.url != 'services-create') {
      this.doctoredit = true;
      this.id = this.app.urlData();
      this.data.getServicesById(this.id).subscribe((res: any) => {
        this.billingObj = res;
        this.activeInd=true,
        this.servicesInd=res.serviceInd;
        this.id = res.id;
        this.serviceName = res.serviceName;
        this.description = res.description;
        this.totalSessions = res.totalSessions;
        this.cost = res.cost;
        this.avlQty = res.avlQty;
        this.unitPrice = res.unitPrice;
        this.additionalInfo = res.additionalInfo;

      }, err => {
        this.loader = false;
      })
    } else {
      console.log('jj')
    }

  }
  servicesIn() {
    if(this.servicesInd  == false ){
      this.avlQty =1
    }else{
      this.avlQty =""
    }
  }
  treatmentCreate() {
    this.loader = true;
    let assetTypeVendorTagList = this.checkloc ? this.checkloc : this.checklocation1;
    if (assetTypeVendorTagList) {
      assetTypeVendorTagList.map((res) => {
        Object.assign(res, { issueId: res.id })
      })
    }
    var post = {
      "serviceName": this.serviceName.toUpperCase(),
      "description": this.description,
      "totalSessions": this.totalSessions,
      "cost": this.cost,
      "avlQty": this.avlQty,
      "unitPrice": this.unitPrice,
      "activeInd":true,
      "serviceInd":this.servicesInd,
      "packageInd":true,
      "additionalInfo": this.additionalInfo,
  

    }
    this.data.servicesCreate(post).subscribe((res: any) => {
      this.loader = false;
      this.app.openPage('services');
      // this.app.openSnackBar('Doctor Created Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("services Created Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })

    }, err => {
      this.loader = false;
    })
  }
  cancel() {
    this.app.openPage('services')
  }

  treatmentUpdate() {
    this.loader = true;
    let assetTypeVendorTagList = this.checkloc ? this.checkloc : this.checklocation1;
    if (assetTypeVendorTagList) {
      assetTypeVendorTagList.map((res) => {
        Object.assign(res, { issueId: res.id })
      })
    }
    this.billingObj.serviceName = this.serviceName.toUpperCase();
    this.billingObj.id = this.id;
    this.billingObj.description = this.description;
    this.billingObj.totalSessions = this.totalSessions;
    this.billingObj.cost = this.cost;
    this.billingObj.avlQty = this.avlQty;
    this.billingObj.avlQty = this.avlQty;
    this.billingObj.unitPrice = this.unitPrice;
    this.billingObj.additionalInfo  = this.additionalInfo;
    this.billingObj.serviceInd  = this.servicesInd;
    // this.billingObj.additionalInfo = assetTypeVendorTagList
    this.activeInd = true;
    this.data.servicesPutById(this.id, this.billingObj).subscribe(res => {
      this.loader = false;
      this.app.openPage('services');
      // this.app.openSnackBar('Doctor Updated Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("services Updated Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })
    }, err => {
      this.loader = false;
    })

  }

  focusOutFunction(data) {
    if (this.refId || this.mobile) {
      var serviceId;
      if (this.patientType == 1 || this.patientType == 3) {
        serviceId = 1;
      }
      if (this.patientType == 2 || this.patientType == 4) {
        serviceId = 2;
      }
      this.data.getPatientDetails(this.refId, serviceId, this.mobile).subscribe((res) => {
        if (res) {
          this.patientObject = res;
          this.name = this.patientObject.name;
          this.documentNbr = this.patientObject.documentNbr;
          if (this.patientObject.entityType != 2) {
            var len = this.patientObject.contactNbr.length;
            if (this.patientObject.secContactNbr) {
              var len1 = this.patientObject.secContactNbr.length;
              len1 = len1 - 10;
            }
            len = len - 10;
            if (len1) {
              this.secCntryNum = this.patientObject.secContactNbr.slice(
                0,
                len1
              );
              this.secMobile = this.patientObject.secContactNbr.slice(-10);
            }
            this.countrynum = this.patientObject.contactNbr.slice(0, len);
            this.mobile = this.patientObject.contactNbr.slice(-10);
          }
          this.refId = this.patientObject.patientRefId;
          this.age = this.patientObject.age;
          if (this.patientObject.entryType) {
            this.entype.map((res) => {
              if (res.id == this.patientObject.entryType) {
                this.entryType = res.name;
              }
            });
          }
          if (this.patientObject.visitType) {
            this.visitype.map((res) => {
              if (res.id == this.patientObject.visitType) {
                this.visitType = res.name;
              }
            });
          }
          if (this.patientObject.expctdReportingTime) {
            let r = this.patientObject.expctdReportingTime;
          }
          this.wardNbr = this.patientObject.wardNbr;
          this.receiptNbr = this.patientObject.receiptNbr;
          this.doctorName = this.patientObject.doctorName;
          this.doctorId = this.patientObject.doctorId;
          this.app.aged = this.patientObject.aged;
          this.app.wheeled = this.patientObject.wheeled;
          this.app.nri = this.patientObject.internationalPatient;
          this.app.callRequired = this.patientObject.callRequired;
        }
      });
    }
  }

}
