import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cafeteria-menus',
  templateUrl: './cafeteria-menus.component.html',
  styleUrls: ['./cafeteria-menus.component.scss']
})
export class CafeteriaMenusComponent implements OnInit {


  alldepart:any=[];
  loader;
  ad;
  page;
  component;
  msgContent: string = "";
  languageCode: string;
  phoneNo: any;
  name: any;
  constructor(private data: DataService, private translate: TranslateService, public app: AppService) { }

  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.loader =true;
    let post={
      
    }
    this.data.getcafeteriamenusList(post).subscribe((res:any)=>{
      if(res){
       this.alldepart = res;
       this.loader=false;
       }else{
         this.alldepart=[];
         this.loader=false;
         this.msgContent = "No vendor Available"
      } 

    }),err=>{
      this.loader=false;
    }

  }

  newCafeteria(){
    this.app.openPage('cafeteria-menus-create')
  }
  edit(id){
    this.app.openPage('cafeteria-menus-edit/' + id)
  }
  delete(id){
    this.loader = true;
    this.data.cafeteriaMenusDeleteById(id).subscribe(res=>{
      this.loader = false;
      this.ngOnInit();
      // this.app.openSnackBar('Deleted Successfully !!', 'success-snackbar');   
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Deleted Successfully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
        })
    })
      
    },err=>{
      this.loader = false;
    })
  }
  search(){
    this.loader =true;
    let post={
      "name":this.name,
    }
    this.data.getcafeteriamenusList(post).subscribe((res:any)=>{
      if(res){
       this.alldepart = res;
       this.loader=false;
       }else{
         this.alldepart=[];
         this.loader=false;
         this.msgContent = "No vendor Available"
      } 

    }),err=>{
      this.loader=false;
    }
  }
  clear() {
    this.name = "";
    this.ngOnInit();

  }
}
