import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import {MatTableDataSource, MatSort} from '@angular/material';


@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit {

    select1 = 0;
    select2 = 0;
    select1Id;
    dropData:any = [];
    dropData2:any = [];
    selectDate=new Date();
    selectDate1=new Date();
    date1;date2;
    loader:boolean;
    selected:boolean = false;
    showTable:boolean=false;
    displayedColumns = ['activityId', 'comments', 'eventTs'];
    dataSource: any;
    sort;
    @ViewChild(MatSort) set content(content: ElementRef) {
      this.sort = content;
      if (this.sort){
         this.dataSource.sort = this.sort;
      }
    }
    applyFilter(filterValue: string) {
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase(); 
      this.dataSource.filter = filterValue;
    } 
    activityLog:any=[];
    msg='Select Datasets To Show Reports';

    public bar_ChartData = [];
    
    
    
    public bar_ChartOptions = {
        title: '',
        legend: {position: 'bottom'},
        theme: 'material',
        vAxis: {
          gridlines: {
              color: 'transparent'
          }
        },
        hAxis: {
          gridlines: {
              color: 'transparent'
          }
        }
    };



  constructor(public data:DataService,public app:AppService) { }

  ngOnInit() {
    this.getDrop1()
  }

  async getDrop1(){
    this.dropData = await this.data.getReport().toPromise()
  }

 async getDrop2(){
    if(this.select1 !=8){
      this.showTable=false;
    this.dropData2 = JSON.parse(JSON.stringify(this.dropData[this.select1].dataSets));
    this.select1Id = this.dropData[this.select1].id
    this.selected = true;
    }
    else{
      this.selected = true;
      this.dropData2=[]
    }
    }
  

  async search(){
   
    if(this.selected){
   
      if(this.select1==8){
        this.loader=true;
        this.showTable=true
        this.date1 = this.selectDate.getFullYear() + '/' + ("0" + (this.selectDate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectDate.getDate()).slice(-2) + ' ' + '00:00:00'
        this.date2 = this.selectDate1.getFullYear() + '/' + ("0" + (this.selectDate1.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectDate1.getDate()).slice(-2) + ' ' + '23:59:59'
        this.activityLog = await this.data.activityLogs(this.date1, this.date2).toPromise();
        this.dataSource = new MatTableDataSource(this.activityLog)
        this.loader=false;
      }
      else{
      this.loader = true;
      this.bar_ChartData = [];
      let post = {}
      if(!this.select2){
        Object.assign(post,{dataSetId:this.select1Id})
      }
      else{
        Object.assign(post,{dataSetId:this.select2})
      }
      let data:any = await this.data.getDatabox(post).toPromise();
      if(data.legends.length > 0){
        this.bar_ChartOptions.title = data.name;
        let len1 = data.legends.length;
        let len2 = data.dataValues[0].length;
        if(len1 == len2){
          this.bar_ChartData.push(data.legends);
          this.bar_ChartData = this.bar_ChartData.concat(data.dataValues);
        }
        else{
          this.msg = 'No reports to show';
          this.bar_ChartData = []
        }
      }
      else{
        this.msg = 'No reports to show';
      }
    
      this.loader = false;
    
    // else{
    //   this.app.openSnackBar('Please select data','error-snackbar');
    // }
  
  }
}
  }

  clear(){
    // this.app.openSnackBar('Reports cleared successfully','success-snackbar');
    setTimeout(() => {
      this.select2 = 0;
      this.select1Id = null;
      this.select1 = 0;
      this.bar_ChartData = [];
      this.bar_ChartOptions.title = '';
      this.dropData2 = [];
      this.selected = false;
      this.msg = 'Select datasets to show reports'
    }, 500);
  }
  


}
