import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AuthService } from 'src/api-calls/auth.service';

@Component({
  selector: 'app-app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.scss']
})
export class AppSettingsComponent implements OnInit {

  data:any = [];
  index:any=[];
  loader:boolean =  true;
  
  constructor(public service:DataService,public auth:AuthService) { }

  async ngOnInit() {
    setTimeout(() => {
      this.conf();
     
      
    }, 1500);
  }

  save(){
    this.loader = true;
    this.index.map(res=>{
      this.data[res]
      this.service.postConfig(this.data[res].configs).subscribe(res=>{
        this.auth.getConfig()
        this.loader = false;
      })
    })

  }
  checked(data,index){
    this.index.push(index)
    this.index=this.index.filter((x,i,a)=>a.indexOf(x) == i );

  }
  change(data,index){
    this.index.push(index)
    this.index=this.index.filter((x,i,a)=>a.indexOf(x) == i );
  }
 cancel(){
  history.back();
 }
 async conf(){
  this.data= await this.auth.getConfig1().toPromise();
  this.data.map(res=>{
   res.configs.map(dat=>{
     if(dat.userValue=='true' )
      dat.userValue=true;
      if(dat.userValue=='false' )
      dat.userValue=false;
   })
  })
  this.loader = false;
 }
}
