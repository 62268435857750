import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/api-calls/auth.service';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-manageuser-edit',
  templateUrl: './manageuser-edit.component.html',
  styleUrls: ['./manageuser-edit.component.scss']
})
export class ManageuserEditComponent implements OnInit {
  usergetId;
  loader: boolean = true;
  userObject: any=[];
  firstname;
  lastname;
  mobile;
  emailid;
  gender;
  height;
  weight;
  region;
  bp;
  tobacco;
  alcoholic;
  age;
  allergic =[{name:'Yes',id:true},{name:'No',id:false},]
  languageCode: string;
  constructor(
    public data: DataService, 
    public app: AppService,
    private translate: TranslateService,
    public auth:AuthService
  ) { }

  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.usergetId = this.app.urlData();
    this.data.getRolesById(this.usergetId).subscribe((res: any) => {
      this.loader=false;
      this.userObject = res;
      this.firstname = this.userObject.firstName;
      this.lastname = this.userObject.lastName;
      this.mobile = this.userObject.contactNbrMob;
      this.emailid = this.userObject.emailId;
      this.gender = this.userObject.gender;
      this.age = this.userObject.age;
      this.height = this.userObject.height;
      this.weight = this.userObject.weight;
      this.region = this.userObject.region;
      this.bp = this.userObject.bp;
      if(this.userObject.tobacco)
        this.tobacco = 'Yes';
      else
        this.tobacco = 'No';
      if(this.userObject.alcoholic)
         this.alcoholic = 'Yes';
      else
         this.alcoholic = 'No';         
     },
    err=>{
      this.loader=false;
    })
  }

  save(){
      this.data.checkpointSave(this.usergetId, this.userObject).subscribe(res => {
      // this.app.openSnackBar('User Details Updated Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("User Details Updated Successfully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
        })
    })
      this.app.openPage('adminPatientSearch');
    },err=>{
      this.loader = false;
    })
  }
  cancel() {
    this.app.openPage('adminPatientSearch');
  }

}
