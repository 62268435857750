import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { DataService } from 'src/api-calls/data.service';
import { interval } from 'rxjs';

@Component({
  selector: 'app-clone-workq',
  templateUrl: './clone-workq.component.html',
  styleUrls: ['./clone-workq.component.scss']
})
export class CloneWorkqComponent implements OnInit {
  subcards: any = [];
  page: any = [];
  header: any = [];
  data: any = [];

  pushData: any = [];
  checkPoint:any=[];
  pushData1: any;
  pushData2: any = [];
  missedData: any = [];
  checkPointId;
  iStart;
  iEnd;

  pageNo = 1;
  url;
  users:any=[];
  showsub:string;
  showToken:string;
  showSubCheckpoint:boolean=false;
  subCheckPoint:any;
  patientCount;
  b;
  initialCount;
  emrgVipPatListDisplay:any=[];
  emrgVipPatList:any=[];
  emrgList:any=[];
  vipList:any=[];
  constructor(public app: AppService, public service: DataService) {

 
      interval(15000).subscribe(()=>{
        if(this.url=='newWorkQ'){
   
        this.refresh()
      }
      })
   
    window.onkeydown = function( e ) {
      var f = document.fullscreen;
      if  (e.key === "Escape" ) {
        event.preventDefault();
        app.showFull = false;
       
      }
      
    };
   }

  async ngOnInit() {
    let x = JSON.parse(localStorage.getItem('config'));
    let e = x.find(res => res.shortCdConst == "SCAPL");
    this.showsub = e.userValue;
     let a = x.find(res => res.shortCdConst == "TKNAUTO");
     this.showToken = a.userValue;
      this.b=x.find(res=>res.shortCdConst=="PATCNTPRPAGE");
      this.b =this.b.userValue-1
     this.iEnd = this.b;
     this.patientCount=this.iEnd+1;
     let c = x.find(res=> res.shortCdConst =="RSRVDSLTS");
     this.initialCount = c.userValue;
     for(let i=1;i<=this.initialCount;i++){
      this.emrgVipPatListDisplay.push({index:i})
    }

    this.url = window.location.href.split('/').pop();

    this.pushData = await this.service.getWorkqById(this.app.workQData).toPromise();
    var post={userId:this.pushData.checkpoint.personInCharge};
    this.service.searchUser(post).subscribe(res => {
      if(res!=null){
      this.users = res[0];
      }
    })
    if(this.pushData.checkpoint.subCheckpoints != undefined){
      this.showSubCheckpoint=true;
    }
    else{
      this.showSubCheckpoint=false;
    }
   
    this.missedData = await this.service.getMissed(this.app.workQData).toPromise();
    this.pushData = this.pushData.entities.sort((a, b) => { return a.positionInQ - b.positionInQ });
    this.pushData1 = this.pushData.filter(res => { return res.positionInQ == 0});
    this.emrgVipPatList = this.pushData.filter(res => { return res.positionInQ != 0 && res.positionInQ <= this.initialCount });
    this.pushData2 = this.pushData.filter(res => { return res.positionInQ > this.initialCount});
    this.emrgVipPatListDisplay.map((res,i)=>{
      this.emrgVipPatList.map((res1,j)=>{
        if(i==j){
          this.emrgVipPatListDisplay[i]=this.emrgVipPatList[j];
        }
    })
   })
    //  this.pushData2 = this.pushData.filter(res => { return res.positionInQ != 0 && !res.emrgPat && !res.vipPat });
    //  this.emrgList = this.pushData.filter(res => { return res.positionInQ > 0 && res.emrgPat});
    //  this.vipList = this.pushData.filter(res => { return res.positionInQ > 0 && res.vipPat });
    //  if(this.initialCount >= this.emrgList.length){
    //    this.emrgList.map(res=>{
    //      this.emrgVipPatList.push(res)
    //    })
    //   }
    //  else{
    //    this.emrgList.map((res,i)=>{
    //      if(this.initialCount>=i+1){
    //        this.emrgVipPatList.push(res)
    //      }
    //      else{
    //        this.pushData2.push(res);
    //        this.pushData2 = this.pushData2.sort((a, b) => { return a.positionInQ - b.positionInQ });
    //      }
    //    })
    //  }
    //  console.log(this.emrgVipPatList)
    //  if(this.initialCount >= this.emrgVipPatList.length){
    //    if(this.initialCount >= this.emrgVipPatList.length + this.vipList.length){
    //      this.vipList.map(res=>{
    //        this.emrgVipPatList.push(res)
    //      })
    //    }
    //    else{
    //      this.vipList.map((res,i)=>{
    //        if(this.initialCount>=i+1+this.emrgVipPatList.length){
    //          this.emrgVipPatList.push(res)
    //        }
    //        else{
    //          this.pushData2.push(res);
    //          this.pushData2 = this.pushData2.sort((a, b) => { return a.positionInQ - b.positionInQ });
    //        }
    //      })
    //    }
    //  }
    //  else{
    //    this.vipList.map(res=>{
    //      this.pushData2.push(res)
    //    })
    //  this.pushData2 = this.pushData2.sort((a, b) => { return a.positionInQ - b.positionInQ });
    //  }
    //  console.log(this.emrgVipPatList)
    //  if(this.emrgVipPatListDisplay.length>= this.emrgVipPatList.length){
    //    this.emrgVipPatListDisplay.map((res,i)=>{
    //      this.emrgVipPatList.map((res1,j)=>{
    //        if(i==j){
    //          this.emrgVipPatListDisplay[i]=this.emrgVipPatList[j];
    //        }
    //    })
    //   })
    //  }
    //  else{

    //  }
    this.pushData2.map((res, i) => {
      if (i > this.iEnd) {
        
      }
      else {
        this.data.push(res)
      }
    })
  }
  
  exit(){
    this.app.showFull = false;
  }

  async refresh(){

    this.data = [];
    var len = this.pushData2.length;
    this.missedData = await this.service.getMissed(this.app.workQData).toPromise();
    if(len > this.iEnd){
      this.iStart = this.iEnd;
      this.iEnd = this.iEnd + this.patientCount;
      this.pageNo = this.pageNo + 1;
      this.pushData2.map((res,i)=>{
        if(i > this.iStart && i <= this.iEnd){
          this.data.push(res)
        }
      })
    }
    else if(len <= this.iEnd){
      this.pageNo = 1;
      this.emrgVipPatListDisplay=[];
      this.iEnd = this.b;
      this.iStart = 0;
      this.emrgVipPatList =[];
      for(let i=1;i<=this.initialCount;i++){
        this.emrgVipPatListDisplay.push({index:i})
      }
      this.pushData = await this.service.getWorkqById(this.app.workQData).toPromise();
      this.pushData = this.pushData.entities.sort((a,b)=>{ return a.positionInQ - b.positionInQ });
      this.pushData1 = this.pushData.filter(res => { return res.positionInQ == 0});
      this.emrgVipPatList = this.pushData.filter(res => { return res.positionInQ != 0 && res.positionInQ <= this.initialCount });
      this.pushData2 = this.pushData.filter(res => { return res.positionInQ > this.initialCount});
      this.emrgVipPatListDisplay.map((res,i)=>{
        this.emrgVipPatList.map((res1,j)=>{
          if(i==j){
            this.emrgVipPatListDisplay[i]=this.emrgVipPatList[j];
          }
      })
     })
      // this.pushData2 = this.pushData.filter(res => { return res.positionInQ != 0 && !res.emrgPat && !res.vipPat });
      // this.emrgList = this.pushData.filter(res => { return res.positionInQ > 0 && res.emrgPat});
      // this.vipList = this.pushData.filter(res => { return res.positionInQ > 0 && res.vipPat });
      // if(this.initialCount >= this.emrgList.length){
      //   this.emrgList.map(res=>{
      //     this.emrgVipPatList.push(res)
      //   })
      //  }
      // else{
      //   this.emrgList.map((res,i)=>{
      //     if(this.initialCount>=i+1){
      //       this.emrgVipPatList.push(res)
      //     }
      //     else{
      //       this.pushData2.push(res);
      //       this.pushData2 = this.pushData2.sort((a, b) => { return a.positionInQ - b.positionInQ });
      //     }
      //   })
      // }
      // console.log(this.emrgVipPatList)
      // if(this.initialCount >= this.emrgVipPatList.length){
      //   if(this.initialCount >= this.emrgVipPatList.length + this.vipList.length){
      //     this.vipList.map(res=>{
      //       this.emrgVipPatList.push(res)
      //     })
      //   }
      //   else{
      //     this.vipList.map((res,i)=>{
      //       if(this.initialCount>=i+1+this.emrgVipPatList.length){
      //         this.emrgVipPatList.push(res)
      //       }
      //       else{
      //         this.pushData2.push(res);
      //         this.pushData2 = this.pushData2.sort((a, b) => { return a.positionInQ - b.positionInQ });
      //       }
      //     })
      //   }
      // }
      // else{
      //   this.vipList.map(res=>{
      //     this.pushData2.push(res)
      //   })
      // this.pushData2 = this.pushData2.sort((a, b) => { return a.positionInQ - b.positionInQ });
      // }
      // console.log(this.emrgVipPatList)
      // if(this.emrgVipPatListDisplay.length>= this.emrgVipPatList.length){
      //   this.emrgVipPatListDisplay.map((res,i)=>{
      //     this.emrgVipPatList.map((res1,j)=>{
      //       if(i==j){
      //         this.emrgVipPatListDisplay[i]=this.emrgVipPatList[j];
      //       }
      //   })
      //  })
      // }
      // else{
 
      // }
      this.pushData2.map((res,i)=>{
        if(i > this.iEnd){
         
        }
        else{
          this.data.push(res)
        }
      })
    }

  }

  async onClickRefresh(){
    this.data = [];
    this.emrgVipPatListDisplay=[];
    this.emrgVipPatList =[];
    for(let i=1;i<=this.initialCount;i++){
      this.emrgVipPatListDisplay.push({index:i})
    }
    this.missedData = await this.service.getMissed(this.app.workQData).toPromise();
    this.pushData = await this.service.getWorkqById(this.app.workQData).toPromise();
    this.pushData = this.pushData.entities.sort((a,b)=>{ return a.positionInQ - b.positionInQ });
    this.pushData1 = this.pushData.filter(res => { return res.positionInQ == 0});
    this.emrgVipPatList = this.pushData.filter(res => { return res.positionInQ != 0 && res.positionInQ <= this.initialCount });
    this.pushData2 = this.pushData.filter(res => { return res.positionInQ > this.initialCount});
    this.emrgVipPatListDisplay.map((res,i)=>{
      this.emrgVipPatList.map((res1,j)=>{
        if(i==j){
          this.emrgVipPatListDisplay[i]=this.emrgVipPatList[j];
        }
    })
   })
    // this.pushData2 = this.pushData.filter(res => { return res.positionInQ != 0 && !res.emrgPat && !res.vipPat });
    //  this.emrgList = this.pushData.filter(res => { return res.positionInQ > 0 && res.emrgPat});
    //  this.vipList = this.pushData.filter(res => { return res.positionInQ > 0 && res.vipPat });
    //  if(this.initialCount >= this.emrgList.length){
    //    this.emrgList.map(res=>{
    //      this.emrgVipPatList.push(res)
    //    })
    //   }
    //  else{
    //    this.emrgList.map((res,i)=>{
    //      if(this.initialCount>=i+1){
    //        this.emrgVipPatList.push(res)
    //      }
    //      else{
    //        this.pushData2.push(res);
    //        this.pushData2 = this.pushData2.sort((a, b) => { return a.positionInQ - b.positionInQ });
    //      }
    //    })
    //  }
    //  console.log(this.emrgVipPatList)
    //  if(this.initialCount >= this.emrgVipPatList.length){
    //    if(this.initialCount >= this.emrgVipPatList.length + this.vipList.length){
    //      this.vipList.map(res=>{
    //        this.emrgVipPatList.push(res)
    //      })
    //    }
    //    else{
    //      this.vipList.map((res,i)=>{
    //        if(this.initialCount>=i+1+this.emrgVipPatList.length){
    //          this.emrgVipPatList.push(res)
    //        }
    //        else{
    //          this.pushData2.push(res);
    //          this.pushData2 = this.pushData2.sort((a, b) => { return a.positionInQ - b.positionInQ });
    //        }
    //      })
    //    }
    //  }
    //  else{
    //    this.vipList.map(res=>{
    //      this.pushData2.push(res)
    //    })
    //  this.pushData2 = this.pushData2.sort((a, b) => { return a.positionInQ - b.positionInQ });
    //  }
    //  console.log(this.emrgVipPatList)
    //  if(this.emrgVipPatListDisplay.length>= this.emrgVipPatList.length){
    //    this.emrgVipPatListDisplay.map((res,i)=>{
    //      this.emrgVipPatList.map((res1,j)=>{
    //        if(i==j){
    //          this.emrgVipPatListDisplay[i]=this.emrgVipPatList[j];
    //        }
    //    })
    //   })
    //  }
    //  else{

    //  }

    this.pushData2.map((res,i)=>{
      if(i > this.iEnd){
     
      }
      else{
        this.data.push(res)
      }
    })
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.url="";
  }

}
