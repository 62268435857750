import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-notification',
  templateUrl: './show-notification.component.html',
  styleUrls: ['./show-notification.component.scss']
})
export class ShowNotificationComponent implements OnInit {

  constructor() { }
  loader:boolean = false;
  ngOnInit() {
  }

}
