import { Component, OnInit,Inject} from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { WorqMobComponent } from '../worq-mob/worq-mob.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-worq-mpop',
  templateUrl: './worq-mpop.component.html',
  styleUrls: ['./worq-mpop.component.scss']
})
export class WorqMpopComponent implements OnInit {
  
  items:any=[];
  subCheckPoints:any=[];
  checkPoint:any=0;
  subCheckPointId:any=0;

  loader:boolean = true;
  showSubCheck:string;
  languageCode: string;

  constructor(public dialogRef: MatDialogRef<WorqMobComponent>, private translate: TranslateService, public app: AppService,
    @Inject(MAT_DIALOG_DATA) public data, public dialog: MatDialog, public service: DataService) { }

  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.service.getRolesById(localStorage.getItem('currentUserId')).subscribe((res:any)=>{
       this.items=res.userCheckpoints;
       this.items.sort(function(a, b){
        var nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase();
        if(nameA < nameB) { return -1; }
        if(nameA > nameB) { return 1; }
          return 0;
        })
       if(sessionStorage.getItem('checkId')){
        this.checkPoint=sessionStorage.getItem('checkId');
       }
       if(sessionStorage.getItem('subCheckId')){
        this.check();
        this.subCheckPointId=sessionStorage.getItem('subCheckId');
      }
      this.loader = false;
    })
    let x = JSON.parse(localStorage.getItem('config'));
    let e = x.find(res => res.shortCdConst == "SCAPL");
    this.showSubCheck = e.userValue;
  }

  check(){
    this.subCheckPointId=0;
    this.service.getCheckpointById(this.checkPoint).subscribe(res=>this.subCheckPoints = res);
 
  }

  final(){
    if (this.subCheckPoints.subCheckpoints && this.subCheckPointId == null && this.subCheckPointId ==undefined) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })

    }
    else{
    sessionStorage.setItem('checkId',this.checkPoint)
    sessionStorage.setItem('subCheckId',this.subCheckPointId)
    this.dialogRef.close(1);
    }
  }

  cancel(){
    this.dialogRef.close();
  }



}
