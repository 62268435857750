import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/api-calls/auth.service';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { DoctorSetupComponent } from '../doctor-setup/doctor-setup.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-orders',
  templateUrl: './create-orders.component.html',
  styleUrls: ['./create-orders.component.scss']
})
export class CreateOrdersComponent implements OnInit {
  checkloc: any[] = [
  ];
  alldepart: any = [];
  name;
  desc;
  code;
  doctoredit: boolean;
  loader;
  url;
  id;
  treatmentObj: any = [];
  languageCode: string;
  items: any = [];
  dataSource: any;
  msgContent: string;
  avgTime;
  langCd: any;
  sittingCount: any;
  activeInd: any;
  issues: any = [];
  checklocation1: any = [];
  handler: any;
  selectdate;
  selectdate1;
  // checkloc: any = [];
  assetTypeVendorTagList: any;
  mobile: any;
  patientType: number;
  patientObject: any;
  documentNbr: any;
  secCntryNum: any;
  secMobile: any;
  countrynum: any;
  age: any;
  entype: any;
  entryType: any;
  visitype: any;
  visitType: any;
  timer: any;
  wardNbr: any;
  receiptNbr: any;
  doctorName: any;
  doctorId: any;
  refId: any;
  customerName: any;
  customerGstNo: any;
  customerPhNo: any;
  billTotal: any;
  cgst: any;
  sgst: any;
  payTotal: any;
  discountAmt: any;
  billingObj: any;
  services: any = [];
  quantity: any;
  billing: any = [];
  billDetailId: any;
  vendor: any;
  service: any;
  patient: any;
  orgId=localStorage.getItem('organizationId');
  patientId: any;
  patientName: any;
  allpatientId: any;
  patientContactNbr: any;
  serviceName: any;
  vendorName: any;
  vendorContactNbr: any;
  order: any = ([] = [
    { name: "Pending ", id: "1" },
    { name: "Approval ", id: "2" },
  ]);
  constructor(public data: DataService, private translate: TranslateService, public app: AppService, public auth: AuthService) { }

  ngOnInit() {
    this.getServices();
    this. getVendor();
    // this.patientSearch();
    this.languageCode = localStorage.getItem('lang');
    this.url = this.app.urlData();
    if (this.url != 'create-orders') {
      this.doctoredit = true;
      this.id = this.app.urlData();
      this.data.getOrdersById(this.id).subscribe((res: any) => {
        this.billingObj = res;
        this.activeInd = true,
        this.id = res.id;
        this.patient = res.patientId;
        this.service = res.serviceId;
        this.vendor = res.vendorId;
        // this.assetTypeVendorTagList = res.billingDetails;
        // if (this.assetTypeVendorTagList && this.assetTypeVendorTagList.length > 0) {
        //   this.assetTypeVendorTagList.map((res, i) => {
        //     this.getIssuesTypes1(res.serviceId);
        //     this.checkloc[i].sgst = res.sgst;
        //     this.checkloc[i].cgst = res.cgst;
        //     this.checkloc[i].quantity = res.quantity;
        //     this.checkloc[i].totalCost = res.totalCost;
        //     this.checkloc[i].payTotal = res.payTotal;
        //     this.checkloc[i].billDetailId = res.billDetailId;
        //     this.checkloc[i].billId = res.billId;
        //   })
        // }
      }, err => {
        this.loader = false;
      })
    } else {
      console.log('jj')
    }

  }
getbybilling(){
  this.data.getBillingById(this.id).subscribe((res: any) => {
    this.billingObj = res;
    this.activeInd = true,
    this.id = res.id;
    this.patient = res.patientId;
    this.service = res.serviceId;
    this.vendor = res.vendorId;
    // this.assetTypeVendorTagList = res.billingDetails;
    // if (this.assetTypeVendorTagList && this.assetTypeVendorTagList.length > 0) {
    //   this.assetTypeVendorTagList.map((res, i) => {
    //     // this.getIssuesTypes1(res.serviceId);
    //     this.checkloc[i].sgst = res.sgst;
    //     this.checkloc[i].cgst = res.cgst;
    //     this.checkloc[i].quantity = res.quantity;
    //     this.checkloc[i].totalCost = res.totalCost;
    //     this.checkloc[i].payTotal = res.payTotal;
    //     this.checkloc[i].billDetailId = res.billDetailId;
    //     this.checkloc[i].billId = res.billId;
    //   })
    // }
  }, err => {
    this.loader = false;
  })
}

  // quantityadded(event: any, index: number) {
  //   const newValue = parseInt(event.target.value); // Assuming quantity is a number
  //   this.checkloc[index].quantity = newValue;
  //   if(this.id !=null){
  //     this.treatmentUpdate();   
  //     this.getbybilling();

  //   }else{
  //     this.treatmentCreate();
  //     this.getbybilling();
  //   }
  // }

  orderCreate() {
    this.loader = true;
    // let assetTypeVendorTagList = this.checkloc ? this.checkloc : this.checklocation1;
    // if (assetTypeVendorTagList) {
    //   assetTypeVendorTagList.map((res) => {
    //     res.serviceId = res.id; // Set the serviceId directly on the object
    //     res.quantity = res.quantity; // Set the quantity directly on the object
    //     return res; // Return the modified object in the map function
    //   });
    // }
    var post = {
      "patientId": this.patientId,
      "patientName": this.patientName,
      "patientContactNbr": this.patientContactNbr,
      "serviceId": this.service,
      // "serviceName": this.serviceName,
      "vendorId": this.vendor,
      // "vendorName": this.vendorName,
      // "vendorContactNbr": this.vendorContactNbr,
      "activeInd": true,
    }
    this.data.ordersCreate(post).subscribe((res: any) => {
      this.id = res.id;
      this.loader = false;
      this.app.openPage('manage-orders');
      // this.app.openSnackBar('Doctor Created Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("order Created Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })

    }, err => {
      this.loader = false;
    })
  }
  cancel() {
    this.app.openPage('manage-orders')
  }

  treatmentUpdate() {
    this.loader = true;
    let assetTypeVendorTagList = this.checkloc ? this.checkloc : this.checklocation1;
    if (assetTypeVendorTagList) {
      assetTypeVendorTagList.map((res) => {
        res.serviceId = res.id; // Set the serviceId directly on the object
        res.quantity = res.quantity; // Set the quantity directly on the object
      });
    }
    this.billingObj.id = this.id;
    this.billingObj.patientId = this.patientId;
    this.billingObj.customerPhNo = this.service;
    this.billingObj.vendorId = this.vendor;
    this.activeInd = true;
    // this.billingObj.billingDetails = assetTypeVendorTagList;
    this.data.ordersPutById(this.id, this.billingObj).subscribe(res => {
      this.loader = false;
      // this.app.openPage('billing');
      // this.app.openSnackBar('Doctor Updated Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("order Updated Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })
    }, err => {
      this.loader = false;
    })

  }

  focusOutFunction(data) {
    if (this.mobile) {
      this.data.getBilliingDetails(this.mobile).subscribe((res) => {
        if (res) {
          this.patientObject = res;
          this.customerName = this.patientObject.name;
          this.documentNbr = this.patientObject.documentNbr;
          if (this.patientObject.entityType != 2) {
            var len = this.patientObject.contactNbr.length;
            if (this.patientObject.secContactNbr) {
              var len1 = this.patientObject.secContactNbr.length;
              len1 = len1 - 10;
            }
            len = len - 10;
            if (len1) {
              this.secCntryNum = this.patientObject.secContactNbr.slice(
                0,
                len1
              );
              this.secMobile = this.patientObject.secContactNbr.slice(-10);
            }
            this.countrynum = this.patientObject.contactNbr.slice(0, len);
            this.mobile = this.patientObject.contactNbr.slice(-10);
          }
          this.refId = this.patientObject.patientRefId;
          this.age = this.patientObject.age;
          if (this.patientObject.entryType) {
            this.entype.map((res) => {
              if (res.id == this.patientObject.entryType) {
                this.entryType = res.name;
              }
            });
          }
          if (this.patientObject.visitType) {
            this.visitype.map((res) => {
              if (res.id == this.patientObject.visitType) {
                this.visitType = res.name;
              }
            });
          }
          if (this.patientObject.expctdReportingTime) {
            let r = this.patientObject.expctdReportingTime;
          }
          this.wardNbr = this.patientObject.wardNbr;
          this.receiptNbr = this.patientObject.receiptNbr;
          this.doctorName = this.patientObject.doctorName;
          this.doctorId = this.patientObject.doctorId;
          this.app.aged = this.patientObject.aged;
          this.app.wheeled = this.patientObject.wheeled;
          this.app.nri = this.patientObject.internationalPatient;
          this.app.callRequired = this.patientObject.callRequired;
        }
      });
    }
  }

  getServices() {
    this.data.getServices().subscribe((res:any) => {
      this.services = res;
      this.serviceName = res.serviceName;
      this.loader = false;
    }, err => {
      this.loader = false;
      this.handler.err(err);
    })
  }
  getVendor() {
    this.data.getvendorList().subscribe((res:any) => {
      this.vendor = res;
      this.vendorName = res.pocName;
      this.vendorContactNbr = res.pocPhNo;
      this.loader = false;
    }, err => {
      this.loader = false;
      this.handler.err(err);
    })
  }
  patientSearch(){
    this.data.loader = true;
    this.data.userSearchBy(localStorage.getItem('organizationId'), this.patientName).subscribe(res=>{
      if(res !=null){
        this.items = res;
        // this.dataSource = new MatTableDataSource(this.items)
        if (this.items.length > 0) {
          this.items = this.items.sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          })
        }
        this.data.loader = false;
      }else{
        this.data.loader =false;
        this.msgContent = "No Data To Show";
      }
    })
  }

  // searchUser() {
  //   clearTimeout(this.timer);
  //   this.timer = setTimeout(() => {
  //     if (this.patientName) {
  //       this.data.userSearchBy(this.orgId).subscribe((res: any) => {
  //         if (res && res.length > 0) {
  //           this.allpatientId = res;
  //         } else {
  //           this.allpatientId = [];
  //           this.handler.res("No Data For You");
  //         }
  //       },
  //         (err) => {
  //           this.loader = false;
  //           this.handler.res(err);
  //         });
  //     }else{
  //       this.allpatientId = [];
  //       this.patientId = 0;
  //     }
  //   }, 200);
  // }
  getlocId(data) {
    this.patientId = data.userId;
    this.patientName = data.firstName;
    this.patientContactNbr = data.contactNbrMob;
  }
  // async getIssuesTypes(id) {
  //   if(this.mobile!=null && this.mobile !="" &&  this.mobile !=undefined 
  //   && this.customerName!=null && this.customerName !="" &&  this.customerName !=undefined ){ 
  //   let mapData: any = [];
  //   mapData = this.services.filter(res => { return res.id == id });
  //   if (mapData.length > 0)
  //     this.checkloc.push(mapData[0]);
  //   let order = [1, 3, 2];
  //   this.checkloc.sort(function (a, b) {
  //     return order.indexOf(a.checkPointPositionId) - order.indexOf(b.checkPointPositionId)

  //   });
  //   this.services = this.services.filter(res => !this.checkloc.find(val => res.id === val.id));
  //   if( this.id !=null){
  //     this.treatmentUpdate();    
  //          setTimeout(() => {
  //       this.getbybilling();
  //     },1500);
      

  //   }else{
  //     this.treatmentCreate();
  //     setTimeout(() => {
  //       this.getbybilling();
  //     },1500);
      
  //   }
    
  // }else{
  //   this.translate.use(this.languageCode).subscribe(() => {
  //     this.translate.get("Please enter the required fields").subscribe((translation: string) => {
  //       this.app.openSnackBar(translation, "success-snackbar")
  //     })
  //   })
  // }
  // }

 
}