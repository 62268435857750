import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';

@Component({
  selector: 'app-patientscustomwork-flow',
  templateUrl: './patientscustomwork-flow.component.html',
  styleUrls: ['./patientscustomwork-flow.component.scss']
})
export class PatientscustomworkFlowComponent implements OnInit {

  items:any = [];
  selectedItem:any=[];

  name:string;
  description:string;
  mobile:any;
  patientId;
  
  constructor(public data:DataService,public app:AppService) { }

  async ngOnInit() {
    this.items = await this.data.getCheckpoints().toPromise();

  }
  async getback(id){
    let mapData:any=[];
    mapData=await this.data.getCheckpointById(id).toPromise();
    this.items.push(mapData);
    this.selectedItem = this.selectedItem.filter(res => !this.items.find(val => res.id === val.id));

  }
  async getCheck(id){
    let mapData:any=[];
    mapData=await this.data.getCheckpointById(id).toPromise();
    this.selectedItem.push(mapData)
    this.items = this.items.filter(res => !this.selectedItem.find(val => res.id === val.id));
  }
  async save(){
    let post = { name: this.name, description: this.description,checkpoints:this.selectedItem }
    if (this.name) {
       this.data.createWorkFlow(post).toPromise()
        .then((res:any) => {
         sessionStorage.setItem('workFlowId',res.id);
         this.app.openPage('patientsCheckIn');
         

        })
        .catch(
          err => {
            // this.presentToast(err.error.msg)

          }
        )
    }
    

  }

}
