import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { DataService } from 'src/api-calls/data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-viewmob',
  templateUrl: './viewmob.component.html',
  styleUrls: ['./viewmob.component.scss']
})
export class ViewmobComponent implements OnInit {
  checkPointId;
  checkPoints:any=[];
  expandObj:any=[];
  checkPoint:any;
  patients:any;
  missedData:any=[];
  skippedData:any;
  workqid;
  que: any = 1;
  loader:boolean=false;
  date1;
  date2;
  ipc;
  mhc;
  bs;
  op;
  intl;
  ipclength;
  oplength;
  bslength;
  mhclength;
  intlength;
  switchId:any;
  patData:any;
  notePop:boolean=false;
  languageCode: string;
  constructor(public data:DataService, private translate: TranslateService, public app:AppService) { }

  async ngOnInit() {
    this.languageCode = localStorage.getItem('lang'); 
    this.loader=true;
    var url=this.app.sendUrl()
    this.checkPointId=url.split('/').pop()
    this.checkPoints=await this.data.getCheckpoints().toPromise()
    if (this.checkPointId != undefined) {
     this.getEntity()
  }

}
async change(id) {
  if(this.checkPoint != 0){
    this.que = id;
    if (id == 1) {
      this.switchId=1
      this.loader = true;
      await this.data.getCompleted(this.workqid,this.date1,this.date2).toPromise().then(res=>{
        if(res){
          this.loader = false;
          this.patients = res;
          var ip=this.patients.filter(res=>{return res.entityType==2})
      var mhc=this.patients.filter(res=>{return res.entityType==3})
      var bs=this.patients.filter(res=>{return res.entityType==2 && res.bedside==false})
      var op=this.patients.filter(res=>{return res.entityType==1})
      var intl=this.patients.filter(res=>{return res.internationalPatient==true})
      this.ipc=ip
      this.mhc=mhc
      this.bs=bs
      this.op=op
      this.intl=intl
      this.ipclength=this.ipc.length
      if(this.ipclength<10){
        this.ipclength='0'+this.ipclength
      }
      this.mhclength=this.mhc.length
      if(this.mhclength<10){
        this.mhclength='0'+this.mhclength
      }
      this.bslength=this.bs.length
      if(this.bslength<10){
        this.bslength='0'+this.bslength
      }
      this.oplength=this.op.length
      if(this.oplength<10){
        this.oplength='0'+this.oplength
      }
      this.intlength=this.intl.length
      if(this.intlength<10){
        this.intlength='0'+this.intlength
      }
        }else{
          this.loader = false;
        }
      })
    }
    if (id == 2) {
      this.switchId=2
      this.loader = true;
      await this.data.getMissed1(this.workqid,this.date1,this.date2).toPromise().then(res=>{
        if(res){
          this.loader =false;
          this.missedData = res;
          var ip=this.missedData.filter(res=>{return res.entityType==2})
          var mhc=this.missedData.filter(res=>{return res.entityType==3})
          var bs=this.missedData.filter(res=>{return res.entityType==2 && res.bedside==false})
          var op=this.missedData.filter(res=>{return res.entityType==1})
          var intl=this.missedData.filter(res=>{return res.internationalPatient==true})
          this.ipc=ip
          this.mhc=mhc
          this.bs=bs
          this.op=op
          this.intl=intl
          this.ipclength=this.ipc.length
          if(this.ipclength<10){
            this.ipclength='0'+this.ipclength
          }
          this.mhclength=this.mhc.length
          if(this.mhclength<10){
            this.mhclength='0'+this.mhclength
          }
          this.bslength=this.bs.length
          if(this.bslength<10){
            this.bslength='0'+this.bslength
          }
          this.oplength=this.op.length
          if(this.oplength<10){
            this.oplength='0'+this.oplength
          }
          this.intlength=this.intl.length
          if(this.intlength<10){
            this.intlength='0'+this.intlength
          }
        }else{
          this.loader = false;
          this.missedData=[];
        }
      })
    }
    if (id == 3) {
      this.switchId=3
      this.loader = true;
      await this.data.getSkipped1(this.workqid,this.date1,this.date2).toPromise().then(res=>{
          if(res){
          this.loader = false;  
          this.missedData = res;
          var ip=this.missedData.filter(res=>{return res.entityType==2})
          var mhc=this.missedData.filter(res=>{return res.entityType==3})
          var bs=this.missedData.filter(res=>{return res.entityType==2 && res.bedside==false})
          var op=this.missedData.filter(res=>{return res.entityType==1})
          var intl=this.missedData.filter(res=>{return res.internationalPatient==true})
          this.ipc=ip
          this.mhc=mhc
          this.bs=bs
          this.op=op
          this.intl=intl
          this.ipclength=this.ipc.length
          if(this.ipclength<10){
            this.ipclength='0'+this.ipclength
          }
          this.mhclength=this.mhc.length
          if(this.mhclength<10){
            this.mhclength='0'+this.mhclength
          }
          this.bslength=this.bs.length
          if(this.bslength<10){
            this.bslength='0'+this.bslength
          }
          this.oplength=this.op.length
          if(this.oplength<10){
            this.oplength='0'+this.oplength
          }
          this.intlength=this.intl.length
          if(this.intlength<10){
            this.intlength='0'+this.intlength
          }
        }
        else{
          this.loader = false;
          this.missedData=[];
          }
      })
    }
    if (id == 4) {
      this.switchId=4
      this.loader = true;
       await this.data.getCheckedIn1(this.workqid,this.date1,this.date2).toPromise().then(res=>{
        if(res){
          this.loader =false;
          this.missedData = res;
          var ip=this.missedData.filter(res=>{return res.entityType==2})
      var mhc=this.missedData.filter(res=>{return res.entityType==3})
      var bs=this.missedData.filter(res=>{return res.entityType==2 && res.bedside==false})
      var op=this.missedData.filter(res=>{return res.entityType==1})
      var intl=this.missedData.filter(res=>{return res.internationalPatient==true})
      this.ipc=ip
      this.mhc=mhc
      this.bs=bs
      this.op=op
      this.intl=intl
      this.ipclength=this.ipc.length
      if(this.ipclength<10){
        this.ipclength='0'+this.ipclength
      }
      this.mhclength=this.mhc.length
      if(this.mhclength<10){
        this.mhclength='0'+this.mhclength
      }
      this.bslength=this.bs.length
      if(this.bslength<10){
        this.bslength='0'+this.bslength
      }
      this.oplength=this.op.length
      if(this.oplength<10){
        this.oplength='0'+this.oplength
      }
      this.intlength=this.intl.length
      if(this.intlength<10){
        this.intlength='0'+this.intlength
      }
        }else{
          this.loader = false;
          this.missedData=[];
        }
      })
    }
  }

}
async expand(data){
  if(data.opencard){
    data.opencard=false
  }
  else {
    this.expandObj=[];
    this.expandObj = await this.data.getWorkflowbyPatient(data.id).toPromise();
    this.expandObj = this.expandObj[0].checkpoints;
    this.expandObj.map(res=>{
      Object.assign(res, { clas: '' });
      if(res.status==1){
        res.clas='checkIn';
      }
      if(res.status==2){
        res.clas='queue';
      }
      if(res.status==3){
        res.clas='current';
      }
      if(res.status==4){
        res.clas='complete';
      }
      if(res.status==5){
        res.clas='miss';
      }
    })
    this.missedData.map(res => {
      Object.assign(res, { opencard: false })
    })

    Object.assign(data,{opencard:true})
  }
  
}
async expand1(data){
  if(data.opencard){
    data.opencard=false
  }
  else {
    this.expandObj=[];
    this.expandObj = await this.data.getWorkflowbyPatient(data.id).toPromise();
    this.expandObj = this.expandObj[0].checkpoints;
    this.expandObj.map(res=>{
      Object.assign(res, { clas: '' });
      if(res.status==1){
        res.clas='checkIn';
      }
      if(res.status==2){
        res.clas='queue';
      }
      if(res.status==3){
        res.clas='current';
      }
      if(res.status==4){
        res.clas='complete';
      }
      if(res.status==5){
        res.clas='miss';
      }
    })
    this.patients.map(res => {
      Object.assign(res, { opencard: false })
    })

    Object.assign(data,{opencard:true})
  }
  
}
filter(id){
  if(this.switchId==1){
    if(id==1){
      this.patients=this.ipc
      }
      if(id==2){
      this.patients=this.op
       }
      if(id==3){
      this.patients=this.bs
      }
       if(id==4){
       this.patients=this.mhc
       }
       if(id==5){
       this.patients=this.intl
          }
  }
  if(this.switchId==2 || this.switchId==3 || this.switchId==4){
    if(id==1){
      this.missedData=this.ipc
      }
      if(id==2){
      this.missedData=this.op
       }
      if(id==3){
      this.missedData=this.bs
      }
       if(id==4){
       this.missedData=this.mhc
       }
       if(id==5){
       this.patients=this.intl
          }
  }

}
searchEntity(id){
this.checkPointId=id
this.patients=[];
this.getEntity()
}
async getEntity(){
  var today = new Date();
  this.date1 = today.getFullYear() + '/' + ("0" + (today.getMonth() + 1)).slice(-2) + '/' + ("0" + today.getDate()).slice(-2) + ' ' + '00:00:00'
  this.date2 = today.getFullYear() + '/' + ("0" + (today.getMonth() + 1)).slice(-2) + '/' + ("0" + today.getDate()).slice(-2) + ' ' + '23:59:59'
  this.loader=true
  this.checkPoint = await this.data.getCheckpointById(this.checkPointId).toPromise();
  this.switchId=1
  this.workqid = this.checkPoint.worq.id
  await this.data.getCompleted(this.workqid,this.date1,this.date2).toPromise().then(res=>{
    if(res){
      this.loader = false;
      this.patients= res;
      var ip=this.patients.filter(res=>{return res.entityType==2})
      var mhc=this.patients.filter(res=>{return res.entityType==3})
      var bs=this.patients.filter(res=>{return res.entityType==2 && res.bedside==false})
      var op=this.patients.filter(res=>{return res.entityType==1})
      this.ipc=ip
      this.mhc=mhc
      this.bs=bs
      this.op=op
      this.ipclength=this.ipc.length
      if(this.ipclength<10){
        this.ipclength='0'+this.ipclength
      }
      this.mhclength=this.mhc.length
      if(this.mhclength<10){
        this.mhclength='0'+this.mhclength
      }
      this.bslength=this.bs.length
      if(this.bslength<10){
        this.bslength='0'+this.bslength
      }
      this.oplength=this.op.length
      if(this.oplength<10){
        this.oplength='0'+this.oplength
      }
    }else{
      this.loader=false
    }
  })
}
addReview(id){
  this.loader=true;
  this.data.addReview(this.workqid,id).subscribe(res=>{
    this.loader=false;
    // this.app.openSnackBar('Patient added for review successfully','success-snackbar')
    this.translate.use(this.languageCode).subscribe(() => {
      this.translate.get("Patient added for review successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
      })
  })
  },
  err=>{
    this.loader=false;
  //  this.app.openSnackBar(err.error.msg,'error-snackbar')
   this.translate.use(this.languageCode).subscribe(() => {
    this.translate.get(err.error.msg).subscribe((translation: string) => {
        this.app.openSnackBar(translation, "error-snackbar")
    })
})
  })
  }

  reportReady(data){
    this.loader=true;
    data.reportReady=!data.reportReady
     this.data.sendReportNotifictaion(data.workQId, data.id,data.reportReady).subscribe(res => {
       this.loader=false;
      //  this.app.openSnackBar('Updated SuccessFully', 'success-snackbar');
       this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Updated SuccessFully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
        })
    })
        this.ngOnInit()
    })
  }
  addNotes(data){
    this.patData=data;
    this.notePop=true;
  }
  putNotes(){
  this.notePop=false;
 this.data.addPatientNotes(this.patData.workQId,this.patData.id,this.patData.notes).subscribe(res=>{
  this.ngOnInit()
  },err=>{
    // this.app.openSnackBar(err.error.msg,'error');
    this.translate.use(this.languageCode).subscribe(() => {
      this.translate.get(err.error.msg).subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
      })
  })
  })
 }
}
