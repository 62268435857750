import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { DataService } from 'src/api-calls/data.service';

@Component({
  selector: 'app-diagnosis-history',
  templateUrl: './diagnosis-history.component.html',
  styleUrls: ['./diagnosis-history.component.scss']
})
export class DiagnosisHistoryComponent implements OnInit {


  loader:boolean=true;
  cols = ['Date'];
  dataSource:any ;
  
  constructor(public service:DataService,private router:Router) { }

  
  ngOnInit() {
    let id=this.router.url.split('/').pop();
   this.service.clinicaPathistory(id).subscribe((res:any)=>{
     this.dataSource = new MatTableDataSource(res);
     this.loader=false;
   })
 }
 route(data){
   this.router.navigate(['/diagnosis', data]);
 }

}
