import { Component, HostListener, Injectable, OnInit, ViewChild } from '@angular/core';
import { AppService } from './app.service';
import { AuthService } from 'src/api-calls/auth.service';
import { DataService } from 'src/api-calls/data.service';
import { MatSidenav } from '@angular/material';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  webCheck:boolean;

  urlCheck:boolean;

  pathFinder:string;
  deviceType;
  org:any={};
  orgLogo;
  showMenu:boolean = false;

  message;

  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(public app:AppService,public auth:AuthService,
              public data:DataService){  }

 async ngOnInit(){
    this.pathFinder = this.app.urlData();
    this.onResize(event);
    this.app.setLang()
    const userId = 'user003';
  }


  @HostListener('window:resize',['$event'])
  onResize(event) {
    this.test()
    
  }

  test(){
    var width = window.innerWidth;
    if(width < 700){
      this.webCheck = false;
      this.showMenu = false;
      if(this.pathFinder == 'weblogin'){
        this.urlCheck = false;
      }    
    }
    else{
      this.webCheck = true;
      this.showMenu = true;
      if(this.pathFinder == 'weblogin'){
        this.urlCheck = true;
      }
    }
  }

  async ngDoCheck(){
    this.test()
  }

  sidenavClose(){
    this.sidenav.close();
  }
  sidenavOpen(){
    this.sidenav.open();
  }

    
  @HostListener('click', ['$event']) 
  myMethod(e) {
      var id = e.target.id;
      var pro = 'pro-id';
      var prof = 'prof-id';
      if(document.getElementById('pro-id')){
        let doc1 = document.getElementById('pro-content');
        if (pro == id){
          doc1.setAttribute('style','display:block');
        } else{
          doc1.setAttribute('style','display:none');
        }
      }
      if(document.getElementById('prof-id')){
        let doc2 = document.getElementById('prof-content');
        if (prof == id){
          doc2.setAttribute('style','display:flex');
        } else{
          doc2.setAttribute('style','display:none');
        }
      }
  }
  


}
