import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HandlerService } from 'src/api-calls/handler.service';
@Component({
  selector: 'app-blocktimer',
  templateUrl: './blocktimer.component.html',
  styleUrls: ['./blocktimer.component.scss'],
})
export class BlocktimerComponent implements OnInit {
 
  times:any=[];hr; min = 0;morEve = true;maxhr;minhr;i=0;
  constructor(public handler:HandlerService,public dialogRef: MatDialogRef<BlocktimerComponent>,@Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    let time:any=[];
    this.data.map(res=>{
      time.push(res.startTime,res.endTime)
    })

  setTimeout(() => {
    time=time.filter((v, i, a) => a.indexOf(v) === i)
    time.map(res=>{
      this.times.push({hr:res.split(':').shift(),min:res.split(':').pop()})
    })
  }, 500);
}

  add() {
   let len=this.times.length-1;
   if(this.handler.i==len){
    this.handler.i=0;
   }
   else
   this.handler.i++;
  }

  setTime(i){
    setTimer(this.times[i].hr + ':' + this.times[i].min);
    this.dialogRef.close(2);
  }

  cancel(){
    this.dialogRef.close(1);
  }
  minus() {
    let len=this.times.length-1;
    if(this.handler.i==0)
      this.handler.i=len;
     else
     this.handler.i--;
   }

}
export let timer;

export function setTimer(data){
    timer = data;
}
 




