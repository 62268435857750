import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { DataService } from 'src/api-calls/data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-grouping',
  templateUrl: './grouping.component.html',
  styleUrls: ['./grouping.component.scss']
})
export class GroupingComponent implements OnInit {
  groupName: any;
  descripTion: any;
  shortCode: any;
  postmedGroup: Object;
  loader: boolean;
  medicines: any;
  selectedItem: any = [];
  url: string;
  ediabLe: boolean;
  id: string;
  groupMedicine: any;
  medicineing: any;
  medIdgroup: any;
  medgrupingId: any;
  languageCode: string;
  prescriValue: boolean = false;
  frequencies: any = ["M", "A", "N"]
  createpres: boolean;
  editpres: boolean;
  creategrp: boolean;
  url1: string;

  constructor(public app: AppService, public data: DataService, private translate: TranslateService) { }

  ngOnInit() {
    this.loader = true;
    this.languageCode = localStorage.getItem('lang');
    this.url = this.app.urlData();
    this.url1 = this.app.sendUrl()

    if (this.url1.includes('editgrouping')) {
      this.ediabLe = true;
      this.createpres = false;
      this.editpres = false;
      this.creategrp = false;
      this.id = this.app.urlData();
      this.getIdgroup();
    } else if (this.url1.includes('creategrouping')) {
      this.ediabLe = false;
      this.createpres = false;
      this.editpres = false;
      this.creategrp = true;
      this.data.getMedicine().subscribe(res => {
        this.medicineing = res;
        if (this.groupMedicine) {
          this.medicineing.map(res => {
            this.groupMedicine.map(response => {
              if (res.id == response.medicineId) {
                this.medIdgroup = response.medicineId;
                this.getCheck(this.medIdgroup);
              }
            });
          });
        }
        this.loader = false;
      })
    } else if (this.url1.includes('editprescrigroup')) {
      this.ediabLe = false;
      this.createpres = false;
      this.editpres = true;
      this.creategrp = false;
      this.id = this.app.urlData();
      this.getIdgroup();
    } else if (this.url1.includes('createprescrigroup')) {
      this.ediabLe = false;
      this.createpres = true;
      this.editpres = false;
      this.creategrp = false;
      this.data.getMedicine().subscribe(res => {
        this.medicineing = res;
        if (this.groupMedicine) {
          this.medicineing.map(res => {
            this.groupMedicine.map(response => {
              if (res.id == response.medicineId) {
                this.medIdgroup = response.medicineId;
                this.getCheck(this.medIdgroup);
              }
            });
          });
        }
        this.loader = false;
      })
    }
  }

  getIdgroup() {
    let id = this.app.urlData();
    this.data.getgroupMedicine(id).subscribe(res => {
      this.medicines = res;
      this.loader = false;
      this.groupName = this.medicines.name;
      this.descripTion = this.medicines.description;
      this.shortCode = this.medicines.code;
      this.groupMedicine = this.medicines.groupMedicines;
      this.medgrupingId = this.medicines.id;
      this.prescriValue = this.medicines.prescriptionInd;
      this.loader = false;
      this.data.getMedicine().subscribe(res => {
        this.medicineing = res;
        if (this.groupMedicine) {
          this.medicineing.map(res => {
            this.groupMedicine.map(response => {
              if (res.id == response.medicineId) {
                this.medIdgroup = response.medicineId;
                this.getCheck(this.medIdgroup);
                Object.assign(res, { groupMedicine: response })
              }
            });
          });
        }
        this.loader = false;
      })
    })
  }

  save() {
    let post1: any = [];
    this.selectedItem.forEach(item => {
      var array = [];
      var fName = [];
      if (item.groupMedicine.medFrequencies[0]) {
        array.push(1)
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Morning").subscribe((translation: string) => {
            fName.push(translation);
          });
        });
      }
      if (item.groupMedicine.medFrequencies[1]) {
        array.push(2)
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("AfterNoon").subscribe((translation: string) => {
            fName.push(translation);
          });
        });
      }
      if (item.groupMedicine.medFrequencies[2]) {
        array.push(3)
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Night").subscribe((translation: string) => {
            fName.push(translation);
          });
        });
      }
      let post: any = {
        "medGroupId": this.medgrupingId,
        "medicineId": item.id,
        "dosage": item.groupMedicine.dosage,
        "beforeFoodInd": item.groupMedicine.beforeFoodInd,
        "days": item.groupMedicine.days,
        "fname": fName,
        "medFrequencies": array,
      };
      post1.push(post);
    });
    let post = {
      name: this.groupName,
      description: this.descripTion,
      orgId: localStorage.getItem('organizationId'),
      code: this.shortCode,
      groupMedicines: post1,
      activeInd: true,
      prescriptionInd: this.prescriValue,
    }
    if (this.prescriValue) {
      if (post1.every(item => item.dosage)) {
        if (post1.every(item => item.days)) {
          if (post1.every(item => item.medFrequencies.length > 0)) {
            this.data.medgroupDetails(post).subscribe(res => {
              if (res) {
                this.postmedGroup = res;
                this.app.openPage('manage-grouping');
              }
            })
          } else {
            this.translate.use(this.languageCode).subscribe(() => {
              this.translate.get('Please Select Frequency').subscribe((translation: string) => {
                this.app.openSnackBar(translation, 'error-snackbar');
              });
            });
          }
        } else {
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get('Please Enter days').subscribe((translation: string) => {
              this.app.openSnackBar(translation, 'error-snackbar');
            });
          });
        }
      } else {
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get('Please Select Dosage').subscribe((translation: string) => {
            this.app.openSnackBar(translation, 'error-snackbar');
          });
        });
      }
    } else {
      this.data.medgroupDetails(post).subscribe(res => {
        if (res) {
          this.postmedGroup = res;
          this.app.openPage('manage-grouping');
        }
      })
    }
  }

  edit() {
    let post1: any = [];
    this.selectedItem.forEach(item => {
      var array = [];
      var fName = [];
      if (item.groupMedicine.medFrequencies[0]) {
        array.push(1)
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Morning").subscribe((translation: string) => {
            fName.push(translation);
          });
        });
      }
      if (item.groupMedicine.medFrequencies[1]) {
        array.push(2)
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("AfterNoon").subscribe((translation: string) => {
            fName.push(translation);
          });
        });
      }
      if (item.groupMedicine.medFrequencies[2]) {
        array.push(3)
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Night").subscribe((translation: string) => {
            fName.push(translation);
          });
        });
      }
      let posting: any = {
        "medGroupId": this.medgrupingId,
        "medicineId": item.id,
        "dosage": item.groupMedicine.dosage,
        "beforeFoodInd": item.groupMedicine.beforeFoodInd,
        "days": item.groupMedicine.days,
        "fname": fName,
        "medFrequencies": array,
      };
      post1.push(posting);
    });
    console.log(post1)
    console.log(post1.every(item => item.medFrequencies))
    let post = {
      name: this.groupName,
      description: this.descripTion,
      orgId: localStorage.getItem('organizationId'),
      code: this.shortCode,
      groupMedicines: post1,
      activeInd: true,
      prescriptionInd: this.prescriValue,
      id: this.id,
    }
    if (this.prescriValue) {
      if (post1.every(item => item.dosage)) {
        if (post1.every(item => item.days)) {
          if (post1.every(item => item.medFrequencies.length > 0)) {
            this.data.putgroupMedicine(this.id, post).subscribe(res => {
              if (res) {
                this.postmedGroup = res;
                this.app.openPage('manage-grouping');
              }
            })
          } else {
            this.translate.use(this.languageCode).subscribe(() => {
              this.translate.get('Please Select Frequency').subscribe((translation: string) => {
                this.app.openSnackBar(translation, 'error-snackbar');
              });
            });
          }
        } else {
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get('Please Enter days').subscribe((translation: string) => {
              this.app.openSnackBar(translation, 'error-snackbar');
            });
          });
        }
      } else {
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get('Please Select Dosage').subscribe((translation: string) => {
            this.app.openSnackBar(translation, 'error-snackbar');
          });
        });
      }
    } else {
      this.data.putgroupMedicine(this.id, post).subscribe(res => {
        if (res) {
          this.postmedGroup = res;
          this.app.openPage('manage-grouping');
        }
      })
    }
  }

  async getCheck(id) {
    if (!id) {
      return;
    }
    this.medicineing.map(res => {
      if (res.id == id) {
        if (!res.groupMedicine) {
          res.groupMedicine = {
            "medicineId": id,
            "dosage": "",
            "beforeFoodInd": "",
            "days": "3",
            "medFrequencies": ["", "", ""]
          }
        }
      }
    });
    const mapData = this.medicineing.filter(res => res.id === id);
    if (mapData.length > 0) {
      this.selectedItem.push(mapData[0]);
      for (const item of this.selectedItem) {
        const newMedFrequencies = ["", "", ""]; // Initialize a new array with empty strings
        // Loop through the medFrequencies array and assign values to newMedFrequencies based on conditions
        for (const freq of item.groupMedicine.medFrequencies) {
          if (freq === 1) {
            newMedFrequencies[0] = freq; // Set value 1 at index 0
          } else if (freq === 2) {
            newMedFrequencies[1] = freq; // Set value 2 at index 1
          } else if (freq === 3) {
            newMedFrequencies[2] = freq; // Set value 3 at index 2
          }
        }
        // Update the medFrequencies array in groupMedicine
        item.groupMedicine.medFrequencies = newMedFrequencies;
      }
      this.medicineing = this.medicineing.filter(res => !this.selectedItem.find(val => res.id === val.id));
    }
  }

  async getback(id) {
    if (!id) {
      return;
    }
    const mapData = this.selectedItem.filter(res => res.id === id);
    this.medicineing.push(mapData[0]);
    this.selectedItem = this.selectedItem.filter(res => !this.medicineing.find(val => res.id === val.id));
  }

  cancel() {
    this.app.openPage('manage-grouping');
  }

}
