import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { Popper5Component } from '../popper5/popper5.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-diagnosis-test',
  templateUrl: './diagnosis-test.component.html',
  styleUrls: ['./diagnosis-test.component.scss']
})
export class DiagnosisTestComponent implements OnInit {

  id;
  data:any;
  data1:any;
  diagnosisId;
  diagnosis;
  doccomments;
  lattestdate;
  sData:any;
  labTestName;
  labtestdate=new Date();
  testreport;
  img;
  loader: boolean = false;
  notes;
  url;
  languageCode: string;
  constructor(private service:DataService,
    public router:ActivatedRoute,
    private santinizer:DomSanitizer,
    private translate: TranslateService,
    public app:AppService, 
    public dialog: MatDialog,
    private route:Router) { }

    async ngOnInit() {
      this.languageCode = localStorage.getItem('lang');
      //  this.loader = true;
        this.sData = await this.service.staticData().toPromise();
        this.sData = this.sData.filter(data=>data.typeId == 11);
        this.sData = this.sData[0].values;
        this.url = window.location.href.split('/')
        if(this.url.length == 8){
          this.diagnosisId=this.url[5];
          this.id = this.url[7];
        }else{
          this.diagnosisId=this.url[4];
          this.id = this.url[6];
        }
        this.labTestName=this.sData.filter(res=> res.id == this.id);
        this.labTestName=this.labTestName[0].name;
        this.data= await this.service.labTestGetById(this.diagnosisId,this.id).toPromise();
        this.data=this.data[0];
        this.doccomments=this.data.doctorComments;
        this.testreport=this.data.labTestResult;
        if(this.data.mydocuments)
        this.data1=this.data.mydocuments[0];
        else
        Object.assign(this.data,{mydocuments:[]})
        this.diagnosis=await this.service.diagnosis(this.diagnosisId).toPromise();
        setTimeout(() => {
          this.loader=false;
        }, 20*1000);
       
    }
    
    save(){
      this.loader = true;
      let date:any=this.labtestdate.getDate();
      if(date<10)
      date='0'+date;
      let month:any=this.labtestdate.getMonth();
      if(month!=12)
      month=month+1;
      if(month<10)
      month='0'+month;
      let minutes:any=this.labtestdate.getMinutes();
      if(minutes<10)
      minutes='0'+minutes
      let time=this.labtestdate.getHours()+':'+minutes+':'+'00';
      let labdate=this.labtestdate.getFullYear()+'-'+month+'-'+date+' '+time
      this.diagnosis.statusId=6;
      this.diagnosis=Object.assign(this.diagnosis,{doctorComments:this.doccomments});
      Object.assign(this.diagnosis.labTests[0],{mydocuments:this.data.mydocuments,labTestDateStr:labdate,labTestResult:this.testreport,
      doctorComments:this.doccomments});
      this.service.diagnosisUpdate(this.diagnosisId,this.diagnosis).subscribe(res=>{
       this.route.navigate(['/diagnosis', this.diagnosisId]);
      //  this.app.openSnackBar("Data posted successfully","success")
       this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Data posted successfully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
        })
    })
        this.loader = false;
      })
      
    }
  
    cancel(){
      // this.app.openSnackBar("Action canceled returning previous page","success")
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Action canceled returning previous page").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
        })
    })
      this.route.navigate(['/diagnosis', this.diagnosisId]);
    }
  
    open(data): void {
      const dialogRef = this.dialog.open(Popper5Component, { data: data });
    }
    handleFile(files){
      let obj=this;
     var reader = new FileReader();
      reader.onload = function(event:any){
     obj.data.mydocuments.push({image:event.target.result,documentName:files[0].name})
  }; 
   var source = reader.readAsDataURL(files[0]);
    }
  }
