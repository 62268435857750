import {Pipe,PipeTransform} from "@angular/core";
@Pipe({
  name:'search'
})
export class SearchPipe implements PipeTransform{
    public transform(value, keys: string, term: string) {
        if (!term) return value;
        var lst = (value || []).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
        return lst  
      }
}

import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'safeHtml'})
export class SafeHtml {
  constructor(private sanitizer:DomSanitizer){}

  transform(html) {
    return this.sanitizer.bypassSecurityTrustUrl(html);
  }
}