import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ApplicationRef, DoBootstrap } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

import { MaterialModule } from 'src/assets/material.module';
import { IonicModule } from '@ionic/angular';
import { DragulaModule } from 'ng2-dragula';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WebLoginComponent } from './web-login/web-login.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { WorkflowComponent } from './workflow/workflow.component';
import { PatientSearchComponent } from './patient-search/patient-search.component';
import { HeaderComponent } from './header/header.component';
import { WorkQComponent } from './work-q/work-q.component';
import { CheckpointSearchComponent } from './checkpoint-search/checkpoint-search.component';
import { CheckpointAddComponent } from './checkpoint-add/checkpoint-add.component';
import { WorkflowAddComponent } from './workflow-add/workflow-add.component';
import { PatientsCheckInComponent } from './patients-check-in/patients-check-in.component';
import { PatientscustomworkFlowComponent } from './patientscustomwork-flow/patientscustomwork-flow.component';
import { InterceptorModule } from 'src/api-calls/interceptor.servie';
import { AuthGuard } from 'src/api-calls/auth.guard';
import { SearchPipe, SafeHtml } from 'src/api-calls/custom.pipe';
import { FullComponent } from './full/full.component';
import { PatientEditComponent } from './patient-edit/patient-edit.component';
import { BlockPopComponent } from './block-pop/block-pop.component';
import { WebLinkComponent } from './web-link/web-link.component';
import { PatinetviewComponent } from './patinetview/patinetview.component';
import { VerifyPageComponent } from './verify-page/verify-page.component';
import { AppSettingsComponent } from './app-settings/app-settings.component';
import { GraphComponent } from './graph/graph.component';
import { GoogleChart } from 'src/api-calls/google';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CloneWorkqComponent } from './clone-workq/clone-workq.component';
import { ManageusersComponent } from './manageusers/manageusers.component';
import { RegisterComponent } from './register/register.component';
import { ManageuserViewComponent } from './manageuser-view/manageuser-view.component';
import { WorqMobComponent } from './worq-mob/worq-mob.component';
import { WorqMpopComponent } from './worq-mpop/worq-mpop.component';
import { ManageRolesComponent } from './manage-roles/manage-roles.component';
import { UserEntitiesComponent } from './user-entities/user-entities.component';
import { ManagerolesViewComponent } from './manageroles-view/manageroles-view.component';
import { ViewentityComponent } from './viewentity/viewentity.component';
import { ViewpatientsComponent } from './viewpatients/viewpatients.component';
import { ViewmobComponent } from './viewmob/viewmob.component';
import { AssistancepopComponent } from './assistancepop/assistancepop.component';
import { CheckedinComponent } from './checkedin/checkedin.component';
import { ForgotpassComponent } from './forgotpass/forgotpass.component';
import { CreatepassComponent } from './createpass/createpass.component';
import { DataTableComponent } from './data-table/data-table.component';
import { ClearworkqComponent } from './clearworkq/clearworkq.component';
import { PatcheckinMobComponent } from './patcheckin-mob/patcheckin-mob.component';
import { PateditMobComponent } from './patedit-mob/patedit-mob.component';
import { AdminPatientSearchComponent } from './admin-patient-search/admin-patient-search.component';
import { AdminworkflowComponent } from './adminworkflow/adminworkflow.component';
import { TabScreen1Component } from './tab-screen1/tab-screen1.component';
import { TabScreen2Component } from './tab-screen2/tab-screen2.component';
import { DiagnosisComponent } from './diagnosis/diagnosis.component';
import { Popper1Component } from './popper1/popper1.component';
import { Popper2Component } from './popper2/popper2.component';
import { Popper4Component } from './popper4/popper4.component';
import { DiagnosisHistoryComponent } from './diagnosis-history/diagnosis-history.component';
import { GetMedicineComponent } from './get-medicine/get-medicine.component';
import { CreateMedicineComponent } from './create-medicine/create-medicine.component';
import { DiagnosisTestComponent } from './diagnosis-test/diagnosis-test.component';
import { Popper5Component } from './popper5/popper5.component';
import { PdfviewComponent } from './pdfview/pdfview.component';
import { PageScrollDirective } from './page-scroll.directive';
import { ManageuserEditComponent } from './manageuser-edit/manageuser-edit.component';
import { DepartmentSetupComponent } from './department-setup/department-setup.component';
import { DepartmentAddComponent } from './department-add/department-add.component';
import { DoctorAddComponent } from './doctor-add/doctor-add.component';
import { DoctorSetupComponent } from './doctor-setup/doctor-setup.component';
import { ReportsComponent } from './reports/reports.component';
import { UserReferenceComponent } from './user-reference/user-reference.component';
import { GroupingComponent } from './grouping/grouping.component';
import { ManageGroupingComponent } from './manage-grouping/manage-grouping.component';
import { CalendarComponent } from './calendar/calendar.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { CalendarManagePage } from './calendar-manage/calendar-manage.page';
import { BlocktimerComponent } from './blocktimer/blocktimer.component';
import { BlockEndComponent } from './block-end/block-end.component';
import { BookAppointmentComponent } from './book-appointment/book-appointment.component';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { TreatmentsetupComponent } from './treatmentsetup/treatmentsetup.component';
import { TreatmentaddComponent } from './treatmentadd/treatmentadd.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { BillingComponent } from './billing/billing.component';
import { BillingCreateComponent } from './billing-create/billing-create.component';
import { ServicesComponent } from './services/services.component';
import { ServicesCreateComponent } from './services-create/services-create.component';
import { VendorRegisterComponent } from './vendor-register/vendor-register.component';
import { ManageVendorComponent } from './manage-vendor/manage-vendor.component';
import { ManageOrdersComponent } from './manage-orders/manage-orders.component';
import { CreateOrdersComponent } from './create-orders/create-orders.component';
import { TreatmentSummaryComponent } from './treatment-summary/treatment-summary.component';
import { TreatementComponent } from './treatement/treatement.component';
import { ShowNotificationComponent } from './show-notification/show-notification.component';
import { AgreementCreateComponent } from './agreement-create/agreement-create.component';
import { AgreementSetupComponent } from './agreement-setup/agreement-setup.component';
import { MedicalcampsetupComponent } from './medicalcampsetup/medicalcampsetup.component';
import { CreateCampComponent } from './create-camp/create-camp.component';
import { NavigationChartComponent } from './navigation-chart/navigation-chart.component';
import { CreatechartComponent } from './createchart/createchart.component';
import { CafeteriaComponent } from './cafeteria/cafeteria.component';
import { CafeteriaCreateComponent } from './cafeteria-create/cafeteria-create.component';
import { CafeteriaMenusComponent } from './cafeteria-menus/cafeteria-menus.component';
import { CafeteriaMenusCreateComponent } from './cafeteria-menus-create/cafeteria-menus-create.component';
import { EventComponent } from './event/event.component';
import { EventCreateComponent } from './event-create/event-create.component';
import { AlertsSetupComponent } from './alerts-setup/alerts-setup.component';
import { AlertsCreateComponent } from './alerts-create/alerts-create.component';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


const routes: Routes = [
  { path: 'weblogin', component: WebLoginComponent },
  { path: 'weblogin/:id', component: WebLoginComponent },
  { path: 'weblink', component: WebLinkComponent },
  { path: 'acvalidate', component: VerifyPageComponent },
  { path: 'weblink/:id', component: VerifyPageComponent },
  { path: 'clone-workq', component: CloneWorkqComponent },
  { path: 'register', component: WebLoginComponent },
  { path: 'forgotpass', component: ForgotpassComponent },
  { path: 'createpass', component: CreatepassComponent },
  { path: 'viewpatients/:id', component: ViewpatientsComponent },
  { path: 'data-table', component: DataTableComponent },
  { path: 'tabscreen1', component: TabScreen1Component },
  { path: 'tabscreen2', component: TabScreen2Component },
  {
    path: '', canActivate: [AuthGuard], children: [
      { path: 'user-entities', component: UserEntitiesComponent },
      { path: 'patientview/:id', component: PatinetviewComponent },
      { path: 'view/:id', component: ViewpatientsComponent },
      { path: 'viewentity/:id', component: PatinetviewComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'workflow', component: WorkflowComponent },
      { path: 'pdfview/:id', component: PdfviewComponent },
      { path: 'workflowAdd', component: WorkflowAddComponent },
      { path: 'addWork', component: WorkflowAddComponent },
      { path: 'workflowAdd/:id', component: WorkflowAddComponent },
      { path: 'workque/:id', component: ClearworkqComponent },
      { path: 'workq', component: WorkQComponent },
      { path: 'worq', component: WorqMobComponent },
      { path: 'patcheckin-mob', component: PatcheckinMobComponent },
      { path: 'patedit-mob/:id', component: PateditMobComponent },
      { path: 'worque/:id', component: WorqMobComponent },
      { path: 'patientSearch', component: PatientSearchComponent },
      { path: 'patientEdit/:id', component: PatientEditComponent },
      { path: 'checkpointSearch', component: CheckpointSearchComponent },
      { path: 'checkpointAdd', component: CheckpointAddComponent },
      { path: 'checkedin/:id', component: CheckedinComponent },
      { path: 'checkpointAdd/:id', component: CheckpointAddComponent },
      { path: 'patientsCheckIn', component: PatientsCheckInComponent },
      { path: 'patientsChecksIn', component: PatientsCheckInComponent },
      { path: 'patientsCustom', component: PatientscustomworkFlowComponent },
      { path: 'manageusers', component: ManageusersComponent },
      { path: 'manageuser-view/:id', component: ManageuserViewComponent },
      { path: 'manageuser-edit/:id', component: ManageuserEditComponent },
      { path: 'pendinguser-view/:id', component: ManageuserViewComponent },
      { path: 'manage-roles', component: ManageRolesComponent },
      { path: 'manageroles-view/:id', component: ManagerolesViewComponent },
      { path: 'full', component: FullComponent },
      { path: 'adminworkflow', component: AdminworkflowComponent },
      { path: 'settings', component: AppSettingsComponent },
      { path: 'graph', component: GraphComponent },
      { path: 'viewentity', component: ViewentityComponent },
      { path: 'viewmob/:id', component: ViewmobComponent },
      { path: 'newWorkQ', component: ClearworkqComponent },
      { path: 'adminPatientSearch', component: AdminPatientSearchComponent },
      { path: 'departmentsetup', component: DepartmentSetupComponent },
      { path: 'departmentadd', component: DepartmentAddComponent },
      { path: 'departmentedit/:id', component: DepartmentAddComponent },
      { path: 'doctoradd', component: DoctorAddComponent },
      { path: 'doctorsetup', component: DoctorSetupComponent },
      { path: 'doctoredit/:id', component: DoctorAddComponent },
      { path: 'reports', component: ReportsComponent },
      { path: 'user-reference', component: UserReferenceComponent },
      { path: 'creategrouping', component: GroupingComponent },
      { path: 'editgrouping/:id', component: GroupingComponent },
      { path: 'createprescrigroup', component: GroupingComponent },
      { path: 'editprescrigroup/:id', component: GroupingComponent },
      { path: 'manage-grouping', component: ManageGroupingComponent },
      { path: 'calendar', component: CalendarComponent },
      { path: 'calendarmanage', component: CalendarManagePage },
      { path: 'book-appointment', component: BookAppointmentComponent },
      { path: 'treatmentsetup', component: TreatmentsetupComponent },
      { path: 'treatmentadd', component: TreatmentaddComponent },
      { path: 'treatmentedit/:id', component: TreatmentaddComponent },
      { path: 'invoice', component: InvoiceComponent },
      { path: 'invoice/:id', component: InvoiceComponent },
      { path: 'billing', component: BillingComponent },
      { path: 'billing-create', component: BillingCreateComponent },
      { path: 'billing-edit/:id', component: BillingCreateComponent },
      { path: 'services', component: ServicesComponent },
      { path: 'services-create', component: ServicesCreateComponent },
      { path: 'services-edit/:id', component: ServicesCreateComponent },
      { path: 'vendor-register', component: VendorRegisterComponent },
      { path: 'vendor-edit/:id', component: VendorRegisterComponent },
      { path: 'manage-vendor', component: ManageVendorComponent },
      { path: 'manage-orders', component: ManageOrdersComponent },
      { path: 'create-orders', component: CreateOrdersComponent },
      { path: 'edit-orders/:id', component: CreateOrdersComponent },
      { path: 'treatment-summary', component: TreatmentSummaryComponent },
      { path: 'treatement', component: TreatementComponent },
      { path: 'temptreatementedit/:id', component: TreatementComponent },
      { path: 'show-notification', component: ShowNotificationComponent },
      { path: 'agreement-setup', component: AgreementSetupComponent },
      { path: 'agreement-create', component: AgreementCreateComponent },
      { path: 'edit-agreement/:id', component: AgreementCreateComponent },
      { path: 'medicalcampsetup', component: MedicalcampsetupComponent },
      { path: 'create-camp', component: CreateCampComponent },
      { path: 'edit-camp/:id', component: CreateCampComponent },
      { path: 'navigation-chart', component: NavigationChartComponent },
      { path: 'createchart', component: CreatechartComponent },
      { path: 'edit-chart/:id', component: CreatechartComponent },
      { path: 'cafeteria', component: CafeteriaComponent },
      { path: 'cafeteria-create', component: CafeteriaCreateComponent },
      { path: 'cafeteria-edit/:id', component: CafeteriaCreateComponent },
      { path: 'cafeteria-menus', component: CafeteriaMenusComponent },
      { path: 'cafeteria-menus-create', component: CafeteriaMenusCreateComponent },
      { path: 'cafeteria-menus-edit/:id', component: CafeteriaMenusCreateComponent },
      { path: 'event', component: EventComponent },
      { path: 'event-create', component: EventCreateComponent },
      { path: 'event-edit/:id', component: EventCreateComponent },
      { path: 'alerts-setup', component: AlertsSetupComponent },
      { path: 'alerts-create', component: AlertsCreateComponent },
      { path: 'alerts-edit/:id', component: AlertsCreateComponent },

      // { path: 'diagnosis/:id',component: DiagnosisComponent},

      {
        path: 'diagnosis/:id', children: [
          { path: '', component: DiagnosisComponent },
          { path: 'diagnosis-test/:id', component: DiagnosisTestComponent }
        ]
      },


      { path: 'diagnosis-history/:id', component: DiagnosisHistoryComponent },
      { path: 'diagnosises/:id', component: DiagnosisComponent },
      // { path: 'diagnosis-test/:id',component: DiagnosisTestComponent},
      { path: 'getmedicine', component: GetMedicineComponent },
      { path: 'create-medicine', component: CreateMedicineComponent },
      { path: 'edit-medicine/:id', component: CreateMedicineComponent },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ]
  },
  { path: '', redirectTo: 'weblogin', pathMatch: 'full' },
  { path: '**', redirectTo: 'weblogin', pathMatch: 'full' }
];
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    IonicModule.forRoot(),
    DragulaModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    InterceptorModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  entryComponents: [BlockPopComponent, WorqMpopComponent, AssistancepopComponent, Popper1Component, Popper2Component, Popper4Component, TimepickerComponent,
    BlocktimerComponent,
    BlockEndComponent,
    Popper5Component],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    LoginComponent,
    WebLoginComponent,
    WorkflowComponent,
    PatientSearchComponent,
    HeaderComponent,
    WorkQComponent,
    CheckpointSearchComponent,
    CheckpointAddComponent,
    WorkflowAddComponent,
    PatientsCheckInComponent,
    PatientscustomworkFlowComponent,
    SearchPipe,
    SafeHtml,
    FullComponent,
    PatientEditComponent,
    BlockPopComponent,
    WebLinkComponent,
    PatinetviewComponent,
    VerifyPageComponent,
    AppSettingsComponent,
    GraphComponent,
    GoogleChart,
    DashboardComponent,
    CloneWorkqComponent,
    ManageusersComponent,
    RegisterComponent,
    PdfviewComponent,
    ManageuserViewComponent,
    WorqMobComponent,
    WorqMpopComponent,
    ManageRolesComponent,
    UserEntitiesComponent,
    ManagerolesViewComponent,
    ViewentityComponent,
    ViewpatientsComponent,
    ViewmobComponent,
    AssistancepopComponent,
    CheckedinComponent,
    ForgotpassComponent,
    CreatepassComponent,
    DataTableComponent,
    ClearworkqComponent,
    PatcheckinMobComponent,
    PateditMobComponent,
    AdminPatientSearchComponent,
    AdminworkflowComponent,
    TabScreen1Component,
    TabScreen2Component,
    DiagnosisComponent,
    Popper1Component,
    Popper2Component,
    Popper4Component,
    DiagnosisHistoryComponent,
    GetMedicineComponent,
    CreateMedicineComponent,
    DiagnosisTestComponent,
    Popper5Component,
    PdfviewComponent,
    PageScrollDirective,
    ManageuserEditComponent,
    DepartmentSetupComponent,
    DepartmentAddComponent,
    DoctorAddComponent,
    DoctorSetupComponent,
    ReportsComponent,
    UserReferenceComponent,
    GroupingComponent,
    ManageGroupingComponent,
    CalendarComponent,
    TimepickerComponent,
    CalendarManagePage,
    BlocktimerComponent,
    BlockEndComponent,
    BookAppointmentComponent,
    TreatmentsetupComponent,
    TreatmentaddComponent,
    InvoiceComponent,
    BillingComponent,
    BillingCreateComponent,
    ServicesComponent,
    ServicesCreateComponent,
    VendorRegisterComponent,
    ManageVendorComponent,
    ManageOrdersComponent,
    CreateOrdersComponent,
    TreatmentSummaryComponent,
    TreatementComponent,
    ShowNotificationComponent,
    AgreementCreateComponent,
    AgreementSetupComponent,
    MedicalcampsetupComponent,
    CreateCampComponent,
    NavigationChartComponent,
    CreatechartComponent,
    CafeteriaComponent,
    CafeteriaCreateComponent,
    CafeteriaMenusComponent,
    CafeteriaMenusCreateComponent,
    EventComponent,
    EventCreateComponent,
    AlertsSetupComponent,
    AlertsCreateComponent
  ],
  exports: [BlockPopComponent, WorqMpopComponent, AssistancepopComponent],
  providers: [SearchPipe, Camera,
    {
      provide: 'cordova',
      useValue: window['cordova']
    },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
