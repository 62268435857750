import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/api-calls/auth.service';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-manageuser-view',
  templateUrl: './manageuser-view.component.html',
  styleUrls: ['./manageuser-view.component.scss']
})
export class ManageuserViewComponent implements OnInit {
  firstname; lastname; mobile; emailid; deviceId;
  rolename: any = [];
  usergetId;
  userObject: any = [];
  roles: any = [];
  selectedItem: any = [];
  checkpoints: any = [];
  checkpoints1: any = [];
  s1;
  s2;
  s3;
  s4;
  loader: boolean = true;
  pending: boolean = false;
  show: boolean = false;
  show1: boolean = false;
  expand: boolean = false;
  checkExpand: boolean = false;
  items: any = [];
  checkObject: any = [];
  checkclone: any = [];
  pageSizeObj: any = [];
  pageSize: any;
  config: any = []
  colSize: any;
  rowSize: any;
  userActive: boolean = true;
  accountEnabledInd: any;
  languageCode: string;
  language = [
    { name: 'English', value: '101', code: 'en-US' },
    { name: 'Hindi', value: '105', code: 'hi' },
    { name: 'Tamil', value: '103', code: 'ta' },
    { name: 'Bengali', value: '107', code: 'bn' }];
  preflang: any;
  localLanguage: string;

  constructor(public data: DataService, private translate: TranslateService, public app: AppService, public auth: AuthService) {

  }

  async ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.checkpoints1 = [];
    this.checkObject = await this.data.getCheckpoints().toPromise();
    this.checkclone = this.checkObject;
    if (window.location.href.includes("pendinguser")) {
      this.pending = true;
    }
    this.items = await this.data.getCheckpoints().toPromise();
    this.usergetId = this.app.urlData();
    sessionStorage.setItem('currentUser', this.usergetId)
    this.data.getRolesById(this.usergetId).subscribe((res: any) => {
      this.loader = false;
      this.userObject = res;
      this.firstname = this.userObject.firstName;
      this.checkpoints1 = this.userObject.userCheckpoints;
      this.lastname = this.userObject.lastName;
      this.mobile = this.userObject.contactNbrMob;
      this.emailid = this.userObject.emailId;
      this.rolename = this.userObject.roles;
      this.deviceId = this.userObject.deviceId;
      this.preflang = this.userObject.langCd;
      this.accountEnabledInd = this.userObject.accountEnabledInd;
    },
      err => {
        this.loader = false;
      })

    this.data.getUserPrefer(this.usergetId).subscribe((res: any) => {
      this.pageSizeObj = res;
      this.pageSize = this.pageSizeObj[0].userValue;
      this.colSize = this.pageSizeObj[0].reportReadyColumnSize;
      this.rowSize = this.pageSizeObj[0].reportReadyRowSize;
    })
  }

  async manageRoles() {
    this.selectedItem = [];
    this.show = !this.show;
    this.expand = !this.expand;
    await this.data.getRoles().subscribe(res => {
      this.roles = res;
      this.loader = false;
      if (this.rolename) {
        this.rolename.map(res => {
          this.getCheck(res.roleId)
        })
      }
    },
      err => {
        this.loader = false;
      });
  }
  manageCheckpoints() {
    this.show1 = !this.show1;
    this.checkExpand = !this.checkExpand;
    if (this.checkpoints1.length > 0) {
      this.checkpoints1.map(res => {
        this.getCheckpoint(res.id)
      })
    }
  }

  switchLanguage(value) {
    this.preflang = value;
  }

  async getCheck(id) {
    let mapData: any = [];
    mapData = this.roles.filter(res => {
      return res.roleId == id
    });
    if (mapData.length > 0) {
      this.selectedItem.push(mapData[0]);
    }
    this.roles = this.roles.filter(res => !this.selectedItem.find(val => res.roleId === val.roleId));
  }

  async getCheckpoint(id) {
    let mapData: any = [];
    mapData = this.items.filter(res => { return res.id == id });
    if (mapData.length > 0)
      this.checkpoints.push(mapData[0]);
    let order = [1, 3, 2];
    this.checkpoints.sort(function (a, b) {
      return order.indexOf(a.checkPointPositionId) - order.indexOf(b.checkPointPositionId)

    });
    this.items = this.items.filter(res => !this.checkpoints.find(val => res.id === val.id));
  }
  async getbackCheckpoint(id) {
    let mapData: any = [];
    mapData = this.checkpoints.filter(res => { return res.id == id });
    this.items.push(mapData[0]);
    this.checkpoints = this.checkpoints.filter(res => !this.items.find(val => res.id === val.id));

  }

  async getback(id) {
    let mapData: any = [];
    mapData = this.selectedItem.filter(res => { return res.roleId == id });
    this.roles.push(mapData[0]);
    this.selectedItem = this.selectedItem.filter(res => !this.roles.find(val => res.roleId === val.roleId));
  }


  save() {
    if (this.pageSize)
      this.postSize();


    let post1: any = [];
    if (this.selectedItem.length > 0) {
      this.selectedItem.map(res => {
        let post: any = { roleId: res.roleId };
        post1.push(post)
      })
    }
    else {
      this.rolename.map(res => {
        let post: any = { roleId: res.roleId };
        post1.push(post)
      })
    }
    this.data.rolesApprove(this.usergetId, post1).subscribe(res => {
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Roles Updated Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })
      this.app.openPage('manageusers');
    })
    if (this.checkpoints.length > 0) {
      this.userObject.userCheckpoints = this.checkpoints;
    }
    this.language.map(res => {
      if(this.preflang == res.name || this.preflang == res.value || this.preflang == res.code){
        this.userObject.langCd = res.value;
        this.userObject.prefLang = res.code;
      }
    })
    this.data.checkpointSave(this.usergetId, this.userObject).subscribe(res => {
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("User Details Updated Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })
      this.app.openPage('manageusers');
      this.items = this.checkclone;


      // this.userObject.userCheckpoints = null;
      // this.userObject.roles = null;
      // this.userObject.designation = null;
      // this.userObject.prefLang = null;
      // this.userObject.staffAdminInd = null;
      // this.userObject.themeId = null;
      // this.data.updateUserData(this.usergetId, this.userObject).subscribe(res => { })
    })






  }

  postSize() {
    this.loader = true;
    this.pageSizeObj[0].userValue = this.pageSize;
    this.pageSizeObj[0].reportReadyRowSize = this.rowSize;
    this.pageSizeObj[0].reportReadyColumnSize = this.colSize;
    this.data.saveUserPrefer(this.pageSizeObj, this.usergetId).subscribe(res => {
      this.loader = false;
      // this.app.openSnackBar('Page Size Updated Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Page Size Updated Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })
    },
      err => {
        this.loader = false;
        // this.app.openSnackBar('Error !!', 'error-snackbar');
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Error").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
      })
  }

  cancel() {
    this.app.openPage('manageusers');
  }
}
