import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-treatment-summary',
  templateUrl: './treatment-summary.component.html',
  styleUrls: ['./treatment-summary.component.scss']
})
export class TreatmentSummaryComponent implements OnInit {

  constructor(private data: DataService, private translate: TranslateService, public app: AppService) { }

  alldepart: any = [];
  loader;
  ad;
  page;
  component;
  msgContent: string = "";
  languageCode: string;

  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.loader = true;
    this.data.getTreatmentTemplateList().subscribe((res: any) => {
      if (res) {
        this.alldepart = res;
        this.loader = false;
      } else {
        this.alldepart = [];
        this.loader = false;
        this.msgContent = "No Treatment Available"
      }

    }), err => {
      this.loader = false;
    }

  }

  edit(id) {
    this.app.openPage('temptreatementedit/' + id)
  }
  delete(id) {
    this.loader = true;
    this.data.treatmenttemplateDeleteById(id).subscribe(res => {
      this.loader = false;
      this.ngOnInit();
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Deleted Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })

    }, err => {
      this.loader = false;
    })
  }

  addnew() {
    this.app.openPage('treatement')
  }

}
