import { Component, OnInit, HostListener } from '@angular/core';
import { interval } from 'rxjs';
import { AppService } from '../app.service';
import { DataService } from 'src/api-calls/data.service';


@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})

export class FullComponent implements OnInit {

  data:any = [];

  pushData:any = [];

  iStart;
  iEnd = 9;

  pageNo = 1;
  url;

  constructor(public app:AppService,private service:DataService) { 
    if(this.url=='full'){
 
    interval(15000).subscribe(()=>{
      this.refresh()
    })
  }
  window.onkeydown = function( e ) {
    var f = document.fullscreen;
    if  (e.key === "Escape" ) {
      event.preventDefault();
      app.showFull = false;
     
    }
  };

 



 
}

  async ngOnInit() {
    this.url= this.app.urlData();
    this.pushData = await this.service.getWorkqById(this.app.workQData).toPromise();
    this.pushData = this.pushData.entities.sort((a,b)=>{ return a.positionInQ - b.positionInQ });
    this.pushData.map((res,i)=>{
      if(i > this.iEnd){
      
      }
      else{
        this.data.push(res)
      }
    })
  }
  

  exit(){
    this.app.showFull = false;
  }

  async refresh(){

    this.data = [];
    var len = this.pushData.length;
    if(len > this.iEnd){
      this.iStart = this.iEnd;
      this.iEnd = this.iEnd + 10;
      this.pageNo = this.pageNo + 1;
      this.pushData.map((res,i)=>{
        if(i > this.iStart && i <= this.iEnd){
          this.data.push(res)
        }
      })
    }
    if(len < this.iEnd){
      this.pageNo = 1
      this.iEnd = 9;
      this.iStart = 0;
      this.pushData = await this.service.getWorkqById(this.app.workQData).toPromise();
      this.pushData = this.pushData.entities.sort((a,b)=>{ return a.positionInQ - b.positionInQ });
      this.pushData.map((res,i)=>{
        if(i > this.iEnd){
         
        }
        else{
          this.data.push(res)
        }
      })
    }

  }

  async onClickRefresh(){
    this.data = [];
    this.pushData = await this.service.getWorkqById(this.app.workQData).toPromise();
    this.pushData = this.pushData.entities.sort((a,b)=>{ return a.positionInQ - b.positionInQ });
    this.pushData.map((res,i)=>{
      if(i > this.iEnd){
        
      }
      else{
        this.data.push(res)
      }
    })
  }

}
