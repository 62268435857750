import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/api-calls/auth.service';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { DoctorSetupComponent } from '../doctor-setup/doctor-setup.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vendor-register',
  templateUrl: './vendor-register.component.html',
  styleUrls: ['./vendor-register.component.scss']
})
export class VendorRegisterComponent implements OnInit {
  alldepart: any = [];
  name;
  desc;
  code;
  departmentId;
  checkpointId;
  avgProcessingTime;
  doctoredit: boolean;
  loader;
  url;
  id;
  doctorObj: any = [];
  languageCode: string;
  items: any = [];
  dataSource: any;
  msgContent: string;
  avgTime;
  pocName: any;
  pocPhNo: any;
  address: any;
  vendorrObj: any;
  activeInd: boolean;

  constructor(public data: DataService, private translate: TranslateService, public app: AppService, public auth: AuthService) { }

  ngOnInit() {
    this.loader = true;
    this.languageCode = localStorage.getItem('lang');
    // this.departmentGet();
    this.url = this.app.urlData();
    setTimeout(() => {
      this.loader = false;
    }, 500);
    if (this.url != 'vendor-register') {
      this.doctoredit = true;
      this.id = this.app.urlData();
      this.data.getvendorById(this.id).subscribe((res: any) => {
        this.vendorrObj = res;
        this.id = res.id;
        this.address = res.address;
        this.pocName = res.pocName;
        this.pocPhNo = res.pocPhNo;
        this.name = res.name;
        this.loader = false;
      }, err => {
        this.loader = false;
      })
    } else {
      console.log('jj')
    }

  }

  departmentGet() {
    this.data.departmentGetAll().subscribe((res: any) => {
      this.alldepart = res;
    })
  }

  doctorCreate() {
    this.loader = true;
    var post = {
      "name": this.name.toUpperCase(),
      "address": this.address,
      "pocName": this.pocName,
      "pocPhNo": this.pocPhNo,
      "activeInd":true,

    }
    this.data.vendorCreate(post).subscribe((res: any) => {
      this.loader = false;
      this.app.openPage('manage-vendor');
      // this.app.openSnackBar('Doctor Created Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("vendor Created Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })

    }, err => {
      this.loader = false;
    })
  }
  cancel() {
    this.app.openPage('manage-vendor')
  }
  doctorUpdate() {
    this.loader = true;

    this.vendorrObj.name = this.name.toUpperCase();
    this.vendorrObj.id = this.id;
    this.vendorrObj.address = this.address;
    this.vendorrObj.pocName = this.pocName;
    this.vendorrObj.pocPhNo = this.pocPhNo;
    this.activeInd=true;
    this.data.vendorPutById(this.id, this.vendorrObj).subscribe(res => {
      this.loader = false;
      this.app.openPage('manage-vendor');
      // this.app.openSnackBar('Doctor Updated Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("vendor Updated Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })
    }, err => {
      this.loader = false;
    })

  }


}

