import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { MatTableDataSource,MatSort } from '@angular/material';
import { ExcelService } from 'src/api-calls/excel.service';

@Component({
  selector: 'app-manage-roles',
  templateUrl: './manage-roles.component.html',
  styleUrls: ['./manage-roles.component.scss']
})
export class ManageRolesComponent implements OnInit {
  displayedColumns = ['name', 'roleId', 'actions'];
  dataSource: any;
  sort;
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort){
       this.dataSource.sort = this.sort;
  
    }
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  } 
  Cview: boolean = true; 
  roles:any = [];
  capabilities:any = [];
  role = 0;
  capability = 0;
  search1;
  loader:boolean =true;
  searcapability:any = [];
  capbiltyId;
  searrolecap:any =[];
  public manageroles:boolean=false;

  constructor(public data:DataService,public app:AppService,private excelService: ExcelService) { }

  async ngOnInit() {
   await this.data.getRoles().toPromise().then(res=>{
     this.searcapability = res;
     this.searrolecap=res;
      this.dataSource = new MatTableDataSource(this.searrolecap)
     this.loader = false;
    });
    this.capabilities = await this.data.getCapabilities().toPromise();
    
  }

  search(){
    this.capbiltyId = this.app.urlData();
    this.data.searchCapabilitiesById(this.role,this.capability).toPromise().then(res => {
        this.searrolecap = res;
        this.dataSource=res;
        this.loader = false;
       }).catch(
        err => {}
      );
  }
  async cancel(){
    await this.data.getRoles().toPromise().then(res=>{
      this.searcapability = res;
      this.searrolecap=res;
       this.dataSource = new MatTableDataSource(this.searrolecap)
      this.loader = false;
     });
  }
  viewChange(id) {
    if (id == 1) { this.Cview = true; }
    else {this.Cview = false; }
  }

  view(data){
    this.app.openPage('manageroles-view/'+data.roleId);
  }
  ngDoCheck(): void {
    if(localStorage.getItem('capability')){
      let data:any = JSON.parse(localStorage.getItem('capability'));
      if(data.length > 0){
        data.map(res=>{
            if(res.includes('MNGRLS')){
              this.manageroles = true;
            }
            
    })
  }
}
      }

      exportAsXLSX(): void {
        let data:any=[]; 
        this.searrolecap.map(res=>
          data.push({RoleName:res.name,RoleId:res.roleId})
      )
        this.excelService.exportAsExcelFile(data, 'Role Details');
    }
}
