import { Component, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/api-calls/auth.service';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-createpass',
  templateUrl: './createpass.component.html',
  styleUrls: ['./createpass.component.scss']
})
export class CreatepassComponent implements OnInit {

  createForm: FormGroup;
  fontStyle = 'english';
  hide:boolean=false;
  hide1:boolean = false;
  enablebtn:boolean = false;
  loader:boolean=true
  languageCode: string;

  constructor(    private auth: AuthService,
    public app: AppService,
          public toastController: ToastController,
          private translate: TranslateService,
           private formBuilder: FormBuilder) {

    this.passCheck()
  }

  async  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    setTimeout(() => {
      this.loader = false;
    }, 300);
 
  }

  passCheck(){
    this.createForm = this.formBuilder.group({
      newPwd:['',[
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30),
      ]],
      conPwd:['',Validators.required]
    },{validator:this.matching('newPwd','conPwd')})
  }

  matching(newPwd,conPwd){
    return(group:FormGroup)=>{
      if(group.controls[newPwd].value === group.controls[conPwd].value){
        return null
      }
      else{
        return {'matching':true}
      }
    }
  }

  newPassword() {
    this.hide=false;
    this.hide1=false;
   this.createForm.get('conPwd').value.trim()
    var y = window.btoa(this.createForm.get('conPwd').value)
    this.createForm.controls['conPwd'].setValue(y)

    var post = { password: this.createForm.get('conPwd').value };
    this.auth.resetpass(post).subscribe(res => {
      // this.app.openSnackBar('Password Updated Successfully','success-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Password Updated Successfully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
        })
    })
        this.app.openPage('login');
      localStorage.clear();
    },
      err => {
        // this.app.openSnackBar(err.error.msg,'error-snackbar')
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err.error.msg).subscribe((translation: string) => {
              this.app.openSnackBar(translation, "error-snackbar")
          })
      })
        this.createForm.controls['conPwd'].setValue('')
        this.createForm.controls['newPwd'].setValue('')
      }

    )
  }

  passwordshow() {
    this.hide = !this.hide
    var x = this.createForm.get('conPwd').value;
    var y = window.btoa(x)
    this.createForm.controls['conPwd'].setValue(y)    

    var x = this.createForm.get('conPwd').value;
    var y = window.atob(x)
    this.createForm.controls['conPwd'].setValue(y)
  }
  passwordshow1() {
    this.hide1 = !this.hide1;
  }
 
  

   async passcheck(){
    var a = this.createForm.get('newPwd').value;
    var b = this.createForm.get('conPwd').value;
  if(a.length <= b.length){
    if( a != b){
      // this.app.openSnackBar('Password Does Not Match','error-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Password Does Not Match").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
      }
      else{

      }
  
    }
  }

}
