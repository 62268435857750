import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { AuthGuard } from 'src/api-calls/auth.guard';
import { AuthService } from 'src/api-calls/auth.service';
import { AppService } from '../app.service';
import { DataService } from 'src/api-calls/data.service';
declare var fetchOrg:any;
@Component({
  selector: 'app-web-login',
  templateUrl: './web-login.component.html',
  styleUrls: ['./web-login.component.scss']
})
export class WebLoginComponent implements OnInit {

  change:boolean = false;
  orgCode;

  constructor(private main:AppComponent,public auth:AuthService,
              public service:DataService,
              public app:AppService) { }

  ngOnInit() {

    let url = window.location.href.split('/').pop();
    let url1=window.location.href
    if(url == 'register'){
      this.change = true;
    }
    if(url1.includes('weblogin')){
      this.change = false;
      if(this.auth.loggedIn()){
        this.app.openPage('dashboard')
      }
      else{
        if(url.includes('weblogin')){
          this.app.openPage('weblogin')
          new fetchOrg();
          // sessionStorage.removeItem('orgCode');
        }
        else{
        this.orgCode=url;
        sessionStorage.setItem('orgCode',url);
        }
        
 
      }
    
    
  
    }

  }



  

}
