import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-department-setup',
  templateUrl: './department-setup.component.html',
  styleUrls: ['./department-setup.component.scss']
})
export class DepartmentSetupComponent implements OnInit {
  alldepart:any=[];
  loader:boolean;
  msgContent: string = "";
  ad;
  languageCode: string;
  constructor(private data: DataService, private translate: TranslateService, public app: AppService,) { }

  ngOnInit() {
   this.languageCode = localStorage.getItem('lang');
   this.loader = true;
   this.data.departmentGetAll().subscribe((res:any)=>{
    this.loader = false;
     if(res){
     this.alldepart = res;
    }else{
      this.msgContent = "No Data"
    }
   },err=>{
     this.loader = false;
   }) 
  }
  newDepartment(){
    this.app.openPage('departmentadd')
  }

  edit(id){
    this.app.openPage('departmentedit/' + id)
  }

  delete(id){
    this.loader = true;
    this.data.departmentDeleteById(id).subscribe(res=>{
      this.loader = false;
      this.ngOnInit();
      // this.app.openSnackBar('Deleted Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Deleted Successfully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
        })
    })
    },err=>{
      this.loader = false;
    })
  }

}
