import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-viewentity',
  templateUrl: './viewentity.component.html',
  styleUrls: ['./viewentity.component.scss']
})
export class ViewentityComponent implements OnInit {
  entities:any=[];
  loader:boolean=false;
  // mobNo="%2b918825950548";
  mobNo;
  viewCard:boolean=false;
  countrynum='+91'
  constructor(public data:DataService,public app:AppService,public route:Router) { }

  ngOnInit() {
  }
async search(){
  this.loader=true;
  if(this.countrynum=="+91"){
   var phone="%2b91"+this.mobNo
  }
  else{
    var phone="%2b1"+this.mobNo
  }
  await this.data.getEntitiesByMobile(phone).toPromise().then(res=>{
    this.entities=res
    this.loader=false;
    this.viewCard=true;
  })

}
view(item){
  this.app.openPage('viewentity/'+item.id)
  sessionStorage.setItem('name',item.name)
  sessionStorage.setItem('patientRefId',item.patientRefId)
}
}
