import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-workflow-add',
  templateUrl: './workflow-add.component.html',
  styleUrls: ['./workflow-add.component.scss'],
  providers: [DragulaService]
})
export class WorkflowAddComponent implements OnInit {

  subs = new Subscription();
  languageCode: string;
  constructor(public data: DataService, public app: AppService, private translate: TranslateService, public dragulaService: DragulaService) {
    dragulaService.createGroup('DRAGULA_FACTS', {

      moves: function (el: any, container: any, handle: any): any {
        if (el.classList.contains('king')) {
          return false;
        }
        return true;
      }
    });
  }


  items: any = [];
  selectedItem: any = [];
  name;
  description;
  id;
  urlCheck: boolean = false;
  getWorkFlow: any = [];
  url;
  s1;
  s2;
  checkObject:any=[];
  appConfig:any=[]
  maxName;
  maxDes;
  shortCd;
  loader:boolean =true;

  async  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.checkObject = await this.data.getCheckpoints().toPromise();
    this.changeByUrl();
    this.appConfig= JSON.parse(localStorage.getItem('config'));
    let x = this.appConfig.find(res => res.shortCdConst == "WFNM");
    this.maxName = x.userValue;
    let y = this.appConfig.find(res => res.shortCdConst == "WFDESC");
     this.maxDes=y.userValue;
  
  }

  save() {
    if (this.selectedItem.length <= 0) {
      // this.app.openSnackBar('Please select check points !!', 'error-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please select check points").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })

    }
    else {

      this.selectedItem.map((res, i) => {
        this.selectedItem[i].stepNbr = i + 1;
      })

      let post = { name: this.name, description: this.description,shortCd:this.shortCd, checkpoints: this.selectedItem, predefined: true }
      if (this.name) {
        this.data.createWorkFlow(post).toPromise()
          .then((res) => {
            this.name = "";
            this.description = "";
            if (this.url == 'addWork') {
              this.app.openPage('patientsCheckIn')
            }
            else {
              this.app.openPage('workflow');
            }
            // this.app.openSnackBar('Workflow added Successfully !!', 'success-snackbar');
            this.translate.use(this.languageCode).subscribe(() => {
              this.translate.get("Workflow added Successfully").subscribe((translation: string) => {
                  this.app.openSnackBar(translation, "success-snackbar")
              })
          })

          })
          .catch(
            err => {
              // this.presentToast(err.error.msg)
            }
          )
      }
    }

  }
  async getCheck(id) {
    let mapData: any = [];
    mapData = this.checkObject.filter(res=>{return res.id==id});
    this.selectedItem.push(mapData[0])
    this.selectedItem.map(res => {
      if (res.checkPointPositionId == 1 || res.checkPointPositionId == 2) {
        Object.assign(res, { cla: 'king' })
      }
      else {
        Object.assign(res, { cla: 'john' })
      }
    })
    // this.selectedItem.filter(res=>this.selectedItem.checkPointPositionId=='1')
    let order = [1, 3, 2];

    this.selectedItem.sort(function (a, b) {
      return order.indexOf(a.checkPointPositionId) - order.indexOf(b.checkPointPositionId)

    });

    //  this.selectedItem=order.map((item)=>{
    //   return this.selectedItem[item];
    //  })
    this.items = this.items.filter(res => !this.selectedItem.find(val => res.id === val.id));
  }

  async changeByUrl() {
    this.id = this.app.urlData();
    this.url = this.app.urlData();
    if (this.url == 'workflowAdd' || this.url == 'addWork') {
      this.items = await this.data.getCheckpoints().toPromise();
      this.loader=false;
    }
    else {
      this.urlCheck = true;
      this.getWorkFlow = await this.data.getWorkFlowsById(this.id).toPromise()
      if (this.getWorkFlow) {
        this.name = this.getWorkFlow.name;
        this.description = this.getWorkFlow.description;
        this.shortCd = this.getWorkFlow.shortCd;
        this.selectedItem = this.getWorkFlow.checkpoints;
        this.selectedItem = this.selectedItem.sort((a, b) => { return a.stepNbr - b.stepNbr });
      }
      this.items = this.checkObject.filter(res => !this.selectedItem.find(val => res.id === val.id));
      this.loader = false ;
    }

  }
  async getback(id) {
    let mapData: any = [];
    mapData =  this.checkObject.filter(res=>{return res.id==id});
    this.items.push(mapData[0]);
    this.selectedItem = this.selectedItem.filter(res => !this.items.find(val => res.id === val.id));

  }
  edit() {
    this.selectedItem.map((res, i) => {
      this.selectedItem[i].stepNbr = i + 1;
    })
    this.getWorkFlow.name = this.name;
    this.getWorkFlow.description = this.description;
    this.getWorkFlow.shortCd = this.shortCd;
    this.getWorkFlow.checkpoints = this.selectedItem;

    if (this.name) {
      this.data.updateWorkFlow(this.id, this.getWorkFlow).toPromise()
        .then((res) => {

          this.name = "";
          this.description = "";
          this.shortCd="";
          this.app.openPage('workflow');
          // this.app.openSnackBar('Workflow updated Successfully !!', 'success-snackbar');
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get("Workflow updated Successfully").subscribe((translation: string) => {
                this.app.openSnackBar(translation, "success-snackbar")
            })
        })

        })
        .catch(
          err => {
            // this.presentToast(err.error.msg)

          }
        )
    }
  }
  cancel() {
    this.app.openPage('workflow');
  }

}
