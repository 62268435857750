import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { DataService } from 'src/api-calls/data.service';

@Component({
  selector: 'app-manage-grouping',
  templateUrl: './manage-grouping.component.html',
  styleUrls: ['./manage-grouping.component.scss']
})
export class ManageGroupingComponent implements OnInit {
  allmedicineGroup: Object;
  loader: boolean;
  change: boolean;

  constructor(public app: AppService, public data: DataService,) { }

  ngOnInit() {
    this.loader = true;
    this.change = false;
    if (!this.change) {
      let post = {
        orgId: localStorage.getItem('organizationId'),
        prescriptionInd: true,
      }
      this.data.searchmedgroupDetails(post).subscribe(res => {
        this.allmedicineGroup = res;
        this.loader = false;
      })
    } else {
      let post = {
        orgId: localStorage.getItem('organizationId'),
      }
      this.data.searchmedgroupDetails(post).subscribe(res => {
        this.allmedicineGroup = res;
        this.loader = false;
      })
    }
  }

  getCheck(id) {
    if (this.change) {
      this.app.openPage('editgrouping/' + id);
    } else {
      this.app.openPage('editprescrigroup/' + id);
    }
  }

  create() {
    if (this.change) {
      this.app.openPage('creategrouping');
    } else {
      this.app.openPage('createprescrigroup');
    }
  }

  search() {

  }

  medgroup() {
    this.loader = true;
    this.change = true;
    let post = {
      orgId: localStorage.getItem('organizationId'),
    }
    this.data.searchmedgroupDetails(post).subscribe(res => {
      this.allmedicineGroup = res;
      this.loader = false;
    })
  }

  presgroup() {
    this.loader = true;
    this.change = false;
    let post = {
      orgId: localStorage.getItem('organizationId'),
      prescriptionInd: true,
    }
    this.data.searchmedgroupDetails(post).subscribe(res => {
      this.allmedicineGroup = res;
      this.loader = false;
    })
  }
}
