import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/api-calls/auth.service';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-department-add',
  templateUrl: './department-add.component.html',
  styleUrls: ['./department-add.component.scss']
})
export class DepartmentAddComponent implements OnInit {
  name;code;id;
  desc;
  loader:boolean;
  url;
  departedit:boolean = false;
  show:boolean = false;
  doctorgetId;
  doctorList;
  doctorName;
  doctorObject;
  selectedItem: any = [];
  expand;
  
  departget:any={};
  languageCode: string;
  constructor(public data: DataService,  private translate: TranslateService, public app: AppService, public auth: AuthService) { }

  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.url = this.app.urlData();
    console.log(this.url)
    if(this.url !='departmentadd'){
      this.departedit = true;
      this.id = this.app.urlData();
      this.data.departmentGetById(this.id).subscribe((res:any)=>{
        if(res){
          this.loader = false;
          this.departget = res;
          this.name = this.departget.name;
          this.desc = this.departget.desc;
        }else{
          this.loader = false;
        }
      },err=>{
        this.loader =false;
      })
  }
}

  departmentCreate(){
    this.loader = true;
   var post={
    "name": this.name,
    "desc": this.desc
   }
   this.data.departmentCreate(post).subscribe((res:any)=>{
    this.loader = false;
    this.app.openPage('departmentsetup');
    // this.app.openSnackBar('Department Created Successfully !!', 'success-snackbar');
    this.translate.use(this.languageCode).subscribe(() => {
      this.translate.get("Department Created Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
      })
  })
   },err=>{
     this.loader = false;
   })
  }
  
  departmentUpdate(){
    this.loader = true;
    this.departget.name = this.name;
    this.departget.desc = this.desc;
    this.data.departmentPutById(this.id,this.departget).subscribe(res=>{
      this.loader = false;
      this.app.openPage('departmentsetup');
      // this.app.openSnackBar('Department Updated Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Department Updated Successfully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
        })
    })
    },err=>{
      this.loader = false;
    })
  }

  cancel(){
    this.app.openPage('departmentsetup')
  }
  // this.data.getRoles().subscribe(res => {
  //   this.roles = res;
  //   this.loader = false;
  async doctorsList(){
    // this.selectedItem=[];
    this.show = true;
    this.expand = true;
   await this.data.getDoctorsList().subscribe(res=>{
      this.doctorObject=res;
      console.log(this.doctorObject);
      this.loader = false;
      if(this.doctorObject){
        this.doctorObject.map(res=>{
          this.getCheck(res.id)
        })
      }
    })

    }
    async getCheck(id) {
      let mapData: any = [];
      mapData = this.doctorObject.filter(res => { 
        return res.id == id });
        console.log(mapData)
      if (mapData.length > 0) {
        console.log(mapData.length)
        this.selectedItem.push(mapData[0]);
      }
      this.doctorObject = this.doctorObject.filter(res => !this.selectedItem.find(val => res.id === val.id));
    }
    async getback(id) {
      let mapData: any = [];
      mapData = this.selectedItem.filter(res => { return res.id == id });
      this.doctorObject.push(mapData[0]);
      this.selectedItem = this.selectedItem.filter(res => !this.doctorObject.find(val => res.id === val.id));
    }
  
}


