import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/api-calls/auth.service';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { DoctorSetupComponent } from '../doctor-setup/doctor-setup.component';
import { HandlerService } from "src/api-calls/handler.service";
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormControl, Validators } from '@angular/forms'


@Component({
  selector: 'app-event-create',
  templateUrl: './event-create.component.html',
  styleUrls: ['./event-create.component.scss']
})
export class EventCreateComponent implements OnInit {
  coalldepart: any = [];
  name;
  desc;
  code;
  selectdate: any;
  selectdate1: any;
  departmentId;
  checkpointId;
  avgProcessingTime;
  doctoredit: boolean;
  loader;
  url;
  id;
  doctorObj: any = [];
  languageCode: string;
  items: any = [];
  dataSource: any;
  msgContent: string;
  avgTime;
  pocName: any;
  pocPhNo: any;
  address: any;
  vendorrObj: any;
  activeInd: boolean;
  uploading: boolean;
  vendor: any;
  phoneNo: any;
  emailId: any;
  cafeteriasObj: any;
  alldepart: any;
  unitPrice: any;
  itemCode: any;
  description: any;
  venue: any;
  eventObj: any;
  sdt: string;
  edt: string;
  blob1: any;
  imageName: any;
  imageId: any;
  images: string[] = [];
  myForm: any;
  openImg: boolean;
  docId: any;

  constructor(public data: DataService, private translate: TranslateService, public app: AppService,
    private sanitizer: DomSanitizer, public auth: AuthService, public handler: HandlerService,) { }

  ngOnInit() {
    this.loader = true;
    this.languageCode = localStorage.getItem('lang');
    // this.departmentGet();
    this.url = this.app.urlData();
    setTimeout(() => {
      this.loader = false;
    }, 500);
    if (this.url != 'event-create') {
      this.doctoredit = true;
      this.id = this.app.urlData();
      this.data.geteventById(this.id).subscribe((res: any) => {
        this.eventObj = res;
        this.id = res.id;
        this.description = res.description;
        this.venue = res.venue;
        this.name = res.name;
        const docItemsIds = res.docId;      
        console.log(docItemsIds)
        this.loader = false;
        this.setUserProfilePic(docItemsIds);
        const collectedTs = res.sdt;
        const parsedDate = new Date(collectedTs);
        this.selectdate = parsedDate.toISOString().split('T')[0]
        const collectedTs1 = res.edt;
        const parsedDate1 = new Date(collectedTs1);
        this.selectdate1 = parsedDate1.toISOString().split('T')[0]
        this.loader = false;
      }, err => {
        this.loader = false;
      })
    } else {
      console.log('jj')
    }

  }

  departmentGet() {
    this.data.departmentGetAll().subscribe((res: any) => {
      this.alldepart = res;
    })
  }

  doctorCreate() {
    this.loader = true;
    this.sdt = this.selectdate.getFullYear() + '-' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '-' + ("0" + this.selectdate.getDate()).slice(-2)
    this.edt = this.selectdate1.getFullYear() + '-' + ("0" + (this.selectdate1.getMonth() + 1)).slice(-2) + '-' + ("0" + this.selectdate1.getDate()).slice(-2)
    var post = {
      "name": this.name.toUpperCase(),
      "description": this.description,
      "venue": this.venue,
      "docId":this.imageId,
      "sdt": this.sdt,
      "edt": this.edt,
      orgId: localStorage.getItem('organizationId'),
    }
    this.data.eventCreate(post).subscribe((res: any) => {
      this.loader = false;
      this.app.openPage('event');
      // this.app.openSnackBar('Doctor Created Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("event Created Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })

    }, err => {
      this.loader = false;
    })
  }
  cancel() {
    this.app.openPage('event')
  }
  doctorUpdate() {
    this.loader = true;
    this.sdt = this.selectdate.getFullYear() + '-' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '-' + ("0" + this.selectdate.getDate()).slice(-2)
    this.edt = this.selectdate1.getFullYear() + '-' + ("0" + (this.selectdate1.getMonth() + 1)).slice(-2) + '-' + ("0" + this.selectdate1.getDate()).slice(-2)
    this.eventObj.name = this.name.toUpperCase();
    this.eventObj.id = this.id;
    this.eventObj.venue = this.venue;
    this.eventObj.description = this.description;
    this.eventObj.docId = this.docId;
    this.eventObj.sdt = this.sdt;
    this.eventObj.edt = this.edt;
    this.data.eventPutById(this.id, this.eventObj).subscribe(res => {
      this.loader = false;
      this.app.openPage('event');
      // this.app.openSnackBar('Doctor Updated Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("event Updated Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })
    }, err => {
      this.loader = false;
    })

  }
  handleFile(files) {
    console.log(files)
    var img = new Image();
    this.blob1 = files[0];
    this.imageName = this.blob1.name;
    this.data.uploadChartFile(this.imageName, this.blob1).subscribe((res: any) => {
      if (res) {
        this.loader = false;
        this.imageId = res.id;
        this.uploading = false;
        this.setUserProfilePic(res.id);
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Image Uploaded Succsessfully!!").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
          })
        })
      } else {
        this.loader = false;
      }
    },
      (err) => {
        this.loader = false;
        this.handler.res(err);
      });

    if (files && files[0]) {
      var filesAmount = files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          // Push Base64 string
          this.images.push(event.target.result);
          this.patchValues();

        }
        reader.readAsDataURL(files[i]);
      }
    }
  }
  patchValues() {
    this.myForm.patchValue({
      fileSource: this.images
    });
  }
  viewImage(url) {
    this.url = url;
    this.openImg = true;
  }

  setUserProfilePic(id) {
    if (id != null) {
      this.data.getDocumentbychartId(id).subscribe((res: any) => {
        let objectURL = URL.createObjectURL(res);
        this.url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      });
    }
  }

  setUserProfilePicBy(id, mainres) {
    if (id != null) {
      this.data.getDocumentbychartId(id).subscribe((res: any) => {
        let objectURL = URL.createObjectURL(res);
        mainres.img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }, err => {
        this.loader = false;
        this.handler.err(err);
      });
    } else {
      mainres.img = 'assets/icon/ads.png';
    }
  }
  removeImage(url: any) {
    if (url != undefined) {
      this.loader = true;
      this.data.deleteitemDocument(url).subscribe(res => {
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Deleted successfully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
          })
        })
        this.loader= false;
      },
        err => {
          this.loader = false;
          this.handler.err(err);
        })
    }
  }
}


