import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { AuthService } from 'src/api-calls/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Platform } from '@ionic/angular';
import { DataService } from 'src/api-calls/data.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {
  countrynum="+91";
  languageCode: string;
  constructor(public app: AppService,
    public fb:FormBuilder,
    public translate: TranslateService,
    private auth: AuthService,
    private platform:Platform,
    public service:DataService) { 
    }

    registerForm: FormGroup;
    public passhide:boolean = true;
    public click:boolean = false;

  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.registerForm = this.fb.group({
      emailId: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(4)]],
      firstName: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', Validators.required],
      contactNbrMob: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(16)]],
      orgCode:['',Validators.required],
      userType:1,
      appId:2
      
    });
    
  }

  async register() {
    this.service.loader = true;
    var x = this.registerForm.get('password').value;
    var y = window.btoa(x)
    this.registerForm.controls['password'].setValue(y)
    var num=this.registerForm.get('contactNbrMob').value
    var phone=this.countrynum+num;
    this.registerForm.controls['contactNbrMob'].setValue(phone)
    this.auth.register(this.registerForm.value).subscribe(
      res =>{
        // this.app.openSnackBar('Registered Successfully,Please reach out to your supervisor for approval','success-snackbar');
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Registered Successfully,Please reach out to your supervisor for approval").subscribe((translation: string) => {
              this.app.openSnackBar(translation, "success-snackbar")
          })
      })
        this.service.loader = false;
        this.app.openPage('weblogin');
    },
     err=>{
           this.service.loader = false;
          //  this.app.openSnackBar(err.error.msg,'error-snackbar');
           this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get(err.error.msg).subscribe((translation: string) => {
                this.app.openSnackBar(translation, "error-snackbar")
            })
        })
           var x = this.registerForm.get('password').value;
           var y = window.atob(x)
           this.registerForm.controls['password'].setValue(y)
           this.registerForm.controls['contactNbrMob'].setValue(num)
          })
  }

  change(id){
    // if(id == 1){
    //   this.click = true;
    // }
    // if(id == 2){
    //   this.click = false;
    // }
  }

}
