import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatSort } from '@angular/material';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
  displayedColumns = ['customerName', 'customerPhNo', 'payTotal','actions'];
  allbilling: any = [];
  loader;
  ad;
  page;
  component;
  msgContent: string = "";
  languageCode: string;
  Cview: boolean = true;
  selectdate = new Date();
  selectdate1 = new Date();
  date1: string;
  date2: string;
  sort: any;
  dataSource: any;

  checkloc: any[] = [
  ];
  alldepart: any = [];
  name;
  desc;
  code;
  doctoredit: boolean;
  url;
  id;
  treatmentObj: any = [];
  items: any = [];
  avgTime;
  langCd: any;
  sittingCount: any;
  activeInd: any;
  issues: any = [];
  checklocation1: any = [];
  handler: any;
  // checkloc: any = [];
  assetTypeVendorTagList: any;
  mobile: any;
  patientType: number;
  patientObject: any;
  documentNbr: any;
  secCntryNum: any;
  secMobile: any;
  countrynum: any;
  age: any;
  entype: any;
  entryType: any;
  visitype: any;
  visitType: any;
  wardNbr: any;
  receiptNbr: any;
  doctorName: any;
  doctorId: any;
  refId: any;
  customerName: any;
  customerGstNo: any;
  printbilling: boolean = false;
  newbill: boolean = false;
  customerPhNo: any;
  billTotal: any;
  cgst: any;
  sgst: any;
  payTotal: any;
  discountAmt: any;
  billingObj: any = [];
  services: any = [];
  quantity: any;
  billing: any = [];
  billingPay: any;
  billDetailId: any;
  paymentModeOptions: any = ([] = [
    { name: "Cash", id: "1" },
    { name: "Online", id: "2" },
  ]);
  paymentStatusOptions: any = ([] = [
    { name: "Not Paid", id: "1" },
    { name: "Paid", id: "2" },
    { name: "Pending Paid", id: "3" },
  ]);
  recordPaymentId: any;
  recordPaymentName: any;
  amountGiven: any;
  paymentMode: any;
  paymentStatus: any;
  Payment: boolean = false;
  patientsdetails: boolean = false;
  paymentdetails: boolean = false;
  SummaryDetails: boolean = false;
  proceedelement: any;
  levelName: any;
  levelId: any;
  activeLevelIndex: any;
  billId: any;
  closebtn: boolean =false;


  constructor(private data: DataService,
    private translate: TranslateService,
    private route: Router,
    public app: AppService) { }
    
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;

    }
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  ngOnInit() {
    this.getServices();
    this.languageCode = localStorage.getItem('lang');
    this.loader = true;
    this.selectdate.setDate(this.selectdate.getDate() -7);
    this.date1 = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2) + ' ' + '00:00:00'
    this.date2 = this.selectdate1.getFullYear() + '/' + ("0" + (this.selectdate1.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate1.getDate()).slice(-2) + ' ' + '23:59:59'
    this.data.getbillingDate(this.date1, this.date2).subscribe((res: any) => {
      if (res) {
        this.allbilling = res.bills;
        this.dataSource = new MatTableDataSource(this.allbilling)
        this.loader = false;
      } else {
        this.allbilling = [];
        this.loader = false;
        this.msgContent = "No Bill Available"
      }

    }), err => {
      this.loader = false;
    }

  }
  allpage() {
    this.checkloc = [];
    this.doctoredit = true;
    this.newbill = true;
    this.data.getBillingById(this.levelId).subscribe((res: any) => {
      this.billingObj = res;
      this.billId = res.id;
      this.billingPay = res.billPayments;
      this.billingPay.map((res) => {
        this.paymentModeOptions.map((res1: any) => {
          if (res.paymentMode == res1.id) {
            Object.assign(res, { paymentModeName: res1.name })
          }
        });
        this.paymentStatusOptions.map((res1: any) => {
          if (res.paymentStatus == res1.id) {
            Object.assign(res, { paymentStatusName: res1.name })
          }
        });
      });
      this.Payment = true;
      if (this.billingPay.length > 0) {
        this.SummaryDetails = true;
      } else {
        this.SummaryDetails = false;
      }
      this.amountGiven = res.payTotal;
      this.activeInd = true,
        this.id = res.id;
      this.customerGstNo = res.customerGstNo;
      this.discountAmt = res.discountAmt;
      this.mobile = res.customerPhNo;
      this.customerName = res.customerName;
      // this.assetTypeVendorTagList = res.billingDetails;
      // if (this.assetTypeVendorTagList && this.assetTypeVendorTagList.length > 0) {
      //   this.assetTypeVendorTagList.map((res, i) => {
      //     this.getIssuesTypes1(res.serviceId);
      //     this.checkloc[i].sgst = res.sgst;
      //     this.checkloc[i].cgst = res.cgst;
      //     this.checkloc[i].quantity = res.quantity;
      //     this.checkloc[i].totalCost = res.totalCost;
      //     this.checkloc[i].payTotal = res.payTotal;
      //     this.checkloc[i].billDetailId = res.billDetailId;
      //     this.checkloc[i].billId = res.billId;
      //   })
      // }
      this.assetTypeVendorTagList = res.billingDetails;
      if (this.assetTypeVendorTagList && this.assetTypeVendorTagList.length > 0) {
        this.assetTypeVendorTagList.forEach((res, i) => {
          this.getIssuesTypes1(res.serviceId);     
          // Ensure that this.checkloc has enough elements
          if (!this.checkloc[i]) {
            this.checkloc[i] = {}; // Initialize a new object if it doesn't exist
          }      
          // Update properties in this.checkloc[i]
          this.checkloc[i].serviceName = res.serviceName;
          this.checkloc[i].unitPrice = res.unitPrice;
          this.checkloc[i].sgst = res.sgst;
          this.checkloc[i].cgst = res.cgst;
          this.checkloc[i].quantity = res.quantity;
          this.checkloc[i].totalCost = res.totalCost;
          this.checkloc[i].payTotal = res.payTotal;
          this.checkloc[i].billDetailId = res.billDetailId;
          this.checkloc[i].billId = res.billId;
        });
      }
    }, err => {
      this.loader = false;
    })
  }
  viewChange(id) {
    if (id == 1) { this.Cview = true; }
    else { this.Cview = false; }
  }
  newBilling() {
    // this.app.openPage('billing-create')
    this.newbill = true;
    this.clear();
  }
  edit(id) {
    this.app.openPage('billing-edit/' + id)
  }
  delete(id) {
    this.loader = true;
    this.data.billingDeleteById(id).subscribe(res => {
      this.loader = false;
      this.ngOnInit();
      // this.app.openSnackBar('Deleted Successfully !!', 'success-snackbar');   
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Deleted Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })

    }, err => {
      this.loader = false;
    })
  }
  search() {
    this.loader = true;
    this.date1 = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2) + ' ' + '00:00:00'
    this.date2 = this.selectdate1.getFullYear() + '/' + ("0" + (this.selectdate1.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate1.getDate()).slice(-2) + ' ' + '23:59:59'
    this.data.getbillingDate(this.date1, this.date2).subscribe((res:any)=> {
      this.allbilling = res.bills;
      this.dataSource = new MatTableDataSource(this.allbilling)
      this.closebtn = true;
      this.loader = false;
    })
  }

  getbybilling() {
    this.doctoredit = true;
    this.data.getBillingById(this.id).subscribe((res: any) => {
      this.billingObj = res;
      this.billingPay = res.billPayments;
      this.billingPay.map((res) => {
        this.paymentModeOptions.map((res1: any) => {
          if (res.paymentMode == res1.id) {
            Object.assign(res, { paymentModeName: res1.name })
          }
        });
        this.paymentStatusOptions.map((res1: any) => {
          if (res.paymentStatus == res1.id) {
            Object.assign(res, { paymentStatusName: res1.name })
          }
        });
      });

      this.Payment = true;
      if (this.billingPay.length > 0) {
        this.SummaryDetails = true;
      } else {
        this.SummaryDetails = false;
      }
      this.amountGiven = res.payTotal;
      this.activeInd = true,
        this.id = res.id;
      this.customerGstNo = res.customerGstNo;
      this.discountAmt = res.discountAmt;
      this.mobile = res.customerPhNo;
      this.customerName = res.customerName;
      this.assetTypeVendorTagList = res.billingDetails;
      if (this.assetTypeVendorTagList && this.assetTypeVendorTagList.length > 0) {
        this.assetTypeVendorTagList.map((res, i) => {
          this.getIssuesTypes1(res.serviceId);
          this.checkloc[i].sgst = res.sgst;
          this.checkloc[i].cgst = res.cgst;
          this.checkloc[i].quantity = res.quantity;
          this.checkloc[i].totalCost = res.totalCost;
          this.checkloc[i].payTotal = res.payTotal;
          this.checkloc[i].billDetailId = res.billDetailId;
          this.checkloc[i].billId = res.billId;
        })
      }
    }, err => {
      this.loader = false;
    })
  }

  quantityadded(event: any, index: number) {
    const newValue = parseInt(event.target.value); // Assuming quantity is a number
    this.checkloc[index].quantity = newValue;
    if (this.id != null) {
      this.treatmentUpdate();
      this.getbybilling();

    } else {
      this.treatmentCreate();
      this.getbybilling();
    }
  }

  treatmentCreate() {
    if (this.mobile != null && this.mobile != "" && this.mobile != undefined
      && this.customerName != null && this.customerName != "" && this.customerName != undefined) {
      this.loader = true;
      let assetTypeVendorTagList = this.checkloc ? this.checkloc : this.checklocation1;
      if (assetTypeVendorTagList) {
        assetTypeVendorTagList.map((res) => {
          res.serviceId = res.id; // Set the serviceId directly on the object
          res.quantity = res.quantity; // Set the quantity directly on the object
          return res; // Return the modified object in the map function
        });
      }
      var post = {
        "customerName": this.customerName.toUpperCase(),
        "customerGstNo": this.customerGstNo,
        "customerPhNo": this.mobile,
        "activeInd": true,
        "discountAmt": this.discountAmt,
        "billingDetails": assetTypeVendorTagList,
      }
      this.data.billingCreate(post).subscribe((res: any) => {
        this.id = res.id;
        this.loader = false;
        // this.app.openPage('billing');
        // this.app.openSnackBar('Doctor Created Successfully !!', 'success-snackbar');
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Billing Created Successfully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
          })
        })

      }, err => {
        this.loader = false;
      })
    } else {
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please enter the required fields").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })

    }
  }

  clear() {
    this.mobile = "";
    this.customerName = "";
    this.customerGstNo = "";
    this.discountAmt = "";
    this.checkloc = [];
    this.activeLevelIndex = "",
    this.doctorName = [];
    this.doctoredit = false;
    this.ngOnInit();

  }
  cancel() {
    // this.app.openPage('billing')
    this.selectdate = new Date();
    this.selectdate1 = new Date();
    this.closebtn =false;
    this.clear();
  }
  treatmentUpdate() {
    this.loader = true;
    let assetTypeVendorTagList = this.checkloc ? this.checkloc : this.checklocation1;
    if (assetTypeVendorTagList) {
      assetTypeVendorTagList.map((res) => {
        res.serviceId = res.id; // Set the serviceId directly on the object
        res.quantity = res.quantity; // Set the quantity directly on the object
      });
    }
    this.billingObj.customerName = this.customerName.toUpperCase();
    this.billingObj.id = this.id;
    this.billingObj.customerGstNo = this.customerGstNo;
    this.billingObj.customerPhNo = this.mobile;
    this.billingObj.discountAmt = this.discountAmt;
    this.billingObj.billingDetails = assetTypeVendorTagList;
    this.activeInd = true;
    this.data.billingPutById(this.id, this.billingObj).subscribe(res => {
      this.loader = false;
      // this.app.openPage('billing');
      // this.app.openSnackBar('Doctor Updated Successfully !!', 'success-snackbar');
      // this.translate.use(this.languageCode).subscribe(() => {
      //   this.translate.get("Billing Updated Successfully").subscribe((translation: string) => {
      //     this.app.openSnackBar(translation, "success-snackbar")
      //   })
      // })
    }, err => {
      this.loader = false;
    })

  }

  focusOutFunction(data) {
    if (this.mobile) {
      this.data.getBilliingDetails(this.mobile).subscribe((res) => {
        if (res) {
          this.patientObject = res;
          this.customerName = this.patientObject.name;
          this.documentNbr = this.patientObject.documentNbr;
          if (this.patientObject.entityType != 2) {
            var len = this.patientObject.contactNbr.length;
            if (this.patientObject.secContactNbr) {
              var len1 = this.patientObject.secContactNbr.length;
              len1 = len1 - 10;
            }
            len = len - 10;
            if (len1) {
              this.secCntryNum = this.patientObject.secContactNbr.slice(
                0,
                len1
              );
              this.secMobile = this.patientObject.secContactNbr.slice(-10);
            }
            this.countrynum = this.patientObject.contactNbr.slice(0, len);
            this.mobile = this.patientObject.contactNbr.slice(-10);
          }
          this.refId = this.patientObject.patientRefId;
          this.age = this.patientObject.age;
          if (this.patientObject.entryType) {
            this.entype.map((res) => {
              if (res.id == this.patientObject.entryType) {
                this.entryType = res.name;
              }
            });
          }
          if (this.patientObject.visitType) {
            this.visitype.map((res) => {
              if (res.id == this.patientObject.visitType) {
                this.visitType = res.name;
              }
            });
          }
          if (this.patientObject.expctdReportingTime) {
            let r = this.patientObject.expctdReportingTime;
          }
          this.wardNbr = this.patientObject.wardNbr;
          this.receiptNbr = this.patientObject.receiptNbr;
          this.doctorName = this.patientObject.doctorName;
          this.doctorId = this.patientObject.doctorId;
          this.app.aged = this.patientObject.aged;
          this.app.wheeled = this.patientObject.wheeled;
          this.app.nri = this.patientObject.internationalPatient;
          this.app.callRequired = this.patientObject.callRequired;
        }
      });
    }
  }

  getServices() {
    this.data.getServices().subscribe((res) => {
      this.services = res;
      this.services.map(res => {
        if (res.id) {
          Object.assign(res, { quantity: 1 })

        }
      })
      this.loader = false;
    }, err => {
      this.loader = false;
      this.handler.err(err);
    })
  }


  async getIssuesTypes(id) {
    this.Payment = true;
    if (this.mobile != null && this.mobile != "" && this.mobile != undefined
      && this.customerName != null && this.customerName != "" && this.customerName != undefined) {
      let mapData: any = [];
      mapData = this.services.filter(res => { return res.id == id });
      if (mapData.length > 0)
        this.checkloc.push(mapData[0]);
      let order = [1, 3, 2];
      this.checkloc.sort(function (a, b) {
        return order.indexOf(a.checkPointPositionId) - order.indexOf(b.checkPointPositionId)

      });
      this.services = this.services.filter(res => !this.checkloc.find(val => res.id === val.id));
      if (this.id != null) {
        this.treatmentUpdate();
        setTimeout(() => {
          this.getbybilling();
        }, 1500);


      } else {
        this.treatmentCreate();
        setTimeout(() => {
          this.getbybilling();
        }, 1500);

      }

    } else {
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please enter the required fields").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })
    }
  }
  async getIssuesTypes1(id) {
    let mapData: any = [];
    mapData = this.services.filter(res => { return res.id == id });
    if (mapData.length > 0)
      this.checkloc.push(mapData[0]);
    let order = [1, 3, 2];
    this.checkloc.sort(function (a, b) {
      return order.indexOf(a.checkPointPositionId) - order.indexOf(b.checkPointPositionId)

    });
    this.services = this.services.filter(res => !this.checkloc.find(val => res.id === val.id));

  }
  async getbackIssuesTypess(id) {
    let mapData: any = [];
    mapData = this.checkloc.filter(res => { return res.id == id });
    this.services.push(mapData[0]);
    this.checkloc = this.checkloc.filter(res => !this.services.find(val => res.id === val.id));
    this.treatmentUpdate();
    this.getbybilling();
  }
  decrementQuantity(item: any) {
    // Decrease the quantity of the item in the checkloc array
    if (item.quantity > 0) {
      item.quantity--;
    }
    if (this.id != null) {
      this.treatmentUpdate();
      setTimeout(() => {
        this.getbybilling();
      }, 1000);

    } else {
      this.treatmentCreate();
      setTimeout(() => {
        this.getbybilling();
      }, 1000);
    }
  }
  incrementQuantity(item: any) {
    // Increase the quantity of the item in the checkloc array
    item.quantity++;
    if (this.id != null) {
      this.treatmentUpdate();
      setTimeout(() => {
        this.getbybilling();
      }, 1000);

    } else {
      this.treatmentCreate();
      setTimeout(() => {
        this.getbybilling();
      }, 1000);
    }
  }
  invoice() {
    // this.printbilling = true;
    this.route.navigateByUrl('/invoice/' + this.id);
  }
  pickdeptment(data) {
    if (data) {
      this.recordPaymentName = data.name;
      this.recordPaymentId = data.id;
      this.recordPayment();
    }
  }
  recordPayment() {
    this.loader = true;
    let post = {
      billId: this.id,
      paymentMode: this.paymentMode,
      paymentStatus: this.paymentStatus,
      amountGiven: this.amountGiven,
    }
    this.data.recordPaymentPutById(this.id, post).subscribe(res => {
      this.loader = false;
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Recordpayment Updated Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })
      this.paymentdetails = false;
    }, err => {
      this.loader = false;

    })
    setTimeout(() => {
      this.getbybilling();
    }, 1000);

  }
  recordPayments() {
    this.paymentdetails = true;
  }
  close() {
    this.paymentdetails = false;
  }
  newAdd() {
    this.paymentdetails = false;
  }
  focusOutFunction1(data) {
    if (this.mobile) {
      var serviceId;
      if (this.patientType == 1 || this.patientType == 3) {
        serviceId = 1;
      }
      if (this.patientType == 2 || this.patientType == 4) {
        serviceId = 2;
      }
      this.data.getPatientDetail1(this.mobile).subscribe((res: any) => {
        if (res) {
          this.patientsdetails = true;
          this.patientObject = res;
          this.dataSource = new MatTableDataSource(this.patientObject)
        }
      });
    }
  }
  setvalue(a) {
    this.proceedelement = a;
  }
  view() {
    if (this.proceedelement == undefined || this.proceedelement == "" || this.proceedelement == null) {
      return this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select Patient ",).subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar");
        });
      })
    } else {
      const element = this.proceedelement;
      this.patientsdetails = false;
      this.customerName = element.name;
      this.age = element.age;
      this.app.aged = element.aged;
      this.app.wheeled = element.wheeled;
      this.app.nri = element.internationalPatient;
      this.app.callRequired = element.callRequired;
    }
  }
  close1() {
    this.patientsdetails = false;
  }
  newAdd1() {
    if (!this.proceedelement) {
      this.patientsdetails = false;
    }

  }

  openleveldown(level, index) {
    this.levelName = level.name;
    this.levelId = level.id;
    this.activeLevelIndex = index; // Update the active level index
    this.allpage();

  }
  openleveldown1(element, index) {
    this.levelName = element.name;
    this.levelId = element;
    this.activeLevelIndex = index; // Update the active level index
    this.allpage();

  }
  
}
