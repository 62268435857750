import { Component, OnInit, HostListener } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { DragulaService } from 'ng2-dragula';
import { MatDialog } from '@angular/material';
import { AssistancepopComponent } from '../assistancepop/assistancepop.component';
import { AuthService } from 'src/api-calls/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-patcheckin-mob',
  templateUrl: './patcheckin-mob.component.html',
  styleUrls: ['./patcheckin-mob.component.scss']
})
export class PatcheckinMobComponent implements OnInit {
  items: any = [];
  name;
  description;
  token;
  s1;
  s2;
  mobile;
  patientId;
  workFlowId;
  add
  loader: boolean = false;
  loader1: boolean = false;
  getWorkFlow: any = [];
  selectedItem: any = [];
  checkpoints: any = [];
  pushcheckpoints: any = [];
  customWorkId;
  metadata: string;
  enable: boolean = true;
  checkObject;
  countrynum = '+91';
  phone;
  refId;
  refIdCheck;
  reportingTime;
  showtime: boolean = false;
  showcalendar: boolean = false;
  showToken: string = "";
  sa: any;
  appConfig: any;
  maxName;
  maxToken;
  maxMetadata;
  maxPatientRef;
  language = [{ name: 'English', value: '101' },
  { name: 'Hindi', value: '105' },
  { name: 'Tamil', value: '103' },
  { name: 'Bengali', value: '107' }];
  preflang = '101';
  patients: any[] = [{ name: 'In-Patient', id: '2' },
  { name: 'Out-Patient', id: '1' },
  { name: 'MHC', id: '3' },
  { name: 'BedSide', id: '4' }];
  patientType = this.patients[1].id
  aged: boolean;
  wheeled: boolean;
  callRequired: boolean;
  selectdate = new Date();
  todayDate = new Date();
  cloneCheck: any;
  receiptNbr;
  wardNbr;
  bedSide: any;
  doctorName; age; gender; height; weight;
  public setApptTime: boolean = false;
  showAppt: boolean = false;
  patientObject: any = [];
  workqId;
  workqTime;
  showTime;
  mulCard: any;
  documentNbr;
  languageCode: string;
  constructor(public data: DataService, private translate: TranslateService, public app: AppService, public dialog: MatDialog, public dragulaService: DragulaService, public auth: AuthService) {
  }
  async ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.app.aged = false;
    this.app.callRequired = false;
    this.app.wheeled = false;
    this.app.nri = false;
    this.loader = true;
    this.data.getCheckpointsPatientAdd(1).subscribe(res => {
      this.checkObject = res;
      this.cloneCheck = JSON.parse(JSON.stringify(res));
      this.loader = false;
    })
    let x = JSON.parse(localStorage.getItem('config'));
    let a = x.find(res => res.shortCdConst == "TKNAUTO");
    this.showToken = a.userValue;
    let b = x.find(res => res.shortCdConst == "PATNM");
    this.maxName = b.userValue;
    let c = x.find(res => res.shortCdConst == "PATTKNNO");
    this.maxToken = c.userValue;
    let d = x.find(res => res.shortCdConst == "PATMTDA");
    this.maxMetadata = d.userValue;
    let e = x.find(res => res.shortCdConst == "PATRFID");
    this.maxPatientRef = e.userValue;
    // this.todayDate.setDate(this.todayDate.getDate() - 1);
    // this.todayDate.setDate(this.todayDate.getDate() - 1);
    let f = x.find(res => res.shortCdConst == "MCPTCD");
    if (f.userValue == "true" || f.userValue == true) {
      this.mulCard = true;
    }
    else {
      this.mulCard = false;
    }
  }
  switchLanguage(value) {
    this.preflang = value
  }

  async save() {
    if (this.name == undefined || this.selectedItem.length == 0 || this.name == "" || this.refId == undefined || this.refId == "") {
      // this.app.openSnackBar('Please enter the required fields', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please enter the required fields").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    }
    else {
      if (this.showcalendar == true) {
        if (this.reportingTime == undefined || this.reportingTime == "") {
          // this.app.openSnackBar('Please enter the required fields', 'error-snackbar')
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get("Please enter the required fields").subscribe((translation: string) => {
              this.app.openSnackBar(translation, "error-snackbar")
            })
          })
        }
        else {
          this.reportingTime = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2) + ' ' + this.reportingTime;
          this.app.loader1 = true;
          if (this.mobile != null || this.mobile != undefined || this.mobile != "") {
            this.phone = this.countrynum + this.mobile;
          }
          if (this.mobile == null) {
            this.phone = this.countrynum + undefined
          }
          if (this.mobile == "") {
            this.phone = this.countrynum + undefined
          }

          var post = {
            name: this.name.toUpperCase(),
            contactNbr: this.phone,
            tokenNbr: this.token,
            patientRefId: this.refId,
            metadata: this.metadata,
            prefLang: this.preflang,
            callRequired: this.app.callRequired,
            aged: this.app.aged,
            wheeled: this.app.wheeled,
            internationalPatient: this.app.nri,
            age: this.age,
            category: 5,
            gender: this.gender,
            height: this.height,
            weight: this.weight,
            doctorName: this.doctorName,
            receiptNbr: this.receiptNbr,
            wardNbr: this.wardNbr,
            documentNbr: this.documentNbr
          };

          if (this.patientType != 4) {
            Object.assign(post, { entityType: this.patientType })
          }
          if (this.patientType == 4) {
            Object.assign(post, { entityType: '2', bedSide: 'true' })
          }
          if (this.showcalendar) {
            Object.assign(post, { expctdReportingTimeMins: this.reportingTime })
          }
          this.selectedItem.map((res, i) => {
            this.selectedItem[i].stepNbr = i + 1;
          })

          if (this.name) {

            this.data.patientCheckin(post).toPromise()
              .then((res: any) => {
                this.patientId = res.id
                if (this.patientId != undefined) {
                  if (this.getWorkFlow.name != undefined) {
                    this.name = this.getWorkFlow.name;
                  }
                  let post1 = { name: this.name, description: this.name, checkpoints: this.selectedItem, predefined: false, entityId: this.patientId }
                  this.data.createWorkFlow(post1).toPromise()
                    .then((res: any) => {
                      this.customWorkId = res.id;
                      if (this.patientId != undefined) {
                        this.data.patientAssignWorkFlow(this.customWorkId, this.patientId, post).subscribe(
                          res => {
                            this.app.loader1 = false;
                            // this.app.openSnackBar(' Patient Checked-In Successfully !!', 'success-snackbar')
                            this.translate.use(this.languageCode).subscribe(() => {
                              this.translate.get("Patient Checked-In Successfully").subscribe((translation: string) => {
                                this.app.openSnackBar(translation, "success-snackbar")
                              })
                            })
                            this.name = "";
                            this.description = "";
                            this.mobile = "";
                            this.token = "";
                            this.metadata = "";
                            this.workFlowId = "";
                            this.age = "";
                            this.gender = "";
                            this.height = "";
                            this.weight = "";
                            this.enable = true;
                            this.selectedItem = [];
                            this.checkObject = JSON.parse(JSON.stringify(this.cloneCheck));
                            this.refId = "";
                            this.reportingTime = "";
                            this.patientType = this.patients[1].id
                            this.app.aged = false;
                            this.app.wheeled = false;
                            this.app.callRequired = false;
                            this.app.nri = false;
                            this.wardNbr = "";
                            this.receiptNbr = "";
                            this.doctorName = "";
                            this.showtime = false;
                            this.showcalendar = false;
                            this.getWorkFlow = [];
                            this.documentNbr = "";
                            // this.bedSide=false;
                          },
                          err => {
                            // this.app.openSnackBar(err, 'success-snackbar')
                            this.translate.use(this.languageCode).subscribe(() => {
                              this.translate.get(err).subscribe((translation: string) => {
                                this.app.openSnackBar(translation, "success-snackbar")
                              })
                            })
                            this.app.loader1 = false
                          }
                        );
                      }
                    });
                }
              })
              .catch(err => {
                // this.app.openSnackBar('Something went wrong', 'error-snackbar'),
                this.translate.use(this.languageCode).subscribe(() => {
                  this.translate.get("Something went wrong").subscribe((translation: string) => {
                    this.app.openSnackBar(translation, "error-snackbar")
                  })
                })
                this.app.loader1 = false
              }
              )
          }
        }
      }
      else {
        this.app.loader1 = true;
        if (this.mobile != null || this.mobile != undefined || this.mobile != "") {
          this.phone = this.countrynum + this.mobile;
        }
        if (this.mobile == null) {
          this.phone = this.countrynum + undefined
        }
        if (this.mobile == "") {
          this.phone = this.countrynum + undefined
        }
        var post = {
          name: this.name.toUpperCase(),
          contactNbr: this.phone,
          tokenNbr: this.token,
          patientRefId: this.refId,
          metadata: this.metadata,
          age: this.age,
          gender: this.gender,
          height: this.height,
          weight: this.weight,
          prefLang: this.preflang,
          callRequired: this.app.callRequired,
          aged: this.app.aged,
          wheeled: this.app.wheeled,
          internationalPatient: this.app.nri,
          category: 5,
          doctorName: this.doctorName,
          receiptNbr: this.receiptNbr,
          wardNbr: this.wardNbr,
          documentNbr: this.documentNbr
        };

        if (this.patientType != 4) {
          Object.assign(post, { entityType: this.patientType })
        }
        if (this.patientType == 4) {
          Object.assign(post, { entityType: '2', bedSide: 'true' })
        }
        this.selectedItem.map((res, i) => {
          this.selectedItem[i].stepNbr = i + 1;
        })

        if (this.name) {

          this.data.patientCheckin(post).toPromise()
            .then((res: any) => {
              this.patientId = res.id
              if (this.patientId != undefined) {
                if (this.getWorkFlow.name != undefined) {
                  this.name = this.getWorkFlow.name;
                }
                let post1 = { name: this.name, description: this.name, checkpoints: this.selectedItem, predefined: false, entityId: this.patientId }
                this.data.createWorkFlow(post1).toPromise()
                  .then((res: any) => {
                    this.customWorkId = res.id;
                    if (this.patientId != undefined) {
                      this.data.patientAssignWorkFlow(this.customWorkId, this.patientId, post).subscribe(
                        res => {
                          this.app.loader1 = false;
                          // this.app.openSnackBar(' Patient Checked-In Successfully !!', 'success-snackbar')
                          this.translate.use(this.languageCode).subscribe(() => {
                            this.translate.get("Patient Checked-In Successfully").subscribe((translation: string) => {
                              this.app.openSnackBar(translation, "success-snackbar")
                            })
                          })
                          this.name = "";
                          this.description = "";
                          this.mobile = "";
                          this.token = "";
                          this.metadata = "";
                          this.age = "";
                          this.gender = "";
                          this.height = "";
                          this.weight = "";
                          this.workFlowId = "";
                          this.enable = true;
                          this.selectedItem = [];
                          this.checkObject = JSON.parse(JSON.stringify(this.cloneCheck));
                          this.refId = "";
                          this.reportingTime = "";
                          this.patientType = this.patients[1].id
                          this.app.aged = false;
                          this.app.wheeled = false;
                          this.app.callRequired = false;
                          this.app.nri = false;
                          this.showtime = false;
                          this.showcalendar = false;
                          this.wardNbr = "";
                          this.receiptNbr = "";
                          this.doctorName = "";
                          this.getWorkFlow = [];
                          this.documentNbr = "";
                        },
                        err => {
                          // this.app.openSnackBar(err, 'success-snackbar')
                          this.translate.use(this.languageCode).subscribe(() => {
                            this.translate.get(err).subscribe((translation: string) => {
                              this.app.openSnackBar(translation, "success-snackbar")
                            })
                          })
                          this.app.loader1 = false
                        }
                      );
                    }
                  });
              }

            })
            .catch(err => {
              // this.app.openSnackBar('Something went wrong', 'error-snackbar'),
              this.translate.use(this.languageCode).subscribe(() => {
                this.translate.get("Something went wrong").subscribe((translation: string) => {
                  this.app.openSnackBar(translation, "error-snackbar")
                })
              })
              this.app.loader1 = false
            }

            )

        }
      }
    }
  }
  async getWorkId(item) {
    this.checkObject.map(res => {
      if (res.id == item.id) {
        if (res.selected) {
          delete res.selected
          this.selectedItem = this.selectedItem.filter(res => { return res.id != item.id });
          this.selectedItem.map(res => {
            if (res.id == item.id) {
              res.date = "";
              res.time = "";
            }
          })
        }
        else {
          Object.assign(res, { selected: true })
          this.selectedItem.push(res)
        }
      }

    })
    if (this.patientType == 2 || item.cpType == 2) {
      let da: Date = new Date();
      this.datePost(item.id, da)
      this.checkObject.map(res => {
        if (res.id == item.id) {
          if (res.select) {
            delete res.select
          }
          else {
            Object.assign(res, { select: true })
          }
        }
      })
    }
  }
  async getWorkqId(id) {
    this.items.map(res => {
      if (res.id == id) {
        Object.assign(res, { selected: true })
      }
      else {
        if (res.selected) {
          delete res.selected
        }
      }
    })
    this.checkpoints = await this.data.getCheckpoints().toPromise();
    this.getWorkFlow = await this.data.getWorkFlowsById(id).toPromise();
    if (this.getWorkFlow) {
      this.selectedItem = this.getWorkFlow.checkpoints
    }
  }
  async custom() {
    this.loader = true;
    this.selectedItem = [];
    this.data.getWorkFlows().subscribe(res => {
      this.items = res;
      this.loader = false;
    })
    this.enable = false;
  }
  cancel() {
    this.name = "";
    this.description = "";
    this.mobile = "";
    this.token = "";
    this.metadata = "";
    this.age = "";
    this.gender = "";
    this.height = "";
    this.weight = "";
    this.workFlowId = "";
    this.enable = true;
    this.refId = "";
    this.reportingTime = "";
    this.app.aged = false;
    this.app.wheeled = false;
    this.app.callRequired = false;
    this.app.nri = false;
    this.wardNbr = "";
    this.receiptNbr = "";
    this.doctorName = "";
    this.patientType = this.patients[1].id;
    this.showcalendar = false;
    this.showtime = false;
    this.getWorkFlow = [];
    this.documentNbr = "";
  }
  ngDoCheck(): void {
    if (this.patientType == 2) {
      this.showtime = true;
      this.showcalendar = true;
    }
    if (this.patientType == 4) {
      this.showcalendar = false;
      this.showtime = true;
    }
    if (this.patientType == 1 || this.patientType == 3) {
      this.showcalendar = false;
      this.showtime = false;
    }
    let apptcap = JSON.parse(localStorage.getItem('capability'))
    if (apptcap.length > 0) {
      apptcap.map(res => {
        if (res.includes('APTTMORD')) {
          this.setApptTime = true;

        }
      })
    }
  }
  openDialog(id): void {
    const dialogRef = this.dialog.open(AssistancepopComponent, {
      data: id,
      disableClose: true
    });
  }
  focusOutFunction(data) {
    if (this.refId.length == 10) {
      if (this.refId) {
        var serviceId;
        if (this.patientType == 1 || this.patientType == 3) {
          serviceId = 1;
        }
        if (this.patientType == 2 || this.patientType == 4) {
          serviceId = 2;
        }
        this.data.getPatientDetails(this.refId, serviceId, this.mobile).subscribe(res => {
          if (res) {
            this.patientObject = res
            this.name = this.patientObject.name;
            this.age = this.patientObject.age;
            this.gender = this.patientObject.gender;
            this.height = this.patientObject.height;
            this.weight = this.patientObject.weight;
            if (this.patientObject.entityType != 2) {
              var len = this.patientObject.contactNbr.length;
              len = len - 10;
              this.countrynum = this.patientObject.contactNbr.slice(0, len);
              this.mobile = this.patientObject.contactNbr.slice(-10);
            }
            this.refId = this.patientObject.patientRefId;
            this.age = this.patientObject.age;
            this.gender = this.patientObject.gender;
            this.height = this.patientObject.height;
            this.weight = this.patientObject.weight;
            this.metadata = this.patientObject.metadata;
            this.documentNbr = this.patientObject.documentNbr;
            if (this.patientObject.expctdReportingTime) {
              let r = this.patientObject.expctdReportingTime
              this.reportingTime = r.slice(11, 16);
              this.selectdate = new Date(r)
            }
            this.wardNbr = this.patientObject.wardNbr;
            this.receiptNbr = this.patientObject.receiptNbr;
            this.doctorName = this.patientObject.doctorName;
          }
        })
      }
    }
  }


  checkPrevEntity() {

    if (this.refId) {
      // this.app.loader1=true;
      var post = {
        patientRefId: this.refId
      }
      var serviceId;
      if (this.patientType == 1 || this.patientType == 3) {
        serviceId = 1;
      }
      if (this.patientType == 2 || this.patientType == 4) {
        serviceId = 2;
      }
      this.data.getPatientDetails(this.refId, serviceId, this.mobile).subscribe(res => {
        if (res) {
          this.patientObject = res
          this.name = this.patientObject.name;
          this.age = this.patientObject.age;
          this.gender = this.patientObject.gender;
          this.height = this.patientObject.height;
          this.weight = this.patientObject.weight;
          if (this.patientObject.entityType != 2) {
            var len = this.patientObject.contactNbr.length;
            len = len - 10;
            this.countrynum = this.patientObject.contactNbr.slice(0, len);
            this.mobile = this.patientObject.contactNbr.slice(-10);
          }
          this.refId = this.patientObject.patientRefId;
          this.age = this.patientObject.age;
          this.gender = this.patientObject.gender;
          this.height = this.patientObject.height;
          this.weight = this.patientObject.weight;
          this.metadata = this.patientObject.metadata;
          this.documentNbr = this.patientObject.documentNbr;
          if (this.patientObject.expctdReportingTime) {
            let r = this.patientObject.expctdReportingTime
            this.reportingTime = r.slice(11, 16);
            this.selectdate = new Date(r)
          }
          this.wardNbr = this.patientObject.wardNbr;
          this.receiptNbr = this.patientObject.receiptNbr;
          this.doctorName = this.patientObject.doctorName;
          this.app.loader1 = false;
        }
        else {
          this.app.loader1 = false;
        }
        err => {
          this.app.loader1 = false;
        }
      })
      this.data.searchPatient1(post).toPromise().then(res => {

        this.refIdCheck = res
        if (this.refIdCheck != null) {
          this.app.refIdObj = res
          this.app.loader1 = false;
          this.openDialog(2)
        }
        else {
        }
        err => {
          this.app.loader1 = false;
        }
      })
    }
  }
  getAppt(data) {
    this.selectedItem = []
    this.loader = true;
    this.data.getCheckpointsPatientAdd(data).subscribe(res => {
      this.checkObject = res;
      // this.cloneCheck = JSON.parse(JSON.stringify(res));
      this.loader = false;
    })
  }
  async datePost(id, date1: Date) {
    if (date1 == undefined) {
      date1 = new Date()
    }
    let checkGetById: any = await this.data.getCheckpointById(id).toPromise();
    let temp;

    if (this.patientType == 2 || checkGetById.cpType == 2) {
      let date = date1.getFullYear() + '/' + ("0" + (date1.getMonth() + 1)).slice(-2) + '/' + ("0" + date1.getDate()).slice(-2)

      this.data.getAppointment(checkGetById.worq.id, date).subscribe(res => {

        this.reportingTime = res;
        temp = res;
        this.selectedItem.map(res => {
          if (res.id == id) {
            Object.assign(res, { apptTimeStr: date + ' ' + this.reportingTime, time: temp, date: date1 })
          }
        })
      },
        err => {

          if (err.status == 409) {
            this.selectedItem.map(res => {
              if (res.id == id) {
                Object.assign(res, { time: "", date: date1 })
              }
            })
            // this.app.openSnackBar('Appointments Not Available for Selected Date', 'error-snackbar')
            this.translate.use(this.languageCode).subscribe(() => {
              this.translate.get("Appointments Not Available for Selected Date").subscribe((translation: string) => {
                this.app.openSnackBar(translation, "error-snackbar")
              })
            })
          }
        })
    }
  }
  timeChange(id, time, date1: Date) {
    let date = date1.getFullYear() + '/' + ("0" + (date1.getMonth() + 1)).slice(-2) + '/' + ("0" + date1.getDate()).slice(-2);
    this.reportingTime = time;
    let temp = time;
    this.selectedItem.map(res => {
      if (res.id == id) {
        Object.assign(res, { apptTimeStr: date + ' ' + this.reportingTime, time: temp })
      }
    })
  }
  async getReportingTIme() {
    let checkGetById: any = await this.data.getCheckpointById(this.workqId).toPromise()
    if (this.patientType == 2 || checkGetById.cpType == 2) {

      if (checkGetById) {
        let date = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2)
        await this.data.getAppointment(checkGetById.worq.id, date).toPromise().then(res => {
          this.reportingTime = res
          this.workqTime = res
          if (this.reportingTime) {
            let date = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2);
            this.selectedItem.map(res => {
              if (res.id == this.workqId) {
                Object.assign(res, { apptTimeStr: date + ' ' + this.reportingTime, time: this.workqTime })
              }
            })

          }
        },
          err => {
            if (err.status == 409) {
              // this.app.openSnackBar('Appointments Not Available for Selected Date', 'error-snackbar')
              this.translate.use(this.languageCode).subscribe(() => {
                this.translate.get("Appointments Not Available for Selected Date").subscribe((translation: string) => {
                  this.app.openSnackBar(translation, "error-snackbar")
                })
              })
            }
          })
      }
    }
  }
  clearArray() {
    this.selectedItem = []
  }
}
