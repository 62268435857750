import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-reference',
  templateUrl: './user-reference.component.html',
  styleUrls: ['./user-reference.component.scss']
})
export class UserReferenceComponent implements OnInit {

  constructor(public app: AppService,
    public translate: TranslateService) { }

  ngOnInit() {
  }

  async setLang(data) {
    console.log(data);
    localStorage.setItem('lang', data)
    this.app.setLang();
  }
  switchLanguage(language: string) {
    this.translate.setDefaultLang(language);
    localStorage.setItem('lang', language);
    this.setLang(language);
    console.log(language);
  }

}
