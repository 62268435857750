import { Component, OnInit, ViewChild,AfterViewInit, ElementRef} from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { SearchPipe } from 'src/api-calls/custom.pipe';
import { MatTableDataSource,MatSort } from '@angular/material';
import { ExcelService } from 'src/api-calls/excel.service';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit,AfterViewInit {
  displayedColumns = ['name', 'description', 'actions'];
  dataSource: any; 
  sort;
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  } 
 

  name;
  description;
  binding;
  Cview: boolean = true;
  add;
  items: any = [];
  workFlow = ["Scan", "X-ray", "Ultrasonic"];
  loader: boolean = true;
  msgContent: string = ""
  public editWf: boolean = false;
  public addWf: boolean = false;
  appConfig: any = []
  maxName;
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort){
       this.dataSource.sort = this.sort;
  
    }
  }

  constructor(public data: DataService, public app: AppService, public pipe: SearchPipe,private excelService: ExcelService) { }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  
  ngOnInit() {
    this.data.getWorkFlows().subscribe(res => {
      this.loader = false;
      if (res != null) {
        this.items = res;
        this.dataSource = new MatTableDataSource(this.items)
      }
      else {
        this.msgContent = "No workflows"
      }
    })

    this.appConfig = JSON.parse(localStorage.getItem('config'));
  let x = this.appConfig.find(res => res.shortCdConst== "WFNM");
  this.maxName=x.userValue;
  }

  viewChange(id) {
    if (id == 1) { this.Cview = true; }
    else {this.Cview = false; }
  }
  ngDoCheck(): void {

    if (localStorage.getItem('capability')) {
      let data: any = JSON.parse(localStorage.getItem('capability'));
      if (data.length > 0) {
        data.map(res => {
          if (res.includes('EDITWFLW')) {
            this.editWf = true;
          }
          if (res.includes('AWFS')) {
            this.addWf = true;
          }

        })
      }
    }

  }
  
  search(data) {
    // let post={name:this.name,description:this.description};

    // this.data.searchWorkFlow(post).toPromise().then(res => {
    //   this.items = res;
    //  
    // }).catch(
    //   err => {}
    // );

    if (data) {
      this.data.getWorkFlows().subscribe(res => {
        this.items = res;
        this.dataSource=res;
        this.items = this.pipe.transform(this.items, 'name', data);
        this.dataSource=this.items;
      })
  
    }
    else {
      this.data.getWorkFlows().subscribe(res => {
        this.items = res;
        this.dataSource=res;
        this.loader = false;
      })

    }
  }
  edit(id) {
    this.app.openPage('workflowAdd/' + id)
  }
  exportAsXLSX(): void {
    let data:any=[]; 
    this.items.map(res=>
      data.push({WorkflowName:res.name,WorkflowDescription:res.description})
    )
    this.excelService.exportAsExcelFile(data, 'WorkFlow Details');
}

}



