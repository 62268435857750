import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/api-calls/auth.service';
import { DataService } from 'src/api-calls/data.service';

@Component({
  selector: 'app-tab-screen1',
  templateUrl: './tab-screen1.component.html',
  styleUrls: ['./tab-screen1.component.scss']
})
export class TabScreen1Component implements OnInit {
  userId;
  checkId: any;
  checkPointId;
  checkObject: any;
  workQId;
  entities: any = [];
  currentPatient: any;
  currentPatientList: any = [];
  loader: boolean = false;
  subCheckPointId;
  subObject: any = [];
  patientInQ;
  QueueData: any = [];
  constructor(public auth: AuthService, public service: DataService) { }

  async ngOnInit() {
    this.loader = true;

    var post = { deviceId: "db2ef8cf-ec83-61af-8656-870337737048", password: window.btoa("Test@123"), appId: "2" }
    this.auth.login(post).subscribe((res: any) => {
      if (res.accountEnabledInd) {
        // localStorage.setItem('hashKey', res.hashKey);
        // localStorage.setItem('currentUserId', JSON.stringify(res.userId));
        // localStorage.setItem('organizationId',res.organizationId);

        // this.service.appSettings()
      }
    })
    this.userId = localStorage.getItem('currentUserId')
    var post1 = { userId: localStorage.getItem('currentUserId') }
    await this.service.getRolesById(localStorage.getItem('currentUserId')).toPromise().then((res: any) => {
      this.checkId = res.userCheckpoints;
      this.checkPointId = this.checkId[0].id
      // this.checkId = this.checkId.filter((res, i) => { return i <= 4 })
    })
    if (this.checkId.length > 0) {
      await this.service.getCheckpointById(this.checkPointId).toPromise().then((res: any) => {
        this.checkObject = res;
        if (this.checkObject.subCheckpoints) {
          this.subObject = this.checkObject.subCheckpoints;
        }
        this.workQId = this.checkObject.worq.id
      })
      this.entities = await this.service.getWorkqById(this.workQId).toPromise();
      this.patientInQ = this.entities.length;
      this.entities = this.entities.entities.sort((a, b) => { return a.positionInQ - b.positionInQ });
      this.currentPatient = this.entities.filter(res => { return res.positionInQ == 0 });
      this.QueueData = this.entities.filter(res => { return res.positionInQ != 0 });
      this.patientInQ = this.QueueData.length;
      this.currentPatientList = this.currentPatient;
      if (this.currentPatient.length > 0) {
        this.currentPatient = this.currentPatient[0];
        this.loader = false;
      }
      else if (this.currentPatient.length <= 0) {
        this.currentPatient = undefined;
        this.loader = false;
      }
      if (this.checkObject.subCheckpoints) {
        this.subCheckPointId = this.currentPatient.subCheckpoint.id;
      }
    }
  }
  async next(id) {
    this.loader = true;
    this.service.nextQ(this.workQId, id, null)
      .subscribe(res => {
        this.loader = false;
        this.refresh();

      },
        err => {
          this.loader = false;
        }
      )
  }
  async missed(data) {
    this.loader = true;
    await this.service.missed(this.workQId, data.id, this.subCheckPointId).toPromise()
      .then((res: any) => {
        this.loader = false;
        this.refresh();
      })
      .catch(err => {
        this.loader = false;
      })

  }
  async refresh() {
    this.loader = true;
    this.currentPatient = [];
    this.entities = await this.service.getWorkqById(this.workQId).toPromise();
    if (this.entities.entities) {
      this.entities = this.entities.entities.sort((a, b) => { return a.positionInQ - b.positionInQ });
      this.patientInQ = this.entities.length;
      this.currentPatient = this.entities.filter(res => { return res.positionInQ == 0 });
      this.QueueData = this.entities.filter(res => { return res.positionInQ != 0 });
      this.patientInQ = this.QueueData.length;
      this.currentPatientList = this.currentPatient;
      if (this.checkObject.subCheckpoints) {
        this.currentPatient = this.currentPatientList.filter(res => {
          return res.subCheckpoint.id == this.subCheckPointId
        })
      }
      if (this.currentPatient.length > 0) {
        this.currentPatient = this.currentPatient[0];
        this.loader = false;
      }
      else if (this.currentPatient.length <= 0) {
        this.currentPatient = undefined;
        this.loader = false;
      }
    }
  }
  async changeSub() {
    this.currentPatient = [];
    this.entities = await this.service.getWorkqById(this.workQId).toPromise();
    if (this.entities.entities) {
      this.entities = this.entities.entities.sort((a, b) => { return a.positionInQ - b.positionInQ });
      this.patientInQ = this.entities.length;
      this.currentPatient = this.entities.filter(res => { return res.positionInQ == 0 });
      this.QueueData = this.entities.filter(res => { return res.positionInQ != 0 });
      this.patientInQ = this.QueueData.length;
      this.currentPatientList = this.currentPatient;
      this.currentPatient = this.currentPatientList.filter(res => {
        return res.subCheckpoint.id == this.subCheckPointId
      })
      if (this.currentPatient.length > 0) {
        this.currentPatient = this.currentPatient[0];
      }
      else if (this.currentPatient.length <= 0) {
        this.currentPatient = undefined;
      }
    }

  }
}


