import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/api-calls/auth.service';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { DoctorSetupComponent } from '../doctor-setup/doctor-setup.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cafeteria-menus-create',
  templateUrl: './cafeteria-menus-create.component.html',
  styleUrls: ['./cafeteria-menus-create.component.scss']
})
export class CafeteriaMenusCreateComponent implements OnInit {

  coalldepart: any = [];
  name;
  desc;
  code;
  departmentId;
  checkpointId;
  avgProcessingTime;
  doctoredit: boolean;
  loader;
  url;
  id;
  doctorObj: any = [];
  languageCode: string;
  items: any = [];
  dataSource: any;
  msgContent: string;
  avgTime;
  pocName: any;
  pocPhNo: any;
  address: any;
  vendorrObj: any;
  activeInd: boolean;
  vendor: any;
  phoneNo: any;
  emailId: any;
  cafeteriasObj: any;
  alldepart: any;
  unitPrice: any;
  itemCode: any;

  constructor(public data: DataService, private translate: TranslateService, public app: AppService, public auth: AuthService) { }

  ngOnInit() {
    this.loader = true;
    this.languageCode = localStorage.getItem('lang');
    // this.departmentGet();
    this.url = this.app.urlData();
    setTimeout(() => {
      this.loader = false;
    }, 500);
    if (this.url != 'cafeteria-menus-create') {
      this.doctoredit = true;
      this.id = this.app.urlData();
      this.data.getcafeteriaMenusById(this.id).subscribe((res: any) => {
        this.cafeteriasObj = res;
        this.id = res.itemId;
        this.unitPrice = res.unitPrice;
        this.itemCode = res.itemCode;
        this.name = res.name;
        this.loader = false;
      }, err => {
        this.loader = false;
      })
    } else {
      console.log('jj')
    }

  }

  departmentGet() {
    this.data.departmentGetAll().subscribe((res: any) => {
      this.alldepart = res;
    })
  }

  doctorCreate() {
    this.loader = true;
    var post = {
      "name": this.name.toUpperCase(),
      "unitPrice": this.unitPrice,
      "itemCode": this.itemCode,
      "activeInd":true,
       orgId: localStorage.getItem('organizationId'),
    }
    console.log(post)
    this.data.cafeteriaMenusCreate(post).subscribe((res: any) => {
      this.loader = false;
      this.app.openPage('cafeteria-menus');
      // this.app.openSnackBar('Doctor Created Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("cafeteria Created Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })

    }, err => {
      this.loader = false;
    })
  }
  cancel() {
    this.app.openPage('cafeteria-menus')
  }
  doctorUpdate() {
    this.loader = true;

    this.cafeteriasObj.name = this.name.toUpperCase();
    this.cafeteriasObj.itemId = this.id;
    this.cafeteriasObj.unitPrice = this.unitPrice;
    this.cafeteriasObj.itemCode = this.itemCode;
    this.activeInd=true;
    this.data.cafeteriaMenusPutById(this.id, this.cafeteriasObj).subscribe(res => {
      this.loader = false;
      this.app.openPage('cafeteria-menus');
      // this.app.openSnackBar('Doctor Updated Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("cafeteria Updated Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })
    }, err => {
      this.loader = false;
    })

  }


}

