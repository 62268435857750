import { Component, OnInit, HostListener } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { ExcelService } from 'src/api-calls/excel.service';
import { SearchPipe } from 'src/api-calls/custom.pipe';
import { interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-adminworkflow',
  templateUrl: './adminworkflow.component.html',
  styleUrls: ['./adminworkflow.component.scss']
})
export class AdminworkflowComponent implements OnInit {

  date1;
  date2;
  search;
  items: any = [];
  searchparam: any = {};
  excel: any = [];
  msgContent: string;
  loader: boolean = false;
  loader1: boolean = true;
  loader2: boolean = false;
  loader3: boolean = false;
  selectId;
  mobileNo;
  entityId;
  countrynum = '+91';
  expand: boolean = false;
  search1: boolean = true;
  selectIdCard;
  checkObject: any = [];
  name;
  firstName
  lastName;
  patientId;
  patrefId;
  PatName;
  PatType;
  selectdate = new Date();
  selectdate1 = new Date();
  patientsearchtypes = [];
  patientType;
  patType;
  patientName: any;
  patientTypes: any = [{ id: '1', type: 'Aged Patient' },
  { id: '2', type: 'Call Assistance' },
  { id: '3', type: 'Wheel Chair Patient' },
  { id: '4', type: 'InPatient' },
  { id: '5', type: 'OutPatient' },
  { id: '6', type: 'Master Health Checkup' },
  { id: '7', type: 'BedSide' },
  { id: '8', type: 'International Patient' },
  ];
  refId;
  expandObj: any = [];
  close: boolean = false;
  maxName;
  maxToken;
  maxMobile;
  showToken: string = "";
  excelData: any = [];
  excelDataAll: any = [];
  excelDataAll1: any = [];
  allEntityData: any = [];
  showStart: string = "";
  refreshCheck: boolean;
  filterOpen: boolean = false;
  checkPoint = 0;
  status = 6;
  itemsDummy: any = [];
  statusArray: any = [
    { id: 1, name: "Pending" },
    { id: 2, name: "In Queue" },
    { id: 3, name: "Current" },
    { id: 4, name: "Completed/Skipped" },
    { id: 5, name: "Missed" },]
  currentName: any;
  languageCode: string;

  constructor(public data: DataService,
    public app: AppService, public pipe: SearchPipe,
    private translate: TranslateService,
    private excelService: ExcelService) {
    interval(25000).subscribe(() => {
      if (this.refreshCheck) {
        if (this.search1 == false) {
          this.searchFields()
        }
        else {
          this.ngOnInit();
        }

      }

    })

  }

  async ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.loader = true;
    var today = new Date();
    let x = JSON.parse(localStorage.getItem('config'));
    let a = x.find(res => res.shortCdConst == "MXSHFTBT");
    this.showToken = a.userValue;
    let b = x.find(res => res.shortCdConst == "PATNM");
    this.maxName = b.userValue;
    let c = x.find(res => res.shortCdConst == "PATTKNNO");
    this.maxToken = c.userValue;
    let d = x.find(res => res.shortCdConst == "PATMOBNO");
    this.maxMobile = d.userValue;
    let e = x.find(res => res.shortCdConst == "CMPLTWITHSTART");
    this.showStart = e.userValue;
    this.date1 = today.getFullYear() + '/' + ("0" + (today.getMonth() + 1)).slice(-2) + '/' + ("0" + today.getDate()).slice(-2) + ' ' + '00:00:00'
    this.date2 = today.getFullYear() + '/' + ("0" + (today.getMonth() + 1)).slice(-2) + '/' + ("0" + today.getDate()).slice(-2) + ' ' + '23:59:59'

    this.data.workFlowStats(null).subscribe(res => {
      if (res != null) {
        this.items = res;
        if (this.items.length > 0) {
          this.items = this.items.sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          })

          this.items.map(entity => {
            entity.checkpoints.map(res => {
              Object.assign(res, { clas: '' });
              if (res.status == 1) {
                res.clas = 'checkIn';
              }
              if (res.status == 2) {
                res.clas = 'queue';
              }
              if (res.status == 3) {
                res.clas = 'current';
              }
              if (res.status == 4) {
                res.clas = 'complete';
              }
              if (res.status == 5) {
                res.clas = 'missed';
              }
              if (res.status == 8) {
                res.clas = 'scheduled';
              }

            })
            let compcheck = entity.checkpoints.filter(res => { return res.completedTime })
            let quecheck = entity.checkpoints.filter(res => { return res.status == 2 && !res.completedTime })
            let pendcheck = entity.checkpoints.filter(res => { return !res.completedTime && res.status != 2 })
            // let checkpointName = entity.checkpoints.filter(res => { return res.status == 2 || res.status == 3 })
            // Object.assign(entity, { currentName: checkpointName[0].name});
            compcheck.sort((a, b) => { let dateA: any = new Date(a.completedTime), dateB: any = new Date(b.completedTime); return dateB.getTime() - dateA.getTime() });
            compcheck.reverse()
            entity.checkpoints = []
            let checkpoints1: any = compcheck.concat(quecheck)
            let checkpoints2: any = checkpoints1.concat(pendcheck)
            entity.checkpoints = checkpoints2;
          })
          this.itemsDummy = this.items;
          this.loader = false;
        }
      }

      else {
        this.loader = false;
        this.msgContent = "No Data To Show";
      }
    },
      err => {
        this.loader = false;
      })
    this.checkObject = await this.data.getCheckpoints().toPromise();
    this.checkObject = this.checkObject.sort((a, b) => {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    })
    this.userCall()
    this.getAllEntity();
  }
  matExp() {
    this.patType = "";
    this.PatName = "";
    this.patrefId = "";
    this.expand = !this.expand;
    this.search1 = false;
  }
  refresh() {
    if (this.search1 == false) {
      this.searchFields()
    }
    else {
      this.ngOnInit();
    }
  }
  clearFields() {
    this.name = "";
    this.refId = "";
    this.patientsearchtypes = [];
    this.patType = "";
    this.searchparam = {};
    this.expand = false;
    this.ngOnInit();
  }
  remove(i) {
    this.patientsearchtypes.splice(i, 1)
  }
  searchFields() {
    this.SearchgetAllEntity();
    this.search1 = false;
    this.expand = false;
    this.loader3 = true;
    this.patrefId = this.refId;
    this.PatName = this.name;
    this.searchparam = {
      name: this.name,
      patientRefId: this.refId
    }
    this.patientsearchtypes.map(res => {
      if (res.id == 1) {
        Object.assign(this.searchparam, { aged: true })
        this.patType = "Aged Patient"
      }
      if (res.id == 2) {
        Object.assign(this.searchparam, { callRequired: true })
        this.patType = "Call Assistance Patient"
      }
      if (res.id == 3) {
        Object.assign(this.searchparam, { wheeled: true })
        this.patType = "Wheel Chair Patient"
      }
      if (res.id == 4) {
        Object.assign(this.searchparam, { entityType: "2" })
        this.patType = "In Patient"
      }
      if (res.id == 5) {
        Object.assign(this.searchparam, { entityType: "1" })
        this.patType = "Out Patient"
      }
      if (res.id == 6) {
        Object.assign(this.searchparam, { entityType: "3" })
        this.patType = "MHC Patient"
      }
      if (res.id == 7) {
        Object.assign(this.searchparam, { bedSide: "true" })
        this.patType = "Bedside Patient"
      }
      if (res.id == 8) {
        Object.assign(this.searchparam, { internationalPatient: true })
        this.patType = "International Patient"
      }
    })
    this.date1 = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2) + ' ' + '00:00:00'
    this.date2 = this.selectdate1.getFullYear() + '/' + ("0" + (this.selectdate1.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate1.getDate()).slice(-2) + ' ' + '23:59:59';
    this.searchparam.fromDateStr = this.date1
    this.searchparam.toDateStr = this.date2
    this.data.workFlowStats(this.searchparam).toPromise().then(res => {
      this.items = res;
      this.items.map(entity => {
        let compcheck = entity.checkpoints.filter(res => { return res.completedTime })
        let quecheck = entity.checkpoints.filter(res => { return res.status == 2 && !res.completedTime })
        let pendcheck = entity.checkpoints.filter(res => { return !res.completedTime && res.status != 2 })
        compcheck.sort((a, b) => { let dateA: any = new Date(a.completedTime), dateB: any = new Date(b.completedTime); return dateB.getTime() - dateA.getTime() });
        compcheck.reverse()
        entity.checkpoints = []
        let checkpoints1: any = compcheck.concat(quecheck)
        let checkpoints2: any = checkpoints1.concat(pendcheck)
        entity.checkpoints = checkpoints2;
        entity.checkpoints.map(res => {
          Object.assign(res, { clas: '' });
          if (res.status == 1) {
            res.clas = 'checkIn';
          }
          if (res.status == 2) {
            res.clas = 'queue';
          }
          if (res.status == 3) {
            res.clas = 'current';
          }
          if (res.status == 4) {
            res.clas = 'complete';
          }
          if (res.status == 5) {
            res.clas = 'missed';
          }
          if (res.status == 8) {
            res.clas = 'scheduled';
          }
          if (res.status == 2 || res.status == 3) {
            this.currentName = res.name;
          }
        })
      })
      this.itemsDummy = this.items;
      this.loader3 = false;
    }).catch(
      err => {
        this.loader3 = false;
      }
    );
  }
  doRefresh(event) {
    setTimeout(() => {
      event.target.complete();
      if (this.search1 == false) {
        this.searchFields()
      }
      else {
        this.ngOnInit();
      }
    }, 500);
  }
  detectRightMouseClick(id, id1) {
    this.loader = true;
    this.data.adminMileStoneComplete(id, id1)
      .subscribe(res => {
        this.loader = false;
        this.entityId = "";
        this.selectId = "";
        if (this.search1 == false) {
          this.searchFields()
        }
        else {
          this.ngOnInit();
        }
      },
        err => {
          // this.app.openSnackBar('Something went wrong', 'error-snackbar')
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get("Something went wrong").subscribe((translation: string) => {
                this.app.openSnackBar(translation, "error-snackbar")
            })
        })
          this.loader = false;
        }
      )
  }
  start(id, id1) {
    this.loader = true;
    this.data.adminMileStoneStart(id, id1)
      .subscribe(res => {
        this.loader = false;
        this.entityId = "";
        this.selectId = "";
        if (this.search1 == false) {
          this.searchFields()
        }
        else {
          this.ngOnInit();
        }
        //  this.check();
      },
        err => {
          // this.app.openSnackBar('Something went wrong', 'error-snackbar')
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get("Something went wrong").subscribe((translation: string) => {
                this.app.openSnackBar(translation, "error-snackbar")
            })
        })
          this.loader = false;
        }
      )
  }
  mileStone(id, id1, name, status) {
    const mq = window.matchMedia("(min-width: 700px)")
    if (mq.matches) {
      this.app.openPage('workque/' + id1)
    }
    else {
      this.app.openPage('worque/' + id1)
    }
    // if (name == 'BREAKFAST' && status!=4) {
    //   if (this.selectId == id1) {
    //     this.entityId = "";
    //     this.selectId = "";
    //   }
    //   else {
    //     this.entityId = id;
    //     this.selectId = id1;
    //   }
    // }
    // else {
    //   this.entityId = "";
    //   this.selectId = "";
    // }
  }


  async getAllEntity() {
    var x = new Date();
    var y = this.returnDate(x)
    let fdate = y + ' ' + '00:00:00';
    let tdate = y + ' ' + '23:59:00';
    this.allEntityData = await this.data.adminExpandAll(fdate, tdate).toPromise();
    // if(this.allEntityData.checkpoints.status == 2 || this.allEntityData.checkpoints.status == 3){
    //   this.currentName = this.allEntityData.checkpoints.name;
    //   console.log(this.currentName)
    // }
    this.allEntityData.map(res => {
      res.checkpoints.map(res1 => {
        Object.assign(res1, { patientName: res.name, uhId: res.patientRefId, department: res.department, allReportsReadyTs: res.allReportsReadyTs })
        if (res.entryType == 1) {
          Object.assign(res1, { patientName: res.name, uhId: res.patientRefId, department: res.department, entrytype: 'Appointment', allReportsReadyTs: res.allReportsReadyTs })
        }
        if (res.entryType == 2) {
          Object.assign(res1, { patientName: res.name, uhId: res.patientRefId, department: res.department, entrytype: 'Walk-In', allReportsReadyTs: res.allReportsReadyTs })
        }
      })
      // if (res.checkpoints.status == 2 || res.checkpoints.status == 3) {
      //   this.currentName = res.checkpoints.name;
      // }
    })
  }

  async SearchgetAllEntity() {
    var x = this.returnDate(this.selectdate)
    var y = this.returnDate(this.selectdate1)
    let fdate = x + ' ' + '00:00:00';
    let tdate = y + ' ' + '23:59:00';
    this.allEntityData = await this.data.adminExpandAll(fdate, tdate).toPromise();
    this.allEntityData.map(res => {
      res.checkpoints.map(res1 => {
        Object.assign(res1, { patientName: res.name, uhId: res.patientRefId, department: res.department, allReportsReadyTs: res.allReportsReadyTs })
        if (res.entryType == 1) {
          Object.assign(res1, { patientName: res.name, uhId: res.patientRefId, department: res.department, entrytype: 'Appointment', allReportsReadyTs: res.allReportsReadyTs })
        }
        if (res.entryType == 2) {
          Object.assign(res1, { patientName: res.name, uhId: res.patientRefId, department: res.department, entrytype: 'Walk-In', allReportsReadyTs: res.allReportsReadyTs })
        }
      })

      // if (res.checkpoints.status == 2 || res.checkpoints.status == 3) {
      //   this.currentName = res.checkpoints.name;
      // }
    })
  }

  async exportAsXLSXALL() {
    console.log(this.allEntityData)
    this.allEntityData.map((res, i) => {
      let sno = i + 1
      Object.assign(res, { SNO: sno })
      res.checkpoints.map((res1) => {

        if (res1.entryTs) {
          res1.entryTs = this.setDate(res1.entryTs)
        } else {
          res1.entryTs = ""
        }

        if (res1.startTime) {
          res1.startTime = this.setDate(res1.startTime)
        } else {
          res1.startTime = ""
        }

        if (res1.completedTime) {
          res1.completedTime = this.setDate(res1.completedTime)
        } else {
          res1.completedTime = ""
        }

        if (res1.exitTs) {
          res1.exitTs = this.setDate(res1.exitTs)
        } else {
          res1.exitTs = ""
        }
        if (res1.reportsReadyTs) {
          res1.reportsReadyTs = this.convertLocalTime(res1.reportsReadyTs)
        } else {
          res1.reportsReadyTs = ""
        }
        if (res1.reportReadyMarkedBy) {
          this.patientName.map(dat => {
            if (res1.reportReadyMarkedBy == dat.userId) {
              Object.assign(res1, { reportname: dat.firstName })
            }
          })
        }
        if (res1.allReportsReadyTs) {
          res1.allReportsReadyTs = this.convertLocalTime(res1.allReportsReadyTs)
        } else {
          res1.allReportsReadyTs = ""
        }
        if (res1.reportCollectTs) {
          res1.reportCollectTs = this.convertLocalTime(res1.reportCollectTs)
        } else {
          res1.reportCollectTs = ""
        }
        this.excelDataAll.push({
          SNO: res.SNO, Name: res.name, UHID: res.patientRefId, Department: res.department, EntryType: res1.entrytype, Checkpoint: res1.name, QueueEntry: res1.entryTs,
          TestStart: res1.startTime, TestComplete: res1.completedTime, RoomExit: res1.exitTs, ReportsReadyTs: res1.reportsReadyTs, ReportReadyBy: res1.reportname,
          AllReportReady: res1.allReportsReadyTs, ReportCollectTs: res1.reportCollectTs
        })
      })
    })
    this.excelService.exportAsExcelFile(this.excelDataAll, 'Patient Details');
    this.excelDataAll = []
  }

  async exporNewAsXLSXALL() {
    console.log(this.allEntityData)
    this.allEntityData.map((res, i) => {
      let sno = i + 1
      Object.assign(res, { SNO: sno })
      res.checkpoints.map((res1) => {

        if (res1.entryTs) {
          res1.entryTs = this.setDateWithSec(res1.entryTs)
        } else {
          res1.entryTs = ""
        }

        if (res1.startTime) {
          res1.startTime = this.setDateWithSec(res1.startTime)
        } else {
          res1.startTime = ""
        }

        if (res1.completedTime) {
          res1.completedTime = this.setDateWithSec(res1.completedTime)
        } else {
          res1.completedTime = ""
        }

        if (res1.exitTs) {
          res1.exitTs = this.setDateWithSec(res1.exitTs)
        } else {
          res1.exitTs = ""
        }
        if (res1.reportsReadyTs) {
          res1.reportsReadyTs = this.convertLocalTime(res1.reportsReadyTs)
        } else {
          res1.reportsReadyTs = ""
        }
        if (res1.reportReadyMarkedBy) {
          this.patientName.map(dat => {
            if (res1.reportReadyMarkedBy == dat.userId) {
              Object.assign(res1, { reportname: dat.firstName })
            }
          })
        }
        if (res1.allReportsReadyTs) {
          res1.allReportsReadyTs = this.convertLocalTime(res1.allReportsReadyTs)
        } else {
          res1.allReportsReadyTs = ""
        }
        if (res1.reportCollectTs) {
          res1.reportCollectTs = this.convertLocalTime(res1.reportCollectTs)
        } else {
          res1.reportCollectTs = ""
        }
        this.excelDataAll.push({
          SNO: res.SNO, Name: res.name, UHID: res.patientRefId, Department: res.department, Checkpoint: res1.name, QueueEntry: res1.entryTs,
          TestStart: res1.startTime, TestComplete: res1.completedTime, RoomExit: res1.exitTs, QWaitingTime: res1.qWaitingTime,
          ServiceProcessedTime: res1.serviceProcessedTime, TotalTimeTaken: res1.totalTimeTaken, OverAllTAT: res.overAllTAT, EntryType: res1.entrytype, ReportsReadyTs: res1.reportsReadyTs, ReportReadyBy: res1.reportname,
          AllReportReady: res1.allReportsReadyTs, ReportCollectTs: res1.reportCollectTs
        })
      })
    })
    this.excelService.exportAsExcelFile(this.excelDataAll, 'Patient Details');
    this.excelDataAll = []
  }

  returnDate(data) {
    let date = data.getDate();
    date = date < 10 ? '0' + date : date;
    let month = data.getMonth() + 1;
    month = month < 10 ? '0' + month : month;
    let year = data.getFullYear();
    return year + '/' + month + '/' + date;
  }


  async expandCard(data) {
    this.excelData = [];
    this.excelData.push({ Name: data.name, UHID: data.patientRefId })
    this.loader2 = true;
    if (this.selectIdCard != data.id) {
      this.expandObj = await this.data.adminExpand(data.id).toPromise();
      this.expandObj.checkpoints.map(res => {
        Object.assign(res, { allReportsReadyTs: this.expandObj.allReportsReadyTs })
      })
      let compcheck = this.expandObj.checkpoints.filter(res => { return res.completedTime })
      let quecheck = this.expandObj.checkpoints.filter(res => { return res.status == 2 && !res.completedTime })
      let pendcheck = this.expandObj.checkpoints.filter(res => { return !res.completedTime && res.status != 2 })
      this.expandObj.checkpoints.map(res => {
        if (res.completedBy) {
          this.data.getRolesById(res.completedBy).subscribe((dat: any) => {
            if (dat) {
              Object.assign(res, { completename: dat.firstName })
            }
          })
        }
        if (res.forceStartedBy) {
          this.data.getRolesById(res.forceStartedBy).subscribe((dat: any) => {
            if (dat) {
              Object.assign(res, { forcename: dat.firstName })
            }
          })
        }
        if (res.markMissedBy) {
          this.data.getRolesById(res.markMissedBy).subscribe((dat: any) => {
            if (dat) {
              Object.assign(res, { markname: dat.firstName })
            }
          })
        }
        if (res.forceRemovedBy) {
          this.data.getRolesById(res.forceRemovedBy).subscribe((dat: any) => {
            if (dat) {
              Object.assign(res, { removename: dat.firstName })
            }
          })
        }
        if (res.reportReadyMarkedBy) {
          this.data.getRolesById(res.reportReadyMarkedBy).subscribe((dat: any) => {
            if (dat) {
              Object.assign(res, { reportname: dat.firstName })
            }
          })
        }

        // this.patientName.map(dat=>{
        //   if(res.completedBy == dat.userId){
        //    Object.assign(res,{completename:dat.firstName})
        //   }
        //   if(res.forceStartedBy == dat.userId){
        //     Object.assign(res,{forcename:dat.firstName})
        //   }
        //   if(res.markMissedBy == dat.userId){
        //     Object.assign(res,{markname:dat.firstName})
        //   }
        //   if(res.forceRemovedBy == dat.userId){
        //     Object.assign(res,{removename:dat.firstName})
        //   }
        //   if(res.reportReadyMarkedBy == dat.userId){
        //     Object.assign(res,{reportname:dat.firstName})
        //   }
        // })
      })
      compcheck.sort((a, b) => { let dateA: any = new Date(a.completedTime), dateB: any = new Date(b.completedTime); return dateB.getTime() - dateA.getTime() });
      compcheck.reverse()
      this.expandObj.checkpoints = []
      let checkpoints1: any = compcheck.concat(quecheck)
      let checkpoints2: any = checkpoints1.concat(pendcheck)
      this.expandObj.checkpoints = checkpoints2;
      this.expandObj.checkpoints.map(res => {
        Object.assign(res, { clas: '' });
        if (res.status == 1) {
          res.clas = 'checkIn';
        }
        if (res.status == 1) {
          res.clas = 'queue';
        }
        if (res.status == 3) {
          res.clas = 'current';
        }
        if (res.status == 4) {
          res.clas = 'complete';
        }
        if (res.status == 2) {
          res.clas = 'missed';
        }
        if (res.status == 5) {
          res.clas = 'skipped';
        }
        if (res.status == 8) {
          res.clas = 'scheduled';
        }
      })
      this.selectIdCard = data.id;
    }
    else {
      this.selectIdCard = "";
      this.loader2 = false;
    }
    setTimeout(() => {
      this.loader2 = false;
    }, 500);
  }
  switchPatientType() {
    let patienttype: any = []
    patienttype = this.patientTypes.filter(res => { return res.id == this.patientType })
    this.patientsearchtypes.push(patienttype[0])
    this.patientsearchtypes = this.patientsearchtypes.filter((x, i, a) => a.indexOf(x) == i);
  }

  async exportAsXLSX(data) {
    this.expandObj = await this.data.adminExpand(data.id).toPromise();
    this.expandObj.checkpoints.map(res => {
      Object.assign(res, { allReportsReadyTs: this.expandObj.allReportsReadyTs })
    })
    let compcheck = this.expandObj.checkpoints.filter(res => { return res.completedTime })
    let quecheck = this.expandObj.checkpoints.filter(res => { return res.status == 2 && !res.completedTime })
    let pendcheck = this.expandObj.checkpoints.filter(res => { return !res.completedTime && res.status != 2 })
    compcheck.sort((a, b) => { let dateA: any = new Date(a.completedTime), dateB: any = new Date(b.completedTime); return dateB.getTime() - dateA.getTime() });
    compcheck.reverse()
    this.expandObj.checkpoints = []
    let checkpoints1: any = compcheck.concat(quecheck)
    let checkpoints2: any = checkpoints1.concat(pendcheck)
    this.expandObj.checkpoints = checkpoints2;
    this.expandObj.checkpoints.map((res, i) => {
      let post: any = {};
      let sno = i + 1
      Object.assign(post, { SNO: sno })
      Object.assign(post, { TestName: res.name })
      if (res.startTime) {
        res.startTime = this.setDate(res.startTime)
        Object.assign(post, { StartTime: res.startTime })
      }
      else {
        Object.assign(post, { StartTime: "" })
      }
      if (res.entryTs) {
        res.entryTs = this.setDate(res.entryTs)
        Object.assign(post, { EntryTime: res.entryTs })
      }
      else {
        Object.assign(post, { EntryTime: "" })
      }
      if (res.exitTs) {
        res.exitTs = this.setDate(res.exitTs)
        Object.assign(post, { ExitTime: res.exitTs })
      }
      else {
        Object.assign(post, { ExitTime: "" })
      }
      if (this.expandObj.department) {
        Object.assign(post, { Department: this.expandObj.department })
      } else {
        Object.assign(post, { Department: "" })
      }
      if (this.expandObj.entryType == 1) {
        Object.assign(post, { EntryType: "Appointment" })
      }
      if (this.expandObj.entryType == 2) {
        Object.assign(post, { EntryType: "Walk-In" })
      }
      if (res.reportsReadyTs) {
        res.reportsReadyTs = this.convertLocalTime(res.reportsReadyTs)
        Object.assign(post, { ReportReadyTs: res.reportsReadyTs })
      }
      else {
        Object.assign(post, { ReportReadyTs: "" })
      }
      if (res.reportReadyMarkedBy) {
        this.patientName.map(dat => {
          if (res.reportReadyMarkedBy == dat.userId) {
            Object.assign(post, { ReportReadyBy: dat.firstName })
          }
        })
      }
      if (res.allReportsReadyTs) {
        res.allReportsReadyTs = this.convertLocalTime(res.allReportsReadyTs)
        Object.assign(post, { AllReportsReadyTs: res.allReportsReadyTs })
      }
      else {
        Object.assign(post, { allReportsReadyTs: "" })
      }
      if (res.reportCollectTs) {
        res.reportCollectTs = this.convertLocalTime(res.reportCollectTs)
        Object.assign(post, { ReportCollectTs: res.reportCollectTs })
      } else {
        Object.assign(post, { ReportCollectTs: "" })
      }
      this.excelData.push(post)
    })
    this.excelService.exportAsExcelFile(this.excelData, 'Patient Details');
  }
  async download(data) {
    this.excelData = [];
    this.excelData.push({ SNO: data.SNO, Name: data.name, UHID: data.patientRefId })
    this.exportAsXLSX(data);
  }
  // @HostListener('contextmenu', ['$event'])
  // onclick(event) {
  //   event.preventDefault();
  // }
  // @HostListener('click', ['$event'])
  // onclick1(event) {
  //   this.selectId = "";
  //   this.entityId = "";
  // }
  // @HostListener('tap', ['$event'])
  // onTap(e){
  //   if(e.tapCount===2){
  //   }
  // }
  hold(id, id1, name, status) {
    setTimeout(() => {
      this.mileStone(id, id1, name, status)

    }, 500);
  }
  async filter() {
    this.filterOpen = true;
    var x = new Date();
    var y = this.returnDate(x)
    let fdate = y + ' ' + '00:00:00';
    let tdate = y + ' ' + '23:59:00';
    this.allEntityData = await this.data.adminExpandAll(fdate, tdate).toPromise();
    this.allEntityData.map(res => {
      res.checkpoints.map(res1 => {
        Object.assign(res1, { allReportsReadyTs: res.allReportsReadyTs })
      })
    })
  }
  cancel() {
    this.filterOpen = false;
    this.status = 6;
    this.checkPoint = 0;
    if (this.expand == false) {
      this.searchFields()
    }
    else {
      this.data.workFlowStats(null).subscribe(res => {
        if (res != null) {
          this.items = res;
          if (this.items.length > 0) {
            this.items = this.items.sort((a, b) => {
              if (a.name < b.name) { return -1; }
              if (a.name > b.name) { return 1; }
              return 0;
            })

            this.items.map(entity => {

              entity.checkpoints.map(res => {
                Object.assign(res, { clas: '' });
                if (res.status == 1) {
                  res.clas = 'checkIn';
                }
                if (res.status == 2) {
                  res.clas = 'queue';
                }
                if (res.status == 3) {
                  res.clas = 'current';
                }
                if (res.status == 4) {
                  res.clas = 'complete';
                }
                if (res.status == 5) {
                  res.clas = 'missed';
                }
                if (res.status == 8) {
                  res.clas = 'scheduled';
                }
              })
              let compcheck = entity.checkpoints.filter(res => { return res.completedTime })
              let quecheck = entity.checkpoints.filter(res => { return res.status == 2 && !res.completedTime })
              let pendcheck = entity.checkpoints.filter(res => { return !res.completedTime && res.status != 2 })
              compcheck.sort((a, b) => { let dateA: any = new Date(a.completedTime), dateB: any = new Date(b.completedTime); return dateB.getTime() - dateA.getTime() });
              compcheck.reverse()
              entity.checkpoints = []
              let checkpoints1: any = compcheck.concat(quecheck)
              let checkpoints2: any = checkpoints1.concat(pendcheck)
              entity.checkpoints = checkpoints2;
            })
            this.loader = false;
          }
        }

        else {
          this.loader = false;
          this.msgContent = "No Data To Show";
        }
      },
        err => {
          this.loader = false;
        })
    }
  }
  filterSearch(data, status) {
    let x: any = [];
    if (status == 6 && data == 0) {
      this.filterOpen = false;
      this.ngOnInit()
    } else {
      this.itemsDummy.map(res => {
        res.checkpoints.map(res1 => {
          if (res1.name.includes(data) && res1.status == status) {
            x.push(res);
          }
          else if (res1.name.includes(data) && status == undefined || status == '') {
            x.push(res);
          } else if (res1.status == status && data == undefined) {
            if (x.length > 0)
              var a = x.find(dat => dat.id == res.id);
            if (!a)
              x.push(res);
          } else if (data == 0 && res1.status == status) {
            if (x.length > 0)
              var a = x.find(dat => dat.id == res.id);
            if (!a)
              x.push(res);
          } else if (res1.name.includes(data) && status == 6) {
            if (x.length > 0)
              var a = x.find(dat => dat.id == res.id);
            if (!a)
              x.push(res);
          }
        })
      });
      var fillterdata = [];
      this.items = x;
      this.items.map(dat1 => {
        this.allEntityData.map(dat2 => {
          if (dat1.patientRefId == dat2.patientRefId) {
            fillterdata.push(dat2)
          }
        })
      })
      this.allEntityData = fillterdata;
      this.allEntityData.map(dat4 => {
        dat4.checkpoints.map(dat5 => {
          Object.assign(dat5, { patientName: dat4.name, uhId: dat4.patientRefId, department: dat4.department })
          if (dat4.entryType == 1) {
            Object.assign(dat5, { patientName: dat4.name, uhId: dat4.patientRefId, department: dat4.department, entrytype: 'Appointment' })
          }
          if (dat4.entryType == 2) {
            Object.assign(dat5, { patientName: dat4.name, uhId: dat4.patientRefId, department: dat4.department, entrytype: 'Walk-In' })
          }
        })
      })
      console.log(this.allEntityData)
      this.filterOpen = false;
      if (this.items.length == 0) {
        this.msgContent = "No Data To Show";
      }
    }

  }
  setDate(date) {
    let x = new Date(date)
    let y = ("0" + (x.getDate())).slice(-2) + '/' + ("0" + (x.getMonth() + 1)).slice(-2) + '/' + x.getFullYear() + ' ' + ("0" + x.getHours()).slice(-2) + ':' + ("0" + x.getMinutes()).slice(-2);
    return y
  }

  setDateWithSec(date) {
    let x = new Date(date)
    console.log(x);
    let y = ("0" + (x.getDate())).slice(-2) + '/' + ("0" + (x.getMonth() + 1)).slice(-2) + '/' + x.getFullYear() + ' ' + ("0" + x.getHours()).slice(-2) + ':' + ("0" + x.getMinutes()).slice(-2) + ':' + ("0" + x.getSeconds()).slice(-2);
    return y
  }
  userCall() {
    var post = {
      "userType": 1
    }
    this.data.searchUser(post).subscribe(res => {
      if (res)
        this.patientName = res;
      // this.items = this.items.sort(function (a, b) {
      //   if (a.firstName < b.firstName) { return -1; }
      //   if (a.firstName > b.firstName) { return 1; }
      //   return 0;
      // })
    })
  }
  convertLocalTime(date) {
    var a
    var day
    a = new Date(date).toLocaleString();
    day = new Date(a)
    let y = ("0" + (day.getDate())).slice(-2) + '/' + ("0" + (day.getMonth() + 1)).slice(-2) + '/' + day.getFullYear() + ' ' + ("0" + day.getHours()).slice(-2) + ':' + ("0" + day.getMinutes()).slice(-2);
    return y;
  }
}

