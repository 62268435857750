import { Component, OnInit, HostListener } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { MatDialog } from '@angular/material';
import { BlockPopComponent } from '../block-pop/block-pop.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  selectedDocNameIds: number[] = [];
  datasets: any = [];
  databox1: any = [];
  show: boolean = false;
  loader: boolean = true;
  checkPoints;
  backgroundColors = ['#CBE4F9', '#CDF5F6', '#EFF9DA', '#F9EBDF', '#F9D8D6', '#D6CDEA'];
  doctorList;
  apptDate = new Date();
  cardHeader = [{ id: 1, name: 'Bar' }, { id: 2, name: 'Table' }, { id: 3, name: 'Line' }, { id: 4, name: 'Area' }];
  date = new Date();
  checkPan: boolean = false;
  idle = 0;
  public viewSum: boolean = false;
  org: any = {};
  date1;
  date2;
  selectdate = new Date();
  selectdate1 = new Date();
  minDate: any;
  cType: any;
  Ctype: any;
  languageCode: string;
  selectedId: null;
  appointMent: boolean;
  appointData: any;
  handler: any;
  docNameId: any;
  // activeLevelIndex: any;
  docNameName: any;
  docName: any;
  doctor: any;
  labels: any[];
  blockData: any[];
  blockSlots: any[];
  unBlockData: any[];
  trainerFetch: any;
  contractor: any;
  picload: boolean;
  timeSlots: any=[];
  doctorIds: number[] =[];
  selectAll: any;
  slots: any;
  activeLevelIndex = -1;
  duration: any;
  id: any;
  checkbox: any;
  appointments: any;
  newtimeSlot: any;
  // docName: any[] = [
  //   { isCheckboxSelected: false },
  //   { isCheckboxSelected: false },
  // ];
  constructor(public data: DataService, private translate: TranslateService, public app: AppService, public dialog: MatDialog) { }

  async ngOnInit() {
    this.selectdate = new Date();
    this.selectdate1 = new Date();
    this.languageCode = localStorage.getItem('lang');
    if (this.show) {
      this.myMetrics()
    }
    else {
      this.myCheckpoints()
    }
    this.getDoctorsList();
    this.org = await this.data.getOrgLogo().toPromise();
    localStorage.setItem('org', JSON.stringify(this.org))
    // this.data.getDoctorsList().subscribe(res=>{
    //   this.doctorList=res;
    //   console.log(this.doctorList);
    // })


  }


  changeChart(index, value) {
    this.datasets.dataSets[index].chartType = value;
  }


  openDialog(id): void {
    const dialogRef = this.dialog.open(BlockPopComponent, {
      data: id
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  async myCheckpoints() {
    this.show = false;
    var today = new Date();
    this.date1 = today.getFullYear() + '/' + ("0" + (today.getMonth() + 1)).slice(-2) + '/' + ("0" + today.getDate()).slice(-2) + ' ' + '00:00:00'
    this.date2 = today.getFullYear() + '/' + ("0" + (today.getMonth() + 1)).slice(-2) + '/' + ("0" + today.getDate()).slice(-2) + ' ' + '23:59:59'
    this.getCheckpoints()
  }
  ngDoCheck(): void {
    if (localStorage.getItem('capability')) {
      let data: any = JSON.parse(localStorage.getItem('capability'));
      if (data.length > 0) {
        data.map(res => {
          if (res.includes('VWWRKQSUM')) {
            this.viewSum = true;
          }
        })
      }

    }
  }
  async myMetrics() {
    this.app.fromDate = this.selectdate;
    this.app.toDate = this.selectdate1;
    this.date1 = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2) + ' ' + '00:00:00'
    this.date2 = this.selectdate1.getFullYear() + '/' + ("0" + (this.selectdate1.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate1.getDate()).slice(-2) + ' ' + '23:59:59'
    this.show = true;
    this.loader = true;
    this.datasets = await this.data.getDashboard().toPromise();
    if (this.datasets != null) {
      this.datasets.dataSets.map(res => {
        if (res.chartType == 1) {
          Object.assign(res, { charData: [], options: { title: '' }, chartName: 'ColumnChart' })
        }
        if (res.chartType == 2) {
          Object.assign(res, { charData: [], options: { title: '' }, chartName: 'Table' })
        }
        if (res.chartType == 3) {
          Object.assign(res, { charData: [], options: { title: '' }, chartName: 'LineChart' })
        }
        if (res.chartType == 4) {
          Object.assign(res, { charData: [], options: { title: '' }, chartName: 'AreaChart' })
        }
      });
      this.datasets.dataSets.map(res => {
        let post = { dataSetId: res.id };
        this.loader = true;
        this.data.getDatabox1(post, this.date1, this.date2).subscribe((value: any) => {
          res.charData.push(value.legends)
          res.charData = res.charData.concat(value.dataValues)
          res.options.title = res.name;
          Object.assign(res.options, {
            legend: { position: 'bottom' },
            vAxis: {
              gridlines: {
                color: 'transparent'
              }
            },
            hAxis: {
              gridlines: {
                color: 'transparent'
              }
            }
          })
        });
      })
      this.loader = false;
    }
    this.loader = false;
  }

  doRefresh(event) {
    setTimeout(() => {
      event.target.complete();
      this.ngOnInit();
    }, 500);
  }

  mobSearch() {
    let width = window.innerWidth
    if (width < 581) {
      this.dateSearch();
    }
  }

  clear() {
    this.ngOnInit();

  }

  fullScreen(i) {
    var docs = document.querySelectorAll('ion-card > div')[i];
    docs.requestFullscreen();
  }

  @HostListener('fullscreenchange', ['$event']) screenChange(e) {
    if (this.idle == 0) {
      this.idle = 1;
    }
    else {
      this.loader = true;
      setTimeout(() => {
        this.loader = false;
      }, 5);
      this.idle = 0;
    }
  }

  async deleteChart(id) {
    this.databox1 = await this.data.deleteChart(id).toPromise();
  }
  view2(id) {
    const mq = window.matchMedia("(min-width: 700px)")
    if (mq.matches) {
      this.app.openPage('workque/' + id)
    }
    else {
      this.app.openPage('worque/' + id)
    }
  }
  view(id) {
    const mq = window.matchMedia("(min-width: 500px)")
    if (mq.matches) {
      this.app.openPage('viewpatients/' + id)
    }
    else {
      this.app.openPage('viewmob/' + id)
    }
  }
  checked(id) {
    this.app.openPage('checkedin/' + id)
  }
  async getCheckpoints() {
    this.loader = true;
    this.databox1 = await this.data.getSummary(this.date1, this.date2).toPromise();

    if (this.databox1 != "") {
      this.databox1.sort(function (a, b) {
        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        if (nameA < nameB) { return -1; }
        if (nameA > nameB) { return 1; }
        return 0;
      })
    }
    this.cType = this.databox1.filter(res => { return res.cpType == 2 })

    this.databox1 = this.databox1.filter(res => { console.log(res.name, res.activeInd); return res.activeInd == true })
    if (this.cType) {
      this.getCheckpointId();
      this.generateTimeSlots();
      this.getAppoints();
    }


    this.loader = false;
  }
  async dateSearch() {
    this.app.fromDate = this.selectdate;
    this.app.toDate = this.selectdate1;
    this.date1 = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2) + ' ' + '00:00:00'
    this.date2 = this.selectdate1.getFullYear() + '/' + ("0" + (this.selectdate1.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate1.getDate()).slice(-2) + ' ' + '23:59:59'
    this.getCheckpoints()
  }


  cancel() {
    this.selectdate = new Date();
    this.selectdate1 = new Date();
    this.ngOnInit()
  }

  async updateTime(index, id, date) {
    this.databox1[index].shiftSpecs = date
    let hr = date.getHours();
    let min = date.getMinutes();
    if (hr < 10) {
      hr = '0' + hr;
    }
    if (min < 10) {
      min = '0' + min;
    }
    let cTime = hr + ':' + min
    this.checkPoints = await this.data.getCheckpointById(id).toPromise();
    if (this.checkPoints.id == id) {
      this.checkPoints.shiftSpecs.map(res => {
        res.startTime = cTime
      })
    }
    if (this.checkPoints) {
      this.data.updateCheckpointsById(id, this.checkPoints).toPromise().then((res) => {
        //  this.app.openSnackBar("Consulation Time Updated SucessFully",'success-snackbar')
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Consulation Time Updated SucessFully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
          })
        })
      })
        .catch(err => {
          // this.app.openSnackBar(err.error.msg,'error-snackbar')
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get(err.error.msg).subscribe((translation: string) => {
              this.app.openSnackBar(translation, "error-snackbar")
            })
          })
        })
    }
  }
  async getCheckpointId() {
    let date: Date = new Date();
    this.cType.map(res => {
      this.data.getCheckpointById(res.id).subscribe(res => {
        this.Ctype = res
        this.databox1.map(res2 => {
          if (res2.id == this.Ctype.id) {
            date.setHours(this.Ctype.shiftSpecs[0].startTime.slice(0, 2))
            date.setMinutes(this.Ctype.shiftSpecs[0].startTime.slice(3, 5))
            Object.assign(res2, { shiftSpecs: date })
          }
        })
      })
    })
  }
  async checkedAvl(data) {
    this.loader = true;
    this.checkPoints = await this.data.getCheckpointById(data.id).toPromise();
    if (this.checkPoints.id == data.id) {
      this.checkPoints.available = data.available
    }
    if (this.checkPoints) {
      this.data.updateCheckpointsById(data.id, this.checkPoints).toPromise().then((res) => {
        this.loader = false;
        this.myCheckpoints();
        //  this.app.openSnackBar("Consulation Time Updated SucessFully",'success-snackbar')
      })
        .catch(err => {
          this.loader = false;
          if (err.status == 500) {
            // this.app.openSnackBar("Internal Server Error",'error-snackbar')
            this.translate.use(this.languageCode).subscribe(() => {
              this.translate.get("Internal Server Error").subscribe((translation: string) => {
                this.app.openSnackBar(translation, "error-snackbar")
              })
            })
          }
          else {
            // this.app.openSnackBar(err.error.msg,'error-snackbar')
            this.translate.use(this.languageCode).subscribe(() => {
              this.translate.get(err.error.msg).subscribe((translation: string) => {
                this.app.openSnackBar(translation, "error-snackbar")
              })
            })
          }
        })
    }
  }

  // next date function
  next() {
    let x = new Date(JSON.parse(JSON.stringify(this.date)));
    let dummy = new Date(x.setDate(x.getDate() + 1));
    this.date = dummy;
    this.appointments = [];
    // this.search();
    // this.getAppoints();

  }

  // previous date function
  prev() {
    let x = new Date(JSON.parse(JSON.stringify(this.date)));
    let dummy = new Date(x.setDate(x.getDate() - 1));
    this.date = dummy;
    this.appointments = []
    // this.search();
    // this.getAppoints();

  }

  getAppoints() {
    this.picload = true;
    // let month = this.date.getMonth() + 1;
    // var fmon;
    // month < 10 ? (fmon = "0" + month) : (fmon = month);
    // let day = this.date.getDate();
    // var fday;
    // day < 10 ? (fday = "0" + day) : (fday = day);
    // let date = this.date.getFullYear() + "-" + fmon + "-" + fday;

    this.date1 = this.date.getFullYear() + '-' + ("0" + (this.date.getMonth() + 1)).slice(-2) + '-' + ("0" + this.date.getDate()).slice(-2)
    this.date2 = this.selectdate1.getFullYear() + '/' + ("0" + (this.selectdate1.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate1.getDate()).slice(-2) + ' ' + '23:59:59'
    let post = {
      // statuses: [2, 3, 6],

      apptDate: this.date1,
      // toapptDate: this.date1,
      // toDate: date,
      // trainerFetch: this.trainerFetch,
      orgId: localStorage.getItem("organizationId"),
      // fetchSpecificOrg: true,
      statuses: [1, 2, 4],
      doctorIds:this.doctorIds,
    };

    // if (this.selectedId != null) {
    // Object.assign(post, { doctorIds: this.docNameId });
    // }
    this.data.getAppointReq2(post).subscribe(
      (res: any) => {
        this.picload = false;
        if (res != null && res != "NO_CONTENT" && res.length > 0) {

          this.appointments = res;
          // this.docName.map(response => {
          //           if (response.id == res[0].doctorId) {
          //             Object.assign(res[0], {backgroundColor : response.backgroundColor})
          //           }
          //         })
          // console.log(res)
          // Assuming res contains your appointment objects and docName contains doctor objects
          for (const appointment of res) {
            const matchingDoctor = this.docName.find(doctor => doctor.id === appointment.doctorId);
            if (matchingDoctor) {
              appointment.backgroundColor = matchingDoctor.backgroundColor;
            }
          }

          console.log(res); // Check the appointments with updated backgroundColor properties
          this.appointments = res; // Assign the modified array to this.appointments if needed



          // let x: any = null;
          // this.labels.map((data, i) => {
          //   res.map((val) => {

          //     if (data.startTime == val.apptTime) {
          //       data.data.push(val);
          //       console.log(data.data)
          //       data.endTime = val.apptEndTime;
          //       x = val.apptEndTime;
          //       data.color = "highlight";

          //       this.docName.map(response => {
          //         if (response.id == data.data[0].doctorId) {
          //           Object.assign(data.data[0], {backgroundColor : response.backgroundColor})
          //         }
          //       })
          //       console.log(data.data)


          //     } else if (x != null && data.color != "highlight") {
          //       data.remove = false;
          //       if (data.endTime == val.apptEndTime) {
          //         x = null;
          //       }
          //     }
          //   });
          // });
          this.labels = this.labels.filter((res) => res.remove != true);
          this.id = this.data[0].id;
        }
        else {
          this.appointments = [];
          this.handler.res("No Appointments For You");
          this.picload = false;
        }
      },
      (err) => {
        this.handler.err(err);
        this.appointMent = false;
        this.picload = false;

      }
    );
  }
  getDoctorsList() {
    this.loader = true;
    this.data.getDoctorsList().subscribe((res: any) => {
      this.loader = false;
      this.docName = res;
      this.doctor = res.id
      this.docName.map((response, index) => {
        // if (response.id == dat.id) {
        Object.assign(response, { backgroundColor: '' })
        Object.assign(response, { isCheckboxSelected: false })
        response.backgroundColor = this.backgroundColors[index % this.backgroundColors.length]
        // }
      })
      console.log(this.docName)

      console.log(res)
    },
      (err) => {
        this.loader = false;
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err).subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
      }
    );
  }
  openleveldown(item, index) {
    if (!item.isCheckboxSelected) {
      this.docNameName = item.name;
      this.docNameId = item.id;
      
      if (!this.doctorIds.includes(this.docNameId)) {
        this.doctorIds.push(this.docNameId);
      }
    } else {
      const indexToRemove = this.doctorIds.indexOf(item.id);
      if (indexToRemove !== -1) {
        this.doctorIds.splice(indexToRemove, 1);
      }
    }
  
    this.activeLevelIndex = index;
    this.getAppoints();
  }
  

  triggerChange(data, value) {
    if (value == true) {
      data.clicked = !data.clicked;
    } else if (value == false) {
      data.clicked = data.clicked != null && (data.clicked == true) ? true : !data.clicked;
    }
  }
  select1(id) {
    this.docName.map(res => {
      if (res.id == id) {
        res.select = !res.select;
      }
    })
  }

  selectAllItems() {
    this.docName = this.docName.forEach(item => item.checkbox = this.selectAll);
    this.docName = this.docName.map(item => {
      return { ...item, checkbox: this.selectAll };
    });
  }

  // ngmodelchange for date picker function
  search() {
    this.picload = true;
    this.labels = [];
    this.blockData = [];
    this.blockSlots = [];
    this.unBlockData = [];
    let month = this.date.getMonth() + 1;
    var fmon;
    month < 10 ? (fmon = "0" + month) : (fmon = month);
    let day = this.date.getDate();
    var fday;
    day < 10 ? (fday = "0" + day) : (fday = day);
    let date = fday + "-" + fmon + "-" + this.date.getFullYear();
    let post = {
      schElements: [1],
      requestDate: date,
      status: ["SlotsAvailable"],
      trainerFetch: this.trainerFetch,
      targetCenterId: this.docNameId,
      targetSource: this.docNameId,
    };

    // if (this.contractor) {
    //   Object.assign(post, { targetCenterId:this.doctor});
    //   Object.assign(post, { targetSource:this.doctor });
    // }
    this.data.getCalendar(date, post).subscribe(
      (res: any) => {
        if (res != null && res != "NO_CONTENT") {
          this.labels = res;
          this.picload = false;
          this.labels.map((data: any) => {
            Object.assign(data, { color: "", data: [], remove: false });

          });

          setTimeout(() => {
            this.getAppoints();
          }, 800);
        } else {
          this.picload = false;
          this.handler.res("No data for you");
        }
      },
      (err) => {
        this.picload = false;
        this.handler.err(err);
      }
    );
  }
  showActions(value){
    this.app.openPage('patientEdit/' +value);
  }
  generateTimeSlots() {
    const startTime = 6 * 60; // 9:00 AM in minutes
    const endTime = 21 * 60; // 9:00 PM in minutes
    const interval = 60; // 60 minutes interval
  
    for (let i = startTime; i + interval <= endTime; i += interval) {
      const startHours = Math.floor(i / 60);
      const startMinutes = i % 60;
      
      const middleHours = Math.floor((i + interval / 2) / 60);
      const middleMinutes = (i + interval / 2) % 60;
      
      const endHours = Math.floor((i + interval) / 60);
      const endMinutes = (i + interval) % 60;
      
      const timeSlot = {
        startTime: this.formatTime(startHours, startMinutes),
        middleTime: this.formatTime(middleHours, middleMinutes),
        endTime: this.formatTime(endHours, endMinutes)
      };
      
      this.timeSlots.push(timeSlot);
    }
    
    console.log(this.timeSlots);
  }
  
  formatTime(hours: number, minutes: number): string {
    const formattedHours = hours < 10 ? `0${hours}` : hours.toString();
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
    return `${formattedHours}:${formattedMinutes}`;
  }

}

