import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { ExcelService } from 'src/api-calls/excel.service';
import { MatTableDataSource, MatSort, matTabsAnimations, MatTable } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
let k=1,j=90;
@Component({
  selector: 'app-admin-patient-search',
  templateUrl: './admin-patient-search.component.html',
  styleUrls: ['./admin-patient-search.component.scss']
})
export class AdminPatientSearchComponent implements OnInit {
  displayedColumns = ['sno','patientRefId', 'name', 'receiptNbr', 'Entity Type', 'checkInTs', 'contactNbr', 'metadata', 'Actions'];
  dataSource: any;
  sort;
  usergetId;
  languageCode: string;
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  Cview: boolean = true;
  firstName;
  lastName;
  patientId;
  mobileNo;
  appConfig: any;
  maxName;
  maxToken;
  maxMobile;
  showToken: string = "";
  countrynum = '+91';
  changeView: boolean = false;
  items: any = [];
  searchparam: any = {};
  s1;
  msgContent: string = "";
  nodata: boolean = false;
  public updent: boolean = false;
  selectdate = new Date();
  selectdate1 = new Date();
  date1;
  date2;
  patientType;
  orgId=localStorage.getItem('organizationId');
  patientTypes: any = [{ id: '1', type: 'Aged Patient' },
  { id: '2', type: 'Call Assistance' },
  { id: '3', type: 'Wheel Chair Patient' },
  { id: '4', type: 'InPatient' },
  { id: '5', type: 'OutPatient' },
  { id: '6', type: 'Master Health Checkup' },
  { id: '7', type: 'BedSide' },
  { id: '8', type: 'International Patient' },
  ];
  refId;
  patientsearchtypes = [];
  checkInTs;
  excelData: any = []
  switch:boolean;
  role: any =[]
  getId;
  constructor(public data: DataService, public app: AppService, private translate: TranslateService, private excelService: ExcelService) { }

  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    var today = new Date();
    this.date1 = today.getFullYear() + '/' + ("0" + (today.getMonth() + 1)).slice(-2) + '/' + ("0" + today.getDate()).slice(-2) + ' ' + '00:00:00'
    this.date2 = today.getFullYear() + '/' + ("0" + (today.getMonth() + 1)).slice(-2) + '/' + ("0" + today.getDate()).slice(-2) + ' ' + '23:59:59'
    this.patientSearch()
    this.getRole()

    let x = JSON.parse(localStorage.getItem('config'));
    let a = x.find(res => res.shortCdConst == "MXSHFTBT");
    this.showToken = a.userValue;
    let b = x.find(res => res.shortCdConst == "PATNM");
    this.maxName = b.userValue;
    let c = x.find(res => res.shortCdConst == "PATTKNNO");
    this.maxToken = c.userValue;
    let d = x.find(res => res.shortCdConst == "PATMOBNO");
    this.maxMobile = d.userValue;
    let r =  x.find(res => res.shortCdConst == "PATVICON");
    if(r)
    this.switch = JSON.parse(r.userValue)
  }


  viewChange(id) {
    if (id == 1) { this.Cview = true; }
    else { this.Cview = false; }
  }
  ngDoCheck(): void {
    if (localStorage.getItem('capability')) {
      let data: any = JSON.parse(localStorage.getItem('capability'));
      if (data.length > 0) {
        data.map(res => {
          if (res.includes('UPDENT')) {
            this.updent = true;
          }
        })
      }

    }
  }
  patientSearch(){
    k=1;
    this.data.loader = true;
    this.data.userSearchByPage(this.orgId,k).subscribe(res=>{
      if(res !=null){
        this.items = res;
        this.dataSource = new MatTableDataSource(this.items)
        if (this.items.length > 0) {
          this.items = this.items.sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          })
        }
        this.data.loader = false;
        k++;
      }else{
        this.data.loader =false;
        this.msgContent = "No Data To Show";
      }
    })
  }
  clear() {
    this.firstName = "";
    this.lastName = "";
    this.patientId = "";
    this.mobileNo = "";
    this.refId = "";
    this.ngOnInit();
    this.selectdate = new Date();
    this.selectdate1 = new Date();
    k=1;
  }
  // search() {
  //   this.data.loader = true;
  //   this.searchparam = {
  //     "name": this.firstName,
  //     "tokenNbr": this.patientId,
  //     "patientRefId": this.refId,
  //     "contactNbr": this.mobileNo,
  //   };
  //   this.patientsearchtypes.map(res => {
  //     if (res.id == 1) {
  //       Object.assign(this.searchparam, { aged: true })
  //     }
  //     if (res.id == 2) {
  //       Object.assign(this.searchparam, { callRequired: true })
  //     }
  //     if (res.id == 3) {
  //       Object.assign(this.searchparam, { wheeled: true })
  //     }
  //     if (res.id == 4) {
  //       Object.assign(this.searchparam, { entityType: "2" })
  //     }
  //     if (res.id == 5) {
  //       Object.assign(this.searchparam, { entityType: "1" })
  //     }
  //     if (res.id == 6) {
  //       Object.assign(this.searchparam, { entityType: "3" })
  //     }
  //     if (res.id == 7) {
  //       Object.assign(this.searchparam, { bedSide: "true" })
  //     }
  //     if (res.id == 8) {
  //       Object.assign(this.searchparam, { internationalPatient: true })
  //     }
  //   })
  //   if(this.switch){
  //     Object.assign(this.searchparam,{roleId:this.getId})
  //   }
  //   this.date1 = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2) + ' ' + '00:00:00'
  //   this.date2 = this.selectdate1.getFullYear() + '/' + ("0" + (this.selectdate1.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate1.getDate()).slice(-2) + ' ' + '23:59:59'
  //   this.data.searchPatientAdmin(this.searchparam, this.date1, this.date2).toPromise().then(res => {

  //     this.items = res;
  //     this.dataSource = res;
  //     this.data.loader = false;
  //   }).catch(
  //     err => {
  //       this.data.loader = false;
  //     }
  //   );

  // }

  view(item) {
    this.app.openPage('viewentity/' + item.id)
    sessionStorage.setItem('name', item.name)
    sessionStorage.setItem('patientRefId', item.patientRefId)
  }
  cancel() {
    // this.app.openPage('patientsCheckIn');
    this.firstName = "";
    this.refId = "";
    this.mobileNo = "";
  }
  switchPatientType() {
    let patienttype: any = []
    patienttype = this.patientTypes.filter(res => { return res.id == this.patientType })
    this.patientsearchtypes.push(patienttype[0])
    this.patientsearchtypes = this.patientsearchtypes.filter((x, i, a) => a.indexOf(x) == i);
  }
  remove(i) {
    this.patientsearchtypes.splice(i, 1)
  }
  exportAsXLSX(): void {
    this.items.map((data4,i)=>{
      let sno = i+1
      Object.assign(data4,{SNO:sno})
})
    this.items.map(res => {
      this.excelData.push({
       SNO:res.SNO, UHID: res.patientRefId, PatientName: res.firstName, ReceiptNo: res.receiptNbr, EntityType: res.entityType, Mobile: res.contactNbrMob,
         CheckInTime: res.checkInTs, CompletedTime: res.completedTs
      })
    })
    this.excelService.exportAsExcelFile(this.excelData, 'Patient Details');
    this.excelData=[];
  }

  patientByPage($event){
    this.data.userSearchByPage(this.orgId,k).subscribe((res:any)=>{
      res.map(dat=>{
        this.items.push(dat);
      
        this.dataSource= new MatTableDataSource(this.items);
       
      })
      this.data.loader = false;
      k++;
    })
  }
  
  edit(data){
    this.app.openPage('manageuser-edit/' + data.userId);
  }
  search(){
    var post ={
      "firstName":this.firstName,
      "patientRefId": this.refId,
    }
    if(this.mobileNo)
    Object.assign(post,{contactNbrMob:this.countrynum + this.mobileNo})
    this.data.patientSearch(post).subscribe(res=>{
      this.items = res;
      this.dataSource = res;
    })
  }
  convertRole(data){
    this.data.loader=true;
    this.data.getRolesById(data.userId).subscribe(res=>{
      this.updateRole(res)
    })
  }
  updateRole(data1){
    data1.userType = 1 
     this.data.checkpointSave(data1.userId,data1).subscribe(res=>{
       },
       err => {
         this.data.loader=false;
        //  this.app.openSnackBar('Error !!', 'error-snackbar')
         this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Error").subscribe((translation: string) => {
              this.app.openSnackBar(translation, "error-snackbar")
          })
      })
       })
       let post1 : any = [];
       let post: any = {roleId: this.getId};
       post1.push(post)
       this.data.rolesApprove(data1.userId,post1).subscribe(res =>{
        // this.app.openSnackBar('Role Converted Succesfully  !!', 'success-snackbar');
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Role Converted Succesfully").subscribe((translation: string) => {
              this.app.openSnackBar(translation, "success-snackbar")
          })
      })
        this.ngOnInit();
       },
       err => {
         this.data.loader= false;
        //  this.app.openSnackBar('Error !!', 'error-snackbar')
         this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Error").subscribe((translation: string) => {
              this.app.openSnackBar(translation, "error-snackbar")
          })
      })
       })
  }
  getRole(){
    this.data.getRoles().subscribe(res=>{
      this.role=res;
      
      let x = this.role.find(res=> res.code == 'A')
      this.getId= x.roleId
      this.dataSource = new MatTableDataSource(this.role);

    })
  }
}
