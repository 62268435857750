import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { HandlerService } from "src/api-calls/handler.service";
import { AuthService } from "src/api-calls/auth.service";
import { MatDialog } from "@angular/material/dialog";
import { BlocktimerComponent, timer } from "../blocktimer/blocktimer.component";
import { BlockEndComponent, timer1 } from "../block-end/block-end.component";
import { AppComponent } from "../app.component";
import { DataService } from 'src/api-calls/data.service';
import { database } from "firebase";
let i = 0;
let j = 0;
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {
  date = new Date();
  times: any = [];
  hr;
  min = 0;
  morEve = true;
  maxhr;
  minhr;
  i = 0;
  check: boolean = false;
  blockCheck: boolean = false;
  blockShow: boolean = false;
  unBlock: boolean = false;
  unBlockcom: boolean = false;
  comments: any = "";
  labels: any = [];
  blockData: any = [];
  unBlockData: any = [];
  font;
  blockStartTime;
  blockEndTime;
  blockSlots: any = [];
  loader: boolean = false;
  switch:boolean;
  msg;
  dateFormat;
  header = "CALENDAR";
  trainerFetch: boolean = true;
  serviceContractorId;
  contractor: string;
  url;
  testResult;
  getObject: any = {};
  rejectOrderpop: boolean = false;
  rejectReason: Object;
  cancellation: any;
  text: any;
  rejectRequest;
  rejectPolicy;
  RequestId: any;
  rejectCount;
  rejectAvailabe: any;
  loader1: boolean = false;
  popUpDetails: boolean = false;
  viewList: boolean = false;
  cancelmsg: boolean;
  cancelId;
  packageInd;
  cancelRequest;
  list: any = [];
  policy: boolean = false;
  notes;
  driveTest: boolean;
  actDuration;
  completeReqId;
  completeReqServiceId;
  completePop: boolean;
  testpass: boolean;
  countopen: boolean = false;
  browsingTime: any;
  testcount;
  instructorNotes;
  seasons = ["0", "1", "3", "5"];
  pass = ["Pass"];
  fail = ["Fail"];
  CustomerNoShow: boolean = false;
  cancelReasons: any;
  cancelReasonsId: any;
  cancelReason: Object;
  picload: boolean;
  id: any;
  blockdata: any;
  appointmentNbr;
  slotApptNbr: any;
  opening: boolean;
  resheduleId: any;
  resheduleItem: any;
  reseduledate: any;
  postTime: any;
  time: string;
  slots: any[];
  show: boolean;
  doctUserId: string;
  duration = 60;
  nullCheck: boolean;
  resheduledate: any;
  resheduled: any;
  confirmed: any;
  completed: any;
  appointmentDetails: any;
  selectedValue: any;
  currentDate: any;
  patientId: any;
  selected: any;

  constructor(
    public service: DataService,
    public handler: HandlerService,
    public router: Router,
    public app: AppComponent,
    public dialog: MatDialog,
    public auth: AuthService,
  ) { }

  ngOnInit() {
    this.contractor = localStorage.getItem("contractor");
    this.font = "normal";
    this.dateFormat = this.auth.getDateformat();
    let a = localStorage.getItem("fontSizeId");
    if (a == "1") {
      this.font = "normal";
    }
    if (a == "2") {
      this.font = "medium";
    }
    if (a == "3") {
      this.font = "large";
    }
    this.search();
    // this.app.setTitle("DDS4U-" + this.header);
    let x = JSON.parse(localStorage.getItem('config'));
    let r = x.find(res => res.shortCdConst == "BAC");
    if (r)
      this.switch = JSON.parse(r.userValue)
  }


  // ngmodelchange for date picker function
  search() {
    this.loader = true;
    this.labels = [];
    this.blockData = [];
    this.blockSlots = [];
    this.unBlockData = [];
    let month = this.date.getMonth() + 1;
    var fmon;
    month < 10 ? (fmon = "0" + month) : (fmon = month);
    let day = this.date.getDate();
    var fday;
    day < 10 ? (fday = "0" + day) : (fday = day);
    let date = fday + "-" + fmon + "-" + this.date.getFullYear();
    let post = {
      schElements: [1],
      requestDate: date,
      status: ["SlotsAvailable"],
      trainerFetch: this.trainerFetch,
    };

    if (this.contractor) {
      Object.assign(post, { targetCenterId: this.contractor });
      Object.assign(post, { targetSource: this.contractor });
    }
    this.service.getCalendar(date, post).subscribe(
      (res: any) => {
        if (res != null && res != "NO_CONTENT") {
          this.labels = res;
          this.labels.map((data: any) => {
            Object.assign(data, { color: "", data: [], remove: false });
          });
          setTimeout(() => {
            this.getAppoints();
          }, 800);
        } else {
          this.loader = false;
          this.handler.res("No data for you");
        }
      },
      (err) => {
        this.loader = false;
        this.handler.err(err);
      }
    );
  }


  // get all appointment function for this doctor
  getAppoints() {
    let month = this.date.getMonth() + 1;
    var fmon;
    month < 10 ? (fmon = "0" + month) : (fmon = month);
    let day = this.date.getDate();
    var fday;
    day < 10 ? (fday = "0" + day) : (fday = day);
    let date = this.date.getFullYear() + "-" + fmon + "-" + fday;
    let post = {
      // statuses: [2, 3, 6],
      apptDate: date,
      // toDate: date,
      // trainerFetch: this.trainerFetch,
      orgId: localStorage.getItem("organizationId"),
      // fetchSpecificOrg: true,
    };

    if (this.contractor != null) {
      Object.assign(post, { doctorId: this.contractor });
    }
    this.service.getAppointReq2(post).subscribe(
      (res: any) => {
        if (res != null && res != "NO_CONTENT" && res.length > 0) {
          let x: any = null;
          this.labels.map((data, i) => {
            res.map((val) => {
              if (data.startTime == val.apptTime) {
                data.data.push(val);
                data.endTime = val.apptEndTime;
                x = val.apptEndTime;
                data.color = "highlight";
              } else if (x != null && data.color != "highlight") {
                data.remove = false;
                if (data.endTime == val.apptEndTime) {
                  x = null;
                }
              }
            });
          });
          this.labels = this.labels.filter((res) => res.remove != true);
        }
        else {
          this.handler.res("No Appointments For You");
        }
      },
      (err) => {
        this.handler.err(err);
        this.loader = false;
      }
    );
    this.getBlocks();
  }

  // get all blocks appointment function
  getBlocks() {
    let month = this.date.getMonth() + 1;
    var fmon;
    month < 10 ? (fmon = "0" + month) : (fmon = month);
    let day = this.date.getDate();
    var fday;
    day < 10 ? (fday = "0" + day) : (fday = day);
    let date = this.date.getFullYear() + "-" + fmon + "-" + fday;
    let doctorId = this.contractor;
    this.service.getBlocks(doctorId, date).subscribe(
      (res: any) => {
        if (res != null) {
          let x: any = null;
          this.labels.map((data: any) => {
            res.map((val) => {
              if (data.startTime == val.apptStartTime) {
                data.data.push(val);
                data.endTime = val.apptEndTime;
                x = val.apptEndTime;
                data.color = "block";
              } else if (x != null && data.color != "block") {
                data.remove = true;
                if (data.endTime == val.apptEndTime) {
                  x = null;
                }
              }
            });
            // res.map((val: any) => {
            //   if (data.startTime == val.apptStartTime) {
            //     data.data.push(val);
            //     data.color = "block";
            //   }
            // });
          });
          this.labels = this.labels.filter((res) => res.remove != true);

        }
        this.loader = false;
      },
      (err) => {
        this.loader = false;
        this.handler.err(err);
      }
    );
  }

  // next date function
  next() {
    let x = new Date(JSON.parse(JSON.stringify(this.date)));
    let dummy = new Date(x.setDate(x.getDate() + 1));
    this.date = dummy;
    this.search();
  }

  // previous date function
  prev() {
    let x = new Date(JSON.parse(JSON.stringify(this.date)));
    let dummy = new Date(x.setDate(x.getDate() - 1));
    this.date = dummy;
    this.search();
  }

  // open appointment time slots function
  open(id, item) {
    j = id;
    this.blockStartTime = item.startTime;
    let x = this.labels.find((res) => res.color == "newblock");
    // let y = this.labels.map((res) => (res.data[0]));
    // let z = y[j]
    this.labels.map((res, i) => {
      if (i == id) {
        if (res.show) {
          res.show = false;
        }
        // else if (res.color == "block") {
        //   this.unBlockcom = true;
        // }
        else if (res.color == "highlight") {
          res.show = true;
          this.unBlockcom = false;
        } else {
          this.unBlockcom = false;
          res.show = false;
        }
      }
      else {
        res.show = false;
      }
    });
    let len = item.data.length;
    if (len == 0) {
      if (!this.unBlock) {
        if (item.color == "newblock") {
          item.color = "";
          this.blockData = this.blockData.filter((res) => res != item);
        } else {
          if (!x) {
            this.blockShow = true;
            item.color = "newblock";
            this.blockData.push(item);
          } else {
            this.labels.map((res) => {
              if (res.color == "newblock") res.color = "";
              this.blockData = [];
              this.blockData.push(item);
            });
            item.color = "newblock";
          }
          this.blockShow = false;
        }
        if (this.blockData.length > 0) {
          this.blockShow = true;
        } else {
          this.blockShow = false;
        }
      }
    }
    if (len == 1 && item.color != "highlight") {
      this.slotApptNbr = item.data[0].appointmentNbr;
      if (!this.blockShow) {
        if (item.color == "block") {
          item.color = "unblock";
          this.unBlockData.push(item);
          this.unBlock = true;
        } else {
          item.color = "block";
          let blen = this.unBlockData.length;
          if (blen > 0)
            this.unBlockData = this.unBlockData.filter((res) => res != item);
        }
        if (this.unBlockData.length > 0) {
          this.unBlock = true;
        } else {
          this.unBlock = false;
        }
      }
    }
  }

  // blocking appointment button function
  blockCall() {
    if (this.blockStartTime != this.blockEndTime) {
      this.loader = true;
      this.blockCheck = false;
      this.blockShow = true;
      this.labels.map((res) => {
        if (
          res.startTime >= this.blockStartTime &&
          res.endTime <= this.blockEndTime
        )
          this.blockSlots.push(res);
      });
      let blen = this.blockSlots.length - 1;
      let month = this.date.getMonth() + 1;
      var fmon;
      month < 10 ? (fmon = "0" + month) : (fmon = month);
      let day = this.date.getDate();
      var fday;
      day < 10 ? (fday = "0" + day) : (fday = day);
      let date = this.date.getFullYear() + "-" + fmon + "-" + fday;
      this.blockSlots.map((res) => {
        this.id = res.capDefId
      });
      let post = {
        targetCenterId: localStorage.getItem("organizationId"),
        apptDate: date,
        apptStartTime: this.blockStartTime,
        apptEndTime: this.blockEndTime,
        capacityDefId: this.id,
        centerId: localStorage.getItem("organizationId"),
        apptStatusId: 1,
        comments: this.comments,
        type: 2,
        trainerFetch: this.trainerFetch,
      };
      if (this.contractor) {
        Object.assign(post, { targetSource: this.contractor });
      }
      this.service.postAppointments(post).subscribe(
        (res) => {
          if (res) {
            this.comments = "";
            this.handler.res("Blocked Successfully");
            this.blockSlots = [];
            this.blockStartTime = undefined;
            this.blockEndTime = undefined;
            this.handler.i = 0;
            this.handler.j = 0;
            this.loader = false;
            this.blockCheck = false;
            this.handler.check = false;
            this.blockShow = false;
            this.search();
          }
        },
        (err) => {
          this.comments = "";
          this.blockCheck = false;
          this.blockShow = false;
          this.blockStartTime = undefined;
          this.blockEndTime = undefined;
          this.handler.i = 0;
          this.handler.j = 0;
          this.handler.check = false;
          this.handler.err(err);
          this.loader = false;
        }
      );
      // });
    } else {
      this.handler.error(
        "Please Select Appropriate Start Time and End Time For Blocking Time Slots"
      );
    }
  }

  // unblocking appointment button function
  unBlockCall() {
    let len = this.unBlockData.length - 1;
    this.loader = true;
    this.service
      .unBlockSlot(this.unBlockData[i].data[0].appointmentNbr)
      .subscribe(
        (res) => {
          if (i < len) {
            i++;
            this.unBlockCall();
          } else {
            i = 0;
            this.handler.res("Slot Unblocked Successfully");
            this.loader = false;
            this.unBlock = false;
            // this.unBlockcom = false;
            this.ngOnInit();
          }
        },
        (err) => {
          this.handler.err(err);
          this.loader = false;
          // this.unBlock = false;
          // this.unBlockcom = false;
        }
      );
  }

  // blocking start time click function
  openModel(id): void {
    let data = [];
    for (let i = j; i < this.labels.length; i++) {
      if (
        this.labels[i].color != "highlight" &&
        this.labels[i].color != "block"
      ) {
        data.push(this.labels[i]);
      } else {
        break;
      }
    }
    const dialogRef = this.dialog.open(BlocktimerComponent, {
      width: "100%",
      data: data,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res == 2) {
        this.blockStartTime = timer;
      }
    });
  }

// blocking end time click function
  openModel1(): void {
    let data = [];
    for (let i = j; i < this.labels.length; i++) {
      if (
        this.labels[i].color != "highlight" &&
        this.labels[i].color != "block"
      ) {
        data.push(this.labels[i]);
      } else {
        break;
      }
    }
    const dialogRef = this.dialog.open(BlockEndComponent, {
      width: "100%",
      data: data,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res == 2) {
        this.blockEndTime = timer1;
      }
    });
  }

  // cancel blocking function cancel button
  cancel() {
    this.blockCheck = false;
    this.blockEndTime = undefined;
    this.blockStartTime = undefined;
    this.handler.i = 0;
    this.handler.j = 0;
    this.handler.check = false;
  }

  // go to manage calendar page function
  cog() {
    if (this.contractor !== null) {
      localStorage.setItem("contractor", this.contractor);
    }
    this.router.navigate(["/calendarmanage"]);
  }

  // leave this page function
  ngOnDestroy() {
    // sessionStorage.removeItem("contractor");
    this.url = window.location.href;
    // if (!this.url.includes("calendar")) localStorage.removeItem("contractor");
  }


  // card arrow icon click function
  details(data) {
    this.popUpDetails = true;
    this.getObject = {};
    let post= {
      apptNbr: data.apptNbr,
      orgId: data.orgId,
    }
    this.service.getAppointReq2(post).subscribe(
      (res: any) => {
        this.getObject = res;
      })
  }

  // this function is used for set time function
  setTime(data, indx) {
    this.time = data.startTime;
    this.postTime = data;
    this.slots.map((res, i) => {
      indx == i
    });
    setTimeout(() => {
      this.show = false;
    }, 200);

  }

  // close patient appointment data popup function
  popUpClose() {
    this.popUpDetails = false;
  }


  // card click function
  // showActions(value) {
  //   this.viewList = !this.viewList
  //   this.selectedValue = value;
  // }

  showActions(id) {
    if (this.selected == id) {
      this.selected = '';
    } else {

      this.selected = id;
    }
  }
  
  // cancel button function
  cancelReson(id, item) {
    this.cancelId = id;
    this.cancelmsg = true;
    this.cancelRequest = item;
  }

  // cancelation popup closing function
  clearPop() {
    this.cancelmsg = false;
    this.notes = "";
  }

  // appointment cancel submittion function
  cancelReq() {
    this.cancelmsg = false;
    this.loader = true;
    var x = {
      cancellationNotes: this.notes,
    };
    this.service.appointmentcancel(this.cancelId, x).subscribe(
      (res: any) => {
        this.handler.res("Cancelled successfully");
        this.notes = "";
        this.ngOnInit();
      },
      (err) => {
        this.loader = false;
        this.handler.err(err);
      }
    );
  }

// resedule click function
  reshedules(id, item) {
    this.time = "time";
    console.log(id)
    this.opening = true;
    this.resheduleId = id;
    this.resheduleItem = item;
  }

  // date picker input clear time function
  clearTime() {
    this.time = "Time";
    this.slots = [];
    this.show = false;
    this.checkPreview();
    this.postTime = "";
  }

  checkPreview() {
    this.doctUserId = localStorage.getItem('contractor')
    this.getSlots(this.doctUserId);
  }
 
  // get time slots function
  getSlots(Id) {
    let x = this.resheduledate.getDate();
    let y = this.resheduledate.getMonth() + 1;
    let z = this.resheduledate.getFullYear();
    let pass = x + "-" + y + "-" + z;
    let post = {
      targetCenterId: Id,
      targetSource: Id,
      schElements: [1],
      slotDuration: this.duration,
      requestDate: pass,
      status: ["SlotsAvailable"],
      independentEntities: true,
    };
    this.show = true;
    this.service.getAppointmentsTime(pass, post).subscribe(
      (res: any) => {
        if (res == null) {
          this.nullCheck = true;
        } else {
          this.nullCheck = false;
          this.slots = res;
          this.slots.map((sum) => Object.assign(sum, { style: "none" }));
        }
      },
      (err) => {
         this.handler.err(err);
      }
    );
  }

  // resedule cancel function
  resedulecancel(){
    this.opening = false;
  }


  // resedule input data submition function
  reseduleSubmit(){
    this.doctUserId = localStorage.getItem('contractor')
    this.opening = false;
    let month = this.resheduledate.getMonth() + 1;
    var fmon;
    month < 10 ? (fmon = "0" + month) : (fmon = month);
    let day = this.resheduledate.getDate();
    var fday;
    day < 10 ? (fday = "0" + day) : (fday = day);
    let date = this.resheduledate.getFullYear() + "-" + fmon + "-" + fday;
    
    // let post = {
    //   doctorId: this.doctUserId,
    //   apptDate: date,
    //   apptTime: this.time,
    // }
    let post = {
    "status": 1,
    "doctorId": localStorage.getItem('contractor'),
    "activeInd": true,
    "apptTs": this.currentDate,
    "entityId": this.patientId,
    }
    Object.assign(post, {
      "capacityDefId": this.postTime.capDefId,
      "apptDate": date,
      "apptTime": this.postTime.startTime,
      "apptEndTime": this.postTime.endTime,
    });
    this.service.appointmentreschedule(this.resheduleId, post).subscribe(
      (res: any) => {
        this.resheduled = res;
        this.handler.res("Resheduled successfully");
        this.ngOnInit();
      });
  }

  // picking data of complete function
  pickReq(data) {
    this.testResult = data;
    if (data == "Pass") {
      this.testpass = true;
      this.countopen = false;
    } else {
      this.testpass = false;
      this.countopen = true;
    }
  }

  // picking data of complete function
  pickReq1(data) {
    this.testcount = data;
  }

  // comfirmed button function 
  confirm(id, item){
    let cofirmedId = id;
    let cofirmedItem = item;

    this.service.appointmentconfirm(cofirmedId, cofirmedItem).subscribe(
      (res: any) => {
        this.confirmed = res;
        this.handler.res("Confirmed successfully");
        this.ngOnInit();
      });
  }

  // complete button function
  complete(id, item){
    let completeId = id;
    let completeItem = item;
    this.service.appointmentcomplete(completeId, completeItem).subscribe(
      (res: any) => {
        this.completed = res;
        this.handler.res("Completed successfully");
        this.ngOnInit();
      });
  }

}