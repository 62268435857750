import { Component, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { DataService } from 'src/api-calls/data.service';
import { AppComponent } from '../app.component';
import { Popper1Component } from '../popper1/popper1.component';
import { Popper2Component } from '../popper2/popper2.component';
import { AppService } from '../app.service';
import { Popper4Component } from '../popper4/popper4.component';
import { Popper5Component } from '../popper5/popper5.component';
import { DomSanitizer } from '@angular/platform-browser';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@angular/cdk/platform';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { HandlerService } from "src/api-calls/handler.service";
import { CameraService } from '../camera.service';

declare var navigator: any;
// declare var Camera: any;
declare var cordova: any;
let docs: any, len: any, j = 1;
@Component({
  selector: 'app-diagnosis',
  templateUrl: './diagnosis.component.html',
  styleUrls: ['./diagnosis.component.scss'],
})
export class DiagnosisComponent {

  @ViewChild('myDivIdElement') myDivIdElement?: ElementRef;
  smoke;
  alcohol;
  private configUrl = window.atob(window["apiUrl"]);
  diagnosisId: any;
  data: any;
  currentSelectedItem: any;
  primaryDoc: boolean;
  previous: boolean = false;
  header: any;
  past: string;
  hideAdMed: any;
  prevDiagnosis;
  height;
  weight;
  bp;
  showTreatment: boolean = false;
  selectedOption: string = 'multiple'; // Default option
  issueName: any;
  comments: any;
  pickers1: any;
  diagnosisReport;
  nullIssue: boolean = false;
  pdata: any = [];
  tdata: any = [];
  arr: any = [];
  cols = ['medicineName', 'dosage', 'fname', 'beforeFoodInd', 'days', 'comments', 'actions'];
  teethIssues: any = [];
  teethSuggest: any = [];
  teethDiagnosis: any = [];
  cols1 = ['docName', 'primary', 'actions'];
  cols2 = ['docName', 'primary',];
  activeList = ['Yes', 'No'];
  dataSource: any;
  dataSource1: any;
  dataSource2: any;
  issue: any;
  issueDetails: any;
  issueSummary: any;
  sData: any = [];
  post: any;
  varId: any = [];
  addMed: any = [];
  addCenter: any = [];
  pic: any;
  pics: any = [];
  url: any;
  prePop: boolean = false;
  view: any;
  access: string;
  position;
  getPharmacy: any = [];
  getPharmacyName: any = [];
  getLab: any = {};
  getLabName: any;
  toggle: boolean = false;
  diagreport;
  status: any = []
  statusName: any = []
  selectStatus;
  loader: boolean = false;
  printsummary: boolean = false;
  firstname;
  doctUserId: string;
  lastname;
  teeths = [
    { name: "Third Molar" },
    { name: "Second Molar" },
    { name: "First Molar" },
    { name: "Second Bicuspid" },
    { name: "First Biscuspid" },
    { name: "Cuspid" },
    { name: "Lateral incisor" },
    { name: "Central incisor" },
    { name: "Central incisor" },
    { name: "Lateral incisor" },
    { name: "Cuspid" },
    { name: "First Biscuspid" },
    { name: "Second Bicuspid" },
    { name: "First Molar" },
    { name: "Second Molar" },
    { name: "Third Molar" },
    { name: "Third Molar" },
    { name: "Second Molar" },
    { name: "First Molar" },
    { name: "Second Bicuspid" },
    { name: "First Biscuspid" },
    { name: "Cuspid" },
    { name: "Lateral incisor" },
    { name: "Central incisor" },
    { name: "Central incisor" },
    { name: "Lateral incisor" },
    { name: "Cuspid" },
    { name: "First Biscuspid" },
    { name: "Second Bicuspid" },
    { name: "First Molar" },
    { name: "Second Molar" },
    { name: "Third Molar" },
  ]
  age;
  sex;
  name;
  newdate;
  history: any = [];
  src: any = [];
  blob;
  pdfData: any = [];
  pickerDate: any;
  picker: any;
  dateTime: boolean = false;
  upload: boolean = false;
  last: any = [];
  printUp: boolean = false;
  currentDate: Date;
  docs: any;
  docName;
  docType;
  i = 0;
  j = 0;
  docID;
  id;
  hidebp: boolean;
  isInputHighlighted: boolean;
  mobile;
  region = "CHENNAI"
  address = "MCONNETZ,65,ANUSHA STREET,AMBATTUR - 600053";
  openPop: boolean = false;
  teethName;
  f1: any;
  f2: any;
  f3: any;
  f4: any;
  f5: any;
  loader1: boolean = false;
  number;

  button: any = [
    { id: 33, name: "General" }
  ]

  button1: any = [
    { id: 34, name: "X-Rays" },
    { id: 35, name: "injection" },
    { id: 36, name: "Obj scan" }
  ]

  imgs: any = [
    {
      img: "assets/teethlist/uprRgt18m.jpg",
      // name: " Upper Right Third Molar",
      id: 1


    },
    {
      img: "assets/teethlist/uprRgt17m.jpg",
      // name: "Upper Right Second Molar",
      id: 2

    },
    {
      img: "assets/teethlist/uprRgt16m.jpg",
      // name: " Upper Right First Molar",
      id: 3

    },
    {
      img: "assets/teethlist/uprRgt15pm.jpg",
      // name: "  Upper Right Second Pre Molar",
      id: 4

    },
    {
      img: "assets/teethlist/uprRgt14pm.jpg",
      // name: " Upper Right First Pre Molar",
      id: 5

    },
    {
      img: "assets/teethlist/uprRgt13.jpg",
      // name: " Upper Right Canine",
      id: 6

    },
    {
      img: "assets/teethlist/uprRgt12.jpg",
      // name: " Upper Right Lateral Incisor",
      id: 7

    },
    {
      img: "assets/teethlist/uprRgt11m.jpg",
      // name: " Upper Right Central Incisor",
      id: 8

    },
  ]

  img1: any = [
    {
      img: "assets/teethlist/uprLft21.jpg",
      // name: " Upper Left Central Incisor",
      id: 9


    },
    {
      img: "assets/teethlist/uprLft22.jpg",
      // name: " Upper Left Central Incisor",
      id: 10

    },
    {
      img: "assets/teethlist/uprLft23.jpg",
      // name: " Upper Left Canine",
      id: 11

    },
    {
      img: "assets/teethlist/uprLft24.jpg",
      // name: " Upper Left First Pre Molar",
      id: 12

    },
    {
      img: "assets/teethlist/uprLft25.jpg",
      // name: " Upper Left Second Pre Molar",
      id: 13

    },
    {
      img: "assets/teethlist/uprLft26.jpg",
      // name: " Upper Left First Molar",
      id: 14

    },
    {
      img: "assets/teethlist/uprLft27.jpg",
      // name: " Upper Left Second Molar",
      id: 15

    },
    {
      img: "assets/teethlist/uprLft28.jpg",
      // name: " Upper Left Third Molar",
      id: 16

    },
  ]

  img2: any = [
    {
      img: "assets/teethlist/lwrRgt48.jpg",
      // name: "Lower Right First Molar",
      id: 24


    },
    {
      img: "assets/teethlist/47.jpg",
      // name: "Lower Right Second Molar",
      id: 23

    },
    {
      img: "assets/teethlist/46.jpg",
      // name: "Lower Right Third Molar",
      id: 22

    },
    {
      img: "assets/teethlist/45.jpg",
      // name: "Lower Right Second PreMolar",
      id: 21

    },
    {
      img: "assets/teethlist/44.jpg",
      // name: "Lower Right First PreMolar",
      id: 20

    },
    {
      img: "assets/teethlist/43.jpg",
      // name: "Lower Right Lateral incior",
      id: 19

    },
    {
      img: "assets/teethlist/42.jpg",
      // name: "Lower Right canine",
      id: 18

    },
    {
      img: "assets/teethlist/lr41.jpg",
      // name: "Lower Right Central incisor",
      id: 17

    },
  ]

  img3: any = [
    {
      img: "assets/teethlist/31.jpg",
      // name: "Lower Left Central incisor",
      id: 25


    },
    {
      img: "assets/teethlist/32.jpg",
      // name: "Lower Left canine",
      id: 26

    },
    {
      img: "assets/teethlist/33.jpg",
      // name: "Lower Left Lateral incior",
      id: 27

    },
    {
      img: "assets/teethlist/34.jpg",
      // name: "Lower Left First PreMolar",
      id: 28

    },
    {
      img: "assets/teethlist/35.jpg",
      // name: "Lower Left Second PreMolar",
      id: 29

    },
    {
      img: "assets/teethlist/36.jpg",
      // name: "Lower Left First Molar",
      id: 30

    },
    {
      img: "assets/teethlist/37.jpg",
      // name: "Lower Left Second Molar",
      id: 31
    },
    {
      img: "assets/teethlist/38.jpg",
      // name: "Lower Left Third Molar",
      id: 32
    },
  ]



  imgkids: any = [

    {
      img: "assets/teethlist/kids17.jpg",
      // name: "Upper Right Second Molar",
      id: 2,
      word: 'E',
    },
    {
      img: "assets/teethlist/kids16.jpg",
      // name: " Upper Right First Molar",
      id: 3,
      word: 'D',
    },
    {
      img: "assets/teethlist/kids13.jpg",
      // name: " Upper Right Canine",
      id: 6,
      word: 'C',
    },

    {
      img: "assets/teethlist/kids12.jpg",
      // name: " Upper Right Lateral Incisor",
      id: 7,
      word: 'B',
    },
    {
      img: "assets/teethlist/kids11.jpg",
      // name: " Upper Right Central Incisor",
      id: 8,
      word: 'A',
    },
  ]

  imgkids1: any = [
    {
      img: "assets/teethlist/kids21.jpg",
      // name: " Upper Left Central Incisor",
      id: 9,
      word: 'A',

    },
    {
      img: "assets/teethlist/kids22.jpg",
      // name: " Upper Left Central Incisor",
      id: 10,
      word: 'B',
    },
    {
      img: "assets/teethlist/kids23.jpg",
      // name: " Upper Left Canine",
      id: 11,
      word: 'C',
    },

    {
      img: "assets/teethlist/kids26.jpg",
      // name: " Upper Left First Molar",
      id: 14,
      word: 'D',
    },
    {
      img: "assets/teethlist/kids27.jpg",
      // name: " Upper Left Second Molar",
      id: 15,
      word: 'E',
    },

  ]

  imgkids2: any = [

    {
      img: "assets/teethlist/kidspng47.jpg",
      // name: "Lower Right Second Molar",
      id: 23,
      word: 'E',
    },
    {
      img: "assets/teethlist/kidspng46.jpg",
      // name: "Lower Right Third Molar",
      id: 22,
      word: 'D',
    },
    {
      img: "assets/teethlist/kidspng43.jpg",
      // name: "Lower Right Lateral incior",
      id: 19,
      word: 'C',
    },
    {
      img: "assets/teethlist/kidspng42.jpg",
      // name: "Lower Right canine",
      id: 18,
      word: 'B',
    },
    {
      img: "assets/teethlist/kidspng41.jpg",
      // name: "Lower Right Central incisor",
      id: 17,
      word: 'A',
    },

  ]

  imgkids3: any = [
    {
      img: "assets/teethlist/kids31.jpg",
      // name: "Lower Left Central incisor",
      id: 25,
      word: 'A',

    },
    {
      img: "assets/teethlist/kids32.jpg",
      // name: "Lower Left canine",
      id: 26,
      word: 'B',
    },
    {
      img: "assets/teethlist/kids33.jpg",
      // name: "Lower Left Lateral incior",
      id: 27,
      word: 'C',
    },

    {
      img: "assets/teethlist/kids36.jpg",
      // name: "Lower Left First Molar",
      id: 30,
      word: 'D',
    },
    {
      img: "assets/teethlist/kids37.jpg",
      // name: "Lower Left Second Molar",
      id: 31,
      word: 'E',
    },

  ]


  selectedIssue: any = [];
  selectedSuggest: any = [];
  diagnosisAlldetails: any = [];
  treatmentSummaryDs: any = [];
  allTeethList: any = [];
  allergic = [{ name: 'Yes', id: true }, { name: 'No', id: false },]
  allissu;
  allsug;
  followuptime;
  languageCode: string;
  mobileview: boolean;
  ulcer;
  thyroid;
  edible;
  wheezing;
  throats;
  cough;
  bleeding;
  palpitation;
  allergy;
  sugar;
  pregnancy;
  menstruation;
  userHabits = {};
  userHealthConditions = {};
  userHealthIssues = {};
  betelAndAricaNut: any;
  theMother: any;
  monthlyDeduction: any;
  dizzy: any;
  fever: any;
  oralMedications: any;
  chestPain: any;
  throatPain: any;
  capturedPhoto: string;
  subCheckPointId: any;
  subCheckPoints: any;
  workFlowId: string;
  showingputon: boolean;
  openImg: boolean = false;
  patientallWorkQs: any;
  patientWorkQsId: any;
  patientcheckPoint: any;
  selectedId: any;
  docId: any;
  checkpointId: any;
  appointMent: boolean;
  userDetails: any;
  showingDate: boolean = false;
  date: any;
  show: boolean;
  time: string;
  slots: any[];
  appointData: any;
  postTime: any;
  duration: any;
  nullCheck: boolean;
  appointmentData: any;
  patientId: any;
  newPatient: any;
  showtime: boolean;
  doctorName: any;
  doctorId: string;
  secMobile: string;
  gender: string;
  enable: boolean;
  selectedItem: any[];
  refId: string;
  patientType: any;
  patients: any;
  entities: any;
  getpatientWorkQsId: any;
  allissue: any = [];
  msgContent: string;
  itemHabits: any;
  itemHealthConditions: any[];
  itemHealthIssues: any[];
  capture: boolean = false;
  picload: boolean = false;
  alldepart: any = [];
  deptId: any;
  available: boolean;
  department: any;
  doctorNam: any;
  selectedTeeth: any;
  selectedItems: any[] = [];
  pushedItems: any;
  isDropdownOpen: any;
  closeDiv: boolean;
  showprescri: boolean;
  totalMedicine: any;
  allmedicineGroup: any;
  clickedCardIndex: any;
  responses: any = [];
  tableloader: boolean;
  userhealthissue: any;
  userhealthconditions: any;
  userHabit: any;
  trestmentId: any;
  userId: any;
  diatretlist: Object;
  listedsitting: { sittingId: any; }[];
  tretmentId: any;
  tretmentName: any;
  summaryDetails: any;
  summaryText: any;
  showingSummary: boolean;
  sittingNbr: any;
  allSummary: any;
  constructor(private router: ActivatedRoute,
    public dialog: MatDialog,
    private route: Router,
    public handler: HandlerService,
    private translate: TranslateService,
    private service: DataService,
    public msg: AppComponent,
    public app: AppService,
    public sanitizer: DomSanitizer,
    public platform: Platform,
    // public printer: Printer,
    private camera: Camera,
    private cameraService: CameraService,

  ) { this.currentDate = new Date(); }


  async ngOnInit() {
    this.loader = true;
    this.capture = false;
    this.picload = false;
    this.checkpointId = localStorage.getItem('diagnosisCheckPoint');
    this.languageCode = localStorage.getItem('lang');
    this.mobileview = window.innerWidth <= 800;
    j = 1;
  
    let x = JSON.parse(localStorage.getItem('config'));
    let a = x.find(res => res.shortCdConst == "GENCON");
    if (a)
      this.hidebp = JSON.parse(a.userValue);
    this.view = localStorage.getItem('roleType');
    this.url = this.route.url;

    if (this.url.includes('diagnosis')) {
      this.header = 'Diagnosis Prescription';
    }

    if (this.url.includes('diagnosises')) {
      this.showingputon = true;
      this.header = 'Diagnosis Prescription';
      var patientId = this.url.split('/').pop();
      this.service.getWorkflowbyPatient(patientId).subscribe(res => {
        this.patientallWorkQs = res;
        if (this.patientallWorkQs && this.patientallWorkQs.checkpoints) {
          this.patientWorkQsId = this.patientallWorkQs.checkpoints.id;

          var patientcheckPot = localStorage.getItem('diagnosisCheckPoint');
          if (patientcheckPot && this.patientWorkQsId === patientcheckPot) {
            this.service.getCheckpointById(this.patientWorkQsId).subscribe(res => {
              this.patientcheckPoint = res;
            });
          }
        }
      });
    }

    this.service.getMedicine().subscribe((res: any) => {
      this.totalMedicine = res;
      this.getmed()
    });

    if (this.url.includes('closed-diagnosis')) {
      this.hideAdMed = 'none';
      this.header = 'Closed Diagnosis';

    }
    if (this.url.includes('pending-reports')) {
      this.header = 'Diagnosis';

    }
    if (this.url.includes('followup/diagnosis')) {
      this.header = 'Diagnosis';
      this.past = 'Past Diagnosis';
    }
    if (this.url.includes('past')) {
      this.header = 'Past Diagnosis';
      this.past = 'Past Diagnosis';

    }
    this.access = localStorage.getItem('roleType');
    var patientId = this.url.split('/').pop()
    this.service.getWorkflowbyPatient(patientId).subscribe(res => {
      this.patientallWorkQs = res;
      if (this.patientallWorkQs[0].checkpoints) {
        this.getpatientWorkQsId = this.patientallWorkQs[0].checkpoints[0].id;
      } else {
        console.log('No checkpoints found.');
      }
      this.service.diagnosisCheckPointId(this.diagnosisId, this.getpatientWorkQsId).subscribe((res) => {
        this.data = res;
        this.userId = this.data.patientId;
        this.getalltretdiagList();
        if (this.data.patientDetails) {
          this.docId = this.data.patientDetails.doctorId;
        }
        this.entities = this.data.patientDetails.id;

        // key varble res added///////

        var a = this.data.userHabits;
        this.itemHabits = [];
        for (var key in a) {
          if (a.hasOwnProperty(key) && a[key] === true) {
            this.itemHabits.push(key);
          }
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get("itemHabits").subscribe((translation: string) => {
            })
          })
        }

        var a = this.data.userHealthConditions;
        this.itemHealthConditions = [];
        for (var key in a) {
          if (a.hasOwnProperty(key) && a[key] === true) {
            this.itemHealthConditions.push(key);
          }
        }
        var a = this.data.userHealthIssues;
        this.itemHealthIssues = [];
        for (var key in a) {
          if (a.hasOwnProperty(key) && a[key] === true) {
            this.itemHealthIssues.push(key);
          }
        }
        // key varble res added///////
      })

    },
      (error) => {
        console.error('Error retrieving workflow:', error);
      }
    );
    this.access = localStorage.getItem('roleType');
    this.router.params.subscribe(params => this.diagnosisId = params['id']);
    this.sData = await this.service.staticData().toPromise();
    this.allTeethList = await this.service.getToothList().toPromise();
    this.allTeethList.map(dat => {
      this.imgs.map(response => {
        if (response.id == dat.id) {
          Object.assign(response, { name: dat.name })
          Object.assign(response, { number: dat.number })
        }
      })
      this.button.map(response => {
        if (response.id == dat.id) {
          Object.assign(response, { name: dat.name })
          Object.assign(response, { number: dat.number })
        }
      })
      this.button1.map(response => {
        if (response.id == dat.id) {
          Object.assign(response, { name: dat.name })
          Object.assign(response, { number: dat.number })
        }
      })
      this.img1.map(response1 => {
        if (response1.id == dat.id) {
          Object.assign(response1, { name: dat.name })
          Object.assign(response1, { number: dat.number })
        }
      })
      this.img2.map(response2 => {
        if (response2.id == dat.id) {
          Object.assign(response2, { name: dat.name })
          Object.assign(response2, { number: dat.number })
        }
      })
      this.img3.map(response3 => {
        if (response3.id == dat.id) {
          Object.assign(response3, { name: dat.name })
          Object.assign(response3, { number: dat.number })
        }
      })
      this.imgkids.map(response => {
        if (response.id == dat.id) {
          Object.assign(response, { name: dat.name })
          Object.assign(response, { number: dat.number })
        }
      })
      this.imgkids2.map(response2 => {
        if (response2.id == dat.id) {
          Object.assign(response2, { name: dat.name })
          Object.assign(response2, { number: dat.number })
        }
      })
      this.imgkids3.map(response3 => {
        if (response3.id == dat.id) {
          Object.assign(response3, { name: dat.name })
          Object.assign(response3, { number: dat.number })
        }
      })
      this.imgkids1.map(response1 => {
        if (response1.id == dat.id) {
          Object.assign(response1, { name: dat.name })
          Object.assign(response1, { number: dat.number })
        }
      })
    })
    this.alldepart = await this.service.departmentGetAll().toPromise();
    this.docName = await this.service.getDoctorsList().toPromise();
    this.teethIssues = await this.service.getStaticIssues().toPromise();
    this.teethIssues.map(dat => {
      Object.assign(dat, { click: false })
    })
    this.teethSuggest = await this.service.getStaticSuggestions().toPromise();
    this.teethSuggest.map(dat => {
      Object.assign(dat, { click: false })
    })
    this.status = this.sData.filter(data => data.typeName == "DIAGNOSIS_STATUS");
    this.sData = this.sData.filter(data => data.typeId == 22);
    this.sData = this.sData[0].values;
    if (this.data.patientDetails.name) {
      this.name = this.data.patientDetails.name;
    }

    if (this.data.diagnosisDocs) {
      this.src = this.data.diagnosisDocs;
      this.dataSource1 = new MatTableDataSource(this.src);
      this.upload = true;
    }
    else {
      this.src = [];
      this.dataSource1 = [];
    }

    if (this.data.diagnosisDateStr) {
      let x = this.data.diagnosisDateStr.slice(0, -5).split(" ");
      let y = x[0].split("-").reverse().join("-");
      this.newdate = y + " " + x[1]
    }

    if (this.data.followUpDate) {
      let x = this.data.followUpDate.split("T");
      this.pickers1 = x[0].split("-").join("-");
    }
    if (this.data.followUpTime) {
      this.followuptime = this.data.followUpTime;
    }
    if (this.data.comments) {
      this.comments = this.data.comments;
    }
    if (this.data.kidsInd) {
      this.toggle = this.data.kidsInd;
    }
    if (this.data.patientDetails.age) {
      this.age = this.data.patientDetails.age;
    }

    if (this.data.patientDetails.gender) {
      this.sex = this.data.patientDetails.gender;
    }

    if (this.data.patientDetails.contactNbr) {
      this.mobile = this.data.patientDetails.contactNbr;
    }
    if (this.data.patientDetails.doctorName) {
      this.doctorNam = this.data.patientDetails.doctorName;
    }
    if (this.data.patientDetails.department) {
      this.department = this.data.patientDetails.department;
    }

    if (this.data.patientDetails.height) {
      this.height = this.data.patientDetails.height;
    }
    if (this.data.patientDetails.weight) {
      this.weight = this.data.patientDetails.weight;
    }
    if (this.data.patientDetails.bpLevel) {
      this.bp = this.data.patientDetails.bpLevel;
    }


    if (this.data.userHabits.smoke) {
      this.smoke = this.data.userHabits.smoke;
    }

    if (this.data.userHabits.habitsNotes) {
      this.userHabit = this.data.userHabits.habitsNotes;
    }
    if (this.data.userHabits.alcohol) {
      this.alcohol = this.data.userHabits.alcohol;
    }
    if (this.data.userHabits.betelAndAricaNut) {
      this.betelAndAricaNut = this.data.userHabits.betelAndAricaNut;
    }


    if (this.data.userHealthConditions.pregnancy) {
      this.pregnancy = this.data.userHealthConditions.pregnancy;
    }
    if (this.data.userHealthConditions.monthlyDeduction) {
      this.monthlyDeduction = this.data.userHealthConditions.monthlyDeduction;
    }
    if (this.data.userHealthConditions.theMother) {
      this.theMother = this.data.userHealthConditions.theMother;
    }
    if (this.data.userHealthConditions.healthConditionsNotes) {
      this.userhealthconditions = this.data.userHealthConditions.healthConditionsNotes;
    }

    if (this.data.userHealthIssues.bleeding) {
      this.bleeding = this.data.userHealthIssues.bleeding;
    }
    if (this.data.userHealthIssues.ulcer) {
      this.ulcer = this.data.userHealthIssues.ulcer;
    }
    if (this.data.userHealthIssues.palpitation) {
      this.palpitation = this.data.userHealthIssues.palpitation;
    }
    if (this.data.userHealthIssues.menstruation) {
      this.menstruation = this.data.userHealthIssues.menstruation;
    }
    if (this.data.userHealthIssues.dizzy) {
      this.dizzy = this.data.userHealthIssues.dizzy;
    }
    if (this.data.userHealthIssues.allergy) {
      this.allergy = this.data.userHealthIssues.allergy;
    }
    if (this.data.userHealthIssues.oralMedications) {
      this.oralMedications = this.data.userHealthIssues.oralMedications;
    }
    if (this.data.userHealthIssues.fever) {
      this.fever = this.data.userHealthIssues.fever;
    }
    if (this.data.userHealthIssues.sugar) {
      this.sugar = this.data.userHealthIssues.sugar;
    }
    if (this.data.userHealthIssues.chestPain) {
      this.chestPain = this.data.userHealthIssues.chestPain;
    }
    if (this.data.userHealthIssues.throatPain) {
      this.throatPain = this.data.userHealthIssues.throatPain;
    }
    if (this.data.userHealthIssues.thyroid) {
      this.thyroid = this.data.userHealthIssues.thyroid;
    }
    if (this.data.userHealthIssues.edible) {
      this.edible = this.data.userHealthIssues.edible;
    }
    if (this.data.userHealthIssues.cough) {
      this.cough = this.data.userHealthIssues.cough;
    }
    if (this.data.userHealthIssues.throatPain) {
      this.throatPain = this.data.userHealthIssues.throatPain;
    }
    if (this.data.userHealthIssues.wheezing) {
      this.wheezing = this.data.userHealthIssues.wheezing;
    }
    if (this.data.userHealthIssues.healthIssuesNotes) {
      this.userhealthissue = this.data.userHealthIssues.healthIssuesNotes;
    }


    setTimeout(() => {
      if (this.data.issueId) {
        let x = this.sData.find(res => res.id == this.data.issueId)
        this.issue = x.name;
      }
    }, 1000);

    if (this.data.issueDetails) {
      this.issueDetails = this.data.issueDetails;
    }
    if (this.data.issueSummary) {
      this.issueSummary = this.data.issueSummary;
    }
    if (this.data.prescriptions) {
      this.addMed = this.data.prescriptions;
      this.addMed.map(data => {
        if (data.medFrequencies) {
          let x = JSON.parse(JSON.stringify(data.medFrequencies));
          Object.assign(data, { "fname": JSON.parse(JSON.stringify(data.medFrequencies)) })
          data.medFrequencies.map(dat1 => {
          })
        }
        else {
          Object.assign(data, { "fname": [1] })
        }
        this.service.getMed(data.medicineId).subscribe((res: any) => {
          Object.assign(data, { "medicineName": res.name })
        })
      })
    }
    if (this.data.labTests) {
      this.tdata = this.data.labTests
    }

    setTimeout(() => {
      if (this.data.statusId) {
        let x = this.status[0].values.find(res => res.id == this.data.statusId)
        this.selectStatus = x.name;
      }
    }, 1000);
    if (this.data.diagnosisReport)
      this.diagreport = this.data.diagnosisReport
    this.addMed.map(res => {
      res.fname.map((part, index, res) => {
        if (res[index] == 1) {
          // res[index] = "Morning";
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get("Morning").subscribe((translation: string) => {
              res[index] = translation;
            });
          });
        }
        if (res[index] == 2) {
          // res[index] = "Afternoon";
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get("Afternoon").subscribe((translation: string) => {
              res[index] = translation;
            });
          });
        }
        if (res[index] == 3) {
          // res[index] = "Night";
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get("Night").subscribe((translation: string) => {
              res[index] = translation;
            });
          });
        }
      })
    })
    if (this.data.diagnosisDetails) {
      this.data.diagnosisDetails.map(dat2 => {
        this.allTeethList.map(dat3 => {
          if (dat2.teethName == dat3.id) {
            this.diagnosisAlldetails.push({
              name: dat3.name,
              issues: dat2.issues,
              suggestions: dat2.suggestions,
              id: dat2.teethName,
              type: dat2.teethType,
              number: dat2.number,
              word: dat2.word,
            })
          }
        })
      })
      this.diagnosisAlldetails = this.diagnosisAlldetails.filter((x, i, a) => a.indexOf(x) == i);
    }

    // if (this.data.treatmentSummaryDs) {
    //   this.data.treatmentSummaryDs.map(dat2 => {
    //     this.allTeethList.map(dat3 => {
    //       if (dat2.teethName == dat3.id) {
    //         this.treatmentSummaryDs.push({
    //           name: dat3.name,
    //           issues: dat2.issues,
    //           suggestions: dat2.suggestions,
    //           id: dat2.teethName,
    //           type: dat2.teethType
    //         })
    //       }
    //     })
    //   })
    //   this.treatmentSummaryDs = this.treatmentSummaryDs.filter((x, i, a) => a.indexOf(x) == i);
    // }
    if (this.data.labTests) {
      this.addCenter = this.data.labTests;
    }

    this.dataSource = new MatTableDataSource(this.addMed);


    setTimeout(() => {
      this.loader = false;
    }, 100);
    this.printDateAndTime()
    this.getallsummayDiag();
  }

  getalltretdiagList() {
    this.service.getalldiagtretListBypatient(this.userId).subscribe(res => {
      this.diatretlist = res;
    })
  }

  treatmentSelect(res) {
    this.tretmentId = res.id;
    this.tretmentName = res.name;
    // res.sittings = [
    //   { id: 1, name: "sitting1" },
    //   { id: 2, name: "sitting2" },
    //   { id: 3, name: "sitting3" },
    // ]
    this.listedsitting = res.sittingCounts;
  }

  sittingSelect(res) {
    this.sittingNbr = res;
    this.sittingtemplate(this.sittingNbr)
  }

  sittingtemplate(id) {
    this.service.gettemplateTreatmentById(this.tretmentId, id).subscribe(res => {
      this.summaryDetails = res;
      this.summaryText = this.summaryDetails[0].summaryText;
      this.showingSummary = true;
    })
  }

  savesummary() {
    let post = {
      "patientId": this.userId,
      "treatmentId": this.tretmentId,
      "sittingNbr": this.sittingNbr,
      "treatmentSumm": this.summaryText,
    }
    this.service.treatmentSummary(post).subscribe(res => {
      if (res) {
        this.getallsummayDiag();
        this.showingSummary = false;
      }
    })
  }

  getallsummayDiag() {
    this.service.getsummarybyDiagnosis(this.userId).subscribe(res => {
      if (res) {
        this.allSummary = res;
      }
    })
  }

  getmed() {
    let post = {
      orgId: localStorage.getItem('organizationId'),
      prescriptionInd: true,
    }
    this.service.searchmedgroupDetails(post).subscribe(res => {
      this.allmedicineGroup = res;
      // filter with id in prescription grouping medicine.
      if (this.allmedicineGroup && this.allmedicineGroup.length > 0) {
        this.allmedicineGroup.sort(function (a, b) {
          return a.id - b.id;
        });
      }

    })
  }


  openMedgroup(item: any, index) {
    if (this.clickedCardIndex === index) {
      this.clickedCardIndex = -1;
      this.dataSource = [];
    } else {
      this.clickedCardIndex = index;
      this.addMed = [];
      this.pushdatamat(item);
    }
  }

  pushdatamat(item) {
    this.tableloader = true;
    const resId = item.id;
    this.service.getgroupMedicine(resId).subscribe(response => {
      this.tableloader = false;
      this.responses = response;
      if (this.responses.groupMedicines) {
        this.responses.groupMedicines.forEach(res => {
          // Check if the medicine is already in the addMed array
          const isDuplicate = this.addMed.some(existingMed => existingMed.medicineId === res.medicineId);
          if (!isDuplicate) {
            const medFrequencies = []
            const medfrequencyvalue = []
            // Determine the appropriate medFrequencies based on values
            if (res.medFrequencies.includes(1)) {
              this.translate.use(this.languageCode).subscribe(() => {
                this.translate.get("Morning").subscribe((translation: string) => {
                  if (translation !== null) {
                    medFrequencies.push(translation);
                    medfrequencyvalue.push(1)
                  }
                });
              });
            }
            if (res.medFrequencies.includes(2)) {
              this.translate.use(this.languageCode).subscribe(() => {
                this.translate.get("AfterNoon").subscribe((translation: string) => {
                  if (translation !== null) {
                    medFrequencies.push(translation);
                    medfrequencyvalue.push(2)
                  }
                });
              });
            }
            if (res.medFrequencies.includes(3)) {
              this.translate.use(this.languageCode).subscribe(() => {
                this.translate.get("Night").subscribe((translation: string) => {
                  if (translation !== null) {
                    medFrequencies.push(translation);
                    medfrequencyvalue.push(3)
                  }
                });
              });
            }
            // If not a duplicate, add the medicine to the array
            res.medicineName = res.medicine.name;
            res.comments = res.medicine.comments;
            res.dosage = res.dosage; // Assuming dosage is a property within res
            res.days = res.days;
            res.inTakeQty = res.inTakeQty;
            res.fname = medFrequencies;
            res.medFrequencies = medfrequencyvalue;
            res.beforeFoodInd = res.beforeFoodInd;
            this.addMed.push(res);
          }
        });
        this.dataSource = new MatTableDataSource(this.addMed);
      }
    })
  }

  printDateAndTime() {
    var x = new Date();
    // this.currentDate = this.returnDate1(x) + " " + new Date().toLocaleTimeString()

  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  prescribe(): void {
    const dialogRef = this.dialog.open(Popper1Component, {
      width: '400px',
      data: this.addMed
    });
    dialogRef.afterClosed().subscribe(res => {
      this.addMed = this.addMed.filter(dat => dat.medicineName);
      this.dataSource = new MatTableDataSource(this.addMed)
      this.everyclicksave();
    })
  }

  returnDate(data) {
    let date = data.getDate();
    date = date < 10 ? '0' + date : date;
    let month = data.getMonth() + 1;
    month = month < 10 ? '0' + month : month;
    let year = data.getFullYear();
    return year + '-' + month + '-' + date;
  }

  returnDate1(data) {
    let date = data.getDate();
    date = date < 10 ? '0' + date : date;
    let month = data.getMonth() + 1;
    month = month < 10 ? '0' + month : month;
    let year = data.getFullYear();
    return date + '/' + month + '/' + year;
  }

  clearItem(item: any) {
    const index = this.diagnosisAlldetails.indexOf(item);
    if (index !== -1) {
      this.diagnosisAlldetails.splice(index, 1);
    }
    const id = item.id
    this.deteleDiatret(id);
    this.everyclicksave();
  }

  deteleDiatret(id) {
    var patientId = this.url.split('/').pop();
    this.service.treatmentDianosisdel(id, patientId).subscribe(res => {
      const response = res;
    })
  }

  putData() {
    this.loader = true;
    // let statusId = this.status[0].values.find(res => res.name == this.selectStatus);
    let issueNumber = this.sData.find(res => res.name == this.issue)
    this.data.userHabits = {
      "smoke": this.smoke,
      "alcohol": this.alcohol,
      "entityId": this.data.entityId,
      "betelAndAricaNut": this.betelAndAricaNut,
      "habitsNotes": this.userHabit,
    },
      this.data.userHealthConditions = {
        "pregnancy": this.pregnancy,
        "theMother": this.theMother,
        "monthlyDeduction": this.monthlyDeduction,
        "entityId": this.data.entityId,
        "healthConditionsNotes": this.userhealthconditions,
      },
      this.data.userHealthIssues = {
        "entityId": this.data.entityId,
        "ulcer": this.ulcer,
        "allergy": this.allergy,
        "dizzy": this.dizzy,
        "palpitation": this.palpitation,
        "sugar": this.sugar,
        "chestPain": this.chestPain,
        "thyroid": this.thyroid,
        "oralMedications": this.oralMedications,
        "fever": this.fever,
        "cough": this.cough,
        "throatPain": this.throatPain,
        "wheezing": this.wheezing,
        "bleeding": this.bleeding,
        "healthIssuesNotes": this.userhealthissue,
      }
    this.data.patientDetails.edible = this.edible;
    this.data.patientDetails.menstruation = this.menstruation;
    this.data.patientDetails.name = this.name;
    this.data.patientDetails.department = this.department;
    this.data.patientDetails.doctorName = this.doctorNam;
    this.data.patientDetails.age = this.age;
    this.data.patientDetails.gender = this.sex;
    this.data.patientDetails.bpLevel = this.bp;
    this.data.patientDetails.height = this.height;
    this.data.patientDetails.weight = this.weight;
    if (issueNumber) {
      this.data.issueId = issueNumber.id;
    }

    this.data.issueDetails = this.issueDetails;
    this.data.diagnosisDocs = this.src;
    this.data.diagnosisReport = this.diagreport;
    this.data.kidsInd = this.toggle;
    this.data.followUpDate = this.pickers1;
    this.data.prescriptions = this.addMed;
    this.data.comments = this.comments;
    if (this.followuptime) {
      this.data.followUpTime = this.followuptime
    }


    // else
    //   this.data.followUpTime = '00'
    var teethItem = [];
    this.diagnosisAlldetails.map(res => {
      if (this.toggle == true) {
        Object.assign(res, { newNum: res.word })
      } else {
        Object.assign(res, { newNum: res.number })
      }
      teethItem.push({
        "diagnosisId": this.data.entityId,
        "teethName": res.id,
        "teethType": res.type,
        "number": res.newNum,
        "comments": "",
        "issues": res.issues,
        "suggestions": res.suggestions,
      })
    })
    this.data.diagnosisDetails = teethItem
    var summaryDs = [];
    this.treatmentSummaryDs.map(res => {
      summaryDs.push({
        "entityId": this.data.entityId,
        "serviceId": res.id,
        "sittingNbr": res.type,
        "treatmentSumm": res.issues,
      })
    })
    this.data.treatmentSummaryDs = summaryDs
    this.service.diagnosisUpdate(this.data.entityId, this.data).subscribe(
      res => {
        // this.app.openSnackBar("Diagnosis Saved Successfully And Patient Mode Off-Que", "success")
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Diagnosis Saved Successfully And Patient Mode Off-Que").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
          })
        })
        sessionStorage.clear();
        this.clearData();
        this.diagnosisAlldetails = [];
        this.treatmentSummaryDs = [];
        this.loader = false;
        setTimeout(() => {
          this.ngOnInit();
        }, 500);
      },
      err => {
        this.loader = false;
        // this.app.openSnackBar(err.error, "error-snackbar");
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err.error).subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
      })
  }

  putData1() {
    this.loader = true;
    // let statusId = this.status[0].values.find(res => res.name == this.selectStatus);
    let issueNumber = this.sData.find(res => res.name == this.issue)
    this.data.userHabits = {
      "smoke": this.smoke,
      "alcohol": this.alcohol,
      "entityId": this.data.entityId,
      "betelAndAricaNut": this.betelAndAricaNut,
      "habitsNotes": this.userHabit,
    },
      this.data.userHealthConditions = {
        "pregnancy": this.pregnancy,
        "theMother": this.theMother,
        "monthlyDeduction": this.monthlyDeduction,
        "entityId": this.data.entityId,
        "healthConditionsNotes": this.userhealthconditions,

      },
      this.data.userHealthIssues = {
        "entityId": this.data.entityId,
        "ulcer": this.ulcer,
        "allergy": this.allergy,
        "dizzy": this.dizzy,
        "palpitation": this.palpitation,
        "sugar": this.sugar,
        "chestPain": this.chestPain,
        "thyroid": this.thyroid,
        "oralMedications": this.oralMedications,
        "fever": this.fever,
        "cough": this.cough,
        "throatPain": this.throatPain,
        "wheezing": this.wheezing,
        "bleeding": this.bleeding,
        "healthIssuesNotes": this.userhealthissue,

      }
    this.data.patientDetails.edible = this.edible;
    this.data.patientDetails.menstruation = this.menstruation;
    this.data.patientDetails.name = this.name;
    this.data.patientDetails.age = this.age;
    this.data.patientDetails.gender = this.sex;
    this.data.patientDetails.bpLevel = this.bp;
    this.data.patientDetails.height = this.height;
    this.data.patientDetails.weight = this.weight;
    if (issueNumber) {
      this.data.issueId = issueNumber.id;
    }
    // if (statusId) {
    //   this.data.statusId = statusId.id;
    // }
    this.data.issueDetails = this.issueDetails;
    this.data.diagnosisDocs = this.src;
    this.data.diagnosisReport = this.diagreport;
    this.data.followUpDate = this.pickers1;
    this.data.prescriptions = this.addMed;
    this.data.kidsInd = this.toggle;
    this.data.comments = this.comments;
    if (this.followuptime) {
      this.data.followUpTime = this.followuptime
    }


    // else
    //   this.data.followUpTime = '00'
    var teethItem = [];
    this.diagnosisAlldetails.map(res => {
      if (this.toggle == true) {
        Object.assign(res, { newNum: res.word })
      } else {
        Object.assign(res, { newNum: res.number })
      }

      teethItem.push({
        "diagnosisId": this.data.entityId,
        "teethName": res.id,
        "teethType": res.type,
        "number": res.newNum,
        "comments": "",
        "issues": res.issues,
        "suggestions": res.suggestions,
      })
    })
    this.data.diagnosisDetails = teethItem
    var summaryDs = [];
    this.treatmentSummaryDs.map(res => {
      summaryDs.push({
        "entityId": this.data.entityId,
        "serviceId": res.id,
        "sittingNbr": res.type,
        "treatmentSumm": res.issues,
      })
    })
    this.data.treatmentSummaryDs = summaryDs
    this.service.diagnosisUpdate(this.data.entityId, this.data).subscribe(
      res => {
        // this.app.openSnackBar("Diagnosis Saved Successfully And Patient Mode Off-Que", "success")
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Diagnosis Saved Successfully And Patient Mode Off-Que").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
          })
        })
        sessionStorage.clear();
        this.clearData();
        this.diagnosisAlldetails = [];
        this.treatmentSummaryDs = [];
        this.loader = false;
        setTimeout(() => {
          this.ngOnInit();
        }, 500);
      },
      err => {
        this.loader = false;
        // this.app.openSnackBar(err.error, "error-snackbar");
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err.error).subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
      })
    this.Print();
  }

  // next(id) {
  //   if (this.selectType) {
  //     if (this.subCheckPoints.length > 0 && this.subCheckPointId == 0) {
  //       this.subCheckPointId = id;
  //     }
  //     if (id == undefined) {
  //       this.subCheckPointId = 0;
  //     }
  //   }
  //   this.loader1 = true;
  //   if (this.subCheckPoints.length > 0 && this.subCheckPointId == 0) {
  //     // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
  //     this.translate.use(this.languageCode).subscribe(() => {
  //       this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
  //           this.app.openSnackBar(translation, "error-snackbar")
  //       })
  //   })
  //     this.loader1 = false;
  //   }
  //   else {
  //     if (this.subCheckPointId != 1) {
  //       if (this.checkObjecttById.mandateEditWorkflow) {
  //         this.fetchPat();
  //       }
  //       else {
  //         this.nextQue();
  //       }
  //     }
  //     else {
  //       // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
  //       this.translate.use(this.languageCode).subscribe(() => {
  //         this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
  //             this.app.openSnackBar(translation, "error-snackbar")
  //         })
  //     })
  //       this.loader1 = false;
  //     }
  //   }
  // }

  cancel() {
    // this.app.openSnackBar("Returning to previous page", "success")
    this.translate.use(this.languageCode).subscribe(() => {
      this.translate.get("Returning to previous page").subscribe((translation: string) => {
        this.app.openSnackBar(translation, "success-snackbar")
      })
    })
  }

  viewlab(id1, id2) {
    if (this.header == 'Completed Diagnosis') {
      this.route.navigate(['/diagnosis/' + id1 + '/diagnosis-test/' + id2]);
    }
    else {
      this.route.navigate(['/diagnosis/' + id1 + '/diagnosis-test/' + id2]);
    }
  }

  remove(data): void {
    const index = this.addMed.indexOf(data);
    if (index >= 0) {
      this.addMed.splice(index, 1);
      this.dataSource = new MatTableDataSource(this.addMed);
    }

  }

  // removedoc(data): void {
  //   const index = this.src.indexOf(data);

  //   if (index >= 0) {
  //     this.src.splice(index, 1);
  //     this.dataSource1 = new MatTableDataSource(this.src);
  //   }

  // }
  editPrescribe(i): void {
    const dialogRef = this.dialog.open(Popper4Component, {
      width: '400px',
      data: this.addMed[i]
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res != null) {
        this.addMed[i] = res;
        this.dataSource = new MatTableDataSource(this.addMed);
      }
    });
  }

  clearData() {
    this.issue = "";
    this.selectStatus = "";
  }

  clear() {
    // this.app.openSnackBar("All fields cleared", "success")
    this.translate.use(this.languageCode).subscribe(() => {
      this.translate.get("All fields cleared").subscribe((translation: string) => {
        this.app.openSnackBar(translation, "success-snackbar")
      })
    })
    this.bp = "";
    this.pickers1 = "";
    this.height = "";
    this.weight = "";
    this.issueDetails = "";
    this.issueSummary = "";
    this.issue = "";
    this.selectStatus = "";
    this.diagreport = "";
  }

  findIssue(data) {
    this.issueName = this.sData.filter(res => res.name.toLowerCase().includes(data));

  }

  pastData() {

    if (this.data.parentDiagnosisId) {
      this.route.navigate(['/out-patients/followup/past/' + this.data.parentDiagnosisId]);
    }
    else {
      // this.app.openSnackBar("No Past Diagnosis for you", "clear");
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("No Past Diagnosis for you").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })
    }
  }


  findstatus(data) {
    this.statusName = this.status[0].values.filter(res => res.name.toLowerCase().includes(data));
  }
  routeTo() {
    this.route.navigateByUrl('/diagnosis-history/' + this.data.patientId);
  }

  previousMed() {
    this.loader = true;
    this.service.previousMdicine(this.data.patientId).subscribe((res: any) => {
      if (res) {
        if (res.prescriptions) {
          this.last = res.prescriptions;
          this.last.map(res => {
            this.addMed.push(res)
          })
          this.addMed.map(res => {
            this.service.getMed(res.medicineId).subscribe((data: any) => {
              Object.assign(res, { "medicineName": data.name })
            })
          })
          this.addMed.map(data => {
            if (data.medFrequencies) {
              let x = JSON.parse(JSON.stringify(data.medFrequencies));
              Object.assign(data, { "fname": JSON.parse(JSON.stringify(data.medFrequencies)) })
            }
            else {

              Object.assign(data, { "fname": [1] })
            }
          })
          this.addMed.map(res => {
            res.fname.map((part, index, res) => {
              if (res[index] == 1) {
                // res[index] = "Morning";
                this.translate.use(this.languageCode).subscribe(() => {
                  this.translate.get("Morning").subscribe((translation: string) => {
                    res[index] = translation;
                  });
                });
              }
              if (res[index] == 2) {
                // res[index] = "Afternoon";
                this.translate.use(this.languageCode).subscribe(() => {
                  this.translate.get("Afternoon").subscribe((translation: string) => {
                    res[index] = translation;
                  });
                });
              }
              if (res[index] == 3) {
                // res[index] = "Night";
                this.translate.use(this.languageCode).subscribe(() => {
                  this.translate.get("Night").subscribe((translation: string) => {
                    res[index] = translation;
                  });
                });
              }
            })
          })
          this.dataSource = new MatTableDataSource(this.addMed);
          this.loader = false;
        } else {
          // this.app.openSnackBar("No Previous Diagnosis for Patient", "error-snackbar");
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get("No Previous Diagnosis for Patient").subscribe((translation: string) => {
              this.app.openSnackBar(translation, "error-snackbar")
            })
          })
          this.loader = false;
        }
      } else {
        // this.app.openSnackBar("No Previous Diagnosis for Patient", "error-snackbar");
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("No Previous Diagnosis for Patient").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
        this.loader = false;
      }

    }, err => {
      this.loader = false;
      // this.app.openSnackBar(err.error, "error-snackbar");
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get(err.error).subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    })
  }


  lab(): void {
    const dialogRef = this.dialog.open(Popper2Component, {
      width: '400px',
      data: this.addCenter
    });
    dialogRef.afterClosed().subscribe(res => {
      this.addCenter = this.addCenter.filter(res => res.name)
    });

  }

  savePrint() {
    this.loader = true;
    // let statusId = this.status[0].values.find(res => res.name == this.selectStatus);
    let issueNumber = this.sData.find(res => res.name == this.issue)
    this.data.userHabits = {
      "smoke": this.smoke,
      "alcohol": this.alcohol,
      "entityId": this.data.entityId,
      "betelAndAricaNut": this.betelAndAricaNut,
      "habitsNotes": this.userHabit,

    },
      this.data.userHealthConditions = {
        "pregnancy": this.pregnancy,
        "theMother": this.theMother,
        "monthlyDeduction": this.monthlyDeduction,
        "entityId": this.data.entityId,
        "healthConditionsNotes": this.userhealthconditions,

      },
      this.data.userHealthIssues = {
        "entityId": this.data.entityId,
        "ulcer": this.ulcer,
        "allergy": this.allergy,
        "dizzy": this.dizzy,
        "palpitation": this.palpitation,
        "sugar": this.sugar,
        "chestPain": this.chestPain,
        "thyroid": this.thyroid,
        "oralMedications": this.oralMedications,
        "fever": this.fever,
        "cough": this.cough,
        "throatPain": this.throatPain,
        "wheezing": this.wheezing,
        "bleeding": this.bleeding,
        "healthIssuesNotes": this.userhealthissue,

      }
    this.data.patientDetails.edible = this.edible;
    this.data.patientDetails.menstruation = this.menstruation;
    this.data.patientDetails.name = this.name;
    this.data.patientDetails.age = this.age;
    this.data.patientDetails.gender = this.sex;
    this.data.patientDetails.bpLevel = this.bp;
    this.data.patientDetails.height = this.height;
    this.data.patientDetails.weight = this.weight;
    if (issueNumber) {
      this.data.issueId = issueNumber.id;
    }
    // if (statusId) {
    //   this.data.statusId = statusId.id;
    // }
    this.data.issueDetails = this.issueDetails;
    this.data.diagnosisDocs = this.src;
    this.data.diagnosisReport = this.diagreport;
    this.data.followUpDate = this.pickers1;
    this.data.prescriptions = this.addMed;
    this.data.kidsInd = this.toggle;
    this.data.comments = this.comments;
    if (this.followuptime) {
      this.data.followUpTime = this.followuptime
    }


    // else
    //   this.data.followUpTime = '00'
    var teethItem = [];
    this.diagnosisAlldetails.map(res => {
      if (this.toggle == true) {
        Object.assign(res, { newNum: res.word })
      } else {
        Object.assign(res, { newNum: res.number })
      }

      teethItem.push({
        "diagnosisId": this.data.entityId,
        "teethName": res.id,
        "teethType": res.type,
        "number": res.newNum,
        "comments": "",
        "issues": res.issues,
        "suggestions": res.suggestions,
      })
    })
    var summaryDs = [];
    this.treatmentSummaryDs.map(res => {
      summaryDs.push({
        "entityId": this.data.entityId,
        "serviceId": res.id,
        "sittingNbr": res.type,
        "treatmentSumm": res.issues,
      })
    })
    this.data.diagnosisDetails = teethItem
    this.data.treatmentSummaryDs = summaryDs
    this.service.diagnosisUpdate(this.data.entityId, this.data).subscribe(
      res => {
        // this.app.openSnackBar("Data posted successfully", "success")
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Data posted successfully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
          })
        })
        sessionStorage.clear();
        this.clearData();
        this.loader = false;
        this.diagnosisAlldetails = [];
        this.treatmentSummaryDs = [];
        setTimeout(() => {
          this.ngOnInit();
          this.nextpatient();
        }, 500);
      },
      err => {
        this.loader = false;
        // this.app.openSnackBar(err.error, "error-snackbar");
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err.error).subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
      })
  }
  Print() {
    this.printUp = true;
  }
  diagnosisummarys() {
    this.printsummary = true;
  }


  // ngAfterViewInit(): void {
  //   setTimeout(() => {
  //     const nativeElement = this.myDivIdElement.nativeElement as HTMLElement;
  //   },1000);
  // }

  startCameraPreview() {
    this.capture = true;
    this.picload = false;
    setTimeout(() => {
      const nativeElement = this.myDivIdElement.nativeElement as HTMLElement;
      this.cameraService.startCameraPreview(nativeElement);
    });
  }

  onCloseCameraClick() {
    this.capture = false;
    this.cameraService.closeCamera();
  }

  onSwitchcamera() {
    this.cameraService.switchCamera();
  }

  capturePicture() {
    this.cameraService
      .takePicture()
      .then((imageSrcData: string) => {
        this.onSuccess(imageSrcData);
      })
      .catch((error: any) => {
        this.onFail(error);
        // Handle the error if necessary
      });
  }


  capturePhoto() {
    document.addEventListener('deviceready', () => {
      const options: CameraOptions = {
        quality: 100,
        destinationType: this.camera.DestinationType.DATA_URL,
        encodingType: this.camera.EncodingType.PNG,
        mediaType: this.camera.MediaType.PICTURE,
        saveToPhotoAlbum: true,
      };

      this.camera.getPicture(options).then((imageData) => {
        // imageData is either a base64 encoded string or a file URI
        // let base64Image = 'data:image/jpeg;base64,' + imageData;
        const fileData = this.dataURLtoBlob('data:image/png;base64,' + imageData);
        this.onSuccess(fileData);
      }, (error: any) => {
        this.onFail(error);
      });

      // navigator.camera.getPicture(
      //   (imageData: string) => {
      //     const fileData = this.dataURLtoBlob('data:image/png;base64,' + imageData);
      //     this.onSuccess(fileData);
      //   },
      //   (error: any) => {
      //     this.onFail(error);
      //   },
      //   options
      // );
    });
  }

  onSuccess(imageUri) {
    const fileName = this.generateFileName();
    this.src.push({ file: imageUri, docName: fileName });
    this.onCloseCameraClick();
    this.PostDocuImage(imageUri, fileName);
    this.dataSource1 = new MatTableDataSource(this.src);

  }

  ngOnDestroy(): void {
    if (this.platform.ANDROID || this.platform.IOS) {
      this.onCloseCameraClick();
    }
  }

  onFail(message) {
    console.error('Photo capture failed:', message);
    alert('Failed because: ' + message);
  }


  dataURLtoBlob(dataURL: string): Blob {
    const byteString = atob(dataURL.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: 'image/png' });
    return blob;
  }



  generateFileName(): string {
    const timestamp = new Date().getTime();
    return `Dental Picture_${timestamp}.png`;
  }

  handleFile1(files) {
    len = files.length;
    docs = files;
    this.pushDocs()
    files = [];
  }
  pushDocs() {
    let obj = this;
    var reader: any = new FileReader();
    if (docs[obj.i]) {
      reader.readAsDataURL(docs[obj.i]);
      reader.onloadend = function (event: any) {
        let oldfile = obj.src.find(dat => dat.docName == docs[obj.i].name);
        if (!oldfile) {
          obj.src.push({ file: event.target.result, docName: docs[obj.i].name });
          obj.PostDocu(event.target.result, docs[obj.i].name)
        }
        else {
          obj.i++;
          obj.pushDocs()
        }
      }
    }
    else {
      this.i = 0;
      this.dataSource1 = new MatTableDataSource(this.src)
      this.ngOnInit()
    }
  }
  deleteFile(id) {
    this.service.deleteDocument(this.diagnosisId, id).subscribe(res => {
      this.ngOnInit();
      // this.app.openSnackBar("Deleted Successfully", "clear");
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Deleted Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })

    })
  }


  openPdf(id, data) {
    let type;
    this.loader = true;
    this.service.getDocument(id, data.documentId).subscribe((res: any) => {
      this.loader = false;
      if (data.docName.includes('.pdf'))
        type = 'application/pdf'
      else
        type = 'image/jpg'
      let x = res.doc.split(',')
      const byteString = window.atob(x[1]);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: type });
      // window.open(URL.createObjectURL(blob), '_blank', 'height=screen.height,width=screen.width')
      const fileUrl = URL.createObjectURL(blob);
      if (this.platform.ANDROID || this.platform.IOS) {
        this.openImg = true;
        this.pic = this.sanitizer.bypassSecurityTrustUrl(fileUrl);
      } else {
        const win = window.open(fileUrl, '_blank');
        if (win) {
          win.focus(); // Ensure the new window/tab is focused
        } else {
          alert('Please allow pop-ups for this website to view the file.'); // Display an alert if the new window/tab was blocked
        }
      }
    })


  }

  clearDocument() {
    this.data.diagnosisDocs = "";
    this.src = "";
    this.upload = false;
  }

  open(data): void {
    const dialogRef = this.dialog.open(Popper5Component, { data: data });
  }

  // setDate() {
  //   var x = this.returnDate(this.picker)
  //   this.pickers1 = x;
  // }

  async missed() {
    this.subCheckPointId = localStorage.getItem('subcheckPointId')
    if (!this.subCheckPointId) {
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    }
    else {
      this.loader = true;
      var id = this.url.split('/').pop()
      this.workFlowId = localStorage.getItem('workQsId')
      await this.service.missed(this.workFlowId, id, this.subCheckPointId).toPromise()
        .then((res: any) => {
          this.loader = false;
          const mq = window.matchMedia("(min-width: 700px)")
          if (mq.matches) {
            this.app.openPage('workque/' + this.checkpointId)
          }
          else {
            this.app.openPage('worque/' + this.checkpointId)
          }
        })
        .catch(err => {
          this.loader = false;
        })
    }
  }

  async nextpatient() {
    this.subCheckPointId = localStorage.getItem('subcheckPointId')
    this.loader = true;
    var id = this.url.split('/').pop()
    this.workFlowId = localStorage.getItem('workQsId')
    let checkArray = [];
    await this.service.nextQ(this.workFlowId, this.subCheckPointId, checkArray).toPromise()
      .then((res: any) => {
        this.loader = false;
        const mq = window.matchMedia("(min-width: 700px)")
        if (mq.matches) {
          this.app.openPage('workque/' + this.checkpointId)
        }
        else {
          this.app.openPage('worque/' + this.checkpointId)
        }
        // this.route.navigate(['/newWorkQ']);
      })
      .catch(err => {
        this.loader = false;
      })
  }


  printOrder() {
    if (this.platform.ANDROID || this.platform.IOS) {
      var printcon = document.getElementById('overall');
      document.addEventListener('deviceready', function () {
        const printContent =
          (`<html>
          <head>  
            <style>@page {
              size: auto;
              /* auto is the initial value */
              margin: 0mm;
              /* this affects the margin in the printer settings */
            }
            
            h1 {
              color: white;
              text-align: center;
            }
            
            p {
              font-family: verdana;
              font-size: 20px;
            }
            .popupbtn {
              text-align: right;
              padding: 3px;
              margin: 40px;
            }
            .pat-data{
              margin: 15% 10% 5% 11%;
              position: relative;
              top: 20px;
            }
            
            .overall {
              display: block;
              // margin-left: auto;
              // margin-right: auto;
              // width: 65%;
              // margin-top:15%;
              margin: 14% 24% 19% 22%;
            }
            .bill {
              display: flex;
              justify-content: space-between;
              padding: 5px;
              border-bottom: 2px solid;
              border-bottom-color: grey;
            }
            
            .bill1 {
              text-align: center;
            }
            
            img {
              width: 75%;
            }
            
            h3 {
              margin: 5px;
              font-size: 17px;
              color: #6fbbd3;
            }
            
            h4 {
              margin: 5px;
              font-size: 16px;
              font-weight: 500;
            }
            h5 {
              margin: 5px;
            }
            
            .patdata {
              display: flex;
              justify-content: space-between;
              padding: 5px;
              border-bottom: 2px solid;
              border-bottom-color: grey;
            }
            
            // .patdata1 {}
            
            .meddata {
              display: flex;
              flex-direction: column;
              padding: 5px;
              border-bottom: 2px solid;
              border-bottom-color: grey;
          
            }
            .medhead {
              display: flex;
              justify-content: space-between;
              border-bottom: 2px solid;
              border-bottom-color: grey;
              padding: 5px;
            }
            .medspan {
              font-size: 15px;
              padding: 2px;
              font-weight: bold;
            }
            
            .medspan1 {
              font-size: 15px;
              // padding: 5px;
              font-weight: 500;
              width: calc(45% - 0px);
            }
            .medspan2 {
              font-size: 15px;
              padding: 5px;
              font-weight: 500;
              width: 16%;
              // text-align: end;
            }
            .medspan3 {
              font-size: 15px;
              padding: 5px;
              font-weight: 500;
              display: flex;
              flex-direction: column;
              width: 40%;
            }
            .medspan4 {
              font-size: 15px;
              padding: 5px;
              font-weight: 500;
              width: 44%;
            }
            .medspan5 {
              font-size: 15px;
              padding: 5px;
              font-weight: 500;
              width: 28%;
            }
            .medloop {
              display: flex;
              margin-bottom: 10px;
              justify-content: space-between;
              align-items: center;
            }
            .sign {
              text-align: right;
            }
            .putdata {
              display: flex;
              justify-content: space-between;
              padding: 5px;
              border-bottom: 2px solid;
              border-bottom-color: grey;
            }
            .putdata1 {
              width: 100%;
            }
            .issue-toggles-class1 {
              display: grid;
              flex-direction: column;
              padding: 10px;
              grid-template-columns: 1fr 1fr 1fr;
              gap: 4px;
              border-bottom: 2px solid;
              border-bottom-color: grey;
            }
            .newrow12 {
              padding: 10px;
              border-bottom: 2px solid;
              border-bottom-color: grey;
            
            }
            .part12{
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              gap: 10px;
              padding: 10px;
              border-bottom: 2px solid;
              border-bottom-color: grey;
            }
            .habits1 {
              display: flex;
              // border: 1px solid #ccc;
              padding: 1% 0% 1% 1%;
              border-radius: 6px;
              column-gap: 2%;
            }
            .Hospitaldelis {
              text-align: center;
              width: 95%;
              margin-left: 2%;
              h4 {
                margin: 5px;
                font-size: 16px;
                font-weight: 500;
                width: 100%;
              }
            }
            </style>
          </head>
          <body>
            ${printcon.innerHTML}
          </body>
         </html>`);
        cordova.plugins.printer.print(printContent);
      }, false);
    } else {
      setTimeout(() => {
        this.printUp = false;
        let a: any = document.getElementById('overall');
        // var mywindow = window.open('', 'Print', 'height=screen.height,width=screen.width');

        // const fileUrl = URL.createObjectURL(a);
        var win = window.open('', '_blank');
        if (win) {
          win.focus(); // Ensure the new window/tab is focused
        } else {
          alert('Please allow pop-ups for this website to view the file.'); // Display an alert if the new window/tab was blocked
        }
        win.document.write('<html><head><link rel="stylesheet" href="assets/print.scss" /><style>@page { size: auto;  margin: 0mm; }</style></head></html>');
        win.document.body.appendChild(a)
        win.document.close();
        setTimeout(() => {
          win.print();
          win.focus()
          win.close();
          this.ngOnInit();
        }, 1000);
      },
        1000);
    }

  }

  workQget() {
    var id = sessionStorage.getItem('checkId')
    this.route.navigateByUrl('/workque/' + id);
  }
  workQget1() {
    this.route.navigateByUrl('/patientSearch');
  }

  PostDocuImage(basedata, name) {
    this.picload = true;
    let post = {
      diagnosisId: this.diagnosisId,
      doc: basedata,
      primary: false
    }
    this.service.postDocument(this.diagnosisId, post, name).subscribe(res => {
      // this.ngOnInit();
      this.service.diagnosisCheckPointId(this.diagnosisId, this.getpatientWorkQsId).subscribe((res) => {
        this.data = res;
        this.userId = this.data.patientId;
        if (this.data.diagnosisDocs) {
          this.picload = false;
          this.src = this.data.diagnosisDocs;
          this.dataSource1 = new MatTableDataSource(this.src);
          this.upload = true;
        } else {
          this.picload = false;
        }

      })


    })
  }

  PostDocu(basedata, name) {
    let post = {
      diagnosisId: this.diagnosisId,
      doc: basedata,
      primary: false
    }
    this.service.postDocument(this.diagnosisId, post, name).subscribe(res => {
      this.i++;
      this.pushDocs()
    })
  }
  viewRoute(id) {
    let routeurl;
    routeurl = this.configUrl + '/cliniq/#/pdfview/' + id;
    window.open(routeurl, "_blank", 'height=screen.height,width=screen.width')
  }

  prev() {
    this.loader = true;
    this.service.clinicaPathistory(this.data.patientId).subscribe((res: any) => {
      if (res) {
        let current = res.find(dat => dat.entityId == this.diagnosisId);
        let i = res.indexOf(current) - j;
        j++;
        if (res[i])
          this.setPrevDiagnosis(res[i].entityId)
        else {
          // this.app.openSnackBar('No Previous Diagnosis Found', 'error-snackbar')
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get("No Previous Diagnosis Found").subscribe((translation: string) => {
              this.app.openSnackBar(translation, "error-snackbar")
            })
          })
        }
      }
      else {
        // this.app.openSnackBar('No Previous Diagnosis Found', 'error-snackbar')
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("No Previous Diagnosis Found").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
      }
      this.loader = false;
    }, err => {
      // this.app.openSnackBar(err.error.msg, 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get(err.error.msg).subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
      this.loader = false;
    })
  }

  setPrevDiagnosis(id) {
    this.service.diagnosis(id).subscribe((res: any) => {
      if (res) {
        this.prePop = true;
        let x = res.diagnosisDateStr.slice(0, -5).split(" ");
        let y = x[0].split("-").reverse().join("-");
        res.diagnosisDateStr = y + " " + x[1];
        if (res.issueId) {
          let x = this.sData.find(res => res.id == res.issueId)
          if (x)
            res.issueId = x.name;
          else
            res.issueId = " ";
        }
        if (res.statusId) {
          let x = this.status[0].values.find(res => res.id == res.statusId)
          if (x)
            res.statusId = x.name;
          else
            res.statusId = " "
        }
        if (res.prescriptions) {
          res.prescriptions.map(dat => {
            let x = JSON.parse(JSON.stringify(dat.medFrequencies));
            Object.assign(dat, { "fname": JSON.parse(JSON.stringify(dat.medFrequencies)) })
            this.service.getMed(dat.medicineId).subscribe((res: any) => {
              Object.assign(dat, { "medicineName": res.name })
            })
            dat.fname.map((part, index, res) => {
              if (dat[index] == 1) {
                // res[index] = "Morning";
                this.translate.use(this.languageCode).subscribe(() => {
                  this.translate.get("Morning").subscribe((translation: string) => {
                    res[index] = translation;
                  });
                });
              }
              if (dat[index] == 2) {
                // res[index] = "Afternoon";
                this.translate.use(this.languageCode).subscribe(() => {
                  this.translate.get("Afternoon").subscribe((translation: string) => {
                    res[index] = translation;
                  });
                });
              }
              if (dat[index] == 3) {
                // res[index] = "Night";
                this.translate.use(this.languageCode).subscribe(() => {
                  this.translate.get("Night").subscribe((translation: string) => {
                    res[index] = translation;
                  });
                });
              }

            })
          })
          let datasource = new MatTableDataSource(res.prescriptions)
          res.prescriptions = datasource
        }
        this.history.push(res);
        this.history = this.history.sort(function (a, b) {
          var key1 = new Date(a.diagnosisDate);
          var key2 = new Date(b.diagnosisDate);

          if (key1 < key2) {
            return -1;
          } else if (key1 == key2) {
            return 0;
          } else {
            return 1;
          }
        });
        this.dataSource2 = new MatTableDataSource(res.diagnosisDocs)
        this.loader = false;
      }
      else {
        // this.app.openSnackBar('No Previous Diagnosis Found', 'error-snackbar');
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("No Previous Diagnosis Found").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
        this.loader = false;
      }

    },
      err => {
        // this.app.openSnackBar(err.error.msg, 'error-snackbar')
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err.error.msg).subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
        this.loader = false;
      })
  }

  suggestClick(itemId: string) {
    this.sData.map(res => {
      if (res.id == itemId) {
        this.issue = res.name;
        // this.issueId =res.id;
        this.isInputHighlighted = true;
      }
    })
  }

  clearDiag() {
    this.loader = true;
    this.prePop = false;
    this.history = [];
    j = 1;
    setTimeout(() => {
      this.loader = false;
    }, 500);
  }

  primaryData(d1, d2, d3) {
    this.service.putPrimaryDoc(d2, d3, d1).subscribe(res => {
      this.ngOnInit();
      // this.app.openSnackBar("Document Updated Successfully", "clear");
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Document Updated Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })
    })
  }

  clickTeeth1(data) {
    let exist = this.teethDiagnosis.find(res => res.id == data.id);
    this.allTeethList.map(dat => {
      if (dat.id == data.id) {
        this.teethName = dat.name;
        if (!exist)
          this.teethDiagnosis.push(dat);
      }
    })
    this.openPop = true;
  }

  clickTeeth(items: any[]): void {
    this.pushedItems = items;
    // Clear the existing teethDiagnosis array
    this.teethDiagnosis.length = 0;
    this.pushedItems.forEach(selectedItem => {
      const existingIndex = this.teethDiagnosis.findIndex(item => item.id === selectedItem.id);
      if (existingIndex === -1) {
        this.teethDiagnosis.push(selectedItem); // Push if not already selected
      }
    });
    this.closeDiv = true;
  }

  isSelected(item: any): boolean {
    return this.selectedItems.includes(item);
  }

  toggleSelection(item: any): void {
    if (this.isSelected(item)) {
      this.selectedItems = this.selectedItems.filter((i) => i !== item);
    } else {
      this.selectedItems.push(item);
    }
  }

  list() {
    this.openPop = true
  }

  pushIssue() {
    if (this.selectedIssue.length == 0 && this.selectedSuggest.length == 0) {
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("please select both issue and suggestions").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    } else {
      this.openPop = false;
      this.closeDiv = false;
      var sentenceArray = [];
      var issue;
      var sentenceArray1 = [];
      var suggest;
      this.selectedIssue.map(res => {
        sentenceArray.push(res.name);
        issue = sentenceArray.slice(0, sentenceArray.length - 1).join(', ') + " ," + sentenceArray.slice(-1);
      })
      this.selectedSuggest.map(res => {
        sentenceArray1.push(res.name);
        suggest = sentenceArray1.slice(0, sentenceArray1.length - 1).join(', ') + " ," + sentenceArray1.slice(-1);
        this.trestmentId = res.id;
        this.savingDiatret(this.trestmentId);
      })
      this.teethDiagnosis.map(res3 => {
        if (this.toggle == true) {
          Object.assign(res3, { newNum: res3.word })
        } else {
          Object.assign(res3, { newNum: res3.number })
        }
        this.diagnosisAlldetails.push({
          img: res3.img,
          name: res3.name,
          issues: issue,
          suggestions: suggest,
          id: res3.id,
          type: res3.type,
          number: res3.newNum,
          word: res3.word,
        })
        this.diagnosisAlldetails = this.diagnosisAlldetails.filter((x, i, a) => a.indexOf(x) == i);
      })
      this.teethIssues.map(dat => {
        Object.assign(dat, { click: false })
      })
      this.teethSuggest.map(dat => {
        Object.assign(dat, { click: false })
      })
      this.selectedIssue = [];
      this.teethDiagnosis = [];
      this.selectedItems = [];
    }
    this.everyclicksave();
  }

  savingDiatret(id) {
    var patientId = this.url.split('/').pop();
    let post = {
      "diagnosisId": patientId,
      "treatmentId": id,
    }
    this.service.treatmentDianosispost(patientId, id, post).subscribe(res => {

    })
  }

  onToggleChange(): void {
    this.everyclicksave(); // Call your function when the toggle is turned on
  }

  everyclicksave() {
    let issueNumber = this.sData.find(res => res.name == this.issue)
    this.data.userHabits = {
      "smoke": this.smoke,
      "alcohol": this.alcohol,
      "entityId": this.data.entityId,
      "betelAndAricaNut": this.betelAndAricaNut,
      "habitsNotes": this.userHabit,

    },
      this.data.userHealthConditions = {
        "pregnancy": this.pregnancy,
        "theMother": this.theMother,
        "monthlyDeduction": this.monthlyDeduction,
        "entityId": this.data.entityId,
        "healthConditionsNotes": this.userhealthconditions,

      },
      this.data.userHealthIssues = {
        "entityId": this.data.entityId,
        "ulcer": this.ulcer,
        "allergy": this.allergy,
        "dizzy": this.dizzy,
        "palpitation": this.palpitation,
        "sugar": this.sugar,
        "chestPain": this.chestPain,
        "thyroid": this.thyroid,
        "oralMedications": this.oralMedications,
        "fever": this.fever,
        "cough": this.cough,
        "throatPain": this.throatPain,
        "wheezing": this.wheezing,
        "bleeding": this.bleeding,
        "healthIssuesNotes": this.userhealthissue,

      }
    this.data.patientDetails.edible = this.edible;
    this.data.patientDetails.menstruation = this.menstruation;
    this.data.patientDetails.name = this.name;
    this.data.patientDetails.department = this.department;
    this.data.patientDetails.doctorName = this.doctorNam;
    this.data.patientDetails.age = this.age;
    this.data.patientDetails.gender = this.sex;
    this.data.patientDetails.bpLevel = this.bp;
    this.data.patientDetails.height = this.height;
    this.data.patientDetails.weight = this.weight;
    if (issueNumber) {
      this.data.issueId = issueNumber.id;
    }
    this.data.issueDetails = this.issueDetails;
    this.data.diagnosisDocs = this.src;
    this.data.diagnosisReport = this.diagreport;
    this.data.followUpDate = this.pickers1;
    this.data.prescriptions = this.addMed;
    this.data.kidsInd = this.toggle;
    this.data.comments = this.comments;
    if (this.followuptime) {
      this.data.followUpTime = this.followuptime
    }
    var teethItem = [];
    this.diagnosisAlldetails.map(res => {
      if (this.toggle == true) {
        Object.assign(res, { newNum: res.word })
      } else {
        Object.assign(res, { newNum: res.number })
      }
      teethItem.push({
        "diagnosisId": this.data.entityId,
        "teethName": res.id,
        "teethType": res.type,
        "number": res.newNum,
        "comments": "",
        "issues": res.issues,
        "suggestions": res.suggestions,
      })
    })
    this.data.diagnosisDetails = teethItem
    var summaryDs = [];
    this.treatmentSummaryDs.map(res => {
      summaryDs.push({
        "entityId": this.data.entityId,
        "serviceId": res.id,
        "sittingNbr": res.type,
        "treatmentSumm": res.issues,
      })
    })
    this.data.treatmentSummaryDs = summaryDs
    this.service.diagnosisUpdate(this.data.entityId, this.data).subscribe(
      res => {
        // this.translate.use(this.languageCode).subscribe(() => {
        //   this.translate.get("Diagnosis Saved Successfully And Patient Mode Off-Que").subscribe((translation: string) => {
        //     this.app.openSnackBar(translation, "success-snackbar")
        //   })
        // })
        sessionStorage.clear();
        this.clearData();
        // this.diagnosisAlldetails = [];
        // this.treatmentSummaryDs = [];
      },
      err => {
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err.error).subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
      })
  }
  // issueSelect(item) {
  //   let x = [];
  //   item.click = !item.click;
  //   x = this.teethIssues.filter(res => { return res.click == true });
  //   x.map((dat1, i) => {
  //     if (dat1.click == true) {
  //       this.selectedIssue.push(dat1);
  //     } else {
  //       this.selectedIssue.splice(i, 1);
  //     }
  //   })
  //   this.selectedIssue = this.selectedIssue.filter((x, i, a) => a.indexOf(x) == i);
  // }

  // select(id) {
  //   this.teethIssues.map(res => {
  //     if (res.id == id) {
  //       res.click = !res.click;
  //     }
  //   })
  //   let x = [];
  //   x = this.teethIssues.filter(res => { return res.click == true });
  //   x.map((dat1, i) => {
  //     if (dat1.click == true) {
  //       this.selectedIssue.push(dat1);
  //     } else {
  //       this.selectedIssue.splice(i, 1);
  //     }
  //   })
  //   this.selectedIssue = this.selectedIssue.filter(res => res.id != id)
  // }  

  select(id) {
    this.teethIssues.map(res => {
      if (res.id == id) {
        res.click = !res.click;
      }
    });
    let x = this.teethIssues.filter(res => res.click == true);
    this.selectedIssue = [];
    x.map((dat1) => {
      if (dat1.click) {
        this.selectedIssue.push(dat1);
      }
    });
    this.selectedIssue = this.teethIssues.filter(res => res.click);
    this.loader = false;
    this.service.getTreatmentIssusList(id).subscribe((res: any) => {
      if (res) {
        this.allissue = res;
        this.loader = false;
        if (this.allissue == '') {
          this.nullIssue = false;
        } else {
          this.nullIssue = true;
        }
        // this.selectedIssue = [];
        // x.map((dat1) => {
        //   if (dat1.click) {
        //     this.selectedIssue.push(dat1);
        //   }
        // });
        // this.selectedIssue = this.teethIssues.filter(res => res.click);
      } else {
        //  this.alldepart=[];
        this.loader = false;
        this.msgContent = "No Treatment Available"
      }

    }), err => {
      this.loader = false;
    }
  }

  // selectSuggest(id) {
  //   this.allissue.map(res => {
  //     if (res.id == id) {
  //       res.click = !res.click;
  //     }
  //   })
  //   let y = this.allissue.filter(res => res.click == true);
  //   this.selectedSuggest = [];
  //   y.map((dat1) => {
  //     if (dat1.click) {
  //       this.selectedSuggest.push(dat1);
  //     }
  //   });
  //   this.selectedSuggest = this.selectedSuggest.filter(res => res.click);
  // }

  selectSuggest(id) {
    this.allissue.forEach(res => {
      if (res.id === id) {
        res.click = !res.click;
      }
    });
    this.selectedSuggest = this.allissue.filter(res => res.click);
  }

  // suggestSelect(item) {
  //   let y = [];
  //   item.click = !item.click;
  //   y = this.teethSuggest.filter(res => { return res.click == true });
  //   y.map((dat1, i) => {
  //     if (dat1.click == true) {
  //       this.selectedSuggest.push(dat1)
  //     } else {
  //       this.selectedSuggest.splice(i, 1);
  //     }
  //   })
  //   this.selectedSuggest = this.selectedSuggest.filter((x, i, a) => a.indexOf(x) == i);
  // }

  // clearReport(i){
  //   this.diagnosisAlldetails.splice(i, 1);
  // }

  // openCalendar() {
  //   this.selectedId = this.docId;
  //   localStorage.setItem('contractor', this.selectedId);
  //   this.app.openPage('calendar');
  // }
  openCalendar() {
    // this.loader = true;
    this.appointMent = false;
    this.selectedId = this.docId;
    if (this.selectedId != null && this.selectedId != "" && this.selectedId != undefined) {
      localStorage.setItem('contractor', this.selectedId);
      this.service.getdoctorsById(this.selectedId).subscribe((res: any) => {
        this.userDetails = res;
        this.loader = false;
        this.showingDate = true;
        this.date = this.currentDate;
        this.clearTime();
        if (this.date == "") {
          this.show = false;
        }
      });
    } else {
      this.handler.res("Invalid doctorId ");
    }

  }


  clearTime() {
    this.time = "Show Time";
    this.slots = [];
    this.show = false;
    this.getAppoints();
    // this.checkPreview1();
    this.postTime = "";
  }


  getAppoints() {

    let month = this.date.getMonth() + 1;
    var fmon;
    month < 10 ? (fmon = "0" + month) : (fmon = month);
    let day = this.date.getDate();
    var fday;
    day < 10 ? (fday = "0" + day) : (fday = day);
    let date = this.date.getFullYear() + "-" + fmon + "-" + fday;
    let post = {
      // statuses: [2, 3, 6],
      apptDate: date,
      // toDate: date,
      // trainerFetch: this.trainerFetch,
      orgId: localStorage.getItem("organizationId"),
      // fetchSpecificOrg: true,
      statuses: [1, 2, 3, 4],
    };

    if (this.docId != null) {
      Object.assign(post, { doctorId: this.docId });
    }
    this.service.getAppointReq2(post).subscribe(
      (res: any) => {
        if (res != null && res != "NO_CONTENT" && res.length > 0) {
          this.appointMent = true;
          this.appointData = res;
          let x: any = null;
        }
        else {
          this.appointMent = false;
          //  this.handler.res("No Appointments For You");
        }
      },
      (err) => {
        this.handler.err(err);
        this.appointMent = false;
        this.loader = false;
      }
    );
  }
  setTime(data, indx) {
    this.time = data.startTime;
    this.postTime = data;
    this.slots.map((res, i) => {
      indx == i
    });
    setTimeout(() => {
      this.show = false;
    }, 200);

  }
  checkPreview() {
    this.doctUserId = localStorage.getItem('contractor')
    this.getSlots(this.doctUserId);
  }
  getSlots(Id) {
    let x = this.date.getDate();
    let y = this.date.getMonth() + 1;
    let z = this.date.getFullYear();
    let pass = x + "-" + y + "-" + z;
    let post = {
      targetCenterId: Id,
      targetSource: Id,
      schElements: [1],
      slotDuration: this.duration,
      requestDate: pass,
      status: ["SlotsAvailable"],
      independentEntities: true,
    };
    this.show = true;
    this.service.getAppointmentsTime(pass, post).subscribe(
      (res: any) => {
        if (res == null) {
          this.nullCheck = true;
        } else {
          this.nullCheck = false;
          this.slots = res;
          this.slots.map((sum) => Object.assign(sum, { style: "none" }));
        }
      },
      (err) => {
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err).subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
      }
    );
  }

  bookCheck() {
    if (this.date != "" && this.date != null && this.time != "Show Time") {
      this.save();
    } else {
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select Date & Time").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    }
  }


  async save() {
    if (this.name == undefined || this.name == "") {
      return this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please enter the required fields",).subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar");
        });
      })
    }
    else {
      let secondNumb;
      if (this.name) {
        this.bookCheck1();
      }
    }
  }

  bookCheck1() {
    // this.loader = true;
    if (this.date) {
      let z = this.date.getFullYear();
      let month = this.date.getMonth() + 1;
      var fmon;
      month < 10 ? (fmon = "0" + month) : (fmon = month);
      let day = this.date.getDate();
      var fday;
      day < 10 ? (fday = "0" + day) : (fday = day);
      var pass = z + "-" + fmon + "-" + fday;
    }
    let post = {
      "status": 1,
      "doctorId": localStorage.getItem('contractor'),
      "activeInd": true,
      "apptTs": this.currentDate,
      "entityId": this.patientId,
    };
    Object.assign(post, {
      "capacityDefId": this.postTime.capDefId,
      "apptDate": pass,
      "apptTime": this.postTime.startTime,
      "apptEndTime": this.postTime.endTime,
      // "newPatient": this.newPatient,
      "newPatient": true,
      "entityId": this.entities,
    });
    this.service.appointmentbooking(post).subscribe((res: any) => {
      this.appointmentData = res;
      this.loader = false;
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get('Patient Checked-In Successfully').subscribe((translation: string) => {
          this.app.openSnackBar(translation, 'success-snackbar');
        });
      });
      this.name = "";
      this.mobile = "";
      this.secMobile = "";
      this.age = "";
      this.gender = "";
      this.height = "";
      this.weight = "";
      this.enable = true;
      this.selectedItem = [];
      this.refId = "";
      this.patientType = this.patients[1].id;
      this.app.aged = false;
      this.app.wheeled = false;
      this.app.callRequired = false;
      this.app.nri = false;
      this.app.vipPat = false;
      this.app.emrgPat = false;
      this.newPatient = false;
      this.showtime = false;
      this.doctorName = "";
      this.doctorId = "";
      this.appointData = [];
      this.docName = [];
      this.showingDate = false;
      this.appointMent = false;
      this.date = null;
      this.time = "Show Time"
      this.selectedId = undefined;
      this.ngOnInit();
    },
    )
  }
  getId(data) {
    this.alldepart.map((res) => {
      if (data) {
        if (res.name == data) {
          this.deptId = res.id;
          this.data.getDoctorByDepartmentId(this.deptId).subscribe((res) => {
            this.docName = res;
            this.available = true;
            this.doctorName = [];
          });
        }
      }
    });
  }
  openprescribe() {
    this.showprescri = true;
  }
}





