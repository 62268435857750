import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navigation-chart',
  templateUrl: './navigation-chart.component.html',
  styleUrls: ['./navigation-chart.component.scss']
})
export class NavigationChartComponent implements OnInit {

  alldepart: any = [];
  loader;
  ad;
  page;
  component;
  msgContent: string = "";
  languageCode: string;
  constructor(private data: DataService, private translate: TranslateService, public app: AppService) { }

  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.loader = true;
    this.data.getnavigationchartList().subscribe((res: any) => {
      if (res) {
        this.alldepart = res;
        this.loader = false;
      } else {
        this.alldepart = [];
        this.loader = false;
        this.msgContent = "No Navigation Chart Available"
      }

    }), err => {
      this.loader = false;
    }

  }

  create() {
    this.app.openPage('createchart')
  }
  edit(id) {
    this.app.openPage('edit-chart/' + id)
  }
  delete(id) {
    this.loader = true;
    this.data.navigationchartDeleteById(id).subscribe(res => {
      this.loader = false;
      this.ngOnInit();
      // this.app.openSnackBar('Deleted Successfully !!', 'success-snackbar');   
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Deleted Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })

    }, err => {
      this.loader = false;
    })
  }

}

