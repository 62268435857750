import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/api-calls/auth.service';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { DoctorSetupComponent } from '../doctor-setup/doctor-setup.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-treatmentadd',
  templateUrl: './treatmentadd.component.html',
  styleUrls: ['./treatmentadd.component.scss']
})
export class TreatmentaddComponent implements OnInit {

  alldepart: any = [];
  name;
  desc;
  code;
  doctoredit: boolean;
  loader;
  url;
  id;
  treatmentObj: any = [];
  languageCode: string;
  items: any = [];
  dataSource: any;
  msgContent: string;
  avgTime;
  langCd: any;
  sittingCount: any;
  activeInd: any;
  issues: any = [];
  checklocation1: any = [];
  handler: any;
  checkloc: any =[];
  assetTypeVendorTagList: any;

  constructor(public data: DataService, private translate: TranslateService, public app: AppService, public auth: AuthService) { }

  ngOnInit() {
    this.getIssues();
    this.languageCode = localStorage.getItem('lang');
    this.url = this.app.urlData();
    if (this.url != 'treatmentadd') {
      this.doctoredit = true;
      this.id = this.app.urlData();
      this.data.getTreatmentById(this.id).subscribe((res: any) => {
        this.treatmentObj = res;
        this.id = res.id;
        this.code = res.code;
        this.sittingCount = res.sittingCount;
        this.langCd = res.langCd;
        this.desc = res.description;
        this.name = res.name;
        this.assetTypeVendorTagList = res.issuesList;
        if(this.assetTypeVendorTagList && this.assetTypeVendorTagList.length > 0){
          this.assetTypeVendorTagList.map((res) => {
            this.getIssuesTypes(res.id)
          })
        }
      }, err => {
        this.loader = false;
      })
    } else {
      console.log('jj')
    }

  }

  treatmentCreate() {
    this.loader = true;
    let assetTypeVendorTagList = this.checkloc ? this.checkloc : this.checklocation1;
    if (assetTypeVendorTagList) {
      assetTypeVendorTagList.map((res) => {
        Object.assign(res, { issueId: res.id })
      })
    }
    var post = {
      "name": this.name.toUpperCase(),
      "code": this.code,
      "sittingCount": this.sittingCount,
      "description": this.desc,
      "langCd": this.langCd,
      "issuesTreatments": assetTypeVendorTagList,
    }
    this.data.treatmentCreate(post).subscribe((res: any) => {
      this.loader = false;
      this.app.openPage('treatmentsetup');
      // this.app.openSnackBar('Doctor Created Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Treatment Created Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })

    }, err => {
      this.loader = false;
    })
  }
  cancel() {
    this.app.openPage('treatmentsetup')
  }



  getIssues() {
    this.data.getStaticIssues().subscribe((res) => {
      this.issues = res;
      this.loader = false;
    }, err => {
      this.loader = false;
      this.handler.err(err);
    })
  }


  async getIssuesTypes(id) {
    let mapData: any = [];
    mapData = this.issues.filter(res => { return res.id == id });
    if (mapData.length > 0)
      this.checkloc.push(mapData[0]);
    let order = [1, 3, 2];
    this.checkloc.sort(function (a, b) {
      return order.indexOf(a.checkPointPositionId) - order.indexOf(b.checkPointPositionId)

    });
    this.issues = this.issues.filter(res => !this.checkloc.find(val => res.id === val.id));
  }
  async getbackIssuesTypess(id) {
    let mapData: any = [];
    mapData = this.checkloc.filter(res => { return res.id == id });
    this.issues.push(mapData[0]);
    this.checkloc = this.checkloc.filter(res => !this.issues.find(val => res.id === val.id));

  }

  treatmentUpdate() {
    this.loader = true;
    let assetTypeVendorTagList = this.checkloc ? this.checkloc : this.checklocation1;
    if (assetTypeVendorTagList) {
      assetTypeVendorTagList.map((res) => {
        Object.assign(res, { issueId: res.id })
      })
    }
    this.treatmentObj.name = this.name.toUpperCase();
    this.treatmentObj.id = this.id;
    this.treatmentObj.shortCode = this.code;
    this.treatmentObj.sittingCount = this.sittingCount;
    this.treatmentObj.description = this.desc;
    this.treatmentObj.langCd = this.langCd;
    this.treatmentObj.issuesTreatments = assetTypeVendorTagList
    this.activeInd = true;
    this.data.treatmentPutById(this.id, this.treatmentObj).subscribe(res => {
      this.loader = false;
      this.app.openPage('treatmentsetup');
      // this.app.openSnackBar('Doctor Updated Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Treatment Updated Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })
    }, err => {
      this.loader = false;
    })

  }


}
