import { Injectable, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  webCheck: boolean = false;

  numTransfer: any;

  lang: any;

  userDetails: any;

  workQData: any;
  checkPointName: any = [];
  entityuserId: any;

  showFull: boolean = false;

  popData: any = [];
  popMob: any = [];

  popSub: any = [];
  shiftData: any = [];
  preRequest: any = [];
  moveBackToQ: boolean = false;
  forceStartReason;
  forceStartComments;
  comments: any = {};
  checkId;
  subCheckId;
  aged: boolean = false;
  wheeled: boolean = false;
  callRequired: boolean = false;
  nri: boolean = false;
  emrgPat: boolean = false;
  vipPat: boolean = false;
  loader1: boolean = false;
  refIdObj: any;
  subCheckpointIdP;
  fromDate: Date;
  toDate: Date;
  mobCab: boolean;
  forceStartRea: any = [];
  forceRemoveRea: any = [];

  constructor(public router: Router, private snackBar: MatSnackBar, public translate: TranslateService) { }

  openPage(data) {
    this.router.navigateByUrl(data);
  }
  urlData() {
    return this.router.url.split('/').pop()
  }
  sendUrl() {
    return this.router.url
  }

  openSnackBar(message: string, pc: string) {
    this.snackBar.open(message, 'x', {
      duration: 2000,
      panelClass: [pc]
    });
  }
  setLang() {
    var lang = localStorage.getItem('lang');
    if (!lang) {

      this.translate.setDefaultLang('en-US');
      localStorage.setItem('lang', 'en-US');
    }
    else {

      this.translate.setDefaultLang(lang);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    var width = window.innerWidth;
    if (width < 600) {
      this.webCheck = false;
    }
    else {
      this.webCheck = true;
    }
  }

  changeCSS(cssFile, cssLinkIndex) {

    var oldlink = document.getElementsByTagName("link").item(cssLinkIndex);
    var newlink = document.createElement("link");
    newlink.setAttribute("rel", "stylesheet");
    newlink.setAttribute("type", "text/css");
    newlink.setAttribute("href", cssFile);

    document.getElementsByTagName("head").item(0).replaceChild(newlink, oldlink);
  }


}
