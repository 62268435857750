import { Injectable, ViewChild, ElementRef } from '@angular/core';
declare var cordova: any;
declare var CameraPreview: any;
@Injectable({
  providedIn: 'root'
})
export class CameraService {

  constructor() { }
  startCameraPreview(myDivId) {
    document.addEventListener('deviceready', () => {
    const popupWidth = 60;
    const popupHeight = 60;
    const cameraOptions = {
      // x: (window.innerWidth - window.innerWidth / 2) / 2,
      // y: (window.innerHeight - window.innerHeight / 2) / 2,
      // width: window.innerWidth / 2,
      // height: window.innerHeight / 2,
      x: (window.innerWidth - (window.innerWidth * popupWidth) / 100) / 2,
      y: (window.innerHeight - (window.innerHeight * popupHeight) / 100) / 2,
      width: (window.innerWidth * popupWidth) / 100,
      height: (window.innerHeight * popupHeight) / 100,
      camera: 'rear', // or "front"
      toBack: false,
      tapPhoto: false,
      tapFocus: true,
      previewDrag: false,
      storeToFile: false,
      disableExifHeaderStripping: false
    };
    
    CameraPreview.startCamera(
      cameraOptions,
      (success) => {
        console.log('Camera preview started');
        const cameraElement = document.createElement('div');
        cameraElement.innerHTML = success;
        document.getElementById(myDivId).appendChild(cameraElement);
      },
      (error: any) => {
        console.error('Error starting camera preview: ', error);
      }
    );  
   });
  }

  takePicture(): Promise<String> {
    const pictureOptions = {
      width: 1280,
      height: 720,
      quality: 50
    };
    
    return new Promise((resolve, reject) => {
      CameraPreview.takePicture(pictureOptions, (base64PictureData: string | string[]) => {
        const imageSrcData = 'data:image/png;base64,' + base64PictureData;
        resolve(imageSrcData);
        },
        (error: any) => {
          console.error('Error capturing picture: ', error);
          reject(error);
        },
        // CameraPreview.getThreadPool()
      );
    });
  }


  closeCamera(): void {
    CameraPreview.stopCamera();
    CameraPreview.hide(); // This will hide the camera preview from the screen
  }


  switchCamera(): void {
    CameraPreview.switchCamera()
      .then(() => {
        console.log('Camera switched successfully.');
      })
      .catch((error: any) => {
        console.error('Error switching the camera: ', error);
      });
  }
}
