import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { AppService } from '../app.service';
import { DataService } from 'src/api-calls/data.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-block-pop',
  templateUrl: './block-pop.component.html',
  styleUrls: ['./block-pop.component.scss']
})
export class BlockPopComponent implements OnInit {
  languageCode: string;
  clinica: boolean;
  showreason: boolean;

  constructor(public dialogRef: MatDialogRef<BlockPopComponent>, public app: AppService,
    @Inject(MAT_DIALOG_DATA) public data, public dialog: MatDialog, private translate: TranslateService, public service: DataService) { }

  public items: any = [];
  selectopt = "Cancellation";
  dropData: any = []
  dropData2: any = []
  reportid;
  comment;
  subBoolean: boolean = true;
  commentData: boolean = false;
  buttonClick = "add";
  buttonClick1 = "addmark";
  chartType = [{ id: 1, name: "BarChart" },
  { id: 2, name: "TableChart" },
  { id: 3, name: "LineChart" },
  { id: 4, name: "AreaChart" }
  ]
  reportGetid;
  dataSetid;
  public bar_ChartData = [];
  public bar_ChartOptions = {
    title: '',
    chartArea: { width: '50%' }
  };
  chartId;
  chartview: boolean = false;
  reasons: any = [];
  appConfig: any;
  showComplete: string;
  checkPoints: any = {}
  subCheckPoints: any = [];
  subId;
  noCheck: boolean = false;
  date1: any;
  date2: any;
  async ngOnInit() {
    const uservalString: string = localStorage.getItem('config');
    const userval: any[] = JSON.parse(uservalString);
    userval.map(res => {
      if (res.shortCdConst == "CLNQENBL") {
        if(res.userValue == "true"){
          this.showreason = false;
        }else{
          this.showreason = true;
        }
      }
    });
    this.languageCode = localStorage.getItem('lang');
    this.appConfig = JSON.parse(localStorage.getItem('config'));
    let x = this.appConfig.find(res => res.shortCdConst == "TOTOP");
    this.showComplete = x.userValue;
    if (this.data == 7 || this.data == 6 || this.data == 11) {
      this.commentData = true;
    }
    if (this.data == 3 || this.data == 7 || this.data == 6 || this.data == 11 || this.data == 15) {
      this.subCheckPoints = [];
      if (sessionStorage.getItem('noDrop') == 'true') {
        this.noCheck = false;
        this.checkPoints = await this.service.getCheckpointById(sessionStorage.getItem('checkId')).toPromise();
        if (this.checkPoints.subCheckpoints) {
          this.subCheckPoints = this.checkPoints.subCheckpoints;
          this.subCheckPoints = this.subCheckPoints.filter(res => { return res.available });
          if (this.subCheckPoints.length < 1) {
            this.subBoolean = false;
          }
        }
      }
      if (sessionStorage.getItem('noDrop') == 'false') {
        this.noCheck = true;
        if (this.data == 19) {
          this.noCheck = false;
        }
        this.checkPoints = await this.service.getCheckpointById(sessionStorage.getItem('checkId')).toPromise();
        if (this.checkPoints.subCheckpoints) {
          this.subCheckPoints = this.checkPoints.subCheckpoints;
          this.subCheckPoints = this.subCheckPoints.filter(res => { return res.available });
          if (this.subCheckPoints.length < 1) {
            this.subBoolean = false;
          }
          else {
            this.subCheckPoints.sort(function (a, b) {
              var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
              if (nameA < nameB) { return -1; }
              if (nameA > nameB) { return 1; }
              return 0;
            })
          }
        }
      }
    }
    if (this.chartview == true) {
    }

    if (this.data == 4) {
      this.dropData = await this.service.getReport().toPromise()

    }
    if (this.data == 1) {
      var len = this.app.popData.length;
      if (len > 0) {
        this.items = this.app.popData;
        this.items.map(res => Object.assign(res, { icon: 'ion-ios-close-circle' }))
        this.items[this.items.length - 1].icon = 'ion-ios-add-circle';
      }
      else {
        this.items.push({ blockStartTime: "", blockEndTime: "", icon: 'ion-ios-add-circle' });
      }
    }
    if (this.data == 20) {
      var len = this.app.popMob.length;
      if (len > 0) {
        // this.app.popMob.map(res=>{
        //   // Object.assign(res, { icon: 'ion-ios-close-circle' })
        //   var len = res.length;
        //   len = len - 10;
        //   res.countryNum = res.slice(0, len);
        //   res.mobile = res.notiMobileNbr.slice(-10);
        // })
        this.items = this.app.popMob;
        this.items.map(res => Object.assign(res, { icon: 'ion-ios-close-circle' }))
        this.items[this.items.length - 1].icon = 'ion-ios-add-circle';
      }
      else {
        this.items.push({ countryNum: "+91", mobile: "", type: "1", icon: 'ion-ios-add-circle' });
      }
    }
    if (this.data == 2) {
      var len = this.app.popSub.length;
      if (len > 0) {
        this.items = this.app.popSub;
        this.items.map(res => Object.assign(res, { icon: 'ion-ios-close-circle' }))
        this.items[this.items.length - 1].icon = 'ion-ios-add-circle';
      }
      else {
        this.items.push({ name: "", description: "", icon: 'ion-ios-add-circle' });
      }
    }
    if (this.data == 8) {
      var len = this.app.shiftData.length;
      if (len > 0) {
        this.items = this.app.shiftData;
        this.items.map(res => Object.assign(res, { icon: 'ion-ios-close-circle' }))
        this.items[this.items.length - 1].icon = 'ion-ios-add-circle';
      }
      else {
        this.items.push({ startTime: "", endTime: "", maxTokens: "", icon: 'ion-ios-add-circle' });
      }
    }
    if (this.data == 3 || this.data == 7 || this.data == 11 || this.data == 15) {
      this.reasons = ["cancellation"];
      // this.app.forceStartRea = await this.service.staticForFStart().toPromise();
      // this.app.forceRemoveRea = await this.service.staticForFRemove().toPromise();
    }
    if (this.data == 6 || this.data == 19) {
      this.reasons = ["cancellation"];
      // this.app.forceRemoveRea = await this.service.staticForFRemove().toPromise();
    }
  }
  addPush(data, i) {
    if (this.data == 1) {
      if (data.icon === "ion-ios-close-circle") {
        this.items.splice(i, 1);
      }
      else {
        this.items[i].icon = "ion-ios-close-circle";
        this.items.push({ blockStartTime: "", blockEndTime: "", icon: 'ion-ios-add-circle' });
      }
    }
    if (this.data == 20) {
      if (data.icon === "ion-ios-close-circle") {
        this.items.splice(i, 1);
      }
      else {
        this.items[i].icon = "ion-ios-close-circle";
        this.items.push({ countryNum: "+91", mobile: "", type: "1", icon: 'ion-ios-add-circle' });
      }
    }

    if (this.data == 2) {
      if (data.icon === "ion-ios-close-circle") {
        this.items.splice(i, 1);
      }
      else {
        this.items[i].icon = "ion-ios-close-circle";
        this.items.push({ blockStartTime: "", blockEndTime: "", icon: 'ion-ios-add-circle' });
      }
      // this.items.push({ name: "", description: "", icon: 'ion-ios-add-circle' });
    }
    if (this.data == 8) {
      if (data.icon === "ion-ios-close-circle") {
        this.items.splice(i, 1);
      }
      else {
        this.items[i].icon = "ion-ios-close-circle";
        this.items.push({ blockStartTime: "", blockEndTime: "", icon: 'ion-ios-add-circle' });
      }
      // this.items.push({ startTime: "", endTime: "", maxTokens: "", icon: 'ion-ios-add-circle' });
    }
  }

  add() {
    if (this.data == 1) {
      this.items = this.items.filter(res => res.blockStartTime != "");
      this.items.map(res => {
        if (res.blockStartTime.length > 8) {
          res.add = true;
          res.blockStartTime = res.blockStartTime.slice(-18, -13);
          res.blockEndTime = res.blockEndTime.slice(-18, -13);
        }
      })
      this.app.popData = this.items;
    }
    if (this.data == 2) {
      this.items = this.items.filter(res => res.dataN != "");
      this.items.map(res => {
        res.add = true;
      })
      this.app.popSub = this.items;
    }
    if (this.data == 20) {
      this.items = this.items.filter(res => res.mobile != "");
      this.app.popMob = this.items;
    }
    if (this.data == 8) {
      this.items = this.items.filter(res => res.startTime != "");
      this.items.map(res => {
        if (res.startTime.length > 8) {
          res.add = true;
          res.startTime = res.startTime.slice(-18, -13);
          res.endTime = res.endTime.slice(-18, -13);
          res.maxTokens = res.maxTokens;
        }
      })
      this.app.shiftData = this.items;
    }
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close('cancel');
  }


  openDialog(id): void {
    sessionStorage.setItem('getreportid', this.reportGetid)
    const dialogRef = this.dialog.open(BlockPopComponent, {
      data: id
    });
    this.dialogRef.close();
  }

  async getDrop2(i) {
    this.reportid = i
    this.dropData2 = (this.dropData[this.reportid].dataSets);
    this.reportGetid = this.dropData[this.reportid].id
  }
  async preview() {
    //  this.reportGetid
    //  this.chartId
    // if (this.dataSetid != undefined) {
    //   sessionStorage.setItem('datasetId', this.dataSetid)
    // }
    this.chartview = true;
    // this.chartId = sessionStorage.getItem('chartid')
    this.bar_ChartData = [];
    let post = {};
    if (this.dataSetid) {
      Object.assign(post, { dataSetId: this.dataSetid });
    }
    else {
      Object.assign(post, { dataSetId: this.reportGetid });
    }
    var today = new Date();
    this.date1 = today.getFullYear() + '/' + ("0" + (today.getMonth() + 1)).slice(-2) + '/' + ("0" + today.getDate()).slice(-2) + ' ' + '00:00:00'
    this.date2 = today.getFullYear() + '/' + ("0" + (today.getMonth() + 1)).slice(-2) + '/' + ("0" + today.getDate()).slice(-2) + ' ' + '23:59:59'

    let data: any = await this.service.getDatabox1(post, this.date1, this.date2).toPromise();
    this.bar_ChartOptions.title = data.name;

    this.bar_ChartData.push(data.legends);
    this.bar_ChartData = this.bar_ChartData.concat(data.dataValues);
  }
  apply() {
    var post = {
      chartType: this.chartId
    }
    if (this.dataSetid) {
      Object.assign(post, { id: this.dataSetid });
    }
    else {
      Object.assign(post, { id: this.reportGetid });
    }
    this.service.postChartType(post).toPromise().then(res => {
      // this.app.openSnackBar('Chart Added SuccessFully', 'success-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Chart Added SuccessFully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })
      this.app.openPage('dashboard');
      this.dialogRef.close();
    }).catch(
      err => { }
    );
  }
  saveComment() {
    if (this.subId == undefined && this.subCheckPoints.length > 0 && this.noCheck && this.data != 7 && this.data != 11) {
      // this.app.openSnackBar('Please select the SubCheckpoint', 'error-snackbar')  
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please select the SubCheckpoint").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    } else if (this.selectopt == undefined) {
      // this.app.openSnackBar('Please select the reason', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please select the reason").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    } else {
      this.app.subCheckpointIdP = this.subId;

      if (this.data == 3) {
        if (this.comment == undefined) {
          this.app.comments = {
            forceStartReason: this.selectopt
          }
        }
        else {
          this.app.comments = {
            // forceStartReason: this.selectopt,
            forceStartComments: this.comment
          }
        }
        this.dialogRef.close(1);
      }
      if (this.data == 11) {
        if (this.comment == undefined) {
          this.app.comments = {
            forceStartReason: this.selectopt
          }
        }
        else {
          this.app.comments = {
            // forceStartReason: this.selectopt,
            forceStartComments: this.comment
          }
        }
        this.dialogRef.close(99);
      }
      if (this.data == 15) {
        if (this.comment == undefined) {
          this.app.comments = {
            forceStartReason: this.selectopt
          }
        }
        else {
          this.app.comments = {
            // forceStartReason: this.selectopt,
            forceStartComments: this.comment
          }
        }
        this.dialogRef.close(15);
      }
      if (this.data == 6) {
        if (this.comment == undefined) {
          this.app.comments = {
            forceRemoveReason: this.selectopt
          }
        }
        else {
          this.app.comments = {
            // forceRemoveReason: this.selectopt,
            forceRemoveComments: this.comment
          }
        }
        this.dialogRef.close(2);
      }
      if (this.data == 19) {
        if (this.comment == undefined) {
          this.app.comments = {
            forceRemoveReason: this.selectopt
          }
        }
        else {
          this.app.comments = {
            // forceRemoveReason: this.selectopt,
            forceRemoveComments: this.comment
          }
        }
        this.dialogRef.close(11);
      }
      if (this.data == 7) {
        if (this.comment == undefined) {
          this.app.comments = {
            forceStartReason: this.selectopt
          }
        }
        else {
          this.app.comments = {
            // forceStartReason: this.selectopt,
            forceStartComments: this.comment
          }
        }
        this.dialogRef.close(3);
      }
      this.commentData = false;
    }


  }
  moveToQ(id) {
    if (id == 1) {
      this.app.moveBackToQ = false;
      this.buttonClick = "add1";
      this.buttonClick1 = "addmark";
    }
    if (id == 2) {
      this.app.moveBackToQ = true;
      this.buttonClick1 = "add2"
      this.buttonClick = "add"
    }
    if (this.data == 17) {
      this.commentData = false;
    }
    if (this.data == 7) {
      this.dialogRef.close(3);
    }
    if (this.data != 7 && this.data != 17) {
      this.commentData = true;
    }

  }
  saveSubDisable() {
    this.dialogRef.close(17);
  }


}
