import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { interval } from 'rxjs';
import { MatDialog } from '@angular/material';
import { AuthService } from 'src/api-calls/auth.service';
import { BlockPopComponent } from '../block-pop/block-pop.component';
import { AssistancepopComponent } from '../assistancepop/assistancepop.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-work-q',
  templateUrl: './work-q.component.html',
  styleUrls: ['./work-q.component.scss']
})

export class WorkQComponent implements OnInit, OnDestroy {
  items: any = [];
  checkPoint: any = 0;
  subCheckPointId = 0;
  subCheckPointId1;
  workq: any = [];
  selectWorkq: any = [];
  selectWorkq5: any = [];
  selectWorkq1: any = [];
  selectWorkqFirst: any = [];
  selectWorkqRem: any = [];
  workFlowId;
  add;
  checkPointName: any;
  msgContent: string = "Select checkpoint to display content";
  firstMsg: string;
  idle = 0;
  disable: boolean = false;
  subCheckPoints: any = [];
  enableNext: boolean = false;
  missedData: any = [];
  skippedData: any = [];
  ms;
  indexValue: any;
  cloneIndex;
  loader: boolean = false;
  loader1: boolean = true;
  url;
  mis: boolean = true;
  viewcheckpt: boolean = false;
  public getmisent: boolean = false;
  public markmis: boolean = false;
  public nextpat: boolean = false;
  showToken: string;
  subCheckPoint;
  wheeled: any = [];
  callRequired: any = [];
  internationalPatient: any = [];
  aged: any = [];
  inPatient: any = [];
  mpatient: any = []
  filterId;
  filterEnable: boolean = false;
  selectType: boolean;
  remainingSub: any = [];
  newSub;
  nriPatient:any=[];
  languageCode: string;

  constructor(public data: DataService, private translate: TranslateService, public app: AppService, public dialog: MatDialog, public auth: AuthService) {
    interval(15000).subscribe(() => {
      if (this.url == "workq") {
        if (this.checkPoint != 0)
          this.check5()
      }
      var url1 = this.app.sendUrl()
      if (url1.includes('workque')) {
        if (this.checkPoint != 0)
          this.check5()
      }
    })
  }
  show: string = 'false';
  async ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.app.showFull = false;
    this.url = window.location.href.split('/').pop();
    await this.data.getCheckpoints().toPromise().then(res => {
      this.loader1 = false;
      this.items = res;
      if (this.items.length > 1) {
        this.items.sort(function (a, b) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
          if (nameA < nameB) { return -1; }
          if (nameA > nameB) { return 1; }
          return 0;
        })
      }
    })
    var url = this.app.sendUrl()
    if (url.includes('workque')) {
      var id = this.url.split('/').pop()
      this.checkPoint = id
      this.check()
    }
    let x = JSON.parse(localStorage.getItem('config'));
    let y = x.find(res => res.shortCdConst == "SCAPL");
    this.show = y.userValue;
    let a = x.find(res => res.shortCdConst == "TKNAUTO");
    this.showToken = a.userValue;
    let b = x.find(res => res.shortCdConst == "SSCHK");
    this.newSub = b.userValue;
    if (this.newSub == 'true') {
      this.selectType = false;
    }
    if (this.newSub == 'false') {
      this.selectType = true;
    }
  }
  ngDoCheck(): void {
    if (localStorage.getItem('capability')) {
      let data: any = JSON.parse(localStorage.getItem('capability'));
      if (data.length > 0) {
        data.map(res => {
          if (res.includes('GETMISSEDENT')) {
            this.getmisent = true;
          }
          if (res.includes('MRKASMISSED')) {
            this.markmis = true;
          }
          if (res.includes('NXTENTITY')) {
            this.nextpat = true;
          }
        })
      }
    }
  }
  async check() {
    this.loader = true;
    this.msgContent = "";
    this.subCheckPoints = [];
    this.disable = false;
    this.app.workQData = '';
    this.app.moveBackToQ = false;
    this.app.checkPointName = '';
    this.selectWorkqFirst = '';
    this.selectWorkqRem = [];
    this.missedData = [];
    this.skippedData = [];
    this.wheeled = [];
    this.aged = [];
    this.callRequired = [];
    this.mpatient = [];
    this.remainingSub = [];
    if (this.checkPoint != undefined) {
      if (this.selectType) {
        sessionStorage.setItem("checkId", this.checkPoint);
        this.subCheckPointId = 0;
        sessionStorage.setItem("noDrop", 'false');
      }
      else {
        sessionStorage.setItem("noDrop", 'true');
      }
      this.subCheckPoints = await this.data.getCheckpointById(this.checkPoint).toPromise();
      var workqid = this.subCheckPoints.worq.id
      if(this.subCheckPoints.subCheckpoints){
      this.subCheckPoints = this.subCheckPoints.subCheckpoints;
      }
      else{
        this.subCheckPoints =[];
        this.remainingSub =[];
      }
      this.missedData = await this.data.getMissed(workqid).toPromise();
      this.skippedData = await this.data.getSkipped(workqid).toPromise();
      if (this.selectWorkq) {
        this.selectWorkq = await this.data.getWorkqById(workqid).toPromise();
        this.checkPointName = this.items.filter(res => res.id == this.checkPoint);
        this.checkPointName = this.checkPointName[0];
        this.app.checkPointName = this.checkPointName;
      }
    }
    this.loader = false;
    this.workFlowId = this.selectWorkq.id;
    if (this.selectWorkq.entities != null) {

      this.selectWorkq = this.selectWorkq.entities.sort((a, b) => { return a.positionInQ - b.positionInQ });
      this.selectWorkqFirst = this.selectWorkq.filter(res => { return res.positionInQ == 0 });
      if (this.subCheckPoints.length > 0) {
        this.selectWorkqFirst.sort(function (a, b) {
          var nameA = a.subCheckpoint.name.toLowerCase(), nameB = b.subCheckpoint.name.toLowerCase();
          if (nameA < nameB) { return -1; }
          if (nameA > nameB) { return 1; }
          return 0;
        })
        this.subCheckPoints.sort(function (a, b) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
          if (nameA < nameB) { return -1; }
          if (nameA > nameB) { return 1; }
          return 0;
        })
      }
      this.selectWorkqRem = this.selectWorkq.filter(res => { return res.positionInQ > 0 })
      let a: any = this.selectWorkq.filter(res => { return res.positionInQ > 0 });
      this.wheeled = a.filter(res => { return res.wheeled == true })
      this.aged = a.filter(res => { return res.aged == true })
      this.callRequired = a.filter(res => { return res.callRequired == true })
      this.nriPatient = a.filter(res => { return res.internationalPatient == true })
      this.inPatient = a.filter(res => { return res.entityType == 2 })
      this.mpatient = a.filter(res => { return res.entityType == 3 })
      this.selectWorkqFirst.map(res => {
        this.subCheckPoint = res.subCheckpoint
      })
      if (this.subCheckPointId > 1) {
        if (!this.selectType) {
          this.selectWorkqFirst = this.selectWorkqFirst.filter(res => {
            if (res.subCheckpoint) {
              return res.subCheckpoint.id == this.subCheckPointId
            }
          })
        }
      }
      if (this.selectType) {
        if (this.subCheckPoints != undefined) {
          if (this.subCheckPoints.length > 0) {
            this.remainingSub = this.subCheckPoints.filter(o1 => {
              return !this.selectWorkqFirst.some(o2 => {
                return o1.id === o2.subCheckpoint.id;
              })
            });
          }
        }
      }
    }
    if (this.selectWorkqFirst) {
      this.firstMsg = "";
    }
    else {
      this.firstMsg = "No current patient";
    }
    this.app.workQData = workqid;
    if (this.selectWorkqFirst || this.selectWorkqRem.length > 0) {
      this.msgContent = "";
    }
    else {
      this.msgContent = "No Patients in Queue";
    }
  }
  fullScreen() {
    this.app.showFull = true;
    setTimeout(() => {
      var show = document.getElementById('fuller')
      show.requestFullscreen();
    }, 10);
  }
  async forceDelete(data) {
    if (this.selectType) {
      this.subCheckPointId = this.app.subCheckpointIdP;
    }
    if (this.subCheckPoints.length > 0 && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
    }
    else {
      await this.data.forceRemove(this.workFlowId, data.id, this.subCheckPointId, this.app.comments).toPromise()
        .then((res: any) => {
          this.check();
        });
    }
  }
  async forceDeleteMissed(data) {
    if (this.selectType) {
      this.subCheckPointId = this.app.subCheckpointIdP;
    }
    if (this.subCheckPoints.length > 0 && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
    }
    else {
      await this.data.forceRemoveMissed(this.workFlowId, data.id, this.subCheckPointId, true).toPromise()
        .then((res: any) => {
          this.check();
        });
    }
  }
  async forceStart(data) {
    if (this.selectType) {
      this.subCheckPointId = this.app.subCheckpointIdP;
    }
    if (this.subCheckPoints.length > 0 && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
    }
    else {
      await this.data.forceStart(this.workFlowId, data.id, this.subCheckPointId, false, this.app.comments, this.app.moveBackToQ).toPromise()
        .then((res: any) => {
          this.check();
          this.disable = true;
        });
    }
  }
  async forceWait(data) {
    if (this.subCheckPoints && this.subCheckPointId == null) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
    }
    else {
      await this.data.forceWait(this.workFlowId, data.id, this.subCheckPointId).toPromise()
        .then((res: any) => {
          this.check();
        });
    }
  }
  async missed(data) {
    if (this.selectType) {
      if (data.subCheckpoint) {
        this.subCheckPointId = data.subCheckpoint.id;
      }
      else {
        this.subCheckPointId = 0;
      }
    }
    if (this.subCheckPoints.length > 0 && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
    }
    else {
      this.loader1 = true;
      await this.data.missed(this.workFlowId, data.id, this.subCheckPointId).toPromise()
        .then((res: any) => {
          this.loader1 = false;
          this.check();
        })
        .catch(err => {
          this.loader1 = false;
        })

    }
  }
  next(id) {
    if (this.selectType) {
      if (this.subCheckPoints.length > 0 && this.subCheckPointId == 0) {
        this.subCheckPointId = id;
      }
      if (id == undefined) {
        this.subCheckPointId = 0;
      }
    }
    this.loader1 = true;
    this.disable = false;
    this.enableNext = false;
    if (this.subCheckPoints.length > 0 && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
      this.loader1 = false;
    }
    else {
      this.data.nextQ(this.workFlowId, this.subCheckPointId,null)
        .subscribe(res => {
          this.enableNext = true;
          this.disable = false;
          this.loader1 = false;
          this.check();
        },
          err => {
            this.loader1 = false;
          }
        )
    }
  
  }
  @HostListener('click', ['$event'])
  myMethod(e) {
    if (e.target.id.includes("more-")) {
      this.indexValue = e.target.id.split("-").pop();
      this.cloneIndex = this.indexValue;
      document.getElementById('drop-' + this.indexValue).setAttribute('style', 'display:grid');
    }
    else if (this.cloneIndex != undefined && this.cloneIndex != null) {
      document.getElementById('drop-' + this.cloneIndex).setAttribute('style', 'display:none');
      this.cloneIndex = null
    }
  }
  @HostListener('fullscreenchange', ['$event']) screenChange(e) {
    if (this.idle == 0) {
      this.app.showFull = true;
      this.idle = 1;
    }
    else {
      this.app.showFull = false;
      this.idle = 0;
    }
  }
  check3() {
    this.subCheckPointId = 0;
  }
  check2() {
    this.check();
  }
  async forceStart1(data) {
    if (this.selectType) {
      this.subCheckPointId = this.app.subCheckpointIdP;
    }
    if (this.subCheckPoints.length > 0 && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
    }
    else {
      await this.data.forceStart(this.workFlowId, data.id, this.subCheckPointId, true, this.app.comments, this.app.moveBackToQ).toPromise()
        .then((res: any) => {
          this.check();
          this.disable = true;
        });
    }
  }
  Popup(id, data): void {
    if (this.subCheckPoints.length > 0 && this.subCheckPointId == 0 && !this.selectType) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
    }
    else {
      if (this.selectWorkqFirst.length > 0 || id == 6 || id == 7 || id == 11) {
        const dialogRef = this.dialog.open(BlockPopComponent, {
          data: id
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result != "cancel") {
          }
          if (result == 1) {
            this.forceStart(data);
          }
          if (result == 2) {
            this.forceDelete(data);
          }
          if (result == 3) {
            this.forceStart1(data);
          }
        });
      }
      if (this.selectWorkqFirst.length == 0 && id == 3) {
        const dialogRef = this.dialog.open(BlockPopComponent, {
          data: 15
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result != "cancel") {
          }
          if (result == 15) {
            this.forceStart(data);
          }
        });
      }
    }
  }
  ngOnDestroy(): void {
    this.url = "";
    this.checkPoint = 0;
  }
  async check5() {
    if (this.checkPoint != undefined) {
      this.subCheckPoints = await this.data.getCheckpointById(this.checkPoint).toPromise();
      var workqid = this.subCheckPoints.worq.id
      if(this.subCheckPoints.subCheckpoints){
      this.subCheckPoints = this.subCheckPoints.subCheckpoints;
      }
      else{
        this.subCheckPoints = [];
        this.remainingSub =[];
      }
      this.missedData = await this.data.getMissed(workqid).toPromise();
      this.skippedData = await this.data.getSkipped(workqid).toPromise();
      if (this.selectWorkq) {
        this.selectWorkq5 = await this.data.getWorkqById(workqid).toPromise();
        this.checkPointName = this.items.filter(res => res.id == this.checkPoint);
        this.checkPointName = this.checkPointName[0];
        this.app.checkPointName = this.checkPointName;
      }
    }
    this.workFlowId = this.selectWorkq5.id;
    if (this.selectWorkq5.entities != null) {
      this.selectWorkq5 = this.selectWorkq5.entities.sort((a, b) => { return a.positionInQ - b.positionInQ });
      let a: any = this.selectWorkq5.filter(res => { return res.positionInQ > 0 });
      this.wheeled = a.filter(res => { return res.wheeled == true })
      this.aged = a.filter(res => { return res.aged == true })
      this.callRequired = a.filter(res => { return res.callRequired == true })
      this.nriPatient = a.filter(res => { return res.internationalPatient == true })
      this.inPatient = a.filter(res => { return res.entityType == 2 })
      this.mpatient = a.filter(res => { return res.entityType == 3 })
      if (this.selectWorkq5 != this.selectWorkq) {
        this.selectWorkqFirst = this.selectWorkq5.filter(res => { return res.positionInQ == 0 });
        if (this.subCheckPoints.length > 0) {
          this.selectWorkqFirst.sort(function (a, b) {
            var nameA = a.subCheckpoint.name.toLowerCase(), nameB = b.subCheckpoint.name.toLowerCase();
            if (nameA < nameB) { return -1; }
            if (nameA > nameB) { return 1; }
            return 0;
          });
          this.subCheckPoints.sort(function (a, b) {
            var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
            if (nameA < nameB) { return -1; }
            if (nameA > nameB) { return 1; }
            return 0;
          })
        }
        this.selectWorkqRem = this.selectWorkq5.filter(res => { return res.positionInQ > 0 });
        if (this.subCheckPointId > 1) {
          if (!this.selectType) {
            this.selectWorkqFirst = this.selectWorkqFirst.filter(res => {
              if (res.subCheckpoint) {
                return res.subCheckpoint.id == this.subCheckPointId
              }
            })
          }
        }
        if (this.selectType) {
          if (this.subCheckPoints != undefined) {
            if (this.subCheckPoints.length > 0) {
              this.remainingSub = this.subCheckPoints.filter(o1 => {
                return !this.selectWorkqFirst.some(o2 => {
                  return o1.id === o2.subCheckpoint.id;
                })
              });
            }
          }
        }
        this.filter(this.filterId);
      }
    }
    if (this.selectWorkqFirst) {
      this.firstMsg = "";
    }
    else {
      this.firstMsg = "No current patient";
    }
    this.app.workQData = workqid;
    if (this.selectWorkqFirst || this.selectWorkqRem.length > 0) {
      this.msgContent = "";
    }
    else {
      this.msgContent = "No Patients in Queue";
    }
  }
  change(id) {
    id == 1 ? this.mis = true : this.mis = false;
  }
  filter(id) {
    this.filterId = id;
    if (id == 1) {
      this.filterEnable = true;
      this.selectWorkqRem = this.callRequired;
    }
    if (id == 2) {
      this.filterEnable = true;
      this.selectWorkqRem = this.wheeled;
    }
    if (id == 3) {
      this.filterEnable = true;
      this.selectWorkqRem = this.aged;
    }
    if (id == 4) {
      this.filterEnable = true;
      this.selectWorkqRem = this.inPatient;
    }
    if (id == 5) {
      this.filterEnable = false;
      this.selectWorkqRem = this.selectWorkq.filter(res => { return res.positionInQ > 0 })
    }
    if (id == 6) {
      this.filterEnable = true;
      this.selectWorkqRem = this.mpatient
    }
    if (id == 7) {
      this.filterEnable = true;
      this.selectWorkqRem = this.nriPatient
    }

  }
  edit(data) {
    this.app.openPage('patientEdit/' + data.id);
  }
  quickCheckIn(id){
    if (this.checkPoint != undefined && this.checkPoint !=0) {
      sessionStorage.setItem('checkPointId', this.checkPoint)
      const dialogRef = this.dialog.open(AssistancepopComponent, {
        data: id
      });
    }
    else{
      // this.app.openSnackBar('Please Select a Checkpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a Checkpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
    }

  }
  async subCheckDisable(id,id1){
    if(id1==0){
    let subCheckObject:any={}
    subCheckObject = await this.data.getCheckpointById(id).toPromise();
    subCheckObject.available=!subCheckObject.available;
    this.data.updateCheckpointsById(id,subCheckObject).toPromise()
    .then((res) => {
      this.check();
    })
    .catch(
      err => {

      }
    )
    }
    if(id1!=0){
      const dialogRef = this.dialog.open(BlockPopComponent, {
        data: 17
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == 17) {
           this.data.subCheckCurrentStart(this.workFlowId, id1, id, false, this.app.comments, this.app.moveBackToQ).toPromise()
          .then((res: any) => {
             this.remove(id);
           
            this.disable = true;
          });
        
        }
    })

  }
}
async remove(id){
  let subCheckObject:any={}
  subCheckObject = await this.data.getCheckpointById(id).toPromise();
  subCheckObject.available=!subCheckObject.available;
  this.data.updateCheckpointsById(id,subCheckObject).toPromise()
  .then((res) => {
    this.check();
  })
  .catch(
    err => {

    }
  )
}

 
  // done(data) {
  //   if (this.subCheckPoints && this.subCheckPointId == null) {
  //     this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')

  //   }
  //   else {
  //     this.data.workqCompleted(this.workFlowId, data.id, this.subCheckPointId).toPromise()
  //       .then(res => {

  //         this.enableNext = false;
  //         this.check();
  //         this.app.openSnackBar('Completed Successfully', 'success-snackbar');

  //       })
  //       .catch();
  //   }
  // }
  // start(data) {
  //   if (this.subCheckPoints && this.subCheckPointId == null) {
  //     this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
  //   }
  //   else {
  //     this.data.startQ(this.workFlowId, data.id, this.subCheckPointId).subscribe(res => {
  //       this.check();
  //       this.disable = true;
  //       this.enableNext = false;
  //     })
  //   }

  // }

}
