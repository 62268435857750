import { CompileShallowModuleMetadata } from '@angular/compiler';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-popper1',
  templateUrl: './popper1.component.html',
  styleUrls: ['./popper1.component.scss']
})
export class Popper1Component implements OnInit {
  // @ViewChild('cancelBtn') cancelButton!: ElementRef;

  item1: any;
  item2: any;
  items: any;
  dosage;
  search: any = { name: '' };
  pitem: any;
  pickers1: any;
  list: any = [
    { name: "Suggested", style: "none", id: 0 },
    { name: "Medicine Group", style: "none", id: 1 },
  ];
  isDivClicked = false;
  divClass = 'pop3-frequency';
  food:any=[
    {name:"Morning", style:"none", id:'f1'},
    {name:"Morning", style:"none", id:'f2'},
    {name:"Morning", style:"none", id:'f3'}

  ]
  f1: any;
  f2: any;
  f3: any;
  selectedClass = 'pop3-frequency-selected';
  bf: boolean = false;
  bf1: boolean = false;
  days: any = 3;
  summary: any;

  input2: any;

  mlist: any = [];

  medicineId: any;
  beforeFoodInd: any;

  msg: any;
  medicines: any = [];
  medicineName: any;
  // medArray:any=[];
  inTakeQty: any = [{
    id: 1, qty: 0.5,
    select: false
  },
  {
    id: 1, qty: 1,
    select: false
  },
  {
    id: 1, qty: 1.5,
    select: false
  },
        {
    id: 1, qty: 2,
    select: false
  }]

  dose: any = [{ id: 1, dose: 250, select: false }, { id: 1, dose: 500, select: false }, { id: 1, dose: 550, select: false }, { id: 1, dose: 650, select: false },]
  dosageArray: any = [];
  languageCode: string;
  medicinesSuggesting: any;
  suggesedInd: any;
  loader: boolean;
  showSuggestedCards: any;
  allmedicineGroup: any;
  openpopupOfGroupmed: boolean;
  groupName: any;
  descripTion: any;
  shortCode: any;
  groupMedicine: any = [];
  allMedicine: any[] = [];
  getmedicineId: any;
  getmedicineName: any;
  showDiv = 0;
  sugguesting: boolean;
  medicening: boolean;
  showingDiv: any;
  isInputHighlighted: boolean;
  medDosage: any;
  matchingMedicine: any;
  showmedicineinput: boolean = false;
  selectmedicineId: string;
  totalMedicine: any;
  dailyDos: any;
  qty: any;
  inTakeQtys: any =[];

  constructor(public dialogRef: MatDialogRef<Popper1Component>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    public service: DataService,
    private translate: TranslateService,
    public app: AppService) { }

  async ngOnInit() {
    this.loader = true;
    this.sugguesting = true;
    this.medicening = false;
    this.listCheck();
    this.languageCode = localStorage.getItem('lang');
    this.service.getMedicine().subscribe((res: any) => {
      this.totalMedicine = res;
      this.medicinesSuggesting = res.filter((medicine: any) => medicine.suggestInd === true);
      // this.medDosage = this.medicinesSuggesting[0].medDosages;
      this.getmed()
    });
    // this.service.getFrequentlyUsed().subscribe(res => {
    //   var x;
    //   x = res;
    //   x.map(res => {
    //     Object.assign(res, { select: false })
    //   })
    //   this.medicines = x.filter(res => { return res.id == 9 || res.id == 10 || res.id == 2 || res.id == 5 });
    // })


    // var x ={
    //     healthCareCenterSearchCriteria:{
    //         healthCenterTypeId:3
    //     }
    // }
    // var healthCenterId = 1
    // this.item2 = await this.service.searchCenter(x).toPromise();
    // this.pitem = await this.service.preferMedicine(healthCenterId).toPromise();
    if (sessionStorage.getItem('data1')) {
      this.input2 = sessionStorage.getItem('data1');

    }
  }

  displayFn(item: any): string {
    return item ? item.name : '';
  }

  getmed() {
    let post = {
      orgId: localStorage.getItem('organizationId'),
    }
    this.service.searchmedgroupDetails(post).subscribe(res => {
      this.allmedicineGroup = res;
      this.loader = false;
    })
  }

  listCheck() {
    this.list.map((res, i) => {
      if (this.showDiv == res.id) res.style = "activing";
      else res.style = "none";
    });
  }

  checkBox(id: number) {
    this.showDiv = id;
    this.listCheck();
    if (id == 0) {
      this.sugguesting = true;
      this.medicening = false;
      this.openpopupOfGroupmed = false;
      this.groupMedicine = "";
      this.showingDiv = "";
      this.allmedicineGroup.map((res, i) => {
        if (this.showingDiv == res.id) {
          Object.assign(res, { style: "activing" });
        }
        else {
          Object.assign(res, { style: "none" });
        }
      });
    }
    if (id == 1) {
      this.sugguesting = false;
      this.medicening = true;
    }
  }

  suggestClick(itemId: string) {
    this.selectmedicineId = itemId;
    this.medicinesSuggesting.map(res => {
      if (res.id == this.selectmedicineId) {
        this.medicineName = res.name;
        this.medicineId = res.id;
        this.medDosage = res.medDosages;
        this.isInputHighlighted = true;
      }
    })
  }

  medicineClick(itemId: string) {
    this.selectmedicineId = itemId;
    this.allMedicine.map(res => {
      if (res.id == this.selectmedicineId) {
        this.medicineName = res.name;
        this.medicineId = res.id;
        this.medDosage = res.medDosages;
        this.isInputHighlighted = true;
      }
    })
  }

  medicineparSelect(selectedItemid: any): void {
    console.log('Selected item:', selectedItemid);
    this.totalMedicine.map(res => {
      if (res.id == selectedItemid) {
        this.medicineName = res.name;
        this.medicineId = res.id;
        this.medDosage = res.medDosages;
        console.log(this.medDosage);
      }
    })
    console.log(this.totalMedicine)
  }

  medicineSelect1(data) {
    this.medDosage.map(res => {
      Object.assign(res, { select: false })
    })
    data.select = !data.select;
    this.dosageArray = this.medDosage.filter(res => { return res.select });
  }

  medicineSelect12(data) {
    this.inTakeQty.map(res => {
      Object.assign(res, { select: false })
    })
    data.select = !data.select;
    this.inTakeQtys = this.inTakeQty.filter(res => { return res.select });
  }


  async findMed(data) {
    if (data && data.length > 2) {
      this.search = { name: data };
      this.mlist = await this.service.searchMedicine(this.search).toPromise();
    } else {
      this.mlist = [];
    }
    setTimeout(() => {
      var x = this.mlist.find(res => { return res.name == this.medicineName });
      if (x) {
        this.medicineId = x.id;
      }
    }, 1000);

  }

  add() {
    // this.medicineId=this.medArray[0].id;
    // this.medicineName=this.medArray[0].name;
    this.dosage = this.dosageArray[0].dosage;
    this.qty = this.inTakeQtys[0].qty;
    var array = [];
    var fName = [];
    if (this.f1) {
      array.push(1)
      // fName.push("Morning")
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Morning").subscribe((translation: string) => {
          fName.push(translation);
        });
      });
    }
    if (this.f2) {
      array.push(2)
      // fName.push("AfterNoon")
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("AfterNoon").subscribe((translation: string) => {
          fName.push(translation);
        });
      });
    }
    if (this.f3) {
      array.push(3)
      // fName.push("Night")
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Night").subscribe((translation: string) => {
          fName.push(translation);
        });
      });

    }
    if (this.medicineId || array || this.medicineName || fName || this.bf || this.qty || this.dailyDos || this.days || this.summary || this.input2) {
      if (this.mlist != null) {
        if (this.data.length == 0) {
          this.data.push({ "medicineId": this.medicineId, "medicineName": this.medicineName, "medFrequencies": array, "fname": fName, "beforeFoodInd": this.bf, "inTakeQty": this.qty, "days": this.days, "comments": this.summary, "dosage": this.dosage, "healthCenterId": 3, "centerName": "Apollo Pharmacy" });
          this.dialogRef.close();
        }
        else {
          this.data.forEach(res => {
            if (this.medicineId != res.medicineId) {
              if (this.medicineName == undefined || this.dosage == undefined || fName.length == 0 || this.days == undefined) {
                // this.app.openSnackBar("Please enter the required fields","error-snackbar")
                this.translate.use(this.languageCode).subscribe(() => {
                  this.translate.get("Please enter the required fields").subscribe((translation: string) => {
                    this.app.openSnackBar(translation, "error-snackbar")
                  })
                })
              }
              else {
                this.data.push({ "medicineId": this.medicineId, "medicineName": this.medicineName, "medFrequencies": array, "fname": fName, "beforeFoodInd": this.bf, "inTakeQty": this.qty, "days": this.days, "comments": this.summary, "dosage": this.dosage, "healthCenterId": 3, "centerName": "Apollo Pharmacy" });
                this.clear();
                this.dialogRef.close();
              }
            }
          })
        }
      }
      else {
        if (this.medicineName == undefined || this.dosage == undefined || fName.length == 0 || this.days == undefined) {
          // this.app.openSnackBar("Please enter the required fields","error-snackbar")
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get("Please enter the required fields").subscribe((translation: string) => {
              this.app.openSnackBar(translation, "error-snackbar")
            })
          })
        }
        else {
          let post = {
            name: this.medicineName,
            compositionName: "Paracetamol",
            compositionId: 1,
            medicineType: 2,
            medicineTypeName: "Tablets",
            sourceType: 1,
            sourceTypeName: "Medicine",
            activeInd: true
          }
          this.service.createMed(post).subscribe((res: any) => {
            this.data.push({ "medicineId": res.id, "medicineName": res.name, "medFrequencies": array, "fname": fName, "beforeFoodInd": this.bf, "inTakeQty": this.qty, "days": this.days, "comments": this.summary, "dosage": this.dosage, "healthCenterId": 3, "centerName": "Apollo Pharmacy" });
            // this.app.openSnackBar("Medicine Created Successfully","success")
            this.translate.use(this.languageCode).subscribe(() => {
              this.translate.get("Medicine Created Successfully").subscribe((translation: string) => {
                this.app.openSnackBar(translation, "success-snackbar")
              })
            })
            this.clear();
            this.dialogRef.close();
          })
        }

      }
    }
    else {
      this.dialogRef.close();
    }
  }

  findCenter(data) {

  }

  medgroupCheck() {
    this.allmedicineGroup.map((res, i) => {
      if (this.showingDiv == res.id) {
        Object.assign(res, { style: "activing" });
      }
      else {
        Object.assign(res, { style: "none" });
      }
    });
  }

  openMedgroup(id) {
    this.loader = true;
    this.showingDiv = id;
    this.medgroupCheck();
    this.service.getMedicine().subscribe((res: any) => {
      this.allMedicine = res;
      this.getallmedgroup(id)
    });
  }

  highlightInput() {
    if (this.medicineName.length > 0) {
      this.isInputHighlighted = true;
    } else {
      this.isInputHighlighted = false;
    }
  }

  getallmedgroup(id) {
    this.service.getgroupMedicine(id).subscribe(res => {
      this.medicines = res;
      this.loader = false;
      this.openpopupOfGroupmed = true;
      this.groupName = this.medicines.name;
      this.descripTion = this.medicines.description;
      this.shortCode = this.medicines.code;
      this.groupMedicine = this.medicines.groupMedicines;
      this.allMedicine = this.allMedicine;
      this.groupMedicine.forEach(res1 => {
        this.matchingMedicine = this.allMedicine.find(response => response.id === res1.medicineId);
        if (this.matchingMedicine) {
          res1.id = this.matchingMedicine.id;
          res1.name = this.matchingMedicine.name;
        }
      });
    })
    // this.allmedicineGroup.map(res => {
    //   if(res.id == id){
    //   Object.assign(res, { select: false })
    // }
    // })
    // id.select = !id.select;
  }

  clear() {
    // this.msg.setMessage("All fields cleared","success");
    this.medicineName = "";
    this.f1 = "";
    this.f2 = "";
    this.f3 = "";
    this.qty = "";
    this.bf = false;
    this.bf1 = false;
    this.days = "";
    this.summary = "";
    this.input2 = "";
    this.dosage = "";
  }
  toggleSelection(buttonId: string) {
    this[buttonId] = !this[buttonId];
  }

}
