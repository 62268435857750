import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { DataService } from 'src/api-calls/data.service';
import { AppComponent } from '../app.component';
import { Popper1Component } from '../popper1/popper1.component';
import { Popper2Component } from '../popper2/popper2.component';
import { AppService } from '../app.service';
import { Popper4Component } from '../popper4/popper4.component';
import { Popper5Component } from '../popper5/popper5.component';
import { DomSanitizer } from '@angular/platform-browser';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@angular/cdk/platform';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { HandlerService } from "src/api-calls/handler.service";
import { ActivatedRoute, Router } from '@angular/router';

declare var cordova: any;
@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  allbilling: any;
  loader: boolean;
  msgContent: string;
  id: any;
  billingObj: any;
  dateFormat;
  billing: any;
  url;
  billingPay: any;
  paymentModeOptions: any = ([] = [
    { name: "Cash", id: "1" },
    { name: "Online", id: "2" },
  ]);
  constructor(
    public dialog: MatDialog,
    public handler: HandlerService,
    private translate: TranslateService,
    private service: DataService,
    public msg: AppComponent,
    public app: AppService,
    public sanitizer: DomSanitizer,
    public platform: Platform,
    private router: Router,
    public route: ActivatedRoute,
    // public printer: Printer,
    private camera: Camera,
  ) { }

  ngOnInit() {
    this.url = this.app.urlData();
    this.id = this.app.urlData();
    this.service.getBillingById(this.id).subscribe((res: any) => {
    this.billing = res;
    this.billingObj = res.billingDetails;
    this.billingPay = res.billPayments;
    this.billingPay.map((res) => {
        this.paymentModeOptions.map((res1: any) => {
            if (res.paymentMode == res1.id) {
              Object.assign(res, { paymentModeName: res1.name })
            }
          });
        });
    this.id = res.id;
   
  }, err => {
    this.loader = false;
  })

  }
  cancel(){
    // this.app.openPage('billing-edit/' + this.id)
    this.app.openPage('billing')
  }
  printOrder() {
    if (this.platform.ANDROID || this.platform.IOS) {
      var printcon = document.getElementById('overall');
      document.addEventListener('deviceready', function () {
        const printContent =
          (`<html>
          <head>  
            <style>
            @page {
              size: auto;
              /* auto is the initial value */
              margin: 0mm;
              /* this affects the margin in the printer settings */
            }
            
            .overall1{
              // background-image: url("../../assets/background-img.png");
              background-repeat: no-repeat;
              background-size: cover;
            }
            
            .invoice {
              max-width: 800px;
              margin: 0 auto;
              padding: 20px;
              border: 1px solid #ccc;
              background-image: url("assets/background-img.png");
              background-repeat: no-repeat;
              background-size: cover;
              height: 176%;
            }
            .invoice-logo {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: -9%;
              margin-left: -4%;
            }
            .invoice-logo img {
              width: 26%;
            }
            .invoice-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: -10%;
              width: 126%;
            }
            
            .invoice-header img {
              width: 12%;
              height: 82px;
            }
            
            .invoice-header h1 {
              font-size: 28px;
              color: #00205b;
              display: flex;
              font-weight: bold;
              margin: 12px;
              width: 71%;
            }
            .header-name {
              display: flex;
              width: 174%;
            }
            .header-name h2 {
              font-size: 85px;
              color: #00205b;
              display: flex;
              font-weight: bold;
            }
            .invoice-details-name {
              display: flex;
              justify-content: space-between;
              width: 80%;
            }
            
            .invoice-details h2 {
              font-size: 18px;
              margin-bottom: 10px;
              color: #00205b;
              font-weight: bold;
            }
            
            .invoice-details p {
              margin: 0;
              color: #007fff;
            }
            
            .invoice-table {
              margin-top: 40px;
              width: 100%;
              border-collapse: collapse;
            }
            
            .invoice-table th,
            .invoice-table td {
              padding: 10px;
              border-bottom: 1px solid #ccc;
            }
            
            .invoice-table th {
              text-align: left;
              background-color: #00205b;
              color: #fff;
              font-weight: bold;
            }
            
            .invoice-total {
              margin-top: 5px;
              font-size: 22px;
              text-align: right;
              margin-right: 32px;
              color: #00205b;
            }
            
            .invoice-total p {
              margin: 6px;
            }
            .invoice-table tr:nth-child(odd) {
              background-color: rgb(193, 233, 240);
            }
            
            .invoice-table tr:nth-child(even) {
              background-color: #e1f3f3;
            }
            .sub-table {
              display: flex;
            }
            .sub-table h2 {
              display: flex;
              font-size: 20px;
              margin-bottom: -33px;
              color: #00205b;
            }
            .battom-sheet {
              display: flex;
              width: 137%;
              margin-left: -23px;
              height: 2%;
              margin-top: -6px;
            }
            .battom-imgage {
              display: flex;
              justify-content: space-evenly;
              width: 89%;
              margin-bottom: -65px;
              margin-top: 36px;
            }
            .battom-imgage h2 {
              font-size: 21px;
              font-weight: bold;
              color: #00205b;
              margin-top: 9px;
            }
            .battom-imgage img {
              width: 45px;
              height: 44px;
            }
            .img1 {
              display: flex;
              justify-content: flex-end;
              margin-right: -8px;
            
            }
            .img1 img{
              width: 52px;
              height: 52px;
              margin-top: 12px;
              margin-bottom: 6px;
            }

            </style>
          </head>
          <body>
            ${printcon.innerHTML}
          </body>
         </html>`);
         console.log(printContent)
      cordova.plugins.printer.print(printContent);
      this.app.openPage('billing-edit/' + this.id)
     }, false);
    }else{
      setTimeout(() => {
        this.app.openPage('billing-edit/' + this.id)
        let a: any = document.getElementById('overall');
        // var mywindow = window.open('', 'Print', 'height=screen.height,width=screen.width');
  
        // const fileUrl = URL.createObjectURL(a);
        var win = window.open('', '_blank');
        if (win) {
          win.focus(); // Ensure the new window/tab is focused
        } else {
          alert('Please allow pop-ups for this website to view the file.'); // Display an alert if the new window/tab was blocked
        }
        win.document.write('<html><head><link rel="stylesheet" href="assets/print.scss" /><style>@page { size: auto;  margin: 0mm; }</style></head></html>');
        win.document.body.appendChild(a)
        win.document.close();
        setTimeout(() => {
          win.print();
          win.focus()
          win.close();
          this.ngOnInit();
        }, 1000);
      },
        1000);
    }

  }
  getbilling() {
  this.service.getBillingById(this.id).subscribe((res: any) => {
    if (res) {
      this.allbilling = res;
      this.loader = false;
    } else {
      this.allbilling = [];
      this.loader = false;
      this.msgContent = "No Treatment Available"
    }

  }), err => {
    this.loader = false;
  }
}
}
