import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/api-calls/auth.service';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-patinetview',
  templateUrl: './patinetview.component.html',
  styleUrls: ['./patinetview.component.scss']
})
export class PatinetviewComponent implements OnInit {
  
  checkpoints:any=[ ] 
  bool:any= null;
  icon:boolean=false;
  patientId;
  getWorkFlow:any=[];
  getRemWorkFlow:any=[];
  name;
  icon1=2;
  entitystatus:any={}
  entitystatus2:any=[]
  loader:boolean;
  msg = null;
  msg2 = null;
  openCheck:boolean=false;
  openBox:boolean=false;
  tokenNbr;
  selectedcircle;
  url;
  userLang;
  showToken:string;
  worQ:any=[];
  workqStatus:any=[];
  patientRefId;
  constructor(private auth: AuthService,private app: AppService,public translate:TranslateService) {}
  
  async ngOnInit() {
    this.userLang=sessionStorage.getItem('userLang')
    if(this.userLang){
      if(this.userLang==105){
        this.translate.use('hi')
       }
       if(this.userLang==103){
        this.translate.use('ta')
       }
       if(this.userLang==101){
        this.translate.use('en-US')
       }
      }
      else{
        this.translate.use('en-US')
      }

    this.loader = true;
    this.patientId=this.app.urlData();
    this.name = sessionStorage.getItem('name')
    this.patientRefId = sessionStorage.getItem('patientRefId')
    await this.auth.getWorkflowbyPatient(this.patientId).subscribe(res=>{
      this.getWorkFlow = res[0];
      this.getRemWorkFlow=this.getWorkFlow.checkpoints.filter(res=>res.status==1)
      this.getWorkFlow = this.getWorkFlow.checkpoints.sort((a,b)=>{ let dateA:any=new Date(a.eventTimeStr),dateB:any= new Date(b.eventTimeStr);  return dateB.getTime() - dateA.getTime() });
      this.getWorkFlow=this.getWorkFlow.reverse()
      this.getWorkFlow = this.getWorkFlow.filter(res => res.status != 1);
      this.getWorkFlow.map(res=>{
        this.worQ.push(res.worq)
      })
      if(this.worQ){
      this.worQ.map((res:any)=>{
        this.auth.getWorkqStatus(res.id,this.patientId).subscribe((res1:any)=>{
          this.getWorkFlow.map((res2:any)=>{
            if(res2.worq.id==res1.worq.id){
           Object.assign(res2,{workqStatus:res1})
            }
          })
        })  
      })
    }
      this.loader = false;
    })


    let x = JSON.parse(localStorage.getItem('config'));
     let a = x.find(res => res.shortCdConst == "TKNAUTO");
     this.showToken = a.userValue;
  }
  clear(){
    var url=this.app.sendUrl()
    if (url.includes('patientview')){
    this.app.openPage('weblink');
    }
    else{
      this.app.openPage('viewentity')
    }
   
  }
  closeStatus(){
    this.openBox=false;
    this.msg = null;
    this.selectedcircle=""
  }
  openStatus(data){
    this.selectedcircle=data.id
    this.openBox=true;
    this.msg2 = 'Loading data';
    this.auth.getWorkqStatus(data.worq.id,this.patientId).subscribe(res=>{
      if(res == null){
        this.entitystatus = {};
        this.msg2 = 'Try again later';
      }
      this.entitystatus = res;
      this.msg2 = null;
    })
  }
}
