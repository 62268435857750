import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/api-calls/auth.service';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { DoctorSetupComponent } from '../doctor-setup/doctor-setup.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-camp',
  templateUrl: './create-camp.component.html',
  styleUrls: ['./create-camp.component.scss']
})
export class CreateCampComponent implements OnInit {

  alldepart: any = [];
  name;
  desc;
  code;
  departmentId;
  checkpointId;
  avgProcessingTime;
  doctoredit: boolean;
  loader;
  url;
  id;
  doctorObj: any = [];
  languageCode: string;
  items: any = [];
  dataSource: any;
  msgContent: string;
  avgTime;
  description: any;
  inchargeName: any;
  location: any;
  activeInd: boolean;
  campObj: any;

  constructor(public data: DataService, private translate: TranslateService, public app: AppService, public auth: AuthService) { }

  ngOnInit() {
    this.loader = true;
    this.languageCode = localStorage.getItem('lang');
    // this.departmentGet();
    this.url = this.app.urlData();
    setTimeout(() => {
      this.loader = false;
    }, 500);
    if (this.url != 'create-camp') {
      this.doctoredit = true;
      this.id = this.app.urlData();
      this.data.getmedicalcampById(this.id).subscribe((res: any) => {
        this.campObj = res;
        this.id = res.id;
        this.location = res.location;
        this.description = res.description;
        this.inchargeName = res.inchargeName;
        this.name = res.name;
        this.loader = false;
      }, err => {
        this.loader = false;
      })
    } else {
      console.log('jj')
    }

  }

  departmentGet() {
    this.data.departmentGetAll().subscribe((res: any) => {
      this.alldepart = res;
    })
  }

  doctorCreate() {
    this.loader = true;
    var post = {
      "name": this.name.toUpperCase(),
      "location": this.location,
      "description": this.description,
      "inchargeName": this.inchargeName,
      orgId: localStorage.getItem('organizationId'),
      "activeInd": true,

    }
    console.log(post)
    this.data.medicalcampCreate(post).subscribe((res: any) => {
      this.loader = false;
      this.app.openPage('medicalcampsetup');
      // this.app.openSnackBar('Created Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Created Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })

    }, err => {
      this.loader = false;
    })
  }
  cancel() {
    this.app.openPage('medicalcampsetup')
  }
  doctorUpdate() {
    this.loader = true;
    this.campObj.name = this.name.toUpperCase();
    this.campObj.id = this.id;
    this.campObj.location = this.location;
    this.campObj.description = this.description;
    this.campObj.inchargeName = this.inchargeName;
    this.activeInd = true;
    this.data.medicalcampPutById(this.id, this.campObj).subscribe(res => {
      this.loader = false;
      this.app.openPage('medicalcampsetup');
      // this.app.openSnackBar('Updated Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get(" Updated Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })
    }, err => {
      this.loader = false;
    })
  }
}

