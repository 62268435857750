import { Component,ViewChild, ElementRef, OnInit } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import {  MatDialog, MatTableDataSource } from '@angular/material';
import { AssistancepopComponent } from '../assistancepop/assistancepop.component';
import { DragulaService } from 'ng2-dragula';
import { TranslateService } from '@ngx-translate/core';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { HandlerService } from "src/api-calls/handler.service";
import { CameraService } from '../camera.service';


@Component({
  selector: 'app-patient-edit',
  templateUrl: './patient-edit.component.html',
  styleUrls: ['./patient-edit.component.scss'],
  providers: [DragulaService]
})
export class PatientEditComponent implements OnInit {
  @ViewChild('myDivIdElement') myDivIdElement?: ElementRef;

  items: any = [];
  name;
  s1;
  s2;
  description;
  patientRefId;
  mobile;
  patientId;
  workFlowId;
  add
  loader: boolean = true;
  getWorkFlow: any = [];
  selectedItem: any = [];
  checkpoints: any = [];
  customWorkId;
  patientObject: any = [];
  workFlowName;
  wDescription;
  patientUserid;
  entityId;
  docName: any = [];
  metadata;
  address;
  language = [{ name: 'English', value: '101' },
  { name: 'Hindi', value: '105' },
  { name: 'Tamil', value: '103' },
  { name: 'Bengali', value: '107' }];
  preflang = '101';
  showToken: string;
  maxName: string;
  maxToken: string;
  maxMetadata: string;
  maxPatientRef: string;
  showtime: boolean = false;
  patients: any[] = [{ name: 'In-Patient', id: '2', disable: true },
  { name: 'Out-Patient', id: '1', disable: false },
  { name: 'MHC', id: '3', disable: true },
  { name: 'BedSide', id: '4', disable: true }];
  entityType;
  countrynum;
  reportingTime = "9.00";
  // selectdate:Date;
  receiptNbr;
  wardNbr;
  bedSide: any;
  doctorName;
  age;
  gender;
  visitsData = [{ name: 'Arrived', id: 1 },
  { name: 'Schedule', id: 2 }]
  height;
  weight;
  showcalendar: boolean = false;
  selectdate = new Date();
  setApptTime: boolean = false;
  mulCard: boolean;
  token;
  workqTime;
  workqId;
  reportingDate;
  documentNbr;
  category: boolean = false;
  newPatient: boolean = false;
  fasting: boolean = false;
  tobacco;
  clinica: boolean;
  alcoholic;
  secMobile;
  secCntryNum;
  allergic = [{ name: 'Yes', id: true }, { name: 'No', id: false },]
  allReportsReady: boolean = false;
  hwind: boolean;
  alldepart: any = [];
  entype: any = [] = [{ name: 'Appointment', id: '1' },
  { name: 'Walk-In', id: '2' }]
  entryType;
  department;
  available: boolean = false;
  showname: boolean = false;
  deptId;
  languageCode: string;
  doctorId: any;
  visitStatus: any;
  smoke: boolean = false;
  alcohol: boolean = false;
  betelAndAricaNut: boolean = false;
  pregnancy: boolean = false;
  monthlyDeduction: boolean = false;
  theMother: boolean = false;
  bleeding: boolean = false;
  ulcer: boolean = false;
  palpitation: boolean = false;
  menstruation: boolean = false;
  dizzy: boolean = false;
  allergy: boolean = false;
  oralMedications: boolean = false;
  fever: boolean = false;
  sugar: boolean = false;
  chestPain: boolean = false;
  throatPain: boolean = false;
  thyroid: boolean = false;
  edible: boolean = false;
  cough: boolean = false;
  dapatientObjectta: boolean = false;
  wheezing: boolean = false;
  capture: boolean = false;
  picload: boolean = false;
  src: any = [];
  dataSource1: any;
  userHabit;
  userhealthcondition;
  userhealthissue;
  checkPOint: any;
  docCheckPoint: any;
  doctId: any;

  constructor(public data: DataService,
     private translate: TranslateService,
     public app: AppService,
     private cameraService: CameraService,
     public dragulaService: DragulaService,
      public dialog: MatDialog) {
    dragulaService.createGroup('DRAGULA_FACTS', {

      moves: function (el: any, container: any, handle: any): any {
        if (el.classList.contains('king')) {
          return false;
        }

        return true;
      }
    });
  }

  async ngOnInit() {
    this.capture = false;
    this.picload = false;
    this.languageCode = localStorage.getItem('lang');
    let x = JSON.parse(localStorage.getItem('config'));
    let a = x.find(res => res.shortCdConst == "TKNAUTO");
    this.showToken = a.userValue;
    let b = x.find(res => res.shortCdConst == "PATNM");
    this.maxName = b.userValue;
    let c = x.find(res => res.shortCdConst == "PATTKNNO");
    this.maxToken = c.userValue;
    let d = x.find(res => res.shortCdConst == "PATMTDA");
    this.maxMetadata = d.userValue;
    let e = x.find(res => res.shortCdConst == "PATRFID");
    this.maxPatientRef = e.userValue;
    let f = x.find(res => res.shortCdConst == "MCPTCD");
    let h = x.find(res => res.shortCdConst == "CLNQENBL");
    if (h)
      this.clinica = JSON.parse(h.userValue);
    let w = x.find(res => res.shortCdConst == "HWIND");
    if (w)
      this.hwind = JSON.parse(w.userValue);
    if (f.userValue == "true" || f.userValue == true) {
      this.mulCard = true;
    } else {
      this.mulCard = false;
    }
    this.departmentGet();
    this.getDoctorsList();
    this.patientId = this.app.urlData()
    this.data.getPatientById(this.patientId).subscribe(res => {
      this.patientObject = res;
      this.name = this.patientObject.name;
      if (this.patientObject.entityType != 2) {
        var len = this.patientObject.contactNbr.length;
        if (this.patientObject.secContactNbr) {
          var len1 = this.patientObject.secContactNbr.length;
          len1 = len1 - 10;
        }
        len = len - 10;
        this.countrynum = this.patientObject.contactNbr.slice(0, len);
        this.mobile = this.patientObject.contactNbr.slice(-10);
        if (len1) {
          this.secCntryNum = this.patientObject.secContactNbr.slice(0, len1);
          this.secMobile = this.patientObject.secContactNbr.slice(-10);
        }
      }

      if (this.patientObject.userHabits.smoke) {
        this.smoke = this.patientObject.userHabits.smoke;
      }
      if (this.patientObject.userHabits.alcohol) {
        this.alcohol = this.patientObject.userHabits.alcohol;
      }
      if (this.patientObject.userHabits.betelAndAricaNut) {
        this.betelAndAricaNut = this.patientObject.userHabits.betelAndAricaNut;
      }
      if (this.patientObject.userHabits.habitsNotes) {
        this.userHabit =this.patientObject.userHabits.habitsNotes;
      }

      if (this.patientObject.userHealthConditions.pregnancy) {
        this.pregnancy = this.patientObject.userHealthConditions.pregnancy;
      }
      if (this.patientObject.userHealthConditions.monthlyDeduction) {
        this.monthlyDeduction = this.patientObject.userHealthConditions.monthlyDeduction;
      }
      if (this.patientObject.userHealthConditions.theMother) {
        this.theMother = this.patientObject.userHealthConditions.theMother;
      }
      if (this.patientObject.userHealthConditions.healthConditionsNotes) {
        this.userhealthcondition =this.patientObject.userHealthConditions.healthConditionsNotes;
      }

      if (this.patientObject.userHealthIssues.bleeding) {
        this.bleeding = this.patientObject.userHealthIssues.bleeding;
      }
      if (this.patientObject.userHealthIssues.ulcer) {
        this.ulcer = this.patientObject.userHealthIssues.ulcer;
      }
      if (this.patientObject.userHealthIssues.palpitation) {
        this.palpitation = this.patientObject.userHealthIssues.palpitation;
      }
      if (this.patientObject.userHealthIssues.menstruation) {
        this.menstruation = this.patientObject.userHealthIssues.menstruation;
      }
      if (this.patientObject.userHealthIssues.dizzy) {
        this.dizzy = this.patientObject.userHealthIssues.dizzy;
      }
      if (this.patientObject.userHealthIssues.allergy) {
        this.allergy = this.patientObject.userHealthIssues.allergy;
      }
      if (this.patientObject.userHealthIssues.oralMedications) {
        this.oralMedications = this.patientObject.userHealthIssues.oralMedications;
      }
      if (this.patientObject.userHealthIssues.fever) {
        this.fever = this.patientObject.userHealthIssues.fever;
      }
      if (this.patientObject.userHealthIssues.sugar) {
        this.sugar = this.patientObject.userHealthIssues.sugar;
      }
      if (this.patientObject.userHealthIssues.chestPain) {
        this.chestPain = this.patientObject.userHealthIssues.chestPain;
      }
      if (this.patientObject.userHealthIssues.throatPain) {
        this.throatPain = this.patientObject.userHealthIssues.throatPain;
      }
      if (this.patientObject.userHealthIssues.thyroid) {
        this.thyroid = this.patientObject.userHealthIssues.thyroid;
      }
      if (this.patientObject.userHealthIssues.edible) {
        this.edible = this.patientObject.userHealthIssues.edible;
      }
      if (this.patientObject.userHealthIssues.cough) {
        this.cough = this.patientObject.userHealthIssues.cough;
      }
      if (this.patientObject.userHealthIssues.throatPain) {
        this.throatPain = this.patientObject.userHealthIssues.throatPain;
      }
      if (this.patientObject.userHealthIssues.wheezing) {
        this.wheezing = this.patientObject.userHealthIssues.wheezing;
      }
      if (this.patientObject.userHealthIssues.healthIssuesNotes) {
        this.userhealthissue =this.patientObject.userHealthIssues.healthIssuesNotes;
      }
      this.patientRefId = this.patientObject.patientRefId;
      this.token = this.patientObject.tokenNbr;
      this.entityType = this.patientObject.entityType;
      this.preflang = this.patientObject.prefLang,

        this.metadata = this.patientObject.metadata;
        this.address = this.patientObject.address;
      this.age = this.patientObject.age;
      this.gender = this.patientObject.gender;
      this.height = this.patientObject.height;
      this.weight = this.patientObject.weight;
      this.documentNbr = this.patientObject.documentNbr;
      if (this.patientObject.apptTimeStr) {
        let r = this.patientObject.expctdReportingTime
        this.reportingTime = r.slice(11, 16);
        this.selectdate = new Date(r)
      }
      this.app.callRequired = this.patientObject.callRequired;
      this.app.wheeled = this.patientObject.wheeled;
      this.app.aged = this.patientObject.aged;
      this.app.nri = this.patientObject.internationalPatient;
      this.app.vipPat = this.patientObject.vipPat;
      this.app.emrgPat = this.patientObject.emrgPat;
      this.wardNbr = this.patientObject.wardNbr;
      this.receiptNbr = this.patientObject.receiptNbr;
      this.bedSide = this.patientObject.bedSide;
      this.doctorName = this.patientObject.doctorName;
      if (this.patientObject.visitStatus) {
        this.visitsData.map(res =>{
          if(res.id == this.patientObject.visitStatus){
            this.visitStatus = res.name;
          }
        })
        console.log(this.visitStatus)
      } else {
        this.visitStatus = this.visitsData[0].name;
        console.log(this.visitStatus)
      }
      this.docName.map(response => {
        if (!this.patientObject.doctorId || this.patientObject.doctorId == null || this.patientObject.doctorId == undefined) {
          if (this.patientObject.doctorName == response.name) {
            this.doctorId = response.id;
            console.log(this.doctorId)
          }
        } else {
          this.doctorId = this.patientObject.doctorId;
          console.log(this.doctorId)
        }
      })
      this.newPatient = this.patientObject.newPatient;
      this.fasting = this.patientObject.fasting;
      this.allReportsReady = this.patientObject.allReportsReady;
      this.department = this.patientObject.department;
      if (this.patientObject.entryType) {
        this.entype.map(res => {
          if (res.id == this.patientObject.entryType) {
            this.entryType = res.name;
          }
        })
      }
      if (this.entityType == 1) {
        this.entityType = this.patients[1].id
      }
      if (this.entityType == 2 && this.bedSide == false) {
        this.entityType = this.patients[0].id
      }
      if (this.entityType == 2 && this.bedSide == true) {
        this.entityType = this.patients[3].id
      }
      if (this.entityType == 3) {
        this.entityType = this.patients[2].id
      }
      this.data.getWorkflowbyPatient(this.patientId).subscribe(res => {
        this.getWorkFlow = res[0];
        if (this.getWorkFlow == null) {
          this.data.getCheckpointsPatientAdd(this.patientObject.entityType).subscribe(res => {
            this.checkpoints = res;
            this.checkpoints = this.checkpoints.filter(res => {
              return res.activeInd == true;
            })
            this.loader = false;
          })
        }
        if (this.getWorkFlow) {
          this.workFlowId = this.getWorkFlow.id;
          this.workFlowName = this.getWorkFlow.name;
        }
        if (this.getWorkFlow) {
          this.selectedItem = this.getWorkFlow.checkpoints;
          this.selectedItem.map(res => {
            if (res.apptTimeStr) {
              let r = res.apptTimeStr
              let time = r.slice(11, 16);
              let date: any = new Date(r)
              this.reportingTime = time
              Object.assign(res, { time: time, date: date })
            }
          })
          this.selectedItem = this.selectedItem.sort((a, b) => {
            return a.stepNbr - b.stepNbr
          });
          // this.selectedItem = this.selectedItem.sort((a, b) => { return a.stepNbr - b.stepNbr });
          this.selectedItem.map(res => {
            if (res.checkPointPositionId == 1 || res.checkPointPositionId == 2) {
              Object.assign(res, { cla: 'king' })
            } else {
              Object.assign(res, { cla: 'john' })
            }
          })
          let order = [1, 3, 2];

          this.selectedItem.sort(function (a, b) {
            return order.indexOf(a.checkPointPositionId) - order.indexOf(b.checkPointPositionId)

          });
          this.data.getCheckpointsPatientAdd(this.patientObject.entityType).subscribe(res => {
            this.checkpoints = res;
            this.checkpoints = this.checkpoints.filter(res => {
              return res.activeInd == true;
            })
            this.loader = false;
            this.checkpoints = this.checkpoints.filter(res => !this.selectedItem.find(val => res.id === val.id));
          })
        }
      })
      setTimeout(() => {
        this.loader = false;
      }, 2000);
    });

  }

  async save() {
    if (this.getWorkFlow == null) {
      this.selectedItem.map((res, i) => {
        this.selectedItem[i].stepNbr = i + 1;
      })
    }

    this.selectedItem.map(res => {
      if (res.cpType == 2) {
        this.category = true;
      }
    })
    this.loader = true;
    if (this.name == undefined || this.selectedItem.length == 0 || this.name == "" || this.patientRefId == undefined || this.patientRefId == "") {
      // this.app.openSnackBar('Please enter the required fields', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please enter the required fields").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
      return this.loader = false;
    } else {
      if (this.showcalendar == true || this.category == true) {
        if (this.reportingTime == undefined || this.reportingTime == "") {
          // this.app.openSnackBar('Please enter the  required fields', 'error-snackbar')
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get("Please enter the  required fields").subscribe((translation: string) => {
              this.app.openSnackBar(translation, "error-snackbar")
            })
          })
          return this.loader = false
        } else {
          // this.reportingTime = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2) + ' ' + this.reportingTime + ':00';
          this.patientObject.name = this.name.toUpperCase();
          if (this.mobile != null || this.mobile != undefined || this.mobile != "") {
            this.patientObject.contactNbr = this.countrynum + this.mobile;
          }
          //  if(this.mobile!=null || this.mobile!=undefined || this.mobile!=""  ){
          //   this.patientObject.contactNbr = this.countrynum + this.mobile;
          //  }
          if (this.mobile == null) {
            this.patientObject.contactNbr = '+91' + undefined
          }
          if (this.mobile == "") {
            this.patientObject.contactNbr = '+91' + undefined
          }
          if (this.mobile == undefined) {
            this.patientObject.contactNbr = '+91' + undefined
          }
          if (this.secMobile == null) {
            this.patientObject.secContactNbr = '+91' + undefined
          }
          if (this.secMobile == "") {
            this.patientObject.secContactNbr = '+91' + undefined
          }
          if (this.secMobile == undefined) {
            this.patientObject.secContactNbr = '+91' + undefined
          }
          this.patientObject.metadata = this.metadata;
          this.address = this.patientObject.address;
          this.patientObject.age = this.age;
          this.patientObject.gender = this.gender;
          this.patientObject.height = this.height;
          this.patientObject.weight = this.weight;
          this.patientObject.prefLang = this.preflang,
            this.patientObject.documentNbr = this.documentNbr
          this.patientObject.patientRefId = this.patientRefId;

          this.patientObject.userHabits = {
            "smoke": this.smoke,
            "alcohol": this.alcohol,
            "entityId": this.patientId,
            "betelAndAricaNut": this.betelAndAricaNut,
            "habitsNotes": this.userHabit,
          },
            this.patientObject.userHealthConditions = {
              "pregnancy": this.pregnancy,
              "theMother": this.theMother,
              "monthlyDeduction": this.monthlyDeduction,
              "entityId": this.patientId,
              "healthConditionsNotes": this.userhealthcondition,
            },
            this.patientObject.userHealthIssues = {
              "entityId": this.patientId,
              "ulcer": this.ulcer,
              "allergy": this.allergy,
              "dizzy": this.dizzy,
              "palpitation": this.palpitation,
              "sugar": this.sugar,
              "chestPain": this.chestPain,
              "thyroid": this.thyroid,
              "oralMedications": this.oralMedications,
              "fever": this.fever,
              "cough": this.cough,
              "throatPain": this.throatPain,
              "wheezing": this.wheezing,
              "bleeding": this.bleeding,
              "healthIssuesNotes": this.userhealthissue,
            }

          if (this.entityType != 4) {
            this.patientObject.entityType = this.entityType;
          }
          if (this.entityType == 4) {
            this.patientObject.entityType = '2';
            this.patientObject.bedSide = 'true';
          }
          // this.patientObject.expctdReportingTime = this.reportingTime;
          this.patientObject.aged = this.app.aged;
          this.patientObject.wheeled = this.app.wheeled;
          this.patientObject.internationalPatient = this.app.nri;
          this.patientObject.emrgPat = this.app.emrgPat;
          this.patientObject.vipPat = this.app.vipPat;
          this.patientObject.callRequired = this.app.callRequired;
          this.patientObject.wardNbr = this.wardNbr;
          this.patientObject.receiptNbr = this.receiptNbr;
          this.patientObject.doctorName = this.doctorName;
          this.patientObject.doctorId = this.doctorId;
          this.patientObject.newPatient = this.newPatient;
          this.visitsData.map(res => {
            if (this.visitStatus == res.name) {
              this.patientObject.visitStatus = res.id;
            }
          })
          this.fasting = this.patientObject.fasting;
          this.patientObject.allReportsReady = this.allReportsReady;
          this.patientObject.department = this.department;
          this.entype.map(res => {
            if (res.name == this.entryType) {
              this.patientObject.entryType = res.id;
            }
          })
          await this.data.editPatient(this.patientId, this.patientObject).toPromise()
            .then((res: any) => {
            });

          this.selectedItem.map((res, i) => {
            this.selectedItem[i].stepNbr = i + 1;
          })


          // let post1 = { name: this.name, description: this.name, checkpoints: this.selectedItem,predefined:false }
          // this.getWorkFlow.name=this.workFlowName;
          // this.getWorkFlow.description=this.workFlowName;
          if (this.getWorkFlow != null) {
            this.getWorkFlow.checkpoints = this.selectedItem;
            this.getWorkFlow.predefined = false;
            this.getWorkFlow.entityId = this.patientId;
          }

          if (this.name) {
            if (this.getWorkFlow != null) {
              this.data.updateWorkFlow(this.workFlowId, this.getWorkFlow).toPromise()
                .then((res: any) => {
                  // this.customWorkId=res.id ;
                  this.loader = false;
                  this.app.openPage('patientSearch');
                  // this.app.openSnackBar('Patient Details Updated Successfully !!', 'success-snackbar')
                  this.translate.use(this.languageCode).subscribe(() => {
                    this.translate.get("Patient Details Updated Successfully").subscribe((translation: string) => {
                      this.app.openSnackBar(translation, "success-snackbar")
                    })
                  })
                  //   this.data.patientAssignWorkFlow(this.customWorkId,this.patientId,null).subscribe(
                  //     res=>this.app.openSnackBar(' Patient checkedin updated Successfully !!'),
                  //   err=>
                  //  );
                  this.app.aged = false;
                  this.app.wheeled = false;
                  this.app.callRequired = false;
                  this.app.nri = false;
                  this.app.vipPat = false;
                  this.app.emrgPat = false;
                  if (sessionStorage.getItem("Edit")) {
                    const mq = window.matchMedia("(min-width: 700px)")
                    var id = sessionStorage.getItem('checkId')
                    if (mq.matches) {
                      this.app.openPage('workque/' + id)
                      sessionStorage.removeItem('Edit')
                    } else {
                      this.app.openPage('worque/' + id)
                      sessionStorage.removeItem('Edit')
                    }
                  }
                })
                .catch(
                  err => {
                    this.loader = false
                  }
                )
            } else {
              let post1 = {
                name: this.name,
                description: this.name,
                checkpoints: this.selectedItem,
                predefined: false,
                entityId: this.patientId
              }
              this.data.createWorkFlow(post1).toPromise()
                .then((res: any) => {
                  this.customWorkId = res.id;
                  if (this.patientId != undefined) {
                    this.data.patientAssignWorkFlow(this.customWorkId, this.patientId, this.patientObject).subscribe(
                      res => {
                        this.loader = false;
                        this.patientObject = "";
                        this.app.openPage('patientSearch');
                        // this.app.openSnackBar('Patient Details Updated Successfully !!', 'success-snackbar')
                        this.translate.use(this.languageCode).subscribe(() => {
                          this.translate.get("Patient Details Updated Successfully").subscribe((translation: string) => {
                            this.app.openSnackBar(translation, "success-snackbar")
                          })
                        })
                      })
                  }
                })
            }

          }
        }
      } else {
        this.patientObject.name = this.name.toUpperCase();
        if (this.mobile != null || this.mobile != undefined || this.mobile != "") {
          this.patientObject.contactNbr = this.countrynum + this.mobile;
        }
        if (this.mobile == null) {
          this.patientObject.contactNbr = '+91' + undefined
        }
        if (this.mobile == "") {
          this.patientObject.contactNbr = '+91' + undefined
        }
        if (this.mobile == undefined) {
          this.patientObject.contactNbr = '+91' + undefined
        }
        // else{
        //   this.patientObject.contactNbr = this.countrynum + undefined
        // }
        this.patientObject.metadata = this.metadata;
        this.address = this.patientObject.address;
        this.patientObject.age = this.age;
        this.patientObject.gender = this.gender;
        this.patientObject.height = this.height;
        this.patientObject.weight = this.weight;
        this.patientObject.prefLang = this.preflang;
        this.patientObject.documentNbr = this.documentNbr;
        this.patientObject.patientRefId = this.patientRefId;
        if (this.entityType != 4) {
          this.patientObject.entityType = this.entityType;
        }
        if (this.entityType == 4) {
          this.patientObject.entityType = '2';
          this.patientObject.bedSide = 'true';
        }
        if (this.showcalendar) {
          this.patientObject.expctdReportingTime = this.reportingTime;
        }
        this.patientObject.wardNbr = this.wardNbr;
        this.patientObject.receiptNbr = this.receiptNbr;
        this.patientObject.doctorName = this.doctorName;
        this.patientObject.doctorId = this.doctorId;
        this.visitsData.map(res => {
          if (this.visitStatus == res.name) {
            this.patientObject.visitStatus = res.id;
          }
        })
        // this.patientObject.expctdReportingTime = this.reportingTime;
        this.patientObject.aged = this.app.aged;
        this.patientObject.wheeled = this.app.wheeled;
        this.patientObject.internationalPatient = this.app.nri;
        this.patientObject.callRequired = this.app.callRequired;
        this.patientObject.emrgPat = this.app.emrgPat;
        this.patientObject.vipPat = this.app.vipPat;
        this.patientObject.newPatient = this.newPatient;
        this.patientObject.allReportsReady = this.allReportsReady;
        this.patientObject.fasting = this.fasting;
        this.patientObject.department = this.department;
        this.entype.map(res => {
          if (res.name == this.entryType) {
            this.patientObject.entryType = res.id;
          }
        })
        console.log(this.patientObject)
        await this.data.editPatient(this.patientId, this.patientObject).toPromise()
          .then((res: any) => {
          });


        this.selectedItem.map((res, i) => {
          this.selectedItem[i].stepNbr = i + 1;
        })

        // let post1 = { name: this.name, description: this.name, checkpoints: this.selectedItem,predefined:false }
        // this.getWorkFlow.name=this.workFlowName;
        // this.getWorkFlow.description=this.workFlowName;
        if (this.getWorkFlow != null) {
          this.getWorkFlow.checkpoints = this.selectedItem;
          this.getWorkFlow.predefined = false;
        }

        if (this.name) {
          if (this.getWorkFlow != null) {
            this.data.updateWorkFlow(this.workFlowId, this.getWorkFlow).toPromise()
              .then((res: any) => {
                // this.customWorkId=res.id ;
                this.loader = false;
                this.app.openPage('patientSearch');
                // this.app.openSnackBar('Patient Details Updated Successfully !!', 'success-snackbar')
                this.translate.use(this.languageCode).subscribe(() => {
                  this.translate.get("Patient Details Updated Successfully").subscribe((translation: string) => {
                    this.app.openSnackBar(translation, "success-snackbar")
                  })
                })
                //   this.data.patientAssignWorkFlow(this.customWorkId,this.patientId,null).subscribe(
                //     res=>this.app.openSnackBar(' Patient checkedin updated Successfully !!'),
                //   err=>
                //  );
                this.app.aged = false;
                this.app.wheeled = false;
                this.app.nri = false;
                this.app.callRequired = false;
                this.app.vipPat = false;
                this.app.emrgPat = false;
                if (sessionStorage.getItem("Edit")) {
                  const mq = window.matchMedia("(min-width: 700px)")
                  var id = sessionStorage.getItem('checkId')
                  if (mq.matches) {
                    this.app.openPage('workque/' + id)
                    sessionStorage.removeItem('Edit')
                  } else {
                    this.app.openPage('worque/' + id)
                    sessionStorage.removeItem('Edit')
                  }
                }
              })
              .catch(
                err => {
                  this.loader = false
                }
              )
          } else {
            let post1 = {
              name: this.name,
              description: this.name,
              checkpoints: this.selectedItem,
              predefined: false,
              entityId: this.patientId
            }
            this.data.createWorkFlow(post1).toPromise()
              .then((res: any) => {
                this.customWorkId = res.id;
                if (this.patientId != undefined) {
                  this.data.patientAssignWorkFlow(this.customWorkId, this.patientId, this.patientObject).subscribe(
                    res => {
                      this.loader = false;
                      this.patientObject = "";
                      this.app.openPage('patientSearch');
                      // this.app.openSnackBar('Patient Details Updated Successfully !!', 'success-snackbar');
                      this.translate.use(this.languageCode).subscribe(() => {
                        this.translate.get("Patient Details Updated Successfully").subscribe((translation: string) => {
                          this.app.openSnackBar(translation, "success-snackbar")
                        })
                      })
                    })
                }
              })
          }

        }

      }
    }


  }

  getDoctorsList() {
    this.data.getDoctorsList().subscribe((res: any) => {
      this.docName = res;
      this.available = true;
    })
  }

  //   getId(data){
  //     console.log(data)
  //     this.alldepart.map(res=>{
  //      if(data){
  //        if(res.name == data){
  //          this.deptId = res.id
  //          console.log(this.deptId)
  //          this.data.getDoctorByDepartmentId(this.deptId).subscribe(res=>{

  //           this.docName=res;
  //           this.available=true;

  //         })
  //        }

  //      }
  //     })
  //  }

  async getCheck(id) {
    this.selectdate = new Date();
    let mapData: any = [];
    this.workqId = id;
    mapData = this.checkpoints.filter(res => {
      return res.id == id
    });
    this.selectedItem.push(mapData[0]);
    this.getReportingTIme(this.selectdate);
    this.checkpoints = this.checkpoints.filter(res => !this.selectedItem.find(val => res.id === val.id));
    this.selectedItem.map(res => {
      if (res.checkPointPositionId == 1 || res.checkPointPositionId == 2) {
        Object.assign(res, { cla: 'king' })
      } else {
        Object.assign(res, { cla: 'john' })
      }
    })
    let order = [1, 3, 2];

    this.selectedItem.sort(function (a, b) {
      return order.indexOf(a.checkPointPositionId) - order.indexOf(b.checkPointPositionId)

    });
  }

  async getback(id) {
    let mapData: any = [];
    mapData = this.selectedItem.filter(res => {
      return res.id == id
    });
    this.checkpoints.push(mapData[0]);
    this.selectedItem = this.selectedItem.filter(res => !this.checkpoints.find(val => res.id === val.id));
    this.category = false;
  }

  cancel() {
    this.app.openPage('patientSearch');
    this.app.aged = false;
    this.app.wheeled = false;
    this.app.nri = false;
    this.app.callRequired = false;
  }

  ngDoCheck(): void {
    if (this.entityType == 2) {
      this.showtime = false;
      this.showcalendar = true;
      this.showname = true;
    }
    if (this.entityType == 4) {
      this.showcalendar = false;
      this.showtime = false;
      this.showname = true;
    }
    if (this.entityType == 1) {
      this.showcalendar = false;
      this.showtime = false;
      this.showname = true;
    }
    if (this.entityType == 3) {
      this.showcalendar = false;
      this.showtime = false;
      this.showname = false;
    }
    let apptcap = JSON.parse(localStorage.getItem('capability'))
    if (apptcap.length > 0) {
      apptcap.map(res => {
        if (res.includes('APTTMORD')) {
          this.setApptTime = true;
        }

      })
    }
  }

  openDialog(id): void {
    const dialogRef = this.dialog.open(AssistancepopComponent, {
      width: '250px',
      data: id
    });
  }

  getAppt(data) {

    // this.loader = true;
    this.data.getCheckpointsPatientAdd(data.id).subscribe(res => {
      this.checkpoints = res;
      this.checkpoints = this.checkpoints.filter(res => !this.selectedItem.find(val => res.id === val.id));
      //  this.cloneCheck = JSON.parse(JSON.stringify(res));
      // this.loader = false;
    })
    // if (data.id == 2) {
    //   let date = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2)
    //   this.data.getAppointment(date).subscribe(res => {
    //     this.reportingTime = res
    //   })


    // }
  }

  async datePost(id, date1: Date) {
    if (this.entityType == 2) {
      // let date = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2)
      // this.data.getAppointment(date).subscribe(res => {
      //   this.reportingTime = res
      // })
    }

    let checkGetById: any = await this.data.getCheckpointById(id).toPromise();
    let temp;
    // this.selectdate = date1;
    if (this.entityType == 2 || checkGetById.cpType == 2) {
      let date = date1.getFullYear() + '/' + ("0" + (date1.getMonth() + 1)).slice(-2) + '/' + ("0" + date1.getDate()).slice(-2)

      this.data.getAppointment(checkGetById.worq.id, date).subscribe(res => {

        // this.reportingTime = res;
        // temp = res;
        // this.selectedItem.map(res => {
        //   if (res.id == id) {
        //     Object.assign(res, { apptTimeStr: date + ' ' + this.reportingTime, time: temp,date:date1 })
        //   }
        // })
      },
        err => {
          // if (err.status == 409) {
          //   this.app.openSnackBar('Appointments Not Available for Selected Date', 'error-snackbar')
          // }
        })
    }
  }

  timeChange(id, time, date1: Date) {
    let date = date1.getFullYear() + '/' + ("0" + (date1.getMonth() + 1)).slice(-2) + '/' + ("0" + date1.getDate()).slice(-2);
    this.reportingTime = time;
    let temp = time;
    this.selectedItem.map(res => {
      if (res.id == id) {
        Object.assign(res, { apptTimeStr: date + ' ' + this.reportingTime, time: temp })
      }
    })

  }

  async getReportingTIme(date1: Date) {
    this.selectdate = date1;
    let checkGetById: any = await this.data.getCheckpointById(this.workqId).toPromise()
    if (this.entityType == 2 || checkGetById.cpType == 2) {

      if (checkGetById) {
        let date = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2)

        await this.data.getAppointment(checkGetById.worq.id, date).toPromise().then(res => {
          // this.reportingTime = res
          // this.workqTime = res
          // if (this.reportingTime) {
          //   let date = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2);
          //   this.selectedItem.map(res => {
          //     if (res.id == this.workqId) {
          //       Object.assign(res, { apptTimeStr: date + ' ' + this.reportingTime, time: this.workqTime,date:date1 })
          //     }
          // })

          // }
        },
          err => {
            // if (err.status == 409) {
            //   this.app.openSnackBar('Appointments Not Available for Selected Date', 'error-snackbar')
            // }
          })
      }
    }
  }

  clearArray() {
    this.selectedItem = []
  }

  switchLanguage(value) {
    this.preflang = value;
  }

  departmentGet() {
    this.data.departmentGetAll().subscribe((res: any) => {
      this.alldepart = res;
    })
  }
  generateFileName(): string {
    const timestamp = new Date().getTime();
    return `Dental Picture_${timestamp}.png`;
  }
  onSuccess(imageUri) {
    const fileName = this.generateFileName();
    this.src.push({ file: imageUri, docName: fileName });
    this.onCloseCameraClick();
    // this.PostDocuImage(imageUri, fileName);
    this.dataSource1 = new MatTableDataSource(this.src);

  }
  onFail(message) {
    console.error('Photo capture failed:', message);
    alert('Failed because: ' + message);
  }

  startCameraPreview() {
    this.capture = true;
    this.picload = false;
    setTimeout(() => {
      const nativeElement = this.myDivIdElement.nativeElement as HTMLElement;
      this.cameraService.startCameraPreview(nativeElement);
    });
  }
   onCloseCameraClick() {
    this.capture = false;
    this.cameraService.closeCamera();
  }

  onSwitchcamera() {
    this.cameraService.switchCamera();
  }

  capturePicture() {
    this.cameraService
      .takePicture()
      .then((imageSrcData: string) => {
        this.onSuccess(imageSrcData);
      })
      .catch((error: any) => {
        this.onFail(error);
        // Handle the error if necessary
      });
  }
  getId(data) {
    if (data == "All") {
      this.getDoctorsList()
    } else {
      this.alldepart.map((res) => {
        if (data) {
          if (res.name == data) {
            this.deptId = res.id;
            this.getDoctors(this.deptId)
          }
        }
      });
    }
  }
  getDoctors(deptId) {
    this.loader = true;
    this.data.getDoctorByDepartmentIdAvl(deptId).subscribe((res: any) => {
      if (res) {
        this.loader = false
        this.docName = res;
        this.available = true;
        this.doctorName = res[0].name;
        if (this.doctorName) {
          this.getDocId(this.doctorName);
        }
        this.doctorId = res[0].id;
      } else {
        this.loader = false;
      }
    },
      (err) => {
        this.loader = false;
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err).subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
      }
    );
  }
  getDocId(data: any) {
    console.log(data)
    this.docName.map((res) => {
      // console.log(res);
      if (data) {
        if (res.name == data) {
          this.doctId = res.id;
          console.log(this.doctId);
          // this.getCheckpoint(this.doctId)
        }
      }
    });
  }
  getCheckpoint(doctId) {
    this.loader = true;
    this.data.getDoctorById(doctId).subscribe((res: any) => {
      if (res) {
        this.loader = false
        this.checkPOint = res;
        const stepNbr = 1;
        Object.assign(this.checkPOint.checkpoint, { "stepNbr": stepNbr }); // Corrected syntax here
        this.docCheckPoint = res.checkpoint;
      } else {
        this.loader = false;
      }
    },
      (err) => {
        this.loader = false;
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err).subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
      }
    );
  }
}
