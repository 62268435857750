import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/api-calls/auth.service';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-web-link',
  templateUrl: './web-link.component.html',
  styleUrls: ['./web-link.component.scss']
})
export class WebLinkComponent implements OnInit {

  url;
  contactnum;
  errMsg:boolean=false;
  languages = [{ value: 'en-US', lang: 'English' },
  { value: 'ta', lang: 'தமிழ்' },
  { value: 'hi', lang: 'हिंदी' },
  { value: 'te', lang: 'తెలుగు' },
  { value: 'kn', lang: 'ಕನಾಡಾ' },
  ]
  languageCode: string;
  constructor(public fb:FormBuilder,private auth: AuthService,private app: AppService,public translate: TranslateService) { }
  loginForm: FormGroup;
  acvalidate:boolean=false;
  userLang;
  userId;
  
  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.userId=this.app.urlData();
    this.userLang=this.userId.split('?').pop();
    this.userLang=this.userLang.split('=').pop();
    if(this.userLang){
    if(this.userLang==105){
      this.translate.use('hi')
     }
     if(this.userLang==103){
      this.translate.use('ta')
     }
     if(this.userLang==101){
      this.translate.use('en-US')
     }
    }
    else{
      this.translate.use('en-US')
    }
 
    if(this.auth.loggedIn()){
      if(localStorage.getItem('entityId')){
        this.app.openPage('patientview/'+localStorage.getItem('entityId'))
      }else{
        this.app.openPage('user-entities')
      }
    }
    else{
      this.app.openPage('weblink')
    }
    localStorage.setItem('lang','en-US')
    this.loginForm = this.fb.group({       
      mobile: ['',[
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]],
      countrynum:['+91',]
  });
  this.url = this.app.sendUrl();
  if(this.url.includes('acvalidate')){
    this.acvalidate=true;
    this.contactnum=sessionStorage.getItem('contactnum')
  }
 


  }
  async login() {
        var cntNum = this.loginForm.get('countrynum').value;
        var mobile = this.loginForm.get('mobile').value;
        var phone = cntNum + mobile;
        var post = { contactNbrMob: phone,appId:2}

     this.auth.loginByMobile(post).subscribe(
        ((res: any) => {
            sessionStorage.setItem('contactnum',phone)
                this.app.openPage('acvalidate');
            
        }),
            err => {
              // this.app.openSnackBar(err.error.msg,'error-snackbar');
              this.translate.use(this.languageCode).subscribe(() => {
                this.translate.get(err.error.msg).subscribe((translation: string) => {
                    this.app.openSnackBar(translation, "success-snackbar")
                })
            })
               this.loginForm.controls['mobile'].setValue('')
            }
     )
    }
   

}
