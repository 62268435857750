import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from 'src/api-calls/data.service';


@Component({
  selector: 'app-popper2',
  templateUrl: './popper2.component.html',
  styleUrls: ['./popper2.component.scss']
})
export class Popper2Component implements OnInit {

  sdata:any;
  item1:any;
  item2:any;
  center:any;

  input1:any;
  input2:any;

  allCenter:any = [];
  summary:any;
  bf:boolean = false;

  preferList:any

  constructor(private service:DataService,
    public dialogRef: MatDialogRef<Popper2Component>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog) { }

    async ngOnInit() {
      this.sdata = await this.service.staticData().toPromise();
      this.sdata = this.sdata.filter(data=>data.typeId == 11);
      this.sdata = this.sdata.reduce(obj=>obj);
      this.sdata = this.sdata.values;
      this.center = await this.service.getLabCenter().toPromise();
    }
  
    findTest(data){
      this.item1 = this.sdata.filter(res=> res.name.toLowerCase().includes(data));
    }
  
    add(){
      var tId = this.sdata.find(res=>res.name == this.input1);
      this.data.push({"id":tId.id,"beforeFoodInd":this.bf,"status": 1,"healthCenterId":1,"name":tId.name,"additionalInstructions":this.summary});
      this.dialogRef.close();
    }
  
  
    clear(){
      //  this.msg.setMessage("All fields cleared","success");
      this.bf = false;
      this.sdata = "";
      this.center = "";
      this.input1="";
      this.input2="";
      this.summary="";
    }

}
