import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-medicine',
  templateUrl: './create-medicine.component.html',
  styleUrls: ['./create-medicine.component.scss']
})
export class CreateMedicineComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  data: any;
  compositionname: any;
  medType: any = [];
  medObj: any;
  compositions: any = []
  loader: boolean = false;
  toggleValue: boolean = false;
  suggestValue: boolean = false;
  edit: boolean = true;
  values: any = [
    { id: 1, label: "Name", type: "input", class: "fields", value: "", auto: false, vid: "auto1" },
    { id: 2, label: "Composition Name", type: "input", class: "fields", value: "", auto: false, vid: "auto2" },
    // { id: 3, label: "Dosage", type: "input", class: "fields", value: "", auto: false, vid: "auto3" },
    { id: 3, label: "Dosage UOM", type: "input", class: "fields", value: "", auto: false, vid: "auto4" },
    { id: 4, label: "Medicine Type", type: "input", class: "fields", value: "", auto: true, vid: "auto5" },
    { id: 5, label: "Comments", type: "textarea", class: "fields2", value: "", auto: false, vid: "auto6" },
  ]
  languageCode: string;
  dosageList: any[] = [];
  DosageName;

  constructor(
    public service: DataService, private router: Router,
    public route: ActivatedRoute,
    private translate: TranslateService,
    public app: AppService) { }

  async ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    let url = this.router.url
    let id = url.split('/').pop()
    await this.service.staticData().subscribe((res: any) => {
      this.medType = res.find(dat => dat.typeName == 'MEDICINE_TYPE');
      this.compositions = res.find(dat => dat.typeName == 'COMPOSITION');
    })
    if (url.includes('edit-medicine')) {
      this.loader = true;
      this.edit = false;
      this.getMed(id)
    }
  }

  add() {
    let url = this.router.url
    let id = url.split('/').pop()
    if (url.includes('edit-medicine')) {
      this.DosageName = (document.getElementById("DosageName") as HTMLInputElement).value;
      if (!this.dosageList) {
        this.dosageList = [];
      }
      this.dosageList.push({ medicineId: id, dosage: this.DosageName });
      this.DosageName = '';
    } else {
      this.DosageName = (document.getElementById("DosageName") as HTMLInputElement).value;
      this.dosageList.push({ dosage: this.DosageName });
      this.DosageName = '';
    }
  }

  remove(index: number) {
    this.dosageList.splice(index, 1);
  }


  save() {
    let composition = this.compositions.values.find(res => res.name == this.values[1].value);
    let med = this.medType.values.find(res => res.name == this.values[3].value)
    if (this.values[0].value != "" && this.values[3].value  && this.values[2].value!="") {
      this.loader = true;

      let post = {
        name: this.values[0].value,
        composition: this.values[1].value,
        measurement:this.values[2].value,
        // compositionId: composition.id,
        medicineType: med.id,
        medicineTypeName: this.values[3].value,
        medDosages: [],
        measurementType: this.values[2].value,
        sourceType: 1,
        sourceTypeName: "Medicine",
        comments: this.values[4].value,
        activeInd: this.toggleValue,
        suggestInd: this.suggestValue,
        orgId: localStorage.getItem('organizationId'),
      }

      for (let i = 0; i < this.dosageList.length; i++) {
        post.medDosages.push({ dosage: this.dosageList[i].dosage });
      }

      this.service.createMed(post).subscribe(res => {
        // this.app.openSnackBar("Medicine Created the Medicine","success")
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Medicine Created the Medicine").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
          })
        })
        this.loader = false;
        this.router.navigateByUrl('/getmedicine')
      })


    }
    else {
      // this.app.openSnackBar("Please enter the required fields",'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please enter the required fields").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })

    }

  }
  find(data, id) {
    if (id == 2)
      this.data = this.compositions.values.filter(res => res.name.toLowerCase().includes(data));
    if (id == 4)
      this.data = this.medType.values.filter(res => res.name.toLowerCase().includes(data));
  }
  data1(id) {
    if (id == 2)
      this.data = this.compositions.values;
    if (id == 4)
      this.data = this.medType.values;
  }

  async getMed(id) {
    this.service.getMed(id).subscribe(async (res) => {
      this.medObj = res;
      this.loader = false;
      this.values[0].value = this.medObj.name;
      this.values[1].value = this.medObj.composition
      this.values[2].value = this.medObj.measurement
      this.dosageList = this.medObj.medDosages
      this.values[2].value = this.medObj.measurementType
      this.values[3].value = this.medObj.medicineTypeName
      this.values[4].value = this.medObj.comments
      this.toggleValue = this.medObj.activeInd;
      this.suggestValue = this.medObj.suggestInd;
    })
  }

  putMed() {
    let composition = this.compositions.values.find(res => res.name == this.values[1].value);
    let med = this.medType.values.find(res => res.name == this.values[3].value)
    if (this.values[0].value != "" && this.values[3].value && this.values[2].value != "") {
      this.medObj.name = this.values[0].value
      this.medObj.composition = this.values[1].value
      this.medObj.medDosages = this.dosageList
      // this.medObj.measurement = this.values[2].value
      this.medObj.measurementType = this.values[2].value
      this.medObj.medicineTypeName = this.values[3].value
      this.medObj.comments = this.values[4].value
      this.medObj.medicineType = med.id
      // this.medObj.compositionId = composition.id
      this.medObj.activeInd = this.toggleValue;
      this.medObj.suggestInd = this.suggestValue;
      this.medObj.orgId = localStorage.getItem('organizationId'),
        this.loader = true;
      this.service.putMed(this.medObj.id, this.medObj).subscribe(res => {
        this.loader = false;
        this.router.navigateByUrl('/getmedicine')
      }, err => {
        this.loader = false;
      })
    }
    else {
      // this.app.openSnackBar("Please enter the required fields",'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please enter the required fields").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })

    }
  }

  cancel() {
    this.router.navigateByUrl('/getmedicine')
  }
}
