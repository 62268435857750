import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/api-calls/auth.service';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { DoctorSetupComponent } from '../doctor-setup/doctor-setup.component';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-treatement',
  templateUrl: './treatement.component.html',
  styleUrls: ['./treatement.component.scss']
})
export class TreatementComponent implements OnInit {

  alldepart: any = [];
  name;
  desc;
  code;
  doctoredit: boolean;
  loader;
  url;
  id;
  treatmentObj: any = [];
  languageCode: string;
  items: any = [];
  dataSource: any;
  msgContent: string;
  avgTime;
  langCd: any;
  sittingCount: any;
  activeInd: any;
  treatments: any = [];
  checklocation1: any = [];
  handler: any;
  checkloc: any = [];
  assetTypeVendorTagList: any;
  getalltemplates: any;
  tempName: any;
  selectedId: any;

  constructor(public data: DataService, private translate: TranslateService, public app: AppService, public auth: AuthService) { }

  ngOnInit() {
    this.getTreats();
    this.languageCode = localStorage.getItem('lang');
    this.url = this.app.urlData();
    if (this.url != 'treatement') {
      this.doctoredit = true;
      this.id = this.app.urlData();
      // this.getalltemp();
    } else {
      console.log('jj')
    }
  }

  getTemId() {
    this.data.gettemplateTreatmentBytemplateId(this.id).subscribe((res: any) => {
      this.code = res[0].searchTags;
      this.desc = res[0].summaryText;
      this.name = res[0].summaryName;
      this.selectedId = res[0].treatmentId;
      this.selectSuggest(this.selectedId)
    }, err => {
      this.loader = false;
    })
  }

  selectSuggest(id) {
    this.treatments.map(res => {
      if (res.id === id) {
        res.click = !res.click;
        this.selectedId = res.click ? id : null;
      } else {
        res.click = false;
      }
    });
  }

  treatmentCreate() {
    this.loader = true;
    var post = {
      "summaryName": this.name.toUpperCase(),
      "searchTags": this.code,
      "summaryText": this.desc,
      "treatmentId": this.selectedId,
    }

    this.data.treatmentTemCre(post).subscribe((res: any) => {
      this.loader = false;
      this.app.openPage('treatment-summary');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Treatment Created Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })

    }, err => {
      this.loader = false;
    })
  }
  cancel() {
    this.app.openPage('treatment-summary')
  }



  getTreats() {
    this.data.getTreatmentList().subscribe((res) => {
      this.treatments = res;
      if (this.id) {
        this.getTemId();
      }
      this.loader = false;
    }, err => {
      this.loader = false;
      this.handler.err(err);
    })
  }

  treatmentUpdate() {
    this.loader = true;
    var treatmentlist = [{ templateId: this.selectedId }]
    let post = {
      "summaryName": this.name.toUpperCase(),
      "templateId": this.id,
      "searchTags": this.code,
      "summaryText": this.desc,
      "treatmentId": this.selectedId,
    }
    this.activeInd = true;
    this.data.puttemplateTreatmentBytemplateId(this.id, post).subscribe(res => {
      this.loader = false;
      this.app.openPage('treatment-summary');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Treatment Updated Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })
    }, err => {
      this.loader = false;
    })

  }
}
