import { Component, OnInit,AfterViewInit, HostListener } from '@angular/core';
import { DataService } from "src/api-calls/data.service";
import { AppService } from '../app.service';
import { MatDialog } from "@angular/material";
import { DragulaService } from "ng2-dragula";
import { AssistancepopComponent } from "../assistancepop/assistancepop.component";
import { TranslateService } from '@ngx-translate/core';
import { HandlerService } from "src/api-calls/handler.service";
import { MatTableDataSource, MatSort } from '@angular/material';
@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrls: ['./book-appointment.component.scss'],
  providers: [DragulaService],
})
export class BookAppointmentComponent implements OnInit, AfterViewInit {
  displayedColumns = ['radio', 'patientRefId', 'name'];
  sort: any;
  dataSource: any;
  fasting: any;
  entype: any;
  entryType: any;
  proceedelement: any;
  visitype: any;
  metadata: any;
  reportingTime: any;
  selectdate: Date;
  receiptNbr: any;
  wardNbr: any;
  visitType: any;
  documentNbr: any;
  ngAfterViewInit() {
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }
  loader: boolean;
  alldepart: any;
  docName: any;
  name: any;
  deptId: any;
  doctorId: any;
  add;
  selectedId: any;
  userDetails: any;
  show: boolean;
  orgId: string;
  nullCheck: boolean;
  languageCode: string;
  slots: any;
  address: any;
  duration = 15;
  date;
  refId;
  mobile: any;
  age: any;
  weight: any;
  gender;
  height;
  secMobile: any;
  countrynum = "+91";
  secCntryNum = "+91";
  date1: any;
  date2: string;
  time: string;
  showingDate: boolean = false;
  postTime: any;
  currentDate: Date;
  appointmentData: any;
  doctUserId: string;
  refIdCheck: Object;
  patients: any[] = [
    { name: "In-Patient", id: "2", disable: true },
    { name: "Out-Patient", id: "1", disable: false },
    { name: "MHC", id: "3", disable: true },
    { name: "BedSide", id: "4", disable: true },
  ];
  patientType = this.patients[1].id;
  patientObject: any;
  maxPatientRef: any;
  newPatient: boolean = false;
  phone: any;
  doctorName: string;
  department: string;
  patientId: any;
  enable: boolean = true;
  items: any = [];
  selectedItem: any = [];
  checkpoints: any = [];
  checkObject;
  cloneCheck: any;
  appointMent: boolean = false;
  appointData: any = [];
  entity: any;
  entitiesId: any;
  appointmentId: any;
  id: any;
  viewList: boolean = false;
  selectedValue: any;
  cancelId: any;
  resheduleId: any;
  statuses: any = [];
  cancelmsg: boolean;
  switch: boolean;
  cancelRequest: any;
  notes: string;
  opening: boolean;
  resheduledate: any;
  showtime: boolean = false;
  resheduled: any;
  confirmed: any;
  resheduleItem: any;
  statusId: any;
  selected: any;
  mobileCheck: Object;
  windowcard: any = [];
  windowcapId: any;
  selectedCardIndex: number;
  windowshow: boolean = false;
  patientsdetails: boolean = false;
  windowRedate: any = [];
  rewindowshow: boolean = false;
  departmentId: any;
  itemsForexcel: any;
  departmentName: any;
  dateee;

  constructor(public data: DataService,
    public service: DataService,
    public dialog: MatDialog,
    private translate: TranslateService,
    public handler: HandlerService,
    public dragulaService: DragulaService,
    public app: AppService,) {
    this.currentDate = new Date();
    dragulaService.createGroup("DRAGULA_FACTS", {
      moves: function (el: any, container: any, handle: any): any {
        if (el.classList.contains("king")) {
          return false;
        }
        return true;
      },
    });
  }

  ngOnInit() {
    this.department = "All";
    this.selectedCardIndex = -1;
    this.loader = true;
    this.languageCode = localStorage.getItem('lang');
    this.departmentGet();
    let x = JSON.parse(localStorage.getItem("config"));
    let e = x.find((res) => res.shortCdConst == "PATRFID");
    this.maxPatientRef = e.userValue;
    let r = x.find(res => res.shortCdConst == "BAC");
    if (r)
      this.switch = JSON.parse(r.userValue)

    this.data.getCheckpointsPatientAdd(1).subscribe((res) => {
      this.checkObject = res;
      this.checkObject = this.checkObject.filter(res => {
        return res.activeInd == true;
      })
      this.cloneCheck = JSON.parse(JSON.stringify(res));
      this.loader = false;
    });

  }


  departmentGet() {
    this.data.departmentGetAll().subscribe((res: any) => {
      this.alldepart = res;
      let myObject = { name: "All", id: 0 };
      this.alldepart.push(myObject)
      this.getDoctorsList();
    });
  }

  getDoctorsList() {
    this.loader = true;
    this.data.getDoctorsList().subscribe((res: any) => {
      this.loader = false;
      this.docName = res;
    },
      (err) => {
        this.loader = false;
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err).subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
      }
    );
  }


  getId(data) {
    if (data == "All") {
      this.getDoctorsList()
    } else {
      this.alldepart.map((res) => {
        if (data) {
          if (res.name == data) {
            this.deptId = res.id;
            this.getDoctors(this.deptId)
          }
        }
      });
    }
  }

  openCalendar(id) {
    this.selectedId = id;
    localStorage.setItem('contractor', this.selectedId);
    this.app.openPage('calendar');
  }

  getDoctors(deptId) {
    this.loader = true;
    this.data.getDoctorByDepartmentId(deptId).subscribe((res) => {
      if (res) {
        this.docName = res;
        this.loader = false;
        // this.docName = res[0].name;
        // this.doctorId = res[0].id;
      } else {
        this.loader = false;
      }
    },
      (err) => {
        this.loader = false;
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err).subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
      }
    );
  }

  selectUser(id) {
    this.windowcard = [];
    this.selectedCardIndex = -1;
    if (this.selectedId == id) {
      this.selectedId = '';
    } else {
      this.selectedId = id;
    }
    this.loader = true;
    this.appointMent = false;
    if (this.selectedId) {
      localStorage.setItem('contractor', this.selectedId)
      this.data.getdoctorsById(this.selectedId).subscribe((res: any) => {
        this.userDetails = res;
        this.departmentId = res.departmentId;
        this.doctorName = res.name;
        this.loader = false;
        this.showingDate = true;
        this.date = this.currentDate;
        this.clearTime();
        if (this.date == "") {
          this.show = false;
        }
      });
    }
  }

  checkPreview() {
    this.doctUserId = localStorage.getItem('contractor')
    this.getSlots(this.doctUserId);
  }
  checkPreview1() {
    this.doctUserId = localStorage.getItem('contractor')
    this.getSlots1(this.doctUserId);
  }

  getSlots(Id) {
    let x = this.date.getDate();
    let y = this.date.getMonth() + 1;
    let z = this.date.getFullYear();
    let pass = x + "-" + y + "-" + z;
    let post = {
      targetCenterId: Id,
      targetSource: Id,
      schElements: [1],
      slotDuration: this.duration,
      requestDate: pass,
      status: ["SlotsAvailable"],
      independentEntities: true,
    };
    this.show = true;
    this.data.getAppointmentsbyWindow(pass, post).subscribe(
      (res: any) => {
        if (res == null) {
          this.nullCheck = true;
        } else {
          this.nullCheck = false;
          this.windowcard = res;
          this.windowcard.map((sum) => Object.assign(sum, { style: "none" }));
        }
      },
      (err) => {
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err).subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
      }
    );
  }

  getassoslots(data, i) {
    if (this.selectedCardIndex === i) {
      this.windowshow = !this.windowshow;
    } else {
      this.windowshow = true;
      this.selectedCardIndex = i;
    }
    this.doctUserId = localStorage.getItem('contractor')
    let x = this.date.getDate();
    let y = this.date.getMonth() + 1;
    let z = this.date.getFullYear();
    let pass = x + "-" + y + "-" + z;
    let post = {
      targetCenterId: this.doctUserId,
      targetSource: this.doctUserId,
      schElements: [1],
      slotDuration: this.duration,
      requestDate: pass,
      status: ["SlotsAvailable"],
      independentEntities: true,
      startTime: data.startTime,
      endTime: data.endTime,
    };
    this.show = true;
    this.service.getAppointmentsTime(pass, post).subscribe(
      (res: any) => {
        if (res == null) {
          this.nullCheck = true;
        } else {
          this.nullCheck = false;
          this.slots = res;
          this.slots.map((sum) => Object.assign(sum, { style: "none" }));
        }
      },
      (err) => {
        this.handler.err(err);
      }
    );
  }


  getresheassoslots(data, i) {
    if (this.selectedCardIndex === i) {
      this.rewindowshow = !this.rewindowshow;
    } else {
      this.rewindowshow = true;
      this.selectedCardIndex = i;
    }
    this.doctUserId = localStorage.getItem('contractor')
    let x = this.resheduledate.getDate();
    let y = this.resheduledate.getMonth() + 1;
    let z = this.resheduledate.getFullYear();
    let pass = x + "-" + y + "-" + z;
    let post = {
      targetCenterId: this.doctUserId,
      targetSource: this.doctUserId,
      schElements: [1],
      slotDuration: this.duration,
      requestDate: pass,
      status: ["SlotsAvailable"],
      independentEntities: true,
      startTime: data.startTime,
      endTime: data.endTime,
    };
    this.show = true;
    this.service.getAppointmentsTime(pass, post).subscribe(
      (res: any) => {
        if (res == null) {
          this.nullCheck = true;
        } else {
          this.nullCheck = false;
          this.slots = res;
          this.slots.map((sum) => Object.assign(sum, { style: "none" }));
        }
      },
      (err) => {
        this.handler.err(err);
      }
    );
  }


  getSlots1(Id) {
    let x = this.resheduledate.getDate();
    let y = this.resheduledate.getMonth() + 1;
    let z = this.resheduledate.getFullYear();
    let pass = x + "-" + y + "-" + z;
    let post = {
      targetCenterId: Id,
      targetSource: Id,
      schElements: [1],
      slotDuration: this.duration,
      requestDate: pass,
      status: ["SlotsAvailable"],
      independentEntities: true,
    };
    this.show = true;
    this.service.getAppointmentsbyWindow(pass, post).subscribe(
      (res: any) => {
        if (res == null) {
          this.nullCheck = true;
        } else {
          this.nullCheck = false;
          this.windowRedate = res;
          this.windowRedate.map((sum) => Object.assign(sum, { style: "none" }));
        }
      },
      (err) => {
        this.handler.err(err);
      }
    );
  }
  clearTime() {
    this.time = "Show Time";
    this.slots = [];
    this.show = false;
    this.getAppoints();
    this.checkPreview1();
    this.postTime = "";
  }

  setTime(data, indx) {
    this.time = data.startTime;
    this.postTime = data;
    this.slots.map((res, i) => {
      indx == i
    });
    setTimeout(() => {
      this.show = false;
    }, 200);
    this.windowcard = [];
  }

  setTime2(data, indx) {
    this.time = data.startTime;
    this.postTime = data;
    this.slots.map((res, i) => {
      indx == i
    });
    setTimeout(() => {
      this.show = false;
    }, 200);
    this.windowRedate = [];
  }

  checknewEntity() {
    this.data.getAllnewnextentity().toPromise().then((res) => {
      this.refId = res;
      this.entity = this.refId.patientRefId,
        this.refId = this.entity
    });
  }

  checkPrevEntity() {
    if (this.refId) {
      var post = {}
        if (this.refId) {
          Object.assign(post, { patientRefId: this.refId })
        }
        if (this.mobile) {
          Object.assign(post, { contactNbr: this.mobile })
        }
  
      this.data.searchPatient1(post).toPromise().then((res) => {
        this.mobileCheck = res;
        if (this.mobileCheck != null) {
          this.app.refIdObj = res;
          this.app.loader1 = false;
          this.openDialog(2);
        } else {
          this.focusOutFunction(res);
        }
        (err) => {
          this.app.loader1 = false;
        };
      });
    }
  }


  getAppoints() {

    let month = this.date.getMonth() + 1;
    var fmon;
    month < 10 ? (fmon = "0" + month) : (fmon = month);
    let day = this.date.getDate();
    var fday;
    day < 10 ? (fday = "0" + day) : (fday = day);
    let date = this.date.getFullYear() + "-" + fmon + "-" + fday;
    let post = {
      // statuses: [2, 3, 6],
      apptDate: date,
      // toDate: date,
      // trainerFetch: this.trainerFetch,
      orgId: localStorage.getItem("organizationId"),
      // fetchSpecificOrg: true,
      statuses: [1, 2, 4],
    };

    if (this.selectedId != null) {
      Object.assign(post, { doctorId: this.selectedId });
    }
    this.data.getAppointReq2(post).subscribe(
      (res: any) => {
        if (res != null && res != "NO_CONTENT" && res.length > 0) {
          this.appointMent = true;
          this.appointData = res;
          let x: any = null;
        }
        else {
          this.appointMent = false;
          //  this.handler.res("No Appointments For You");
        }
      },
      (err) => {
        this.handler.err(err);
        this.appointMent = false;
        this.loader = false;
      }
    );
  }


  // clearUhid() {
  //   this.newPatient = !this.newPatient;
  //   if (this.newPatient == true) {
  //     this.refId = null;
  //     this.refId = "";
  //     this.refId = undefined;
  //     // this.checknewEntity();
  //   }
  // }
  clearUhid() {
    if (this.newPatient) {
      this.refId = null;
      this.refId = '';
      // this.cancel();
    }
    if (!this.newPatient) {
      // this.checknewEntity();
    }
  }
  cancel() {
    this.name = "";
    this.mobile = "";
    this.secMobile = "";
    this.age = "";
    this.gender = "";
    this.height = "";
    this.weight = "";
    this.refId = "";
    this.app.aged = false;
    this.app.wheeled = false;
    this.app.callRequired = false;
    this.app.emrgPat = false;
    this.app.vipPat = false;
    this.showtime = false;
    this.app.nri = false;
    this.newPatient = false;
    this.app.nri = false;
    this.doctorId = "";
    this.patientType = this.patients[1].id;
  }

  openDialog(id): void {
    const dialogRef = this.dialog.open(AssistancepopComponent, {
      data: id,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "1") {
        this.patientType = this.patients[1].id;
        this.app.aged = false;
        this.app.wheeled = false;
        this.app.callRequired = false;
        this.app.nri = false;
        this.app.emrgPat = false;
        this.app.vipPat = false;
        this.doctorId = "";
        this.name = "";
        this.mobile = "";
        this.secMobile = "";
        this.age = "";
        this.gender = "";
        this.height = "";
        this.weight = "";
      }
    });
  }

  focusOutFunction(data) {
    if (this.refId || this.mobile) {
      var serviceId;
      if (this.patientType == 1 || this.patientType == 3) {
        serviceId = 1;
      }
      if (this.patientType == 2 || this.patientType == 4) {
        serviceId = 2;
      }
      this.data.getPatientDetails(this.refId, serviceId, this.mobile).subscribe((res) => {
        if (res) {
          this.patientObject = res;
          this.name = this.patientObject.name;
          if (this.patientObject.entityType != 2) {
            var len = this.patientObject.contactNbr.length;
            if (this.patientObject.secContactNbr) {
              var len1 = this.patientObject.secContactNbr.length;
              len1 = len1 - 10;
            }
            len = len - 10;
            if (len1) {
              this.secCntryNum = this.patientObject.secContactNbr.slice(
                0,
                len1
              );
              this.secMobile = this.patientObject.secContactNbr.slice(-10);
            }
            this.countrynum = this.patientObject.contactNbr.slice(0, len);
            this.mobile = this.patientObject.contactNbr.slice(-10);
          }
          this.refId = this.patientObject.patientRefId;
          this.age = this.patientObject.age;
          this.gender = this.patientObject.gender;
          // this.newPatient = this.patientObject.newPatient;
          this.height = this.patientObject.height;
          this.weight = this.patientObject.weight;
          this.app.aged = this.patientObject.aged;
          this.app.wheeled = this.patientObject.wheeled;
          this.app.nri = this.patientObject.internationalPatient;
          this.app.callRequired = this.patientObject.callRequired;
        }
      });
    }
  }

  bookCheck() {
    if (this.date != "" && this.date != null && this.time != "Show Time") {
      this.save();
    } else {
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select Date & Time").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    }
  }

  async save() {
    this.loader = true;
    if (this.name == undefined || this.name == "") {
      return this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please enter the required fields",).subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar");
        });
      })
    }
    else {
      let secondNumb;
      if (this.mobile != null || this.mobile != undefined || this.mobile != "") {
        this.phone = this.countrynum + this.mobile;
      }
      if (this.mobile == null) {
        this.phone = this.countrynum + undefined;
      }
      if (this.mobile == "") {
        this.phone = this.countrynum + undefined;
      }
      if (this.secMobile != null || this.secMobile != undefined || this.secMobile != "") {
        secondNumb = this.secCntryNum + this.secMobile;
      }
      if (this.secMobile == null) {
        secondNumb = this.secCntryNum + undefined;
      }
      if (this.secMobile == "") {
        secondNumb = this.secCntryNum + undefined;
      }
      if (this.date) {
        let z = this.date.getFullYear();
        let month = this.date.getMonth() + 1;
        var fmon;
        month < 10 ? (fmon = "0" + month) : (fmon = month);
        let day = this.date.getDate();
        var fday;
        day < 10 ? (fday = "0" + day) : (fday = day);
        var pass = z + "-" + fmon + "-" + fday;
      }

      var post = {
        name: this.name.toUpperCase(),
        contactNbr: this.phone,
        callRequired: this.app.callRequired,
        aged: this.app.aged,
        wheeled: this.app.wheeled,
        internationalPatient: this.app.nri,
        emrgPat: this.app.emrgPat,
        vipPat: this.app.vipPat,
        age: this.age,
        category: 5,
        entityType: 1,
        height: this.height,
        weight: this.weight,
        doctorName: this.doctorName,
        departmentId: this.departmentId,
        newPatient: this.newPatient,
        secContactNbr: secondNumb,
        appointmentInd: true, 
        bookCheck: true,
        // fromDate: this.currentDate,
        // toDate: pass + this.postTime.startTime + this.postTime.endTime,
        // apptTime: this.postTime.startTime,
        // apptEndTime: this.postTime.endTime,
        visitStatus: 2,

      };
      this.alldepart.map(res => {
        if (res.id == this.departmentId) {
          Object.assign(post, { department: res.name })
        }
      })

      if (this.refId != null && this.refId != undefined && this.refId != "") {
        Object.assign(post, { patientRefId: this.refId });
      }
      if (this.gender)
        Object.assign(post, { gender: this.gender.toUpperCase() });
      if (this.patientType != 4) {
        Object.assign(post, { entityType: this.patientType });
      }
      if (this.patientType == 4) {
        Object.assign(post, { entityType: "2", bedSide: "true" });
      }

      if (this.name) {
        this.saveCheck(post);
      }
    }
  }

  saveCheck(post) {
    this.date1 = this.date .getFullYear() + '/' + ("0" + (this.date .getMonth() + 1)).slice(-2) + '/' + ("0" + this.date .getDate()).slice(-2) + ' ' + '00:00:00'
    this.date2 = this.date.getFullYear() + '/' + ("0" + (this.date.getMonth() + 1)).slice(-2) + '/' + ("0" + this.date.getDate()).slice(-2) + ' ' + '23:59:59'
    this.loader = true;
    this.data.patientCheck(post,this.date1, this.date2).toPromise().then((res: any) => {
      this.patientId = res.id;
      if (this.date && this.time && this.name && this.doctUserId) {
        this.loader = true;
        if (this.date) {
          let z = this.date.getFullYear();
          let month = this.date.getMonth() + 1;
          var fmon;
          month < 10 ? (fmon = "0" + month) : (fmon = month);
          let day = this.date.getDate();
          var fday;
          day < 10 ? (fday = "0" + day) : (fday = day);
          var pass = z + "-" + fmon + "-" + fday;
        }
        let post = {
          "status": 1,
          "doctorId": localStorage.getItem('contractor'),
          "activeInd": true,
          "apptTs": this.currentDate,
          "entityId": this.patientId,
        };
        Object.assign(post, {
          "capacityDefId": this.postTime.capDefId,
          "apptDate": pass,
          "apptTime": this.postTime.startTime,
          "apptEndTime": this.postTime.endTime,
          "newPatient": this.newPatient,
        });
        this.data.appointmentbooking(post).subscribe((res: any) => {
          this.appointmentData = res;
          this.loader = false;
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get('Appointment Booked Successfully').subscribe((translation: string) => {
              this.app.openSnackBar(translation, 'success-snackbar');
            });
          });
          this.name = "";
          this.mobile = "";
          this.secMobile = "";
          this.age = "";
          this.gender = "";
          this.height = "";
          this.weight = "";
          this.enable = true;
          this.selectedItem = [];
          this.refId = "";
          this.patientType = this.patients[1].id;
          this.app.aged = false;
          this.app.wheeled = false;
          this.app.callRequired = false;
          this.app.nri = false;
          this.app.vipPat = false;
          this.app.emrgPat = false;
          this.newPatient = false;
          this.showtime = false;
          this.doctorName = "";
          this.doctorId = "";
          this.appointData = [];
          this.docName = [];
          this.showingDate = false;
          this.appointMent = false;
          this.date = null;
          this.time = "Show Time"
          this.selectedId = undefined;
          this.ngOnInit();
        },
          (err) => {
            this.translate.use(this.languageCode).subscribe(() => {
              this.translate.get(err).subscribe((translation: string) => {
                this.app.openSnackBar(translation, "error-snackbar")
              })
            })
            this.loader = false;
          });
      } else {
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("please select required field").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
      }
    })
      .catch((res) => {
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(res.error.msg).subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar");
          });
        }),
          (this.app.loader1 = false);
      });
  }




  appointmentbookings() {
    if (this.date && this.time && this.refId && this.name && this.doctUserId) {
      this.loader = true;
      if (this.date) {
        let z = this.date.getFullYear();
        let month = this.date.getMonth() + 1;
        var fmon;
        month < 10 ? (fmon = "0" + month) : (fmon = month);
        let day = this.date.getDate();
        var fday;
        day < 10 ? (fday = "0" + day) : (fday = day);
        var pass = z + "-" + fmon + "-" + fday;
      }
      let post = {
        "status": 1,
        "doctorId": localStorage.getItem('contractor'),
        "activeInd": true,
        "apptTs": this.currentDate,
        "entityId": this.refId,
      };
      Object.assign(post, {
        "capacityDefId": this.postTime.capDefId,
        "apptDate": pass,
        "apptTime": this.postTime.startTime,
        "apptEndTime": this.postTime.endTime,
      });
      this.data.appointmentbooking(post).subscribe((res: any) => {
        this.appointmentData = res;
      },
        (err) => {
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get(err).subscribe((translation: string) => {
              this.app.openSnackBar(translation, "error-snackbar")
            })
          })
          this.loader = false;
        });
    } else {
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("please select required field").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
        })
      })
    }

  }


  async getCheck(id) {
    let mapData: any = [];
    // this.workqId = id;
    mapData = this.checkObject.filter((res) => {
      return res.id == id;
    });
    this.selectedItem.push(mapData[0]);
    // this.getReportingTIme();
    this.selectedItem.map((res) => {
      if (res.checkPointPositionId == 1 || res.checkPointPositionId == 2) {
        Object.assign(res, { cla: "king" });
      } else {
        Object.assign(res, { cla: "john" });
      }
    });
    let order = [1, 3, 2];
    this.selectedItem.sort(function (a, b) {
      return (
        order.indexOf(a.checkPointPositionId) -
        order.indexOf(b.checkPointPositionId)
      );
    });
    this.checkpoints = this.checkpoints.filter(
      (res) => !this.selectedItem.find((val) => res.id === val.id)
    );
    this.checkObject = this.checkObject.filter(
      (res) => !this.selectedItem.find((val) => res.id === val.id)
    );
  }



  async getback(id) {
    let mapData: any = [];
    mapData = this.selectedItem.filter((res) => {
      return res.id == id;
    });
    this.checkpoints.push(mapData[0]);
    this.checkObject.push(mapData[0]);
    this.selectedItem = this.selectedItem.filter(
      (res) => !this.checkpoints.find((val) => res.id === val.id)
    );
  }

  async custom() {
    this.loader = true;
    this.add = ""
    this.selectedItem = [];
    this.data.getWorkFlows().subscribe((res) => {
      this.items = res;
      this.loader = false;
    });
    this.enable = false;
  }

  goCheckPoint() {
    this.loader = true;
    this.items = [];
    this.selectedItem = [];
    this.checkpoints = [];
    // this.workFlowId = "";
    this.data.getCheckpointsPatientAdd(this.patientType).subscribe((res) => {
      this.checkObject = res;
      this.loader = false;
    });
    this.enable = true;
  }

  arrivedAppointment(item) {
    this.appointData.map(res => {
      if (item.id == res.id) {
        this.entitiesId = res.entity.id;
        this.id = res.id;
        this.appointmentId = item.apptNbr;
      }
    })
    let post = {
      visitStatus: 2,
      apptNbr: this.appointmentId,
    }
    this.data.appointment(this.id, post).subscribe((res) => {
      let getappointments = res;
      this.ngOnInit();
    })
  }
  showActions(id) {
    if (this.selected == id) {
      this.selected = '';
    } else {

      this.selected = id;
    }
  }

  cancelReson(id, item) {
    this.cancelId = id;
    this.cancelmsg = true;
    this.cancelRequest = item;
  }
  clearPop() {
    this.cancelmsg = false;
    this.notes = "";
  }

  // appointment cancel submittion function
  cancelReq() {
    this.cancelmsg = false;
    this.loader = true;
    var x = {
      cancellationNotes: this.notes,
    };
    this.service.appointmentcancel(this.cancelId, x).subscribe(
      (res: any) => {
        // this.handler.res("Cancelled successfully");
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get('Cancelled successfully').subscribe((translation: string) => {
            this.app.openSnackBar(translation, 'success-snackbar');
          });
        });
        this.notes = "";
        this.ngOnInit();
        this.getAppoints();
      },
      (err) => {
        this.loader = false;
        this.handler.err(err);
      }
    );
  }

  // resedule click function
  reshedules(id, item) {
    this.time = "time";
    this.opening = true;
    this.resheduleId = id;
    this.resheduleItem = item;
  }
  reseduleSubmit() {
    this.doctUserId = localStorage.getItem('contractor')
    this.opening = false;
    let month = this.resheduledate.getMonth() + 1;
    var fmon;
    month < 10 ? (fmon = "0" + month) : (fmon = month);
    let day = this.resheduledate.getDate();
    var fday;
    day < 10 ? (fday = "0" + day) : (fday = day);
    let date = this.resheduledate.getFullYear() + "-" + fmon + "-" + fday;

    // let post = {
    //   doctorId: this.doctUserId,
    //   apptDate: date,
    //   apptTime: this.time,
    // }
    let post = {
      "status": 1,
      "doctorId": localStorage.getItem('contractor'),
      "activeInd": true,
      "apptTs": this.currentDate,
      "entityId": this.patientId,
    }
    Object.assign(post, {
      "capacityDefId": this.postTime.capDefId,
      "apptDate": date,
      "apptTime": this.postTime.startTime,
      "apptEndTime": this.postTime.endTime,
    });
    this.service.appointmentreschedule(this.resheduleId, post).subscribe(
      (res: any) => {
        this.resheduled = res;
        // this.handler.res("Resheduled successfully");
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get('Resheduled successfully').subscribe((translation: string) => {
            this.app.openSnackBar(translation, 'success-snackbar');
          });
        });
        this.ngOnInit();
        this.getAppoints();
      });
  }
  resedulecancel() {
    this.slots = [];
    this.opening = false;
  }
  confirm(id, item) {
    let cofirmedId = id;
    let cofirmedItem = item;

    this.service.appointmentconfirm(cofirmedId, cofirmedItem).subscribe(
      (res: any) => {
        this.confirmed = res;
        // this.handler.res("Confirmed successfully");
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get('Confirmed successfully').subscribe((translation: string) => {
            this.app.openSnackBar(translation, 'success-snackbar');
          });
        });
        this.ngOnInit();
      });
  }

  focusOutFunction1(data) {
    if (this.refId || this.mobile) {
      var serviceId;
      if (this.patientType == 1 || this.patientType == 3) {
        serviceId = 1;
      }
      if (this.patientType == 2 || this.patientType == 4) {
        serviceId = 2;
      }
      this.data.getPatientDetail(serviceId, this.mobile).subscribe((res:any) => {
        if (res) {
          this.patientsdetails = true;
          this.patientObject = res;
          this.dataSource = new MatTableDataSource( this.patientObject)
        }
      });
    } 
  }
  setvalue(a){
    this.proceedelement =a;
  }
  view(){
    if (this.proceedelement == undefined || this.proceedelement == ""|| this.proceedelement == null) {
      return this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select Patient ",).subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar");
        });
      })
    }else{
   const element = this.proceedelement ;
    this.patientsdetails =false;
    this.name = element.name;
    if (element.entityType != 2) {
      var len =element.contactNbr.length;
      if (element.secContactNbr) {
        var len1 = element.secContactNbr.length;
        len1 = len1 - 10;
      }
      len = len - 10;
      if (len1) {
        this.secCntryNum = element.secContactNbr.slice(
          0,
          len1
        );
        this.secMobile =element.secContactNbr.slice(-10);
      }
      this.countrynum = element.contactNbr.slice(0, len);
      this.mobile = element.contactNbr.slice(-10);
    }
    this.refId =element.patientRefId;
    this.age = element.age;
    this.gender =element.gender;
    this.height = element.height;
    this.weight = element.weight;
    this.app.aged = element.aged;
    this.app.wheeled =element.wheeled;
    this.app.nri = element.internationalPatient;
    this.app.callRequired = element.callRequired;
  }
}
  close(){
    this.patientsdetails =false;
  }
  newAdd(){
    if(!this.proceedelement){
      this.patientsdetails =false;
    }

  }
}
