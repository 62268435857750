import { Component, OnInit, OnDestroy } from "@angular/core";
import { DataService } from 'src/api-calls/data.service';
import { HandlerService } from 'src/api-calls/handler.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/api-calls/auth.service';
import { TimepickerComponent, timer } from '../timepicker/timepicker.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calendar-manage',
  templateUrl: './calendar-manage.page.html',
  styleUrls: ['./calendar-manage.page.scss'],
})
export class CalendarManagePage implements OnInit, OnDestroy {
  contractor: string;

  constructor(private service: DataService,
    public dialog: MatDialog, public router: Router,
    public handler: HandlerService, public auth: AuthService) { }

  loader: boolean;
  font;
  items = [];

  ngOnInit() {
    this.font = "normal";
    this.contractor = localStorage.getItem('contractor')
    let a = localStorage.getItem('fontSizeId');
    if (a == '1') {
      this.font = 'normal'
    }
    if (a == '2') {
      this.font = 'medium'
    }
    if (a == '3') {
      this.font = 'large'
    }
    this.items = [
      { name: 'MONDAY', day: "MON", active: false, show: false, slots: [] },
      { name: "TUESDAY", day: "TUE", active: false, show: false, slots: [] },
      { name: "WEDNESDAY", day: "WED", active: false, show: false, slots: [] },
      { name: "THURSDAY", day: "THU", active: false, show: false, slots: [] },
      { name: "FRIDAY", day: "FRI", active: false, show: false, slots: [] },
      { name: "SATURDAY", day: "SAT", active: false, show: false, slots: [] },
      { name: "SUNDAY", day: "SUN", active: false, show: false, slots: [] }
    ]
    let post = {
      "targetCenterId": this.contractor,
      "targetSource": this.contractor,
    }
    this.loader = true;
    // this.items = [];
    this.service.manageCalGet(post).subscribe((res: any) => {
      if (res != null && res != "NO_CONTENT" && res.length > 0) {

        this.items.map(item => {
          res.map(val => {
            if (val.capDefDays.includes(item.day)) {
              item.active = true;
              item.slots.push({ startTime: val.startTime, endTime: val.endTime, edit: false, id: val.id })
            }
          })
        })
      }
      this.loader = false;
    }, err => {
      this.loader = false;
      this.handler.err(err);
    })
  }

  defaulter(data, start, end, what) {
    return data.substring(0, start) + what + data.substring(end);
  };

  open(i) {
    if (i != null) {
      this.items.map((res, j) => {
        if (i == j)
          this.items[i].show = this.items[i].show == false ? true : false;
        else
          res.show = false
      })
    }
  }

  submit() {
    let i = 0;
    let z = 0;
    this.items.map(res => {
      res.slots.map(val => {
        if (!val.id) {
          i = i + 1;
        }
      })
    })

    this.items.map(res => {
      res.slots.map(val => {
        if (!val.id) {
          this.loader = true;
          let post = {
            "activeInd": "Y",
            "startTime": val.startTime,
            "endTime": val.endTime,
            "maxAllowedAppts": "1",
            "perApptDuration": "00:15",
            "capDefDays": [res.day],
            "targetCenterId": localStorage.getItem("contractor"),
            "capDefSchElements": [1]
          }
          if (localStorage.getItem('contractor')) {
              Object.assign(post, { targetCenterId: localStorage.getItem('contractor') })
              Object.assign(post, { targetSource: localStorage.getItem('contractor') })
            }
              this.service.manageCalPost(post).subscribe(res => {
                z = z + 1;
                this.handler.res('Calendar updated successfully');
                this.open(null)
                if (i == z) {
                  this.ngOnInit();
                }
              }, err => {
                this.loader = false;
                this.handler.err(err);
              })
            }

          })

    })


  }
  addSlots(i) {
    let date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    let x = new Date(date).toDateString()
    this.items[i].slots.push({ startTime: '00:00', endTime: '00:00', edit: true })
  }
  removeSlots(i, j, slot) {
    if (slot.id) {
      this.loader = true;
      this.service.deleteSlots(slot.id).subscribe(res => {
        this.loader = false;
        this.handler.res('Deleted Successfully');
        this.ngOnInit();
        this.open(null)
      }, err => {
        this.loader = false;
        this.handler.err(err)
      })
    }
    else {
      this.items[i].slots.splice(j, 1);
    }
  }

  openModel(id, i, j): void {
    const dialogRef = this.dialog.open(TimepickerComponent, {
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(() => {
      if (id == 1) {
        this.items[i].slots[j].startTime = timer;
      }
      if (id == 2) {
        this.items[i].slots[j].endTime = timer;
      }
    });
  }
  back() {
    if (this.contractor != null) {
      localStorage.setItem('contractor', this.contractor)
    }
    this.router.navigate(['calendar'])
  }
  ngOnDestroy(): void {
    if (this.contractor != null) {
      // sessionStorage.setItem("contractor", this.contractor)
    }
    // localStorage.removeItem('contractor')
  }

}
