import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { ExcelService } from 'src/api-calls/excel.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-viewpatients',
  templateUrl: './viewpatients.component.html',
  styleUrls: ['./viewpatients.component.scss']
})
export class ViewpatientsComponent implements OnInit,OnDestroy {

  checkPointId;
  checkPoint: any;
  patients: any;
  patientsClone: any;
  missedData: any;
  missedDataClone: any;
  skippedData: any;
  skippedDataClone: any;
  checkedInData: any;
  checkedInDataClone: any;
  patData:any;
  loader: boolean = false;
  checkpoints;
  select1 = '0';
  selectDate: any;
  selectDate1: any;

  missedLen: any = [];
  skippedLen: any = [];
  completedLen: any = [];
  checkedInLen: any = [];
  iplengthC: any = [];
  oplengthC: any = [];
  bslengthC: any = [];
  intllengthC: any = [];
  mhlengthC: any = []
  iplengthM: any = [];
  oplengthM: any = [];
  bslengthM: any = [];
  intllengthM: any = [];
  mhlengthM: any = []
  iplengthS: any = [];
  oplengthS: any = [];
  bslengthS: any = [];
  intllengthS: any = [];
  mhlengthS: any = []
  iplengthCH: any = [];
  oplengthCH: any = [];
  bslengthCH: any = [];
  intllengthCH: any = [];
  mhlengthCH: any = []
  currentDatesearch: boolean = false;
  searchS;
  searchC;
  searchM;
  date1;
  date2;
  bsC: any = [];
  intlC: any = [];
  ipC: any = [];
  opC: any = [];
  mhC: any = [];
  bsM: any = [];
  intlM: any = [];
  ipM: any = [];
  opM: any = [];
  mhM: any = [];
  bsS: any = [];
  intlS: any = [];
  ipS: any = [];
  opS: any = [];
  mhS: any = [];
  bsCH: any = [];
  intlCH: any = [];
  ipCH: any = [];
  opCH: any = [];
  mhCH: any = [];
  expandObj: any = [];
  notePop:boolean=false;
  reportReadyNotification:boolean;
  reportCollected:boolean;
  opencard: any = null;
  expandcard: boolean = false;
  workQId;
  clinica:boolean;
  patientName: any = [];
  languageCode: string;
  constructor(public data: DataService,
    public app: AppService,
    private excelService: ExcelService,
    private translate: TranslateService,
    public router : Router) { }

  async  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    // this.loader = true;
    var url = this.app.sendUrl()
    this.checkPointId = url.split('/').pop()
    this.select1 = this.checkPointId
    if (this.app.fromDate != undefined) {
      this.selectDate = this.app.fromDate;
    }
    else {
      this.selectDate = new Date();
    }
    if (this.app.toDate != undefined) {
      this.selectDate1 = this.app.toDate;
    }
    else {
      this.selectDate1 = new Date();
    }
    var today = new Date();
    this.date1 = this.selectDate.getFullYear() + '/' + ("0" + (this.selectDate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectDate.getDate()).slice(-2) + ' ' + '00:00:00'
    this.date2 = this.selectDate1.getFullYear() + '/' + ("0" + (this.selectDate1.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectDate1.getDate()).slice(-2) + ' ' + '23:59:59'
    this.search()
    let y = JSON.parse(localStorage.getItem('capability'));
    this.app.mobCab= y.includes('MOBNOVISIB') ;
    
    let x = JSON.parse(localStorage.getItem('config'));
    let f = x.find(res => res.shortCdConst == "CLNQENBL");
    if(f)
    this.clinica = JSON.parse(f.userValue);
    this.userCall();
  }
  async search() {
    this.oplengthC=undefined;
     this.iplengthC=undefined;
     this.mhlengthC=undefined;
     this.intllengthC=undefined;
     this.bslengthC=undefined;
     this.completedLen=undefined;
     this.missedLen=undefined;
     this.skippedLen=undefined;
     this.checkedInLen=undefined;
     this.oplengthCH=undefined;
     this.iplengthCH=undefined;
     this.mhlengthCH=undefined;
     this.intllengthCH=undefined;
     this.bslengthCH=undefined;
     this.bslengthM=undefined;
     this.iplengthM=undefined;
     this.mhlengthM=undefined;
     this.intllengthM=undefined;
     this.oplengthM=undefined;
     this.oplengthS=undefined;
     this.iplengthS=undefined;
     this.mhlengthS=undefined;
     this.bslengthS=undefined;
     this.intllengthS=undefined;
    if (this.checkPointId != undefined) {
      this.loader = true;
      this.checkPoint = await this.data.getCheckpointById(this.checkPointId).toPromise();
      this.reportReadyNotification= this.checkPoint.reportReadyNotification
      this.reportCollected = this.checkPoint.reportCollectNotification
      var workqid = this.checkPoint.worq.id;
      this.workQId = this.checkPoint.worq.id;
      this.patients = await this.data.getCompleted(workqid, this.date1, this.date2).toPromise();
      this.patientsClone = this.patients;
      if (this.patients) {
        this.patients.map(res=>{
          if(!res.notes)
          Object.assign(res,{notes:" "})
          if(!res.reportReady)
          Object.assign(res,{reportReady:false,reportReadyNotification:this.reportReadyNotification,reportCollect:this.reportCollected})
          else
           Object.assign(res,{reportReadyNotification:this.reportReadyNotification,reportCollect:this.reportCollected})
        })
        var bs = this.patients.filter(res => { return res.bedSide == true })
        this.bsC = bs;
        var intl = this.patients.filter(res => { return res.internationalPatient == true })
        this.intlC = intl;
        var ip = this.patients.filter(res => { return res.entityType == 2 && res.bedSide == false })
        this.ipC = ip;
        var op = this.patients.filter(res => { return res.entityType == 1 })
        this.opC = op;
        var mhc = this.patients.filter(res => { return res.entityType == 3 })
        this.mhC = mhc
        let len = this.patients.length;
        let oplen = op.length
        let iplen = ip.length
        let bslen = bs.length
        let intllen = intl.length
        let mhlen = mhc.length
        if (oplen > 9) {
          this.oplengthC = oplen;
        }
        else {
          this.oplengthC = '0' + oplen;
        }
        if (iplen > 9) {
          this.iplengthC = iplen;
        }
        else {
          this.iplengthC = '0' + iplen;
        }
        if (bslen > 9) {
          this.bslengthC = bslen;
        }
        else {
          this.bslengthC = '0' + bslen;
        }
        if (intllen > 9) {
          this.intllengthC = intllen;
        }
        else {
          this.intllengthC = '0' + intllen;
        }
        if (len > 9) {
          this.completedLen = len;
        }
        else {
          this.completedLen = '0' + len;
        }
        if (mhlen > 9) {
          this.mhlengthC = mhlen;

        }
        else {
          this.mhlengthC = '0' + mhlen;

        }
      }
      this.missedData = await this.data.getMissed1(workqid, this.date1, this.date2).toPromise();
      this.missedDataClone = this.missedData;
      if (this.missedData) {
        var bs = this.missedData.filter(res => { return res.bedSide == true })
        this.bsM = bs;
        var intl = this.missedData.filter(res => { return res.internationalPatient == true })
        this.intlM = intl;
        var ip = this.missedData.filter(res => { return res.entityType == 2 && res.bedSide == false })
        this.ipM = ip;
        var op = this.missedData.filter(res => { return res.entityType == 1 })
        this.opM = op;
        var mhc = this.missedData.filter(res => { return res.entityType == 3 })
        this.mhM = mhc
        let len = this.missedData.length;
        let oplen = op.length
        let iplen = ip.length
        let bslen = bs.length
        let intllen = intl.length
        let mhlen = mhc.length
        if (oplen > 9) {
          this.oplengthM = oplen;
        }
        else {
          this.oplengthM = '0' + oplen;
        }
        if (iplen > 9) {
          this.iplengthM = iplen;
        }
        else {
          this.iplengthM = '0' + iplen;
        }
        if (bslen > 9) {
          this.bslengthM = bslen;
        }
        else {
          this.bslengthM = '0' + bslen;
        }
        if (intllen > 9) {
          this.intllengthM = intllen;
        }
        else {
          this.intllengthM = '0' + intllen;
        }
        if (len > 9) {
          this.missedLen = len;
        }
        else {
          this.missedLen = '0' + len;
        }
        if (mhlen > 9) {
          this.mhlengthM = mhlen;

        }
        else {
          this.mhlengthM = '0' + mhlen;

        }
      }
      this.skippedData = await this.data.getSkipped1(workqid, this.date1, this.date2).toPromise();
      this.skippedDataClone = this.skippedData;
      if (this.skippedData) {
        var bs = this.skippedData.filter(res => { return res.bedSide == true })
        this.bsS = bs;
        var intl = this.skippedData.filter(res => { return res.internationalPatient == true })
        this.intlS = intl;
        var ip = this.skippedData.filter(res => { return res.entityType == 2 && res.bedSide == false })
        this.ipS = ip;
        var op = this.skippedData.filter(res => { return res.entityType == 1 })
        this.opS = op;
        var mhc = this.skippedData.filter(res => { return res.entityType == 3 })
        this.mhS = mhc;

        let oplen = op.length
        let iplen = ip.length
        let bslen = bs.length
        let intllen = intl.length
        let mhlen = mhc.length
        let len = this.skippedData.length;
        if (oplen > 9) {
          this.oplengthS = oplen;
        }
        else {
          this.oplengthS = '0' + oplen;
        }
        if (iplen > 9) {
          this.iplengthS = iplen;
        }
        else {
          this.iplengthS = '0' + iplen;
        }
        if (bslen > 9) {
          this.bslengthS = bslen;
        }
        else {
          this.bslengthS = '0' + bslen;
        }
        if (intllen > 9) {
          this.intllengthS = intllen;
        }
        else {
          this.intllengthS = '0' + intllen;
        }
        if (len > 9) {
          this.skippedLen = len;
        }
        else {
          this.skippedLen = '0' + len;
        }
        if (mhlen > 9) {
          this.mhlengthS = mhlen;
        }
        else {
          this.mhlengthS = '0' + mhlen;
        }
      }
      this.checkedInData = await this.data.getCheckedIn1(workqid, this.date1, this.date2).toPromise();
      this.checkedInDataClone = this.checkedInData;
      if (this.checkedInData) {
        var bs = this.checkedInData.filter(res => { return res.bedSide == true })
        this.bsCH = bs;
        var intl = this.checkedInData.filter(res => { return res.internationalPatient == true })
        this.intlCH = intl;
        var ip = this.checkedInData.filter(res => { return res.entityType == 2 && res.bedSide == false })
        this.ipCH = ip;
        var op = this.checkedInData.filter(res => { return res.entityType == 1 })
        this.opCH = op;
        var mhc = this.checkedInData.filter(res => { return res.entityType == 3 })
        this.mhCH = mhc;
        let len = this.checkedInData.length;
        let oplen = op.length
        let iplen = ip.length
        let bslen = bs.length
        let intllen = intl.length
        let mhlen = mhc.length
        if (oplen > 9) {
          this.oplengthCH = oplen;
        }
        else {
          this.oplengthCH = '0' + oplen;
        }
        if (iplen > 9) {
          this.iplengthCH = iplen;
        }
        else {
          this.iplengthCH = '0' + iplen;
        }
        if (bslen > 9) {
          this.bslengthCH = bslen;
        }
        else {
          this.bslengthCH = '0' + bslen;
        }
        if (intllen > 9) {
          this.intllengthCH = intllen;
        }
        else {
          this.intllengthCH = '0' + intllen;
        }
        if (len > 9) {
          this.checkedInLen = len;
        }
        else {
          this.checkedInLen = '0' + len;
        }
        if (mhlen > 9) {
          this.mhlengthCH = mhlen;
        }
        else {
          this.mhlengthCH = '0' + mhlen;
        }
      }
      this.checkpoints = await this.data.getCheckpoints().toPromise()
      this.loader = false;
      if (this.skippedData) {
        let len = this.skippedData.length;
        if (len > 9) {
          this.skippedLen = len;

        }
        else {
          this.skippedLen = '0' + len;
        }
      }
      this.checkedInData = await this.data.getCheckedIn1(workqid, this.date1, this.date2).toPromise();
      if (this.checkedInData) {
        let len = this.checkedInData.length;
        if (len > 9) {
          this.checkedInLen = len;
        }
        else {
          this.checkedInLen = '0' + len;
        }
      }
      this.checkpoints = await this.data.getCheckpoints().toPromise()
      this.loader = false;

    }
  }
  async search2() {
    this.checkPointId = this.select1

    this.date1 = this.selectDate.getFullYear() + '/' + ("0" + (this.selectDate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectDate.getDate()).slice(-2) + ' ' + '00:00:00'
    this.date2 = this.selectDate1.getFullYear() + '/' + ("0" + (this.selectDate1.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectDate1.getDate()).slice(-2) + ' ' + '23:59:59'
    this.search()
  }
  cancel() {
    this.ngOnInit()
  }

  filter(id, id1) {
    if (id == 1) {
      if (id1 == 1) {
        this.patients = this.ipC;
      }
      if (id1 == 2) {
        this.patients = this.opC;
      }
      if (id1 == 3) {
        this.patients = this.bsC;
      }
      if (id1 == 6) {
        this.patients = this.intlC;
      }
      if (id1 == 4) {
        this.patients = this.patientsClone;
      }
      if (id1 == 5) {
        this.patients = this.mhC;
      }
    }
    if (id == 2) {
      if (id1 == 1) {
        this.missedData = this.ipM;
      }
      if (id1 == 2) {
        this.missedData = this.opM;
      }
      if (id1 == 3) {
        this.missedData = this.bsM;
      }
      if (id1 == 6) {
        this.missedData = this.intlM;
      }
      if (id1 == 4) {
        this.missedData = this.missedDataClone;
      }
      if (id1 == 5) {
        this.missedData = this.mhM;
      }
    }
    if (id == 3) {
      if (id1 == 1) {
        this.skippedData = this.ipS;
      }
      if (id1 == 2) {
        this.skippedData = this.opS;
      }
      if (id1 == 3) {
        this.skippedData = this.bsS;
      }
      if (id1 == 6) {
        this.skippedData = this.intlS;
      }
      if (id1 == 4) {
        this.skippedData = this.skippedDataClone;
      }
      if (id1 == 5) {
        this.skippedData = this.mhS;
      }
    }
    if (id == 4) {
      if (id1 == 1) {
        this.checkedInData = this.ipCH;
      }
      if (id1 == 2) {
        this.checkedInData = this.opCH;
      }
      if (id1 == 3) {
        this.checkedInData = this.bsCH;
      }
      if (id1 == 6) {
        this.checkedInData = this.intlCH;
      }
      if (id1 == 4) {
        this.checkedInData = this.checkedInDataClone;
      }
      if (id1 == 5) {
        this.checkedInData = this.mhCH;
      }
    }

  }

  async expand(data) {
    if (data.opencard) {
      data.opencard = false;
    }
    else {
      this.expandObj=[];
      this.expandObj = await this.data.getWorkflowbyPatient(data.id).toPromise();
      this.expandObj = this.expandObj[0].checkpoints;
      this.expandObj.map(res=>{
        Object.assign(res, { clas: '' });
        if(res.status==1){
          res.clas='checkIn';
        }
        if(res.status==2){
          res.clas='queue';
        }
        if(res.status==3){
          res.clas='current';
        }
        if(res.status==4){
          res.clas='complete';
        }
        if(res.status==5){
          res.clas='miss';
        }
        if(res.status==8){
          res.clas='scheduled';
        }
      })
      if(this.missedData){
      this.missedData.map(res => {
        Object.assign(res, { opencard: false })
      })
    }
    if(this.skippedData){
      this.skippedData.map(res => {
        Object.assign(res, { opencard: false })
      })
    }
    if(this.patients){
      this.patients.map(res => {
        Object.assign(res, { opencard: false })
      })
    }
    if(this.checkedInData){
      this.checkedInData.map(res => {
        Object.assign(res, { opencard: false })
      })
    }
      Object.assign(data, { opencard: true })
    }
  }

  exportAsXLSX(id): void {
    if (id == 1) {
      let data: any = [];
      let patients: any = [];
      patients=JSON.parse(JSON.stringify(this.patients));
      patients.map((res,i: any) => {
        let sno = i+1
        Object.assign(res,{SNO:sno})
        if (res.entryTs) {
          res.entryTs =this.setDate(res.entryTs)
        }
        if (res.startTs) {
          res.startTs = this.setDate(res.startTs)
        }
        if (res.exitTs) {
          res.exitTs =this.setDate(res.exitTs)
        }
        if(res.entityType == 1){
          res.entityType = 'OP'
        }
        
        if(res.entityType == 2 && res.bedSide){
          res.entityType = 'BS'
        }
        if(res.notes){
          res.notes=res.notes
        }
        if(res.entityType == 2 && !res.bedSide){
          res.entityType = 'IP'
        }
        if(res.reportReady){
          res.reportReady='Y'
        }
        if(!res.reportReady){
          res.reportReady='N'
        }
        if(res.entityType == 3){
          res.entityType = 'MHC'
        }
        if (res.reportCollectTs) {
          res.reportCollectTs =this.setDate(res.reportCollectTs)
        }
        if (res.reportReadyTs) {
          res.reportReadyTs =this.setDate(res.reportReadyTs)
        }
        if(res.reportReadyMarkedBy){
          this.patientName.map(dat=>{
            if(res.reportReadyMarkedBy == dat.userId){
              Object.assign(res,{reportname:dat.firstName})
            }
          }) 
        }
        // res.blockEndTime = res.blockEndTime.slice(-18, -13);

        data.push({SNO:res.SNO, UHID: res.patientRefId, Name: res.name, Metadata: res.metadata, EntityType: res.entityType, EntryTime: res.entryTs, StartTime: res.startTs, ExitTime: res.exitTs,Notes:res.notes,ReportReady:res.reportReady,
          ReportReadyTs:res.reportReadyTs,ReportCollectTs:res.reportCollectTs,ReportReadyMarkedBy:res.reportname })
      }
      )

      this.excelService.exportAsExcelFile(data, 'Completed');
    }
    if (id == 2) {
      let data: any = [];
      let missedData: any = [];
      missedData=JSON.parse(JSON.stringify(this.missedData));
      missedData.map((res,i:any) => {
        let sno = i+1
        Object.assign(res,{SNO:sno})
        if (res.entryTs) {
          res.entryTs =this.setDate(res.entryTs)
        }
        if (res.startTs) {
          res.startTs = this.setDate(res.startTs)
        }
        if (res.exitTs) {
          res.exitTs = this.setDate(res.exitTs)
        }
        if(res.entityType == 1){
          res.entityType = 'OP'
        }
        if(res.notes){
          res.notes=res.notes
        }
        if(res.entityType == 2 && res.bedSide){
          res.entityType = 'BS'
        }
        
        if(res.entityType == 2 && !res.bedSide){
          res.entityType = 'IP'
        }
        
        if(res.entityType == 3){
          res.entityType = 'MHC'
        }
        data.push({SNO:res.SNO, UHID: res.patientRefId, Name: res.name, Metadata: res.metadata, EntityType: res.entityType, EntryTime: res.entryTs, StartTime: res.startTs, ExitTime: res.exitTs })
      }
      )

      this.excelService.exportAsExcelFile(data, 'Missed');
    }
    if (id == 3) {
      let data: any = [];
      let skippedData: any = [];
      skippedData=JSON.parse(JSON.stringify(this.skippedData));
      this.skippedData.map((res,i:any) => {
        let sno = i+1
        Object.assign(res,{SNO:sno})
        if (res.entryTs) {
          res.entryTs = this.setDate(res.entryTs)
        }
        if (res.startTs) {
          res.startTs =this.setDate(res.startTs)
        }
        if (res.exitTs) {
          res.exitTs =this.setDate(res.exitTs)
        }
        if(res.entityType == 1){
          res.entityType = 'OP'
        }
        if(res.notes){
          res.notes=res.notes
        }
        if(res.entityType == 2 && res.bedSide){
          res.entityType = 'BS'
        }
        
        if(res.entityType == 2 && !res.bedSide){
          res.entityType = 'IP'
        }
        
        if(res.entityType == 3){
          res.entityType = 'MHC'
        }
        data.push({SNO:res.SNO, UHID: res.patientRefId, Name: res.name, Metadata: res.metadata, EntityType: res.entityType, EntryTime: res.entryTs, StartTime: res.startTs, ExitTime: res.exitTs })
      }
      )

      this.excelService.exportAsExcelFile(data, 'Skipped');
    }
    if (id == 4) {
      let data: any = [];
      let checkedInData: any = [];
      checkedInData=JSON.parse(JSON.stringify(this.checkedInData));
      checkedInData.map((res,i:any) => {
        let sno = i+1
        Object.assign(res,{SNO:sno})
        if (res.entryTs) {
          res.entryTs =this.setDate(res.entryTs);
        }
        if (res.startTs) {
          res.startTs = this.setDate(res.startTs)
        }
        if (res.exitTs) {
          res.exitTs = this.setDate(res.exitTs)
        }
        if(res.entityType == 1){
          res.entityType = 'OP'
        }
        if(res.notes){
          res.notes=res.notes
        }
        if(res.entityType == 2 && res.bedSide){
          res.entityType = 'BS'
        }
        
        if(res.entityType == 2 && !res.bedSide){
          res.entityType = 'IP'
        }
        
        if(res.entityType == 3){
          res.entityType = 'MHC'
        }
        data.push({SNO:res.SNO, UHID: res.patientRefId, Name: res.name, Metadata: res.metadata, EntityType: res.entityType, EntryTime: res.entryTs, StartTime: res.startTs, ExitTime: res.exitTs})
      })

      this.excelService.exportAsExcelFile(data, 'CheckedIn');
    }
  }

  userCall(){
    var post = {
      "userType":1
    }
    this.data.searchUser(post).subscribe(res => {
      if(res)
      this.patientName = res;
    })
  }
  addReview(id) {
    this.loader = true;
    this.data.addReview(this.workQId, id).subscribe(res => {
      this.loader = false;
      // this.app.openSnackBar('Patient added for review successfully', 'success-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Patient added for review successfully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
        })
    })
    },
      err => {
        this.loader = false;
        // this.app.openSnackBar(err.error.msg, 'error-snackbar')
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err.error.msg).subscribe((translation: string) => {
              this.app.openSnackBar(translation, "error-snackbar")
          })
      })
      })
  }

 addNotes(data){
   this.patData=data;
   this.notePop=true;
 
 }
 putNotes(){
  this.notePop=false;
 this.data.addPatientNotes(this.patData.workQId,this.patData.id,this.patData.notes).subscribe(res=>{
  this.ngOnInit()
  },err=>{
    // this.app.openSnackBar(err.error.msg,'error');
    this.translate.use(this.languageCode).subscribe(() => {
      this.translate.get(err.error.msg).subscribe((translation: string) => {
          this.app.openSnackBar(translation, "error-snackbar")
      })
  })
  })
 }
 ngOnDestroy(){
 this.notePop=false;
 this.app.fromDate= undefined;
 this.app.toDate= undefined;
 }

 reportReady(data){
   data.reportReady=!data.reportReady
    this.data.sendReportNotifictaion(data.workQId, data.id,data.reportReady).subscribe(res => {
      // this.app.openSnackBar('Updated SuccessFully', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Updated SuccessFully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
        })
    })
       this.ngOnInit()
   })
 }
 reportCollect(data){
   data.reportsCollected=!data.reportsCollected
   this.data.sendReportCollectedNotifictaion(data.workQId,data.id,data.reportsCollected).subscribe(res=>{
    //  this.app.openSnackBar('Updated SuccessFully', 'success-snackbar');
     this.translate.use(this.languageCode).subscribe(() => {
      this.translate.get("Updated SuccessFully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
      })
  })
     this.ngOnInit()
   })

 }
  setDate(date){
    let x=new Date(date)
    let y=("0" + (x.getDate())).slice(-2)+'/'+("0" + (x.getMonth() + 1)).slice(-2)+'/'+x.getFullYear()+' '+("0"+x.getHours()).slice(-2)+':'+("0"+x.getMinutes()).slice(-2);
    return y
  }

  diagnosis(data){
      this.router.navigate(['/diagnosis',data.id]);
  }
}
