import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { AuthService } from 'src/api-calls/auth.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import * as sha512 from 'js-sha512'; 
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgotpass',
  templateUrl: './forgotpass.component.html',
  styleUrls: ['./forgotpass.component.scss']
})
export class ForgotpassComponent implements OnInit {
  phoneOtp:string;
  emailOtp:string;


  email;
  mobnumber;
  
  validateEnable:boolean = false;
  sendOtpEnable:boolean = false;

  phoneInput:string = 'dontShow';
  emailInput:string = 'dontShow';

  checkValidate:number;
  user:any;

  showMsg:boolean = false;
  errMsg:boolean = false;
  otp1:boolean = true;
  otp2:boolean = false;
  forgotForm1: FormGroup;
  forgotForm2: FormGroup;
  languageCode: string;

  constructor(public app: AppService,
    public formBuilder:FormBuilder,
    private auth: AuthService,
    private translate: TranslateService,
    public toastController: ToastController) { }

  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.forgotForm1 = this.formBuilder.group({
      emailId: ['', Validators.required],
      otp1: ['', [Validators.required,Validators.minLength(6), Validators.maxLength(6)]],
    });
    this.forgotForm2 = this.formBuilder.group({
      countrynum:['+91',Validators.required],
      contactNbrMob: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(13)]],
      otp2: ['', [Validators.required,Validators.minLength(6), Validators.maxLength(6)]],
    });
    this.user = JSON.parse(sessionStorage.getItem('validateUser'));
  }
  checkBox(id){
    if(this.forgotForm1.get('emailId').valid || this.forgotForm2.get('contactNbrMob').valid ){
      this.sendOtpEnable = true;
    }
    else{
      this.sendOtpEnable = false;
    }
    this.checkValidate = id;
    if(id == 1){
      this.otp1=true;
      this.otp2=false;
    }
    if(id == 2){
      this.otp1=false;
      this.otp2=true;
    }
  }
  sendForgetOtp() {
    var cntNum=this.forgotForm2.get('countrynum').value;
      let mobile:any=this.forgotForm2.get('contactNbrMob').value;
      if(mobile!=''){
        var phone=cntNum+mobile;
        this.user={contactNbrMob:phone}
      }
      else{
       this.user={emailId:this.forgotForm1.get('emailId').value}
      }
    this.auth.sendForgetOtp(this.user).toPromise()
    .then((res => {
      this.showMsg = true;
      // this.app.openSnackBar('Otp Sent SuccessFully','success-sncakbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Otp Sent SuccessFully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
        })
    })
      setTimeout(() => {
        this.checkValidate == 1 ? this.emailInput = 'input': this.phoneInput = 'input';
        this.showMsg = false;
      }, 2000);
    })
    )
    .catch(
      err=>{
        this.forgotForm1.controls['emailId'].setValue('');
        this.forgotForm1.controls['contactNbrMob'].setValue('');
        // this.presentToast(err.error.msg)
        
      }
    )
  }
  validateForgetOtp(){
   this.emailOtp = this.forgotForm1.get('otp1').value;
   this.phoneOtp = this.forgotForm2.get('otp2').value;
    if (this.emailOtp.length == 6 ||  this.phoneOtp.length  == 6){
   
    if(this.emailOtp){
      this.user.mailOTP= sha512.sha512(this.emailOtp.toString());
    }
    if(this.phoneOtp ){
      this.user.mobileOTP= sha512.sha512(this.phoneOtp.toString());
    }
    this.auth.validMobileOtp(this.user).toPromise()
    .then((res:any) =>{
      localStorage.setItem('hash',res.hashKey);
      localStorage.setItem('current',JSON.stringify(res.userId));
      localStorage.setItem('UserObject',JSON.stringify(res));
      this.app.openPage('createpass');
    })

    .catch(
      err=>{
        this.errMsg==true
        this.forgotForm1.controls['emailId'].setValue('');
        this.forgotForm1.controls['otp1'].setValue('');
        this.forgotForm1.controls['contactNbrMob'].setValue('');
        this.forgotForm1.controls['otp2'].setValue('');
        // this.app.openSnackBar(err.error.msg,'error-snackbar')
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err.error.msg).subscribe((translation: string) => {
              this.app.openSnackBar(translation, "error-snackbar")
          })
      })
        this.forgotForm1.controls['contactNbrMob'].setValue('');
        this.forgotForm1.controls['emailId'].setValue('');
      }
    )
    }
  
  }

  onPasteForemail(event:ClipboardEvent){
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    this.forgotForm1.controls['otp1'].setValue(pastedText)
    this.validateForgetOtp()
  }
  onPasteFormobile(event:ClipboardEvent){
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    this.forgotForm2.controls['otp2'].setValue(pastedText)
    this.validateForgetOtp()
  }
}
