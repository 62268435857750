import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatCalendarBody } from '@angular/material';
import { AppService } from '../app.service';
import { DataService } from 'src/api-calls/data.service';
import { AuthService } from 'src/api-calls/auth.service';
import {FormControl} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-assistancepop',
  templateUrl: './assistancepop.component.html',
  styleUrls: ['./assistancepop.component.scss']
})
export class AssistancepopComponent implements OnInit {

  aged: boolean = false;
  wheeled: boolean = false;
  callRequired: boolean = false;
  loader: boolean = false;
  nri: boolean = false;
  category: any = [];
  categoryId = 0;
  refId;
  refIdCheck: any;
  customWorkId;
  patientId;
  name;
  checkId;
  checkIdMove;
  checkObject: any = [];
  metadata;
  editFlow: boolean = false;
  patientObject: any;
  getWorkFlow: any = [];
  selectedItem: any = [];
  workFlowId;
  workFlowName;
  showToken;
  token;
  selectdate: Date= new Date();
  reportingTime;
  workqTime;
  checkSend;
  workqId;
  entitiesObj:any=[];
  selectedEntities:any=[];
  entityId:any;
  body;
  selectedcheckPoints:any=[];
  checkObj:any=[];
  selectcheck;
  toppings = new FormControl();
  checkObjectMove:any=[];
  emrgPat:boolean =false;
  vipPat:boolean =false;
  countrynum = '+91';
  phone;
  mobile;
  languageCode: string;


  constructor(public dialogRef: MatDialogRef<AssistancepopComponent>, public app: AppService,
    @Inject(MAT_DIALOG_DATA) public data, public dialog: MatDialog,
    private translate: TranslateService, public service: DataService) { }

  async ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    let x = JSON.parse(localStorage.getItem('config'));
    let a = x.find(res => res.shortCdConst == "TKNAUTO");
    this.showToken = a.userValue;
    this.callRequired = this.app.callRequired;
    this.wheeled = this.app.wheeled;
    this.aged = this.app.aged;
    this.nri = this.app.nri;
    this.emrgPat = this.app.emrgPat;
    this.vipPat = this.app.vipPat;
    if (this.data == 3) {
      this.category = await this.service.staticForPatientCategory().toPromise();
      this.checkId = sessionStorage.getItem('checkPointId');
      if (this.checkId) {
        this.checkObject = await this.service.getCheckpointById(this.checkId).toPromise();
        Object.assign(this.checkObject, { stepNbr: 1 })

      }
    }
    if(this.data==4 || this.data==6){
      if(this.data==6){
        this.checkObjectMove = await this.service.getCheckpoints().toPromise();
      }
      this.checkSend=sessionStorage.getItem('checkPointSend');
      this.workqId=sessionStorage.getItem('workqId');
      this.entitiesObj=await this.service.getWorkqById(this.workqId).toPromise()
      if(this.entitiesObj.entities){
      this.entitiesObj=this.entitiesObj.entities;
      this.entitiesObj=this.entitiesObj.filter(res=>{return res.entityType!=2})
      }

    }
    if(this.data==5){
     this.checkObj=await this.service.getCheckpoints().toPromise();
     let id=sessionStorage.getItem('preReqId');
     if(id!=undefined && id!=null && id!="null"){
       this.checkObj=this.checkObj.filter(res=>{return res.id!=id});
     }
     if(this.app.preRequest.length > 0){
       this.checkObj.map(res=>{
         this.app.preRequest.map(res1=>{
           if(res.id==res1){
            this.selectedcheckPoints.push(res); 
           }
         })
       })
     }
       }
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(AssistancepopComponent, {

    });
    this.dialogRef.close();
  }
  click(i) {
    if (i == 0) {
      this.callRequired = !this.callRequired;
    }
    if (i == 1) {
      this.wheeled = !this.wheeled;
    }
    if (i == 2) {
      this.aged = !this.aged;
    }
    if (i == 3) {
      this.nri = !this.nri;
    }
    if (i == 4) {
      this.vipPat = !this.vipPat;
    }
    if (i == 5) {
      this.emrgPat = !this.emrgPat;
    }

    // if (this.items[i].color) {
    //   delete this.items[i]['color'];
    // }
    // else {
    //   Object.assign(this.items[i], { color: 'light' });
    // }
  }
  cancel() {
    this.dialogRef.close();
  }
  cancel1() {
    this.dialogRef.close(1);
  }
  save1() {
    this.app.callRequired = this.callRequired;
    this.app.wheeled = this.wheeled;
    this.app.aged = this.aged;
    this.app.nri = this.nri;
    this.app.emrgPat =this.emrgPat;
    this.app.vipPat = this.vipPat;
    this.dialogRef.close();
  }
  route() {
    this.dialogRef.close();
    const mq = window.matchMedia("(min-width: 700px)")
    if (mq.matches) {
      this.app.openPage('patientEdit/' + this.app.refIdObj[0].id);
    }
    else {
      this.app.openPage('patedit-mob/' + this.app.refIdObj[0].id)
    }
  }
  
  async checkIn() {
    if (!this.name || !this.refId) {
      // this.app.openSnackBar('Please enter the required fields', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please enter the required fields").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
    }
    if (this.name && this.refId) {
      this.loader = true;
      if (this.name) {
        this.name = this.name.toUpperCase();
      }

      let post: any;
      if (this.categoryId == 1) {
        post = {
          name: this.name,
          contactNbr: '+91' + this.mobile,
          patientRefId: this.refId,
          category: this.categoryId,
          metadata: this.metadata,
          entityType: 2,
          tokenNbr: this.token
        };

        this.selectdate = new Date();
        let date = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2);
        let hr:any=this.selectdate.getHours();
        let min:any=this.selectdate.getMinutes();
        if(hr < 10){
         hr='0'+hr;
        }
        if(min < 10){
          min='0'+min;
         }
          Object.assign(this.checkObject, { apptTimeStr: date + ' ' + hr + ":" + min })
      }
      if(this.checkObject.cpType == 2){
        this.selectdate = new Date();
        let date = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2);
        let hr:any=this.selectdate.getHours();
        let min:any=this.selectdate.getMinutes();
        if(hr < 10){
         hr='0'+hr;
        }
        if(min < 10){
          min='0'+min;
         }
          Object.assign(this.checkObject, { apptTimeStr: date + ' ' + hr + ":" + min })
      }
      if (this.categoryId != 1) {
        post = {
          name: this.name,
          contactNbr: '+91' + this.mobile,
          patientRefId: this.refId,
          category: this.categoryId,
          metadata: this.metadata,
          entityType: 1,
          tokenNbr: this.token
        };
      }
      if (!this.editFlow) {

        this.service.patientCheckin(post).toPromise()
          .then((res: any) => {
            this.patientId = res.id
            if (this.patientId != undefined) {
              var post1 = { name: this.name, description: this.name, checkpoints: [this.checkObject], predefined: false, entityId: this.patientId };
              this.service.createWorkFlow(post1).toPromise()
                .then((res: any) => {
                  this.customWorkId = res.id;
                  if (this.patientId != undefined) {
                    this.service.patientAssignWorkFlow(this.customWorkId, this.patientId, post).subscribe(
                      res => {
                        this.loader = false;
                        this.name = "";
                        this.dialogRef.close();
                        // this.app.openSnackBar(' Patient Checked-In Successfully !!', 'success-snackbar')
                        this.translate.use(this.languageCode).subscribe(() => {
                          this.translate.get("Patient Checked-In Successfully").subscribe((translation: string) => {
                              this.app.openSnackBar(translation, "success-snackbar")
                          })
                      })
                      },
                      err => {
                        this.app.openSnackBar(err, 'error-snackbar')
                        this.translate.use(this.languageCode).subscribe(() => {
                          this.translate.get(err).subscribe((translation: string) => {
                              this.app.openSnackBar(translation, "error-snackbar")
                          })
                      })
                        this.loader = false
                      }
                    );
                  }
                });
            }

          })
          .catch(res => {
            // this.app.openSnackBar(res.error.msg, 'error-snackbar'),
            this.translate.use(this.languageCode).subscribe(() => {
              this.translate.get(res.error.msg).subscribe((translation: string) => {
                  this.app.openSnackBar(translation, "error-snackbar")
              })
          })
              this.loader = false;
          }
          )
      }
      else {
        var workObject = { name: this.name, description: this.name, checkpoints: this.selectedItem, predefined: false };
        this.patientObject.category = this.categoryId;
        this.patientObject.metadata = this.metadata,
          this.service.editPatient(this.patientId, this.patientObject).toPromise()
            .then((res: any) => {
            });
        this.getWorkFlow.checkpoints = this.selectedItem
        this.service.updateWorkFlow(this.workFlowId, this.getWorkFlow).toPromise()
          .then((res: any) => {
            // this.customWorkId=res.id ;
            this.loader = false;
            this.dialogRef.close();
            // this.app.openSnackBar('Patient Details Updated Successfully !!', 'success-snackbar')
            this.translate.use(this.languageCode).subscribe(() => {
              this.translate.get("Patient Details Updated Successfully").subscribe((translation: string) => {
                  this.app.openSnackBar(translation, "success-snackbar")
              })
          })

          })
          .catch(
            err => {
              this.loader = false
            }
          )
      }
    }
  }
  checkPrevEntity() {
    if (this.refId) {
      var post = {
        patientRefId: this.refId
      }
      this.service.searchPatient1(post).toPromise().then(res => {

        this.refIdCheck = res
        if (this.refIdCheck != null) {
          this.app.refIdObj = res
          this.editFlow = true;
          this.openDialog1(2)
          this.patientId = this.app.refIdObj[0].id;
          this.service.getPatientById(this.patientId).subscribe(res => {
            this.patientObject = res;

            this.name = this.patientObject.name;
            this.metadata = this.patientObject.metadata;

            this.service.getWorkflowbyPatient(this.patientId).subscribe(res => {
              this.getWorkFlow = res[0];
              if (this.getWorkFlow) {
                this.workFlowId = this.getWorkFlow.id;
                this.workFlowName = this.getWorkFlow.name;
              }

              this.loader = false;
              if (this.getWorkFlow) {
                this.selectedItem = this.getWorkFlow.checkpoints;
                Object.assign(this.checkObject, { stepNbr: this.selectedItem.length })
                this.selectedItem = this.selectedItem.filter(res => { return res.id != this.checkObject.id });
                this.selectedItem.push(this.checkObject);


                this.selectedItem = this.selectedItem.sort((a, b) => { return a.stepNbr - b.stepNbr });
                // this.selectedItem = this.selectedItem.sort((a, b) => { return a.stepNbr - b.stepNbr });



              }
            })
            setTimeout(() => {
              this.loader = false;
            }, 2000);

          });
        }
        else {
          this.editFlow = false;
        }
      })
    }
  }
  
  openDialog1(id): void {
    const dialogRef = this.dialog.open(AssistancepopComponent, {
      data: id
    });
    this.dialogRef.close();
  }
  check(e){
    this.entitiesObj.map(res=>{
      if(res.id==e){
        this.selectedEntities.push(res);
      }
    })
  }
  removeChip(i) {
    this.selectedEntities.splice(i, 1);
  }
  move(){
    if(this.body=="" || this.entityId.length < 1){
      // this.app.openSnackBar('Please enter the required fields', 'error-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please enter the required fields").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
    }
    else{
    this.service.moveEntity(this.workqId,this.checkIdMove,this.entityId).toPromise()
    .then((res) => {
      this.dialogRef.close(21);
      // this.app.openSnackBar('Moved Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Moved Successfully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
        })
    })

    })
    .catch(
      err => {
        // this.app.openSnackBar(err.error.msg, 'error-snackbar');
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err.error.msg).subscribe((translation: string) => {
              this.app.openSnackBar(translation, "error-snackbar")
          })
      })
        // this.presentToast(err.error.msg)
      }
    )
    }
  }
  check1(e){

    this.checkObj.map(res=>{
      if(res.id==e){
        this.selectedcheckPoints.push(res);
      }
    })
    this.checkObj=this.checkObj.filter(res=>{return res.id!=e})
     }
     removeChip1(i) {
       this.checkObj.push(this.selectedcheckPoints[i]);
      this.selectedcheckPoints.splice(i, 1);
    }
    saveCheck(){
      this.app.preRequest=this.selectedcheckPoints;
      this.dialogRef.close();
    }
    send(){
      if(this.body=="" || this.entityId.length < 1){
        // this.app.openSnackBar('Please enter the required fields', 'error-snackbar');
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Please enter the required fields").subscribe((translation: string) => {
              this.app.openSnackBar(translation, "error-snackbar")
          })
      })
      }
      else{
      let post;
      post={
        workqId:this.workqId,
        chptId:this.checkSend,
        body:this.body,
        entityIds:this.entityId
      }
      this.service.sendMsg(post).toPromise()
      .then((res) => {
        this.dialogRef.close();
        // this.app.openSnackBar('Message sent Successfully !!', 'success-snackbar');
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Message sent Successfully").subscribe((translation: string) => {
              this.app.openSnackBar(translation, "success-snackbar")
          })
      })
      })
      .catch(
        err => {
          // this.app.openSnackBar(err.error.msg, 'error-snackbar');
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get(err.error.msg).subscribe((translation: string) => {
                this.app.openSnackBar(translation, "error-snackbar")
            })
        })
          // this.presentToast(err.error.msg)
        }
      )
      }
    }

}
