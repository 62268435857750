import { Directive, Output,EventEmitter,OnDestroy, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[appPageScroll]'
})
export class PageScrollDirective implements OnDestroy {
   scrollEvent;
  @Output() onScroll = new EventEmitter();
  constructor(private el: ElementRef) {
  this.scrollEvent= fromEvent(el.nativeElement,'scroll').subscribe((e:any)=>{

          if(( e.target.offsetHeight + e.target.scrollTop) >=  e.target.scrollHeight) {
        this.onScroll.emit(e)
          }
   
  })

  
  }
  ngOnDestroy() {
    this.scrollEvent.unsubscribe();
  }
}
