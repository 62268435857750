import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material';
import { WorqMpopComponent } from '../worq-mpop/worq-mpop.component';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { AuthService } from 'src/api-calls/auth.service';
import { interval } from 'rxjs';
import { BlockPopComponent } from '../block-pop/block-pop.component';
import { AssistancepopComponent } from '../assistancepop/assistancepop.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-worq-mob',
  templateUrl: './worq-mob.component.html',
  styleUrls: ['./worq-mob.component.scss']
})

export class WorqMobComponent implements OnInit {

  que: any = 1;
  items: any = [];
  checkPoint: any = 0;
  subCheckPointId: any = 0;
  subCheckPointId1;
  workq: any = [];
  selectWorkq: any = [];
  selectWorkq5: any = [];
  selectWorkq1: any = [];
  selectWorkqFirst: any;
  selectWorkqRem: any = [];
  workFlowId;
  checkPointName: any;
  msgContent: string = "Select checkpoint to display content";
  firstMsg: string;
  disable: boolean = false;
  subCheckPoints: any = [];
  enableNext: boolean = false;
  missedData: any = []
  indexValue: any;
  cloneIndex;
  loader: boolean = false;
  loader1: boolean = false;
  url;
  show: string = 'false';
  subCheckName: any;
  workqid;
  userName: boolean;
  userMob: boolean;
  refId: boolean;
  showToken: string;
  wheeled: any = [];
  callRequired: any = [];
  aged: any = [];
  inPatient: any = [];
  mpatient: any = [];
  nriPatient: any = [];
  filterId;
  filterEnable: boolean = false;
  location: any;
  checkpointCheck: any = [];
  checkArray: any = [];
  colorId;
  expandObj: any = [];
  expandId;
  expandBoolean: boolean = false;
  checkAvail: boolean = false;
  checkObjecttById: any;
  languageCode: string;




  constructor(public data: DataService, public app: AppService,
    private translate: TranslateService,
    public dialog: MatDialog, public auth: AuthService) {
    interval(15000).subscribe(() => {
      if (this.url == "worq") {
        if (this.checkPoint != 0 && !this.loader && this.checkPoint != undefined)
          this.check5()
      }
      var url1 = this.app.sendUrl()
      if (url1.includes('worque')) {
        if (this.checkPoint != 0 && !this.loader && this.checkPoint != undefined)
          this.checkPoint = sessionStorage.getItem('checkId');
        this.check5()
      }
    })
  }

  async change(id) {
    if (this.checkPoint != 0) {
      this.que = id;
      if (id == 2) {
        this.loader = true;
    await this.data.getMissed(this.workqid).toPromise().then(res=>{
      if(res)
      this.missedData = res;
      else
      this.missedData=[];
    })
        if (this.missedData) {
          this.missedData.map(res => Object.assign(res, { sell: 'col' }));
        }
        this.loader = false;
      }
      if (id == 3) {
        this.loader = true;
        await this.data.getSkipped(this.workqid).toPromise().then(res=>{
          if(res)
          this.missedData = res;
          else
          this.missedData=[];
        })
        if (this.missedData) {
          this.missedData.map(res => Object.assign(res, { sell: 'col' }));
        }
        this.loader = false;
      }
    }
    else {
      // this.app.openSnackBar('Please Select a Checkpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Deleted Successfully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
        })
    })
    }

  }

  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.app.showFull = false;
    this.url = window.location.href.split('/').pop();
    var url = this.app.sendUrl()
    this.data.getCheckpoints().subscribe(res => this.items = res)
    if (url.includes('worque')) {
      var url1 = this.url.split('/').pop();
      this.checkPoint = url1;

      sessionStorage.setItem('checkId', url1)
      sessionStorage.setItem('subCheckId', '0')

      this.check();
    }
    else {

      if (sessionStorage.getItem('checkId')) {
        this.check();
      }
    }
    let x = JSON.parse(localStorage.getItem('config'));
    // x = x.find(res => res.id == 10);

    // this.userName=x.configs[0].userValue;
    // this.userMob=x.configs[1].userValue;
    // this.refId=x.configs[2].userValue;
    let a = x.find(res => res.shortCdConst == "TKNAUTO");
    this.showToken = a.userValue;
    let b = x.find(res => res.shortCdConst == "WQMBVNM");
    this.userName = b.userValue;
    let c = x.find(res => res.shortCdConst == "WQMBVMB");
    this.userMob = c.userValue;



  }

  openDialog(): void {
    const dialogRef = this.dialog.open(WorqMpopComponent)
    dialogRef.afterClosed().subscribe(result => {
      if (result == 1) {
        this.check();
      }
    });
  }

  async check() {
    this.loader = true;
    this.msgContent = "";
    this.checkPoint = sessionStorage.getItem('checkId');
    this.subCheckPointId = sessionStorage.getItem('subCheckId');
    this.subCheckPoints = [];
    this.disable = false;
    this.app.workQData = '';
    this.app.moveBackToQ = false;
    this.app.checkPointName = '';
    this.selectWorkqFirst = '';
    this.selectWorkqRem = [];
    this.missedData = [];
    this.wheeled = [];
    this.aged = [];
    this.callRequired = [];
    this.mpatient = [];
    this.nriPatient = [];
    this.inPatient = [];
    this.checkArray = [];
    this.checkpointCheck = [];

    if (this.checkPoint != undefined && this.checkPoint != 0) {
      this.subCheckPoints = await this.data.getCheckpointById(this.checkPoint).toPromise();
      this.app.forceStartRea = await this.data.staticForFStart().toPromise();
      this.app.forceRemoveRea = await this.data.staticForFRemove().toPromise();
      if(this.subCheckPoints.checkpointChecks.length>0){
      this.checkpointCheck = this.subCheckPoints.checkpointChecks;
      this.checkpointCheck.map(res => {
        Object.assign(res, { check: false })
      })
    }
      this.checkObjecttById = this.subCheckPoints;
      this.checkAvail = this.checkObjecttById.available;
      this.location = this.subCheckPoints.location;
      sessionStorage.setItem("noDrop", 'true');

      if (this.subCheckPointId != 0) {
        this.subCheckName = this.subCheckPoints.subCheckpoints.filter(res => { return res.id == this.subCheckPointId })
        this.subCheckName = this.subCheckName[0]
      }
      else {
        this.subCheckName = '';
      }
      this.workqid = this.subCheckPoints.worq.id
      this.subCheckPoints = this.subCheckPoints.subCheckpoints;
      if (this.que == 2) {
        await this.data.getMissed(this.workqid).toPromise().then
        (res=>{
          if(res)
          this.missedData=res;
          else
          this.missedData=[];
        })
        if (this.missedData) {
          this.missedData.map(res => Object.assign(res, { sell: 'col' }));
        }
      }
      if (this.que == 3) {
       await this.data.getSkipped(this.workqid).toPromise().then(res=>{
          if(res)
          this.missedData=res;
          else
          this.missedData=[];
        })
        if (this.missedData) {
          this.missedData.map(res => Object.assign(res, { sell: 'col' }));
        }
      }

      if (this.workqid) {
        this.selectWorkq = await this.data.getWorkqById(this.workqid).toPromise();
        this.checkPointName = this.items.filter(res => res.id == this.checkPoint);
        this.checkPointName = this.checkPointName[0];
        this.app.checkPointName = this.checkPointName;
      }
    }

    this.workFlowId = this.selectWorkq.id;
    if (this.selectWorkq.entities != null) {

      this.selectWorkq = this.selectWorkq.entities.sort((a, b) => { return a.positionInQ - b.positionInQ });
      this.selectWorkqFirst = this.selectWorkq.filter(res => { return res.positionInQ == 0 });



      // if(this.selectWorkqFirst.subCheckpoint){

      if (this.subCheckPointId > 1) {

        this.selectWorkqFirst = this.selectWorkqFirst.filter(res => {
          if (res.subCheckpoint) {
            return res.subCheckpoint.id == this.subCheckPointId
          }
        })
      }
      // }
      this.selectWorkqFirst.map(res => Object.assign(res, { sell: 'col' }));
      let a: any = this.selectWorkq.filter(res => { return res.positionInQ > 0 })
      this.wheeled = a.filter(res => { return res.wheeled == true })
      this.aged = a.filter(res => { return res.aged == true })
      this.callRequired = a.filter(res => { return res.callRequired == true })
      this.inPatient = a.filter(res => { return res.entityType == 2 })
      this.mpatient = a.filter(res => { return res.entityType == 3 })
      this.nriPatient = a.filter(res => { return res.internationalPatient == true })
      this.selectWorkqRem = this.selectWorkq.filter(res => { return res.positionInQ > 0 })
      this.selectWorkqRem.map(res => Object.assign(res, { sell: 'col' }));
    }
    this.loader = false;

    this.selectWorkqFirst ? this.firstMsg = "" : this.firstMsg = "No current patient";

    if (this.selectWorkqFirst || this.selectWorkqRem.length > 0) {
      this.msgContent = "";
    }
    else {
      this.msgContent = "No Patients in Queue";
    }


  }

  expand(id, data, id1) {
    this.expandFunc(id1);
    if (data == 1) {
      if (this.selectWorkqRem[id].sell == 'col') {
        this.selectWorkqRem.map((res, i) => {
          if (i != id) {
            res.sell = 'col'
          }
          else {
            res.sell = 'col2'
          }
        });
      }
      else {
        this.selectWorkqRem[id].sell = 'col';
      }
    }
    if (data == 3) {
      if (this.selectWorkqFirst[id].sell == 'col') {
        this.selectWorkqFirst.map((res, i) => {
          if (i != id) {
            res.sell = 'col'
          }
          else {
            res.sell = 'col2'
          }
        });
      }
      else {
        this.selectWorkqFirst[id].sell = 'col';
      }
    }
    if (data == 2) {
      if (this.missedData[id].sell == 'col') {
        this.missedData.map((res, i) => {
          if (i != id) {
            res.sell = 'col'
          }
          else {
            res.sell = 'col2'
          }
        });
      }
      else {
        this.missedData[id].sell = 'col';
      }
    }

  }

  done(data) {
    if (this.subCheckPoints && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Deleted Successfully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
        })
    })
    }
    else {
      this.data.workqCompleted(this.workFlowId, data.id, this.subCheckPointId).toPromise()
        .then(res => {

          this.enableNext = false;
          this.check();
          // this.app.openSnackBar('Completed Successfully', 'success-snackbar');
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get("Deleted Successfully").subscribe((translation: string) => {
                this.app.openSnackBar(translation, "success-snackbar")
            })
        })

        })
        .catch();
    }
    // if (this.subCheckPoints == null) {
    //   this.data.workqCompleted1(this.workFlowId, data.id).toPromise()
    //     .then(res => {

    //       this.enableNext = false;
    //       this.check();
    //       this.app.openSnackBar('Completed Successfully', 'success-snackbar');

    //     })
    //     .catch();
    // }
  }


  async forceDelete(data) {

    if (this.subCheckPoints && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Deleted Successfully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
        })
    })
    }
    else {

      await this.data.forceRemove(this.workFlowId, data.id, this.subCheckPointId, this.app.comments).toPromise()
        .then((res: any) => {
          this.check();

        });

    }
    // if (this.subCheckPoints == null) {

    //   await this.data.forceRemove1(this.workFlowId, data.id, this.app.comments).toPromise()
    //     .then((res: any) => {
    //       this.check();
    //     })
    // }
  }
  async currentForceDelete(data) {

    if (this.subCheckPoints && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Deleted Successfully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
        })
    })
    }
    else {
      await this.data.forceRemove(this.workFlowId, data.id, this.subCheckPointId, this.app.comments).toPromise()
        .then((res: any) => {
          this.check();
        });
    }
  }
  async forceDeleteMissed(data) {

    if (this.subCheckPoints && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })

    }
    else {

      await this.data.forceRemoveMissed(this.workFlowId, data.id, this.subCheckPointId, true).toPromise()
        .then((res: any) => {
          this.check();

        });

    }
    // if (this.subCheckPoints == null) {

    //   await this.data.forceRemove1Missed(this.workFlowId, data.id, true).toPromise()
    //     .then((res: any) => {
    //       this.check();
    //     })
    // }
  }

  async forceStart(data) {

    if (this.subCheckPoints && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })

    }

    else {
      await this.data.forceStart(this.workFlowId, data.id, this.subCheckPointId, false, this.app.comments, this.app.moveBackToQ).toPromise()
        .then((res: any) => {
          this.check();

          this.disable = true;
        });
    }

    // if (this.subCheckPoints == null) {
    //   await this.data.forceStart1(this.workFlowId, data.id, false, this.app.moveBackToQ, this.app.comments).toPromise()
    //     .then((res: any) => {

    //       this.check();
    //     });
    // }
  }
  async forceWait(data) {

    if (this.subCheckPoints && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })

    }
    else {
      await this.data.forceWait(this.workFlowId, data.id, this.subCheckPointId).toPromise()
        .then((res: any) => {
          this.check();

        });
    }
    // if (this.subCheckPoints == null) {
    //   await this.data.forceWait1(this.workFlowId, data.id).toPromise()
    //     .then((res: any) => {
    //       this.check();


    //     });
    // }
  }
  async missed(data) {

    if (this.subCheckPoints && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })

    }
    else {
      await this.data.missed(this.workFlowId, data.id, this.subCheckPointId).toPromise()
        .then((res: any) => {
          this.check();
        });
    }
    // if (this.subCheckPoints == null) {
    //   await this.data.missed1(this.workFlowId, data.id).toPromise()
    //     .then((res: any) => {
    //       this.check();

    //     });
    // }
  }


  start(data) {

    if (this.subCheckPoints && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
    }
    else {
      this.data.startQ(this.workFlowId, data.id, this.subCheckPointId).subscribe(res => {
        this.check();
        this.disable = true;
        this.enableNext = false;
      })
    }
    // if (this.subCheckPoints == null) {
    //   this.data.startQ1(this.workFlowId, data.id).subscribe(res => {
    //     this.check();
    //     this.disable = true;
    //     this.enableNext = false;

    //   })
    // }

  }
  next() {
    this.disable = false;
    this.enableNext = false;
    if (this.subCheckPoints && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
    }


    else {
      if (this.selectWorkqRem.length < 1 && !this.selectWorkqFirst) {
        // this.app.openSnackBar('No patient in queue', 'error-snackbar')
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("No patient in queue").subscribe((translation: string) => {
              this.app.openSnackBar(translation, "error-snackbar")
          })
      })
      }
      else {
        this.loader1 = true;
        this.data.nextQ(this.workFlowId, this.subCheckPointId, this.checkArray)
          .subscribe(res => {
            this.enableNext = true;
            this.disable = false;
            this.check();
            this.loader1 = false
          },
            err => {
              this.loader1 = false;
            }
          )
      }
    }

    // else {
    //   this.loader1=true;
    //   this.data.nextQ(this.workFlowId, this.subCheckPointId)
    //     .subscribe(res => {
    //       this.enableNext = true;
    //       this.disable = false;
    //       this.check();
    //       this.loader1=false
    //     },
    //     err=>{
    //       this.loader1 = false;
    //     }
    //     )
    // }
  }

  async forceStart1(data) {
    if (this.subCheckPoints && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
    }
    else {
      await this.data.forceStart(this.workFlowId, data.id, this.subCheckPointId, true, this.app.comments, this.app.moveBackToQ).toPromise()
        .then((res: any) => {
          this.check();
          this.disable = true;
        });
    }
    // if (this.subCheckPoints == null) {
    //   await this.data.forceStart1(this.workFlowId, data.id, true, this.app.moveBackToQ, this.app.comments).toPromise()
    //     .then((res: any) => {
    //       this.check();
    //     });
    // }
  }

  Popup(id, data): void {
    this.loader = true;
    if (this.subCheckPoints && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
    }
    else {
      if (this.selectWorkqFirst.length > 0 || id == 6 || id == 7 || id == 11) {
        const dialogRef = this.dialog.open(BlockPopComponent, {
          data: id
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result != "cancel") {
          }
          if (result == 1) {
            this.forceStart(data);
          }
          if (result == 2) {
            this.forceDelete(data);
          }
          if (result == 3) {
            this.forceStart1(data);
          }
        });
      }
      if (this.selectWorkqFirst.length == 0 && id == 3) {
        const dialogRef = this.dialog.open(BlockPopComponent, {
          data: 15
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result != "cancel") {
          }
          if (result == 15) {
            this.forceStart(data);
          }
        });
      }
    }
    this.loader = false;
  }

  // ngOnDestroy(): void {
  //   this.url = "";
  //   this.checkPoint = 0;
  // }

  async check5() {

    if (this.checkPoint != undefined) {
      this.subCheckPoints = await this.data.getCheckpointById(this.checkPoint).toPromise();
      var workqid = this.subCheckPoints.worq.id
      this.subCheckPoints = this.subCheckPoints.subCheckpoints;


      if (this.selectWorkq) {
        this.selectWorkq5 = await this.data.getWorkqById(workqid).toPromise();
        this.checkPointName = this.items.filter(res => res.id == this.checkPoint);
        this.checkPointName = this.checkPointName[0];
        this.app.checkPointName = this.checkPointName;

      }
    }

    this.workFlowId = this.selectWorkq5.id;
    if (this.selectWorkq5.entities != null) {
      let a: any;
      this.selectWorkq5 = this.selectWorkq5.entities.sort((a, b) => { return a.positionInQ - b.positionInQ });
      // this.selectWorkq = this.selectWorkq5;

      if (this.selectWorkq5 != this.selectWorkq) {
        this.selectWorkq = this.selectWorkq5;

        this.selectWorkqFirst = this.selectWorkq5.filter(res => { return res.positionInQ == 0 });

        if (this.subCheckPointId != 0) {

          this.selectWorkqFirst = this.selectWorkqFirst.filter(res => {
            if (res.subCheckpoint) {
              return res.subCheckpoint.id == this.subCheckPointId
            }
          })
        }
        // this.selectWorkqFirst.map(res=>{
        //   if(res.subCheckpoint){
        //     if(this.subCheckPointId!=0){
        //       
        //       this.selectWorkqFirst=[];
        //    this.selectWorkqFirst.push(res.filter(res2=>{return res2.subCheckpoint==this.subCheckPointId}))
        // 
        //    }
        // }})


        this.selectWorkqFirst.map(res => Object.assign(res, { sell: 'col' }));
        let a: any = this.selectWorkq5.filter(res => { return res.positionInQ > 0 });

        this.wheeled = a.filter(res => { return res.wheeled == true })
        this.aged = a.filter(res => { return res.aged == true })
        this.callRequired = a.filter(res => { return res.callRequired == true })
        this.inPatient = a.filter(res => { return res.entityType == 2 })
        this.mpatient = a.filter(res => { return res.entityType == 3 })
        this.nriPatient = a.filter(res => { return res.internationalPatient == true })
        this.selectWorkqRem = this.selectWorkq5.filter(res => { return res.positionInQ > 0 });
        this.selectWorkqRem.map(res => Object.assign(res, { sell: 'col' }));
        this.filter(this.filterId);
      }

    }
    if (this.selectWorkqFirst) {

      this.firstMsg = "";
    }
    else {

      this.firstMsg = "No current patient";
    }




    if (this.selectWorkqFirst || this.selectWorkqRem.length > 0) {
      this.msgContent = "";
    }
    else {
      this.msgContent = "No Patients in Queue";
    }
  }
  filter(id) {
    this.filterId = id;
    if (id == 1) {
      this.filterEnable = true;
      this.selectWorkqRem = this.callRequired;
      this.selectWorkqRem.map(res => Object.assign(res, { sell: 'col' }));
    }
    if (id == 2) {
      this.filterEnable = true;
      this.selectWorkqRem = this.wheeled;
      this.selectWorkqRem.map(res => Object.assign(res, { sell: 'col' }));
    }
    if (id == 3) {
      this.filterEnable = true;
      this.selectWorkqRem = this.aged;
      this.selectWorkqRem.map(res => Object.assign(res, { sell: 'col' }));
    }
    if (id == 4) {
      this.filterEnable = true;
      this.selectWorkqRem = this.inPatient;
      this.selectWorkqRem.map(res => Object.assign(res, { sell: 'col' }));
    }
    if (id == 5) {
      this.filterEnable = false;
      this.selectWorkqRem = this.selectWorkq.filter(res => { return res.positionInQ > 0 })
      this.selectWorkqRem.map(res => Object.assign(res, { sell: 'col' }));
    }
    if (id == 6) {
      this.filterEnable = true;
      this.selectWorkqRem = this.mpatient;
      this.selectWorkqRem.map(res => Object.assign(res, { sell: 'col' }));
    }
    if (id == 7) {
      this.filterEnable = true;
      this.selectWorkqRem = this.nriPatient;
      this.selectWorkqRem.map(res => Object.assign(res, { sell: 'col' }));
    }


  }
  quickCheckIn(id) {
    if (this.checkPoint != undefined && this.checkPoint != 0) {
      sessionStorage.setItem('checkPointId', this.checkPoint)
      const dialogRef = this.dialog.open(AssistancepopComponent, {
        data: id
      });
    }
    else {
      // this.app.openSnackBar('Please Select a Checkpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a Checkpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
    }

  }
  sendMsg(id) {
    if (this.checkPoint != undefined && this.checkPoint != 0) {
      sessionStorage.setItem('workqId', this.workqid);
      sessionStorage.setItem('checkPointSend', this.checkPoint);
      const dialogRef = this.dialog.open(AssistancepopComponent, {
        data: id
      });
    }
    else {
      // this.app.openSnackBar('Please Select a Checkpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a Checkpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
    }

  }

  checkEdit(event) {
    this.loader = true;
    this.checkAvail=event.checked;
    this.checkObjecttById.available = this.checkAvail;
    this.data.updateCheckpointsById(this.checkPoint, this.checkObjecttById).toPromise()
      .then((res) => {
        this.loader = false;
      })
      .catch(
        err => {
          this.loader = false;
          // this.app.openSnackBar(err.error.msg, 'error-snackbar');
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get(err.error.msg).subscribe((translation: string) => {
                this.app.openSnackBar(translation, "error-snackbar")
            })
        })
        }
      )

  }


  doRefresh(event) {

    setTimeout(() => {
      if (this.url == "worq") {
        if (this.checkPoint != 0 || this.selectWorkqFirst || this.selectWorkqRem.length > 0)
          this.check5()
      }
      var url1 = this.app.sendUrl()
      if (url1.includes('worque')) {
        if (this.checkPoint != 0 || this.selectWorkqFirst || this.selectWorkqRem.length > 0)
          this.checkPoint = sessionStorage.getItem('checkId');
        this.check5()
      }
      event.target.complete();

    }, 2000);
  }
  checkpointChecks(id) {
    if (this.subCheckPoints && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
    }
    else {
      this.checkpointCheck.map(res => {
        if (res.id == id) {
          res.check = !res.check;
        }
      })

      if (this.checkArray.length < 1) {
        this.checkArray.push(id);
      }
      else {
        // for(var i = this.checkArray.length - 1; i >= 0; i--) {
        if (this.checkArray.includes(id)) {
          this.checkArray.splice(this.checkArray.indexOf(id), 1)
        }
        else {
          this.checkArray.push(id);
        }
      }
    }
  }
  PopupRemove(id, data): void {
    if (this.subCheckPoints && this.subCheckPointId == 0) {
      // this.app.openSnackBar('Please Select a SubCheckpoint', 'error-snackbar')
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please Select a SubCheckpoint").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
    }
    else {

      const dialogRef = this.dialog.open(BlockPopComponent, {
        data: id
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != "cancel") {
        }
        if (result == 11) {
          this.currentForceDelete(data);
        }
      });


    }
  }

  async expandFunc(id) {

    if (this.expandId == id) {

      this.expandId = "";
      this.expandObj = [];
      this.expandBoolean = false;
    }
    else {

      this.expandId = id;
      this.expandBoolean = true;
      this.expandObj = [];
      this.expandObj = await this.data.getWorkflowbyPatient(id).toPromise();
      this.expandObj = this.expandObj[0].checkpoints;
      // this.expandObj = this.expandObj.sort((a,b)=>{ let dateA:any=new Date(a.completedTime),dateB:any= new Date(b.completedTime);  return dateB.getTime() - dateA.getTime() })
      this.expandObj.map(res => {
        Object.assign(res, { clas: '' });
        if (res.status == 1) {
          res.clas = 'checkIn';
        }
        if (res.status == 2) {
          res.clas = 'queue';
        }
        if (res.status == 3) {
          res.clas = 'currentposition';
        }
        if (res.status == 4) {
          res.clas = 'complete';
        }
        if (res.status == 5) {
          res.clas = 'miss';
        }
      })
    }

  }

}


