import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { AuthService } from 'src/api-calls/auth.service';
import * as sha512 from 'js-sha512';
import { DataService } from 'src/api-calls/data.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-verify-page',
  templateUrl: './verify-page.component.html',
  styleUrls: ['./verify-page.component.scss']
})
export class VerifyPageComponent implements OnInit {
  
  mobotp;
  contactnum;
  url;
  patientId:any;
  userId:any;
  userLang:any;
  orgId:any;
  loader:boolean=false;
  languageCode: string;
  constructor(public service:DataService, public app:AppService,
              private activate:ActivatedRoute,
              public translate:TranslateService,private auth: AuthService ) { }

  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.contactnum=sessionStorage.getItem('contactnum')
    this.url = this.app.sendUrl();
    this.userId=this.app.urlData();


    this.activate.queryParams.subscribe(param=>{
      this.userLang= param["prefLang"];
      this.orgId = param['orgId']
    })
    let url = window.location.href;
    url = url.substring(0,url.indexOf('?'));
    this.patientId = url.toString().split('/').pop()


    // let x=this.userId.split('?').pop();
    //
    // this.orgId=x.split('=').pop();
  
    // this.userLang=x.split('=');
    // 
    // this.userLang=this.userLang[1].split('&');
    // this.userLang=this.userLang[0]
    
    // this.patientId=this.userId.split('?');
    // this.patientId=this.patientId[0];
    
    // this.orgId=this.patientId[0];
    sessionStorage.setItem('userLang',this.userLang)
    if(this.userLang){
      if(this.userLang==105){
        this.translate.use('hi')
       }
       if(this.userLang==103){
        this.translate.use('ta')
       }
       if(this.userLang==101){
        this.translate.use('en-US')
       }
      }
      else{
        this.translate.use('en-US')
      }
    if(this.url.includes('weblink/' +this.patientId)){
      var post = { userId: this.patientId,appId:2}
    this.auth.loginByMobile(post).subscribe((res:any)=>{
      err=>{
        // this.app.openSnackBar(err.error.msg,'error-snackbar');
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get(err.error.msg).subscribe((translation: string) => {
              this.app.openSnackBar(translation, "error-snackbar")
          })
      })
      }
    })
  }
  }
  

    

  
  async validMobileOtp() {
    this.loader=true;
    if (this.mobotp.length == 6) {
      if (this.mobotp != undefined || null) {
        var m = sha512.sha512(this.mobotp.toString());
      }
      var post={};
      if(this.contactnum){
       post={contactNbrMob:this.contactnum,mobileOTP:m}
      }
      else{
        post={userId:this.patientId,mobileOTP:m}
      }
      this.auth.validMobileOtp(post).subscribe((res: any) => {
        setTimeout(() => {
          localStorage.setItem('hashKey', res.hashKey)
          localStorage.setItem('currentUserId', res.userId)
          localStorage.setItem('organizationId',this.orgId)
          localStorage.removeItem('contactnum');
          this.auth.getConfig();
          this.auth.getCapability();
          // this.app.openSnackBar('Otp Validated SuccessFully','success-snackbar');
          this.translate.use(this.languageCode).subscribe(() => {
            this.translate.get("Otp Validated SuccessFully").subscribe((translation: string) => {
                this.app.openSnackBar(translation, "success-snackbar")
            })
        })
          this.service.getEntitiesByUserId().subscribe(res=>{
            this.loader=false;
            let entities:any = res;
            if(entities.length<2){
              this.app.openPage('patientview/'+entities[0].id);
              sessionStorage.setItem('name',entities.name)
              sessionStorage.setItem('patientRefId',entities.patientRefId)
              localStorage.setItem('entityId',entities[0].id)
            }
            else{
              this.app.openPage('user-entities');
            }
          })
        
        }, 1500);
      },
      err => {
        // this.app.openSnackBar('Invalid Otp','error-snackbar');
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Invalid Otp").subscribe((translation: string) => {
              this.app.openSnackBar(translation, "error-snackbar")
          })
      })
        this.loader=false;
      })
    }
  } 
}
