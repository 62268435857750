import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AuthService } from 'src/api-calls/auth.service';
import { AppService } from '../app.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  clickedItem: any;
  orglogo:any;
  check:boolean;
  clinica:boolean;
  public patchkin:boolean = false;
  public vwfs:boolean = false;
  public viewuserwfw:boolean = false;
  public getappconf:boolean=false;
  public getusers:boolean=false;
  public getworkflow:boolean=false;
  public view3rdflow:boolean=false;
  public navOpen:boolean = false;
  public showAppt:boolean=false;
  bookAppt:boolean;
  tamilfontsize: string;

  constructor(public app: AppService, 
              public auth: AuthService,              
              public App:AppComponent) { }

  user: any;

  async ngOnInit() {

  }
  

  ngDoCheck(): void {
    if (localStorage.getItem("lang") =="en-US"){
      this.tamilfontsize = 'space'
    } else{
      this.tamilfontsize = 'tamilspace'
    }

    const htmlElement = document.getElementsByTagName('html')[0];
    if (localStorage.getItem("lang") === "ta") {
      htmlElement.classList.add('language-ta');
    } else {
      htmlElement.classList.remove('language-ta');
    }
    

    this.orglogo =JSON.parse(localStorage.getItem('org'))
    this.user = sessionStorage.getItem('userName');
    if(localStorage.getItem('config')){
      let x = JSON.parse(localStorage.getItem('config'));
      let y = x.find(res => res.shortCdConst == "CLNQENBL");
      if(y)
      this.clinica = JSON.parse(y.userValue);
      let z = x.find(res => res.shortCdConst == "BKAPPMT");
      if(z)
      this.bookAppt = JSON.parse(z.userValue);
    }
    if(localStorage.getItem('capability')){
    let data:any = JSON.parse(localStorage.getItem('capability'));
    
    if(data.length > 0){
      data.map(res=>{
          if(res.includes('PATCHKIN')){
            this.patchkin = true;
          }
          if(res.includes('VWFS')){
            this.vwfs = true;
          }
          if(res.includes('VIEWUSERWFW')){
            this.viewuserwfw = true;
          }
          if(res.includes('GETAPPCONF')){
            this.getappconf = true;
          }
          if(res.includes('GETALLUSRS')){
            this.getusers = true;
          }
          if(res.includes('GETALLWFWS')){
            this.getworkflow = true;
          }
          if(res.includes('VIEW3RDUSERWFW')){
            this.view3rdflow = true;
          }
          if(res.includes('BKAPT')){
            this.showAppt = true;
          }
      })
    }
  }
    if(window.location.href.includes('user-entities') || window.location.href.includes('patientview') || window.location.href.includes('weblink') || window.location.href.includes('acvalidate') ){
      this.check = true;
    }
    else{
      this.check = false;
    }
  }

  logout(){
    this.App.org=""
  }

  menuStart(){
    let width = window.innerWidth;
    if(width > 700){
      this.app.openPage('dashboard')
    }
    else{
      if(this.auth.loggedIn()){
        if(this.navOpen){
          this.navOpen = false;
          this.App.sidenavClose();
        }
        else{
          this.navOpen = true;
          this.App.sidenavOpen();
        }
      }
    }

  }


}
