import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alerts-setup',
  templateUrl: './alerts-setup.component.html',
  styleUrls: ['./alerts-setup.component.scss']
})
export class AlertsSetupComponent implements OnInit {

  alldepart:any=[];
  loader;
  ad;
  page;
  component;
  msgContent: string = "";
  languageCode: string;
  venue: any;
  name: any;
  constructor(private data: DataService, private translate: TranslateService, public app: AppService) { }

  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.loader =true;
    let post = {}
    this.data.getalertsList(post).subscribe((res:any)=>{
      if(res){
       this.alldepart = res;
       this.loader=false;
       }else{
         this.alldepart=[];
         this.loader=false;
         this.msgContent = "No Alerts Available"
      } 

    }),err=>{
      this.loader=false;
    }

  }

  newCafeteria(){
    this.app.openPage('alerts-create')
  }
  edit(id){
    this.app.openPage('alerts-edit/' + id)
  }
  delete(id){
    this.loader = true;
    this.data.alertsDeleteById(id).subscribe(res=>{
      this.loader = false;
      this.ngOnInit();
      // this.app.openSnackBar('Deleted Successfully !!', 'success-snackbar');   
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Deleted Successfully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
        })
    })
      
    },err=>{
      this.loader = false;
    })
  }
  search(){
    let name = this.name;
    let venue = this.venue;
    this.data.getEvent(name,venue).subscribe((res: any) => {
      if(res){
        this.alldepart = res;
        this.loader=false;
        }else{
          this.alldepart=[];
          this.loader=false;
          this.msgContent = "No Alerts Available"
       } 

    }, err => {
      this.loader = false;
    })
  }
  clear() {
    this.name = "";
    this.venue = "";
    this.ngOnInit();

  }
}

