import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-manage-orders',
  templateUrl: './manage-orders.component.html',
  styleUrls: ['./manage-orders.component.scss']
})
export class ManageOrdersComponent implements OnInit {
  allbilling: any = [];
  loader;
  ad;
  page;
  component;
  msgContent: string = "";
  languageCode: string;
  selectdate = new Date();
  selectdate1 = new Date();
  date1: string;
  date2: string;
  patientId: any;
  contactNbrMob: any;
  PatientName: any;
  userName: string;
  search: string;
  firstName: string;
  maxPatientRef;
  maxName;
  lastName: string;
  constructor(private data: DataService,
    private translate: TranslateService,
    public app: AppService) { }

  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.loader = true;
    this.data.getOrdersList().subscribe((res: any) => {
      if (res) {
        this.allbilling = res;
        this.contactNbrMob =res.patientContactNbr;
        this.loader = false;
      } else {
        this.allbilling = [];
        this.loader = false;
        this.msgContent = "No Order Available"
      }

    }), err => {
      this.loader = false;
    }

  }

  newBilling() {
    this.app.openPage('create-orders')
  }
  edit(id) {
    this.app.openPage('edit-orders/' + id)

  }
  delete(id) {
    this.loader = true;
    this.data.ordersDeleteById(id).subscribe(res => {
      this.loader = false;
      this.ngOnInit();
      // this.app.openSnackBar('Deleted Successfully !!', 'success-snackbar');   
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Deleted Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })

    }, err => {
      this.loader = false;
    })
  }
  userSearch() {
   
    this.userName = '';
    this.search = '';
    this.loader = true;
    let post = {};
    let patientId = this.patientId;
    let contactNbrMob = this.contactNbrMob;
    this.date1 = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2) + ' ' + '00:00:00'
    this.date2 = this.selectdate1.getFullYear() + '/' + ("0" + (this.selectdate1.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate1.getDate()).slice(-2) + ' ' + '23:59:59'
    // if (this.selectdate) {
    //   this.date1 = this.selectdate.getFullYear() + '-' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '-' + ("0" + this.selectdate.getDate()).slice(-2) + ' ' + '00:00:00'
    // }
    // if (this.selectdate1) {
    //   this.date2 = this.selectdate1.getFullYear() + '-' + ("0" + (this.selectdate1.getMonth() + 1)).slice(-2) + '-' + ("0" + this.selectdate1.getDate()).slice(-2)  + ' ' + '23:59:59'
    // }
    // post = {
    //   studentName: this.firstName,
    //   // orgId: this.schooltypeId,
    //   gradeId: this.gradeId,
    //   levelId: this.levelId,
    //   // year:this.yearName,
    //   studentId: this.studentId,
    // }

    this.data.getOrders(patientId,contactNbrMob, this.date1, this.date2).subscribe((res) => {
      if (res) {
        if(res){
          this.allbilling = res;
        }else{
          this.allbilling = [];
        }
        this.loader = false;
      }else{
      this.loader = false;
      }
    }, err => {
      this.loader = false;
    });
      (err) => {
        this.loader = false;

      };
  }
  clear() {
    this.firstName = "";
    this.lastName = "";
    this.patientId = "";
    this.contactNbrMob = "";
    this.ngOnInit();
    this.selectdate = new Date();
    this.selectdate1 = new Date();

  }
}
