import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { ExcelService } from 'src/api-calls/excel.service';
import { MatTableDataSource, MatSort } from '@angular/material';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { Popper1Component } from '../popper1/popper1.component';
import { Popper2Component } from '../popper2/popper2.component';
import { Popper4Component } from '../popper4/popper4.component';
import { Popper5Component } from '../popper5/popper5.component';
import { DomSanitizer } from '@angular/platform-browser';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Platform } from '@angular/cdk/platform';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { HandlerService } from "src/api-calls/handler.service";
declare var cordova: any;

@Component({
  selector: 'app-patient-search',
  templateUrl: './patient-search.component.html',
  styleUrls: ['./patient-search.component.scss']
})
export class PatientSearchComponent implements OnInit, AfterViewInit {
  displayedColumns = ['sno', 'patientRefId', 'name', 'age', 'gender', 'department', 'doctorName', 'newPatient', 'entryType', 'fastingPatient', 'checkInTs', 'Actions', 'edit','History'];
  dataSource: any;
  allbilling: any;
  loader: boolean;

  // @ViewChild(MatSort) set content(content: ElementRef) {
  //   this.sort = content;
  //   if (this.sort) {
  //     this.dataSource.sort = this.sort;
  //   }
  // }

  @ViewChild(MatSort) sort: MatSort;
  doctId: any;

  ngAfterViewInit() {
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  hwind: boolean;
  Cview: boolean = false;
  firstName;
  lastName;
  patientId;
  mobileNo;
  appConfig: any;
  length;
  maxName;
  maxToken;
  maxMobile;
  showToken: string = "";
  countrynum = '+91';
  changeView: boolean = false;
  mulCard: boolean;
  printbilling: boolean = false;
  items: any = [];
  searchparam: any = {};
  s1;
  msgContent: string = "";
  nodata: boolean = false;
  public updent: boolean = false;
  selectdate = new Date();
  selectdate1 = new Date();
  date1;
  date2;
  patientType;
  role: any = [];
  deptId : any;
  available: boolean = false;
  patientTypes: any = [{ id: '1', type: 'Aged Patient' },
  { id: '2', type: 'Call Assistance' },
  { id: '3', type: 'Wheel Chair Patient' },
  { id: '4', type: 'InPatient' },
  { id: '5', type: 'OutPatient' },
  { id: '6', type: 'Master Health Checkup' },
  { id: '7', type: 'BedSide' },
  { id: '8', type: 'International Patient' },
  ];
  refId;
  patientsearchtypes = [];
  checkInTs;
  entry: any = [{ id: '1', entryType: "Appointment" }, { id: '2', entryType: "Walk-in" }]
  excelData: any = [];
  clinica: boolean;
  switch: boolean;
  alldepart: any = [];
  // CurrentLoction: any = [{id: '1', currentName: "BILLING" },]
  entype: any = [] = [{ name: 'Appointment', id: '1' },
  { name: 'Walk-In', id: '2' }]
  entryType;
  department;
  docName: any = [];
  constructor(public data: DataService,
    public router: Router,
    public app: AppService,
    private excelService: ExcelService,
    public handler: HandlerService,
    private service: DataService,
    public msg: AppComponent,
    public sanitizer: DomSanitizer,
    public platform: Platform,
    // public printer: Printer,
    private camera: Camera,
  ) { }
  doctorName: any[];

  ngOnInit() {
    var today = new Date();
    this.date1 = today.getFullYear() + '/' + ("0" + (today.getMonth() + 1)).slice(-2) + '/' + ("0" + today.getDate()).slice(-2) + ' ' + '00:00:00'
    this.date2 = today.getFullYear() + '/' + ("0" + (today.getMonth() + 1)).slice(-2) + '/' + ("0" + today.getDate()).slice(-2) + ' ' + '23:59:59'
    this.departmentGet();
    let x = JSON.parse(localStorage.getItem('config'));
    let a = x.find(res => res.shortCdConst == "MXSHFTBT");
    this.showToken = a.userValue;
    let b = x.find(res => res.shortCdConst == "PATNM");
    this.maxName = b.userValue;
    let c = x.find(res => res.shortCdConst == "PATTKNNO");
    this.maxToken = c.userValue;
    let d = x.find(res => res.shortCdConst == "PATMOBNO");
    this.maxMobile = d.userValue;
    let y = JSON.parse(localStorage.getItem('capability'));
    this.app.mobCab = y.includes('MOBNOVISIB');
    let f = x.find(res => res.shortCdConst == "CLNQENBL");
    if (f)
      this.clinica = JSON.parse(f.userValue);
    if (this.clinica)
      this.displayedColumns = ['sno', 'patientRefId', 'name', 'checkInTs','department','doctorName', 'Actions', 'edit','History'];
    else
      this.displayedColumns = ['sno', 'patientRefId', 'name', 'age', 'gender', 'department', 'doctorName', 'newPatient', 'entryType', 'fastingPatient', 'checkInTs', 'Actions'];
    let r = x.find(res => res.shortCdConst == "PATVICON");
    if (r)
      this.switch = JSON.parse(r.userValue)
    this.patientSearch()
    let h = x.find(res => res.shortCdConst == "CLNQENBL");
    if (h)
      this.clinica = JSON.parse(h.userValue);
    let w = x.find(res => res.shortCdConst == "HWIND");
    if (w)
      this.hwind = JSON.parse(w.userValue);
  }
  viewChange(id) {
    if (id == 1) { this.Cview = true; }
    else { this.Cview = false; }
  }
  ngDoCheck(): void {
    if (localStorage.getItem('capability')) {
      let data: any = JSON.parse(localStorage.getItem('capability'));
      if (data.length > 0) {
        data.map(res => {
          if (res.includes('UPDENT')) {
            this.updent = true;
          }
        })
      }

    }
  }
  patientSearch() {
    this.data.getAllPatient(this.date1, this.date2).subscribe(res => {
      if (res != null) {
        this.items = res;
        this.items.map(data1 => {
          this.entry.map(data2 => {
            if (data1.entryType == data2.id) {
              Object.assign(data1, { EntryType: data2.entryType })
            }

          })
        })
        this.items.map(data1 => {
          if (data1.newPatient == true) {
            Object.assign(data1, { new: "Yes" })
          } else {
            Object.assign(data1, { new: "No" })
          }
        })
        this.items.map(data1 => {
          if (data1.fasting != null && data1.fasting == true) {
            Object.assign(data1, { fasting: "Yes" })
          } else {
            Object.assign(data1, { fasting: "No" })
          }
        })

        // this.items.map(data2 => {
        //   Object.assign(data2, { CurrentLoction: this.CurrentLoction })
        // })
        this.dataSource = new MatTableDataSource(this.items)
        if (this.items.length > 0) {
          this.items = this.items.sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          })
        }
      }
      else {
        this.msgContent = "No Data To Show";
      }
    })
  }
  clear() {
    this.firstName = "";
    this.lastName = "";
    this.patientId = "";
    this.mobileNo = "";
    this.department = "";
    this.entryType = "";
    this.patientType = "";
    this.doctorName = [];
    this.ngOnInit();
    this.selectdate = new Date();
    this.selectdate1 = new Date();

  }
  search() {
    this.data.loader = true;
    let docName: any = "";
    if (this.doctorName != null || undefined) {
      docName = this.doctorName.length > 0 ? this.doctorName : "";
    }

    this.searchparam = {
      "tokenNbr": this.patientId,
      "contactNbr": this.mobileNo,
      "department": this.department,
      "entryType": this.entryType,
      "doctorName": docName,
    };

    this.msgContent = "";
    if (this.firstName)
      Object.assign(this.searchparam, { "name": this.firstName, })
    if (this.refId)
      Object.assign(this.searchparam, { "patientRefId": this.refId, })
    this.patientsearchtypes.map(res => {
      if (res.id == 1) {
        Object.assign(this.searchparam, { aged: true })
      }
      if (res.id == 2) {
        Object.assign(this.searchparam, { callRequired: true })
      }
      if (res.id == 3) {
        Object.assign(this.searchparam, { wheeled: true })
      }
      if (res.id == 4) {
        Object.assign(this.searchparam, { entityType: "2" })
      }
      if (res.id == 5) {
        Object.assign(this.searchparam, { entityType: "1" })
      }
      if (res.id == 6) {
        Object.assign(this.searchparam, { entityType: "3" })
      }
      if (res.id == 7) {
        Object.assign(this.searchparam, { bedSide: "true" })
      }
      if (res.id == 8) {
        Object.assign(this.searchparam, { internationalPatient: true })
      }
    })
    this.date1 = this.selectdate.getFullYear() + '/' + ("0" + (this.selectdate.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate.getDate()).slice(-2) + ' ' + '00:00:00'
    this.date2 = this.selectdate1.getFullYear() + '/' + ("0" + (this.selectdate1.getMonth() + 1)).slice(-2) + '/' + ("0" + this.selectdate1.getDate()).slice(-2) + ' ' + '23:59:59'
    this.data.searchPatient(this.searchparam, this.date1, this.date2).toPromise().then(res => {
      this.items = res;
      if (res != null) {
        this.items.map(data1 => {
          this.entry.map(data2 => {
            if (data1.entryType == data2.id) {
              Object.assign(data1, { EntryType: data2.entryType })
            }
          })
        })
        this.items.map(data1 => {
          if (data1.newPatient == true) {
            Object.assign(data1, { new: "Yes" })
          } else {
            Object.assign(data1, { new: "No" })
          }
        })
        this.items.map(data1 => {
          if (data1.fasting != null && data1.fasting == true) {
            Object.assign(data1, { fasting: "Yes" })
          } else {
            Object.assign(data1, { fasting: "No" })
          }
        })
        this.dataSource = new MatTableDataSource(this.items);
        this.data.loader = false;
      } else {
        this.msgContent = "No Data Found";
        this.data.loader = false;
        this.dataSource = false;
      }
    }).catch(
      err => {
        this.data.loader = false;
      }
    );
  }
  edit(data) {
    this.app.openPage('patientEdit/' + data.id);
  }
  cancel() {
    this.app.openPage('patientsCheckIn');
  }
  switchPatientType() {
    let patienttype: any = []
    patienttype = this.patientTypes.filter(res => { return res.id == this.patientType })
    this.patientsearchtypes.push(patienttype[0])
    this.patientsearchtypes = this.patientsearchtypes.filter((x, i, a) => a.indexOf(x) == i);
  }
  remove(i) {
    this.patientsearchtypes.splice(i, 1)
  }
  exportAsXLSX(): void {
    this.items.map((data4, i) => {
      let sno = i + 1
      Object.assign(data4, { SNO: sno })
    })
    this.items.map(res => {

      // if (res.checkInTs) {
      // //   res.checkInTs =this.setDate(res.checkInTs)
      // // }
      // // if(res.entityType == 1){
      // //   res.entityType = 'OP'
      // // }

      // // if(res.entityType == 2 && res.bedSide){
      // //   res.entityType = 'BS'
      // // }

      // // if(res.entityType == 2 && !res.bedSide){
      // //   res.entityType = 'IP'
      // // }

      // // if(res.entityType == 3){
      // //   res.entityType = 'MHC'
      // // }
      if (res.checkInTs) {
        Object.assign(res, { localtime: this.convertLocalTime(res.checkInTs) })

      }
      if (res.allReportsReadyTs) {
        Object.assign(res, { rrTime: this.convertLocalTime(res.allReportsReadyTs) })
      }
      this.excelData.push({
        SNO: res.SNO, UHID: res.patientRefId, PatientName: res.name, ContactNumber: res.contactNbr, Age: res.age, Sex: res.gender, Department: res.department, DoctorName: res.doctorName, NewPatient: res.new, EntryType: res.EntryType, FastingPatient: res.fasting,
        Metadata: res.metadata, CheckInTime: res.localtime, ReportReady: res.allReportsReady, ReportReadyTs: res.rrTime
      })
    })
    this.excelService.exportAsExcelFile(this.excelData, 'Patient Details');
    this.excelData = [];
  }
  setDate(date) {
    let x = new Date(date)
    let y = ("0" + (x.getDate())).slice(-2) + '/' + ("0" + (x.getMonth() + 1)).slice(-2) + '/' + x.getFullYear() + ' ' + ("0" + x.getHours()).slice(-2) + ':' + ("0" + x.getMinutes()).slice(-2);
    return y
  }

  diagnosis(data) {
    this.router.navigate(['/diagnosis', data.id]);
  }

  routeTo(data) {
    this.router.navigateByUrl('/diagnosis-history/' + data.userId);
  }
  departmentGet() {
    this.data.departmentGetAll().subscribe((res: any) => {
      this.alldepart = res;
    })
  }
  getDoctorsList() {
    this.data.getDoctorsList().subscribe((res: any) => {
      this.docName = res;

    })
  }
  convertLocalTime(date) {
    var a
    var day
    a = new Date(date).toLocaleString();
    day = new Date(a)
    let y = ("0" + (day.getDate())).slice(-2) + '/' + ("0" + (day.getMonth() + 1)).slice(-2) + '/' + day.getFullYear() + ' ' + ("0" + day.getHours()).slice(-2) + ':' + ("0" + day.getMinutes()).slice(-2);
    return y;
  }
// getId(data) {
  //   this.alldepart.map((res) => {
  //     if (data) {
  //       if (res.name == data) {
  //         this.deptId = res.id;
  //         this.data.getDoctorByDepartmentId(this.deptId).subscribe((res) => {
  //           this.docName = res;
  //           this.available = true;
  //           this.doctorName = [];
  //         });
  //       }
  //     }
  //   });
  // }
  getId(data) {
    if (data == "All") {
      this.getDoctorsList()
    } else {
      this.alldepart.map((res) => {
        if (data) {
          if (res.name == data) {
            this.deptId = res.id;
            this.getDoctors(this.deptId)
          }
        }
      });
    }
  }
  getDoctors(deptId) {
    this.loader = true;
    this.data.getDoctorByDepartmentIdAvl(deptId).subscribe((res: any) => {
      if (res) {
        this.loader = false
        this.docName = res;
        this.available = true;
        this.doctorName = res[0].name;
        if (this.doctorName) {
          this.getDocId(this.doctorName);
        }
        this.doctId = res[0].id;
      } else {
        this.loader = false;
      }
    },
      (err) => {
        this.loader = false;
      }
    );
  }
  getDocId(data: any) {
    console.log(data)
    this.docName.map((res) => {
      // console.log(res);
      if (data) {
        if (res.name == data) {
          this.doctId = res.id;
          console.log(this.doctId);
        }
      }
    });
  }
  invoice() {
    // this.router.navigateByUrl('/invoice')
    this.printbilling = true;
    // this.getbilling(data);
  }
  printOrder() {
    if (this.platform.ANDROID || this.platform.IOS) {
      var printcon = document.getElementById('overall');
      document.addEventListener('deviceready', function () {
        const printContent =
          (`<html>
          <head>  
            <style>
            @page {
              size: auto;
              /* auto is the initial value */
              margin: 0mm;
              /* this affects the margin in the printer settings */
            }
            
            .overall1{
              // background-image: url("../../assets/background-img.png");
              background-repeat: no-repeat;
              background-size: cover;
            }
            
            .invoice {
              max-width: 800px;
              margin: 0 auto;
              padding: 20px;
              border: 1px solid #ccc;
              background-image: url("assets/background-img.png");
              background-repeat: no-repeat;
              background-size: cover;
              height: 176%;
            }
            .invoice-logo {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: -9%;
              margin-left: -4%;
            }
            .invoice-logo img {
              width: 26%;
            }
            .invoice-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: -10%;
              width: 126%;
            }
            
            .invoice-header img {
              width: 12%;
              height: 82px;
            }
            
            .invoice-header h1 {
              font-size: 28px;
              color: #00205b;
              display: flex;
              font-weight: bold;
              margin: 12px;
              width: 71%;
            }
            .header-name {
              display: flex;
              width: 174%;
            }
            .header-name h2 {
              font-size: 85px;
              color: #00205b;
              display: flex;
              font-weight: bold;
            }
            .invoice-details-name {
              display: flex;
              justify-content: space-between;
              width: 80%;
            }
            
            .invoice-details h2 {
              font-size: 18px;
              margin-bottom: 10px;
              color: #00205b;
              font-weight: bold;
            }
            
            .invoice-details p {
              margin: 0;
              color: #007fff;
            }
            
            .invoice-table {
              margin-top: 40px;
              width: 100%;
              border-collapse: collapse;
            }
            
            .invoice-table th,
            .invoice-table td {
              padding: 10px;
              border-bottom: 1px solid #ccc;
            }
            
            .invoice-table th {
              text-align: left;
              background-color: #00205b;
              color: #fff;
              font-weight: bold;
            }
            
            .invoice-total {
              margin-top: 5px;
              font-size: 22px;
              text-align: right;
              margin-right: 32px;
              color: #00205b;
            }
            
            .invoice-total p {
              margin: 6px;
            }
            .invoice-table tr:nth-child(odd) {
              background-color: rgb(193, 233, 240);
            }
            
            .invoice-table tr:nth-child(even) {
              background-color: #e1f3f3;
            }
            .sub-table {
              display: flex;
            }
            .sub-table h2 {
              display: flex;
              font-size: 20px;
              margin-bottom: -33px;
              color: #00205b;
            }
            .battom-sheet {
              display: flex;
              width: 137%;
              margin-left: -23px;
              height: 2%;
              margin-top: -6px;
            }
            .battom-imgage {
              display: flex;
              justify-content: space-evenly;
              width: 89%;
              margin-bottom: -65px;
              margin-top: 36px;
            }
            .battom-imgage h2 {
              font-size: 21px;
              font-weight: bold;
              color: #00205b;
              margin-top: 9px;
            }
            .battom-imgage img {
              width: 45px;
              height: 44px;
            }
            .img1 {
              display: flex;
              justify-content: flex-end;
              margin-right: -8px;
            
            }
            .img1 img{
              width: 52px;
              height: 52px;
              margin-top: 12px;
              margin-bottom: 6px;
            }
  
            </style>
          </head>
          <body>
            ${printcon.innerHTML}
          </body>
         </html>`);
        console.log(printContent)
        cordova.plugins.printer.print(printContent);
      }, false);
    } else {
      setTimeout(() => {
        this.printbilling = false;
        let a: any = document.getElementById('overall');
        // var mywindow = window.open('', 'Print', 'height=screen.height,width=screen.width');

        // const fileUrl = URL.createObjectURL(a);
        var win = window.open('', '_blank');
        if (win) {
          win.focus(); // Ensure the new window/tab is focused
        } else {
          alert('Please allow pop-ups for this website to view the file.'); // Display an alert if the new window/tab was blocked
        }
        win.document.write('<html><head><link rel="stylesheet" href="assets/print.scss" /><style>@page { size: auto;  margin: 0mm; }</style></head></html>');
        win.document.body.appendChild(a)
        win.document.close();
        setTimeout(() => {
          win.print();
          win.focus()
          win.close();
          this.ngOnInit();
        }, 1000);
      },
        1000);
    }

  }
  getbilling(data) {
      this.router.navigate(['/invoice', data.id]);
    // this.data.getBillingById(this.id).subscribe((res: any) => {
    //   if (res) {
    //     this.allbilling = res;
    //     this.loader = false;
    //   } else {
    //     this.allbilling = [];
    //     this.loader = false;
    //     this.msgContent = "No Treatment Available"
    //   }

    // }), err => {
    //   this.loader = false;
    // }
  }
}