import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/api-calls/auth.service';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { DoctorSetupComponent } from '../doctor-setup/doctor-setup.component';
import { HandlerService } from "src/api-calls/handler.service";
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormControl, Validators } from '@angular/forms'


@Component({
  selector: 'app-createchart',
  templateUrl: './createchart.component.html',
  styleUrls: ['./createchart.component.scss']
})
export class CreatechartComponent implements OnInit {

  alldepart: any = [];
  name;
  desc;
  code;
  edit: boolean = false;
  departmentId;
  checkpointId;
  avgProcessingTime;
  doctoredit: boolean;
  loader;
  url;
  files: any = [];
  // images: string[] = [];
  uploading: boolean;
  id;
  imageId: any;
  doctorObj: any = [];
  languageCode: string;
  items: any = [];
  dataSource: any;
  msgContent: string;
  docItemsIds: any = [];
  openImg: boolean = false;
  avgTime;
  description: any;
  inchargeName: any;
  location: any;
  activeInd: boolean;
  floor: any;
  chartObj: any;
  blob1: any;
  imageName: any;
  myForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });
  images: string[] = [];
  docId: any;
  get formValue() {
    return this.myForm.controls;
  }
  navigationReqId: any;
  capture: boolean;
  cameraService: any;

  constructor(public data: DataService, private translate: TranslateService, public app: AppService,
    private sanitizer: DomSanitizer, public auth: AuthService, public handler: HandlerService,) { }

  ngOnInit() {
    this.loader = true;
    this.languageCode = localStorage.getItem('lang');
    // this.departmentGet();
    this.url = this.app.urlData();
    setTimeout(() => {
      this.loader = false;
    }, 500);
    if (this.url != 'createchart') {
      this.doctoredit = true;
      this.id = this.app.urlData();
      this.data.getnavigationcampById(this.id).subscribe((res: any) => {
        this.chartObj = res;
        this.id = res.id;
        this.name = res.name;
        this.floor = res.floor;
        const docItemsIds = res.docId;      
        console.log(docItemsIds)
        this.loader = false;
        this.setUserProfilePic(docItemsIds);
         
      }, err => {
        this.loader = false;
      })
    } else {
      console.log('jj')
    }

  }

  departmentGet() {
    this.data.departmentGetAll().subscribe((res: any) => {
      this.alldepart = res;
    })
  }

  doctorCreate() {
    this.loader = true;
    var post = {
      "name": this.name.toUpperCase(),
      "floor": this.floor,
      orgId: localStorage.getItem('organizationId'),
      "activeInd": true,
      "docId": this.imageId,
      // "navigationReqDocImageList": [
      //   {
      //     "docImageId": this.imageId,
      //   },
      // ]

    }
    console.log(post)
    this.data.navigationchartCreate(post).subscribe((res: any) => {
      this.navigationReqId = res.id;
      this.loader = false;
      this.app.openPage('navigation-chart');
      // this.app.openSnackBar('Doctor Created Successfully !!', 'success-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Created Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })

    }, err => {
      this.loader = false;
    })
  }
  cancel() {
    this.app.openPage('navigation-chart')
  }
  doctorUpdate() {
    this.loader = true;
    this.chartObj.name = this.name.toUpperCase();
    this.chartObj.id = this.id;
    this.chartObj.floor = this.floor;
    this.chartObj.docId = this.docId;
    this.activeInd = true;
    this.data.navigationchartPutById(this.id, this.chartObj).subscribe(res => {
      this.loader = false;
      this.app.openPage('navigation-chart');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Updated Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })
    }, err => {
      this.loader = false;
    })

  }
  handleFile(files) {
    console.log(files)
    var img = new Image();
    this.blob1 = files[0];
    this.imageName = this.blob1.name;
    this.data.uploadChartFile(this.imageName, this.blob1).subscribe((res: any) => {
      if (res) {
        this.loader = false;
        this.imageId = res.id;
        this.uploading = false;
        this.setUserProfilePic(res.id);
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Image Uploaded Succsessfully!!").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
          })
        })
      } else {
        this.loader = false;
      }
    },
      (err) => {
        this.loader = false;
        this.handler.res(err);
      });

    if (files && files[0]) {
      var filesAmount = files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          // Push Base64 string
          this.images.push(event.target.result);
          this.patchValues();
        }
        reader.readAsDataURL(files[i]);
      }
    }
  }
  patchValues() {
    this.myForm.patchValue({
      fileSource: this.images
    });
  }
  viewImage(url) {
    this.url = url;
    this.openImg = true;
  }

  setUserProfilePic(id) {
    if (id) {
      this.data.getDocumentbychartId(id).subscribe((res: any) => {
        let objectURL = URL.createObjectURL(res);
        this.url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      });
    }
  }

  setUserProfilePicBy(id, mainres) {
    if (id != null) {
      this.data.getDocumentbychartId(id).subscribe((res: any) => {
        let objectURL = URL.createObjectURL(res);
        mainres.img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }, err => {
        this.loader = false;
        this.handler.err(err);
      });
    } else {
      mainres.img = 'assets/icon/ads.png';
    }
  }
  removeImage(url: any) {
    if (url != undefined) {
      this.loader = true;
      this.data.deleteitemDocument(url).subscribe(res => {
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Deleted successfully").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "success-snackbar")
          })
          this.docItemsIds = this.docItemsIds.filter(docId => docId.docImageId !== url);
        })
        this.loader= false;
      },
        err => {
          this.loader = false;
          this.handler.err(err);
        })
    }
  }
}

