import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-popper4',
  templateUrl: './popper4.component.html',
  styleUrls: ['./popper4.component.scss']
})
export class Popper4Component implements OnInit {
  @ViewChild('cancelBtn') cancelButton!: ElementRef;
  item1: any;
  item2: any;
  items: any;

  search: any = { name: '' };
  pitem: any;
  pickers1: any;

  f1: any;
  f2: any;
  f3: any;

  bf: boolean = false;
  days: any;
  summary: any;

  input2: any;

  mlist: any = [];
  dosage: any = [];
  medicineId: any;
  beforeFoodInd: any;
  medicineName: any;
  medicines: any = [];
  medArray: any = []
  inTakeQty: any = [{
    id: 1, qty: 0.5,
    select: false
  },
  {
    id: 1, qty: 1,
    select: false
  },
  {
    id: 1, qty: 1.5,
    select: false
  },
        {
    id: 1, qty: 2,
    select: false
  }]

  dose: any = [{ id: 1, dose: 250, select: false }, { id: 1, dose: 500, select: false }, { id: 1, dose: 550, select: false }, { id: 1, dose: 650, select: false },]
  dosageArray: any = [];
  languageCode: string;
  list: any = [
    { name: "Suggested", style: "none", id: 0 },
    { name: "Medicine Group", style: "none", id: 1 },
  ];
  showDiv: any;
  allmedicineGroup: any = [];
  loader: boolean;
  medicinesSuggesting: any;
  sugguesting: boolean;
  medicening: boolean;
  isInputHighlighted: boolean;
  allMedicine: any = [];
  openpopupOfGroupmed: boolean;
  groupMedicine: any = [];
  showingDiv: string;
  groupName: any;
  descripTion: any;
  shortCode: any;
  matchingMedicine: any;
  medDosage: any = [];
  inTakeQtys: any = [];
  qty: any;

  constructor(private service: DataService, public app: AppService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<Popper4Component>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog) { }
  async ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.listCheck();
    this.sugguesting = true;
    this.medicening = false;
    this.service.getMedicine().subscribe((res: any) => {
      this.medDosage = res.medDosages;
      this.medicinesSuggesting = res.filter((medicine: any) => medicine.suggestInd === true);
      this.getmed()
      var x;
      x = res;
      x.map(res => {
        Object.assign(res, { select: false })
      })
      this.medicines = x.filter(res => { return res.id == 9 || res.id == 10 || res.id == 2 || res.id == 5 });
      this.data.medFrequencies.map((part, index, res) => {
        if (res[index] == 1) {
          this.f1 = true
        }
        if (res[index] == 2) {
          this.f2 = true
        }
        if (res[index] == 3) {
          this.f3 = true
        }
      });
      this.search = { name: this.data.medicineName };
      this.medicineName = this.data.medicineName;
      this.bf = this.data.beforeFoodInd;
      this.days = this.data.days;
      console.log(this.data)
      this.dosage = this.data.dosage;
      this.medDosage = this.data.medDosageList;
      this.qty = this.data.inTakeQty;

      this.medicineId = this.data.medicineId;
      this.summary = this.data.comments;
      this.medicines.map(res => {
        if (this.medicineId == res.id) {
          Object.assign(res, { select: true });
          this.medArray.push(res)
        }
      })
      this.medDosage.map(res => {
        if (this.dosage == res.dosage) {
          Object.assign(res, { select: true });
          this.dosageArray.push(res)

        }
      })
      this.inTakeQty.map(res => {
        if (this.qty == res.qty) {
          Object.assign(res, { select: true });
          this.inTakeQtys.push(res)

        }
      })
      if (this.data.centerName)
        this.input2 = this.data.centerName;
      else
        this.input2 = this.data.healthCenterName;
      this.summary = this.data.comments;
    })


  }



  suggestClick(itemId: string) {
    this.medicinesSuggesting.map(res => {
      if (res.id == itemId) {
        this.medicineName = res.name;
        this.medicineId = res.id;
        this.dose = res.medDosages;
        this.medDosage = res.medDosages;
        this.isInputHighlighted = true;
      }
    })
  }

  medicineClick(itemId: string) {
    this.medicineId = itemId;
    this.allMedicine.map(res => {
      if (res.id == this.medicineId) {
        this.medicineName = res.name;
        this.dose = res.medDosages;
        this.medDosage = res.medDosages;
        this.isInputHighlighted = true;
      }
    })
  }


  checkBox(id: number) {
    this.showDiv = id;
    this.listCheck();
    if (id == 0) {
      this.sugguesting = true;
      this.medicening = false;
      this.openpopupOfGroupmed = false;
      this.groupMedicine = "";
      this.showingDiv = "";
      this.allmedicineGroup.map((res, i) => {
        if (this.showingDiv == res.id) {
          Object.assign(res, { style: "activing" });
        }
        else {
          Object.assign(res, { style: "none" });
        }
      });
    }
    if (id == 1) {
      this.sugguesting = false;
      this.medicening = true;
    }
  }

  openMedgroup(id) {
    this.loader = true;
    this.showingDiv = id;
    this.medgroupCheck();
    this.service.getMedicine().subscribe((res: any) => {
      this.allMedicine = res;
      this.getallmedgroup(id)
    });
  }

  getallmedgroup(id) {
    this.service.getgroupMedicine(id).subscribe(res => {
      this.medicines = res;
      this.loader = false;
      this.openpopupOfGroupmed = true;
      this.groupName = this.medicines.name;
      this.descripTion = this.medicines.description;
      this.shortCode = this.medicines.code;
      this.groupMedicine = this.medicines.groupMedicines;
      this.allMedicine = this.allMedicine;
      this.groupMedicine.forEach(res1 => {
        this.matchingMedicine = this.allMedicine.find(response => response.id === res1.medicineId);
        if (this.matchingMedicine) {
          res1.id = this.matchingMedicine.id;
          res1.name = this.matchingMedicine.name;
        }
      });
    })
    // this.allmedicineGroup.map(res => {
    //   if(res.id == id){
    //   Object.assign(res, { select: false })
    // }
    // })
    // id.select = !id.select;
  }

  medgroupCheck() {
    this.allmedicineGroup.map((res, i) => {
      if (this.showingDiv == res.id) {
        Object.assign(res, { style: "activing" });
      }
      else {
        Object.assign(res, { style: "none" });
      }
    });
  }

  getmed() {
    let post = {
      orgId: localStorage.getItem('organizationId'),
    }
    this.service.searchmedgroupDetails(post).subscribe(res => {
      this.allmedicineGroup = res;
      this.loader = false;
    })
  }

  listCheck() {
    this.list.map((res, i) => {
      if (this.showDiv == res.id) res.style = "activing";
      else res.style = "none";
    });
  }

  medicineSelect(data) {
    this.medicines.map(res => {
      Object.assign(res, { select: false })
    })
    data.select = !data.select;
    this.medArray = this.medicines.filter(res => { return res.select });
  }

  medicineSelect1(data) {
    this.medDosage.map(res => {
      Object.assign(res, { select: false })
    })
    data.select = !data.select;
    this.dosageArray = this.medDosage.filter(res => { return res.select });
  }


  async findMed(data) {
    if (data && data.length > 2) {
      this.search = { name: data };
      this.mlist = await this.service.searchMedicine(this.search).toPromise();
    } else {
      this.mlist = [];
    }
    setTimeout(() => {
      var x = this.mlist.find(res => { return res.name == this.medicineName });
      if (x) {
        this.medicineId = x.id;
      }
    }, 1000);

  }

  add() {
    this.medicineId = this.medicineId;
    this.medicineName = this.medicineName;
    // this.dosage = this.dosageArray[0].dose;
    this.dosage = this.dosageArray[0].dosage;
    this.qty = this.inTakeQtys[0].qty;
    console.log(this.dosage)
    var array = [];
    var fName = [];
    if (this.f1) {
      array.push(1)
      // fName.push("Morning")
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Morning").subscribe((translation: string) => {
          fName.push(translation);
        });
      });
    }
    if (this.f2) {
      array.push(2)
      // fName.push("AfterNoon")
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("AfterNoon").subscribe((translation: string) => {
          fName.push(translation);
        });
      });
    }
    if (this.f3) {
      array.push(3)
      // fName.push("Night")
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Night").subscribe((translation: string) => {
          fName.push(translation);
        });
      });
    }
    if (this.days == undefined) {
      this.days = 0;
    }
    if (this.medicineId || array || this.medicineName || this.dosage == undefined || fName || this.bf || this.qty || this.days || this.summary || this.input2) {
      if (this.medicineName == undefined || this.days == undefined) {
        // this.app.openSnackBar("Please enter the required fields","error-snackbar")
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Please enter the required fields").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
          })
        })
      } else {
        this.data = {
          "medicineId": this.medicineId, "medicineName": this.medicineName, "medFrequencies": array,
          "fname": fName, "beforeFoodInd": this.bf, "days": this.days, "comments": this.summary,
          "dosage": this.dosage,"inTakeQty": this.qty, "healthCenterId": 3, "centerName": "Apollo Pharmacy"
        };
        this.clear();
        this.dialogRef.close(this.data);
      }
    }
    else {
      this.dialogRef.close();
    }
  }

  findCenter(data) {

  }

  clear() {
    this.medicineName = "";
    this.f1 = "";
    this.f2 = "";
    this.f3 = "";
    this.bf = false;
    this.days = "";
    this.summary = "";
    this.dosage = "";
    this.qty = "";
  }

  cancel() {
    this.dialogRef.close();
  }
  toggleSelection(buttonId: string) {
    this[buttonId] = !this[buttonId];
  }
  medicineSelect12(data) {
    this.inTakeQty.map(res => {
      Object.assign(res, { select: false })
    })
    data.select = !data.select;
    this.inTakeQtys = this.inTakeQty.filter(res => { return res.select });
  }
}