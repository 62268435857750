import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
})
export class TimepickerComponent {
  
  constructor(public dialogRef: MatDialogRef<TimepickerComponent>){}

  hr = 0; min = 0;morEve = true;

  add() {
    this.hr++;
    if(this.hr == 24)
    this.hr = 0;
  }
  minus() {
    --this.hr;
    if(this.hr < 0)
    this.hr = 23
  }

  addMin() {
    let url = window.location.href;
    if(url.includes('single')){
 
      this.min += 5;
      if(this.min == 60)
      this.min = 0;
    }else{
      this.min += 15;
      if(this.min == 60)
      this.min = 0;
    }
  
  }
  minMin() {
    let url = window.location.href;
    if(url.includes('single')){
      this.min -= 5;
      if(this.min < 0)
      this.min = 55
    }else{
      this.min -= 15;
      if(this.min < 0)
      this.min = 45
    }

  }

  setTime(){
    let hr = this.hr > 9 ? this.hr : '0' + this.hr;
    let min = this.min > 9 ? this.min : '0' + this.min;
    setTimer(hr + ':' + min);
    this.cancel()
  }

  cancel(){
    this.dialogRef.close();
  }
}

export let timer;

export function setTimer(data){
    timer = data;
}
