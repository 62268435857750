import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';

@Component({
  selector: 'app-checkedin',
  templateUrl: './checkedin.component.html',
  styleUrls: ['./checkedin.component.scss']
})
export class CheckedinComponent implements OnInit {
  checkedInData:any;
  checkedInDataClone:any;
  completedLen:any;
  checkedInLen:any;
  checkPoint: any;
  searchS;
  date1;
  date2;
  checkPointId;
  iplengthCH: any = [];
  oplengthCH: any = [];
  bslengthCH: any = [];
  mhlengthCH:any=[];
  bsCH: any = [];
  ipCH: any = [];
  opCH: any = [];
  mhCH:any=[];
  loader:boolean=false;
  
  constructor(public data: DataService, public app: AppService) { }

  async ngOnInit() {
    this.loader=true;
    var url = this.app.sendUrl()
    this.checkPointId = url.split('/').pop()
    this.checkPoint = await this.data.getCheckpointById(this.checkPointId).toPromise();
    var workqid = this.checkPoint.worq.id
    var today = new Date();
    this.date1 = today.getFullYear() + '/' + ("0" + (today.getMonth() + 1)).slice(-2) + '/' + ("0" + today.getDate()).slice(-2) + ' ' + '00:00:00'
    this.date2 = today.getFullYear() + '/' + ("0" + (today.getMonth() + 1)).slice(-2) + '/' + ("0" + today.getDate()).slice(-2) + ' ' + '23:59:59'
    this.checkedInData = await this.data.getCheckedIn1(workqid,this.date1,this.date2).toPromise();
    this.checkedInDataClone=this.checkedInData
    var ip=this.checkedInData.filter(res=>{return res.entityType==2})
    this.ipCH=ip
    var op=this.checkedInData.filter(res=>{return res.entityType==1})
    this.opCH=op
    var bs=this.checkedInData.filter(res=>{return res.bedSide==true})
    this.bsCH=bs
    var mhc=this.checkedInData.filter(res=>{return res.entityType==3})
    this.mhCH=mhc
    if (this.checkedInData) {
      let len = this.checkedInData.length;
      let iplength=ip.length
      let oplength=op.length
      let bslength=bs.length
      let mhclength=mhc.length
      if(len > 9){
        this.checkedInLen = len;
      }
      else{
        this.checkedInLen = '0'+len;
      }
      if(iplength > 9){
        this.iplengthCH = iplength;
      }
      else{
        this.iplengthCH = '0'+iplength;
      }
      if(oplength > 9){
        this.oplengthCH = oplength;
      }
      else{
        this.oplengthCH = '0'+oplength;
      }
      if(bslength > 9){
        this.bslengthCH = bslength;
      }
      else{
        this.bslengthCH = '0'+bslength;
      }
      if(mhclength > 9){
        this.mhlengthCH = mhclength;
      }
      else{
        this.mhlengthCH = '0'+mhclength;
      }
     this.loader=false;
    }
  }
expand(data){
  if(data.opencard){
    data.opencard=false
  }
  else {
    this.checkedInData.map(res => {
      Object.assign(res, { opencard: false })
    })

    Object.assign(data,{opencard:true})
  }
}
filter(id){
  if(id==1){
   this.checkedInData=this.ipCH
  }
  if(id==2){
    this.checkedInData=this.opCH
   }
   if(id==3){
    this.checkedInData=this.bsCH
   }
   if(id==4){
    this.checkedInData= this.mhCH
   }
   if(id==5){
    this.checkedInData= this.checkedInDataClone
   }
}
}
