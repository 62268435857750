import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    loader: boolean = false;
    languageCode: string;

    constructor(private http: HttpClient, public app: AppService, private router: Router, private translate: TranslateService,) {
        this.languageCode = localStorage.getItem('lang');
    }

    private url = window.atob(window["apiUrl"]);
    // private url = "http://192.168.1.21:8082"

    // clinica prod
    private url1 = "/userManagement/"
    
    // local prod
    // private url1 = "/userManagement-svcs-sdh/"

    login(credentials) {
        return this.http.post(this.url + this.url1 +'login/authenticate', credentials);
    }

    loginByMobile(credentials) {
        return this.http.post(this.url + this.url1 +'users/signUpByContactNo', credentials)
    }

    loggedIn() {
        return !!localStorage.getItem('hashKey')
    }

    logout() {
        return this.http.post(this.url + this.url1 +'login/logout', { "userId": localStorage.getItem('currentUserId') })
            .subscribe(res => {
                localStorage.clear();
                sessionStorage.clear();
                localStorage.setItem('lang', 'en-US')
                this.router.navigate(['weblogin']);
                // this.app.openSnackBar('Logged out Successfully', 'success-snackbar')
                this.translate.use(this.languageCode).subscribe(() => {
                    this.translate.get("Logged out Successfully",).subscribe((translation: string) => {
                        this.app.openSnackBar(translation, "success-snackbar");
                    });
                })
            }, err => {
                localStorage.clear();
                sessionStorage.clear();
                localStorage.setItem('lang', 'en-US')
                this.router.navigate(['weblogin']);
                // this.app.openSnackBar('Logged out Successfully', 'success-snackbar')
                this.translate.use(this.languageCode).subscribe(() => {
                    this.translate.get("Logged out Successfully",).subscribe((translation: string) => {
                        this.app.openSnackBar(translation, "success-snackbar");
                    });
                })
            })
    }

    validMobileOtp(data) {
        return this.http.post(this.url + this.url1 +'login/validateResetPasswordOTP', data)
    }
    sendForgetOtp(data) {
        return this.http.post(this.url + this.url1 +'login/forgotPassword', data);
    }
    resetpass(data) {
        return this.http.put(this.url + this.url1 +'login/newPassword?reset=true', data);
    }
    getWorkflowbyPatient(id) {
        return this.http.get(this.url + '/clinique/workflows?entityId=' + id);
    }
    getPatientById(id) {
        return this.http.get(this.url + '/clinique/entities/' + id);
    }
    getDateformat() {
        if (localStorage.getItem('config')) {
            var con = JSON.parse(localStorage.getItem('config'));
            let y = con.find(res => res.shortCdConst == "DTFMT");
            return y.userValue;
        }
    }
    async getConfig() {
        let config = await this.http.get(this.url + '/clinique/appConfigs').toPromise()
        //  localStorage.setItem('config',JSON.stringify(config))
        let x: any = config;
        x = x.map(res => { return res.configs });
        let a: any = [];
        x = x.map(res => { a = a.concat(res) });
        localStorage.setItem('config', JSON.stringify(a))
        var con = JSON.parse(localStorage.getItem('config'));
        let y = con.find(res => res.shortCdConst == "UIC");
        y = y.userValue;
        console.log(y);
        if (y == 'Health') {
            sessionStorage.setItem('health', 'true');
        }
        else {
            sessionStorage.setItem('health', 'false');
        }
    }
    getConfig1() {
        return this.http.get(this.url + '/clinique/appConfigs')

    }

    async getCapability() {
        let capabilities: any = await this.http.get(this.url + '/clinique/users/' + localStorage.getItem('currentUserId') + '/capabilities').toPromise()
        capabilities = capabilities.map(({ code }) => code);
        localStorage.setItem('capability', JSON.stringify(capabilities))
        let y = JSON.parse(localStorage.getItem('capability'));
        this.app.mobCab = y.includes('MOBNOVISIB');
    }

    getWorkqStatus(id, id1) {
        return this.http.get(this.url + '/clinique/workqs/' + id + '/status?entityId=' + id1);
    }
    register(user) {
        return this.http.post<any>(this.url + this.url1 +'users/signUp', user, {
            observe: 'response',
            headers: new HttpHeaders({
                'Accept-Language': localStorage.getItem('lang'),
            })
        });
    }
    getOrgLogo(code) {
        return this.http.get(this.url + '/clinique/org/organizatins/' + code);
    }
}