import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HandlerService } from 'src/api-calls/handler.service';
import { AuthService } from 'src/api-calls/auth.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatTableDataSource } from '@angular/material/table';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-agreement-setup',
  templateUrl: './agreement-setup.component.html',
  styleUrls: ['./agreement-setup.component.scss']
})
export class AgreementSetupComponent implements OnInit {
  applyFilter(filterValue: string) {

    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  loader: boolean = true;
  items: any = [];
  search;
  editId;
  orgObj: any; header = "AGREEMENTS"
  orgName;
  orgId = localStorage.getItem('organizationId');
  newOrgObject: any = {};
  dataSource: any;
  cardView: boolean = true;
  agreement;
  agree;
  displayedColumns = ['id', 'name', 'description', 'edit'];
  constructor(private service: DataService,
    private handler: HandlerService,
    private router: Router,
    private route: ActivatedRoute,
    public app: AppComponent,
    public auth: AuthService,
    private bottomSheet: MatBottomSheet) { }

  ngOnInit() {
    this.loader = true;
    // if (localStorage.getItem('dashBoardOrgId')) {
    //   this.orgId = localStorage.getItem('dashBoardOrgId')
    // } else {
    //   this.orgId = localStorage.getItem('organizationId');
    // }
    // this.service.getOrgLogo().subscribe(res => {
    //   this.newOrgObject = res;
    // })
    // localStorage.setItem('dashBoardOrgId', this.orgId)
    // this.orgObj = JSON.parse(localStorage.getItem('capabilityOrg'));
    // let obj = [];
    // this.orgObj.map(res => {
    //   let x = this.auth.getOrgCapabilities(res.id, 'SP');
    //   if (x == true) {
    //     obj.push(res)
    //   }
    // })
    this.getAgreementType();
    this.getAgreementsType();
    // this.newOrgObject = obj;
    // let org = this.newOrgObject.find(dat => dat.id == this.orgId)
    // if (org)
    //   this.orgName = org.name;
    // else {
    //   this.orgName = this.newOrgObject[0].name;
    //   this.orgId = this.newOrgObject[0].id;
    // }
    // localStorage.setItem('dashBoardOrgId', this.orgId)
    this.service.getallAgreement().subscribe(res => {
      if (res) {
        this.items = res;
        this.dataSource = new MatTableDataSource(this.items);
      }
      this.loader = false;
    }, err => {
      this.loader = false;
      this.handler.err(err)
    })
    // this.service.getAllAgreementsOrg(this.orgId).subscribe(res => {
    //   if (res) {
    //     this.items = res;
    //     this.dataSource = new MatTableDataSource(this.items);

    //   }
    //   this.loader = false;
    // }, err => {
    //   this.loader = false;
    //   this.handler.err(err)
    // })
    // sessionStorage.removeItem('roleCd')
    // this.app.setTitle("DDS4U-" + this.header)
  }

  viewChange(id) {
    if (id == 1) {
      this.cardView = true
    }
    else {
      this.cardView = false
    }
  }

  addnew(){
    this.router.navigate(['/agreement-create']);
  }

  edit(id) {
    this.router.navigate(['/edit-agreement', id]);
  }
  // openBottomSheetOrg(res): void {
  //   if (res) {
  //     this.orgName = res.name;
  //     this.orgId = res.id;
  //     localStorage.setItem('dashBoardOrgId', res.id)
  //     this.loader = true;
  //     this.service.getAllAgreementsOrg(this.orgId).subscribe(res => {
  //       if (res) {
  //         this.items = res;
  //       }
  //       else {
  //         this.items = [];
  //         this.handler.res("No Agreements For you")
  //       }
  //       this.loader = false;
  //     }, err => {
  //       this.loader = false;
  //       this.handler.err(err)
  //     })
  //     this.auth.getConfig();
  //   }
  // }

  openBottomSheetOrg(res): void {
    if (res) {
      this.orgName = res.name;
      this.orgId = res.id;
      this.loader = true;
      this.service.getAllAgreementsOrg(this.orgId).subscribe(res => {
        if (res) {
          this.items = res;
        }
        else {
          this.items = [];
          this.handler.res("No Agreements For you")
        }
        this.loader = false;
      }, err => {
        this.loader = false;
        this.handler.err(err)
      })
      this.auth.getConfig();
    }
  }

  getAgreementType() {
    this.service.getAgreementType().subscribe(res => {
      this.agreement = res;
    })
  }

  getAgreementsType() {
    this.service.getAgreementsType().subscribe(res => {
      this.agree = res;
    })
  }
}