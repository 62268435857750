
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { jsPDF } from "jspdf";
import { DataService } from 'src/api-calls/data.service';
let i=0,j=0,k=0;
@Component({
  selector: 'app-pdfview',
  templateUrl: './pdfview.component.html',
  styleUrls: ['./pdfview.component.scss']
})
export class PdfviewComponent implements OnInit {
  pdfDatas:any=[];
  pdfjsLib = window['pdfjs-dist/build/pdf'];
  pdfWorkerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
  id;docs:any;loader:boolean=false;
  constructor(private service:DataService,private router:Router) { 
  
  }
    pdf;
  async ngOnInit() {
    this.loader=true;
    j=0;
    i=0;
    this.pdfjsLib.GlobalWorkerOptions.workerSrc = this.pdfWorkerSrc;
    this.getDiagnosis()
}
  renderPage(page,pdf,pageNumber){
    let obj=this;
    var scale = 1.8;
      var viewport = page.getViewport({scale: scale});
      var numPages = pdf.numPages;
      let div= document.getElementById( "full" );
      // Prepare canvas using PDF page dimensions

      var canvas = document.createElement( "canvas" );
      div.appendChild(canvas)
      canvas.style.display = "block";
      var context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
  
      // Render PDF page into canvas context
      var renderContext = {
        canvasContext: context,
        viewport: viewport
      };
      var renderTask = page.render(renderContext);
      renderTask.promise.then(function () {
      });
      pageNumber++;
      if ( pdf !== null && pageNumber <= numPages )
      {
        pdf.getPage(pageNumber).then(function(page){
          obj.renderPage(page,pdf,pageNumber)
        })
      }
      else{
        i++;
        if(this.pdfDatas[i]){
          this.setPdf()
        }
        else{
         i=0;
         this.loader=false;
        }
      }
    }

setPdf(){
  let obj=this;
  let pdf:any;
  pdf=this.pdfjsLib.getDocument(this.pdfDatas[i]);
  pdf.promise.then(function(pdf) {
    // Fetch the first page
    var pageNumber = 1;
    pdf.getPage(pageNumber).then(function(page){
      obj.renderPage(page,pdf,pageNumber)
    })
  })
}
 imageToPdf(data){
   let obj=this;
  var doc = new jsPDF();
  var i = new Image(); 

  i.onload = function(){
    if(i.width>500)
    doc.addImage(data, 'JPEG',10, 50, 200, 180);
    else
    doc.addImage(data, 'JPEG',10, 50, 0, 0);
      let dataurl=doc.output('datauristring');
      obj.pdfDatas.push(dataurl);
      j++;
      obj.getDocs(obj.docs)
  };

   i.src = data; 
 
 }
 getDiagnosis(){
   this.id=this.router.url.split('/').pop()
   this.service.diagnosis(this.id).subscribe((res:any)=>{
     this.docs=res.diagnosisDocs
     this.getDocs(res.diagnosisDocs)
   })
 }
 getDocs(docs){
   if(docs[j]){
   this.service.getDocument(this.id,docs[j].documentId).subscribe((res:any)=>{
     if(docs[j].docName.includes('.pdf')){
     this.pdfDatas.push(res.doc);
     j++;
     this.getDocs(docs)
     }
     else
     this.imageToPdf(res.doc)
   },err=>{
     j++;
     this.getDocs(docs)
   })
  }
  else{
    j=0;
    this.setPdf()
  }
 }
}
