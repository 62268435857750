import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { MatDialog } from '@angular/material';
import { BlockPopComponent } from '../block-pop/block-pop.component';
import { AuthService } from 'src/api-calls/auth.service';
import { AssistancepopComponent } from '../assistancepop/assistancepop.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-checkpoint-add',
  templateUrl: './checkpoint-add.component.html',
  styleUrls: ['./checkpoint-add.component.scss']
})
export class CheckpointAddComponent implements OnInit {

  name;
  description;
  item: any;
  urlCheck: boolean = false;
  id;
  avgTime;
  location;
  incharge = 0;
  block: any;
  checkpoint;
  position: any = [];
  positionId = 0;
  users: any = [];
  checkTypes: any = [];
  blockShow: string;
  subShow: string;
  appcheckpoint;
  addReviewToTop: boolean;
  milestoneMandatryInd: boolean;
  entityNotifications: boolean;
  appname;
  appdesp;
  applocation;
  appapt;
  activeInd;
  checkType = 1;
  patientTypes: any[] = [{ name: 'In-Patient', id: 2 },
  { name: 'Out-Patient', id: 1 },
  { name: 'MHC', id: 3 },
  { name: 'BedSide', id: 4 }];
  layout: any[] = [{ name: 'Yes', id: 1 },
  { name: 'No', id: 2 }];
  layoutId;
  patientsearchtypes: any = [];
  patientType = this.patientTypes[1].id
  checkTyp = this.checkTypes;
  checksearchtypes: any = [];

  apptClosingTime;
  entityType: any = [1];
  loader: boolean = false;
  available: boolean = true;
  reArrangeAllocation: boolean = true;
  countrynum = "+91";
  mobile;
  contactNbrLst: any = [];
  advNoteCount;
  waitingArea;
  shortCode;
  preReq: any = [];
  configOpen: boolean = false;
  actOpen: boolean = false;
  items:any=[];
  selectsub:boolean = true;
  mandateEditWorkflow : boolean = false;
  reportReadyNotification:boolean;
  fullScreenView:boolean;
  reportCollectNotification:boolean;
  active: boolean = true;
  reportReadyTvConfig:boolean;
  languageCode: string;
  constructor(public data: DataService, public dialog: MatDialog, private translate: TranslateService, public app: AppService, public auth: AuthService) { }

  async  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.app.popData = [];
    this.app.popSub = [];
    this.app.shiftData = [];
    this.app.preRequest = [];
    this.app.popMob = [];
    this.items.push({ testName: "", description: "",shortCd:"",combination:false,dependent:false, icon: 'ion-ios-add-circle' });
    let url = this.app.urlData();
    this.data.getPosition().subscribe(res => {
      this.position = res;
    });
    var post = {}
    // this.data.searchUser(post).subscribe(res => {
    //   this.users = res;


    // });
    this.data.getChecks().subscribe(res => {
      this.checkTypes = res;


    });

    if (url != 'checkpointAdd') {
      this.urlCheck = true;
      this.id = this.app.urlData();
      if (this.id != null) {
        this.loader = true;

        this.data.getCheckpointById(this.id).subscribe(res => {
          this.item = res;
          if (this.item) {
            this.name = this.item.name;
            this.description = this.item.description;
            this.avgTime = this.item.avgProcessingTime;
            this.positionId = this.item.checkPointPositionId;
            this.location = this.item.location;
            this.incharge = this.item.personInCharge;
            this.apptClosingTime = this.item.apptClosingTime;
            this.checkType = this.item.cpType;
            this.available = this.item.available;
            this.reArrangeAllocation = this.item.reArrangeAllocation;
            this.mandateEditWorkflow = this.item.mandateEditWorkflow;
            this.advNoteCount = this.item.advNoteCnt;
            this.shortCode = this.item.shortCd;
            this.waitingArea = this.item.waitingArea;
            this.addReviewToTop = this.item.addReviewToTop;
            this.milestoneMandatryInd = this.item.milestoneMandatryInd;
            this.entityNotifications = this.item.entityNotifications;
            this.reportReadyNotification = this.item.reportReadyNotification;
            this.reportReadyTvConfig = this.item.reportReadyTvConfig;
            this.fullScreenView = this.item.fullScreenView;
            this.reportCollectNotification = this.item.reportCollectNotification;
            this.active = this.item.activeInd;
            this.layoutId=this.item.layout
            if (this.item.preReqChkpoint != null) {
              this.app.preRequest = this.item.preReqChkpoint;
            }
            if (this.item.blockTimes != null) {
              this.item.blockTimes.map(res => {
                res.add = true;
              })
              this.app.popData = this.item.blockTimes;
            }
            let mobList: any = [];
            if (this.item.contactNoLst) {
              this.item.contactNoLst.map(res => {
                this.app.popMob.push({ num: res.notiMobileNbr, type: res.type, countryNum: '', mobile: '' })
              })
              // this.app.popMob=this.item.contactNbrLst;
              if (this.app.popMob.length > 0)
                this.app.popMob.map(res => {
                  // Object.assign(res, { countryNum: '', mobile: '' })
                  var len = res.num.length;
                  len = len - 10;
                  let a: any = res.num.slice(0, len)
                  res.countryNum = a;
                  res.mobile = res.num.slice(-10);
                })
              // this.item.contactNbrLst.map(res=>{
              //   var len = res.length;
              //   len = len - 10;
              //   var countryNum = res.slice(0, len);
              //   var mobile = res.contactNbrLst.slice(-10);
              // })
              // var len = this.item.contactNbrLst.length;
              // len = len - 10;
              // this.countrynum = this.item.contactNbrLst.slice(0, len);
              // this.mobile = this.item.contactNbrLst.slice(-10);
            }
            if (this.item.subCheckpoints != null) {
              this.item.subCheckpoints.map(res => {
                res.add = true;
              })
              this.app.popSub = this.item.subCheckpoints;
            }
            if (this.item.shiftSpecs != null) {
              this.item.shiftSpecs.map(res => {
                res.add = true;
              })
              this.app.shiftData = this.item.shiftSpecs;
            }
            this.item.checkpointChecks.map(res => {
              this.switchCheckType(res.id);
            })


            this.item.entityTypesLst.map((res: any) => {
              if (res.entityType == 1) {
                this.switchPatientType(1);
              }
              if (res.entityType == 2) {
                this.switchPatientType(2);
              }
              if (res.entityType == 3) {
                this.switchPatientType(3);
              }
              if (res.entityType == 4) {
                this.switchPatientType(4);
              }
              if (res.entityType == 1 && res.entityType == 2 && res.entityType == 3 && res.entityType == 4) {
                this.switchPatientType(5);
              }
            }),
              this.loader = false
          }
        },
          err => {
            this.loader = false;
          })


      }
    }


    let x = JSON.parse(localStorage.getItem('config'));
    let a = x.find(res => res.shortCdConst == "CHKNM");
    this.appname = a.userValue;
    let b = x.find(res => res.shortCdConst == "CHKDESC");
    this.appdesp = b.userValue;
    let c = x.find(res => res.shortCdConst == "CHKLOC");
    this.applocation = c.userValue;
    let d = x.find(res => res.shortCdConst == "CHKAVGPT");
    this.appapt = d.userValue;
    let e = x.find(res => res.shortCdConst == "SCAPL");
    this.subShow = e.userValue;
    let f = x.find(res => res.shortCdConst == "BTAPL");
    this.blockShow = f.userValue;

  }
  async save() {

    if (this.app.shiftData.length < 1) {
      // this.app.openSnackBar('Please enter shift time', 'error-snackbar');
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Please enter shift time").subscribe((translation: string) => {
            this.app.openSnackBar(translation, "error-snackbar")
        })
    })
    }
    else {
      this.saveClear();
    }
  }
  async saveClear() {
    this.loader = true;
    var checkType = [];


    var blockTimes = [];
    var mobNum = [];
    this.app.popData.map(res => {
      if (res.add == true) {
        blockTimes.push({
          blockStartTime: res.blockStartTime,
          blockEndTime: res.blockEndTime
        })
      }
    });
    this.app.popMob.map(res => {
      mobNum.push({
        notiMobileNbr: res.countryNum + res.mobile,
        type: res.type
      }
      )
    });
    var shiftData = [];
    this.app.shiftData.map(res => {
      if (res.add == true) {
        shiftData.push({
          startTime: res.startTime,
          endTime: res.endTime,
          maxTokens: res.maxTokens,
        })
      }
    });
    // this.app.preRequest.map(res=>{
    //   this.preReq.push(res.id)
    // })
    if (this.patientType != 1) {
      this.entityType = [];
      this.patientsearchtypes.map((res: any) => {
        this.entityType.push(res.id);
      })
    }
    






    
    if (this.patientType == 1) {
      this.patientsearchtypes.map((res: any) => {
        this.entityType.push(res.id);
      })
    }
    this.entityType = this.entityType.filter((x, i, a) => a.indexOf(x) == i);
    this.contactNbrLst = this.countrynum + this.mobile;
    if (this.name) {
      this.name = this.name.toUpperCase();
    }

    let post = {
      name: this.name,
      description: this.description,
      activeInd: this.active,
      avgProcessingTime: this.avgTime,
      checkPointPositionId: this.positionId,
      location: this.location,
      personInCharge: this.incharge,
      blockTimes: blockTimes,
      shiftSpecs: shiftData,
      apptClosingTime: this.apptClosingTime,
      entityTypes: this.entityType,
      cpType: this.checkType,
      available: this.available,
      reArrangeAllocation: this.reArrangeAllocation,
      mandateEditWorkflow:this.mandateEditWorkflow,
      contactNoLst: mobNum,
      advNoteCnt: this.advNoteCount,
      waitingArea: this.waitingArea,
      shortCd: this.shortCode,
      preReqChkpoint: this.app.preRequest,
      checkpointChecks: this.checksearchtypes,
      addReviewToTop: this.addReviewToTop,
      milestoneMandatryInd: this.milestoneMandatryInd,
      entityNotifications: this.entityNotifications,
      reportReadyNotification:this.reportReadyNotification,
      reportReadyTvConfig:this.reportReadyTvConfig,
      fullScreenView:this.fullScreenView,
      reportCollectNotification:this.reportCollectNotification, 
      layout:this.layoutId

    }
    if (this.patientType)
      if (this.name) {
        await this.data.createCheckpoints(post).toPromise()
          .then((res: any) => {
            // this.app.popSub=[];
            // this.app.popData=[];
            this.loader = false;
            this.app.openPage('checkpointSearch');
            // this.app.openSnackBar('Checkpoint added Successfully !!', 'success-snackbar');
            this.translate.use(this.languageCode).subscribe(() => {
              this.translate.get("Checkpoint added Successfully").subscribe((translation: string) => {
                  this.app.openSnackBar(translation, "success-snackbar")
              })
          })
            this.app.popSub.map((res1) => {
              let post1 = {
                name: res1.name,
                description: res1.description,
                active: res1.activeInd,
                avgProcessingTime: null,
                checkPointPositionId: null,
                location: null,
                personInCharge: null,
                blockTimes: null,
                parentCheckpointId: res.id,
              }
              this.data.createCheckpoints(post1).toPromise()
                .then((res) => {
                  this.name = "";
                  this.description = "";
                  this.avgTime = "";
                  this.app.popSub = [];
                  this.app.popData = [];
                  this.app.popMob = [];
                  this.app.shiftData = [];
                  this.app.preRequest = [];
                  this.patientsearchtypes = [];
                  this.checksearchtypes = [];
                  this.loader = false;
                  this.addReviewToTop = false;
                  this.milestoneMandatryInd = false;
                  this.entityNotifications = false;
                  this.app.openPage('checkpointSearch');
                  //  this.app.openSnackBar('Checkpoint added Successfully !!','success-snackbar');

                })
            });
          })
          .catch(
            err => {
              this.loader = false;
              // this.presentToast(err.error.msg)

            }
          )
      }
  }

  addNew() {
    this.name = "";
    this.description = "";
    this.app.popSub = []
    this.app.popData = []
    this.app.shiftData = [];
    this.app.preRequest = [];
    this.patientsearchtypes = [];
    this.checksearchtypes = [];
    this.app.popMob = [];
    this.addReviewToTop = false;
    this.milestoneMandatryInd = false;
    this.entityNotifications = false;
  }

  edit() {
    this.loader = true;
    var blockTimes = [];
    var mobNum = [];
    this.app.popMob.map(res => {
      mobNum.push({
        notiMobileNbr: res.countryNum + res.mobile,
        type: res.type
      }
      )
    });
    this.app.popData.map(res => {
      blockTimes.push({
        id: res.id,
        blockStartTime: res.blockStartTime,
        blockEndTime: res.blockEndTime
      })
    });
    var subData = [];
    this.app.popSub.map(res => {
      subData.push({
        id: res.id,
        name: res.name,
        description: res.description
      }
      )
    })
    var shiftData = [];
    this.app.shiftData.map(res => {
      shiftData.push({
        id: res.id,
        startTime: res.startTime,
        endTime: res.endTime,
        maxTokens: res.maxTokens,
        checkpoint: res.checkpoint
      })
    });
    var entityType = [];
    this.patientsearchtypes.map((res: any) => {
      entityType.push(res.id);

    })
    entityType = entityType.filter((x, i, a) => a.indexOf(x) == i);
    if (this.name) {
      this.name = this.name.toUpperCase();
    }
    this.item.name = this.name;
    this.item.description = this.description;
    this.item.avgProcessingTime = this.avgTime;
    this.item.blockTimes = blockTimes;
    this.item.location = this.location;
    this.item.personInCharge = this.incharge;
    this.item.checkPointPositionId = this.positionId;
    this.item.shiftSpecs = shiftData;
    this.item.entityTypes = entityType;
    this.item.apptClosingTime = this.apptClosingTime;
    this.item.cpType = this.checkType;
    this.item.available = this.available;
    this.item.reArrangeAllocation = this.reArrangeAllocation;
    this.item.mandateEditWorkflow = this.mandateEditWorkflow;
    this.item.addReviewToTop = this.addReviewToTop;
    this.item.milestoneMandatryInd = this.milestoneMandatryInd;
    this.item.reportReadyNotification = this.reportReadyNotification;
    this.item.reportReadyTvConfig = this.reportReadyTvConfig;
    this.item.fullScreenView = this.fullScreenView;
    this.item.reportCollectNotification = this.reportCollectNotification;
    this.item.activeInd = this.active;
    // this.contactNbrLst=this.countrynum+this.mobile
    this.item.contactNoLst = mobNum;
    this.item.advNoteCnt = this.advNoteCount;
    this.item.waitingArea = this.waitingArea;
    this.item.shortCd = this.shortCode;
    this.item.checkpointChecks = this.checksearchtypes;
    this.item.preReqChkpoint = this.app.preRequest;
    this.item.entityNotifications = this.entityNotifications;
    this.item.layout=this.layoutId;


    this.data.updateCheckpointsById(this.id, this.item).toPromise()
      .then((res) => {
        this.loader = false;
        this.app.openPage('checkpointSearch');
        // this.app.openSnackBar('Checkpoint Updated Successfully !!', 'success-snackbar');
        this.translate.use(this.languageCode).subscribe(() => {
          this.translate.get("Checkpoint Updated Successfully").subscribe((translation: string) => {
              this.app.openSnackBar(translation, "success-snackbar")
          })
      })
        this.app.popSub.map(res => {
          if (res.id == undefined) {
            let p = {
              name: res.name,
              description: res.description,
              active: res.activeInd,
              avgProcessingTime: null,
              checkPointPositionId: null,
              location: null,
              personInCharge: null,
              blockTimes: null,
              parentCheckpointId: this.id
            }
            this.data.createCheckpoints(p).toPromise()
              .then((res) => {
                this.name = "";
                this.description = "";
                this.avgTime = "";
                this.app.popSub = [];
                this.app.popData = [];
                this.app.popSub = [];
                this.app.shiftData = [];
                this.app.preRequest = [];
                this.patientsearchtypes = [];
                this.checksearchtypes = [];
                this.loader = false;
                this.addReviewToTop = false;
                this.milestoneMandatryInd = false;
                this.entityNotifications = false;
                this.app.openPage('checkpointSearch');
                // this.app.openSnackBar('Checkpoint Updated Successfully !!', 'success-snackbar');
                this.translate.use(this.languageCode).subscribe(() => {
                  this.translate.get("Checkpoint Updated Successfully").subscribe((translation: string) => {
                      this.app.openSnackBar(translation, "success-snackbar")
                  })
              })
              })
              .catch(
                err => {
                  this.loader = false;

                }
              )
          }
          else {
            this.loader = false;
            this.app.openPage('checkpointSearch');
            // this.app.openSnackBar('Checkpoint Updated Successfully !!', 'success-snackbar');
            this.translate.use(this.languageCode).subscribe(() => {
              this.translate.get("Checkpoint Updated Successfully").subscribe((translation: string) => {
                  this.app.openSnackBar(translation, "success-snackbar")
              })
          })
          }
        })

      })
      .catch(
        err => {
          this.loader = false;
          // this.presentToast(err.error.msg)

        }
      )
  }
  back() {
    history.back();
  }
  cancel() {
    this.app.popSub = []
    this.app.popData = []
    this.app.popMob = []
    this.app.shiftData = []
    this.app.openPage('checkpointSearch');
  }

  openDialog(id): void {
    const dialogRef = this.dialog.open(BlockPopComponent, {
      data: id
    });
  }

  remove(i) {
    this.app.popData.splice(i, 1);
  }
  mobRemove(i) {
    this.app.popMob.splice(i, 1);
  }

  delete(i) {
    this.app.popSub.splice(i, 1);
  }
  clear(i) {
    this.app.shiftData.splice(i, 1);
  }
  clear1(i) {
    this.app.preRequest.splice(i, 1);
  }
  removeChip(i) {
    this.patientsearchtypes.splice(i, 1);
  }
  dltChip(i) {
    this.checksearchtypes.splice(i, 1);
  }
  switchPatientType(data) {
    let patienttype: any = [];
    if (data != 5) {
      patienttype = this.patientTypes.filter(res => { return res.id == data })
      this.patientsearchtypes.push(patienttype[0])
    }
    else {
      this.patientsearchtypes = []
      this.patientsearchtypes = this.patientTypes;
    }
    this.patientsearchtypes = this.patientsearchtypes.filter((x, i, a) => a.indexOf(x) == i);
  }
  switchCheckType(data) {
    let checkTyp: any = [];
    if (data != 5) {
      checkTyp = this.checkTypes.filter(res => { return res.id == data })
      this.checksearchtypes.push(checkTyp[0])
    }
    else {
      this.checksearchtypes = []
      this.checksearchtypes = this.checkTypes;
    }

    this.checksearchtypes = this.checksearchtypes.filter((x, i, a) => a.indexOf(x) == i);
  }
  perReq(id): void {
    if (this.id != undefined && this.id != null && this.id != "null") {
      sessionStorage.setItem('preReqId', this.id)
    }
    else {
      sessionStorage.setItem('preReqId', null)
    }
    const dialogRef = this.dialog.open(AssistancepopComponent, {
      data: id
    });
  }
  openConfig() {
    this.configOpen = !this.configOpen;
    this.actOpen = false;
  }
  openAct() {
    this.actOpen = !this.actOpen;
    this.configOpen = false;
  }
  addPush(data, i) {

      if (data.icon === "ion-ios-close-circle") {
        this.items.splice(i, 1);
      }
      else {
        this.items[i].icon = "ion-ios-close-circle";
        this.items.push({ testName: "", description: "",shortCd:"",combination:false,dependent:false, icon: 'ion-ios-add-circle' });
      }
    }

}
