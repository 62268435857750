import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HandlerService } from 'src/api-calls/handler.service';

@Component({
  selector: 'app-block-end',
  templateUrl: './block-end.component.html',
  styleUrls: ['./block-end.component.scss'],
})
export class BlockEndComponent implements OnInit {
 
  times:any=[];hr; min = 0;morEve = true;maxhr;minhr;i=0;check:boolean=false;
  constructor(public handler:HandlerService,public dialogRef: MatDialogRef<BlockEndComponent>,@Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    if(!this.handler.check)
    this.handler.j++;
    let time:any=[];
    this.data.map(res=>{
      time.push(res.startTime,res.endTime)
    })

  setTimeout(() => {
    time=time.filter((v, i, a) => a.indexOf(v) === i)
    time.map(res=>{
      this.times.push({hr:res.split(':').shift(),min:res.split(':').pop()})
    })
  }, 500);
}

  add() {
   let len=this.times.length-1;
   if(this.handler.j==len){
    this.handler.j=0;
   }
   else{
   this.handler.j++;
   }
  }

  setTime(i){
    setTimer(this.times[i].hr + ':' + this.times[i].min);
    this.handler.check=true;
    this.dialogRef.close(2);
  }

  cancel(){
    this.dialogRef.close(1);
  }
  minus() {
    let len=this.times.length-1;
    if(this.handler.j==0)
      this.handler.j=len;
     else
      this.handler.j--;
 
   }

}
export let timer1;

export function setTimer(data){
    timer1 = data;
}