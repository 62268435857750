import { Injectable, NgModule } from '@angular/core';
import { HttpInterceptor, HTTP_INTERCEPTORS, HttpHeaders } from '@angular/common/http';
import { DataService } from './data.service';
import { AuthGuard } from './auth.guard';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class InterService implements HttpInterceptor {
    languageCode: string;

    constructor(public app: AppService, private router: Router, public data: DataService, private translate: TranslateService) {
        this.languageCode = localStorage.getItem('lang');
    }

    intercept(req, next) {
        if (req.url.includes('assets')) {
            let token = req.clone(
                new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            )
            return next.handle(token)
        }
        if (req.url.includes('forgotPassword')) {
            let token = req.clone({
                headers: new HttpHeaders({
                    'Accept-Language': localStorage.getItem('lang'),
                    'appId': "2",
                    // 'currentUserId':'1'
                })
            })
            return next.handle(token).pipe(
                tap(
                    res => { },
                    err => 
                    // this.app.openSnackBar("Invalid Login Credentials", 'error-snackbar')

                    this.translate.use(this.languageCode).subscribe(() => {
                        this.translate.get("Invalid Login Credentials",).subscribe((translation: string) => {
                            this.app.openSnackBar(translation, "error-snackbar")
                        })
                    })
                ))
        }
        if (req.url.includes('newPassword?reset=true')) {
            let token = req.clone({
                headers: new HttpHeaders({
                    'Accept-Language': localStorage.getItem('lang'),
                    'appId': "2",
                    'Content-Type': 'application/json',
                    'currentUserId': localStorage.getItem('current'),
                    'hashKey': localStorage.getItem('hash'),
                })
            })
            return next.handle(token).pipe(
                tap(
                    res => { },
                    err => 
                    // this.app.openSnackBar("Invalid Login Credentials", 'error-snackbar')
                    this.translate.use(this.languageCode).subscribe(() => {
                        this.translate.get("Invalid Login Credentials",).subscribe((translation: string) => {
                            this.app.openSnackBar(translation, "error-snackbar")
                        })
                    })
                ))
        }
        if (req.url.includes('authenticate') || req.url.includes('signUp')) {
            let token = req.clone({
                headers: new HttpHeaders({
                    'Accept-Language': localStorage.getItem('lang'),
                    'appId': "2",
                    // 'currentUserId':'1'
                })
            })
            return next.handle(token).pipe(
                tap(
                    res => { },
                    err => 
                    // this.app.openSnackBar("Invalid Login Credentials", 'error-snackbar')
                    this.translate.use(this.languageCode).subscribe(() => {
                        this.translate.get("Invalid Login Credentials",).subscribe((translation: string) => {
                            this.app.openSnackBar(translation, "error-snackbar")
                        })
                    })
                ))
        }
        if (req.url.includes('signUpByContactNo')) {
            let token = req.clone({
                headers: new HttpHeaders({
                    'Accept-Language': localStorage.getItem('lang'),
                    'appId': "2"
                })
            })
            return next.handle(token).pipe(
                tap(
                    res => { },
                    err => 
                    // this.app.openSnackBar(err.error.msg, 'error-snackbar')
                    this.translate.use(this.languageCode).subscribe(() => {
                        this.translate.get(err.error.msg).subscribe((translation: string) => {
                            this.app.openSnackBar(translation, "error-snackbar")
                        })
                    })
                ))
        }
        if (req.url.includes('validateResetPasswordOTP')) {
            let token = req.clone({
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'appId': "2"
                })
            })
            return next.handle(token).pipe(
                tap(
                    res => { },
                    err => 
                    // this.app.openSnackBar(err.error.msg, 'error-snackbar')
                    this.translate.use(this.languageCode).subscribe(() => {
                        this.translate.get(err.error.msg).subscribe((translation: string) => {
                            this.app.openSnackBar(translation, "error-snackbar")
                        })
                    })
                ))
        }
        if (req.url.includes('logout')) {
            let token = req.clone({
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'currentUserId': localStorage.getItem('currentUserId'),
                    'hashKey': localStorage.getItem('hashKey'),
                    'Accept-Language': localStorage.getItem('lang'),
                    'X-Content-Type-Options': 'nosniff',
                    'X-XSS-Protection': '1',
                    'organizationId': localStorage.getItem('organizationId'),
                    'appId': '2'
                })
            })
            return next.handle(token).pipe(
                tap(
                    res => { },
                    err => 
                    // this.app.openSnackBar(err.error.msg, 'error-snackbar')
                    this.translate.use(this.languageCode).subscribe(() => {
                        this.translate.get(err.error.msg).subscribe((translation: string) => {
                            this.app.openSnackBar(translation, "error-snackbar")
                        })
                    })
                ))
        }
        if (req.url.includes('hc1010')) {
            let token = req.clone({
                headers: new HttpHeaders({
                    // 'Content-Type':'application/json',
                    // 'Accept':'application/json',
                    // 'currentUserId': localStorage.getItem('currentUserId'),
                    // 'hashKey':localStorage.getItem('hashKey'),
                    // 'healthCenterId':localStorage.getItem('organizationId')
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    // 'currentUserId': "2",
                    // 'hashKey':"d5488d71c06ca84b42f9c8687e9a1267163bbee5071ce1bde843a4681f9bcb15541336c585d38cc0c88872dd2d8358801134cd818539a331276ae88ec95eeab3",
                    // 'healthCenterId':"1"
                })
            })
            return next.handle(token).pipe(
                tap(
                    res => { },
                    err => 
                    // this.app.openSnackBar(err.error.msg, 'error-snackbar')
                    this.translate.use(this.languageCode).subscribe(() => {
                        this.translate.get(err.error.msg).subscribe((translation: string) => {
                            this.app.openSnackBar(translation, "error-snackbar")
                        })
                    })
                ))
        }
        else {
            let token = req.clone({
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'currentUserId': localStorage.getItem('currentUserId'),
                    'hashKey': localStorage.getItem('hashKey'),
                    'Accept-Language': localStorage.getItem('lang'),
                    'X-Content-Type-Options': 'nosniff',
                    'X-XSS-Protection': '1',
                    'organizationId': localStorage.getItem('organizationId'),
                    'appId': '2'
                })
            })
            return next.handle(token)
                .pipe(
                    tap(
                        res => { },
                        err => {
                            if (err.status === 401) {
                                localStorage.clear();
                                localStorage.setItem('lang', 'en-US')
                                sessionStorage.clear();
                                // this.app.openSnackBar('Session timed out', 'success-snackbar')
                                this.translate.use(this.languageCode).subscribe(() => {
                                    this.translate.get("Session timed out").subscribe((translation: string) => {
                                        this.app.openSnackBar(translation, "success-snackbar")
                                    })
                                })
                                this.router.navigate(['weblogin']);
                            }
                            if (err.status === 500) {
                                this.app.loader1 = false;
                                this.data.loader = false;
                                // this.app.openSnackBar('Internal server error', 'error-snackbar');
                                this.translate.use(this.languageCode).subscribe(() => {
                                    this.translate.get("Internal server error").subscribe((translation: string) => {
                                        this.app.openSnackBar(translation, "error-snackbar")
                                    })
                                })
                            }
                            if (err.status === 409) {
                                if (!req.url.includes('nextAppt')) {
                                    // this.app.openSnackBar(err.error.msg, 'error-snackbar');
                                    this.translate.use(this.languageCode).subscribe(() => {
                                        this.translate.get(err.error.msg).subscribe((translation: string) => {
                                            this.app.openSnackBar(translation, "error-snackbar")
                                        })
                                    })
                                    this.app.loader1 = false;
                                }

                            }
                            if (err.status === 400) {
                                this.app.loader1 = false;
                            }
                            if (err.status === 204) {
                                if (req.url.includes('entities?parentUserId')) {
                                    // this.app.openSnackBar('No data', 'error-snackbar');
                                    this.translate.use(this.languageCode).subscribe(() => {
                                        this.translate.get("No data").subscribe((translation: string) => {
                                            this.app.openSnackBar(translation, "error-snackbar")
                                        })
                                    })
                                    this.router.navigate(['weblink']);
                                }
                            }
                        }
                    )
                )
        }
    }



}

@NgModule({
    providers: [
        DataService,
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterService,
            multi: true
        }
    ]
})

export class InterceptorModule { }