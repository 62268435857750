import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-entities',
  templateUrl: './user-entities.component.html',
  styleUrls: ['./user-entities.component.scss']
})
export class UserEntitiesComponent implements OnInit {
  entities:any=[];
  loader:boolean=true;
  userLang;
  constructor(public service:DataService, public app:AppService,public translate:TranslateService ) { }

  ngOnInit() {
     this.userLang=sessionStorage.getItem('userLang')
     if(this.userLang){
      if(this.userLang==105){
        this.translate.use('hi')
       }
       if(this.userLang==103){
        this.translate.use('ta')
       }
       if(this.userLang==101){
        this.translate.use('en-US')
       }
      }
      else{
        this.translate.use('en-US')
      }

    this.service.getEntitiesByUserId().subscribe(res=>{
      this.entities = res;
      this.loader = false;
    })

  }
  view(item){
    this.app.openPage('patientview/'+item.id)
    sessionStorage.setItem('name',item.name)
    sessionStorage.setItem('patientRefId',item.patientRefId)
  }

}
