import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { DataService } from 'src/api-calls/data.service';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource ,MatSort} from '@angular/material';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  displayedColumns = ['serviceName', 'unitPrice', 'totalSessions','avlQty','cost','actions','action'];
  allbilling: any = [];
  loader;
  ad;
  page;
  component;
  msgContent: string = "";
  languageCode: string;
  Cview: boolean = true;
  dataSource: any;
  allservices: any =[];
  sort;
  constructor(private data: DataService,
    private translate: TranslateService,
    public app: AppService) { }
    @ViewChild(MatSort) set content(content: ElementRef) {
      this.sort = content;
      if (this.sort){
         this.dataSource.sort = this.sort;
    
      }
    }
    applyFilter(filterValue: string) {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue;
    } 
  ngOnInit() {
    this.languageCode = localStorage.getItem('lang');
    this.loader = true;
    this.data.getServices().subscribe((res: any) => {
      if (res) {
        this.allservices = res;
        this.dataSource = new MatTableDataSource(this.allservices)
        this.loader = false;
      } else {
        this.allbilling = [];
        this.loader = false;
        this.msgContent = "No services Available"
      }

    }), err => {
      this.loader = false;
    }

  }
  viewChange(id) {
    if (id == 1) { this.Cview = true; }
    else {this.Cview = false; }
  }
  newBilling() {
    this.app.openPage('services-create')
  }
  edit(id) {
    this.app.openPage('services-edit/' + id)
  }
  delete(id) {
    this.loader = true;
    this.data.servicesDeleteById(id).subscribe(res => {
      this.loader = false;
      this.ngOnInit();
      // this.app.openSnackBar('Deleted Successfully !!', 'success-snackbar');   
      this.translate.use(this.languageCode).subscribe(() => {
        this.translate.get("Deleted Successfully").subscribe((translation: string) => {
          this.app.openSnackBar(translation, "success-snackbar")
        })
      })

    }, err => {
      this.loader = false;
    })
  }

}
